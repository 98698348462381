import { GamepadDataHandler, VibrationHandler } from "../interfaces";
import { StreamClient } from "../streamclient";
import { TelemetryHandler } from "../telemetry/telemetryhandler";
import { Log } from "../dependencies";
import { setUint64 } from "../util/utils";
import { InputChannel } from "./inputinterfaces";
import {
    WireProtocolPacketId,
    MOUSE_GROUP_HEADER_LEN,
    PacketId,
    CHROME_CALLBACK_LEN,
    SENT_TIMESTAMP_LEN,
    ServerCommands,
    HapticsCommands
} from "./inputpacketinfo";
import { InputHandler, Measurements } from "./inputhandler";
import { MAX_TOUCH_COUNT, TouchType, TouchDataHandler } from "./touchlistener";
import { VideoState } from "../rinterfaces";

const LOGTAG = "inputpackethandler";

export interface KBEvents {
    keydowns: number;
    keyups: number;
}

// Stores everything we need to generate a move packet from a mouse move.
export interface MoveEvent {
    absPos: boolean;
    x: number;
    y: number;
    captureTimestamp: number;
    // When stored in a list, indicates how many subsequent events (including this one)
    // were created from a single event from the browser. One is a valid group size.
    // If zero, means that no mouse group packets should be generated.
    groupSize: number;
    // Time at which the browser gave us this event. Only used if groupSize > 0.
    callbackTimestamp: number;
}

/// Max expected size of a single input packet.
const MAX_SINGLE_INPUT_PACKET = 100;

/// Maximum number of gamepads we can support.
const MAX_GAMEPAD_COUNT = 4;

/// Size of a gamepad packet
const GAMEPAD_PACKET_SIZE = 38;

/// Size of max gamepad buffer size depending on protocol
const GAMEPAD_BUFFER_SIZE_V2 = MAX_GAMEPAD_COUNT * (GAMEPAD_PACKET_SIZE + 2) + 1;
const GAMEPAD_BUFFER_SIZE_V3 = SENT_TIMESTAMP_LEN + MAX_GAMEPAD_COUNT * (GAMEPAD_PACKET_SIZE + 3);

const GamepadControlIndex = 0;

/// Size of a touch packet header
const TOUCH_HEADER_LENGTH = 8;
/// Size of a touch record in a touch packet
const TOUCH_RECORD_LENGTH = 16;
const TOUCH_PACKET_OFFSET = SENT_TIMESTAMP_LEN + 1; /*v3 pid*/

class ShiftedDataView extends DataView {
    private shift = 0;
    constructor(buffer: ArrayBuffer, byteLength?: number, byteOffset?: number, shift?: number) {
        super(buffer, byteLength, byteOffset);
        this.shift = shift ?? 0;
    }
    public setUint8 = (byteOffset: number, value: number) =>
        super.setUint8(byteOffset + this.shift, value);
    public setUint16 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setUint16(byteOffset + this.shift, value, littleEndian);
    public setUint32 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setUint32(byteOffset + this.shift, value, littleEndian);
    public setInt8 = (byteOffset: number, value: number) =>
        super.setInt8(byteOffset + this.shift, value);
    public setInt16 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setInt16(byteOffset + this.shift, value, littleEndian);
    public setInt32 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setInt32(byteOffset + this.shift, value, littleEndian);
    public setFloat32 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setFloat32(byteOffset + this.shift, value, littleEndian);
    public setFloat64 = (byteOffset: number, value: number, littleEndian?: boolean) =>
        super.setFloat64(byteOffset + this.shift, value, littleEndian);
}

type ServerCmdFnType = (view: DataView, offset: number) => void;

export class InputPacketHandler implements GamepadDataHandler, TouchDataHandler {
    private inputChannel: InputChannel;
    private videoState: VideoState;
    private telemetry: TelemetryHandler;
    private streamClient: StreamClient;
    private measurements: Measurements;
    private _lastMoveSendTime: number = 0;
    private _protocolVersion: number = 0;
    // MTU set in WebRTC is 1200 - size of SCTP headers which is about 1188. Round down to 1150 "to be safe".
    private buffer: ArrayBuffer = new ArrayBuffer(1150);
    private bufferView = new DataView(this.buffer);
    private touchBuffer: ArrayBuffer = new ArrayBuffer(
        TOUCH_PACKET_OFFSET + TOUCH_HEADER_LENGTH + MAX_TOUCH_COUNT * TOUCH_RECORD_LENGTH
    );
    private touchBufferView: DataView = new DataView(this.touchBuffer);
    private gamepadBufferView = new DataView(
        new ArrayBuffer(Math.max(GAMEPAD_BUFFER_SIZE_V2, GAMEPAD_BUFFER_SIZE_V3))
    );
    private tempBuffer: ArrayBuffer = new ArrayBuffer(MAX_SINGLE_INPUT_PACKET);

    private handleServerCommandFunc: ServerCmdFnType;

    private sendTimerId: number = 0;

    private moveEventBuffer: MoveEventBuffer;
    private inputHandler: InputHandler;

    private prependScheduledMoves = this.prependScheduledMovesV2;
    private getTempPacket = this.getTempPacketV2;

    private vibrationHandlers: VibrationHandler[] = [];

    constructor(
        inputHandler: InputHandler,
        moveEventBuffer: MoveEventBuffer,
        measurements: Measurements,
        videoState: VideoState,
        streamClient: StreamClient,
        inputChannel: InputChannel,
        telemetry: TelemetryHandler
    ) {
        this.inputHandler = inputHandler;
        this.moveEventBuffer = moveEventBuffer;

        this.streamClient = streamClient;
        this.videoState = videoState;

        this.inputChannel = inputChannel;
        this.inputChannel.onmessage = evt => this.onMessage(evt);

        this.telemetry = telemetry;
        this.measurements = measurements;

        this.handleServerCommandFunc = this.handleVibrationCommand.bind(this);

        // v3 sends from SENT_TIMESTAMP_LEN; v2 from TOUCH_PACKET_OFFSET
        this.touchBufferView.setUint8(SENT_TIMESTAMP_LEN, WireProtocolPacketId.RI_NO_SIZE);
        this.touchBufferView.setUint32(TOUCH_PACKET_OFFSET, PacketId.PACKET_TOUCH_LOW_LEVEL, true);
    }

    public sendLockKeyState(state: number) {
        const packet = this.getTempPacket(5);
        packet.setUint32(0, PacketId.PACKET_LOCK_KEYS, true);
        packet.setUint8(4, state);
        try {
            this.sendInput(packet);
            Log.d("{57a6a4d}", "{56c9613}", state.toString(2));
        } catch (exp) {
            let msg = "LockKeys state synchronize exception";
            Log.e("{57a6a4d}", "{cbe483c}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }

    private onMessage(evt: MessageEvent) {
        // Only interpret the first message received as server version. Version will always be > 0.
        const view = new DataView(evt.data);
        if (this._protocolVersion === 0) {
            let first16 = view.getUint16(0, true);
            if (first16 == ServerCommands.STREAMER_INPUT_CHANNEL_VERSINO_INFO) {
                this.handleServerCommandFunc = this.handleServerCommand.bind(this);
                this._protocolVersion = view.getUint16(2, true);
            } else {
                this.handleServerCommandFunc = this.handleVibrationCommand.bind(this);
                this._protocolVersion = first16;
            }

            if (this._protocolVersion == 2) {
                this.moveEventBuffer.supportsGrouping = true;
            } else if (this._protocolVersion > 2) {
                this.moveEventBuffer.supportsGrouping = true;
                this.prependScheduledMoves = this.prependScheduledMovesV3;
                this.getTempPacket = this.getTempPacketV3;
            }
            Log.i("{57a6a4d}", "{68c4654}", this._protocolVersion);
        } else {
            this.handleServerCommandFunc(view, 0);
        }
    }

    private handleServerCommand(view: DataView, offset: number) {
        let first16 = view.getUint16(offset, true);
        switch (first16) {
            case ServerCommands.STREAMER_SERVER_HAPTICS_EVENT:
                this.handleVibrationCommand(view, offset + 2);
                break;
            default:
                // We assume that all RTC server-to-client communication in future will use aggregation.
                let aggCmd = first16 & 0xff;
                switch (aggCmd) {
                    case WireProtocolPacketId.MOUSE_GROUP:
                    case WireProtocolPacketId.RI_PACKET:
                    case WireProtocolPacketId.RI_NO_SIZE:
                    case WireProtocolPacketId.SENT_TIMESTAMP:
                    case WireProtocolPacketId.CHROME_CALLBACK_TIMESTAMP:
                    case WireProtocolPacketId.INPUT_GROUP:
                        this.handleAggregateRiCommand(aggCmd, view, 1);
                        break;
                    default:
                        // Not a valid aggregation command, nor a known server-to-client command.
                        Log.w("{57a6a4d}", "{aea35b8}", first16);
                        break;
                }
        }
    }

    private handleAggregateRiCommand(cmd: number, view: DataView, offset: number) {
        switch (cmd) {
            case WireProtocolPacketId.RI_NO_SIZE:
                this.handleBasicRiCommand(view, offset);
                break;
            default:
                Log.d("{57a6a4d}", "{ba4ff6a}", cmd);
                break;
        }
    }

    private handleBasicRiCommand(view: DataView, offset: number) {
        let riCmd = view.getUint32(offset + 0, true);
        switch (riCmd) {
            case PacketId.PACKET_HAPTICS_EVENT:
                this.handleVibrationCommand(view, offset + 4);
                break;
            default:
                Log.w("{57a6a4d}", "{25be6a3}", riCmd);
                break;
        }
    }

    private handleVibrationCommand(view: DataView, offset: number) {
        // First byte is a HapticsCommand command - 0==NOOP, 1==SIMPLE, 2==DURATION
        // TODO: Extend this or fix it so that non-haptics data could be sent as well.
        const packetId = view.getUint16(offset + 0, true);
        if (packetId == HapticsCommands.HAPTICS_SIMPLE) {
            // Simple haptics command, no duration.
            const size = view.getUint16(offset + 2, true);
            if (size < 6) {
                Log.e("{57a6a4d}", "{1527181}", size);
                return;
            } else if (size > 6) {
                Log.w("{57a6a4d}", "{78db114}", size);
            }
            const index = view.getUint16(offset + 4, true);
            const lMotorSpeed = view.getUint16(offset + 6, true);
            const rMotorSpeed = view.getUint16(offset + 8, true);
            for (const vibrationHandler of this.vibrationHandlers) {
                vibrationHandler.handleSimpleVibration(index, lMotorSpeed, rMotorSpeed);
            }
        } else {
            // Note: Server code currently doesn't use anything except HAPTICS_SIMPLE for haptics.
            Log.w("{57a6a4d}", "{c7cf6da}", packetId);
        }
    }

    public sendMouseDown(button: number, ts: number /*ms*/) {
        const packet = this.getTempPacket(18);
        packet.setUint32(0, PacketId.PACKET_MOUSEDOWN, true); // little endian format.
        packet.setUint8(4, button + 1); //JS button order directly map with NVST, except that NVSTbuttons starts with value 1.
        packet.setUint8(5, 0);
        packet.setUint32(6, 0);
        setUint64(ts, packet, 10, false, 1000);

        try {
            this.sendInput(packet);
        } catch (exp) {
            let msg = "send mousedown exception";
            Log.e("{57a6a4d}", "{b258856}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }

    public sendMouseUp(button: number, ts: number /*ms*/) {
        const packet = this.getTempPacket(18);
        packet.setUint32(0, PacketId.PACKET_MOUSEUP, true); // little endian format.
        packet.setUint8(4, button + 1);
        packet.setUint8(5, 0);
        packet.setUint32(6, 0);
        setUint64(ts, packet, 10, false, 1000);

        try {
            this.sendInput(packet);
        } catch (exp) {
            let msg = "send mouseup exception";
            Log.e("{57a6a4d}", "{c1ae1fc}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }

    public sendMouseWheel(deltaY: number, ts: number /*ms*/) {
        const packet = this.getTempPacket(22);
        packet.setUint32(0, PacketId.PACKET_MOUSEWHEEL, true); // little endian format.
        packet.setUint16(4, 0, false);
        /* The values sent by browser is of opposite sign compared to what native clients receive. */
        packet.setUint16(6, -deltaY, false);
        packet.setUint16(8, 0, false);
        packet.setUint32(10, 0);
        setUint64(ts, packet, 14, false, 1000);

        try {
            this.sendInput(packet);
        } catch (exp) {
            let msg = "send mousewheel exception";
            Log.e("{57a6a4d}", "{3d81a57}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }
    // Input coordinates must be pre-scaled to account for the session size:
    //
    // Absolute coordinates are scaled such that events at the bottom-right of the
    //  client session area are at the maximum X resolution and maximum Y resolution values.
    public sendCursorPos(absolute: boolean, x: number, y: number, ts: number = 0) {
        const packetSize = getMoveEventSize(absolute);
        const packet = this.getTempPacket(packetSize);

        this.fillMovePacket(packet, 0, absolute, x, y, ts);
        try {
            if (this.channelOpen()) {
                this.sendInput(packet);
            }
        } catch (exp) {
            let msg = "send CursorPos exception";
            Log.e("{57a6a4d}", "{dba1265}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }

    /// Returns the index of the first byte after the header.
    private fillMouseGroupHeader(
        view: DataView,
        index: number,
        totalSize: number,
        callbackTimestamp: number /*ms*/,
        sendTimestamp: number /*ms*/
    ) {
        view.setUint8(index + 0, WireProtocolPacketId.MOUSE_GROUP);
        view.setUint16(index + 1, totalSize, false);

        setUint64(sendTimestamp, view, index + 3, false, 1000);
        setUint64(callbackTimestamp, view, index + 11, false, 1000);
        return index + MOUSE_GROUP_HEADER_LEN;
    }

    /// Returns the index of the first byte after the mouse group packet.
    private fillMouseGroupPacketV2(
        view: DataView,
        packetIndex: number,
        firstIndex: number,
        sendTimestampMs: number
    ): number {
        const firstEvent: MoveEvent = this.moveEventBuffer.moveEvents[firstIndex];
        const groupStartIndex = packetIndex;
        // Skip the header for now. We'll write it last once we know the total size of all events.
        packetIndex += MOUSE_GROUP_HEADER_LEN;
        for (let i = 0; i < firstEvent.groupSize; ++i) {
            const moveEvent: MoveEvent = this.moveEventBuffer.moveEvents[firstIndex + i];
            view.setUint8(packetIndex, getMoveEventSize(moveEvent.absPos));
            packetIndex++;
            packetIndex = this.fillMovePacket(
                view,
                packetIndex,
                moveEvent.absPos,
                moveEvent.x,
                moveEvent.y,
                moveEvent.captureTimestamp
            );
        }
        const groupTotalSize = packetIndex - groupStartIndex;
        this.fillMouseGroupHeader(
            view,
            groupStartIndex,
            groupTotalSize,
            firstEvent.callbackTimestamp,
            sendTimestampMs
        );
        return packetIndex;
    }

    /// Returns the index of the first byte after the packet.
    private fillMovePacket(
        view: DataView,
        index: number,
        absolute: boolean,
        x: number,
        y: number,
        ts: number = 0 /*ms*/
    ): number {
        let sessionWidth = this.videoState.displayVideoWidth;
        let sessionHeight = this.videoState.displayVideoHeight;

        let packetype = PacketId.PACKET_MOUSEMOVE_REL;
        if (absolute) {
            packetype = PacketId.PACKET_MOUSEMOVE_ABS;
        }

        let videoDimensionOffset = 0;
        if (packetype == PacketId.PACKET_MOUSEMOVE_ABS) {
            videoDimensionOffset = 4;
        }

        view.setUint32(index + 0, packetype, true); // little endian format.
        view.setUint16(index + 4, x, false);
        view.setUint16(index + 6, y, false);
        view.setUint16(index + 8, 0, false); // modifier flags
        view.setUint32(index + 10 + videoDimensionOffset, 0, false); // window handle
        setUint64(ts, view, index + 14 + videoDimensionOffset, false, 1000);
        if (videoDimensionOffset) {
            view.setUint16(index + 10, sessionWidth, false);
            view.setUint16(index + 12, sessionHeight, false);
        }
        return index + getMoveEventSize(absolute);
    }

    public sendKeyboardEvent(
        packetId: number,
        keyCode: number,
        flags: number,
        ts: number = 0 /*ms*/
    ) {
        if (packetId !== PacketId.PACKET_KEYUP && packetId !== PacketId.PACKET_KEYDOWN) {
            Log.e("{57a6a4d}", "{66e269d}", packetId);
            return;
        }

        const packet = this.getTempPacket(18);
        packet.setUint32(0, packetId, true); // little endian format.
        packet.setUint16(4, keyCode, false); // big endian format
        packet.setUint16(6, flags, false);
        packet.setUint16(8, 0, false);
        setUint64(ts, packet, 10, false, 1000);

        this.sendInput(packet);
    }

    public sendHeartbeatEvent() {
        const packet = this.getTempPacket(10);
        packet.setUint32(0, PacketId.PACKET_HEARTBEAT, true);
        try {
            this.inputChannel.send(packet);
            Log.d("{57a6a4d}", "{5693344}");
        } catch (exp) {
            let msg = "heartbeat exception";
            Log.e("{57a6a4d}", "{ab52f04}", exp);
            this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
        }
    }

    /// Sends specified input buffer to the server-side
    /// If available, scheduled packets are prepended to the packet.
    /// Allow an undefined/missing buffer to trigger sending of scheduled packets
    private sendInput(buffer?: DataView, prependTimestamp: boolean = true): boolean {
        if (this.hasScheduledPackets()) {
            buffer = this.prependScheduledMoves(buffer);
        }
        if (buffer === undefined) {
            return false;
        }

        const before = window.performance.now();
        if (this.inputHandler.isUserIdleTimeoutPending) {
            this.inputHandler.clearIdleTimeout();
            return false;
        }

        let result = false;
        if (this._protocolVersion > 2 && prependTimestamp) {
            if (buffer.byteOffset < SENT_TIMESTAMP_LEN) {
                Log.w("{57a6a4d}", "{0fc6121}");
            } else {
                buffer = new DataView(
                    buffer.buffer,
                    buffer.byteOffset - SENT_TIMESTAMP_LEN,
                    SENT_TIMESTAMP_LEN + buffer.byteLength
                );
                buffer.setUint8(0, WireProtocolPacketId.SENT_TIMESTAMP);
                setUint64(performance.now(), buffer, 1, false, 1000);
            }
        }

        try {
            let t0 = performance.now();
            this.inputChannel.send(buffer);
            let t1 = performance.now();
            this.streamClient.updateDcTimeDuration(t1 - t0);
            const after = window.performance.now();
            this.measurements.sendInputCount += 1;
            const value = after - before;
            if (value > 5) {
                this.measurements.sendInputOver5ms += 1;
            }
            if (value > 10) {
                this.measurements.sendInputOver10ms += 1;
            }
            result = true;
        } catch (exp) {
            if (exp.stack && !exp.stack.includes("Could not send data")) {
                //@todo evaluate telemetry and fail the session if aaplicable
                let msg = "sendinput exception";
                Log.e("{57a6a4d}", "{7a6bf7c}", exp);
                // only emit exceptions when the channel is open
                if (this.channelOpen()) {
                    this.telemetry.emitExceptionEvent(exp, msg, "{57a6a4d}.ts", 0, 0, true);
                }
            }
        }
        return result;
    }

    private getScheduledPacketSizeV2(): number {
        let totalSize = 1;
        let i = 0;
        while (i < this.moveEventBuffer.moveEventIndex) {
            totalSize += 2;
            const groupSize = this.moveEventBuffer.moveEvents[i].groupSize;
            if (groupSize > 0) {
                totalSize += MOUSE_GROUP_HEADER_LEN;
                for (let groupIndex = 0; groupIndex < groupSize; groupIndex++) {
                    totalSize +=
                        1 +
                        getMoveEventSize(this.moveEventBuffer.moveEvents[i + groupIndex].absPos);
                }
                i += groupSize;
            } else {
                totalSize += getMoveEventSize(this.moveEventBuffer.moveEvents[i].absPos);
                i++;
            }
        }
        return totalSize;
    }

    private getScheduledPacketSizeV3(): number {
        let totalSize = 0;
        let groupSize = -1;
        const packetPrefixSize = 3; // id + size
        for (let i = 0; i < this.moveEventBuffer.moveEventIndex; i++) {
            if (this.moveEventBuffer.moveEvents[i].groupSize > 0) {
                totalSize += CHROME_CALLBACK_LEN;
                groupSize = this.moveEventBuffer.moveEvents[i].groupSize;
            } else if (groupSize === 0) {
                // workaround to end mouse group
                totalSize += CHROME_CALLBACK_LEN;
            }
            totalSize +=
                packetPrefixSize + getMoveEventSize(this.moveEventBuffer.moveEvents[i].absPos);
            groupSize--;
        }
        return totalSize;
    }

    public hasScheduledPackets(): boolean {
        return this.moveEventBuffer.moveEventIndex > 0;
    }

    public timeScheduledPackets(delay: number) {
        this.sendTimerId = window.setTimeout(() => {
            this.sendTimerId = 0;
            this.sendScheduledPackets();
        }, delay);
    }

    /// Sends all scheduled packets.
    public sendScheduledPackets() {
        this.sendInput();
    }

    private prependScheduledMovesV2(extraPacket?: DataView): DataView {
        if (this.sendTimerId !== 0) {
            window.clearTimeout(this.sendTimerId);
            this.sendTimerId = 0;
        }
        const now = performance.now();
        this._lastMoveSendTime = now;

        // The resulting packet looks like this:
        // u8: 0xFF (indicating this is an event group)
        // u16: size of next packet
        // bytes: packet
        // u16: size of next packet
        // bytes: packet
        // etc...
        const extraBytes = extraPacket ? 2 + extraPacket.byteLength : 0;
        const totalSize = this.getScheduledPacketSizeV2() + extraBytes;
        let totalPacket: DataView;
        // Try to use our stored move buffer if it will fit our scheduled packet. Otherwise, allocate a larger one.
        // This should not happen frequently since our buffer is able to fit more than 16 mouse events, the max we
        // expect to see in one frame.
        if (totalSize > this.buffer.byteLength) {
            totalPacket = new DataView(new ArrayBuffer(totalSize));
            this.measurements.oversizedEventCount++;
        } else {
            totalPacket = new DataView(this.buffer, 0, totalSize);
        }
        totalPacket.setUint8(0, WireProtocolPacketId.INPUT_GROUP);

        let packetIndex = 1;
        // Use a while loop instead of a for loop because we can handle more than one event
        // in each iteration.
        let i = 0;
        while (i < this.moveEventBuffer.moveEventIndex) {
            const packetSizeIndex = packetIndex;
            packetIndex += 2;
            const moveEvent: MoveEvent = this.moveEventBuffer.moveEvents[i];
            if (moveEvent.groupSize > 0) {
                packetIndex = this.fillMouseGroupPacketV2(totalPacket, packetIndex, i, now);
                i += moveEvent.groupSize;
            } else {
                packetIndex = this.fillMovePacket(
                    totalPacket,
                    packetIndex,
                    moveEvent.absPos,
                    moveEvent.x,
                    moveEvent.y,
                    moveEvent.captureTimestamp
                );
                i++;
            }
            // Write the packet size now that we know how big it was.
            totalPacket.setUint16(packetSizeIndex, packetIndex - packetSizeIndex - 2);
        }
        this.moveEventBuffer.clear();

        if (extraPacket) {
            totalPacket.setUint16(packetIndex, extraPacket.byteLength);
            packetIndex += 2;
            for (let i = 0; i < extraPacket.byteLength; i++) {
                totalPacket.setUint8(packetIndex + i, extraPacket.getUint8(i));
            }
            packetIndex += extraPacket.byteLength;
        }
        return totalPacket;
    }

    private prependScheduledMovesV3(extraPacket?: DataView): DataView {
        if (extraPacket && extraPacket.buffer != this.buffer) {
            // not allowing bulking of scheduled events with packets not created by InputPacketHandler
            this.sendScheduledPackets();
            return extraPacket;
        }

        if (this.sendTimerId !== 0) {
            window.clearTimeout(this.sendTimerId);
            this.sendTimerId = 0;
        }
        this._lastMoveSendTime = performance.now();

        const totalSize = this.getScheduledPacketSizeV3() + (extraPacket?.byteLength ?? 0);
        const necessaryBufferSize = SENT_TIMESTAMP_LEN + totalSize;
        let totalPacket: DataView;
        if (necessaryBufferSize > this.buffer.byteLength) {
            totalPacket = new DataView(
                new ArrayBuffer(necessaryBufferSize),
                necessaryBufferSize - totalSize,
                totalSize
            );
            this.measurements.oversizedEventCount++;
            if (extraPacket) {
                const offset = totalPacket.byteLength - extraPacket.byteLength;
                for (let i = 0; i < extraPacket.byteLength; i++) {
                    totalPacket.setUint8(offset + i, extraPacket.getUint8(i));
                }
            }
        } else {
            totalPacket = new DataView(this.buffer, this.buffer.byteLength - totalSize, totalSize);
        }

        let packetIndex = 0;
        let groupSize = -1;
        for (let i = 0; i < this.moveEventBuffer.moveEventIndex; i++) {
            const moveEvent: MoveEvent = this.moveEventBuffer.moveEvents[i];
            if (moveEvent.groupSize > 0) {
                groupSize = moveEvent.groupSize;
                // write chrome callback ts for the group
                totalPacket.setUint8(packetIndex, WireProtocolPacketId.CHROME_CALLBACK_TIMESTAMP);
                setUint64(moveEvent.callbackTimestamp, totalPacket, packetIndex + 1, false, 1000);
                packetIndex += CHROME_CALLBACK_LEN;
            } else if (groupSize === 0) {
                // workaround to end mouse group count on server
                totalPacket.setUint8(packetIndex, WireProtocolPacketId.CHROME_CALLBACK_TIMESTAMP);
                setUint64(0, totalPacket, packetIndex + 1, false, 1000);
                packetIndex += CHROME_CALLBACK_LEN;
            }

            totalPacket.setUint8(packetIndex, WireProtocolPacketId.RI_PACKET);
            totalPacket.setUint16(packetIndex + 1, getMoveEventSize(moveEvent.absPos));
            packetIndex = this.fillMovePacket(
                totalPacket,
                packetIndex + 3,
                moveEvent.absPos,
                moveEvent.x,
                moveEvent.y,
                moveEvent.captureTimestamp
            );
            groupSize--;
        }
        this.moveEventBuffer.clear();

        return totalPacket;
    }

    /// Returns a temporary DataView of the given length.
    /// This DataView is only valid until the next call to this function.
    private getTempPacketV2(length: number): ShiftedDataView {
        return new ShiftedDataView(this.tempBuffer, 0, length);
    }

    /// Returns a temporary DataView for an RI packet located at the end of the buffer.
    /// This location allows for direct prepending of scheduled events without moving the returned packet.
    /// \todo Only return offset to an overall bufferDV and only create a new DataView
    /// before sending across the network. This way we'll have only one DataView instantiation
    /// per packet no matter what.
    private getTempPacketV3(length: number): ShiftedDataView {
        length = length + 1 /* id */;
        const offset = this.buffer.byteLength - length;
        let dv = new ShiftedDataView(this.buffer, offset, length, 1);
        // the last RI packet always has this id in protocol v3
        this.bufferView.setUint8(offset, WireProtocolPacketId.RI_NO_SIZE);
        return dv;
    }

    public sendTextInput(text: ArrayBuffer) {
        let bytesSent = 0;
        // TODO: This should re-use a buffer instead of creating one every time. It could even use tempBuffer if
        // tempBuffer was increased to MTU size
        const MAX_DATA_LEN = 1016;
        let textView = new DataView(text, 0, text.byteLength);
        let sendBuffer = new ArrayBuffer(MAX_DATA_LEN + 5);
        while (bytesSent < text.byteLength) {
            let textChunkToSend = 0;
            if (text.byteLength - bytesSent <= MAX_DATA_LEN) {
                textChunkToSend = text.byteLength - bytesSent;
            } else {
                textChunkToSend = bytesSent + MAX_DATA_LEN;
                let foundCodePoint = false;
                //utf-8 code can be of 4 bytes max
                for (let i = 0; i < 4; i++) {
                    if ((textView.getUint8(textChunkToSend) & 0xc0) != 0x80) {
                        foundCodePoint = true;
                        break;
                    } else {
                        textChunkToSend--; // Unrecognized lead byte
                    }
                }

                if (foundCodePoint) {
                    textChunkToSend -= bytesSent;
                } else {
                    Log.e("{57a6a4d}", "{938b0d3}");
                    break;
                }
            }
            let sendBufferView = new DataView(sendBuffer, 0, textChunkToSend + 5);
            sendBufferView.setUint8(0, WireProtocolPacketId.RI_NO_SIZE);
            sendBufferView.setUint32(1, PacketId.PACKET_UNICODE, true); // little endian format.
            new Uint8Array(sendBuffer).set(new Uint8Array(text, bytesSent, textChunkToSend), 5);
            bytesSent += textChunkToSend;
            this.sendInput(sendBufferView, false);
        }
    }

    public channelOpen(): boolean {
        return this.inputChannel.readyState === "open";
    }

    public stop() {
        if (this.inputChannel.bufferedAmount > 0) {
            Log.w("{57a6a4d}", "{5e5b9e5}", this.inputChannel.bufferedAmount);
        }
    }

    public get protocolVersion(): number {
        return this._protocolVersion;
    }

    public get lastMoveSendTime(): number {
        return this._lastMoveSendTime;
    }

    private toHexString(buffer: ArrayBuffer) {
        let byteArray = new Uint8Array(buffer);
        return Array.prototype.map
            .call(byteArray, function (byte: number) {
                return ("0" + (byte & 0xff).toString(16)).slice(-2);
            })
            .join("");
    }

    private populateMultiGamepadPacket(
        packet: DataView,
        offset: number,
        index: number,
        buttons: number,
        trigger: number,
        axes: readonly number[],
        ts: number = 0 /*ms*/,
        gamepadBitmap: number = 0
    ) {
        packet.setUint32(offset, PacketId.PACKET_GAMEPAD_MULTIUSER, true);
        packet.setInt16(offset + 4, 0x001a, true);

        packet.setInt16(offset + 6, index, true); // gamepad index
        packet.setInt16(offset + 8, gamepadBitmap, true); // gamepads bitmap

        packet.setInt16(offset + 6 + 4, 0x0014, true);
        packet.setInt16(offset + 6 + 6, buttons, true);
        packet.setInt16(offset + 6 + 8, trigger, true);

        // Axes in NVST procotol are:
        //  1ittle-endian signed 16bit value, -ve left, -ve bottom
        //  16-17 - left stick X
        //  18-19 - left stick Y
        //  20-21 - right stick X
        //  12-23 - right stick Y
        packet.setInt16(offset + 6 + 10, Math.round((axes[0] + 1.0) * 32767.5) - 32768, true);
        packet.setInt16(offset + 6 + 12, Math.round((-axes[1] + 1.0) * 32767.5) - 32768, true);
        packet.setInt16(offset + 6 + 14, Math.round((axes[2] + 1.0) * 32767.5) - 32768, true);
        packet.setInt16(offset + 6 + 16, Math.round((-axes[3] + 1.0) * 32767.5) - 32768, true);
        packet.setInt16(offset + 6 + 18, 0, true);
        packet.setInt16(offset + 6 + 20, 0x0055, true);
        packet.setInt16(offset + 6 + 22, 0x0000, true);
        setUint64(ts, packet, offset + 6 + 24, true, 1000);

        // Log.d("{57a6a4d}", "{0b0c6f9}", this.toHexString(inputPacket));
    }

    private getMultiGamepadPacket(
        buttons: number,
        trigger: number,
        index: number,
        axes: readonly number[],
        ts: number = 0,
        gamepadBitmap: number = 0
    ): DataView {
        const packet = this.getTempPacket(GAMEPAD_PACKET_SIZE);
        this.populateMultiGamepadPacket(
            packet,
            0,
            index,
            buttons,
            trigger,
            axes,
            ts,
            gamepadBitmap
        );
        return packet;
    }

    public gamepadBitmapUpdateHandler(gamepadBitmap: number) {
        const packet = this.getMultiGamepadPacket(
            0,
            0,
            GamepadControlIndex,
            {} as number[],
            0,
            gamepadBitmap
        );
        this.sendInput(packet);
    }

    public gamepadStateUpdateHandler(
        count: number,
        index: number,
        buttons: number,
        trigger: number,
        axes: readonly number[],
        ts: number = 0,
        gamepadBitmap: number
    ) {
        if (this._protocolVersion > 2) {
            const mult = GAMEPAD_PACKET_SIZE + 3; // wire protocol id + size + content
            const offset = SENT_TIMESTAMP_LEN + mult * count;
            this.gamepadBufferView.setUint8(offset, WireProtocolPacketId.RI_PACKET);
            this.gamepadBufferView.setUint16(offset + 1, GAMEPAD_PACKET_SIZE);
            this.populateMultiGamepadPacket(
                this.gamepadBufferView,
                offset + 3,
                index,
                buttons,
                trigger,
                axes,
                ts,
                gamepadBitmap
            );
        } else if (this._protocolVersion == 2) {
            const mult = GAMEPAD_PACKET_SIZE + 2; // size of gamepad data + 2 for bytecount
            const offset = 1 + mult * count;

            this.gamepadBufferView.setUint16(offset, GAMEPAD_PACKET_SIZE);
            this.populateMultiGamepadPacket(
                this.gamepadBufferView,
                offset + 2,
                index,
                buttons,
                trigger,
                axes,
                ts,
                gamepadBitmap
            );
        } else {
            const packet = this.getMultiGamepadPacket(
                buttons,
                trigger,
                index,
                axes,
                ts,
                gamepadBitmap
            );
            this.sendInput(packet);
        }
    }

    public virtualGamepadUpdateHandler(
        buttons: number,
        trigger: number,
        index: number,
        axes: readonly number[],
        gamepadBitmap: number = 0
    ) {
        const packet = this.getMultiGamepadPacket(buttons, trigger, index, axes, 0, gamepadBitmap);
        this.sendInput(packet);
    }

    public finalizeGamepadData(count: number) {
        // Only need to send data for newer protocols; packets will have been sent already
        // for older protocols
        if (this._protocolVersion < 2) return;

        // Cannot yet group moves and multiple extra packets
        this.sendScheduledPackets();
        let totalPacketView = null;

        if (this._protocolVersion > 2) {
            const mult = GAMEPAD_PACKET_SIZE + 3;
            totalPacketView = new DataView(
                this.gamepadBufferView.buffer,
                SENT_TIMESTAMP_LEN,
                count * mult
            );
        } else if (this._protocolVersion == 2) {
            this.gamepadBufferView.setUint8(0, WireProtocolPacketId.INPUT_GROUP);
            const mult = GAMEPAD_PACKET_SIZE + 2;
            totalPacketView = new DataView(this.gamepadBufferView.buffer, 0, 1 + count * mult);
        }

        if (totalPacketView) this.sendInput(totalPacketView);
    }

    public connectUnsupportedGamepad(gamepad: Gamepad) {}

    public disconnectUnsupportedGamepad(index: number) {}

    public sendGamepadHapticsControl(enable: boolean) {
        const packet = this.getTempPacket(6);
        packet.setUint32(0, PacketId.PACKET_HAPTICS_CONTROL, true);
        packet.setUint16(4, enable ? 1 : 0, false);
        this.sendInput(packet);
    }

    public addTouchEvent(
        idx: number,
        id: number,
        touchType: TouchType,
        x: number,
        y: number,
        radiusX: number,
        radiusY: number,
        ts: number /*ms*/
    ) {
        if (idx >= MAX_TOUCH_COUNT) {
            return false;
        }
        const offset = TOUCH_PACKET_OFFSET + TOUCH_HEADER_LENGTH + idx * TOUCH_RECORD_LENGTH;
        this.touchBufferView.setUint8(offset + 0, id);
        this.touchBufferView.setUint8(offset + 1, touchType);
        this.touchBufferView.setUint16(offset + 2, x, false);
        this.touchBufferView.setUint16(offset + 4, y, false);
        this.touchBufferView.setUint8(offset + 6, radiusX);
        this.touchBufferView.setUint8(offset + 7, radiusY);
        setUint64(ts, this.touchBufferView, offset + 8, false, 1000);
        return true;
    }

    public sendTouchPacket(count: number): boolean {
        let size = TOUCH_HEADER_LENGTH + count * TOUCH_RECORD_LENGTH;
        this.touchBufferView.setUint16(TOUCH_PACKET_OFFSET + 4, size, false);
        this.touchBufferView.setUint16(TOUCH_PACKET_OFFSET + 6, count, false);
        let offset = TOUCH_PACKET_OFFSET;
        if (this._protocolVersion >= 3) {
            // add WireProtocolPacketId.RI_NO_SIZE to the DataView
            // (it's already present in the buffer)
            size++;
            offset--;
        }
        const totalPacketView = new DataView(this.touchBufferView.buffer, offset, size);

        // Don't take any chance that PACKET_TOUCH_LOW_LEVEL is too big to combine with
        // other sent data (such as grouped mouse moves)
        this.sendScheduledPackets();

        return this.sendInput(totalPacketView);
    }

    public addVibrationHandler(vibrationHandler: VibrationHandler) {
        this.vibrationHandlers.push(vibrationHandler);
    }

    public removeVibrationHandler(vibrationHandler: VibrationHandler) {
        const index = this.vibrationHandlers.indexOf(vibrationHandler);
        if (index > -1) {
            this.vibrationHandlers.splice(index, 1);
        }
    }
}

function getMoveEventSize(absolute: boolean) {
    return absolute ? 26 : 22;
}

export class MoveEventBuffer {
    private _moveEvents: MoveEvent[];
    public supportsGrouping: boolean = false;
    private _moveEventIndex: number = 0;

    constructor(bufferSize: number) {
        this._moveEvents = new Array(bufferSize);
        for (let i = 0; i < this.moveEvents.length; i++) {
            this._moveEvents[i] = {
                absPos: false,
                x: 0,
                y: 0,
                captureTimestamp: 0,
                groupSize: 0,
                callbackTimestamp: 0
            };
        }
    }

    public get moveEvents(): ReadonlyArray<MoveEvent> {
        return this._moveEvents as ReadonlyArray<MoveEvent>;
    }

    public get moveEventIndex(): number {
        return this._moveEventIndex;
    }

    public clear() {
        this._moveEventIndex = 0;
    }

    public setGroupSize(idx: number, groupSize: number) {
        this._moveEvents[idx].groupSize = groupSize;
    }

    public addMoveEvent(
        absolute: boolean,
        x: number,
        y: number,
        captureTimestamp: number,
        groupSize: number,
        callbackTimestamp: number,
        aggregate?: boolean
    ) {
        if (aggregate && this._moveEventIndex > 0) {
            const target = this._moveEvents[this._moveEventIndex - 1];
            // Make sure we can actually aggregate these events.
            if (target.absPos === absolute && target.callbackTimestamp === callbackTimestamp) {
                if (absolute) {
                    target.x = x;
                    target.y = y;
                } else {
                    target.x += x;
                    target.y += y;
                }
                return;
            }
            // If we didn't aggregate, handle the event normally.
        }

        const actualGroupSize = this.supportsGrouping ? groupSize : 0;
        if (this._moveEventIndex == this._moveEvents.length) {
            this._moveEvents.push({
                absPos: absolute,
                x: x,
                y: y,
                captureTimestamp: captureTimestamp,
                groupSize: actualGroupSize,
                callbackTimestamp: callbackTimestamp
            });
            this._moveEventIndex++;
        } else {
            const target = this._moveEvents[this._moveEventIndex++];
            target.absPos = absolute;
            target.x = x;
            target.y = y;
            target.captureTimestamp = captureTimestamp;
            target.groupSize = actualGroupSize;
            target.callbackTimestamp = callbackTimestamp;
        }
    }
}
