import { CustomMessage } from "./interfaces";
import { EdgeInsets, GetSafeAreas, IsEqualShallow, GetLogicalResolution } from "./util/utils";
import { TelemetryHandler } from "./telemetry/telemetryhandler";
import { RagnarokSettings } from "./util/settings";
import { PlatformDetails, IsiDevice, Log, IsAndroidOS } from "./dependencies";

const LOGTAG = "safezonehandler";

export declare interface MessageSender {
    sendCustomMessage(message: CustomMessage): void;
}

const MAX_TELEMETRY_EVENT_COUNT = 2;

export class SafeZoneHandler {
    private safeZone: EdgeInsets = {
        top: -1,
        left: -1,
        bottom: -1,
        right: -1
    };
    private topPadding: number = 0;
    private leftPadding: number = 0;
    private showSafeZone: boolean = false;
    private debugWindow?: HTMLDivElement;
    private telemetryEventCount: number = 0;

    constructor(
        private sender: MessageSender,
        private videoElement: HTMLVideoElement,
        private platformDetails: PlatformDetails,
        private telemetry?: TelemetryHandler
    ) {
        if (RagnarokSettings.isInternalUser) {
            this.debugWindow = this.createDebugWindow();
            videoElement.insertAdjacentElement("afterend", this.debugWindow);
        }
    }

    public uninitialize() {
        if (this.debugWindow) {
            this.debugWindow.remove();
        }
    }

    /**
     * @param topPadding top padding of video element
     * @param leftPadding left padding of video element
     * Provide information regarding the video's current state.
     * Should be invoked whenever video state is updated, which accounts for resize and orientation change events.
     */
    public updateVideoState(topPadding: number, leftPadding: number): void {
        this.topPadding = topPadding;
        this.leftPadding = leftPadding;
    }

    /**
     * Calculates safe area inset values and sends updated insets over custom message.
     */
    public send(): void {
        if (this.videoElement.videoHeight <= 0 || this.videoElement.videoWidth <= 0) {
            // Skip update if no video content yet
            return;
        }

        // insets in CSS pixels
        const insets = GetSafeAreas();
        const safeZone = this.getSafeZone(insets);

        if (IsEqualShallow(safeZone, this.safeZone)) {
            // Skip update if insets have not changed
            return;
        }

        const normalizedStr = JSON.stringify(safeZone, (_, v) => {
            return v.toFixed ? Number(v.toFixed(2)) : v;
        });
        Log.d("{48f82fd}", "{054b8b0}", JSON.stringify(insets), normalizedStr);

        if (
            (RagnarokSettings.ragnarokConfig.sendInsetValueUpdateEvents ?? false) &&
            this.telemetryEventCount++ < MAX_TELEMETRY_EVENT_COUNT
        ) {
            // Round to nearest integer, replacing NaNs with -1
            const roundToInt = (obj: Object, multFactor: number = 1): Object => {
                for (const key of Object.keys(obj)) {
                    const val = Math.round(obj[key] * multFactor);
                    obj[key] = isNaN(val) ? -1 : val;
                }
                return obj;
            };
            const insetsTelemetry = <EdgeInsets>roundToInt(Object.assign({}, insets));
            // Since normalized values are in the range [0,1] but the schema mandates int values, multiply by 1000
            const safeZoneTelemetry = <EdgeInsets>roundToInt(Object.assign({}, safeZone), 1000);

            this.telemetry?.emitMetricEvent(
                "InsetValueUpdate",
                "Original",
                insetsTelemetry.top,
                insetsTelemetry.left,
                insetsTelemetry.bottom,
                insetsTelemetry.right
            );
            this.telemetry?.emitMetricEvent(
                "InsetValueUpdate",
                "Normalized",
                safeZoneTelemetry.top,
                safeZoneTelemetry.left,
                safeZoneTelemetry.bottom,
                safeZoneTelemetry.right
            );
        }

        this.safeZone = Object.assign({}, safeZone);
        for (const key of Object.keys(safeZone)) {
            safeZone[key] = isNaN(safeZone[key]) ? 0 : safeZone[key];
        }
        const data = {
            "safeZoneData": {
                "type": "InsetValueUpdate",
                "rect": safeZone
            }
        };
        const message: CustomMessage = {
            messageType: "SAFE_ZONE",
            messageRecipient: "NvGridSvc:NGS",
            data: JSON.stringify(data)
        };

        this.sender.sendCustomMessage(message);
        if (RagnarokSettings.isInternalUser) {
            this.updateDebugWindow();
        }
    }

    /**
     * Toggles whether safe zone inset values are displayed on screen.
     * For debugging purposes only.  Not intended for production use.
     */
    public toggleDisplaySafeZone(): void {
        if (!this.debugWindow) {
            return;
        }
        this.showSafeZone = !this.showSafeZone;
        this.debugWindow.style.display = this.showSafeZone ? "block" : "none";
    }

    /**
     * Returns normalized inset values relative to displayed video
     * This is the rect within the video view unobscured by notches and bars.
     */
    private getSafeZone(insets: EdgeInsets): EdgeInsets {
        let { width, height } = GetLogicalResolution();

        const isScreenNaturalOrientation = (): boolean => {
            let angle;
            if (window.orientation !== undefined) {
                angle = window.orientation;
            } else if (screen.orientation) {
                angle = screen.orientation.angle;
            }
            switch (angle) {
                case 90:
                case -90:
                case 270:
                    return false;
                case 0:
                case 180:
                default:
                    return true;
            }
        };

        // On iOS/iPadOS, adjust screen dimensions for directionality because dimensions based on device's natural orientation
        // On other platforms, screen dimensions  adjust to account for the current orientation
        if (IsiDevice(this.platformDetails) && !isScreenNaturalOrientation()) {
            const _width = width;
            width = height;
            height = _width;
        }

        const getOffsets = (
            element: HTMLElement
        ): {
            top: number;
            left: number;
            bottom: number;
            right: number;
        } => {
            let top = element.offsetTop;
            let left = element.offsetLeft;
            let parent = element.offsetParent;
            while (parent) {
                top += (parent as HTMLElement).offsetTop;
                left += (parent as HTMLElement).offsetLeft;
                parent = (parent as HTMLElement).offsetParent;
            }

            let clientHeight = element.clientHeight;
            let clientWidth = element.clientWidth;

            // On Android devices, if after an orientation change the window's innerHeight/Width correctly reflect the orientation change but
            // the video element's clientWidth/Height correspond to the previous orientation, flip the clientHeight and clientWidth.
            // This has been observed exclusively on Android when handling resize events with a timeout delay.
            if (
                IsAndroidOS(this.platformDetails) &&
                ((window.innerHeight > window.innerWidth &&
                    element.clientHeight < element.clientWidth) ||
                    (window.innerHeight < window.innerWidth &&
                        element.clientHeight > element.clientWidth))
            ) {
                Log.w("{48f82fd}", "{c9d343e}");
                clientHeight = element.clientWidth;
                clientWidth = element.clientHeight;
            }
            return {
                top: top,
                left: left,
                bottom: Math.max(window.innerHeight - top - clientHeight, 0),
                right: Math.max(window.innerWidth - left - clientWidth, 0)
            };
        };
        // offsets of video element relative to layout viewport
        const offsets = getOffsets(this.videoElement);

        // TODO: Determine why on some Android devices, window.innerHeight/innerWidth is observed to be larger than screen.height/width
        //       As a WAR for this, set lower bound to 0
        const screenMinusViewportTopPadding = Math.max(height - window.innerHeight, 0) / 2;
        const screenMinusViewportLeftPadding = Math.max(width - window.innerWidth, 0) / 2;

        // express insets as percentage of displayed video
        // account for difference between screen and viewport dimensions
        // account for video element offsets
        // account for padding generated by sever-side scaling
        const safeZone = {
            top:
                Math.max(
                    insets.top - screenMinusViewportTopPadding - offsets.top - this.topPadding,
                    0
                ) /
                (this.videoElement.clientHeight - this.topPadding * 2),
            left:
                Math.max(
                    insets.left - screenMinusViewportLeftPadding - offsets.left - this.leftPadding,
                    0
                ) /
                (this.videoElement.clientWidth - this.leftPadding * 2),
            bottom:
                Math.max(
                    insets.bottom -
                        screenMinusViewportTopPadding -
                        offsets.bottom -
                        this.topPadding,
                    0
                ) /
                (this.videoElement.clientHeight - this.topPadding * 2),
            right:
                Math.max(
                    insets.right -
                        screenMinusViewportLeftPadding -
                        offsets.right -
                        this.leftPadding,
                    0
                ) /
                (this.videoElement.clientWidth - this.leftPadding * 2)
        };

        return safeZone;
    }

    private createDebugWindow(): HTMLDivElement {
        const div = document.createElement("div");
        div.style.display = "none";
        div.style.position = "absolute";
        div.style.color = "white";
        div.style.backgroundColor = "rgb(105, 105, 105, 0.7)";
        div.style.padding = "2px";
        div.style.bottom = "0px";
        div.style.left = "0px";
        div.style.margin = "5px";
        return div;
    }

    private updateDebugWindow(): void {
        if (this.debugWindow) {
            this.debugWindow.innerText =
                "Safe Zone: \nTop: " +
                (Math.max(this.safeZone.top, 0) * 100).toFixed() +
                "%\nRight: " +
                (Math.max(this.safeZone.right, 0) * 100).toFixed() +
                "%\nBottom: " +
                (Math.max(this.safeZone.bottom, 0) * 100).toFixed() +
                "%\nLeft: " +
                (Math.max(this.safeZone.left, 0) * 100).toFixed() +
                "%";
        }
    }
}
