export type SDPBlob = string;
export type SDPLine = string;
export type SDPSection = string;
export interface ISDPIceParameters {
    iceLite?: boolean;
    usernameFragment: string;
    password: string;
}

export interface ISDPFingerprint {
    algorithm: string;
    value: string;
}

export interface ISDPDtlsParameters {
    role: string;
    fingerprints: ISDPFingerprint[];
}

export class SDPUtils {
    // Splits SDP into lines, dealing with both CRLF and LF.
    static SplitLines(blob: SDPBlob): SDPLine[] {
        return blob
            .trim()
            .split("\n")
            .map(line => line.trim());
    }

    // Splits SDP into sessionpart and mediasections. Ensures CRLF.
    static SplitSections(blob: SDPBlob): SDPSection[] {
        const parts = blob.split("\nm=");
        return parts.map((part, index) => (index > 0 ? "m=" + part : part).trim() + "\r\n");
    }

    // Returns the session description.
    static GetDescription(blob: SDPBlob): SDPSection {
        const sections = SDPUtils.SplitSections(blob);
        return sections && sections[0];
    }

    // Returns the individual media sections.
    static GetMediaSections(blob: SDPBlob): SDPSection[] {
        const sections = SDPUtils.SplitSections(blob);
        sections.shift();
        return sections;
    }

    // Returns lines that start with a certain prefix.
    static MatchPrefix(blob: SDPBlob, prefix: string): SDPLine[] {
        return SDPUtils.SplitLines(blob).filter(line => line.indexOf(prefix) === 0);
    }

    // Parses a fingerprint line for DTLS-SRTP.
    static ParseFingerprint(line: SDPLine): ISDPFingerprint {
        const parts = line.substr(14).split(" ");
        return {
            algorithm: parts[0].toLowerCase(), // algorithm is case-sensitive in Edge.
            value: parts[1].toUpperCase() // the definition is upper-case in RFC 4572.
        };
    }
    // Extracts DTLS parameters from SDP media section or sessionpart.
    // FIXME: for consistency with other functions this should only
    //   get the fingerprint line as input. See also getIceParameters.
    static GetDtlsParameters(
        mediaSection: SDPSection,
        sessionpart: SDPSection
    ): ISDPDtlsParameters {
        const lines = SDPUtils.MatchPrefix(mediaSection + sessionpart, "a=fingerprint:");
        // Note: a=setup line is ignored since we use the 'auto' role in Edge.
        return {
            role: "auto",
            fingerprints: lines.map(SDPUtils.ParseFingerprint)
        };
    }
    // Parses ICE information from SDP media section or sessionpart.
    // FIXME: for consistency with other functions this should only
    //   get the ice-ufrag and ice-pwd lines as input.
    static GetIceParameters(mediaSection: SDPSection, sessionpart: SDPSection): ISDPIceParameters {
        const ufrag = SDPUtils.MatchPrefix(mediaSection + sessionpart, "a=ice-ufrag:")[0];
        const pwd = SDPUtils.MatchPrefix(mediaSection + sessionpart, "a=ice-pwd:")[0];
        /*
        if (!(ufrag && pwd)) {
            return null;
        }
        */
        return {
            usernameFragment: ufrag.substr(12),
            password: pwd.substr(10)
        };
    }
}
