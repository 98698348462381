/**
 * @fileoverview added by tsickle
 * Generated from: ragnarok-core/output/ragnarokprofiler.ts
 * @suppress {checkTypes,const,extraRequire,missingOverride,missingRequire,missingReturn,unusedPrivateMembers,uselessCode}
 */
goog.module('output.ragnarokprofiler');
var module = module || { id: 'ragnarokprofiler.ts' };
goog.require('tslib');
const tsickle_interfaces_1 = goog.requireType("output.interfaces");
const tsickle_rinterfaces_2 = goog.requireType("output.rinterfaces");
const tsickle_statsinterfaces_3 = goog.requireType("output.stats.statsinterfaces");
const tsickle_dependencies_4 = goog.requireType("output.dependencies");
const tsickle_telemetryhandler_5 = goog.requireType("output.telemetry.telemetryhandler");
const dependencies_1 = goog.require('output.dependencies');
/** @type {string} */
const LOGTAG = "RProfiler";
/** @typedef {!output$ragnarokprofiler.Perf} */
exports.Perf;
/** @typedef {!output$ragnarokprofiler.ClientEvent} */
exports.ClientEvent;
/** @typedef {!output$ragnarokprofiler.WorkerInit} */
exports.WorkerInit;
/** @typedef {!output$ragnarokprofiler.StartStats} */
exports.StartStats;
/** @typedef {!output$ragnarokprofiler.StreamingQualityWithTs} */
exports.StreamingQualityWithTs;
/** @typedef {!output$ragnarokprofiler.StartWebSocket} */
exports.StartWebSocket;
/** @typedef {!output$ragnarokprofiler.MtbDuration} */
exports.MtbDuration;
/** @typedef {!output$ragnarokprofiler.InputChannelStats} */
exports.InputChannelStats;
/** @typedef {!output$ragnarokprofiler.GarbageCollectionStats} */
exports.GarbageCollectionStats;
/** @typedef {!output$ragnarokprofiler.WorkerMessage} */
exports.WorkerMessage;
// In retail builds, the build system will replace the value of this constant with the actual
// source code for the Ragnarok Worker.
// Use a template literal with embedded newline to start the preprocessor directive on a newline,
// and thus not have an indent - the preprocessor would otherwise replicate that indent after
// every newline in the source code, unnecessarily bulking the shipped product.
/** @type {string} */
const WORKER_SRC = `
var p={size:70,version:1,name:"DEPR"},q={size:80,version:3,name:"RTPV"},r={size:48,version:1,name:"RTPA"},t={size:88,version:1,name:"TRAA"},u={size:44,version:1,name:"TRAV"},v={size:12,version:1,name:"VFMD"},aa={size:20,version:1,name:"PFDA"},ba={size:0,version:1,name:""};function w(a,b=!1){try{let d;a.url.includes("wss")&&(d="x-nv-sessionid."+a.sessionId);let f=a.url;b&&(f+="&reconnect=1");let e=new WebSocket(f,d);e.onopen=()=>{a.h("{aaf44dc}");a.l.A();if(e===a.g){for(const c of a.j)c.stats?e.send(c.stats):e.send(JSON.stringify(c));for(const c of a.o)y(a,c);a.o=[]}};e.onclose=c=>{a.h("{7a09ad8}");a.l.v({error:a.u,code:c.code,reason:c.reason,wasClean:c.wasClean});e===a.g&&(a.g=void 0);a.u=!1};e.onerror=()=>{a.h("{418a180}");a.u=!0};e.onmessage=c=>{c=JSON.parse(c.data);
a.serverSupportsAck&&z(a);void 0===c.ackid||a.serverSupportsAck||(a.serverSupportsAck=!0,a.h("{523f5b9}"),z(a));if(!c.hb)if(a.serverSupportsAck){if(void 0!==c.ack){var g=c.ack;for(let k=a.j.length-1;0<=k;k--)a.j[k].ackid<=g&&a.j.splice(k,1)}void 0!==c.ackid?(a.maxReceivedAckId<c.ackid&&(a.l.s(c),a.maxReceivedAckId=c.ackid),a.g&&(c=JSON.stringify({ack:a.maxReceivedAckId}),a.h("{903be10}"+c),a.g.send(c))):void 0===c.ack&&a.l.s(c)}else a.l.s(c)};a.g=e}catch(d){a.g=void 0,a.B("WebSocket creation exception: "+
d)}}function A(a){0!==a.m&&(self.clearTimeout(a.m),a.m=0)}function ca(){var a=C,b;a.maxReceivedAckId=0;a.serverSupportsAck=!1;a.j=[];a.o=[];A(a);null===(b=a.g)||void 0===b?void 0:b.close(void 0);a.h("{986b258}undefined")}function z(a){A(a);a.m=self.setTimeout(()=>{var b;a.h("{6c3505a}"+(null===(b=a.g)||void 0===b?void 0:b.readyState));a.g||w(a,!0);z(a)},3E3)}
function y(a,b){void 0!==b.ackid&&a.serverSupportsAck&&void 0!==b.ackid&&a.j.push(b);a.g&&(b.stats?a.g.send(b.stats):a.g.send(JSON.stringify(b)))}
var ha=class{constructor(){var a={s:da,A:ea,v:fa,C:D},b=E,d=F;this.sessionId=I;this.serverSupportsAck=this.u=!1;this.j=[];this.o=[];this.maxReceivedAckId=this.m=0;this.url="";this.h=b;this.B=d;this.l=a}initialize(a,b,d,f=!1){this.url=a;this.maxReceivedAckId=b;this.serverSupportsAck=d;w(this,f);this.h("{f4b05a4}")}reconnect(){w(this,!0)}send(a){this.serverSupportsAck||this.g||w(this,!0);this.g&&this.g.readyState===WebSocket.OPEN?y(this,a):this.o.push(a)}};const J=self;let K=[],L=[];function ia(a){var b=M;let d=b.g.get(a.type);d||(d=[],b.g.set(a.type,d));for(const f of a.stats)d.push(f)}function ja(a){switch(a){case 0:return p;case 1:return q;case 2:return r;case 3:return u;case 4:return t;case 5:return v;case 6:return aa;default:return ba}}
let M=new class{constructor(){this.g=new Map;this.g.clear()}size(){let a=0;for(let [,b]of this.g){let d=b;for(let f of d)a+=f.byteLength}return a+=9*this.g.size}write(a,b){var d=b;for(let [g,k]of this.g){b=k;var f=d,e=d;d=b.length;var c=ja(g);let l=c.name,n=new DataView(a.buffer);for(let h=0;4>h;h++)n.setUint8(e+h,l.charCodeAt(h));n.setUint8(e+4,c.version);n.setUint16(e+5,d,!0);n.setUint16(e+7,c.size,!0);f=d=f+9;e=a;c=0;for(let h of b)b=new Uint8Array(h),e.set(b,d+c),c+=h.byteLength;d=f+c}return d}},
I="",N="",O=null,C=void 0,P=0,Q=[],R=[],S=[],T=[],U=!1,V=0;function E(a){J.postMessage({log:a})}function F(a){J.postMessage({exception:a})}function W(){K=[];L=[];M.g.clear();Q=[];R=[];S=[];T=[]}function X(a,b,d,f,e,c){for(let g=0;4>g;g++)a.setUint8(b+g,d.charCodeAt(g));a.setUint8(b+4,f);a.setUint16(b+5,e,!0);a.setUint16(b+7,c,!0)}function Y(a){return 0>a?Math.max(a,-2147483648):Math.min(a,4294967295)}
function Z(){if((K.length||L.length)&&O)try{O.ackid=U?V:void 0;var a=JSON.stringify(O),b=a.length,d=b+2,f=new ArrayBuffer(d);const B=new DataView(f);B.setUint16(0,b);for(b=2;b<d;++b)B.setUint8(b,a.charCodeAt(b-2));var e=new Uint8Array(f),c=13*K.length,g=72*L.length,k=12*Q.length,l=10*R.length,n=12*S.length;const G=16*T.length;let x=9+e.length;c&&(x+=9+c);g&&(x+=9+g);k&&(x+=9+k);l&&(x+=9+l);n&&(x+=9+n);G&&(x+=9+G);x+=M.size();const H=new ArrayBuffer(x);var h=new DataView(H);a=0;(new Uint8Array(H)).set(e);
a+=e.length;X(h,a,"BPRF",1,0,0);a+=9;if(c){X(h,a,"PERF",2,K.length,13);a+=9;e=h;d=a;for(f=0;f<K.length;f++)e.setFloat64(d,K[f].RAFTS,!0),e.setUint16(d+8,Math.min(1E3*K[f].DCSend,65535),!0),e.setUint16(d+10,Math.min(1E3*K[f].GetStats,65535),!0),e.setUint8(d+12,K[f].FrameInfo),d+=13;a+=c}if(g){X(h,a,"EVNT",1,L.length,72);a+=9;c=h;e=a;for(d=0;d<L.length;d++){c.setFloat64(e,L[d].TS,!0);e+=8;var m=L[d].eventtype;for(f=0;f<m.length&&63>f;f++)c.setUint8(e+f,m.charCodeAt(f));c.setUint8(e+f,0);e+=64}a+=g}if(k){X(h,
a,"SQEV",1,Q.length,12);a+=9;g=h;m=a;for(c=0;c<Q.length;c++)g.setUint8(m,Q[c].qualityScore),g.setUint8(m+1,Q[c].bandwidthScore),g.setUint8(m+2,Q[c].latencyScore),g.setUint8(m+3,Q[c].networkLossScore),g.setFloat64(m+4,Q[c].timestamp,!0),m+=12;a+=k}if(l){X(h,a,"MTBD",1,R.length,10);a+=9;k=h;g=a;for(m=0;m<R.length;m++)k.setFloat64(g,R[m].timestamp,!0),k.setUint16(g+8,Math.min(R[m].duration,65535),!0),g+=10;a+=l}if(n){X(h,a,"INPT",1,S.length,12);a+=9;l=h;k=a;for(g=0;g<S.length;g++)l.setFloat64(k,S[g].timestamp,
!0),l.setUint16(k+8,Math.min(S[g].bufferedAmount,65535),!0),l.setUint16(k+10,Math.min(S[g].maxSchedulingDelay,65535),!0),k+=12;a+=n}if(G){X(h,a,"GRBG",1,T.length,16);a+=9;n=h;h=a;for(l=0;l<T.length;l++)n.setFloat64(h,T[l].timestamp,!0),n.setInt32(h+8,Y(T[l].deltaUsedHeapSize),!0),n.setInt32(h+12,Y(T[l].deltaTotalHeapSize),!0),h+=16;a+=G}let ka=new Uint8Array(H);M.write(ka,a);null===C||void 0===C?void 0:C.send({stats:H,ackid:U?V:void 0})}catch(B){F("Exception in perf/stats upload. Error : "+B.message+
" stack: "+B.stack)}W()}function da(a){U||void 0===a.ackid||(U=!0);J.postMessage({wsMessage:a})}function fa(a){J.postMessage({wsClose:a})}function ea(){J.postMessage({wsOpen:!0})}function D(){J.postMessage({wsOpening:!0})}function la(a,b){C=new ha;C.initialize(N,a,U,b);D()}
J.onmessage=function(a){try{const b=a.data;b.initMessage?(I=b.initMessage.sessionId,E("{6ccab8d}")):b.perf?K.push(b.perf):b.clientEvent?L.push(b.clientEvent):b.startStats?(O=b.startStats.statsHeader,P=self.setInterval(()=>Z(),5E3),J.postMessage({statsStarted:!0}),E("{5209d98}")):b.stopStats?(self.clearInterval(P),Z(),W(),E("{b58b6ad}")):b.webrtcStats?(b.ackid&&(V=b.ackid),b.webrtcStats.stats&&ia(b.webrtcStats)):b.sq?Q.push(b.sq):b.startWebSocket?(N=b.startWebSocket.signInURL,U=b.startWebSocket.serverSupportsAck,
la(b.startWebSocket.maxReceivedAckId,b.startWebSocket.reconnect)):b.stopWebSocket?(null===C||void 0===C?void 0:ca(),U=!1):b.send?null===C||void 0===C?void 0:C.send(b.send):b.duration?R.push(b.duration):b.inputChannelStats?S.push(b.inputChannelStats):b.garbageCollectionStats&&T.push(b.garbageCollectionStats)}catch(b){F("Worker onmessage exception: "+b)}};


`;
/* Messages for the main thread *(/

/* This class creates a worker thread and controls it through the life time of the webpage.
   The worker thread is not terminated and is removed by browser during page unload.
   Between every stream session the worker is asked to do background work and is stopped at the
   end of the stream session. */
class RagnarokProfilerImpl {
    /**
     * @public
     */
    constructor() {
        this.profiling = false;
        this.rworker = null;
        this.streamBeginTs = 0;
        this.wsHandler = null;
        this.pendingErrors = [];
        this.pendingTelemetry = [];
        this.perf = {
            RAFTS: 0,
            DCSend: 0,
            GetStats: 0,
            FrameInfo: 0
        };
        /*@todo: Logging to console directly. At this point Logger class is not initialized and
                  it doesnt even log to console if eventEmitter is not set. Figure out how to set to redesign
                  eventEmitter so that all logs during initialization is collected */
        dependencies_1.Log.i("{1f5ec29}", "{139da36}");
        try {
            /** @type {string} */
            let blobContent;
            // If WORKER_SRC's content is replaced by the build system, it'll be larger than the
            // preprocessor directive (which has current length of 40 characters).
            if (WORKER_SRC.length > 40) {
                blobContent = WORKER_SRC;
            }
            else {
                /** @type {string} */
                let workerFile = "";
                /** @type {(null|!HTMLScriptElement)} */
                const currentScript = (/** @type {(null|!HTMLScriptElement)} */ (document.currentScript));
                if (currentScript) {
                    // use absolute path for worker, in case page and scripts are in different locations
                    /** @type {number} */
                    const pathEnd = currentScript.src.lastIndexOf("/");
                    if (pathEnd !== -1) {
                        workerFile += currentScript.src.substring(0, pathEnd + 1);
                    }
                }
                workerFile += "ragnarokworker.js";
                // use import scripts and blob URL to allow cross origin loading of worker
                blobContent = "importScripts('" + workerFile + "');";
            }
            /** @type {!Blob} */
            const blob = new Blob([blobContent], { type: "text/javascript" });
            /** @type {string} */
            const blobUrl = URL.createObjectURL(blob);
            this.rworker = new Worker(blobUrl);
            URL.revokeObjectURL(blobUrl);
            this.rworker.onmessage = this.onWorkerMessage.bind(this);
            this.rworker.onerror = this.onWorkerError.bind(this);
            dependencies_1.Log.i("{1f5ec29}", "{62dee75}");
        }
        catch (err) {
            dependencies_1.Log.e("{1f5ec29}", "{50fa2e5}", err.message);
            this.pendingTelemetry.push({
                name: "RagnarokWorkerProblem",
                error: JSON.stringify(err)
            });
        }
    }
    /**
     * @public
     * @param {string} signInURL
     * @param {number} maxReceivedAckId
     * @param {boolean} serverSupportsAck
     * @param {!tsickle_rinterfaces_2.WebSocketHandler} wsHandler
     * @param {(undefined|boolean)=} reconnect
     * @return {void}
     */
    startWebSocket(signInURL, maxReceivedAckId, serverSupportsAck, wsHandler, reconnect) {
        this.wsHandler = wsHandler;
        /** @type {!output$ragnarokprofiler.WorkerMessage} */
        let msg = {
            startWebSocket: {
                signInURL,
                maxReceivedAckId,
                serverSupportsAck,
                reconnect
            }
        };
        if (this.rworker) {
            this.rworker.postMessage(msg);
            dependencies_1.Log.d("{1f5ec29}", "{b3f6faa}");
        }
    }
    /**
     * @public
     * @return {void}
     */
    stopWebSocket() {
        /** @type {!output$ragnarokprofiler.WorkerMessage} */
        const workerMessage = {
            stopWebSocket: true
        };
        if (this.rworker) {
            dependencies_1.Log.d("{1f5ec29}", "{7ac815d}");
            this.rworker.postMessage(workerMessage);
        }
        this.wsHandler = null;
    }
    /**
     * @public
     * @param {string} sessionId
     * @param {!tsickle_telemetryhandler_5.TelemetryHandler} telemetry
     * @param {!tsickle_rinterfaces_2.AckIdGenerator} ackIdGenerator
     * @return {void}
     */
    initialize(sessionId, telemetry, ackIdGenerator) {
        this.telemetry = telemetry;
        this.ackIdGenerator = ackIdGenerator;
        if (this.pendingTelemetry.length > 0) {
            for (const obj of this.pendingTelemetry) {
                telemetry.emitDebugEvent(obj.name, obj.error);
            }
            this.pendingTelemetry = [];
        }
        if (this.pendingErrors.length > 0) {
            for (const error of this.pendingErrors) {
                this.emitError(error);
            }
            this.pendingErrors = [];
        }
        this.profiling = false;
        this.resetPerf();
        // If needed, updateStreamTime should be called with a better stream begin time.
        if (this.streamBeginTs === 0) {
            this.updateStreamTime();
        }
        if (this.rworker) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                initMessage: {
                    sessionId
                }
            };
            this.rworker.postMessage(workerMessage);
            dependencies_1.Log.d("{1f5ec29}", "{404f264}");
        }
        else {
            dependencies_1.Log.e("{1f5ec29}", "{c62bf29}");
        }
    }
    /**
     * @public
     * @return {void}
     */
    deinitialize() {
        this.telemetry = undefined;
    }
    /**
     * @public
     * @param {!tsickle_rinterfaces_2.StatsHeader} statsHeader
     * @return {void}
     */
    startProfiling(statsHeader) {
        this.profiling = false;
        /** @type {!output$ragnarokprofiler.WorkerMessage} */
        const workerMessage = {
            startStats: {
                statsHeader
            }
        };
        if (this.rworker) {
            dependencies_1.Log.d("{1f5ec29}", "{6897d5e}");
            this.rworker.postMessage(workerMessage);
        }
    }
    /**
     * @public
     * @return {void}
     */
    stopProfiling() {
        this.profiling = false;
        /** @type {!output$ragnarokprofiler.WorkerMessage} */
        const workerMessage = {
            stopStats: true
        };
        if (this.rworker) {
            dependencies_1.Log.d("{1f5ec29}", "{76b05dd}");
            this.rworker.postMessage(workerMessage);
        }
    }
    /**
     * @public
     * @return {void}
     */
    updateStreamTime() {
        // Every timestamp collected and uploaded to server will be relative to below timestamp.
        this.streamBeginTs = performance.now();
    }
    /**
     * @public
     * @return {void}
     */
    resetPerf() {
        this.perf.RAFTS = 0;
        this.perf.DCSend = 0;
        this.perf.GetStats = 0;
        this.perf.FrameInfo = 0;
    }
    /**
     * @public
     * @param {!tsickle_rinterfaces_2.WebSocketMsg} _send
     * @return {void}
     */
    sendOverWs(_send) {
        if (this.rworker) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            let msg = {
                send: _send
            };
            this.rworker.postMessage(msg);
        }
    }
    /**
     * @public
     * @param {!MessageEvent<?>} ev
     * @return {void}
     */
    onWorkerMessage(ev) {
        /** @type {!tsickle_rinterfaces_2.WorkerResponse} */
        let response = ev.data;
        if (response.statsStarted) {
            this.profiling = true;
            dependencies_1.Log.d("{1f5ec29}", "{0f8a41a}");
        }
        else if (response.log) {
            dependencies_1.Log.i("{cbf9f59}", "{0b0c6f9}", response.log);
        }
        else if (response.exception) {
            dependencies_1.Log.i("{cbf9f59}", "{0b0c6f9}", response.exception);
        }
        else if (response.wsClose) {
            if (this.wsHandler) {
                this.wsHandler.closeHandler(response.wsClose);
            }
            else {
                dependencies_1.Log.e("{1f5ec29}", "{900a6f2}");
            }
        }
        else if (response.wsMessage) {
            if (this.wsHandler) {
                this.wsHandler.messageHandler(response.wsMessage);
            }
            else {
                dependencies_1.Log.e("{1f5ec29}", "{900a6f2}");
            }
        }
        else if (response.wsOpening) {
            if (this.wsHandler) {
                this.wsHandler.openingHandler();
            }
            else {
                dependencies_1.Log.e("{1f5ec29}", "{900a6f2}");
            }
        }
        else if (response.wsOpen) {
            if (this.wsHandler) {
                this.wsHandler.openHandler();
            }
            else {
                dependencies_1.Log.e("{1f5ec29}", "{900a6f2}");
            }
        }
    }
    /**
     * @private
     * @param {!ErrorEvent} ev
     * @return {void}
     */
    onWorkerError(ev) {
        if (this.telemetry) {
            this.emitError(ev);
        }
        else {
            this.pendingErrors.push(ev);
        }
    }
    /**
     * @private
     * @param {!ErrorEvent} ev
     * @return {void}
     */
    emitError(ev) {
        var _a, _b, _c, _d;
        // We are seeing that the ErrorEvent object doesn't contain any useful
        // information. Fill defaults to avoid parsing errors.
        (/** @type {!tsickle_telemetryhandler_5.TelemetryHandler} */ (this.telemetry)).emitExceptionEvent(undefined, (_a = ev === null || ev === void 0 ? void 0 : ev.message) !== null && _a !== void 0 ? _a : "", (_b = ev === null || ev === void 0 ? void 0 : ev.filename) !== null && _b !== void 0 ? _b : "", (_c = ev === null || ev === void 0 ? void 0 : ev.lineno) !== null && _c !== void 0 ? _c : 0, (_d = ev === null || ev === void 0 ? void 0 : ev.colno) !== null && _d !== void 0 ? _d : 0, true, "WorkerError");
    }
    /**
     * @public
     * @return {void}
     */
    onPreRender() {
        if (this.profiling) {
            if (this.perf.RAFTS != 0) {
                /** @type {!output$ragnarokprofiler.WorkerMessage} */
                const workerMessage = {
                    perf: this.perf
                };
                if (this.rworker) {
                    this.rworker.postMessage(workerMessage);
                }
                this.resetPerf();
            }
            this.perf.RAFTS = this.getStreamTime();
        }
    }
    /**
     * @public
     * @param {number} _time
     * @return {void}
     */
    addDataChannelSendTime(_time) {
        if (this.profiling) {
            this.perf.DCSend += _time;
        }
    }
    /**
     * @public
     * @param {number} _time
     * @return {void}
     */
    addGetStatsTime(_time) {
        if (this.profiling) {
            this.perf.GetStats += _time;
        }
    }
    /**
     * @public
     * @param {!Array<!ArrayBuffer>} report
     * @param {tsickle_statsinterfaces_3.StatsType} type
     * @return {void}
     */
    addStatsReport(report, type) {
        var _a;
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                webrtcStats: { type: type, stats: report },
                ackid: (_a = this.ackIdGenerator) === null || _a === void 0 ? void 0 : _a.getNextAckId()
            };
            if (this.rworker) {
                //   console.log("report to  " + typeof report);
                this.rworker.postMessage(workerMessage, report);
            }
        }
    }
    /**
     * @public
     * @param {!tsickle_interfaces_1.StreamingQuality} streamingQuality
     * @return {void}
     */
    addQualityScore(streamingQuality) {
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                sq: {
                    latencyScore: streamingQuality.latencyScore,
                    bandwidthScore: streamingQuality.bandwidthScore,
                    qualityScore: streamingQuality.qualityScore,
                    networkLossScore: streamingQuality.networkLossScore,
                    timestamp: this.getStreamTime()
                }
            };
            if (this.rworker) {
                this.rworker.postMessage(workerMessage);
            }
        }
    }
    /**
     * @public
     * @param {number} duration
     * @param {number} startTime
     * @return {void}
     */
    addMainThreadBlockDuration(duration, startTime) {
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                duration: {
                    timestamp: startTime - this.streamBeginTs,
                    duration: duration
                }
            };
            if (this.rworker) {
                this.rworker.postMessage(workerMessage);
            }
        }
    }
    /**
     * @public
     * @param {number} bufferedAmount
     * @param {number} schedulingDelay
     * @return {void}
     */
    addInputChannelStats(bufferedAmount, schedulingDelay) {
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                inputChannelStats: {
                    timestamp: this.getStreamTime(),
                    bufferedAmount: bufferedAmount,
                    maxSchedulingDelay: schedulingDelay
                }
            };
            if (this.rworker) {
                this.rworker.postMessage(workerMessage);
            }
        }
    }
    /**
     * @public
     * @param {number} deltaUsedHeapSize
     * @param {number} deltaTotalHeapSize
     * @return {void}
     */
    addGarbageCollectionStats(deltaUsedHeapSize, deltaTotalHeapSize) {
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                garbageCollectionStats: {
                    timestamp: this.getStreamTime(),
                    deltaUsedHeapSize: deltaUsedHeapSize,
                    deltaTotalHeapSize: deltaTotalHeapSize
                }
            };
            if (this.rworker) {
                this.rworker.postMessage(workerMessage);
            }
        }
    }
    /**
     * @public
     * @param {number} framesDecoded
     * @param {number} framesDropped
     * @return {void}
     */
    onFrameInfo(framesDecoded, framesDropped) {
        if (this.profiling) {
            /* Frame Render Info of the video tag element.
                           This is not related to decode.
                           1 byte value:  Upper nibble - FramesDecoded
                                          Lower nibble - FramesDropped.
                           In case either framesDecoded/Dropped is above 15,
                           upload 15(0xF) as the value so that its contained in nibble*/
            /** @type {number} */
            let x = Math.min(framesDecoded, 15) << 4;
            /** @type {number} */
            let y = Math.min(framesDropped, 15);
            this.perf.FrameInfo = x | y;
        }
    }
    /**
     * @public
     * @param {string} type
     * @return {void}
     */
    onEvent(type) {
        if (this.profiling) {
            /** @type {!output$ragnarokprofiler.WorkerMessage} */
            const workerMessage = {
                clientEvent: {
                    TS: this.getStreamTime(),
                    eventtype: type
                }
            };
            if (this.rworker) {
                this.rworker.postMessage(workerMessage);
            }
        }
    }
    /* Get the current time relative to the stream start time. */
    /**
     * @public
     * @return {number}
     */
    getStreamTime() {
        return performance.now() - this.streamBeginTs;
    }
    /**
     * @public
     * @return {number}
     */
    getStreamBeginTime() {
        return this.streamBeginTs;
    }
}
exports.RagnarokProfilerImpl = RagnarokProfilerImpl;
/* istanbul ignore if */
if (false) {
    /**
     * @type {boolean}
     * @private
     */
    RagnarokProfilerImpl.prototype.profiling;
    /**
     * @type {!output$ragnarokprofiler.Perf}
     * @private
     */
    RagnarokProfilerImpl.prototype.perf;
    /**
     * @type {(null|!Worker)}
     * @private
     */
    RagnarokProfilerImpl.prototype.rworker;
    /**
     * @type {number}
     * @private
     */
    RagnarokProfilerImpl.prototype.streamBeginTs;
    /**
     * @type {(null|!tsickle_rinterfaces_2.WebSocketHandler)}
     * @private
     */
    RagnarokProfilerImpl.prototype.wsHandler;
    /**
     * @type {(undefined|!tsickle_telemetryhandler_5.TelemetryHandler)}
     * @private
     */
    RagnarokProfilerImpl.prototype.telemetry;
    /**
     * @type {(undefined|!tsickle_rinterfaces_2.AckIdGenerator)}
     * @private
     */
    RagnarokProfilerImpl.prototype.ackIdGenerator;
    /**
     * @type {!Array<!ErrorEvent>}
     * @private
     */
    RagnarokProfilerImpl.prototype.pendingErrors;
    /**
     * @type {!Array<{name: string, error: string}>}
     * @private
     */
    RagnarokProfilerImpl.prototype.pendingTelemetry;
}
// Create one instance of profiler used by all Ragnarok classes.
/** @type {!RagnarokProfilerImpl} */
exports.RagnarokProfiler = new RagnarokProfilerImpl();
