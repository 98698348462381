import {
    GridApp,
    EVENTS,
    LogEvent,
    SessionProgressState,
    RErrorCode,
    TelemetryEvent,
    StartSessionResultEvent,
    StreamingTerminatedEvent,
    StopSessionResultEvent,
    ActiveSessionResultEvent,
    SessionProgressUpdateEvent,
    StreamingEvent,
    STREAMING_STATE,
    GetSessionResult,
    SessionState,
    InputConfigFlags,
    CHANGELIST,
    CursorType,
    RagnarokConfigData,
    ConfigureRagnarokSettings,
    ActiveSessionInfo,
    ChooseStreamingSettings,
    AppLaunchMode,
    TrackType,
    AuthInfo,
    CustomMessage,
    PlatformDetails,
    getPlatformDetails,
    AddPlatformTelemetry,
    TextCompositionEvent,
    ClientType,
    DeviceOS,
    DeviceType,
    ClientStreamer,
    LogLevel,
    BrowserName,
    MicState,
    MicStateEvent,
    StreamingProfilePreset,
    StreamingSettings,
    Usage,
    AppLevelProtocol,
    Protocol
} from "@gamestream/ragnarok";
import { AuthProviderHashMap } from "./authprovider";
import { DummyAuth } from "./dummyauth";
import { Log, LogBuffer } from "./logger";
import { createTracer } from "./tracer";
import { accountProviders } from "./accntlinkutil";
import {
    GetHexString,
    IsiDevice,
    IsiPadOS,
    IsIPv4Address,
    IsValidIPv4,
    IsWebOS,
    IsXbox
} from "./utils";

const LOGTAG = "client";

const DisplayDivs = {
    LOADING_PAGE: 0,
    SETTINGS_PAGE: 1,
    STREAM: 2,
    ERROR_PAGE: 3,
    TRANSITION_PAGE: 4
};

// Methods of Authentication for the AuthProvider
// const STAGING_STARFLEET = "StagingStarfleet";
// const PRODUCTION_STARFLEET = "ProductionStarfleet";
const DUMMY_PROVIDER = "Dummy";
const MAX_SESSION_CONNECTION_RETRY = 5;

let authProviders: AuthProviderHashMap = {};
let currentAuthProvider: string = "";

let currentDivIndex = 0;
let divs = [];
let statusBarPlaceholder = undefined;
let messageElement = undefined;
let errorMessageElement = undefined;
let serverAddress = undefined;
let resolutionElem = undefined;
let fpsElement = undefined;
let bitrateElement = undefined;
let platformSimulationElement = undefined;
let applaunchModeSimulationElement = undefined;
let kbLayoutElement = undefined;
let fsSettingElement = undefined;
let clientConfigElement: HTMLTextAreaElement = undefined;
let persistClientConfigElement: HTMLInputElement = undefined;
let sbSettingElement = undefined;
let rrlSettingElement = undefined;
let videoElement = undefined;
let audioElement = undefined;
let videoDiv = undefined;
let defaultVideoHeight = undefined;
let hiddenTextElement = undefined;
let compositionElement = undefined;
let streamerSelectElement = undefined;
let statusToggleButton = undefined;
let igoNetworkDirty = false;
let igoMaxBitrateDirty = false;
let igoNetworkCheckbox = undefined;
let igoMaxbitSlider = undefined;
let igoMaxbitSpan = undefined;
const codecElements = [];

let gfnpcApp!: GFNPCApp;
let IsSafari = false;
let isHiddenTextFocused = false;
let isKeyboardShown = false;
let userId: string = "";
let lastSessionId = undefined;
let lastSubSessionId = undefined;
let lastSessionIdElement = undefined;
let lastSubSessionIdElement = undefined;
let platformDetails: PlatformDetails;
let micButton = undefined;
let drc: boolean = undefined;
let issoEnabled: boolean = false;
let igoEnabled: boolean = false;
let inDebugMode: boolean = false;

// register service worker
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("service-worker.js");
}

const enum ClientPlatformName {
    WINDOWS = "Windows",
    LINUX = "Linux",
    MACOS = "MacOSX",
    BROWSER = "browser"
}

let logBuffer: LogBuffer | undefined;

let fakeFullscreen = false;

function useFakeFullScreen() {
    return !document.fullscreenEnabled;
}

function IsDocumentFullScreen() {
    if (useFakeFullScreen()) {
        return fakeFullscreen;
    } else {
        let doc: any = window.document;
        if (
            doc.fullscreen ||
            doc.webkitIsFullScreen == true ||
            doc.mozFullScreen ||
            doc.msFullscreenElement
        ) {
            return true;
        }
        return false;
    }
}

function DisplayScreen(index) {
    currentDivIndex = index;
    divs.forEach(function (element, arrayindex) {
        if (arrayindex !== DisplayDivs.STREAM) {
            element.style.display = index == arrayindex ? "block" : "none";
        } else {
            element.style.visibility = index == arrayindex ? "visible" : "hidden";
        }
    });

    if (currentDivIndex === DisplayDivs.SETTINGS_PAGE) {
        document.removeEventListener("keydown", keyupdownEventHandler);
        document.removeEventListener("keydown", keydownEventHandler);
        document.removeEventListener("keyup", keyupdownEventHandler);
        if (lastSessionId) {
            lastSessionIdElement.innerHTML = "Last SessionId: " + lastSessionId;
            lastSessionIdElement.style.display = "block";
        } else {
            lastSessionIdElement.style.display = "none";
        }
        if (lastSubSessionId) {
            lastSubSessionIdElement.innerHTML = "Last SubSessionId: " + lastSubSessionId;
            lastSubSessionIdElement.style.display = "block";
        } else {
            lastSubSessionIdElement.style.display = "none";
        }
    } else {
        document.addEventListener("keydown", keyupdownEventHandler);
        document.addEventListener("keydown", keydownEventHandler);
        document.addEventListener("keyup", keyupdownEventHandler);
    }

    // if (currentDivIndex != DisplayDivs.LOADING_PAGE) {
    //     fsbuttonLoadingPage.style.visibility = "hidden";
    // } else {
    //     if (IsDocumentFullScreen()) {
    //         //getting compile error for !document.fullscreen
    //     } else {
    //         fsbuttonLoadingPage.style.visibility = "visible";
    //     }
    // }
}

function GetQueryParam(param): string {
    let params = new URLSearchParams(location.search);
    return params.get(param);
}

function SetMessage(msg) {
    messageElement.textContent = msg;
}

function DisplayLoadingMessage(msg) {
    SetMessage(msg);
    DisplayScreen(DisplayDivs.LOADING_PAGE);
}

function DisplayErrorMessage(msg, showButtons: boolean = true) {
    errorMessageElement.textContent = msg;
    DisplayScreen(DisplayDivs.ERROR_PAGE);
    if (IsDocumentFullScreen()) {
        exitFullscreen();
    }
    if (!showButtons) {
        document.getElementById("okbutton").style.visibility = "hidden";
        document.getElementById("terminate-3").style.visibility = "hidden";
    }
}

function LogoutAuthProvider(authProvider: string) {
    if (authProviders[authProvider]) {
        authProviders[authProvider].logout();
        authProviders[authProvider] = null;
    }
}

function enterFullScreenPage() {
    if (useFakeFullScreen()) {
        enterFakeFullScreen();
    } else if (IsSafari) {
        let _elem: any = document.documentElement;
        _elem.webkitRequestFullscreen();
    } else {
        let fullscreen = document.body.requestFullscreen; // || document.body.mozRequestFullScreen || document.body.msRequestFullscreen;
        fullscreen.call(document.body);
    }
}

function enterFullScreenVideo() {
    if (useFakeFullScreen()) {
        enterFakeFullScreen();
    } else if (IsSafari) {
        enterFullScreenPage();
    } else {
        let fullscreen =
            videoDiv.webkitRequestFullscreen ||
            videoDiv.mozRequestFullScreen ||
            videoDiv.msRequestFullscreen;
        fullscreen.call(videoDiv);
    }
}

function toggleKeyboard() {
    if (!isKeyboardShown) {
        Log.i(LOGTAG, "Showing keyboard");
        hiddenTextElement.focus();
    }
}

// This checks to see if any part of the visual viewport is consumed by a software keyboard or keyboard accessory (e.g. suggestion bar).
// Note that floating and split keyboards appear as if there is no keyboard.
// This detection mechanism is the same as what is used for GFN.
function checkForVisibleKeyboard() {
    const visualViewport = (<any>window).visualViewport;
    if (visualViewport) {
        isKeyboardShown = isHiddenTextFocused && visualViewport.height < window.innerHeight;
        gfnpcApp.getGridApp().setVirtualKeyboardState(isKeyboardShown);
    }
}

function enterFakeFullScreen() {
    if (!fakeFullscreen) {
        fakeFullscreen = true;
        fullscreenchangeEventHandler(undefined);
    }
}

function exitFullscreen() {
    if (useFakeFullScreen()) {
        if (fakeFullscreen) {
            fakeFullscreen = false;
            fullscreenchangeEventHandler(undefined);
        }
    } else {
        document.exitFullscreen();
    }
}

/** Returns the boolean representation of the associated string */
function toBool(value: string): boolean | undefined {
    if (value) {
        switch (value) {
            case "enable":
            case "on":
            case "1":
            case "true":
                return true;
            case "disable":
            case "off":
            case "0":
            case "false":
                return false;
        }
    }
    return undefined;
}

/** Returns the numerical representation of the associated string */
function toNumber(value: string): number | undefined {
    if (value) {
        const num = Number.parseInt(value);
        if (!Number.isNaN(num)) {
            return num;
        }
    }
    return undefined;
}

enum DeviceOSType {
    Windows = "Windows",
    MacOS = "MacOS",
    Shield = "Shield",
    Android = "Android",
    IOS = "iOS",
    IPadOS = "iPadOS",
    ChromeOS = "ChromeOS",
    Linux = "Linux",
    Tizen = "Tizen",
    WebOS = "WebOS",
    TvOS = "tvOS",
    XBox = "Xbox",
    Undefined = "undefined"
}

// Rename to CommonDeviceType to avoid duplicate identifer from PlatformAPI.DeviceType
enum CommonDeviceType {
    Desktop = "Desktop",
    Laptop = "Laptop",
    TV = "TV",
    Phone = "Phone",
    Tablet = "Tablet",
    Server = "Server",
    Console = "Console",
    Undefined = "undefined"
}

enum BrowserType {
    CHROME = "Chrome",
    SAFARI = "Safari",
    YANDEX = "Yandex",
    EDGE = "Edge",
    EDGE_LEGACY = "Edge_legacy",
    FIREFOX = "Firefox",
    SAMSUNG = "Samsung",
    CHROMIUM = "Chromium",
    OPERA = "Opera",
    BRAVE = "Brave",
    SILK = "Silk",
    Undefined = "undefined"
}

let canvasCtx: CanvasRenderingContext2D = undefined;
let isSendVideoTrack: boolean = false;
let isCancelCanvasAnimation: boolean = false;

function canvasAnimation(): void {
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, 10, 10);
    canvasCtx.fillStyle = "green";
    canvasCtx.fillRect(0, 0, 10, 10);
    canvasCtx.restore();
    if (!isCancelCanvasAnimation) {
        window.requestAnimationFrame(canvasAnimation);
    }
}

class GFNPCApp {
    private sessionStartParams;
    private sessionId: string;
    private sessionsToDelete: number;
    private gridApp: any;
    private errorMap: Map<number, string>;
    private isStreamTest: boolean;
    private startResult: StartSessionResultEvent;
    private tracksMap: Map<string, boolean> = new Map<string, boolean>();
    private totalTracks: number = 0;
    private continueShownOnce: boolean = false;
    private hasPlatformBeenSimulated: boolean = false;
    private hasAppLaunchBeenSimulated: boolean = false;
    private micEnabled: boolean = false;
    private sessionStartRetryLeft: number = 0;

    constructor(isStreamTest: boolean, maxSessionStartRetry: number) {
        this.sessionStartParams = {};
        this.sessionId = null;
        this.sessionsToDelete = 0;
        this.sessionStartRetryLeft = maxSessionStartRetry;
        this.errorMap = new Map();
        this.buildErrorMessageMap();
        this.isStreamTest = isStreamTest;
        this.instantiateGridApp();
    }

    private updateEventDataElements() {
        this.gridApp?.updateEventDataElements({
            // @todo this is mocked data and will be improved in followup.
            commonData: {
                clientId: "78589530426925203",
                clientVer: "2.0.45.34",
                clientVariant: "Release",
                eventSchemaVer: "23.0",
                eventSysVer: "0.12.2",
                deviceId: authProviders[currentAuthProvider].getDeviceId(),
                userId: authProviders[currentAuthProvider].getUserId(),
                externalUserId: authProviders[currentAuthProvider].getExternalUserId(),
                idpId: authProviders[currentAuthProvider].getIdpId(),
                integrationId: "undefined",
                sessionId: "92774af7-fbc1-488f-a090-ba49cfd13da2",
                deviceOS: this.getMappedDeviceOS(platformDetails.os),
                deviceOSVersion: platformDetails.osVer,
                deviceType: this.getMappedDeviceType(platformDetails.deviceType),
                deviceMake: "APPLE",
                deviceModel: "undefined",
                clientType: "Browser",
                browserType: this.getMappedBrowserName(platformDetails.browser),
                platform: "Web",
                eventProtocol: "1.4",
                deviceGdprFuncOptIn: "Full",
                deviceGdprTechOptIn: "None",
                deviceGdprBehOptIn: "None",
                gdprFuncOptIn: "Full",
                gdprTechOptIn: "Full",
                gdprBehOptIn: "Full"
            },
            experiments: [
                {
                    id: "6376d3bf-3f62-419d-989c-2955c25f2b4e",
                    group: "ENABLE"
                },
                {
                    id: "feb30a68-ee9a-44a0-a717-da2d28c81e97",
                    group: "ENABLE"
                },
                {
                    id: "ea128779-32c7-45c1-94c8-c9374d64219d",
                    group: "ENABLE"
                },
                {
                    id: "ac00061b-eb8d-4d6c-978a-f3ac96f8e91a",
                    group: "ENABLE"
                },
                {
                    id: "21f7e7af-b5ae-4c76-b269-3cc4351671d2",
                    group: "ENABLE"
                },
                {
                    id: "b205ded0-6c08-4436-b818-f0bde4633f08",
                    group: "ENABLE"
                },
                {
                    id: "d639ef8f-c6c5-4931-8e75-451e9e075c62",
                    group: "ENABLE"
                },
                {
                    id: "cdce276d-d611-4ef9-ba6f-a90c3e7040d4",
                    group: "ENABLE"
                },
                {
                    id: "4c57b739-c860-4c5e-b3e4-9d89ff91b561",
                    group: "ENABLE"
                },
                {
                    id: "86bf4cf1-b717-4f72-8414-0f40d6a32656",
                    group: "ENABLE"
                }
            ],
            config: {
                server: "https://events.gfestage.nvidia.com",
                version: "v1.0"
            },
            telemetryEventIds: {
                streamingProfileGuid: "testClientStreamingProfileGuid",
                systemInfoGuid: "testClientSystemInfoGuid"
            }
        });
    }

    private getMappedBrowserName(browserName: BrowserName): BrowserType {
        switch (browserName) {
            case BrowserName.CHROME:
                return BrowserType.CHROME;
            case BrowserName.SAFARI:
                return BrowserType.SAFARI;
            case BrowserName.YANDEX:
                return BrowserType.YANDEX;
            case BrowserName.EDGE:
                return BrowserType.EDGE;
            case BrowserName.EDGE_LEGACY:
                return BrowserType.EDGE_LEGACY;
            case BrowserName.FIREFOX:
                return BrowserType.FIREFOX;
            case BrowserName.SAMSUNG:
                return BrowserType.SAMSUNG;
            case BrowserName.CHROMIUM:
                return BrowserType.CHROMIUM;
            case BrowserName.OPERA:
                return BrowserType.OPERA;
            case BrowserName.BRAVE:
                return BrowserType.BRAVE;
            case BrowserName.SILK:
                return BrowserType.SILK;
            case BrowserName.UNKNOWN:
            // As per comment https://nvbugswb.nvidia.com/NVBugs5/redir.aspx?url=/3434141/6 ReactNative browser
            // is not supported and is mapped to undefined.
            case BrowserName.REACT:
            default:
                return BrowserType.Undefined;
        }
    }

    private getMappedDeviceType(platformDeviceType: string): CommonDeviceType {
        // Mapping the PlatformDetails.DeviceType to GXT common fields.
        // If no mapped value, should return Undefined string.
        let deviceType = CommonDeviceType.Undefined;
        if (platformDeviceType === DeviceType.CONSOLE) {
            deviceType = CommonDeviceType.Console;
        } else if (platformDeviceType === DeviceType.TABLET) {
            deviceType = CommonDeviceType.Tablet;
        } else if (platformDeviceType === DeviceType.PHONE) {
            deviceType = CommonDeviceType.Phone;
        } else if (platformDeviceType === DeviceType.TV) {
            deviceType = CommonDeviceType.TV;
        } else if (platformDeviceType === DeviceType.LAPTOP) {
            deviceType = CommonDeviceType.Laptop;
        } else if (platformDeviceType === DeviceType.DESKTOP) {
            deviceType = CommonDeviceType.Desktop;
        }
        return deviceType;
    }

    private getMappedDeviceOS(platformOS: string): DeviceOSType {
        switch (platformOS.toLowerCase()) {
            case "windows":
                return DeviceOSType.Windows;
            case "macosx":
            case "macos":
                return DeviceOSType.MacOS;
            case "chromeos":
            case "chrome os":
                return DeviceOSType.ChromeOS;
            case "linux":
                return DeviceOSType.Linux;
            case "ios":
                return DeviceOSType.IOS;
            case "ipados":
                return DeviceOSType.IPadOS;
            case "tizen":
                return DeviceOSType.Tizen;
            case "webos":
                return DeviceOSType.WebOS;
            case "xbox":
                return DeviceOSType.XBox;
            default:
                return DeviceOSType.Undefined;
        }
    }

    instantiateGridApp() {
        Log.i(LOGTAG, "GridApp instantiated");
        this.gridApp = new GridApp(platformDetails);

        this.gridApp.addListener(EVENTS.SESSION_START_RESULT, this.onSessionStartResult.bind(this));
        this.gridApp.addListener(EVENTS.SESSION_STOP_RESULT, this.onSessionStopResult.bind(this));
        this.gridApp.addListener(
            EVENTS.ACTIVE_SESSIONS_RESULT,
            this.onActiveSessionResult.bind(this)
        );
        this.gridApp.addListener(EVENTS.PROGRESS_UPDATE, this.onProgressUpdate.bind(this));
        this.gridApp.addListener(EVENTS.STREAM_STOPPED, this.onStreamStopped.bind(this));
        this.gridApp.addListener(EVENTS.ANALYTICS_EVENT, this.onAnalyticsEvent.bind(this));
        this.gridApp.addListener(EVENTS.STREAMING_EVENT, this.onStreamingEvent.bind(this));
        this.gridApp.addListener(EVENTS.GET_SESSION_RESULT, this.onGetSessionResult.bind(this));
        this.gridApp.addListener(EVENTS.CUSTOM_MESSAGE, this.onCustomMessage.bind(this));
        this.gridApp.addListener(EVENTS.TEXT_COMPOSITION, this.onTextComposition.bind(this));
        this.gridApp.addListener(EVENTS.MIC_CAPTURE, this.onMicCapture.bind(this));
        this.gridApp.addListener(EVENTS.STREAM_STATS_UPDATE, onISSOUpdate);

        // Use the more full-featured external log function that logs to console if there's no LogBuffer.
        this.gridApp.addListener(EVENTS.LOG_EVENT, onLogEvent);
    }

    buildErrorMessageMap() {
        this.errorMap.set(
            RErrorCode.AuthProviderError,
            "Error happened during request to auth provider"
        );
        this.errorMap.set(RErrorCode.NoNetwork, "No internet connection.");
        this.errorMap.set(RErrorCode.NetworkError, "A network error occurred.");
        this.errorMap.set(
            RErrorCode.GetActiveSessionServerError,
            "Error occurred due to GetActiveSessionServerError."
        );
        this.errorMap.set(RErrorCode.ExceptionHappened, "Unexpected exception happened.");
        this.errorMap.set(
            RErrorCode.AuthTokenNotUpdated,
            "Error occurred due to AuthTokenNotUpdated."
        );
        this.errorMap.set(
            RErrorCode.SessionFinishedState,
            "Unexpected session state while polling."
        );
        this.errorMap.set(RErrorCode.ResponseParseFailure, "Failed to parse server response.");
        this.errorMap.set(RErrorCode.StreamerVideoPlayError, "Video play failure.");
        this.errorMap.set(
            RErrorCode.GridAppNotInitialized,
            "Grid app instance was not initialized."
        );
        this.errorMap.set(RErrorCode.SessionLimitExceeded, "Session Limit Exceeded.");
        this.errorMap.set(
            RErrorCode.StreamErrorGeneric,
            "Some generic error happened in streamer."
        );
        this.errorMap.set(
            RErrorCode.StreamerSignInFailure,
            "Error happened during signin request to signaling server."
        );
        this.errorMap.set(
            RErrorCode.StreamerHanginGetFailure,
            "Error happened in hanging get request of peer connection."
        );
        this.errorMap.set(
            RErrorCode.StreamerIceConnectionFailed,
            "Could not find valid ice candidate pair."
        );
        this.errorMap.set(
            RErrorCode.StreamerGetRemotePeerTimedOut,
            "Streaming stopped as there is no remote peer."
        );
        this.errorMap.set(
            RErrorCode.StreamInputChannelError,
            "Streaming stopped due to input channel error."
        );
        this.errorMap.set(
            RErrorCode.StreamCursorChannelError,
            "Streaming stopped due to cursor channel error."
        );
        this.errorMap.set(
            RErrorCode.StreamControlChannelError,
            "Streaming stopped due to control channel error."
        );
        this.errorMap.set(
            RErrorCode.StreamerIceReConnectionFailed,
            "Reconnect attempt failed after network problem or remote peer not alive."
        );
        this.errorMap.set(
            RErrorCode.StreamerNoVideoPacketsReceivedEver,
            "Streaming stopped as NoVideoPacketsReceivedEver."
        );
        this.errorMap.set(
            RErrorCode.StreamerNoVideoFramesLossyNetwork,
            "Streaming stopped as NoVideoFramesLossyNetwork."
        );
        this.errorMap.set(
            RErrorCode.StreamDisconnectedFromServer,
            "Stream Disconnected from server, unknown reason."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedNoResponce,
            "Stream Disconnected from server, NoResponce."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedRemoteInputError,
            "Stream Disconnected from server, RemoteInputError."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedFrameGrabFailed,
            "Stream Disconnected from server, FrameGrabFailed."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedConfigUnAvailable,
            "Stream Disconnected from server, ConfigUnAvailable."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedInvalidCommand,
            "Stream Disconnected from server, InvalidCommand."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedInvalidMouseState,
            "Stream Disconnected from server, InvalidMouseState."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedNetworkError,
            "Stream Disconnected from server, NetworkError."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedGameLaunchFailed,
            "Stream Disconnected from server, GameLaunchFailed."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedVideoFirstFrameSendFailed,
            "Stream Disconnected from server, VideoFirstFrameSendFailed."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedVideoNextFrameSendFailed,
            "Stream Disconnected from server, VideoNextFrameSendFailed."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedFrameGrabTimedOut,
            "Stream Disconnected from server, FrameGrabTimedOut."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedFrameEncodeTimedOut,
            "Stream Disconnected from server, FrameEncodeTimedOut."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedFrameSendTimedOut,
            "Stream Disconnected from server, FrameSendTimedOut."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedIntended,
            "Stream Disconnected from server as user quit the game."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnecteduserLoggedinDifferenAccount,
            "Stream Disconnected from server, userLoggedinDifferenAccount."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedWindowedMode,
            "Stream Disconnected from server, WindowedMode."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedUserIdle,
            "Stream Disconnected from server, UserIdle."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedUnAuthorizedProcessDetected,
            "Stream Disconnected from server, UnAuthorizedProcessDetected."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedMaliciousProcessDetected,
            "Stream Disconnected from server, MaliciousProcessDetected."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedUnKnownProcessDetected,
            "Stream Disconnected from server, UnKnownProcessDetected."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedMinerProcessDetected,
            "Stream Disconnected from server, MinerProcessDetected."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedStreamingUnsupported,
            "Stream Disconnected from server, StreamingUnsupported."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedAnotherClient,
            "Stream Disconnected from server, AnotherClient."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedUnknownFromPm,
            "Stream Disconnected from server, UnknownFromPm."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedUserEntitledMinutesExceeded,
            "Stream Disconnected from server, UserEntitledMinutesExceeded."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedClientReconnectTimeLimitExceeded,
            "Stream Disconnected from server, ClientReconnectTimeLimitExceeded."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedOperatorCommandedTermination,
            "Stream Disconnected from server, OperatorCommandedTermination."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedConcurrentSessionLimitExceeded,
            "Stream Disconnected from server, ConcurrentSessionLimitExceeded."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedMaxSessionTimeLimitExceeded,
            "Stream Disconnected from server, MaxSessionTimeLimitExceeded."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedBifrostInitiatedSessionPause,
            "Stream Disconnected from server, BifrostInitiatedSessionPause."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedSystemCommandTermination,
            "Stream Disconnected from server, SystemCommandTermination."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedMultipleLogin,
            "Stream Disconnected from server, MultipleLogin."
        );
        // @todo we should plumb the unified error code from ragnarok and display in UI.
        this.errorMap.set(RErrorCode.ServerInternalError, "Session setup failed on server.");
        this.errorMap.set(
            RErrorCode.RequestLimitExceeded,
            "You've tried to play too many games in a short period of time. Please wait a few minutes and try again."
        );
        this.errorMap.set(
            RErrorCode.EntitlementFailure,
            "You are not entitled to play this game. Please check you entitlement."
        );
        this.errorMap.set(
            RErrorCode.InvalidServiceReponse,
            "One of the micro services on Zone failed. Please check telemetry for more details."
        );
        this.errorMap.set(
            RErrorCode.AppPatching,
            "Game is currently in patching state, please try again later."
        );
        this.errorMap.set(
            RErrorCode.GameNotFound,
            "Requested game is not available in the zone. Please try another zone."
        );
        this.errorMap.set(RErrorCode.AppMaintenanceStatus, "Requested game is under maintenance.");
        this.errorMap.set(
            RErrorCode.RequiredSeatInstanceTypeNotSupported,
            "Required instance type for the game is not available in current zone. Please try different zone."
        );
        this.errorMap.set(
            RErrorCode.ServerSessionQueueLengthExceeded,
            "We’ve reached our limit for the number of gamers who can wait for the next available rig. Please try again later"
        );
        this.errorMap.set(
            RErrorCode.SessionTerminatedByAnotherClient,
            "Session setup request was terminated by another client."
        );
        this.errorMap.set(
            RErrorCode.ServerDisconnectedGameNotOwnedByUser,
            "Your session ended as you do not own the requested game."
        );
        this.errorMap.set(
            RErrorCode.SystemSleepDuringStreaming,
            "Your session ended as device went to sleep during streaming"
        );
        this.errorMap.set(RErrorCode.SessionInQueueAbandoned, "Session abandoned during polling");
        this.errorMap.set(RErrorCode.NoInternetDuringSessionSetup, "Cannot connect to server.");
        this.errorMap.set(RErrorCode.NoInternetDuringStreaming, "Cannot connect to server.");
    }

    getDisplayableErrorMessage(code: number) {
        let msg = "";
        if (this.errorMap.has(code)) {
            msg = this.errorMap.get(code);
        }
        return msg;
    }

    handleActiveSessions(sessionList: ActiveSessionInfo[]) {
        for (let i = 0; i < sessionList.length; i++) {
            if (
                this.sessionStartParams.appId == sessionList[i].appId &&
                (sessionList[i].state == SessionState.READY_FOR_CONNECTION ||
                    sessionList[i].state == SessionState.STREAMING ||
                    sessionList[i].state == SessionState.PAUSED)
            ) {
                Log.i(
                    LOGTAG,
                    "Existing session found with the appid " +
                        sessionList[i].appId +
                        " and SessionState " +
                        sessionList[i].state
                );
                SetMessage("Resuming previous session...");
                // If client wanted to launch in touch friendly mode but the session was not started in touch friendly mode,
                // switch client to defualt mode of game launch as we cannot send raw touch event to game.
                if (
                    this.sessionStartParams.appLaunchMode == AppLaunchMode.TouchFriendly &&
                    sessionList[i].appLaunchMode != AppLaunchMode.TouchFriendly
                ) {
                    this.sessionStartParams.appLaunchMode = AppLaunchMode.Default;
                    Log.w(
                        LOGTAG,
                        "session " +
                            sessionList[i].sessionId +
                            " was not launched in touch friendly mode, ignoring touch input mode."
                    );
                }

                this.gridApp.resumeSession(this.sessionStartParams, sessionList[i].sessionId);
                return;
            }
        }
        // No resumable session, cleanup existing sessions so a new one can be started.
        SetMessage("Stopping existing sessions");
        this.sessionsToDelete = sessionList.length;
        for (let i = 0; i < sessionList.length; i++) {
            this.gridApp.stopSession(sessionList[i].sessionId);
        }
    }

    private resetTrackInfo() {
        this.totalTracks = 0;
        this.continueShownOnce = false;
        this.tracksMap.clear();
        this.hideOverlay();
    }

    showContinueIfNeeded(playSucceeded: boolean) {
        if (this.continueShownOnce) {
            if (!playSucceeded) {
                this.gridApp.stopSession(this.sessionId);
                DisplayErrorMessage("Play failed");
            }
            return;
        }
        if (this.totalTracks === this.tracksMap.size) {
            this.continueShownOnce = true;
            for (let entry of this.tracksMap.entries()) {
                if (!entry[1]) {
                    this.showOverlay();
                    break;
                }
            }
        }
    }

    playTrack(trackId: string, element: HTMLVideoElement | HTMLAudioElement) {
        let playPromise = element.play();
        const setTracksPlayStatus = (succeeded: boolean) => {
            for (const track of (element.srcObject as MediaStream).getTracks()) {
                this.tracksMap.set(track.id, succeeded);
            }
        };
        if (playPromise) {
            playPromise
                .then(() => {
                    Log.i(LOGTAG, `${trackId} play succeeded.`);
                    if (element instanceof HTMLVideoElement) {
                        this.gridApp.toggleUserInput(true);
                    }
                    setTracksPlayStatus(true);
                    this.showContinueIfNeeded(true);
                })
                .catch((error: any) => {
                    Log.e(LOGTAG, `${trackId} play error: ${error?.name} , ${error?.message}`);
                    setTracksPlayStatus(false);
                    this.showContinueIfNeeded(false);
                });
        } else {
            if (element instanceof HTMLVideoElement) {
                this.gridApp.toggleUserInput(true);
            }
            setTracksPlayStatus(true);
            this.showContinueIfNeeded(true);
        }
    }

    playMedia() {
        for (let stream of this.startResult.streams!) {
            for (let track of stream.tracks) {
                if (track.kind === TrackType.VIDEO && videoElement.srcObject) {
                    this.playTrack(track!.trackId, videoElement);
                } else if (track.kind === TrackType.AUDIO && audioElement.srcObject) {
                    this.playTrack(track!.trackId, audioElement);
                }
            }
        }
    }

    playMediaOnContinue() {
        this.playMedia();
        this.hideOverlay();
    }

    showOverlay() {
        document.getElementById("overlay").style.display = "block";
    }

    hideOverlay() {
        document.getElementById("overlay").style.display = "none";
    }

    onSessionStartResult(eventData: StartSessionResultEvent) {
        lastSessionId = eventData.sessionId;
        lastSubSessionId = eventData.subSessionId;
        if (eventData.error) {
            if (eventData.error.code == RErrorCode.SessionLimitExceeded) {
                if (eventData.sessionList) {
                    Log.i(
                        LOGTAG,
                        "Session limit reached. Number of active sessions: " +
                            eventData.sessionList.length
                    );
                    if (eventData.sessionList.length) {
                        this.handleActiveSessions(eventData.sessionList);
                    }
                } else {
                    SetMessage("Checking for existing sessions..");
                    this.gridApp.getActiveSessions();
                }
                this.handleLogs(eventData.sessionId, eventData.subSessionId);
            } else if (eventData.error.code == RErrorCode.SessionLimitPerDeviceReached) {
                /* This case can only happen when the users close a tab while in streaming and logs in as different user.
                   There is no way to get the active session. Ideally PM should delete the other existing session,
                   as it has info of all sessions */
                SetMessage(
                    "There is an active session for this device belonging to another user. Please try again after sometime"
                );
                this.handleLogs(eventData.sessionId, eventData.subSessionId);
            } else if (
                eventData.error.code == RErrorCode.SessionSetupCancelled ||
                eventData.error.code == RErrorCode.SessionSetupCancelledDuringQueuing
            ) {
                DisplayErrorMessage(
                    "Session setup was cancelled, please reconfigure your session."
                );
                // DisplayScreen(DisplayDivs.SETTINGS_PAGE);
            } else {
                if (IsDocumentFullScreen()) {
                    Log.i(LOGTAG, " exiting fullscreen");
                    exitFullscreen();
                }
                if (eventData.error.code == RErrorCode.AuthTokenNotUpdated) {
                    if (navigator.onLine) {
                        // Need to relogin here.
                        Log.e(LOGTAG, "auth token update failure, relogin.");
                        DisplayErrorMessage("Authentication failure, please relogin");
                        LogoutAuthProvider(currentAuthProvider);
                        document.getElementById("okbutton").onclick = SetupComplete;
                        return;
                    } else {
                        Log.e(LOGTAG, "System not online, auth token update cannot be performed");
                        eventData.error.code = RErrorCode.NoNetwork;
                    }
                }
                let msg = this.getDisplayableErrorMessage(eventData.error.code);
                if (msg.length == 0) {
                    msg = "Session setup failed.";
                }

                // Retry a couple times in case the streaming isn't ready yet.
                if (this.sessionStartRetryLeft > 0) {
                    let loggingMsg = `
                        Connection attempt failed with error message '${msg}'. 
                        Number of retry left: ${this.sessionStartRetryLeft}. 
                        Retry in 5 seconds...`;
                    Log.e(LOGTAG, loggingMsg);
                    SetMessage("Connection in progress...");
                    this.sessionStartRetryLeft -= 1;
                    window.setTimeout(() => this.startButtonClick(), 5000);
                } else {
                    DisplayErrorMessage(msg + " " + GetHexString(eventData.error.code));
                }
                this.handleLogs(eventData.sessionId, eventData.subSessionId);
            }
        } else {
            Log.i(LOGTAG, "client: successfully set up session: " + eventData.sessionId);
            Log.i(LOGTAG, "Should enable mic: " + this.getGridApp().shouldDefaultEnableMic());
            // do not use default recommendation, since it is a test app for devs
            this.setMicRecordingEnabled(true);
            this.sessionId = eventData.sessionId;
            DisplayScreen(DisplayDivs.STREAM);
            this.startResult = eventData;
            Log.i(LOGTAG, "streams: " + JSON.stringify(this.startResult.streams));

            for (let stream of this.startResult.streams!) {
                this.totalTracks += stream.tracks.length;
            }

            document.getElementById("isso-gpu").textContent = eventData.gpuType;
            document.getElementById("isso-zone").textContent = eventData.zoneName;
            this.playMedia();
        }
    }

    private setMicRecordingEnabled(enabled: boolean) {
        if (this.gridApp.isMicSupported() && enabled !== this.micEnabled) {
            this.gridApp.setMicRecordingEnabled(enabled);
        }
    }

    public toggleMicrophone() {
        this.setMicRecordingEnabled(!this.micEnabled);
    }

    private handleLogs(sessionId: string, subSessionId: string) {
        if (this.isStreamTest) {
            this.downloadLogs();
        }
        logBuffer?.setIds(sessionId, subSessionId);
    }

    onSessionStopResult(eventData: StopSessionResultEvent) {
        lastSessionId = eventData.sessionId;
        lastSubSessionId = eventData.subSessionId;
        if (this.sessionId == eventData.sessionId) {
            this.sessionId = "";
            if (!this.isStreamTest || (this.isStreamTest && eventData.framesDecoded > 0)) {
                Log.i(LOGTAG, "Session stop result is normal");
            } else if (this.isStreamTest) {
                DisplayErrorMessage("Zero Frames");
            }
        } else if (this.sessionsToDelete > 0) {
            this.sessionsToDelete--;
            if (this.sessionsToDelete === 0) {
                //start new session, only used after getActive call
                this.begin();
            }
        }
        this.handleLogs(eventData.sessionId, eventData.subSessionId);
    }

    onProgressUpdate(eventData: SessionProgressUpdateEvent) {
        Log.i(LOGTAG, JSON.stringify(eventData));
        let message = "Setting up your session. state: " + eventData.state;

        if (eventData.state == SessionProgressState.STARTING_STREAMER) {
            message = "Starting streamer...";
        } else if (eventData.state == SessionProgressState.CONNECTING) {
            message = "Connecting to streamer...";
        }

        if (eventData.queuePosition) {
            message += ". QueuePosition: " + eventData.queuePosition;
        }
        if (eventData.eta > 1000) {
            message += ". ETA " + Math.round(eventData.eta / 1000) + "seconds";
        }

        SetMessage(message);
    }

    onStreamStopped(eventData: StreamingTerminatedEvent) {
        lastSessionId = eventData.sessionId;
        lastSubSessionId = eventData.subSessionId;
        this.resetTrackInfo();
        if (IsDocumentFullScreen()) {
            exitFullscreen();
        }

        if (eventData.error) {
            let resumeParam = GetQueryParam("resume");
            if (eventData.error.code == RErrorCode.ServerDisconnectedIntended) {
                DisplayErrorMessage("Server disconnected intended.");
                // DisplayScreen(DisplayDivs.SETTINGS_PAGE);
            } else if (!this.isStreamTest && resumeParam && eventData.isResumable) {
                //resuming after 15 secs
                Log.i(LOGTAG, "Resuming after 15 secs");
                DisplayLoadingMessage("Resuming after 15 secs...");
                window.setTimeout(() => this.gridApp.getActiveSessions(), 15000);
            } else {
                let msg = this.getDisplayableErrorMessage(eventData.error.code);
                if (msg.length == 0) {
                    msg = "Stream stopped.";
                }
                DisplayErrorMessage(msg + " " + GetHexString(eventData.error.code));
            }
        }
        this.handleLogs(eventData.sessionId, eventData.subSessionId);
    }

    onActiveSessionResult(result: ActiveSessionResultEvent) {
        if (result.error) {
            Log.e(
                LOGTAG,
                "ActiveSession request failed. Error: " +
                    result.error.code.toString(16).toUpperCase()
            );
            DisplayErrorMessage("Get Active session failed");
        } else {
            Log.i(
                LOGTAG,
                "ActiveSession request succeeded. Number of active sessions: " +
                    result.sessionList.length
            );

            if (result.sessionList.length) {
                this.handleActiveSessions(result.sessionList);
            } else {
                this.begin();
            }
        }
    }

    onAnalyticsEvent(event: TelemetryEvent) {
        Log.i(LOGTAG, "Analytics event: " + JSON.stringify(event));
    }

    onStreamingEvent(event: StreamingEvent) {
        Log.i(LOGTAG, "StreamingEvent event: " + JSON.stringify(event));
        if (event.streamingState) {
            if (event.streamingState.state === STREAMING_STATE.RECONNECTING) {
                DisplayLoadingMessage("Reconnecting...");
            } else if (event.streamingState.state === STREAMING_STATE.RECONNECTED) {
                DisplayScreen(DisplayDivs.STREAM);
            }
        }
    }

    onGetSessionResult(event: GetSessionResult) {
        if (event.error) {
            Log.e(LOGTAG, "Cannot resume: " + event.error.code);
            DisplayErrorMessage(event.error.description + ": " + event.error.code);
        } else {
            Log.i(LOGTAG, "onGetSessionResult state: " + event.state);
            if (event.state == SessionState.INITIALIZING || event.state == SessionState.RESUMING) {
                //wait for ready_for_connection
                window.setTimeout(() => this.gridApp.getSession(event.sessionId), 1000);
            } else if (event.state == SessionState.READY_FOR_CONNECTION) {
                this.gridApp.resumeSession(this.sessionStartParams, event.sessionId);
            }
        }
    }

    onCustomMessage(msg: CustomMessage) {
        if (msg.data == "OK") {
            return;
        }

        Log.i(LOGTAG, JSON.stringify(msg));

        // sample acknowledgement message
        const message: CustomMessage = {
            messageType: "customMessage",
            messageRecipient: "Serenity",
            data: '{"method":"POST","url":"data://Serenity/GfnEvent/v.1.0/BusMessage","data":{"event":"testing"}}'
        };

        this.gridApp.sendCustomMessage(message);
    }

    onTextComposition(event: TextCompositionEvent) {
        if (event.compositionText) {
            compositionElement.innerHTML = "Composition: " + event.compositionText;
        } else {
            compositionElement.innerHTML = "";
        }
    }

    onMicCapture(event: MicStateEvent) {
        if (event.state === MicState.STARTED) {
            micButton.style.backgroundColor = "red";
            this.micEnabled = true;
        } else {
            micButton.style.backgroundColor = "white";
            this.micEnabled = false;
        }
    }

    public downloadLogs() {
        logBuffer?.download();
    }

    public uploadLogs() {
        logBuffer?.upload(userId);
    }

    public downloadAudioRecordings() {
        this.gridApp?.downloadAudio();
    }

    // private handleExitEvent(event: RagnarokStreamExitEvent): boolean {
    //     Log.i(LOGTAG, "handleExitEvent: " + JSON.stringify(event));
    //     return true;
    // }

    private getPlatformOverride(
        clientPlatformName: ClientPlatformName | "",
        clientType: ClientType | "",
        deviceOS: DeviceOS | "",
        deviceType: DeviceType | "",
        clientStreamer: ClientStreamer | ""
    ) {
        return (
            "clientplatformname=" +
            clientPlatformName +
            "&clienttype=" +
            clientType +
            "&deviceos=" +
            deviceOS +
            "&devicetype=" +
            deviceType +
            "&clientstreamer=" +
            clientStreamer
        );
    }

    private getPlatformAndAppLaunchOverrides(
        platformSimulationValue: number,
        applaunchModeSimulationValue: number
    ): string {
        let overrideString = "";

        let platformOverrides = "";
        switch (platformSimulationValue) {
            case 0: // no simulation
                if (this.hasPlatformBeenSimulated) {
                    // ragnarok default platform selection
                    this.getPlatformOverride("", "", "", "", "");
                }
                break;
            case 1: // native windows
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.WINDOWS,
                    ClientType.NATIVE,
                    DeviceOS.WINDOWS,
                    DeviceType.DESKTOP,
                    ClientStreamer.CLASSIC
                );
                break;
            case 2: // native mac
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.MACOS,
                    ClientType.NATIVE,
                    DeviceOS.MACOS,
                    DeviceType.DESKTOP,
                    ClientStreamer.CLASSIC
                );
                break;
            case 3: // native android
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.LINUX,
                    ClientType.NATIVE,
                    DeviceOS.ANDROID,
                    DeviceType.TABLET,
                    ClientStreamer.CLASSIC
                ); // no match for android tv
                break;
            case 4: // ios web
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.IOS,
                    DeviceType.PHONE,
                    ClientStreamer.WEBRTC
                );
                break;
            case 5: // ipados web
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.IPADOS,
                    DeviceType.TABLET,
                    ClientStreamer.WEBRTC
                );
                break;
            case 6: // Chrome OS
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.CHROMEOS,
                    DeviceType.DESKTOP,
                    ClientStreamer.WEBRTC
                );
                break;
            case 7: // windows web
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.WINDOWS,
                    DeviceType.DESKTOP,
                    ClientStreamer.WEBRTC
                );
                break;
            case 8: // mac web
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.MACOS,
                    DeviceType.DESKTOP,
                    ClientStreamer.WEBRTC
                );
                break;
            case 9: // linux web
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.LINUX,
                    DeviceType.DESKTOP,
                    ClientStreamer.WEBRTC
                );
                break;
            case 10: // TV-LG
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.WEBOS,
                    DeviceType.TV,
                    ClientStreamer.WEBRTC
                );
                break;
            case 11: // TV-Samsung
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.TIZEN,
                    DeviceType.TV,
                    ClientStreamer.WEBRTC
                );
                break;
            case 12: // Xbox
                platformOverrides = this.getPlatformOverride(
                    ClientPlatformName.BROWSER,
                    ClientType.BROWSER,
                    DeviceOS.XBOX,
                    DeviceType.CONSOLE,
                    ClientStreamer.WEBRTC
                );
                break;
        }

        if (platformOverrides) {
            this.hasPlatformBeenSimulated = true;
            overrideString += platformOverrides + "&";
        }

        let appLaunchMode: AppLaunchMode | undefined = undefined;
        switch (applaunchModeSimulationValue) {
            case 0:
                if (this.hasAppLaunchBeenSimulated) {
                    appLaunchMode = AppLaunchMode.Default;
                }
                break;
            case 1:
                appLaunchMode = AppLaunchMode.Default;
                break;
            case 2:
                appLaunchMode = AppLaunchMode.TouchFriendly;
                break;
            case 3:
                appLaunchMode = AppLaunchMode.GamepadFriendly;
                break;
        }
        if (appLaunchMode !== undefined) {
            this.hasAppLaunchBeenSimulated = true;
            overrideString += "applaunchmode=" + appLaunchMode + "&";
        }

        return overrideString + "webrtcstreamer=enable";
    }

    configureGridApp(server): Promise<void> {
        return new Promise((resolve, reject) => {
            // Note: JarvisAuth object is created even before initialization.
            // we will always have the deviceid generated by now.
            // todo: We should move everything to indexdb to support clients on all browsers.
            this.updateEventDataElements();
            let deviceId = "";
            if (typeof Storage !== "undefined") {
                deviceId = localStorage.getItem("deviceid");
            }

            let gridAppInitParams = {
                serverAddress: server,
                authTokenCallback: authProviders[currentAuthProvider].getTokenCallback(),
                deviceHashId: deviceId,
                textInputElement: hiddenTextElement,
                clientShutDownCallback: undefined, //this.handleExitEvent.bind(this),
                createTracer: createTracer
            };
            let params = new URLSearchParams(location.search);
            let cursorType: CursorType;
            switch (params.get("cursor")) {
                case "hw":
                    cursorType = CursorType.HARDWARE;
                    break;
                case "free":
                    cursorType = CursorType.FREE;
                    break;
            }
            let inputConfigFlags: InputConfigFlags = {
                cursorType: cursorType,
                allowUnconfined: params.get("allowunconfined") !== null
            };

            Log.i(LOGTAG, "initializing gridApp with server: " + gridAppInitParams.serverAddress);
            if (!this.gridApp.initialize(gridAppInitParams, inputConfigFlags)) {
                reject();
                return;
            }

            authProviders[currentAuthProvider]
                .getAuthInfo()
                .then((authInfo: AuthInfo) => {
                    this.gridApp.setAuthInfo(authInfo);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    getGridApp(): GridApp {
        return this.gridApp;
    }

    begin() {
        this.gridApp.startSession(this.sessionStartParams);
        if (isSendVideoTrack) {
            window.requestAnimationFrame(canvasAnimation);
        }
    }

    async startButtonClick() {
        initISSO();
        initIGO();

        const platformSimulationValue = parseInt(
            platformSimulationElement.options[platformSimulationElement.selectedIndex].value
        );
        const applaunchModeSimulationValue = parseInt(
            applaunchModeSimulationElement.options[applaunchModeSimulationElement.selectedIndex]
                .value
        );

        let overrideDataString = this.getPlatformAndAppLaunchOverrides(
            platformSimulationValue,
            applaunchModeSimulationValue
        );

        const codecList = [];
        for (const element of codecElements) {
            if (!element.value) {
                break;
            }
            codecList.push(element.value);
        }

        if (codecList.length) {
            Log.i(LOGTAG, "Overriding codec list to: " + codecList);
            overrideDataString += "&codecList=" + codecList.toString();
        }

        const clientConfigOverride = clientConfigElement.value;
        const configData: RagnarokConfigData = {
            // This is a string in the standard nvscClientConfig.txt form, like:
            // "general.testSetting:1\n
            // video[0].videoSetting:2"
            clientConfigOverride,
            overrideData: overrideDataString
        };
        const gxtConfig = (<HTMLTextAreaElement>document.getElementById("gxt-config")).value.trim();
        if (gxtConfig) {
            configData.gxtOverrideData = gxtConfig;
        }
        // TODO: Can we configure all ragnarok settings right here?
        ConfigureRagnarokSettings(configData);

        let server = serverAddress;

        if (!server || server.includes("mockpm://") || IsValidIPv4(server)) {
            // Passthrough and MockPM sessions do not need a real auth provider
            currentAuthProvider = DUMMY_PROVIDER;
            if (!authProviders[currentAuthProvider]) {
                authProviders[currentAuthProvider] = new DummyAuth();
            }
        }

        try {
            if (this.isStreamTest) {
                window.setTimeout(() => this.stopButtonClick(), 25000);
            }

            let _appId = 123456;

            if (fsSettingElement.checked == true) {
                enterFullScreenPage();
            }

            let selectedResolutionValue =
                resolutionElem.options[resolutionElem.selectedIndex].value;
            let selectedResolution = selectedResolutionValue.split(":");
            let fpsValue = fpsElement.options[fpsElement.selectedIndex].value;
            const bitrateValue = bitrateElement.options[bitrateElement.selectedIndex].value;

            let kbLayout = kbLayoutElement.value;

            if (typeof Storage !== "undefined") {
                //save back all the settings.
                localStorage.setItem("server", server);
                localStorage.setItem("fps", fpsValue);
                localStorage.setItem("kbLayout", kbLayout);
                localStorage.setItem("fullscreen", fsSettingElement.checked);
                localStorage.setItem("bitrate", bitrateValue);
                if (persistClientConfigElement.checked) {
                    localStorage.setItem("persist-client-config", "true");
                    localStorage.setItem("client-config", clientConfigOverride);
                } else {
                    localStorage.removeItem("persist-client-config");
                    localStorage.removeItem("client-config");
                }
            }

            this.configureGridApp(server)
                .then(() =>
                    currentAuthProvider === DUMMY_PROVIDER
                        ? [false]
                        : Promise.all(
                              accountProviders.map(provider =>
                                  provider
                                      .isAccountLinkedForGame(
                                          authProviders[currentAuthProvider],
                                          _appId
                                      )
                                      .catch(() => false)
                              )
                          )
                )
                .then(linked => {
                    return linked.includes(true);
                })
                .then(accountLinked => {
                    Log.i(LOGTAG, "grid app setup completed");
                    const enableTouch = IsiDevice(platformDetails);

                    this.sessionStartParams = {
                        // appId: _appId,
                        streamParams: [],
                        appLaunchMode: enableTouch
                            ? AppLaunchMode.TouchFriendly
                            : AppLaunchMode.Default,
                        keyboardLayout: kbLayout,
                        shortName: "Omniverse App Streaming",
                        accountLinked: accountLinked
                        // enablePersistingInGameSettings: persistInGameSettingElement.checked
                    };

                    let _width = parseInt(selectedResolution[0]);
                    let _height = parseInt(selectedResolution[1]);
                    let _fps = parseInt(fpsValue);
                    const _bitrate = parseInt(bitrateValue);

                    if (!_width || !_height) {
                        const candidateSettings: StreamingSettings[] = [
                            { resolution: { width: 3840, height: 2160 }, frameRate: 60 },
                            { resolution: { width: 3440, height: 1440 }, frameRate: 60 },
                            { resolution: { width: 2560, height: 1600 }, frameRate: 120 },
                            { resolution: { width: 2560, height: 1600 }, frameRate: 60 },
                            { resolution: { width: 2560, height: 1440 }, frameRate: 120 },
                            { resolution: { width: 2560, height: 1440 }, frameRate: 60 },
                            { resolution: { width: 1920, height: 1200 }, frameRate: 60 },
                            { resolution: { width: 1920, height: 1080 }, frameRate: 60 },
                            { resolution: { width: 1920, height: 1080 }, frameRate: 30 },
                            { resolution: { width: 1920, height: 1080 }, frameRate: 120 },
                            { resolution: { width: 1680, height: 1050 }, frameRate: 60 },
                            { resolution: { width: 1600, height: 900 }, frameRate: 60 },
                            { resolution: { width: 1280, height: 1024 }, frameRate: 60 },
                            { resolution: { width: 1440, height: 900 }, frameRate: 60 },
                            { resolution: { width: 1366, height: 768 }, frameRate: 60 },
                            { resolution: { width: 1280, height: 800 }, frameRate: 60 },
                            { resolution: { width: 1280, height: 720 }, frameRate: 60 },
                            { resolution: { width: 1600, height: 1200 }, frameRate: 60 },
                            { resolution: { width: 1112, height: 834 }, frameRate: 60 },
                            { resolution: { width: 1024, height: 768 }, frameRate: 60 },
                            { resolution: { width: 960, height: 540 }, frameRate: 60 }
                        ];
                        const settings = ChooseStreamingSettings(
                            StreamingProfilePreset.BALANCED,
                            candidateSettings,
                            platformDetails
                        );
                        _width = settings.resolution.width;
                        _height = settings.resolution.height;
                        Log.i(
                            LOGTAG,
                            "Recommended stream settings: " +
                                _width +
                                "x" +
                                _height +
                                "@" +
                                settings.frameRate
                        );
                        Log.i(LOGTAG, "Capable stream settings: " + candidateSettings);
                    }

                    let canvasVideoTrack;
                    if (isSendVideoTrack) {
                        let canvasElement = document.createElement("canvas");
                        let canvasStream = canvasElement.captureStream();
                        canvasCtx = canvasElement.getContext("2d");
                        canvasVideoTrack = canvasStream.getVideoTracks()[0];
                    }

                    this.sessionStartParams.streamParams.push({
                        width: _width,
                        height: _height,
                        fps: _fps,
                        maxBitrateKbps: _bitrate,
                        drc: drc,
                        videoTagId: "remote-video",
                        audioTagId: "remote-audio",
                        sendVideoTrack: canvasVideoTrack
                    });

                    const bitrateMbps = _bitrate / 1000; // Convert Kbps to Mbps
                    // Set the inital setting status to IGO
                    igoNetworkCheckbox.checked = drc;
                    igoMaxbitSpan.textContent = bitrateMbps.toString();
                    igoMaxbitSlider.value = bitrateMbps;

                    if (streamerSelectElement.value) {
                        this.sessionStartParams.metaData = {
                            "streamServerBackendMode": streamerSelectElement.value
                        };
                    }

                    // For non-iPv4 passthru users should provide the following query parameters
                    // signalingserver=XX&signalingport=XX&mediaserver=XX&mediaport=XX
                    const signalingServer = GetQueryParam("signalingserver");
                    let customConnectionInfo = [];
                    if (signalingServer) {
                        const signalingPort = parseInt(GetQueryParam("signalingport"));
                        // Use secure websockets by default, only in case of IP based connection use unsecure websockets.
                        const protocol = IsValidIPv4(signalingServer)
                            ? AppLevelProtocol.HTTP
                            : AppLevelProtocol.HTTPS;
                        customConnectionInfo.push({
                            ip: signalingServer,
                            port: signalingPort,
                            usage: Usage.SIGNALING,
                            appLevelProtocol: protocol,
                            protocol: Protocol.TCP
                        });
                    }
                    const mediaServer = GetQueryParam("mediaserver");
                    if (mediaServer) {
                        if (IsIPv4Address(mediaServer) && !IsValidIPv4(mediaServer)) {
                            Log.e(
                                LOGTAG,
                                "mediaserver query parameter is not a valid IP. value: " +
                                    mediaServer
                            );
                        } else {
                            const mediaPort = parseInt(GetQueryParam("mediaport"));
                            customConnectionInfo.push({
                                ip: mediaServer,
                                port: mediaPort,
                                usage: Usage.VIDEO,
                                appLevelProtocol: AppLevelProtocol.UNKNOWN,
                                protocol: Protocol.UDP
                            });
                        }
                    }

                    if (!server) {
                        if (customConnectionInfo.length) {
                            this.sessionStartParams.connectionInfo = customConnectionInfo;
                        } else {
                            const msg =
                                "Signaling server info is required in passthru use case. Please add signalingserver=<> to the query parameters";
                            DisplayErrorMessage(msg);
                            return;
                        }

                        const customSessionId = GetQueryParam("sessionid");
                        if (customSessionId) {
                            this.sessionStartParams.sessionId = customSessionId;
                        }
                    } else {
                        this.sessionStartParams.connectionInfo = undefined;
                        this.sessionStartParams.sessionId = undefined;
                    }

                    Log.i(LOGTAG, "Beginning");
                    this.begin();
                })
                .catch(err => {
                    const msg = `Something unexpected happened: ${err?.name} ${err?.message}`;
                    Log.e(LOGTAG, msg);
                    DisplayErrorMessage(msg);
                    isCancelCanvasAnimation = true;
                });
        } catch (exp) {
            Log.e(LOGTAG, "Exception in start button click: " + exp);
            isCancelCanvasAnimation = true;
        }
    }

    stopButtonClick(pause: boolean = false, terminating: boolean = false) {
        this.resetTrackInfo();
        if (this.sessionId) {
            if (pause) {
                this.gridApp.pauseSession(this.sessionId);
            } else {
                this.gridApp.stopSession(this.sessionId);
            }
        }
        if (!terminating) {
            DisplayScreen(DisplayDivs.TRANSITION_PAGE);
        }
        isCancelCanvasAnimation = true;
    }
}

function initISSO() {
    issoEnabled = false;
    showISSO(issoEnabled);
}

function initIGO() {
    igoMaxBitrateDirty = false;
    igoNetworkDirty = false;
    igoEnabled = false;
    showIGO(false);
}

function showISSO(show: boolean) {
    document.getElementById("isso").style.display = show ? "block" : "none";
}

function showIGO(show: boolean) {
    document.getElementById("igo").style.display = show ? "block" : "none";
}

function saveIGO() {
    if (igoMaxBitrateDirty) {
        gfnpcApp.getGridApp().setStreamingMaxBitrate(Number(igoMaxbitSlider.value) * 1000);
        igoMaxBitrateDirty = false;
    }
    if (igoNetworkDirty) {
        gfnpcApp.getGridApp().setDrcDfcState(igoNetworkCheckbox.checked);
        igoNetworkDirty = false;
    }
}

function onISSOUpdate(stats: any) {
    document.getElementById("isso-fps").textContent = stats.fps.toString();
    document.getElementById("isso-rtd").textContent = stats.rtd.toString();
    document.getElementById("isso-decodeTimeAvg").textContent = stats.avgDecodeTime.toFixed(2);
    if (stats.avgDecodeTime > 12) {
        document.getElementById("isso-decodeTimeAvg").style.color = "red";
        document.getElementById("isso-decodeTimeAvg").style.fontWeight = "bold";
        document.getElementById("isso-decodeTimeAvg").textContent +=
            " (High decode cost - reduce GPU load)";
    } else {
        document.getElementById("isso-decodeTimeAvg").style.color = "white";
        document.getElementById("isso-decodeTimeAvg").style.fontWeight = "normal";
    }
    document.getElementById("isso-frameloss").textContent = stats.frameLoss.toString();
    document.getElementById("isso-packetloss").textContent = stats.packetLoss.toString();
    document.getElementById("isso-totalbandwidth").textContent =
        stats.totalBandwidth.toFixed(2) + " Mbps";
    if (stats.totalBandwidth < 25) {
        document.getElementById("isso-totalbandwidth").style.color = "red";
        document.getElementById("isso-totalbandwidth").style.fontWeight = "bold";
        document.getElementById("isso-totalbandwidth").textContent +=
            " (Insufficient network bandwidth)";
    } else {
        document.getElementById("isso-totalbandwidth").style.color = "white";
        document.getElementById("isso-totalbandwidth").style.fontWeight = "normal";
    }
    document.getElementById("isso-usedbandwidth-mbps").textContent = (
        (stats.utilizedBandwidth * stats.totalBandwidth) /
        100
    ).toFixed(2);
    document.getElementById("isso-usedbandwidth").textContent = stats.utilizedBandwidth.toFixed();
    document.getElementById(
        "isso-streamingresolution"
    ).textContent = `${stats.streamingResolution.width}x${stats.streamingResolution.height}`;
    if (stats.streamingResolution.height < 1080) {
        document.getElementById("isso-streamingresolution").style.color = "red";
        document.getElementById("isso-streamingresolution").style.fontWeight = "bold";
        document.getElementById("isso-streamingresolution").textContent +=
            " Degradation in resolution";
        if (stats.totalBandwidth < 25 && stats.avgDecodeTime > 5) {
            document.getElementById("isso-streamingresolution").textContent +=
                " due to high decode cost and low network bandwidth";
        } else {
            if (stats.totalBandwidth < 25) {
                document.getElementById("isso-streamingresolution").textContent +=
                    " due to low network bandwidth";
            }
            if (stats.avgDecodeTime > 5) {
                document.getElementById("isso-streamingresolution").textContent +=
                    " due to high decode cost";
            }
        }
    } else {
        document.getElementById("isso-streamingresolution").style.color = "white";
        document.getElementById("isso-streamingresolution").style.fontWeight = "normal";
    }
}

function updateStreamingPage() {
    const visualViewport = (<any>window).visualViewport;
    // Force buttons to show in portrait mode when we're using fake fullscreen. This makes sure the user
    // can stop the stream.
    const portraitForced =
        useFakeFullScreen() && visualViewport
            ? visualViewport.width < visualViewport.height
            : false;
    const showButtons = !IsDocumentFullScreen() || portraitForced;
    if (showButtons) {
        document.getElementById("streamtransition").style.display = "flex";
        videoElement.style.marginLeft = "15%";
        videoElement.style.width = "70%";
        videoElement.style.height = defaultVideoHeight;
        showStatusBar();
        hideStatusToggleButton();
    } else {
        document.getElementById("streamtransition").style.display = "none";
        videoElement.style.marginLeft = "0px";
        videoElement.style.width = "100%";
        videoElement.style.height = "100%";
        showStatusBarIfNeeded();
        showStatusToggleButtonIfNeeded();
    }
}

function fullscreenchangeEventHandler(event) {
    // if (IsDocumentFullScreen()) {
    //     fsbuttonLoadingPage.style.visibility = "hidden";
    //     //gfnpcApp.getGridApp().toggleUserInput(true);
    // } else {
    //     if (currentDivIndex == DisplayDivs.LOADING_PAGE) {
    //         fsbuttonLoadingPage.style.visibility = "visible";
    //     }
    //     //gfnpcApp.getGridApp().toggleUserInput(false);
    // }

    updateStreamingPage();
}

function stringToUtf8(text: string): ArrayBuffer {
    let uint8 = new TextEncoder().encode(text);
    let arraybuff = new ArrayBuffer(uint8.byteLength);
    let byteView = new DataView(arraybuff);
    for (let i = 0; i < uint8.byteLength; i++) {
        byteView.setUint8(i, uint8[i]);
    }
    return arraybuff;
}

function keydownEventHandler(evt: KeyboardEvent) {
    if (evt.ctrlKey && evt.altKey && evt.key == "m") {
        // ctrl + alt + m trigger isso toggling
        issoEnabled = !issoEnabled;
        showISSO(issoEnabled);
        evt.stopPropagation();
    }
    if (evt.ctrlKey && evt.altKey && evt.shiftKey && evt.key == "V") {
        // ctrl + shift + alt + V
        igoEnabled = !igoEnabled;
        showIGO(igoEnabled);
        gfnpcApp.getGridApp().toggleUserInput(!igoEnabled);
        if (!igoEnabled) {
            saveIGO();
        }
        evt.stopPropagation();
    }
}

// Ragnarok doesnt prevent default action on any keys. Its upto client to decide which browser keys combination is allowed.
function keyupdownEventHandler(evt) {
    if (
        !(evt.keyCode == 73 && evt.ctrlKey && evt.shiftKey) /* ctrl + shift + i - windows */ &&
        !(evt.keyCode == 67 && evt.shiftKey && evt.metaKey) /* shift + command + c - mac*/ &&
        !(evt.keyCode == 68 && evt.ctrlKey && evt.shiftKey) /* ctrl + shift + d */ &&
        !(evt.code == "Tab" && evt.altKey) /* alt + tab - chromeos*/ &&
        !(evt.code == "ZoomToggle") /* media key - chromeos */ &&
        !(evt.code == "SelectTask") /* media key - chromeos */ &&
        !(evt.code == "BrightnessDown") /* media key - chromeos */ &&
        !(evt.code == "BrightnessUp") /* media key - chromeos */ &&
        !(evt.code == "AudioVolumeMute") /* media key - chromeos */ &&
        !(evt.code == "AudioVolumeDown") /* media key - chromeos */ &&
        !(evt.code == "AudioVolumeUp") /* media key - chromeos */ &&
        !(
            evt.keyCode >= 48 && evt.keyCode <= 57
        ) /* digits 0-9; for specifying loupe position of ldat */ &&
        !(evt.code == "Backspace")
    ) {
        evt.preventDefault();
    }

    if (evt.keyCode == 68 && evt.ctrlKey && evt.shiftKey) {
        let text = prompt("Enter Client Ime text", "");
        if (text) {
            gfnpcApp.getGridApp().sendTextInput(stringToUtf8(text));
        }
    }
}

function pasteClipboardText() {
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
            .readText()
            .then((text?: string) => {
                if (text) {
                    Log.i(LOGTAG, "Sent clipboard text to the server.");
                    gfnpcApp.getGridApp().sendTextInput(stringToUtf8(text));
                }
            })
            .catch(err => {
                Log.e(LOGTAG, "Failed to read clipboard contents: " + err);
            });
    } else {
        Log.i(LOGTAG, "Cannot read from clipboard because in insecure context.");
    }
}

// async function FilterForNumberKeys(evt: KeyboardEvent) {
//     if (!evt.key.match(/^\d$/)) {
//         evt.preventDefault();
//     }
// }

function onLogEvent(event: LogEvent) {
    if (logBuffer) {
        // Only include DEBUG messages when the client is run in debug mode.
        if (event.logLevel !== LogLevel.DEBUG || inDebugMode) {
            logBuffer.log(
                `${event.timeStamp} ${event.logLevel} ${event.logModule}/${event.logtag} ${event.logstr}`
            );
        }
    } else {
        // This code should only run for queued logs that happen before the consoleLoggingEnabled flag is
        // finally set.  After that, if console logging is enabled, the Log infrastructure is mostly bypassed
        // and logs happen directly to the console.
        switch (event.logLevel) {
            case LogLevel.INFO:
                console.log(
                    `${event.timeStamp} ${event.logLevel} ${event.logModule}/${event.logtag} ${event.logstr}`
                );
                break;
            case LogLevel.WARN:
                console.warn(
                    `${event.timeStamp} ${event.logLevel} ${event.logModule}/${event.logtag} ${event.logstr}`
                );
                break;
            case LogLevel.DEBUG:
                console.debug(
                    `${event.timeStamp} ${event.logLevel} ${event.logModule}/${event.logtag} ${event.logstr}`
                );
                break;
            case LogLevel.ERROR:
                console.error(
                    `${event.timeStamp} ${event.logLevel} ${event.logModule}/${event.logtag} ${event.logstr}`
                );
                break;
        }
    }
}

function showStatusBar() {
    statusBarPlaceholder.style.display = "block";
    divs[DisplayDivs.STREAM].style.top = "env(safe-area-inset-top)";
    divs[DisplayDivs.STREAM].style.height = "100%";
}

function hideStatusBar() {
    statusBarPlaceholder.style.display = "none";
    divs[DisplayDivs.STREAM].style.top = "0px";
    divs[DisplayDivs.STREAM].style.height = "calc(100% + env(safe-area-inset-top))";
}

function showStatusBarIfNeeded() {
    if (sbSettingElement.checked) {
        showStatusBar();
    } else {
        hideStatusBar();
    }
}

function showStatusToggleButton() {
    statusToggleButton.style.display = "inline-block";
}

function hideStatusToggleButton() {
    statusToggleButton.style.display = "none";
}

function showStatusToggleButtonIfNeeded() {
    if (IsiPadOS(platformDetails)) {
        showStatusToggleButton();
    } else {
        hideStatusToggleButton();
    }
}

async function terminateOmniverseStreamingSession() {
    if (
        confirm(
            "Terminating session will destroy all associated resources and is irrecoverable, unsaved changes will be lost, do you confirm you want to terminate it?"
        )
    ) {
        gfnpcApp.stopButtonClick(false, true);

        // Old version of the saas backend expects GET method while new version
        // expects DELETE method and will provide that explicitly.
        // TODO: Only keep the DELETE approach once the callsite migration is done.
        let terminateVerb = GetQueryParam("terminateVerb") || "GET";

        if (terminateVerb !== "GET" && terminateVerb !== "DELETE") {
            let message = `Expect the HTTP verb used to terminate session to be either GET or DELETE, but received ${terminateVerb} instead.`;
            Log.e(LOGTAG, message);
            DisplayErrorMessage(message);
            return;
        }

        let response = null;
        if (terminateVerb === "DELETE") {
            response = await fetch(`https://${GetQueryParam("backendurl")}/stream`, {
                method: terminateVerb,
                headers: {
                    "Authorization": `Bearer ${GetQueryParam("accessToken")}`,
                    "accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ "session_id": `${GetQueryParam("sessionid")}` })
            });
        } else {
            response = await fetch(
                `https://${GetQueryParam("backendurl")}/logout?session_id=${GetQueryParam(
                    "sessionid"
                )}&server=${GetQueryParam("nucleus")}`,
                {
                    method: terminateVerb,
                    headers: {
                        "Authorization": `Bearer ${GetQueryParam("accessToken")}`
                    }
                }
            );
        }

        const result = await response.json();
        if (response.status !== 200) {
            Log.e(LOGTAG, `Session termination failed due to '${result.message}'`);
            DisplayErrorMessage(
                `Session termination failed due to '${result.message}', please contact maintainer for further debugging.`,
                false
            );
        } else {
            DisplayErrorMessage(
                "The session has been terminated, you can safely close this window now.",
                false
            );
        }
    }
}

function Setup() {
    const hostedBuild =
        window.location.hostname.includes(".net") ||
        window.location.hostname.includes(".com") ||
        window.location.hostname.includes(".org");

    // Pass the query parameters from URL as override data.
    let overrideData = new URLSearchParams(location.search);

    inDebugMode = toBool(GetQueryParam("debug"));

    // Enable console logs only for local builds. Hosted builds will buffer logs
    // so they can be downloaded by the user.
    const isStreamTest = overrideData.get("test") === "true";
    let logfile: undefined | string = undefined;
    if (isStreamTest) {
        overrideData.set("console", "0");
        overrideData.set("allowtelemetry", "0");
        logfile = "streamtest.log";
    } else if (!hostedBuild && !overrideData.has("console")) {
        overrideData.set("console", "1");
    }
    // Enable dev mode for ragnarok client by default if no mode is set
    if (!overrideData.has("mode")) {
        overrideData.set("mode", "dev");
    }
    // Only enable log buffering if console logs are disabled. We can't intercept console logs.
    // if (["enable", "on", "1", "true"].includes(overrideData.get("console"))) {
    //     document.getElementById("downloadlogs").style.display = "none";
    //     document.getElementById("uploadlogs").style.display = "none";
    // } else {
    //     logBuffer = new LogBuffer(getLogSize(), logfile);
    // }

    logBuffer = new LogBuffer(getLogSize(), logfile);

    // Get Send Video Track option from the query parameters
    isSendVideoTrack = overrideData.get("sendVideoTrack") === "true";

    // Should be set up after logBuffer has been created (if needed), otherwise
    // some logs will go to the console.
    Log.addListener(EVENTS.LOG_EVENT, onLogEvent);

    getPlatformDetails()
        .then((details: PlatformDetails) => {
            platformDetails = details;
            document.addEventListener("contextmenu", function (event) {
                if (!IsXbox(platformDetails)) {
                    event.preventDefault();
                }
            });
            SetupImpl(overrideData, isStreamTest, hostedBuild);
        })
        .catch(e => {
            Log.e(LOGTAG, e);
            DisplayErrorMessage(`Error happened during setup: ${e}`);
        });
}

// Remainder of Setup, happens after Platform API has completed.
// overrideData - the URL parameters, unparsed (?<key1>=<value1>&<key2>=<value2>...)
// isStreamTest - streaming test; limited-time run that logs to file
// hostedBuild  - whether the build is hosted or a local setup
function SetupImpl(overrideData: URLSearchParams, isStreamTest: boolean, hostedBuild: boolean) {
    Log.i(LOGTAG, "location is " + location);
    IsSafari = /^(?!.*chrome).*safari/i.test(navigator.userAgent);
    Log.i(LOGTAG, " is safari: " + IsSafari);
    window.name = "Streaming Client";
    statusBarPlaceholder = document.getElementById("status-bar-placeholder");
    let versionElement = document.getElementById("version");
    lastSessionIdElement = document.getElementById("lastsessionid");
    lastSubSessionIdElement = document.getElementById("lastsubsessionid");
    versionElement.innerHTML = "Version: " + CHANGELIST;
    Log.i(LOGTAG, "Test App Version: " + CHANGELIST);
    messageElement = document.getElementById("message");
    resolutionElem = document.getElementById("resolution");
    fpsElement = document.getElementById("fps");
    bitrateElement = <HTMLSelectElement>document.getElementById("bitrate");
    platformSimulationElement = <HTMLSelectElement>document.getElementById("platform-options");
    applaunchModeSimulationElement = <HTMLSelectElement>(
        document.getElementById("applaunchmode-options")
    );
    kbLayoutElement = document.getElementById("kb-layout");
    fsSettingElement = document.getElementById("fullscreensetting");
    clientConfigElement = <HTMLTextAreaElement>document.getElementById("client-config");
    persistClientConfigElement = <HTMLInputElement>document.getElementById("persist-client-config");
    rrlSettingElement = document.getElementById("refreshRateLoopsetting");
    sbSettingElement = document.getElementById("statusbarsetting");
    let sbSettingLabelElement = document.getElementById("statusbarsettinglabel");
    if (IsiPadOS(platformDetails)) {
        sbSettingElement.style.display = "inline-block";
        sbSettingLabelElement.style.display = "inline-block";
    } else {
        sbSettingElement.style.display = "none";
        sbSettingLabelElement.style.display = "none";
    }
    document.getElementById("drc-setting").onchange = (e: Event) => {
        drc = (e.target as HTMLInputElement).checked;
    };
    videoElement = document.getElementById("remote-video");
    audioElement = document.getElementById("remote-audio");
    defaultVideoHeight = videoElement.style.height;
    videoDiv = document.getElementById("stream");
    errorMessageElement = document.getElementById("errormessage");
    // fsbuttonLoadingPage = document.getElementById("fsloading");
    // fsbuttonLoadingPage.onclick = enterFullScreenPage;
    document.getElementById("fsbutton").onclick = enterFullScreenVideo;
    document.getElementById("okbutton").onclick = () => {
        gfnpcApp.startButtonClick();
    };
    document.getElementById("metrics-button").onclick = () => {
        issoEnabled = !issoEnabled;
        showISSO(issoEnabled);
    };
    streamerSelectElement = document.getElementById("streamer-select");

    let kbElement = document.getElementById("kbbutton");
    let pasteButton = document.getElementById("pastebutton");
    hiddenTextElement = document.getElementById("hiddentext");
    compositionElement = document.getElementById("compositiondisplay");
    micButton = document.getElementById("micbutton");

    if (IsiDevice(platformDetails) && window.isSecureContext) {
        micButton.style.display = "block";
        pasteButton.style.display = "block";
        pasteButton.onclick = pasteClipboardText;
        micButton.onclick = () => gfnpcApp.toggleMicrophone();
    }

    if (IsiDevice(platformDetails) || IsWebOS(platformDetails) || IsXbox(platformDetails)) {
        kbElement.style.display = "block";
        compositionElement.style.display = "block";
        kbElement.onclick = toggleKeyboard;

        hiddenTextElement.addEventListener("focusin", () => {
            isHiddenTextFocused = true;
        });
        hiddenTextElement.addEventListener("focusout", () => {
            isHiddenTextFocused = false;
        });
    }

    (<any>window).visualViewport?.addEventListener("resize", _ => {
        updateStreamingPage();
        checkForVisibleKeyboard();
    });

    divs.push(document.getElementById("messageDisplay"));
    divs.push(document.getElementById("settings"));
    divs.push(document.getElementById("stream"));
    divs.push(document.getElementById("errorMessageDisplay"));
    divs.push(document.getElementById("transitionDisplay"));

    // Add listeners to IGO components
    igoNetworkCheckbox = document.getElementById("igo-network-checkbox");
    igoNetworkCheckbox.onclick = () => {
        igoNetworkDirty = true;
    };
    igoMaxbitSlider = document.getElementById("igo-maxbit-slider");
    igoMaxbitSlider.onmousedown = (event: Event) => {
        event.stopPropagation();
    };
    igoMaxbitSpan = document.getElementById("igo-maxbit-span");
    igoMaxbitSlider.oninput = (event: Event) => {
        igoMaxbitSpan.textContent = (event.target as HTMLInputElement).value;
        igoMaxBitrateDirty = true;
    };
    makeDraggable(document.getElementById("igo")); // Make IGO draggable
    makeDraggable(document.getElementById("isso")); // Make ISSO draggable

    [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange"
    ].forEach(eventType => document.addEventListener(eventType, fullscreenchangeEventHandler));

    videoElement.addEventListener("keydown", keyupdownEventHandler);
    videoElement.addEventListener("keydown", keydownEventHandler);
    videoElement.addEventListener("keyup", keyupdownEventHandler);

    if (typeof Storage !== "undefined") {
        const sanitiseOption = (elem: HTMLSelectElement) => {
            if (elem.selectedIndex == -1) {
                for (let option of elem.options) {
                    if (option.defaultSelected) {
                        elem.selectedIndex = option.index;
                    }
                }
            }
        };

        let fps = localStorage.getItem("fps");
        if (fps) {
            fpsElement.value = fps;
            sanitiseOption(fpsElement);
        }

        let bitrate = localStorage.getItem("bitrate");
        if (bitrate) {
            bitrateElement.value = bitrate;
            sanitiseOption(bitrateElement);
        }

        let kbLayout = localStorage.getItem("kb-layout");
        if (kbLayout) {
            kbLayoutElement.value = kbLayout;
        }

        let fs = localStorage.getItem("fullscreen");
        if (fs !== undefined) {
            fsSettingElement.checked = fs == "true" ? true : false;
        }

        const persistClientConfig = localStorage.getItem("persist-client-config") === "true";
        const oldClientConfigOverride = persistClientConfig
            ? localStorage.getItem("client-config")
            : null;

        if (persistClientConfig) {
            persistClientConfigElement.checked = true;
            if (oldClientConfigOverride?.length > 0) {
                clientConfigElement.value = oldClientConfigOverride;
            }
        }
    }

    //Use gxtConfig to disable CPM
    let gxtOverrideData: string = JSON.stringify({
        params: [
            {
                name: "ragnarok",
                value: {
                    enableCpm: false
                }
            }
        ]
    });

    let configData: RagnarokConfigData = {
        remoteConfigData: {
            ragnarok: '{"connectivityCheckTimeout": 2000, "sleepErrorsStreaming": ["0xC0F2230F"]}'
        },
        overrideData: overrideData.toString(),
        gxtOverrideData: gxtOverrideData.toString()
    };

    ConfigureRagnarokSettings(configData);
    Log.i(LOGTAG, "configured ragnarok with settings: " + configData.toString());

    gfnpcApp = new GFNPCApp(isStreamTest, MAX_SESSION_CONNECTION_RETRY);
    let gamepadTesterQPEnabled = toBool(GetQueryParam("gamepadtest")) ?? false;
    if (gamepadTesterQPEnabled) {
        // Only the Gamepad Tester will be displayed
        return;
    }
    let platformApiQPEnabled = toBool(GetQueryParam("platform")) ?? false;
    if (platformApiQPEnabled) {
        AddPlatformTelemetry({
            emitDebugEvent: (key1?, key2?, key3?, key4?, key5?, sessionId?, subSessionId?) => {
                Log.i(
                    LOGTAG,
                    `Debug Telemetry: ${key1}, ${key2}, ${key3}, ${key4}, ${key5}, ${sessionId}, ${subSessionId}`
                );
            }
        });
        // Only the Platform names will be displayed
        document.getElementById("heading").innerHTML = "<h1>Details</h1>";
        document.getElementById("osname").innerHTML = "OS : " + platformDetails.os;
        document.getElementById("browsername").innerHTML = "Browser : " + platformDetails.browser;
        document.getElementById("forged").innerHTML =
            "Forged : " + platformDetails.forging.toString();
        document.getElementById("spoofed").innerHTML =
            "UA Data spoofed : " + platformDetails.spoofing.toString();
        document.getElementById("confidence").innerHTML =
            "Confidence : " + platformDetails.confidence.toString();
        document.getElementById("osverraw").innerHTML =
            "OS raw version : " + platformDetails.osRawVer.toString();
        document.getElementById("osver").innerHTML =
            "OS version : " + platformDetails.osVer.toString();
        document.getElementById("uaver").innerHTML =
            "Browser version : " + platformDetails.browserVer.toString();
        document.getElementById("uafullver").innerHTML =
            "Full version : " + platformDetails.browserFullVer.toString();
        document.getElementById("vendor").innerHTML = "Vendor : " + platformDetails.vendor;
        document.getElementById("deviceOS").innerHTML =
            "Device OS : " + (platformDetails.deviceOS ?? "N/A");
        document.getElementById("deviceType").innerHTML =
            "Device Type : " + (platformDetails.deviceType ?? "N/A");
        document.getElementById("deviceModel").innerHTML =
            "Device Model : " + (platformDetails.deviceModel ?? "N/A");
        document.getElementById("platform").style.display = "block";

        return;
    }
    document.getElementById("start").onclick = () => gfnpcApp.startButtonClick();
    document.getElementById("stop").onclick = () => gfnpcApp.stopButtonClick(true);
    document.getElementById("resume_from_stop").onclick = () => gfnpcApp.startButtonClick();
    document.getElementById("advanced_settings").onclick = () =>
        DisplayScreen(DisplayDivs.SETTINGS_PAGE);

    document.getElementById("terminate-1").onclick = () => terminateOmniverseStreamingSession();
    document.getElementById("terminate-2").onclick = () => terminateOmniverseStreamingSession();
    document.getElementById("terminate-3").onclick = () => terminateOmniverseStreamingSession();
    statusToggleButton = document.getElementById("statustoggle");
    statusToggleButton.onclick = () => {
        sbSettingElement.checked = !sbSettingElement.checked;
        showStatusBarIfNeeded();
    };

    document.getElementById("downloadlogs").onclick = () => gfnpcApp.downloadLogs();
    // document.getElementById("uploadlogs").onclick = () => gfnpcApp.uploadLogs();
    // document.getElementById("downloadAudioRecordings").onclick = () =>
    //     gfnpcApp.downloadAudioRecordings();
    document.getElementById("overlaycontinue").onclick = () => gfnpcApp.playMediaOnContinue();
    DisplayLoadingMessage("Initializing...");

    let maxBitrate = toNumber(GetQueryParam("bitrate")) ?? 0;
    if (maxBitrate) {
        if (maxBitrate <= 100) {
            maxBitrate *= 1000; //Convert Mbps to Kbps.
        }
        const bitrateValue = String(maxBitrate);
        const bitrateLabel = bitrateValue + " Kbps";
        bitrateElement.options[bitrateElement.options.length] = new Option(
            bitrateLabel,
            bitrateValue
        );
        bitrateElement.value = bitrateValue;
        bitrateElement.disabled = true;
    }

    const requestRes = GetQueryParam("resolution");
    if (requestRes) {
        const resInfo = requestRes.split("x");
        if (resInfo.length == 2) {
            const widthQP = parseInt(resInfo[0]);
            const heightQP = parseInt(resInfo[1]);
            if (widthQP && heightQP) {
                const resValue = String(widthQP) + ":" + String(heightQP);
                const resLabel = String(widthQP) + "x" + String(heightQP) + "p";
                resolutionElem.options[resolutionElem.options.length] = new Option(
                    resLabel,
                    resValue
                );
                resolutionElem.value = resValue;
                resolutionElem.disabled = true;
            } else {
                Log.e(
                    LOGTAG,
                    `Invalid resolution in query param:
                ${requestRes} , correct format is: widthxheight (e.g 1080x720)`
                );
            }
        } else {
            Log.e(
                LOGTAG,
                `Invalid resolution in query param:
                ${requestRes} , correct format is: widthxheight (e.g 1080x720)`
            );
        }
    }

    const requestFps = GetQueryParam("fps");
    if (requestFps) {
        const fpsQP = toNumber(requestFps);
        if (fpsQP > 0) {
            fpsElement.options[fpsElement.options.length] = new Option(
                requestFps + "fps",
                requestFps
            );
            fpsElement.value = requestFps;
            fpsElement.disabled = true;
        } else {
            Log.e(LOGTAG, `Invalid fps in query param: ${requestFps} , must be a positive integer`);
        }
    }

    let kbLayout = GetQueryParam("kbLayout");
    if (kbLayout) {
        kbLayoutElement.value = kbLayout;
        kbLayoutElement.disabled = true;
    }

    let serverFromQP = GetQueryParam("server");
    if (serverFromQP !== null) {
        if (serverFromQP.includes("mockpm://") && !IsValidIPv4(serverFromQP.substring(9))) {
            DisplayErrorMessage("Error: The mockpm address in query params is not valid IPv4.");
            document.getElementById("okbutton").style.visibility = "hidden";
            return;
        } else if (
            serverFromQP &&
            !serverFromQP.includes("mockpm://") &&
            !serverFromQP.includes("nvidiagrid") &&
            !IsValidIPv4(serverFromQP)
        ) {
            DisplayErrorMessage("Error: The passthru address in query params is not valid IPv4.");
            document.getElementById("okbutton").style.visibility = "hidden";
            return;
        } else {
            serverAddress = serverFromQP;
        }
    }

    const url = window.location.href;

    const times = [];
    let frameRate;
    const startTime = performance.now();
    let runRefreshLoop = false;

    function refreshLoop() {
        if (runRefreshLoop) {
            window.requestAnimationFrame(() => {
                const now = performance.now();
                while (times.length && times[0] <= now - 1000) {
                    times.shift();
                }
                times.push(now);
                frameRate = times.length;
                console.log("RAF callback, frameRate: " + frameRate);
                refreshLoop();
            });
        }
    }

    rrlSettingElement.addEventListener("change", () => {
        if (rrlSettingElement.checked) {
            runRefreshLoop = true;
            refreshLoop();
        } else {
            runRefreshLoop = false;
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        console.log("DOM fully loaded and parsed");
        const endTime = performance.now();
        const diff = endTime - startTime;
        console.log("WindowLoad total time: " + diff.toFixed(2) + " ms");
    });

    for (let i = 0; i < 3; i++) {
        const codec = <HTMLSelectElement>document.getElementById(`codec-${i}`);
        codecElements.push(codec);
    }
    const defaultCodecLabels = new Map([
        [0, "First Choice"],
        [1, "Second Choice"],
        [2, "Third Choice"]
    ]);

    const onCodecSelect = (index: number) => {
        const element = codecElements[index];
        const defaultLabel = defaultCodecLabels.get(index);
        if (!element.value) {
            disableCodecOption(index);
        } else {
            Log.i(LOGTAG, "Selecting " + element.value + " as " + defaultLabel + " codec");
            element.options[0].disabled = false;
            element.options[0].label = "Default";
        }
    };

    const resetCodecSelectElement = (index: number) => {
        const element = codecElements[index];
        element.disabled = true;
        element.options[0].selected = true;
        disableCodecOption(index);
    };

    const disableCodecOption = (index: number) => {
        const option = codecElements[index].options[0];
        option.disabled = true;
        const defaultLabel = defaultCodecLabels.get(index);
        option.label = "--" + defaultLabel + "--";
    };

    for (let i = 0; i < codecElements.length; i++) {
        codecElements[i].oninput = () => {
            onCodecSelect(i);
            if (i < codecElements.length - 1) {
                if (codecElements[i].value) {
                    codecElements[i + 1].disabled = false;
                } else {
                    for (let j = i + 1; j < codecElements.length; j++) {
                        resetCodecSelectElement(j);
                    }
                }
            }
        };
    }

    SetupComplete();
}

function getLogSize(): number {
    const DEFAULT_LOG_SIZE = 500 * 1024;
    const qp = GetQueryParam("logsizekb");
    if (qp) {
        return Number.parseInt(qp) * 1024;
    }
    return DEFAULT_LOG_SIZE;
}

function SetupComplete() {
    let autolaunchParam = GetQueryParam("autolaunch");
    if (autolaunchParam) {
        gfnpcApp.startButtonClick();
    } else {
        DisplayScreen(DisplayDivs.LOADING_PAGE);
    }
}

function makeDraggable(elem: HTMLElement) {
    let x0: number, y0: number;
    elem.onmousedown = (e: MouseEvent) => {
        e.preventDefault();
        x0 = e.clientX;
        y0 = e.clientY;
        const oldMouseUp = document.onmouseup;
        const oldMouseMove = document.onmousemove;
        document.onmouseup = () => {
            document.onmouseup = oldMouseUp;
            document.onmousemove = oldMouseMove;
        };
        document.onmousemove = e => {
            e.preventDefault();
            let dx = e.clientX - x0;
            let dy = e.clientY - y0;
            elem.style.left = `${elem.offsetLeft + dx}px`;
            elem.style.top = `${elem.offsetTop + dy}px`;
            x0 = e.clientX;
            y0 = e.clientY;
        };
    };
}

document.addEventListener("DOMContentLoaded", Setup);
