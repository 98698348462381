import {
    WebSocketMsg,
    WebSocketImplCallbacks,
    LogCallbackType,
    WebSocketHandler
} from "./rinterfaces";

export class WebSocketImpl {
    private ws?: WebSocket;
    private wsHadError: boolean = false;
    private serverSupportsAck = false;
    private cacheMsgsForAck: WebSocketMsg[] = [];
    private wsQueue: WebSocketMsg[] = []; // Queue for data to be sent when the WebSocket reconnects.
    private wsLogger: LogCallbackType;
    private wsException: LogCallbackType;
    private wsHandler: WebSocketHandler;
    private heartBeatTimeoutId: number = 0;
    private maxReceivedAckId: number = 0;
    private url: string = "";
    constructor(private sessionId: string, webSocketImplCallbacks: WebSocketImplCallbacks) {
        this.wsLogger = webSocketImplCallbacks.logCallback.info;
        this.wsException = webSocketImplCallbacks.logCallback.exception;
        this.wsHandler = webSocketImplCallbacks.wsHandler;
    }

    public initialize(
        url: string,
        maxReceivedAckId: number,
        serverSupportsAck: boolean,
        reconnect: boolean = false
    ) {
        this.url = url;
        this.maxReceivedAckId = maxReceivedAckId;
        this.serverSupportsAck = serverSupportsAck;
        this.createWebSocket(reconnect);
        this.wsLogger("{f4b05a4}");
    }

    public uninitialize(closeCode?: number) {
        this.maxReceivedAckId = 0;
        this.serverSupportsAck = false;
        this.cacheMsgsForAck = [];
        this.wsQueue = [];
        this.clearHeartBeatTimeout();
        this.ws?.close(closeCode);
        this.wsLogger("{986b258}"+ closeCode);
    }

    public reconnect() {
        this.createWebSocket(true);
    }

    private checkWebsocketConnectionPeriodically() {
        this.wsLogger("{6c3505a}"+ this.ws?.readyState);
        if (!this.ws) {
            this.createWebSocket(true);
        }
        this.setHeartBeatTimeout();
    }

    private clearHeartBeatTimeout() {
        if (this.heartBeatTimeoutId !== 0) {
            self.clearTimeout(this.heartBeatTimeoutId);
            this.heartBeatTimeoutId = 0;
        }
    }

    private setHeartBeatTimeout() {
        this.clearHeartBeatTimeout();
        this.heartBeatTimeoutId = self.setTimeout(
            () => this.checkWebsocketConnectionPeriodically(),
            3000
        );
    }

    private deleteFromAckCache(ack: number) {
        for (let i = this.cacheMsgsForAck.length - 1; i >= 0; i--) {
            if (this.cacheMsgsForAck[i].ackid! <= ack) {
                //this.wsLogger("{72e9db8}"+ this.cacheMsgsForAck[i].ackid!);
                this.cacheMsgsForAck.splice(i, 1);
            }
        }
    }

    private addInAckCache(data: WebSocketMsg) {
        if (data.ackid !== undefined) {
            //this.wsLogger("{8fae9a4}"+ data.ackid);
            this.cacheMsgsForAck.push(data);
        }
    }

    private sendOnWsReliablyIfNeeded(data: WebSocketMsg) {
        if (data.ackid !== undefined && this.serverSupportsAck) {
            this.addInAckCache(data);
        }
        if (this.ws) {
            if (data.stats) {
                this.ws.send(data.stats);
            } else {
                this.ws.send(JSON.stringify(data));
            }
        }
    }

    public send(data: WebSocketMsg) {
        if (!this.serverSupportsAck && !this.ws) {
            this.createWebSocket(true);
        }

        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.sendOnWsReliablyIfNeeded(data);
        } else {
            this.wsQueue.push(data);
        }
    }

    private sendAckToServer() {
        if (this.ws) {
            let ackmsg: WebSocketMsg = {
                ack: this.maxReceivedAckId
            };
            let ack = JSON.stringify(ackmsg);
            this.wsLogger("{903be10}"+ ack);
            this.ws.send(ack);
        }
    }

    public createWebSocket(reconnect: boolean = false) {
        try {
            let header;
            if (this.url.includes("wss")) {
                header = "x-nv-sessionid." + this.sessionId;
            }
            let websocketUrl = this.url;
            if (reconnect) {
                websocketUrl += "&reconnect=1";
            }
            let localWs = new WebSocket(websocketUrl, header);
            localWs.onopen = event => {
                this.wsLogger("{aaf44dc}");
                this.wsHandler.openHandler();
                if (localWs === this.ws) {
                    for (const pendingMessage of this.cacheMsgsForAck) {
                        if (pendingMessage.stats) {
                            localWs.send(pendingMessage.stats);
                        } else {
                            localWs.send(JSON.stringify(pendingMessage));
                        }
                    }
                    for (const pendingMessage of this.wsQueue) {
                        this.sendOnWsReliablyIfNeeded(pendingMessage);
                    }
                    this.wsQueue = [];
                }
            };
            localWs.onclose = event => {
                this.wsLogger("{7a09ad8}");
                this.wsHandler.closeHandler({
                    error: this.wsHadError,
                    code: event.code,
                    reason: event.reason,
                    wasClean: event.wasClean
                });
                if (localWs === this.ws) {
                    this.ws = undefined;
                }
                this.wsHadError = false;
            };
            localWs.onerror = event => {
                this.wsLogger("{418a180}");
                this.wsHadError = true;
            };
            localWs.onmessage = event => {
                let wsMsg = <WebSocketMsg>JSON.parse(event.data); //@todo do we need try-catch?
                if (this.serverSupportsAck) {
                    this.setHeartBeatTimeout();
                }
                if (wsMsg.ackid !== undefined && !this.serverSupportsAck) {
                    this.serverSupportsAck = true;
                    this.wsLogger("{523f5b9}");
                    this.setHeartBeatTimeout();
                }
                if (wsMsg.hb) {
                    // don't pass heartbeat to main thread
                    return;
                }

                if (this.serverSupportsAck) {
                    if (wsMsg.ack !== undefined) {
                        this.deleteFromAckCache(wsMsg.ack);
                    }
                    // check if this msg need ack
                    if (wsMsg.ackid !== undefined) {
                        //check for a reapeated msg and ignore if true
                        if (this.maxReceivedAckId < wsMsg.ackid) {
                            this.wsHandler.messageHandler(wsMsg);
                            this.maxReceivedAckId = wsMsg.ackid;
                        }
                        this.sendAckToServer();
                    } else if (wsMsg.ack === undefined) {
                        //this msg don;t need ack
                        // only pass non-ack msgs to main thread
                        this.wsHandler.messageHandler(wsMsg);
                    }
                } else {
                    this.wsHandler.messageHandler(wsMsg);
                }
            };
            this.ws = localWs;
        } catch (exp) {
            this.ws = undefined;
            this.wsException("WebSocket creation exception: " + exp);
        }
    }
}
