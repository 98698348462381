(window as any)["RtcUtils"] = (window as any)["RtcUtils"] || {};

/* Typings to be exposed to library user */
export * from "./output/interfaces";
export * from "./output/version";
export { version as PackageVersion } from "./output/genversion";
export * from "./output/utils";
export * from "./output/settings";
export * from "./output/eventemitter";
export * from "./output/logger";
export * from "./output/platform";
export * from "./output/enumnames";
export * from "./output/logqueueingeventemitter";
export * from "./output/workerutils";
export {
    FORMAT_TEXT_MAP,
    TracingCarrier,
    Span,
    SpanContext,
    Tracer,
    createTracerCallback,
    TracingInfo
} from "./output/tracing";
export * from "./output/extensions";
