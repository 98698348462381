import {
    AppLaunchMode,
    ActiveSessionInfo,
    ErrorDetails,
    authTokenCallbackType,
    createTracerCallback,
    TelemetryEventPayload,
    ClientType,
    StreamInfo
} from "./dependencies";

export const enum EVENTS {
    /** Indicates session has started, failed if error field is there
     * Format is {sessionId?: string, subSessionId?: string, error?: { code: RErrorCode} }
     * Note: in all of these events sessionId may only be there if possible.
     */
    SESSION_START_RESULT = "SessionStartResult",

    /** Indicates session has stopped with succesfull delete request to PM,
     * error field indicates delete request was not sent
     * Format is {sessionId?: string, subSessionId?: string, error?: { code: RErrorCode} }
     */
    SESSION_STOP_RESULT = "SessionStopResult",

    /** Indicates session has started, failed if error field is there,
     * In success case result will contain list of sessions with sessionId.
     * Format is { error?: { code: RErrorCode}, sessionList: [ { sessionId: X, appId: X, state: X}] }
     */
    ACTIVE_SESSIONS_RESULT = "ActiveSessionsResult",

    /** This event is used to give progress updates like eta, queue size,
     * these updates will only come before SESSION_START_RESULT event.
     * Format is {
        sessionId: string,
        subSessionId: string,
        queuePosition: number,
        eta: number (milliseconds),
        state: SessionProgressState
        };
    */
    PROGRESS_UPDATE = "ProgressUpdate",

    /** Indicates streaming termination, can only come after SESSION_START_RESULT, error field indicates
     * abnormal termination else intentional disconnect.
     * Format is {sessionId?: string, subSessionId?: string, error?: { code: RErrorCode} }
     */
    STREAM_STOPPED = "StreamStopped",

    /**
     * Deprecated: Clients should register for TELEMETRY_EVENT for receiving telemetry events
     *
     *Indicates analytics Event sent by library, clients should expect TelemetryEvent derived objects.
     *Check analytics.ts for all derived objects which library can send.
     *Append all parameters which are common to all events and send to telemetry server.
     */
    ANALYTICS_EVENT = "AnalyticsEvent",

    /**
     *Indicates an Event sent by library during streaming like temporary disconnect or warnings.
     */
    STREAMING_EVENT = "StreamingEvent",

    /**
     *Deprecated
     */
    GETSESSIONRESULT = "GetSessionResult",

    /**
     *Indicates Event sent by library when getSession() request completes. Only used in resume scenarios.
     */
    GET_SESSION_RESULT = "GetSessionResult",

    /**
     *Indicates a log sent by the library
     */
    LOG_EVENT = "Log",

    /*
     * Sends streamingQuality struct per 100ms.used to draw network bar in UI
     */
    STREAMING_QUALITY = "StreamingQuality",

    /** This event is used for updates in mic recorder state
     * Format is {
             state: MicState
       };
    */
    MIC_CAPTURE = "MicCapture",

    /**
     * This event is used for fowarding Extended Commands
     */
    CUSTOM_MESSAGE = "CustomMessage",

    /**
     * Indicates that text composition is in progress
     * An event where compositionText is empty will be sent when composition has ended
     */
    TEXT_COMPOSITION = "TextComposition",

    /**
     * Indicates streaming stats are updated
     */
    STREAM_STATS_UPDATE = "StreamStatsUpdate",

    /**
     * This event is used for forwarding the Analytics Events generated by library. Clients should expect TelemetryEventPayload object.
     *
     * Clients should dispatch the received events using the ClientTelemetryConfig in the TelemetryEventPayload object.
     * Events can have different telemetry configurations based on how the library is configured.
     * When
     *      RagnarokConfig.useSplitSchema is true:
     *              Events from GFN_GridServer schema (93289439828086895) will be received
     *              Events from Ragnarok schema (62677554106929725) will be received
     *
     *      RagnarokConfig.useUITelemetry is true:
     *              Events from GFN_Client schema (78589530426925203) will be received
     *
     * If clients do not register for this, Ragnarok library will dispatch the telemetry events from within.
     *
     * Note: For handling Stream Exit events, client should pass a clientShutDownCallback in the initParams.
     * Care should be taken to dispactch the exit events reliably as streaming yield metrics are based on these telemetry events.
     */
    TELEMETRY_EVENT = "TelemetryEvent"
}

/**
 * Type of cursor to display
 */
export const enum CursorType {
    /** Render the cursor in software, matching cursor with the server */
    SOFTWARE,
    /** Render the cursor in hardware, matching cursor with the server */
    HARDWARE,
    /** Allow the browser to handle cursor on its own, without matching the server cursor */
    FREE
}

/**
 * Input configuration flags.
 * Intended for debugging purposes; leave at defaults.
 */
export declare interface InputConfigFlags {
    cursorType?: CursorType;
    allowUnconfined: boolean;
    preventNavigation?: boolean;
}

export const defaultInputConfigFlags: InputConfigFlags = {
    allowUnconfined: false,
    preventNavigation: false
};

export declare interface clientShutDownCallbackType {
    (exitEventPayload: TelemetryEventPayload): boolean;
}

export declare interface Resolution {
    width: number;
    height: number;
}

export declare interface StreamingResolution extends Resolution {}

/**
 * Interface representing video streaming settings
 */
export declare interface StreamingSettings {
    resolution: StreamingResolution;
    frameRate: number;
}

export const enum StreamingProfilePreset {
    BALANCED,
    DATASAVER,
    COMPETITIVE
}

export const enum TrackType {
    VIDEO = "video",
    AUDIO = "audio"
}

export declare interface Track {
    kind: TrackType;
    trackId?: string; // only being used internally as of now
}

/** This is passed in StartSessionResult for client to call play on appropriate media element.
 * Client may need to show a bridge state to get user gesture for successful play call and hence get
 * around browser play policies. Client should also handle all success/fail cases with correct screens/UX and telemetry.
 */
export declare interface Stream {
    streamId: string; // eg. [single stream -> "app_media_stream"], [seperate stream -> "app_video_stream", "app_audio_stream"] as of now
    tracks: Track[];
}

/** Event generated for EVENTS.SESSION_START_RESULT*/
export declare interface StartSessionResultEvent {
    sessionId: string;
    subSessionId: string;
    error?: ErrorDetails;
    sessionList?: ActiveSessionInfo[];
    streams?: Stream[];
    streamInfo?: StreamInfo[];
    zoneName: string;
    zoneAddress: string;
    gpuType: string;
    isResume: boolean;
}

/** Event generated for EVENTS.SESSION_STOP_RESULT*/
export declare interface StopSessionResultEvent {
    sessionId: string;
    subSessionId: string;
    error?: ErrorDetails;
    framesDecoded?: number;
}

/** Event generated for EVENTS.GET_SESSION_RESULT*/
export declare interface GetSessionResult {
    sessionId: string;
    subSessionId: string;
    appId?: number;
    status?: string; // deprecated
    state?: string;
    error?: ErrorDetails;
    appLaunchMode?: AppLaunchMode;
}

/** Event generated for EVENTS.STREAM_STOPPED*/
export declare interface StreamingTerminatedEvent {
    sessionId: string;
    subSessionId: string;
    exitcode?: number; //deprecated
    error: ErrorDetails;
    isResumable?: boolean; // client should resume session if this flag is true
    zoneName: string;
    zoneAddress: string;
}

/** Interface for handling the Gamepad packet updates */
export interface GamepadDataHandler {
    // Handles the resulting update of the gamepad bitmap
    gamepadBitmapUpdateHandler(gamepadBitmap: number): void;
    // Handles the resulting update of the gamepad state
    gamepadStateUpdateHandler(
        count: number,
        index: number,
        buttons: number,
        trigger: number,
        axes: readonly number[],
        ts: number,
        gamepadBitmap: number,
        name: string
    ): void;
    // Sends gamepad packets to the packet handler
    finalizeGamepadData(count: number): void;
    // Creates and sends the virtual gamepad packets to the packet handler
    virtualGamepadUpdateHandler(
        buttons: number,
        trigger: number,
        index: number,
        axes: readonly number[],
        gamepadBitmap: number
    ): void;
    // Handles unsupported gamepad connected events
    connectUnsupportedGamepad(gamepad: Gamepad): void;
    // Handles unsupported gamepad disconnected events
    disconnectUnsupportedGamepad(index: number): void;
    // Control haptics support
    sendGamepadHapticsControl?(enable: boolean): void;
}

export interface VibrationHandler {
    handleSimpleVibration(index: number, leftMotorSpeed: number, rightMotorSpeed: number): void;
}

export declare interface Zoneless {
    windowAddEventListener?: Function;
    windowRemoveEventListener?: Function;
    documentAddEventListener?: Function;
    documentRemoveEventListener?: Function;
    videoAddEventListener?: Function;
    videoRemoveEventListener?: Function;
}

export const enum STREAMING_STATE {
    RECONNECTING = "reconnecting",
    RECONNECTED = "reconnected"
}

export const enum RNotificationCode {
    Unknown = 0,
    ApproachingEntitlementTimeout = 1,
    ApproachingIdleTimeout = 2,
    ApproachingSessionMaxTimeLimit = 3,
    ClearUserIdleTimeOut = 4
}

export declare interface StreamingState {
    state: string;
}

export declare interface StreamingWarnings {
    code: number; //RNotificationCode
    secondsLeft?: number;
}

/** Event generated for EVENTS.STREAMING_EVENT*/
export declare interface StreamingEvent {
    streamingState?: StreamingState;
    streamingWarnings?: StreamingWarnings;
}

/**
 * In-stream stat overlay event
 */
export declare interface StreamUpdateEvent {
    /** Average game FPS */
    avgGameFps: number;
    /** Streaming FPS */
    fps: number;
    /** Round trip delay (ms) */
    rtd: number;
    /** Average decode cost (ms) */
    avgDecodeTime: number;
    /** Total frame loss */
    frameLoss: number;
    /** Total packet loss */
    packetLoss: number;
    /** Available bandwidth (Mbps) */
    totalBandwidth: number;
    /** Utilized bandwidth (%) */
    utilizedBandwidth: number;
    /** Streaming resolution */
    streamingResolution: StreamingResolution;
}

/** InitParams object structure used to initialize GridApp class object for streaming purpose
 * Consider all fields mandatory for production.
 */
export declare interface InitParams {
    /** clientIdentification like GFN-PC will probably change in future*/
    clientIdentification?: string;
    /**Version of this client */
    clientVersion?: string;
    /**Version of the client application */
    clientAppVersion?: string;
    /**indicates the deviceHashId */
    deviceHashId?: string;
    /**indicates the zone to connect to */
    serverAddress: string;
    /**  This function should return a promise and should be resolved when the client gets the auth/delegate token.
         resolve(authtoken) else reject().*/
    authTokenCallback?: authTokenCallbackType;
    /** Used to give callback to client in case tab is closed during streaming */
    clientShutDownCallback?: clientShutDownCallbackType;
    /** Tag of an input field that should be listened to for input, main use case is for TVs' software keyboards */
    textInputElement?: HTMLInputElement;
    /** This function should return a tracer that can be used to enable distributed tracing */
    createTracer?: createTracerCallback;
    /** Map representing application provided client headers.  If key is one of the default headers, value of header is overriden.
     * New headers are added as new headers. */
    clientHeaders?: Map<string, string>;
    /** Indicates client type.
     * See gridserver-core/src/interfaces.ts for definition.
     * If none is provided, ClientType.BROWSER will be used */
    clientType?: ClientType;
    /** Registered client ID for this client */
    clientId?: string;
}

export declare interface StreamingQuality {
    qualityScore: number;
    bandwidthScore: number;
    networkLossScore: number;
    latencyScore: number;
}

/**
 * State of mic
 */
export const enum MicState {
    /** Not initialized yet, call startMicCaptureOnDefaultDevice() */
    UNINITIALIZED,
    /** Permission is not granted yet by user (may be in progress) */
    PERMISSION_PENDING,
    /** User denied permission from popup (requires page refresh, browser side limitation) */
    PERMISSION_DENIED,
    /** Mic capture has started */
    STARTED,
    /** No suitable devices present (eg, all mics are unplugged).
        Note: This currently adds an handler which will start mic if a new
        device is seen, hence start should not be called */
    NO_SUITABLE_DEVICE,
    /** Permission has been granted by user, but mic capture is not active (not started/stopped) */
    STOPPED,
    /** Not supported (eg, on http) */
    UNSUPPORTED,
    /** Generic error condition */
    ERROR
}

/**
 * Event data returned by mic
 */
export declare interface MicStateEvent {
    state: MicState;
}

/**
 *This is the base interface for any custom message sent.
 *Based on messageType, processor will know what to do with the data payload
 */
export declare interface CustomMessage {
    messageType: string; // Type of this message
    messageRecipient?: string; // Recipient module
    data?: string; // Optional data associated with this message
}

/** Event generated for EVENTS.TEXT_COMPOSITION */
export declare interface TextCompositionEvent {
    compositionText: string; // The updated text while the compisition is underway
    imeRecommendation?: boolean; // Set to true when non-ascii input is detected. Clients should bring up an IME editior and handle the inputs
}

/**
 * Types of user inputs
 */
export declare const enum InputType {
    None = 0,
    Mouse = 1,
    Keyboard = 2,
    Gamepad = 4,
    Touch = 8,
    All = Mouse | Keyboard | Gamepad | Touch
}

/**
 * Browser features used during gamestreaming that may not be supported on all devices
 */
export declare const enum BrowserFeature {
    /** minimal feature set for gamestreaming */
    Streaming
}
