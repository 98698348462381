import {
    StartSessionResultEvent,
    StreamingTerminatedEvent,
    STREAMING_STATE,
    StreamingEvent,
    RNotificationCode,
    TrackType,
    Stream,
    CustomMessage,
    MicState,
    EVENTS,
    InputConfigFlags,
    InputType
} from "./interfaces";
import {
    IEventEmitter,
    ErrorDetails,
    GetRandNumericString,
    GetHexString,
    HttpRequestHeaders,
    GridSession,
    Usage,
    IsChromium,
    PlatformDetails,
    SDPUtils,
    ISDPIceParameters,
    ISDPDtlsParameters,
    IsiDevice,
    IsiOSVersion,
    IsWebOS,
    AppLaunchMode,
    Log
} from "./dependencies";
import {
    WebSocketMsg,
    ExtendedDebugStats,
    AckIdGenerator,
    StatsHeader,
    IStreamCallbacks,
    WebSocketClose,
    WebSocketHandler,
    VideoStreamProgress,
    StaticStreamStats,
    DataChannelParams
} from "./rinterfaces";
import {
    getClientTerminationReason,
    getRNotificationCode,
    getRErrorCodeForExitMessage,
    ExitMessage
} from "./util/serverclienterrormap";
import { TelemetryHandler } from "./telemetry/telemetryhandler";
import { InputHandler } from "./input/inputhandler";
import { MicCapturer } from "./miccapturer";
import { RErrorCode } from "./rerrorcode";
import { ClientStatsService, IceStats } from "./stats/clientstatsservice";
import { RagnarokProfiler } from "./ragnarokprofiler";
import { RagnarokSettings } from "./util/settings";
import { VirtualGamepadHandler } from "./input/virtualgamepad";
import { GamepadTester } from "./debug/gamepadtester";
import { GamepadHandler } from "./input/gamepadhandler";
import { AudioRecorder } from "./debug/audiorecorder";
import { NvstConfig, getDefaultNvstVideoConfig, handleNvstOffer } from "./nvstconfig";
import { IsStreamingErrorCategory, CalculateMaxBitrateKbps } from "./util/utils";
import { WebSocketImpl } from "./websocketimpl";
import { GetCodecList, SdpCodecType } from "./util/devicecapabilities";
import { BitstreamDump } from "./debug/bitstreamdump";
import { ILDATHandler } from "./debug/ldatoverlay";
import { SafeZoneHandler } from "./safezonehandler";

const LOGTAG = "streamclient";
const WSLOGTAG = "websocketimpl";

export declare interface ControlChannelMsg {
    version?: { major: number; minor: number } /* c->s and s->c sent at the starting */;
    exitMessage?: ExitMessage /* c->s and s->c, terminates the session */;
    perfIndicator?: { on: boolean } /* c->s, toggles perf indiciator */;
    stutterIndicator?: { on: boolean } /* c->s, toggles stutter indiciator */;
    latencyTrigger?: boolean /* sends latency trigger to server */;
    pcmDumpTrigger?: boolean /* sends pcmdump trigger to server */;
    timerNotification?: { code: number; secondsLeft: number } /* s->c, timer warnings */;
    heartbeat?: {} /* c->s, tells server we're alive */;
    debugMessage?: { message: string } /* s->c, request to display message on client */;
    customMessage?: string /* s->c & c->s stringified CustomMessage JSON*/;
    videoStreamProgressEvent?: {
        streamIndex: number;
        videoStreamProgress: number;
        timestampUs: number;
    } /* s->c, video stream progress  */;
    etwPrint?: string /* c->s remote trace print*/;
    setMaxBitrate?: {
        streamIndex: number;
        maxBitrate: number;
    } /* c->s, max bitrate in kbps */;
    setDrcState?: { streamIndex: number; state: boolean } /* c->s, drc state */;
    setDfcState?: { streamIndex: number; state: boolean } /* c->s, dfc state */;
}

declare interface SignalingMessage {
    type?: string;
    sdp?: string;
    candidate?: string;
    sdpMLineIndex?: number | null;
    sdpMid?: string | null;
    nvstSdp?: string;
    nvstServerOverrides?: string;
}

interface MultiopusInfo {
    fmt: string;
    specification: string;
}

const IceConnectionFlags = {
    NONE: 0x0000,
    NEW: 0x0001,
    CHECKING: 0x0002,
    CONNECTED: 0x0004,
    COMPLETED: 0x0008,
    FAILED: 0x0010,
    DISCONNECTED: 0x0020,
    CLOSED: 0x0040
};

const enum ChannelState {
    CONNECTING = "connecting",
    OPEN = "open",
    CLOSING = "closing",
    CLOSED = "closed"
}

const VIDEO_STREAM_NAME = "app_video_stream";
const AUDIO_STREAM_NAME = "app_audio_stream";
const MEDIA_STREAM_NAME = "app_media_stream";

const LEGACY_STREAM_NAME = "stream_id";
const LEGACY_SECOND_STREAM_NAME = "second_stream_id";
const MIC_MID = "3";
const DIR_SEND_TRACK = "sendrecv";

export class StreamClient implements WebSocketHandler {
    eventEmitter: IEventEmitter;
    name: string = "";
    id: number;
    private nvstConfig: NvstConfig = { video: [], clientCapture: 0 };
    session: GridSession;
    videoElement: HTMLVideoElement | null;
    audioElement: HTMLAudioElement | null;
    remotePeerId: number = -1;
    connectionUrl: string;
    pc?: RTCPeerConnection;
    inputChannel?: RTCDataChannel;
    cursorChannel?: RTCDataChannel;
    statsChannel?: RTCDataChannel;
    controlChannel?: RTCDataChannel;
    inputHandler: InputHandler | null;
    stopNotified: boolean;
    startNotified: boolean;
    iceCandidateFlag: number;
    httpHeaders: HttpRequestHeaders;
    telemetry: TelemetryHandler;
    configFlags: InputConfigFlags;
    micCapturer: MicCapturer | null;
    debugMessageElement: HTMLDivElement | null;
    debugMessageTimeoutId: number;
    streamBeginTimeoutId: number;
    heartbeatIntervalId: number;
    statsService?: ClientStatsService;
    profilerRunning: boolean = false;
    webSocketDirtyCloseCount: number = 0;
    signInTimeoutId: number = 0;
    gotOffer: boolean = false;
    gotLocalCandidate: boolean = false;
    gotRemoteCandidate: boolean = false;
    gotAudioTrack: boolean = false;
    gotVideoTrack: boolean = false;
    callbacks: IStreamCallbacks;
    audioTrackMuted: boolean = true;
    //Feature toggle state
    // can be moved to new class, aggregating all features that can be toggled
    private perfIndicator: boolean;
    private stutterIndicator: boolean;
    private trackIdsExpected: string[] = [];
    private streamsAttached: Stream[] = [];
    private signInUrl: string = "";
    private signInRetries = 0;
    private gamepadTester: GamepadTester;
    private gamepadHandler: GamepadHandler;
    private isAckSupportedOnWs: boolean = false;
    private ackIdGenerator: AckIdGenerator = new AckIdGenerator();
    private timeTakenBySetRemoteDescriptionCall: number = 0;
    private timeTakenBySetLocalDescriptionCall: number = 0;
    private timeTakenByCreateAnswerCall: number = 0;
    private audioRecorder?: AudioRecorder;
    private textInputElement?: HTMLInputElement;
    private signInTimerStart: number = 0;
    private streamBeginTimerStart: number = Date.now();
    private signInDuration: number = 0;
    private streamBeginDuration: number = 0;
    private pcReconnects: number = 0;
    private platformDetails: PlatformDetails;
    private hasPendingKeyboardLayout: boolean = false;
    private keyboardLayout: string = "";
    private appLaunchMode: AppLaunchMode;
    private webSocket?: WebSocketImpl;
    private maxReceivedAckId: number = 0;
    private videoStreamProgress: VideoStreamProgress = VideoStreamProgress.NO_INFO;
    private clientAppVersion?: string;
    /// ICE stats taken when the peer connection becomes "disconnected"
    private disconnectedIceStats?: IceStats;
    private isResume?: boolean;
    private codecsPromise: Promise<SdpCodecType[]>;
    private requestedRegion?: string;
    private bitstreamDump?: BitstreamDump;
    private queuedControlMessages: ControlChannelMsg[] = [];
    private safeZoneHandler?: SafeZoneHandler;
    private sendVideoTrack?: MediaStreamTrack;
    constructor(
        parent: IStreamCallbacks,
        configFlags: InputConfigFlags,
        appLaunchMode: AppLaunchMode,
        gamepadTester: GamepadTester,
        gamepadHandler: GamepadHandler,
        telemetry: TelemetryHandler,
        platformDetails: PlatformDetails,
        session: GridSession,
        audioRecorder?: AudioRecorder,
        textInputElement?: HTMLInputElement,
        requestedMaxBitrate?: number,
        drcDfcEnabled?: boolean,
        clientAppVersion?: string,
        isResume?: boolean,
        requestedRegion?: string,
        sendVideoTrack?: MediaStreamTrack
    ) {
        this.platformDetails = platformDetails;
        this.eventEmitter = parent;
        this.id = 0;
        Log.d("{93c7910}", "{e1ca54d}", JSON.stringify(session));
        this.session = session;

        let providedBitrate: number | undefined;
        if (RagnarokSettings.maxBitrate) {
            providedBitrate = RagnarokSettings.maxBitrate;
            Log.d("{93c7910}", "{55e5b30}", providedBitrate);
        } else if (requestedMaxBitrate) {
            providedBitrate = requestedMaxBitrate;
            Log.d("{93c7910}", "{965049f}", providedBitrate);
        }
        for (const stream of session.streamInfo) {
            // Use a function for this so we can log the calculated max bitrate
            const calculate = () => {
                const calculated = CalculateMaxBitrateKbps(stream.width, stream.height, stream.fps);
                Log.d("{93c7910}", "{aff4184}", calculated);
                return calculated;
            };
            const maxBitrate = providedBitrate ?? calculate();
            // TODO: This can be determined by checking if the video element supports requestVideoFrameCallback, but
            // we don't have access to the video element in the constructor. Once we cleanup GridApp/StreamClient, we
            // can set this properly
            const mapRtpTimestampsToFrames = true;
            this.nvstConfig.video.push(
                getDefaultNvstVideoConfig(
                    stream,
                    maxBitrate,
                    mapRtpTimestampsToFrames,
                    drcDfcEnabled
                )
            );
        }
        // TODO: Remove from constructor once early initialization added for device capabilities
        this.codecsPromise = GetCodecList(
            this.session.streamInfo[0] ?? { width: 0, height: 0, fps: 0 },
            this.platformDetails
        );

        this.videoElement = null;
        this.audioElement = null;
        this.inputHandler = null;
        this.stopNotified = false;
        this.startNotified = false;
        this.iceCandidateFlag = 0;
        this.httpHeaders = {};
        this.telemetry = telemetry;
        this.configFlags = configFlags;
        this.micCapturer = null;
        this.debugMessageElement = null;
        this.debugMessageTimeoutId = 0;
        this.streamBeginTimeoutId = 0;
        this.heartbeatIntervalId = 0;
        this.callbacks = parent;
        // aggregate them later.
        this.perfIndicator = false;
        this.stutterIndicator = false;
        this.gamepadTester = gamepadTester;
        this.gamepadHandler = gamepadHandler;
        this.audioRecorder = audioRecorder;
        this.textInputElement = textInputElement;
        this.appLaunchMode = appLaunchMode;
        this.clientAppVersion = clientAppVersion;
        this.isResume = isResume;
        this.requestedRegion = requestedRegion;
        this.sendVideoTrack = sendVideoTrack;

        // TODO: signalConnectionInfo members shouldn't be optional
        let protocol = this.session.signalConnectionInfo.protocol!;
        protocol = protocol.replace("http", "ws");

        this.httpHeaders["x-nv-sessionid"] = this.session.sessionId;

        this.connectionUrl =
            protocol +
            "://" +
            this.session.signalConnectionInfo.ip +
            ":" +
            this.session.signalConnectionInfo.port;
    }

    private setSignInfo() {
        this.name = "peer-" + GetRandNumericString(10);
        Log.d("{93c7910}", "{f2b789d}", this.name);
        this.signInUrl = this.connectionUrl + "/sign_in?peer_id=" + this.name + "&version=2";

        Log.d("{93c7910}", "{05b8afd}", this.signInUrl);
    }

    start(
        videoElement: HTMLVideoElement,
        audioElement: HTMLAudioElement,
        micCapturer: MicCapturer
    ) {
        this.videoElement = videoElement;
        this.audioElement = audioElement;
        this.micCapturer = micCapturer;

        // Workaround for bug where video freezes on iOS 15
        // We need to disable autoplay when initially launching a session to prevent the bug
        // The video is played when the user clicks continue in the UI
        // However, in the case of autoresume this dialog is not present so we need to let it autoplay
        if (
            RagnarokSettings.allowAutoplayChange &&
            IsiDevice(this.platformDetails) &&
            (IsiOSVersion(this.platformDetails, 15, 0) || IsiOSVersion(this.platformDetails, 15, 1))
        ) {
            // using data prefix for custom tag: https://www.w3schools.com/tags/att_global_data.asp
            const kShouldAutoplay = "data-shouldautoplay";
            const kAutoplay = "autoplay";
            if (this.videoElement.hasAttribute(kShouldAutoplay)) {
                this.videoElement.setAttribute(kAutoplay, "");
            } else {
                this.videoElement.setAttribute(kShouldAutoplay, "");
                this.videoElement.removeAttribute(kAutoplay);
            }
        }

        RagnarokProfiler.initialize(this.session.sessionId, this.telemetry, this.ackIdGenerator);
        this.safeZoneHandler = new SafeZoneHandler(
            this,
            videoElement,
            this.platformDetails,
            this.telemetry
        );

        //@todo here set the headers for the httprequest.
        this.signInToConnectionServer();
        RagnarokProfiler.updateStreamTime();
        this.logStreamTimestamps();

        const debugMessageElement = document.createElement("div");
        debugMessageElement.style.position = "absolute";
        debugMessageElement.style.zIndex = "300";
        debugMessageElement.style.left = "0";
        debugMessageElement.style.top = "0";
        debugMessageElement.style.width = "100%";
        debugMessageElement.style.display = "none";
        debugMessageElement.style.fontSize = "3em";
        debugMessageElement.style.color = "white";
        debugMessageElement.style.backgroundColor = "gray";
        debugMessageElement.style.textAlign = "center";
        this.videoElement.insertAdjacentElement("afterend", debugMessageElement);
        this.debugMessageElement = debugMessageElement;
    }

    stop(errorCode: number) {
        this.telemetry.emitMetricEvent(
            "StreamTimersAndReconnects",
            GetHexString(errorCode),
            0,
            this.signInDuration,
            this.streamBeginDuration,
            this.pcReconnects
        );
        const peerAPIDurationsAndMicTelemetry = (micState: MicState) => {
            Log.i("{93c7910}", "{a848987}", micState);
            this.telemetry.emitMetricEvent(
                "PeerAPIDurationsAndMic",
                GetHexString(errorCode),
                this.timeTakenByCreateAnswerCall,
                this.timeTakenBySetRemoteDescriptionCall,
                this.timeTakenBySetLocalDescriptionCall,
                micState
            );
        };
        this.micCapturer!.getCurrentMicStatus().then(state => {
            peerAPIDurationsAndMicTelemetry(state);
        });

        // This will be no-op if start has already been notified.
        this.notifyStart({
            code: errorCode,
            description: "Session stopped before stream connected"
        });

        if (this.debugMessageTimeoutId) {
            clearTimeout(this.debugMessageTimeoutId);
            this.debugMessageTimeoutId = 0;
        }
        if (this.debugMessageElement) {
            this.debugMessageElement.remove();
            this.debugMessageElement = null;
        }

        this.stopNotified = true; // no need to report errors in this case.

        //attempt to notify error to server as ragnarok is terminating the session
        try {
            this.sendControlMessage({
                exitMessage: { code: getClientTerminationReason(errorCode) }
            });
        } catch (exp) {
            const EXP_MSG = "Send termination reason to server exception";
            Log.e("{93c7910}", "{d337883}", exp);
        }
        this.stopInputHandler();

        if (this.inputChannel) {
            this.inputChannel.close();
        }

        this.stopStatsService();

        if (this.pc) {
            this.pc.close();
        }

        this.stopBitstreamDump();

        if (this.streamBeginTimeoutId !== 0) {
            window.clearTimeout(this.streamBeginTimeoutId);
            this.streamBeginTimeoutId = 0;
        }

        if (this.heartbeatIntervalId !== 0) {
            window.clearInterval(this.heartbeatIntervalId);
            this.heartbeatIntervalId = 0;
        }

        if (this.signInTimeoutId !== 0) {
            window.clearTimeout(this.signInTimeoutId);
            this.signInTimeoutId = 0;
        }

        RagnarokProfiler.stopProfiling();

        this.webSocket?.uninitialize();
        RagnarokProfiler.stopWebSocket();
        this.logStreamTimestamps();
        RagnarokProfiler.deinitialize();

        if (this.gotVideoTrack && this.audioTrackMuted) {
            Log.e("{93c7910}", "{283a180}");
            this.telemetry.emitDebugEvent("Audio track muted");
        }
        this.gotVideoTrack = false;
        this.gotAudioTrack = false;

        if (this.webSocketDirtyCloseCount > 0) {
            this.telemetry.emitMetricEvent(
                "WebSocketClose",
                "",
                0,
                this.webSocketDirtyCloseCount,
                0,
                0
            );
        }

        this.audioRecorder?.uninitialize();
        this.safeZoneHandler?.uninitialize();
    }

    messageHandler(obj: WebSocketMsg) {
        Log.i("{93c7910}", "{39024c3}", JSON.stringify(obj));
        if (obj.ackid && this.maxReceivedAckId < obj.ackid) {
            this.maxReceivedAckId = obj.ackid;
        }
        if (obj.peer_info) {
            if (!this.isAckSupportedOnWs && obj.ackid !== undefined) {
                this.isAckSupportedOnWs = true;
            }
            if (obj.peer_info.name === this.name || this.id === 0) {
                // This is the first message and this is our id.
                this.id = obj.peer_info.id;
                Log.d("{93c7910}", "{c5faf27}", this.id);
            } else {
                // This is some other peer.
            }
        } else if (obj.peer_msg) {
            let peerId = obj.peer_msg.from;
            this.handlePeerMessage(peerId, obj.peer_msg.msg);
        } else if (obj.error) {
            if (obj.error === "peerRemoved") {
                this.stopStreamWithError(RErrorCode.ServerDisconnectedPeerRemovedByServer);
            } else {
                this.stopStreamWithError(RErrorCode.ServerDisconnectedUnknownError);
            }
        }
    }

    signInTimeout() {
        this.signInTimeoutId = 0;
        Log.i("{93c7910}", "{6d6b337}");
        this.notifyStart({
            code: RErrorCode.StreamerSignInTimeout
        });
    }

    closeHandler(data: WebSocketClose) {
        if (data.error && this.signInTimeoutId !== 0) {
            // If there was an error and the timeout hasn't been cleared, sign-in failed.
            // @todo add an event for WS in schema
            this.telemetry.emitHttpEvent({
                url: this.signInUrl,
                verb: "WS",
                statusCode: String(data.code ?? ""),
                requestStatusCode: data.reason ?? "",
                sessionId: this.session.sessionId,
                subSessionId: this.session.subSessionId,
                requestId: String(data.wasClean ?? ""),
                serverId: this.session?.signalConnectionInfo.ip ?? "",
                callDuration: this.signInRetries
            });
            const MAX_SIGNIN_RETRIES = 3;
            this.signInRetries++;
            if (this.signInRetries <= MAX_SIGNIN_RETRIES) {
                this.setSignInfo();
                this.createWSAndSignIn();
            } else {
                window.clearTimeout(this.signInTimeoutId);
                this.signInTimeoutId = 0;
                Log.i("{93c7910}", "{8595cb4}");
                this.notifyStart({
                    code: RErrorCode.StreamerSignInFailure
                });
            }
        }

        // Based on telemetry data, !data.wasClean covers all error cases and more
        if (!data.wasClean) {
            this.webSocketDirtyCloseCount++;
        }
    }

    openingHandler() {
        Log.i("{93c7910}", "{6423101}");
    }

    openHandler() {
        if (this.signInTimeoutId !== 0) {
            window.clearTimeout(this.signInTimeoutId);
            this.signInTimeoutId = 0;
            this.signInDuration = Date.now() - this.signInTimerStart;
        }

        if (!this.startNotified && this.streamBeginTimeoutId === 0) {
            this.startStreamBeginTimeout();
        }
    }

    private onWsInfoLog(infoMsg: string) {
        Log.i("{8ba4138}", "{0b0c6f9}", infoMsg);
    }

    private onWsExceptionLog(expMsg: string) {
        this.telemetry.emitExceptionEvent(undefined, expMsg, "{8ba4138}.ts", 0, 0, true);
        Log.e("{8ba4138}", "{0b0c6f9}", expMsg);
    }

    private createWSAndSignIn() {
        this.webSocket = new WebSocketImpl(this.session.sessionId, {
            wsHandler: this,
            logCallback: {
                info: this.onWsInfoLog.bind(this),
                exception: this.onWsExceptionLog.bind(this)
            }
        });
        this.webSocket.initialize(this.signInUrl, this.maxReceivedAckId, this.isAckSupportedOnWs);
    }

    signInToConnectionServer() {
        this.setSignInfo();
        let signInTimeoutMs = 10000;
        if (
            RagnarokSettings.ragnarokConfig.signInTimeout &&
            RagnarokSettings.ragnarokConfig.signInTimeout !== 0
        ) {
            signInTimeoutMs = RagnarokSettings.ragnarokConfig.signInTimeout;
            Log.i("{93c7910}", "{4f2f101}", signInTimeoutMs);
        }

        this.signInTimeoutId = window.setTimeout(() => this.signInTimeout(), signInTimeoutMs);
        this.signInTimerStart = Date.now();
        this.createWSAndSignIn();
    }

    startStreamBeginTimeout() {
        const INITIAL_ICE_CONNECTION_TIMEOUT_MS = 30000;
        this.streamBeginTimeoutId = window.setTimeout(
            () => this.streamBeginTimeout(),
            INITIAL_ICE_CONNECTION_TIMEOUT_MS
        );
        this.streamBeginTimerStart = Date.now();
    }

    streamBeginTimeout() {
        this.streamBeginTimeoutId = 0;
        Log.i("{93c7910}", "{d22df91}");
        let errorCode = RErrorCode.StreamerGetRemotePeerTimedOut;
        switch (this.videoStreamProgress) {
            case VideoStreamProgress.NO_INFO:
            case VideoStreamProgress.SETUP_END:
                break;
            case VideoStreamProgress.ADAPTER_INIT_BEGIN:
                errorCode = RErrorCode.StreamerVideoAdapterInitTimeOut;
                break;
            case VideoStreamProgress.FRAMEPROVIDER_INIT_BEGIN:
                errorCode = RErrorCode.StreamerVideoFrameProviderInitTimeOut;
                break;
            case VideoStreamProgress.ENCODER_INIT_BEGIN:
                errorCode = RErrorCode.StreamerVideoEncoderInitTimeOut;
                break;
            default:
                errorCode = RErrorCode.StreamerVideoSetupTimeOut;
                break;
        }
        if (!this.id) {
            errorCode = RErrorCode.StreamerNoPeerInfo;
        } else if (!this.gotOffer) {
            errorCode = RErrorCode.StreamerNoOffer;
        } else if (!this.gotAudioTrack) {
            errorCode = RErrorCode.StreamerNoAudioTrack;
        } else if (!this.gotVideoTrack) {
            errorCode = RErrorCode.StreamerNoVideoTrack;
        } else if (!this.gotRemoteCandidate) {
            errorCode = RErrorCode.StreamerNoRemoteCandidates;
        } else if (!this.gotLocalCandidate) {
            errorCode = RErrorCode.StreamerNoLocalCandidates;
        } else if (getChannelState(this.inputChannel) === ChannelState.CONNECTING) {
            errorCode = RErrorCode.StreamerInputChannelNotOpen;
        } else if (getChannelState(this.cursorChannel) === ChannelState.CONNECTING) {
            errorCode = RErrorCode.StreamerCursorChannelNotOpen;
        } else if (getChannelState(this.controlChannel) === ChannelState.CONNECTING) {
            errorCode = RErrorCode.StreamerControlChannelNotOpen;
        }
        this.stopStreamWithError(errorCode);
    }

    private stopStreamWithErrorIfSleep(): boolean {
        let stopped = false;
        // As per bug https://bugs.chromium.org/p/chromium/issues/detail?id=1060547 relying on signalingState to detect lid close (sleep),
        // this is a temporary change until http://crbug.com/1083204 gets fixed.
        if (IsChromium() && this.pc!.signalingState === "closed") {
            stopped = true;
            this.stopStreamWithError(RErrorCode.SystemSleepDuringStreaming);
        }
        return stopped;
    }

    private stopStreamDueToChannelClosing() {
        if (!this.stopStreamWithErrorIfSleep()) {
            this.stopStreamWithError(RErrorCode.StreamerDataChannelClosing);
        }
    }

    private sendCandidatesTelemetry(code: number) {
        this.pc
            ?.getStats()
            .then(report => {
                const getCandidateStats = (statType: string): string[] => {
                    let candidateIds: string[] = [];
                    for (const stat of report.values()) {
                        if (stat.type === statType) {
                            candidateIds.push(stat.id);
                        }
                    }
                    let candidates: string[] = [];
                    for (const id of candidateIds) {
                        const stats = report.get(id);
                        candidates.push(JSON.stringify(stats));
                    }
                    return candidates;
                };
                this.telemetry.emitDebugEvent(
                    "candidates",
                    JSON.stringify(getCandidateStats("candidate-pair"), null, 1),
                    JSON.stringify(getCandidateStats("local-candidate"), null, 1),
                    JSON.stringify(getCandidateStats("remote-candidate"), null, 1),
                    GetHexString(code)
                );
            })
            .catch(exp => {});
    }

    public stopStreamWithError(error: number) {
        if (!this.stopNotified) {
            this.stopNotified = true;
            this.stopInputHandler();
            this.stopStatsService();
            if (!this.startNotified) {
                this.notifyStart({ code: error });
            } else {
                let result: StreamingTerminatedEvent = {
                    sessionId: this.session.sessionId,
                    subSessionId: this.session.subSessionId,
                    error: { code: error },
                    zoneName: this.session.zoneName,
                    zoneAddress: this.session.zoneAddress
                };
                this.telemetry.emitDebugEvent(
                    "SignalingState",
                    GetHexString(error),
                    this.pc?.signalingState
                );
                Log.i("{93c7910}", "{89d3ea6}", this.pc!.signalingState);
                this.callbacks.onStreamStop(result);
            }
            Log.e("{93c7910}", "{573fb24}", GetHexString(error));
        }
    }

    /** Replaces legacy stream names with new ones */
    private renameStreams(): void {
        for (const stream of this.streamsAttached) {
            if (stream.streamId === LEGACY_STREAM_NAME && this.streamsAttached.length === 2) {
                stream.streamId = VIDEO_STREAM_NAME;
            } else if (stream.streamId === LEGACY_STREAM_NAME) {
                stream.streamId = MEDIA_STREAM_NAME;
            } else if (stream.streamId === LEGACY_SECOND_STREAM_NAME) {
                stream.streamId = AUDIO_STREAM_NAME;
            }
        }
    }

    /**
     * Determines which streams to apply to the video/audio elements. If possible, will combine separate video and audio
     * streams into one media stream and apply it to the video element
     *
     * The server can send separate streams to avoid WebRTC A/V sync from delaying the video, but we may want to only
     * have one stream on the client. This avoids having to use the audio element, which is important for Tizen. By
     * combining them on the client, we get the best of both worlds
     */
    private setMediaElementStreams(): void {
        const mediaStream = this.streamsAttached.find(x => x.streamId === MEDIA_STREAM_NAME);
        if (mediaStream) {
            this.setStreamOnElement(mediaStream, this.videoElement!);
            Log.i("{93c7910}", "{a5e354c}");
            return;
        }

        const videoStream = this.streamsAttached.find(x => x.streamId === VIDEO_STREAM_NAME);
        const audioStream = this.streamsAttached.find(x => x.streamId === AUDIO_STREAM_NAME);
        if (videoStream && audioStream) {
            const mediaStream: Stream = {
                streamId: MEDIA_STREAM_NAME,
                tracks: videoStream.tracks.concat(audioStream.tracks)
            };
            this.streamsAttached.splice(this.streamsAttached.indexOf(videoStream), 1);
            this.streamsAttached.splice(this.streamsAttached.indexOf(audioStream), 1);
            this.streamsAttached.push(mediaStream);
            this.setStreamOnElement(mediaStream, this.videoElement!);
            Log.i("{93c7910}", "{e2dc4ec}");
        } else if (videoStream) {
            this.setStreamOnElement(videoStream, this.videoElement!);
            Log.i("{93c7910}", "{07f3411}");
        } else if (audioStream) {
            this.setStreamOnElement(audioStream, this.audioElement!);
            Log.i("{93c7910}", "{243a50b}");
        } else {
            Log.w("{93c7910}", "{c3a2679}");
        }
    }

    private addSendVideoTrack(): void {
        try {
            if (this.pc && this.sendVideoTrack) {
                const transceiver = this.pc
                    .getTransceivers()
                    .find(x => x.receiver.track.kind === "video");
                if (!transceiver) {
                    Log.w("{93c7910}", "{8272b59}");
                } else {
                    let logMsg = "";
                    let origDir = transceiver.direction;
                    if (transceiver.direction !== DIR_SEND_TRACK) {
                        transceiver.direction = DIR_SEND_TRACK;
                        logMsg =
                            " (" + origDir + " direction is overridden by " + DIR_SEND_TRACK + ")";
                    }
                    transceiver
                        .sender!.replaceTrack(this.sendVideoTrack)
                        .then(() => {
                            Log.i("{93c7910}", "{b54f651}", logMsg);
                        })
                        .catch((err: any) => {
                            if (transceiver.direction !== origDir) {
                                transceiver.direction = origDir;
                            }
                            Log.w("{93c7910}", "{d225159}", err);
                        });
                }
            }
        } catch (exp) {
            const EXP_MSG = "SendVideoTrack: exception";
            Log.w("{93c7910}", "{66082ec}", exp);
        }
    }

    private setCodecPreferences(codec: SdpCodecType): void {
        const transceiver = this.pc!.getTransceivers().find(
            x =>
                x.receiver.track.kind === "video" &&
                (x.direction === "recvonly" || x.direction === "sendrecv")
        );

        if (!transceiver) {
            return;
        }

        const codecsToKeep = [codec, "flexfec-03", "rtx"];
        const codecCapabilities = RTCRtpReceiver.getCapabilities("video")?.codecs;
        let preferences: RTCRtpCodecCapability[] = [];

        if (!codecCapabilities) {
            return;
        }

        for (const codec of codecsToKeep) {
            const capabilities = codecCapabilities.filter(x => x.mimeType === "video/" + codec);
            if (capabilities) {
                preferences.push.apply(preferences, capabilities);
            }
        }

        preferences = preferences.concat(RTCRtpSender.getCapabilities("video")?.codecs ?? []);
        transceiver.setCodecPreferences(preferences);
    }

    private moveWSToWebWorker() {
        const WS_CLOSE_KEEP_PEER_ALIVE = 4001;
        this.webSocket?.uninitialize(WS_CLOSE_KEEP_PEER_ALIVE);
        this.webSocket = undefined;
        RagnarokProfiler.startWebSocket(
            this.signInUrl,
            this.maxReceivedAckId,
            this.isAckSupportedOnWs,
            this,
            true
        );
    }

    notifyStart(error?: ErrorDetails) {
        if (!this.startNotified) {
            this.streamBeginDuration = Date.now() - this.streamBeginTimerStart;
            if (error === undefined) {
                this.moveWSToWebWorker();
                if (RagnarokSettings.statsUploadEnabled) {
                    this.startProfiler();
                }
            }

            if (error && IsStreamingErrorCategory(error!.code)) {
                this.sendCandidatesTelemetry(error!.code);
            }

            this.startNotified = true;
            if (this.streamBeginTimeoutId !== 0) {
                window.clearTimeout(this.streamBeginTimeoutId);
                this.streamBeginTimeoutId = 0;
            }

            let result: StartSessionResultEvent = {
                sessionId: this.session.sessionId,
                subSessionId: this.session.subSessionId,
                streams: this.streamsAttached,
                streamInfo: this.session.streamInfo,
                error: error,
                zoneName: this.session.zoneName,
                zoneAddress: this.session.zoneAddress,
                gpuType: this.session.gpuType,
                isResume: this.isResume ?? false
            };
            this.callbacks.onSessionStart(result);
            this.safeZoneHandler?.send();
        }
    }

    public isStartNotified(): boolean {
        return this.startNotified;
    }

    /** Sets element.srcObject to a new MediaStream with all the given stream's tracks */
    private setStreamOnElement(stream: Stream, element: HTMLMediaElement): void {
        const tracks = this.pc!.getTransceivers().map(x => x.receiver.track);
        const mediaStream = new MediaStream(
            tracks.filter(x => stream.tracks.find(y => y.trackId === x.id))
        );
        element.srcObject = mediaStream;
    }

    /**
     * Sends a control channel message, failing if the channel isn't open
     */
    private sendControlMessage(msg: ControlChannelMsg): void {
        try {
            if (this.controlChannel?.readyState === ChannelState.OPEN) {
                let t0 = performance.now();
                this.controlChannel.send(JSON.stringify(msg));
                let t1 = performance.now();
                let delta = t1 - t0;
                this.updateDcTimeDuration(delta);
            } else {
                Log.w("{93c7910}", "{593db1b}");
            }
        } catch (exp) {
            const EXP_MSG = "sendControlMessage exception";
            Log.e("{93c7910}", "{a585a6e}", exp);
            this.telemetry.emitExceptionEvent(exp, EXP_MSG, "{93c7910}.ts", 0, 0, true);
        }
    }

    /**
     * Queues a control channel message for sending if the channel isn't open yet, or sends it immediately if the
     * channel is already open
     */
    private queueControlMessage(msg: ControlChannelMsg): void {
        if (getChannelState(this.controlChannel) === ChannelState.CONNECTING) {
            this.queuedControlMessages.push(msg);
        } else {
            // sendControlMessage checks if readyState === open, so we don't need to check it here
            this.sendControlMessage(msg);
        }
    }

    public updateDcTimeDuration(duration: number) {
        RagnarokProfiler.addDataChannelSendTime(duration);
        this.statsService?.updateDcSendDuration(duration);
    }

    public updateBlockedDuration(duration: number) {
        this.statsService?.updateBlockedDuration(duration);
    }

    public notifyIdleUpdate(code: RNotificationCode, secondsLeft: number) {
        if (code === RNotificationCode.ApproachingIdleTimeout) {
            this.inputHandler?.setUserIdleTimeoutPending(true);
        }

        Log.d("{93c7910}", "{5ba6173}", code, secondsLeft);
        const timerEvent: StreamingEvent = {
            streamingWarnings: {
                code: code,
                secondsLeft: secondsLeft
            }
        };
        this.eventEmitter.emit(EVENTS.STREAMING_EVENT, timerEvent);
    }

    private sendTimerEvent(msg: ControlChannelMsg) {
        let clientTimerNotificationCode = getRNotificationCode(msg.timerNotification!.code);
        if (clientTimerNotificationCode === RNotificationCode.Unknown) {
            Log.e("{93c7910}", "{de37ac5}", msg.timerNotification!.code);
            return;
        }

        this.notifyIdleUpdate(clientTimerNotificationCode, msg.timerNotification!.secondsLeft);
    }

    private emitCustomMessageEvent(message: string) {
        this.eventEmitter.emit(EVENTS.CUSTOM_MESSAGE, JSON.parse(message));
    }

    private emitIceStatsEvent(pcState: string, stats: IceStats) {
        this.telemetry.emitMetricEvent(
            "IceStats",
            pcState,
            0,
            stats.recentRequestsSent,
            stats.recentResponsesReceived,
            stats.recentPacketsReceived
        );
    }

    private startBitstreamDump(): void {
        this.bitstreamDump?.start(this.pc!);
    }

    private stopBitstreamDump(): void {
        this.bitstreamDump?.save();
        this.bitstreamDump = undefined;
    }

    createPeerConnection(peerId: number) {
        Log.d("{93c7910}", "{d79ed0a}", peerId);
        try {
            let pcConfig: RTCConfiguration = {
                iceServers: [{ urls: "stun:s1.stun.gamestream.nvidia.com:19308" }]
            };
            this.bitstreamDump?.maybeUpdateRtcConfig(pcConfig);
            Log.d("{93c7910}", "{93106c2}", JSON.stringify(pcConfig));

            this.remotePeerId = peerId;

            this.pc = new RTCPeerConnection(pcConfig);
            const stopStream = (isIceConnectionFailed: boolean) => {
                if (this.disconnectedIceStats) {
                    this.emitIceStatsEvent("disconnected", this.disconnectedIceStats);
                    this.disconnectedIceStats = undefined;
                }
                const failedIceStats = this.statsService?.getIceStats();
                if (failedIceStats) {
                    this.emitIceStatsEvent("failed", failedIceStats);
                }

                this.iceCandidateFlag |= IceConnectionFlags.FAILED;
                if ((this.iceCandidateFlag & IceConnectionFlags.CONNECTED) == 0) {
                    this.stopStreamWithError(RErrorCode.StreamerIceConnectionFailed);
                } else if (this.statsService && this.statsService.packetsReceived() == 0) {
                    this.stopStreamWithError(RErrorCode.StreamerNoVideoPacketsReceivedEver);
                } else if (this.statsService && this.statsService.getFramesDecoded() == 0) {
                    this.stopStreamWithError(RErrorCode.StreamerNoVideoFramesLossyNetwork);
                } else if (isIceConnectionFailed) {
                    this.stopStreamWithError(RErrorCode.StreamerIceReConnectionFailed);
                } else {
                    this.stopStreamWithError(RErrorCode.StreamerReConnectionFailed);
                }
            };

            this.pc.onconnectionstatechange = (event: Event) => {
                if (this.pc) {
                    Log.d("{93c7910}", "{4ced155}", this.pc.connectionState);
                    switch (this.pc.connectionState) {
                        case "connected":
                            // The connection has become fully connected
                            break;
                        case "disconnected":
                            break;
                        case "failed":
                            // One or more transports has terminated unexpectedly or in an error
                            stopStream(false);
                            break;
                        case "closed":
                            // The connection has been closed
                            break;
                    }
                } else {
                    Log.e("{93c7910}", "{648f784}");
                }
            };

            this.pc.oniceconnectionstatechange = (ev: any) => {
                if (this.pc) {
                    Log.d("{93c7910}", "{3a3143a}", this.pc.iceConnectionState);
                    //https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState#RTCIceConnectionState_enum
                    switch (this.pc.iceConnectionState) {
                        case "new":
                            this.iceCandidateFlag |= IceConnectionFlags.NEW;
                            break;
                        case "checking":
                            this.iceCandidateFlag |= IceConnectionFlags.CHECKING;
                            break;
                        case "connected":
                            this.disconnectedIceStats = undefined;
                            if (this.iceCandidateFlag & IceConnectionFlags.DISCONNECTED) {
                                this.eventEmitter.emit(EVENTS.STREAMING_EVENT, {
                                    streamingState: { state: STREAMING_STATE.RECONNECTED }
                                });
                                this.pcReconnects++;
                            }
                            this.iceCandidateFlag |= IceConnectionFlags.CONNECTED;
                            break;
                        case "completed":
                            this.disconnectedIceStats = undefined;
                            this.iceCandidateFlag |= IceConnectionFlags.COMPLETED;
                            break;
                        case "failed":
                            stopStream(true);
                            break;
                        case "disconnected":
                            this.disconnectedIceStats = this.statsService?.getIceStats();
                            if (this.iceCandidateFlag & IceConnectionFlags.CONNECTED) {
                                this.eventEmitter.emit(EVENTS.STREAMING_EVENT, {
                                    streamingState: { state: STREAMING_STATE.RECONNECTING }
                                });
                            }
                            this.iceCandidateFlag |= IceConnectionFlags.DISCONNECTED;
                            break;
                        case "closed":
                            this.iceCandidateFlag |= IceConnectionFlags.CLOSED;
                            if ((this.iceCandidateFlag & IceConnectionFlags.DISCONNECTED) != 0) {
                                this.stopStreamWithError(RErrorCode.StreamerNetworkError); //in case if we ever reach here without failed.
                            }
                            break;
                    }
                } else {
                    Log.e("{93c7910}", "{648f784}");
                }
            };

            this.pc.ondatachannel = ev => {
                Log.d("{93c7910}", "{b364db9}", ev.channel.label);
                if (ev.channel.label == "control_channel") {
                    this.addDataChannel(ev.channel, {
                        errorCode: RErrorCode.StreamControlChannelError,
                        open: () => {
                            this.controlChannel = ev.channel;

                            for (const msg of this.queuedControlMessages) {
                                this.sendControlMessage(msg);
                            }
                            Log.d("{93c7910}", "{038895c}", this.queuedControlMessages.length);
                            this.queuedControlMessages = [];

                            const ETWS_RTC_BROWSER = `NvRtcClient Browser name: ${this.platformDetails.browser}, Browser version: ${this.platformDetails.browserFullVer}`;
                            this.writeEtwPrint(ETWS_RTC_BROWSER);
                        }
                    });

                    ev.channel.onmessage = (msg: any) => {
                        try {
                            let obj = <ControlChannelMsg>JSON.parse(msg.data);
                            Log.d("{93c7910}", "{9371373}");
                            if (obj.exitMessage) {
                                this.stopStreamWithError(
                                    getRErrorCodeForExitMessage(obj.exitMessage)
                                );
                            } else if (obj.timerNotification) {
                                this.sendTimerEvent(obj);
                            } else if (obj.debugMessage) {
                                this.showDebugMessage(obj.debugMessage.message);
                            } else if (obj.customMessage) {
                                this.emitCustomMessageEvent(obj.customMessage);
                            } else if (obj.videoStreamProgressEvent) {
                                this.videoStreamProgress =
                                    obj.videoStreamProgressEvent.videoStreamProgress;
                            } else {
                                Log.d("{93c7910}", "{d14e0fd}");
                            }
                        } catch (exp) {
                            const MSG = "Error in control_channel message handling";
                            Log.e("{93c7910}", "{a40734a}", exp);
                            this.telemetry.emitExceptionEvent(exp, MSG, "{93c7910}.ts", 0, 0, true);
                        }
                    };
                }
            };

            Log.d("{93c7910}", "{d9ffda2}");
            let inputChannelParams: RTCDataChannelInit = <any>{
                ordered: true,
                reliable: true
            };
            Log.d("{93c7910}", "{2bd4728}");
            this.inputChannel = this.pc.createDataChannel("input_channel_v1", inputChannelParams);
            this.addDataChannel(this.inputChannel, {
                errorCode: RErrorCode.StreamInputChannelError,
                open: () => {
                    this.inputHandler = new InputHandler(
                        this,
                        this.videoElement!,
                        this.inputChannel!,
                        this.telemetry,
                        this.eventEmitter,
                        this.configFlags,
                        this.appLaunchMode === AppLaunchMode.TouchFriendly,
                        this.gamepadTester,
                        this.gamepadHandler,
                        this.platformDetails,
                        this.callbacks,
                        this.safeZoneHandler!,
                        this.textInputElement
                    );
                    if (this.hasPendingKeyboardLayout) {
                        this.setKeyboardLayout(this.keyboardLayout);
                        this.hasPendingKeyboardLayout = false;
                    }
                    if (!this.configFlags.allowUnconfined) {
                        // Always confine the cursor since there are too many limitations without pointer lock.
                        this.inputHandler.setCursorConfinement(true);
                    }
                }
            });

            if (RagnarokSettings.webrtcStatsEnabled) {
                Log.d("{93c7910}", "{9ae428b}");
                const staticStreamStats: StaticStreamStats = {
                    zoneName: this.session.zoneName,
                    clientAppVersion: this.clientAppVersion ?? "",
                    appId: this.session.appId,
                    requestedRegion: this.requestedRegion ?? "",
                    gpuType: this.session.gpuType,
                    streamInfo: this.session.streamInfo[0] ?? { width: 0, height: 0, fps: 0 },
                    clientLocale: this.session.clientLocale
                };
                this.statsService = new ClientStatsService(
                    this.eventEmitter,
                    this,
                    this.pc,
                    this.nvstConfig,
                    this.telemetry,
                    this.platformDetails,
                    this.inputChannel,
                    this.gamepadHandler,
                    staticStreamStats,
                    this.configFlags
                );
            }

            let cursorChannelParams: RTCDataChannelInit = <any>{
                ordered: true,
                reliable: true
            };
            this.cursorChannel = this.pc.createDataChannel("cursor_channel", cursorChannelParams);
            this.cursorChannel.binaryType = "arraybuffer";
            this.addDataChannel(this.cursorChannel, {
                errorCode: RErrorCode.StreamCursorChannelError
            });
            this.cursorChannel.onmessage = msg => {
                this.onCursorMessage(msg);
            };

            // add Firefox support
            if (!IsChromium()) {
                this.pc.addEventListener("icecandidate", (event: any) => {
                    Log.d("{93c7910}", "{4742d5e}");
                    if (this.pc && this.pc.canTrickleIceCandidates && this.pc.onicecandidate) {
                        this.pc.onicecandidate(event);
                    }
                });
            }

            // this doesn't get called if our code do pc.close()
            this.pc.onsignalingstatechange = (event: any) => {
                Log.i("{93c7910}", "{3236227}", this.pc!.signalingState);
            };

            this.pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
                Log.d("{93c7910}", "{78d1fdd}", event);
                if (event.candidate) {
                    if (event.candidate.protocol === "tcp") {
                        Log.d("{93c7910}", "{6f19d1e}");
                        return;
                    }
                    Log.d("{93c7910}", "{0b83eb2}", event.candidate.candidate);
                    const candidate: SignalingMessage = {
                        sdpMLineIndex: event.candidate.sdpMLineIndex,
                        sdpMid: event.candidate.sdpMid,
                        candidate: event.candidate.candidate
                    };
                    //exchange candidates with the remote peer
                    this.sendDataToPeer(peerId, candidate);
                    this.gotLocalCandidate = true;
                } else {
                    Log.d("{93c7910}", "{a759d16}");
                }
            };

            this.pc.onicecandidateerror = (event_: Event) => {
                const event = <RTCPeerConnectionIceErrorEvent>event_;
                Log.w("{93c7910}", "{c56b304}", event.address, event.port, event.url, event.errorCode, event.errorText);
            };

            if (
                this.micCapturer &&
                (RagnarokSettings.micEnabled ?? !IsWebOS(this.platformDetails))
            ) {
                this.micCapturer.initialize(
                    this.pc,
                    this.eventEmitter,
                    this.videoElement!,
                    this.audioElement!
                );
            }

            this.pc.ontrack = (event: RTCTrackEvent) => {
                const stream = event.streams[0];
                Log.d("{93c7910}", "{47359d4}", stream.id, event.track.kind, event.track.id, event.streams.length, GetHexString(this.iceCandidateFlag));

                let track = {
                    kind: event.track.kind === "video" ? TrackType.VIDEO : TrackType.AUDIO,
                    trackId: event.track.id
                };

                // could have both audio and video in single stream case
                const isMediaStream = (): boolean => {
                    return stream.id === LEGACY_STREAM_NAME || stream.id === MEDIA_STREAM_NAME;
                };

                const isAudioStream = (): boolean => {
                    return (
                        stream.id === LEGACY_SECOND_STREAM_NAME || stream.id === AUDIO_STREAM_NAME
                    );
                };

                // only render the first video track received
                if (event.track.kind === "video" && isMediaStream() && !this.gotVideoTrack) {
                    this.gotVideoTrack = true;
                    if (!shouldCombineStreams()) {
                        this.videoElement!.srcObject = stream;
                    }
                } else if (event.track.kind === "audio" && (isMediaStream() || isAudioStream())) {
                    this.gotAudioTrack = true;
                    // audio stream is received only in case of double stream
                    if (isAudioStream() && !shouldCombineStreams()) {
                        this.audioElement!.srcObject = stream;
                    }
                    this.signalAudioPacketsReceived(stream);
                    this.audioRecorder?.initialize(new MediaStream(stream.getAudioTracks()));
                }

                let streamInArray = this.streamsAttached.find(s => s.streamId === stream.id);
                if (streamInArray) {
                    streamInArray.tracks.push(track);
                } else {
                    this.streamsAttached.push({
                        streamId: stream.id,
                        tracks: [track]
                    });
                }
                this.checkAndNotifyStartToClient(); // when all play calls are removed from ragnarok, this is needed.
            };
        } catch (exp) {
            const EXP_MSG = "Exception in creating peer connection";
            Log.e("{93c7910}", "{ff11182}", exp);
            this.telemetry.emitExceptionEvent(exp, EXP_MSG, "{93c7910}.ts", 0, 0, true);
        }
    }

    private stopInputHandler() {
        if (this.inputHandler) {
            this.inputHandler.uninitialize();
            this.inputHandler = null;
        }
    }

    private stopStatsService() {
        // do not reset statsService object to undefined/null we need it to get saved stats like framesdecoded
        this.statsService?.stop();
    }

    onCursorMessage(msg: MessageEvent) {
        let uint8Array = new Uint8Array(msg.data);
        //Log.d("{93c7910}", "{f367b3c}", uint8Array[0], uint8Array[1]);
        if (this.inputHandler) {
            const SYSTEM_CURSOR = 0;
            const BITMAP_CURSOR = 1;
            const CONFINEMENT = 10;
            switch (uint8Array[0]) {
                case SYSTEM_CURSOR:
                case BITMAP_CURSOR:
                    // Set cursor image message
                    let cursorId = uint8Array[1];
                    let hotX = uint8Array[2];
                    let hotY = uint8Array[3];
                    let mimeSize = uint8Array[4];
                    let offset = 5;
                    let read16 = () => {
                        const result = uint8Array[offset] + (uint8Array[offset + 1] << 8);
                        offset += 2;
                        return result;
                    };
                    let mimeType = "";
                    if (mimeSize != 0) {
                        let mimearray = uint8Array.subarray(offset, offset + mimeSize);
                        offset += mimeSize;
                        mimeType = new TextDecoder("utf-8").decode(mimearray);
                    }

                    const payloadSize = read16();
                    let payload = "";
                    if (payloadSize != 0) {
                        let payloadarray = uint8Array.subarray(offset, offset + payloadSize);
                        offset += payloadSize;
                        payload = new TextDecoder("utf-8").decode(payloadarray);
                    }

                    let x = null;
                    let y = null;
                    if (offset + 4 <= uint8Array.byteLength) {
                        x = read16();
                        y = read16();
                    }
                    let scale: number | undefined;
                    if (offset + 2 <= uint8Array.byteLength) {
                        scale = read16() / 100;
                    }
                    if (uint8Array[0] == SYSTEM_CURSOR) {
                        this.inputHandler.handleSystemCursor(cursorId, x, y);
                    } else {
                        this.inputHandler.handleBitmapCursor(
                            cursorId,
                            hotX,
                            hotY,
                            mimeType,
                            payload,
                            x,
                            y,
                            scale
                        );
                    }
                    break;
                case CONFINEMENT:
                    // If !allowUnconfined, do not change the cursor confinement. It will always be enabled.
                    if (this.configFlags.allowUnconfined) {
                        // Set cursor confinement message
                        let confine = uint8Array[1];

                        this.inputHandler.setCursorConfinement(confine == 1);
                    }
                    break;
            }
        }
    }

    updateIceCandidates(sdp: string): string {
        return sdp
            .split("\r\n")
            .map(line => {
                if (!line.startsWith("a=candidate:")) {
                    return line;
                }
                this.gotRemoteCandidate = true;
                return "a=" + this.updateIceCandidate(line.substr(2));
            })
            .join("\r\n");
    }

    updateIceCandidate(iceCandidate: string): string {
        Log.d("{93c7910}", "{2dd62a2}");
        const videoInfo = this.session.mediaConnectionInfo.find(x => x.usage === Usage.VIDEO);
        if (videoInfo) {
            // Update fields [4] and [5] which contain the address and port to connect to.
            // See https://datatracker.ietf.org/doc/html/rfc5245#section-15.1
            let list = iceCandidate.split(" ");
            list[4] = videoInfo.ip;
            list[5] = "" + videoInfo.port;

            let newIceCandidate = list.join(" ");
            Log.d("{93c7910}", "{1fbcd88}", newIceCandidate);
            return newIceCandidate;
        }

        Log.d("{93c7910}", "{aee7283}");
        return iceCandidate;
    }

    private didReceiveExpectedTracks(): boolean {
        let receivedTracks: string[] = [];
        for (const stream of this.streamsAttached) {
            for (const track of stream.tracks) {
                receivedTracks.push(track.trackId!);
            }
        }
        if (this.trackIdsExpected.length !== receivedTracks.length) {
            return false;
        }

        for (const trackId of this.trackIdsExpected) {
            if (!receivedTracks.some(id => id === trackId)) {
                return false;
            }
        }
        Log.d("{93c7910}", "{4fef10b}");
        return true;
    }

    private cacheTrackIdsExpected() {
        let transceivers = this.pc!.getTransceivers();

        if (transceivers.length === 0) {
            Log.d("{93c7910}", "{3399d9d}");
            this.notifyStart({ code: RErrorCode.StreamerNoTracksReceivedInSdp });
            return;
        }

        for (const transceiver of transceivers) {
            /// \todo: Remove this hard coded mid
            if (transceiver.mid !== MIC_MID) {
                this.trackIdsExpected.push(transceiver.receiver.track.id);
            }
        }

        Log.d("{93c7910}", "{648ae93}", JSON.stringify(this.trackIdsExpected));
    }

    async handlePeerMessage(peerId: number, data: string) {
        Log.d("{93c7910}", "{9978507}", peerId);

        try {
            const dataJson = <SignalingMessage>JSON.parse(data);
            if (dataJson.type === "offer") {
                this.gotOffer = true;
                let answerConfig: string | undefined = undefined;
                let sdp: string = dataJson.sdp || "";

                Log.d("{93c7910}", "{ca9a155}", sdp);

                const codecs = await this.codecsPromise;
                const selectedCodec = StreamClient.GetSelectedCodec(sdp, codecs);

                if (dataJson.nvstSdp) {
                    const override = dataJson.nvstServerOverrides ?? "";
                    answerConfig = this.handleNvstSdp(dataJson.nvstSdp, override);
                    if (!answerConfig) {
                        return;
                    }
                }
                this.bitstreamDump = new BitstreamDump(this.nvstConfig, selectedCodec);
                this.createPeerConnection(peerId);
                let startTime = Date.now();
                sdp = this.updateIceCandidates(sdp);
                // This adds an 'a=imageattr' tag for H26[45] targets.  Imageattr notifies the
                // peer about supported resolutions and framerates (nonstandard).  It will eventually
                // come from the server but hack it in here for the time being.  It will be ignored
                // by all clients that don't understand it.
                sdp = StreamClient.AddImageattrsToSDP(
                    sdp,
                    960,
                    this.nvstConfig.video[0].clientViewportWd,
                    540,
                    this.nvstConfig.video[0].clientViewportHt,
                    30, // user is able to select 30fps in the UI
                    this.nvstConfig.video[0].maxFps
                );
                Log.d("{93c7910}", "{542f9d5}", sdp);
                this.pc!.setRemoteDescription({
                    type: dataJson.type,
                    sdp
                })
                    .then(() => {
                        this.timeTakenBySetRemoteDescriptionCall = Date.now() - startTime;
                        this.cacheTrackIdsExpected();
                        Log.d("{93c7910}", "{ab7b3af}", this.timeTakenBySetRemoteDescriptionCall);

                        this.renameStreams();
                        if (shouldCombineStreams()) {
                            // If !ShouldCombineStreams(), this has already happened in ontrack
                            this.setMediaElementStreams();
                        }

                        this.startBitstreamDump();
                        this.addSendVideoTrack();
                        this.setCodecPreferences(selectedCodec);

                        let onSessionDescriptionFunction = (
                            sessionDescription: RTCSessionDescriptionInit
                        ) => {
                            this.timeTakenByCreateAnswerCall = Date.now() - startTime;
                            Log.d("{93c7910}", "{2984003}", this.timeTakenByCreateAnswerCall);
                            //set local session description and provide callback for when it succeeds or fails

                            const bitRateMax =
                                this.nvstConfig.video[0].maximumBitrateKbps.toString();
                            const bitRateMin =
                                this.nvstConfig.video[0].minimumBitrateKbps.toString();
                            const bitRateSrt =
                                this.nvstConfig.video[0].initialBitrateKbps.toString();
                            Log.d("{93c7910}", "{b535c15}", sessionDescription.sdp);
                            if (sessionDescription.sdp !== undefined) {
                                sessionDescription.sdp = StreamClient.GetMediaBitrateUpdatedSDP(
                                    sessionDescription.sdp,
                                    "video",
                                    bitRateMax
                                );
                                if (IsChromium()) {
                                    sessionDescription.sdp = StreamClient.GetGoogBitrateUpdatedSDP(
                                        sessionDescription.sdp,
                                        "video",
                                        bitRateMin,
                                        bitRateMax,
                                        bitRateSrt
                                    );
                                }
                                sessionDescription.sdp = StreamClient.AddOpusStereoSupported(
                                    sessionDescription.sdp,
                                    "audio"
                                );

                                // iOS safari is not tested for surround, rest Chrome/Yandex/Edge supports it
                                const info: MultiopusInfo | undefined =
                                    StreamClient.isMultiopusOffered(sdp, "audio");
                                if (info) {
                                    sessionDescription.sdp = StreamClient.AddOpusSurroundSupported(
                                        sessionDescription.sdp,
                                        "audio",
                                        info
                                    );
                                }
                                Log.d("{93c7910}", "{1a048a7}", sessionDescription.sdp);
                            }

                            startTime = Date.now();
                            this.pc!.setLocalDescription(sessionDescription)
                                .then(() => {
                                    this.timeTakenBySetLocalDescriptionCall =
                                        Date.now() - startTime;
                                    Log.d("{93c7910}", "{de86c01}", this.timeTakenBySetLocalDescriptionCall);
                                    if (sessionDescription.sdp !== undefined && !IsChromium()) {
                                        sessionDescription.sdp =
                                            StreamClient.GetGoogBitrateUpdatedSDP(
                                                sessionDescription.sdp,
                                                "video",
                                                bitRateMin,
                                                bitRateMax,
                                                bitRateSrt
                                            );
                                    }

                                    if (sessionDescription.sdp !== undefined && answerConfig) {
                                        const sdpSections: string[] = SDPUtils.SplitSections(
                                            sessionDescription.sdp
                                        );
                                        const sessionPart: string = sdpSections.shift()!;
                                        const iceParameters: ISDPIceParameters =
                                            SDPUtils.GetIceParameters(sdpSections[0], sessionPart);
                                        const dtlsParameters: ISDPDtlsParameters =
                                            SDPUtils.GetDtlsParameters(sdpSections[0], sessionPart);
                                        const nvSections: string[] = SDPUtils.SplitSections(
                                            answerConfig!
                                        );
                                        nvSections[0] +=
                                            "a=general.icePassword:" +
                                            iceParameters.password +
                                            "\r\n";
                                        nvSections[0] +=
                                            "a=general.iceUserNameFragment:" +
                                            iceParameters.usernameFragment +
                                            "\r\n";
                                        nvSections[0] +=
                                            "a=general.dtlsFingerprint:" +
                                            dtlsParameters.fingerprints[0].value +
                                            "\r\n";
                                        answerConfig = nvSections.join("");
                                    }

                                    const answerMessage: SignalingMessage = {
                                        type: sessionDescription.type,
                                        sdp: sessionDescription.sdp,
                                        nvstSdp: answerConfig
                                    };
                                    this.sendDataToPeer(peerId, answerMessage);

                                    Log.i("{93c7910}", "{bd70666}", JSON.stringify(this.streamsAttached));
                                    this.checkAndNotifyStartToClient();
                                })
                                .catch((sdpError: any) => {
                                    Log.e("{93c7910}", "{ba2deaa}", sdpError);
                                    this.stopStreamWithError(RErrorCode.StreamerSetSDPFailure);
                                });
                        };

                        startTime = Date.now();
                        this.pc!.createAnswer()
                            .then(onSessionDescriptionFunction)
                            .catch((error: DOMException) => {
                                // error
                                Log.d("{93c7910}", "{40c0f69}", error);
                            });
                    })
                    .catch((event: any) => {
                        Log.e("{93c7910}", "{ec2853e}", event);
                        this.stopStreamWithError(RErrorCode.StreamerSetSDPFailure);
                    });
            } else if (dataJson.candidate) {
                // add ice candidates
                Log.d("{93c7910}", "{cf4c103}");
                let updatedCandidate = this.updateIceCandidate(dataJson.candidate);
                Log.d("{93c7910}", "{43e80e0}", updatedCandidate);
                this.pc!.addIceCandidate({
                    sdpMLineIndex: dataJson.sdpMLineIndex,
                    sdpMid: dataJson.sdpMid,
                    candidate: updatedCandidate
                })
                    .then(() => {
                        Log.d("{93c7910}", "{db0ae14}");
                        this.gotRemoteCandidate = true;
                    })
                    .catch((ex?: DOMException) => {
                        Log.e("{93c7910}", "{62f207e}", ex);
                        this.telemetry.emitDebugEvent("AddCandidateFailed", ex?.name, ex?.message);
                    });
                Log.d("{93c7910}", "{9454009}");
            }
        } catch (exp) {
            if (data === "BYE") {
                this.stopStreamWithError(RErrorCode.StreamDisconnectedFromServer);
            } else {
                const EXP_MSG = "Invalid handlePeerMessage Response";
                Log.e("{93c7910}", "{93367fc}", exp);
                this.telemetry.emitExceptionEvent(exp, EXP_MSG, "{93c7910}.ts", 0, 0, true);
            }
        }
    }

    handleNvstSdp(sdp: string, override: string): string | undefined {
        const result = handleNvstOffer(this.nvstConfig, sdp, override, this.platformDetails);
        if (result.config && result.answer) {
            this.nvstConfig = result.config;
            return result.answer;
        } else {
            this.notifyStart({ code: result.error ?? RErrorCode.StreamerNvstSdpFailure });
            return undefined;
        }
    }

    public toggleOnScreenStats(enableDevStats: boolean = false) {
        if (this.statsServiceEnabled()) {
            this.statsService!.toggleOnScreenStats(enableDevStats);
        } else {
            this.showDebugMessage("Stats is OFF. Please enable by ctrl+alt+F5/F6");
            return;
        }
    }

    public getLdatHandler(): ILDATHandler | undefined {
        if (this.statsServiceEnabled()) {
            return this.statsService!.getLdatHandler();
        }
    }

    public toggleProfiler() {
        if (this.profilerRunning) {
            RagnarokProfiler.stopProfiling();
            this.profilerRunning = false;
            this.disableWebRTCStats();
            this.showDebugMessage("Profiler: OFF, Stats: OFF");
        } else {
            this.startProfiler();
            this.enableWebRTCStats();
            this.showDebugMessage("Profiler: ON, Stats: ON");
        }
    }

    public toggleWebRTCStats() {
        if (this.statsServiceEnabled()) {
            this.disableWebRTCStats();
            this.showDebugMessage("Stats: OFF");
        } else {
            this.enableWebRTCStats();
            this.showDebugMessage("Stats: ON");
        }
    }

    private statsServiceEnabled(): boolean {
        return !!(this.statsService && this.statsService.isEnabled());
    }

    enableWebRTCStats() {
        this.statsService?.enableStats();
    }

    disableWebRTCStats() {
        this.statsService?.disableStats();
    }

    sendDataToPeer(peerId: number, message: SignalingMessage) {
        const data = JSON.stringify(message);
        const obj: WebSocketMsg = {
            peer_msg: {
                from: this.id,
                to: peerId,
                msg: data
            },
            ackid: this.isAckSupportedOnWs ? this.ackIdGenerator.getNextAckId() : undefined
        };
        Log.d("{93c7910}", "{7ecb902}", peerId, data.length, JSON.stringify(obj));
        if (this.webSocket) {
            this.webSocket.send(obj);
        } else {
            RagnarokProfiler.sendOverWs(obj);
        }
    }

    getFramesDecoded() {
        if (!this.statsService) return 0;
        return this.statsService.getFramesDecoded();
    }

    getVideoCodec() {
        if (!this.statsService) return "UNKNOWN";
        const codec = this.statsService.getVideoCodec();
        return codec === "" ? "PENDING" : codec;
    }

    public toggleUserInput(enable: boolean, inputs?: InputType) {
        Log.d("{93c7910}", "{7ba1946}", (this.inputHandler !== null), enable);
        this.inputHandler?.toggleUserInput(enable, inputs);
    }

    showDebugMessage(message: string) {
        if (!this.debugMessageElement) {
            return;
        }
        this.debugMessageElement.innerHTML = message;
        this.debugMessageElement.style.display = "block";
        if (this.debugMessageTimeoutId) {
            clearTimeout(this.debugMessageTimeoutId);
        }
        this.debugMessageTimeoutId = window.setTimeout(() => {
            if (this.debugMessageElement) {
                this.debugMessageElement.style.display = "none";
            }
        }, 1000);
    }

    startProfiler() {
        const statsHeader: StatsHeader = {
            stats: {
                from: this.id,
                to: this.remotePeerId
            }
        };

        RagnarokProfiler.startProfiling(statsHeader);
        this.profilerRunning = true;
    }

    static GetMediaBitrateUpdatedSDP(sdp: string, media: string, bitrate: string) {
        let modifier = "AS:";

        let lines = sdp.split("\r\n");
        let line = -1;
        // Find the right media line
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("m=" + media) === 0) {
                line = i;
                break;
            }
        }
        // If not found - return the original SDP payload
        if (line === -1) {
            Log.e("{93c7910}", "{e0899ab}", media);
            return sdp;
        }
        Log.d("{93c7910}", "{5cc346f}", media, line);

        // Go to the next line
        line++;

        // Skip i and c lines - don't mess up the SDP parser order - it seems to matter
        while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
            line++;
        }

        // Are we on the B line - if so, try to override the value
        if (lines[line].indexOf("b") === 0) {
            Log.d("{93c7910}", "{e77177e}", line);
            lines[line] = "b=" + modifier + bitrate;
            return lines.join("\r\n");
        }

        // Add a new b line
        Log.d("{93c7910}", "{3dfc348}", line);
        let newLines = lines.slice(0, line);
        newLines.push("b=" + modifier + bitrate);
        newLines = newLines.concat(lines.slice(line, lines.length));
        return newLines.join("\r\n");
    }

    static isMultiopusOffered(serverSdp: string, media: string): MultiopusInfo | undefined {
        let serverLines = serverSdp.split("\r\n");
        let mediaLine = -1;
        let bFoundMultiopus = 0;

        for (let i = 0; i < serverLines.length; i++) {
            if (serverLines[i].indexOf("m=" + media) === 0) {
                mediaLine = i;
                break;
            }
        }
        if (mediaLine === -1) {
            Log.d("{93c7910}", "{a939549}", media);
            return undefined;
        }

        //multiopus will be highest preference codec thus listed at first, RFC 3264
        let multiopusFmt = serverLines[mediaLine].split(" ")[3];
        let multiopusSpec: string[] = [];
        for (let i = mediaLine + 1; i < serverLines.length; i++) {
            if (serverLines[i].indexOf("a=rtpmap:" + multiopusFmt + " multiopus") === 0) {
                multiopusSpec.push(serverLines[i]);
                bFoundMultiopus = 1;
            } else if (
                bFoundMultiopus &&
                (serverLines[i].indexOf("a=rtcp-fb:" + multiopusFmt) === 0 ||
                    serverLines[i].indexOf("a=fmtp:" + multiopusFmt) === 0)
            ) {
                multiopusSpec.push(serverLines[i]);
            } else if (serverLines[i].indexOf("m=") === 0) {
                Log.d("{93c7910}", "{9fc6964}", serverLines[i]);
                break;
            }
        }

        if (bFoundMultiopus === 0) {
            Log.d("{93c7910}", "{9d15736}", media, multiopusFmt);
            return undefined;
        }

        const info: MultiopusInfo = {
            fmt: multiopusFmt,
            specification: multiopusSpec.join("\r\n")
        };
        return info;
    }

    // Need to do sdp munge to support surround audio, RFC 3264
    // TODO Remove this when multiopus is offered in codec list ie. Fmt 0 is fixed
    static AddOpusSurroundSupported(sdp: string, media: string, multiopus: MultiopusInfo) {
        let lines = sdp.split("\r\n");
        let mediaLine = -1;
        let endLine = -1;

        for (let i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("m=" + media) === 0) {
                mediaLine = i;
                break;
            }
        }
        if (mediaLine === -1) {
            Log.d("{93c7910}", "{e0899ab}", media);
            return sdp;
        }

        let newLines = lines.slice(0, mediaLine);
        //Append multiopus fmt to media line, as it was set 0 (multiopus is non-advertised codec)
        let mediaDescriptor = lines[mediaLine].split(" ");
        if (mediaDescriptor[3] !== "0") {
            Log.d("{93c7910}", "{794b3c6}", media, mediaDescriptor.join(" "));
            return sdp;
        }
        mediaDescriptor[3] = multiopus.fmt;
        newLines.push(mediaDescriptor.join(" "));

        // search the end of current mediablock and then add multiopus
        for (let i = mediaLine + 1; i < lines.length; i++) {
            if (lines[i].indexOf("m=") === 0) {
                endLine = i;
                break;
            }
        }

        if (endLine === -1) {
            //Since we end SDP with a empty line, Rfc 4566
            endLine = lines.length - 1;
        }
        newLines = newLines.concat(lines.slice(mediaLine + 1, endLine));

        //add multiopus Rtpmap and Fmtp from server sdp
        newLines = newLines.concat(multiopus.specification.split("\r\n"));
        newLines = newLines.concat(lines.slice(endLine));
        return newLines.join("\r\n");
    }

    //Indicate that the client prefers stereo audio (see RFC 7587)
    static AddOpusStereoSupported(sdp: string, media: string) {
        let fmtpLines: Array<number> = [];
        let lines = sdp.split("\r\n");
        let fmt = "-1";
        // Find the right media line then obtain fmtp from that media block
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("m=" + media) === 0) {
                fmt = lines[i].split(" ")[3];
            } else if (lines[i].indexOf("a=fmtp:" + fmt + " ") === 0) {
                // Find the fmtp (will always be ahead of media line)
                fmtpLines.push(i);
            }
        }
        // If not found - return the original SDP payload
        if (fmtpLines.length === 0) {
            Log.e("{93c7910}", "{b2c3701}", media, fmt);
            return sdp;
        }

        let fmtp;
        let lastFmtpLine = -1;
        let newLines: string[] = [];
        for (const fmtpLine of fmtpLines) {
            fmtp = lines[fmtpLine];
            Log.d("{93c7910}", "{9bb0e6f}", fmtp);
            fmtp = fmtp + ";stereo=1";
            Log.d("{93c7910}", "{4f021eb}", fmtp);

            newLines = newLines.concat(lines.slice(lastFmtpLine + 1, fmtpLine));
            newLines.push(fmtp);
            lastFmtpLine = fmtpLine;
        }
        newLines = newLines.concat(lines.slice(lastFmtpLine + 1));
        return newLines.join("\r\n");
    }

    // Adds 'a=imageattr' to SDP for H26[45] targets.  See RFC 6236.
    // We also added nonstandard parameter "framerate," which is allowed
    // by the spec.
    // Should be removed when server begins to send this in the SDP.
    // Only adds "a=imageattr" to first video media section of SDP.
    static AddImageattrsToSDP(
        sdp: string,
        minw: number,
        maxw: number,
        minh: number,
        maxh: number,
        minfps: number,
        maxfps: number
    ) {
        let lines = sdp.split("\r\n");
        let lineIndex = -1;
        // Find the video media line
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].startsWith("m=video")) {
                lineIndex = i;
                break;
            }
        }
        // If not found - return the original SDP payload
        if (lineIndex === -1) {
            Log.e("{93c7910}", "{3ab33e3}");
            return sdp;
        }

        // Go to the next line
        lineIndex++;

        // First, search for a=imageattr to make sure it is not here yet.
        // This allows seamless upgrade when server adds it to the SDP.
        for (let i = lineIndex; i < lines.length; i++) {
            if (lines[i].startsWith("a=imageattr")) {
                Log.d("{93c7910}", "{b38bf22}");
                return sdp;
            }
        }

        // ok, no imageattr - continue to add

        let payloadTypes = new Set<string>();
        // Look for all RTP payloads that correspond to H26[45] streams, taking care not to
        // move into the next media section. Add imageattr line for each payload.
        while (lineIndex < lines.length && !lines[lineIndex].startsWith("m=")) {
            const line = lines[lineIndex];
            const h26XRe = /a=rtpmap:.*H26[45]\//;
            if (h26XRe.test(line)) {
                const payloadType = line.slice(9, line.indexOf(" "));
                payloadTypes.add(payloadType);
            } else if (line.indexOf("a=fmtp:") === 0) {
                const payloadType = line.slice(7, line.indexOf(" "));
                if (payloadTypes.has(payloadType)) {
                    // insert new line after fmtp for this payloadType
                    lineIndex++;
                    let imageattr = `a=imageattr:${payloadType} send [x=[${minw}:${maxw}],y=[${minh}:${maxh}],fps=[${minfps}:${maxfps}]]`;
                    lines.splice(lineIndex, 0, imageattr);
                }
            }
            lineIndex++;
        }
        return lines.join("\r\n");
    }

    static GetGoogBitrateUpdatedSDP(
        sdp: string,
        media: string,
        br_min: string,
        br_max: string,
        br_start: string
    ) {
        let lines = sdp.split("\r\n");
        let lineIndex = -1;
        // Find the right media line
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].indexOf("m=" + media) === 0) {
                lineIndex = i;
                break;
            }
        }
        // If not found - return the original SDP payload
        if (lineIndex === -1) {
            Log.e("{93c7910}", "{e0899ab}", media);
            return sdp;
        }
        Log.d("{93c7910}", "{5cc346f}", media, lineIndex);

        // Go to the next line
        lineIndex++;

        // Additional format params
        let additionalParams = "";
        additionalParams += ";x-google-max-bitrate=" + br_max;
        additionalParams += ";x-google-min-bitrate=" + br_min;
        additionalParams += ";x-google-start-bitrate=" + br_start;

        let h264Payloads = new Set<string>();
        // Look for all RTP payloads that correspond to H264 streams, taking care not to
        // move into the next media section. When we find corresponding format parameters
        // for one, add our additional parameters
        while (lineIndex < lines.length && lines[lineIndex].indexOf("m=") !== 0) {
            const line = lines[lineIndex];
            if (line.indexOf("a=rtpmap:") === 0 && line.indexOf("H264/") > 0) {
                const payload = line.slice(9, line.indexOf(" "));
                h264Payloads.add(payload);
            } else if (line.indexOf("a=fmtp:") === 0) {
                const payload = line.slice(7, line.indexOf(" "));
                if (h264Payloads.has(payload)) {
                    // Modify the line in-place instead of using our local variable
                    lines[lineIndex] += additionalParams;
                }
            }
            lineIndex++;
        }
        return lines.join("\r\n");
    }

    /**
     * @brief Compares the provided SDP to the list of supported codecs, selecting the top codec present in the SDP that has a match in the codec list.
     * If no match is found, defaults to H264.
     * @param sdp the WebRTC SDP
     * @param codecs list of codecs supported by device in descending order according to priority.
     * The first codec in the array is given highest priority.
     * @returns the selected codec
     * @todo Consoliate SDP manipulation into one function or group of functions.
     */
    static GetSelectedCodec(sdp: string, codecs: SdpCodecType[]): SdpCodecType {
        const lines = sdp.split("\r\n");
        let mediaLineIndex = -1;
        let lineIndex = -1;

        if (!codecs.length) {
            return SdpCodecType.H264;
        }

        // Find the video media line
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].startsWith("m=video")) {
                mediaLineIndex = i;
                break;
            }
        }
        if (mediaLineIndex === -1) {
            Log.e("{93c7910}", "{3ab33e3}");
            return SdpCodecType.H264;
        }

        // Go to the next line
        lineIndex = mediaLineIndex + 1;

        const sdpCodecs = new Set<SdpCodecType>();

        // Find all codecs present in the SDP
        while (lineIndex < lines.length && !lines[lineIndex].startsWith("m=")) {
            const line = lines[lineIndex];
            const rtpMap = "a=rtpmap:";
            if (line.indexOf(rtpMap) === 0) {
                const codec = line.slice(line.indexOf(" ") + 1, line.indexOf("/"));
                if (
                    codec === SdpCodecType.H264 ||
                    codec === SdpCodecType.H265 ||
                    codec === SdpCodecType.AV1
                ) {
                    sdpCodecs.add(codec);
                }
            }
            lineIndex++;
        }

        // Traverse list of supported codecs, selecting the first that is present in the SDP
        for (const codec of codecs) {
            if (sdpCodecs.has(codec)) {
                Log.i("{93c7910}", "{304c3b1}", codec);
                return codec;
            }
        }

        Log.i("{93c7910}", "{81357e4}");
        return SdpCodecType.H264;
    }

    public getMaxBitRate() {
        return this.nvstConfig.video[0].maximumBitrateKbps;
    }

    public getVirtualGamepadHandler(): VirtualGamepadHandler | undefined {
        return this.inputHandler?.getVirtualGamepadHandler();
    }

    public getVideoElement(): HTMLVideoElement | null {
        return this.videoElement;
    }

    public getExtendedDebugStats(): ExtendedDebugStats {
        return {
            isVideoElementPaused: this.videoElement?.paused ?? false,
            isAudioElementPaused: this.audioElement?.paused ?? false,
            isUserInputEnabled: this.inputHandler?.isUserInputEnabled() ?? false,
            isVirtualKeyboardVisible: this.inputHandler?.getVirtualKeyboardState() ?? false,
            micState: this.micCapturer?.getMicState() ?? MicState.UNINITIALIZED,
            isRsdmmActive: this.gamepadHandler.isRsdmmActive(),
            keyboardLayout: this.keyboardLayout,
            appLaunchMode: this.appLaunchMode
        };
    }

    public togglePerfIndicator() {
        this.perfIndicator = !this.perfIndicator;
        const msg: ControlChannelMsg = {
            perfIndicator: { on: this.perfIndicator }
        };
        this.queueControlMessage(msg);
    }

    public toggleStutterIndicator() {
        this.stutterIndicator = !this.stutterIndicator;
        const msg: ControlChannelMsg = {
            stutterIndicator: { on: this.stutterIndicator }
        };
        this.queueControlMessage(msg);
    }

    // Returns true if we sent the latency trigger
    public sendLatencyTrigger(): boolean {
        if (this.audioRecorder?.startLatencyDump()) {
            const msg: ControlChannelMsg = {
                latencyTrigger: true
            };
            this.queueControlMessage(msg);
            return true;
        }
        return false;
    }

    public sendPcmDumpTrigger() {
        if (this.audioRecorder?.startPcmDump()) {
            const msg: ControlChannelMsg = {
                pcmDumpTrigger: true
            };
            this.queueControlMessage(msg);
        }
    }

    public eventTriggerLatencyDump() {
        this.audioRecorder?.createNewLatencyDump();
    }

    public toggleGpuViewCapture() {
        // TODO: This lives in the mall client in native, along with the rest of hotkey handling. We should work to
        // move it out
        this.sendCustomMessage({
            messageType: "GpuViewStartRequest",
            messageRecipient: "GPUViewTraceControl",
            data: "GpuView"
        });
        Log.i("{93c7910}", "{0915cd3}");
    }

    public sendTextInput(text: ArrayBuffer) {
        this.inputHandler?.sendTextInput(text);
    }

    public setVirtualKeyboardState(visible: boolean) {
        this.inputHandler?.setVirtualKeyboardState(visible);
    }

    public setVideoTransforms(offsetX: number, offsetY: number, zoomFactor: number) {
        this.inputHandler?.clientRequestVideoTransform(offsetX, offsetY, zoomFactor);
    }

    public sendCustomMessage(data: CustomMessage) {
        const message: ControlChannelMsg = {
            customMessage: JSON.stringify(data)
        };
        this.queueControlMessage(message);
    }

    public setStreamingMaxBitrate(streamIdx: number, kbps: number) {
        this.queueControlMessage({ setMaxBitrate: { streamIndex: streamIdx, maxBitrate: kbps } });
        Log.d("{93c7910}", "{6bbc1a2}", kbps);
    }

    public setDrcDfcState(streamIdx: number, enabled: boolean) {
        this.queueControlMessage({ setDrcState: { streamIndex: streamIdx, state: enabled } });
        this.queueControlMessage({ setDfcState: { streamIndex: streamIdx, state: enabled } });
        Log.d("{93c7910}", "{0ed76f3}", enabled);
    }

    public writeEtwPrint(msg: string) {
        this.queueControlMessage({ etwPrint: msg });
    }

    public emitChannelErrorEvent(name: string, event: Event, bufferedAmount?: number) {
        const error = (<RTCErrorEvent>event)?.error;
        // Only report this if the session isn't already stopping. Client-initiated stops
        // always trigger error events on all data channels
        if (!this.stopNotified) {
            this.telemetry.emitDebugEvent(
                "ChannelError",
                name,
                error?.name,
                error?.message,
                bufferedAmount?.toString()
            );
        }
        Log.e("{93c7910}", "{db7ae96}", name, error?.name, error?.message);
    }

    public sendKeyEvent(event: KeyboardEvent) {
        this.inputHandler?.sendKeyEvent(event);
    }

    private checkAndNotifyStartToClient() {
        if (
            this.trackIdsExpected.length > 0 &&
            getChannelState(this.controlChannel) !== ChannelState.CONNECTING &&
            getChannelState(this.cursorChannel) !== ChannelState.CONNECTING &&
            getChannelState(this.inputChannel) !== ChannelState.CONNECTING &&
            this.didReceiveExpectedTracks()
        ) {
            this.notifyStart();
        }
    }

    private signalAudioPacketsReceived(stream: MediaStream) {
        let audioTrack = getAudioTrack(stream);
        if (audioTrack) {
            audioTrack.onunmute = () => {
                this.audioTrackMuted = false;
            };
        }
    }

    public setKeyboardLayout(layout: string) {
        this.keyboardLayout = layout;
        if (!this.inputHandler) {
            this.hasPendingKeyboardLayout = true;
        } else {
            this.sendCustomMessage({
                messageType: "kbLayout",
                messageRecipient: "KBLayoutChange",
                data: layout
            });

            this.inputHandler.setKeyboardLayout(layout);
        }
    }

    private logStreamTimestamps() {
        Log.d("{93c7910}", "{440d709}", RagnarokProfiler.getStreamBeginTime(), performance.timeOrigin);
    }

    /**
     * Configures the given data channel with common event handling, logs, and telemetry
     * @param dataChannel The data channel to configure
     * @param params How to configure the given data channel, see DataChannelParams for details
     */
    public addDataChannel(dataChannel: RTCDataChannel, params: DataChannelParams): void {
        // TODO: If requiredForStreaming, we could keep track of the data channel in a list instead of hard coding
        // the list of required channels in checkAndNotifyStartToClient
        const channelName = dataChannel.label;
        let isClosing = false;
        dataChannel.onopen = () => {
            Log.d("{93c7910}", "{8e9ee5d}", channelName);
            params.open?.();
            if (params.errorCode) {
                this.checkAndNotifyStartToClient();
            }
        };

        dataChannel.onclosing = () => {
            Log.d("{93c7910}", "{d0c8ae7}", channelName);
            isClosing = true;
        };

        dataChannel.onclose = () => {
            Log.d("{93c7910}", "{a3c9826}", channelName);
            params.close?.();
            if (params.errorCode) {
                this.stopStreamWithErrorIfSleep();
                // TODO: I guess we should stop the stream unconditionally here
            }
        };

        dataChannel.onerror = error => {
            Log.e("{93c7910}", "{fcd64bb}", channelName);
            if (isClosing && params.errorCode) {
                this.stopStreamDueToChannelClosing();
            } else {
                this.emitChannelErrorEvent(channelName, error, dataChannel.bufferedAmount);
                if (params.errorCode) {
                    this.stopStreamWithError(params.errorCode);
                }
            }
        };
    }
}

function getAudioTrack(stream: MediaStream) {
    let audioTrack = null;
    let audioTracks = stream.getAudioTracks();
    if (audioTracks.length) {
        audioTrack = audioTracks[0];
    }
    return audioTrack;
}

function shouldCombineStreams(): boolean {
    return (
        RagnarokSettings.combineStreams ?? RagnarokSettings.ragnarokConfig.combineStreams ?? true
    );
}

/**
 * If the provided data channel is defined, returns readyState.
 * Otherwise, returns "connecting" since the data channel hasn't been created yet.
 */
function getChannelState(dataChannel?: RTCDataChannel): RTCDataChannelState {
    return dataChannel?.readyState ?? ChannelState.CONNECTING;
}
