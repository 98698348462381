import { LatencyIndicator } from "../debug/latencyindicator";
import { RagnarokSettings } from "../util/settings";
import { IsTouchCapable } from "../dependencies";
import { WarpTouch } from "../util/utils";

export interface TouchRecord {
    identifier: number;
    clientX: number;
    clientY: number;
    deltaX: number;
    deltaY: number;
}

function recordFromTouch(touch: Touch, warp: boolean = false): TouchRecord {
    let pt = warp ? WarpTouch(touch) : { x: touch.clientX, y: touch.clientY };
    return {
        identifier: touch.identifier,
        clientX: pt.x,
        clientY: pt.y,
        deltaX: 0,
        deltaY: 0
    };
}

const enum GestureType {
    NONE,
    PENDING,
    HOLD,
    DRAG,
    HELD_DRAG,
    SCROLL,
    PAN_ZOOM
}

export interface GestureHandler {
    tap: (
        target: HTMLElement,
        timestamp: number,
        lastTouch: TouchRecord,
        touchCount: number
    ) => void;
    holdBegin: (target: HTMLElement, timestamp: number, touch: TouchRecord) => void;
    holdEnd: (target: HTMLElement, timestamp: number) => void;
    drag: (target: HTMLElement, timestamp: number, touch: TouchRecord) => void;
    scroll: (target: HTMLElement, timestamp: number, touches: TouchRecord[]) => void;
    panZoom: (target: HTMLElement, timestamp: number, touches: TouchRecord[]) => void;
    panZoomEnd: (target: HTMLElement, timestamp: number) => void;
    shouldPreventDefaultTouch: () => boolean;
}

// https://developer.apple.com/documentation/uikit/uilongpressgesturerecognizer?language=objc
export const maximumTapDurationMs: number = 500;

export class GestureDetector {
    static isSupported(): boolean {
        return RagnarokSettings.forceTouchCapable || IsTouchCapable();
    }

    // https://developer.apple.com/documentation/uikit/uilongpressgesturerecognizer?language=objc
    private allowableMovement: number = 10;

    private currentTouches: TouchRecord[] = [];
    private tapTimerId: number = 0;
    private maxTouchCount: number = 0;
    private activeGesture: GestureType = GestureType.NONE;

    private touchStartListener = (evt: TouchEvent) => {
        let touchHandled = false;

        const touches = evt.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            if (touch.target === this.target) {
                touchHandled = true;

                LatencyIndicator.getInstance().toggleIndicator();

                if (this.currentTouches.length === 0) {
                    this.activeGesture = GestureType.PENDING;
                    this.tapTimerId = window.setTimeout(() => {
                        this.tapTimerId = 0;
                        if (this.maxTouchCount === 1) {
                            this.activeGesture = GestureType.HOLD;
                            if (RagnarokSettings.advancedGestures) {
                                this.gestureHandler.holdBegin(
                                    this.target,
                                    evt.timeStamp,
                                    recordFromTouch(touch)
                                );
                            }
                        }
                    }, maximumTapDurationMs);
                } else if (this.activeGesture !== GestureType.PENDING) {
                    if (
                        this.activeGesture === GestureType.HOLD ||
                        this.activeGesture === GestureType.HELD_DRAG
                    ) {
                        if (RagnarokSettings.advancedGestures) {
                            this.gestureHandler.holdEnd(this.target, evt.timeStamp);
                        }
                    } else if (this.activeGesture === GestureType.PAN_ZOOM) {
                        if (RagnarokSettings.advancedGestures) {
                            this.gestureHandler.panZoomEnd(this.target, evt.timeStamp);
                        }
                    }
                    this.activeGesture = GestureType.NONE; // cancel active gesture
                }

                this.currentTouches.push(recordFromTouch(touch));
                if (this.currentTouches.length > this.maxTouchCount) {
                    this.maxTouchCount = this.currentTouches.length;
                }
            }
        }

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    };

    private touchMoveListener = (evt: TouchEvent) => {
        let touchHandled = false;

        for (let touchRecord of this.currentTouches) {
            touchRecord.deltaX = 0;
            touchRecord.deltaY = 0;
        }

        const changedTouches = evt.changedTouches;
        let updatedTouchIndices: number[] = [];
        for (let i = 0; i < changedTouches.length; i++) {
            const touch = changedTouches[i];
            const touchStartIndex = this.currentTouches.findIndex(
                t => t.identifier == touch.identifier
            );
            if (touchStartIndex != -1) {
                touchHandled = true;

                const touchStart = this.currentTouches[touchStartIndex];
                const deltaX = touch.clientX - touchStart.clientX;
                const deltaY = touch.clientY - touchStart.clientY;

                let shouldUpdateTouch = false;

                if (this.tapTimerId !== 0) {
                    if (
                        Math.abs(deltaX) > this.allowableMovement ||
                        Math.abs(deltaY) > this.allowableMovement
                    ) {
                        window.clearTimeout(this.tapTimerId);
                        this.tapTimerId = 0;

                        shouldUpdateTouch = true;
                    }
                } else {
                    shouldUpdateTouch = true;
                }

                if (shouldUpdateTouch) {
                    const touchRecord = {
                        identifier: touch.identifier,
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                        deltaX: deltaX,
                        deltaY: deltaY
                    };
                    updatedTouchIndices.push(touchStartIndex);
                    this.currentTouches[touchStartIndex] = touchRecord;
                }
            }
        }

        if (
            updatedTouchIndices.length > 0 &&
            this.tapTimerId === 0 && // no longer considered a tap
            this.activeGesture !== GestureType.NONE // gesture is not cancelled
        ) {
            if (this.currentTouches.length === 1) {
                if (this.activeGesture === GestureType.PENDING) {
                    this.activeGesture = GestureType.DRAG;
                } else if (this.activeGesture === GestureType.HOLD) {
                    this.activeGesture = GestureType.HELD_DRAG;
                }
                if (RagnarokSettings.advancedGestures) {
                    this.gestureHandler.drag(this.target, evt.timeStamp, this.currentTouches[0]);
                }
            } else if (this.currentTouches.length === 2) {
                if (this.activeGesture === GestureType.SCROLL) {
                    if (RagnarokSettings.advancedGestures) {
                        this.gestureHandler.scroll(this.target, evt.timeStamp, this.currentTouches);
                    }
                } else if (this.activeGesture === GestureType.PAN_ZOOM) {
                    if (RagnarokSettings.advancedGestures) {
                        this.gestureHandler.panZoom(
                            this.target,
                            evt.timeStamp,
                            this.currentTouches
                        );
                    }
                } else if (this.activeGesture === GestureType.PENDING) {
                    if (
                        this.currentTouches[0].deltaY * this.currentTouches[1].deltaY > 0 && // touches moving same direction veritically and
                        (Math.sign(this.currentTouches[0].deltaX) ===
                            Math.sign(this.currentTouches[1].deltaX) || // touches moving the same direction or
                            (Math.abs(this.currentTouches[0].deltaX) < this.allowableMovement &&
                                Math.abs(this.currentTouches[1].deltaX) < this.allowableMovement)) // not moving horizontally
                    ) {
                        this.activeGesture = GestureType.SCROLL;
                        if (RagnarokSettings.advancedGestures) {
                            this.gestureHandler.scroll(
                                this.target,
                                evt.timeStamp,
                                this.currentTouches
                            );
                        }
                    } else {
                        this.activeGesture = GestureType.PAN_ZOOM;
                        if (RagnarokSettings.advancedGestures) {
                            this.gestureHandler.panZoom(
                                this.target,
                                evt.timeStamp,
                                this.currentTouches
                            );
                        }
                    }
                }
            } else {
                this.activeGesture = GestureType.NONE; // more than two finger move does not have a supported gesture
            }
        }

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    };

    private endTouches(evt: TouchEvent, shouldSendTap: boolean) {
        let touchHandled = false;

        const touches = evt.changedTouches;
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            const touchStartIndex = this.currentTouches.findIndex(
                t => t.identifier == touch.identifier
            );
            if (touchStartIndex != -1) {
                touchHandled = true;

                LatencyIndicator.getInstance().toggleIndicator();

                this.currentTouches.splice(touchStartIndex, 1);
                if (this.currentTouches.length === 0) {
                    if (this.tapTimerId !== 0) {
                        window.clearTimeout(this.tapTimerId);
                        this.tapTimerId = 0;
                        if (shouldSendTap) {
                            this.gestureHandler.tap(
                                this.target,
                                evt.timeStamp,
                                recordFromTouch(touch, true),
                                this.maxTouchCount
                            );
                        }
                    } else if (
                        this.activeGesture === GestureType.HOLD ||
                        this.activeGesture === GestureType.HELD_DRAG
                    ) {
                        if (RagnarokSettings.advancedGestures) {
                            this.gestureHandler.holdEnd(this.target, evt.timeStamp);
                        }
                    }

                    this.maxTouchCount = 0;
                    this.activeGesture = GestureType.NONE;
                } else if (this.activeGesture === GestureType.SCROLL) {
                    this.activeGesture = GestureType.NONE;
                } else if (this.activeGesture === GestureType.PAN_ZOOM) {
                    if (RagnarokSettings.advancedGestures) {
                        this.gestureHandler.panZoomEnd(this.target, evt.timeStamp);
                    }
                    this.activeGesture = GestureType.NONE;
                }
            }
        }

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    }

    private touchCancelListener = (evt: TouchEvent) => {
        this.endTouches(evt, false);
    };

    private touchEndListener = (evt: TouchEvent) => {
        this.endTouches(evt, true);
    };

    constructor(
        private target: HTMLElement,
        private videoAddEventListener: (eventName: string, handler: any, options?: any) => void,
        private videoRemoveEventListener: (eventName: string, handler: any) => void,
        private gestureHandler: GestureHandler
    ) {}

    start() {
        const options = {
            passive: !RagnarokSettings.advancedGestures
        };
        this.videoAddEventListener("touchstart", this.touchStartListener, options);
        this.videoAddEventListener("touchmove", this.touchMoveListener, options);
        this.videoAddEventListener("touchcancel", this.touchCancelListener, options);
        this.videoAddEventListener("touchend", this.touchEndListener, options);
    }

    stop() {
        this.videoRemoveEventListener("touchstart", this.touchStartListener);
        this.videoRemoveEventListener("touchmove", this.touchMoveListener);
        this.videoRemoveEventListener("touchcancel", this.touchCancelListener);
        this.videoRemoveEventListener("touchend", this.touchEndListener);

        this.currentTouches = [];
        if (this.tapTimerId !== 0) {
            window.clearTimeout(this.tapTimerId);
            this.tapTimerId = 0;
        }
        this.maxTouchCount = 0;
        if (RagnarokSettings.advancedGestures) {
            switch (this.activeGesture) {
                case GestureType.PAN_ZOOM:
                    this.gestureHandler.panZoomEnd(this.target, performance.now());
                    break;
                case GestureType.HOLD:
                case GestureType.HELD_DRAG:
                    this.gestureHandler.holdEnd(this.target, performance.now());
                    break;
                default:
                    break;
            }
        }
        this.activeGesture = GestureType.NONE;
    }
}
