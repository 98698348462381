import { PlatformDetails, PlatformName } from "@gamestream/ragnarok";

/**
 * Convert a signed integer value to an unsigned integer value.
 *
 * Returns signed 2's complement for negative numbers and results in no-op for positive numbers.
 *
 * Unified error codes are represented as 32 bit unsigned integers.
 *  Because Javascript's number type is not a 32 bit integer, these codes can be sent as a negative value.
 * For example, the code 0xC1C8B0B0 is sent as -1043812176, or -0x3E374F50 in hex.  This code is converted
 * to 0xFFFFFFFFC1C8B0B0, which after extracting the rightmost 32 bits results in the original code, 0xC1C8B0B0.
 *
 * https://confluence.nvidia.com/display/GNCE/New+Schema+Format
 **/
export function convertToUnsignedInt(code: number): number {
    return code >>> 0;
}

/**
 * Returns an hex format of an integer code. The hex string starts with 0x and has 8 upper case hex format.
 **/
export function GetHexString(code: number): string {
    code = convertToUnsignedInt(code);
    let hex = ("00000000" + code.toString(16).toUpperCase()).slice(-8);
    return "0x" + hex;
}

/**
 * Returns an randomly generated number in string format for the requested length.
 **/
export function GetRandNumericString(length: number): string {
    let multiplier = 1;
    for (let x = 1; x < length; x++) {
        multiplier = multiplier * 10;
    }
    let randnum = "" + Math.floor(Math.random() * multiplier);
    randnum = randnum.padEnd(length, "0");
    return randnum;
}

export function IsIPv4Address(ipAddress: string): boolean {
    const ipComponents = ipAddress.split(".");
    if (ipComponents.length !== 4) {
        return false;
    }

    for (const component of ipComponents) {
        const componentInt = parseInt(component);
        if (isNaN(componentInt)) {
            return false;
        }
    }

    return true;
}

/**
 * Returns true if the input string is an ipv4 address format. X.X.X.X -> X:<0 - 255>
 **/
export function IsValidIPv4(ipAddress: string): boolean {
    const ipComponents = ipAddress.split(".");
    if (ipComponents.length !== 4) {
        return false;
    }
    for (const component of ipComponents) {
        const componentInt = parseInt(component);
        if (isNaN(componentInt) || componentInt < 0 || componentInt > 255) {
            return false;
        }
    }
    return true;
}

export function IsXbox(platformDetails: PlatformDetails): boolean {
    return platformDetails.os === PlatformName.XBOX;
}

export function IsiPadOS(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.IPADOS;
}

export function IsWebOS(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.WEBOS;
}

export function IsTizen(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.TIZEN;
}

export function IsiOS(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.IOS;
}

export function IsiDevice(platform: PlatformDetails): boolean {
    return IsiOS(platform) || IsiPadOS(platform);
}
