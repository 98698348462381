import { PlatformDetails, IsTizenOS, StreamInfo, IsWebOS, Log } from "../dependencies";
import { RagnarokSettings } from "./settings";
import { CalculateMaxBitrateKbps, IsResolution4k, IsResolution1440p, RunTaskOnInit } from "./utils";

const LOGTAG = "devicecapabilities";

declare interface VideoConfiguration {
    bitrate: number;
    contentType: string;
    framerate: number;
    height: number;
    width: number;
}

declare interface MediaConfiguration {
    video: VideoConfiguration;
}

declare type MediaDecodingType = "file" | "media-source" | "webrtc";

declare interface MediaDecodingConfiguration extends MediaConfiguration {
    type: MediaDecodingType;
}

export declare interface MediaCapabilitiesDecodingInfo {
    supported: boolean;
    smooth: boolean;
    powerEfficient: boolean;
}

declare interface MediaCapabilities {
    decodingInfo(configuration: MediaDecodingConfiguration): Promise<MediaCapabilitiesDecodingInfo>;
}

export function ConvertCapabilityToNumber(capability?: MediaCapabilitiesDecodingInfo): number {
    let numCapability = 0;
    if (capability?.supported) {
        numCapability |= 1;
    }
    if (capability?.smooth) {
        numCapability |= 2;
    }
    if (capability?.powerEfficient) {
        numCapability |= 4;
    }
    return numCapability;
}

export function IsMediaCapabilitiesSupported(): boolean {
    const mediaCapabilities: MediaCapabilities = (navigator as any).mediaCapabilities;
    return !!mediaCapabilities;
}

export class DeviceCapabilitiesImpl {
    private supportsAv1?: MediaCapabilitiesDecodingInfo;
    private supportsH265?: MediaCapabilitiesDecodingInfo;
    private checkAv1?: Promise<MediaCapabilitiesDecodingInfo | undefined>;
    private checkH265?: Promise<MediaCapabilitiesDecodingInfo | undefined>;
    private refreshRate?: number;
    private refreshRatePromise?: Promise<number>;
    public constructor() {
        RunTaskOnInit(this.refresh120FpsSupport.bind(this));
        RunTaskOnInit(this.refreshAv1Support.bind(this));
        RunTaskOnInit(this.refreshH265Support.bind(this));
    }

    public refreshAv1Support(): Promise<MediaCapabilitiesDecodingInfo | undefined> {
        this.checkAv1 = this.getDecodeCapability("av1").then(
            (capability?: MediaCapabilitiesDecodingInfo) => {
                this.supportsAv1 = capability;
                return this.supportsAv1;
            }
        );
        return this.checkAv1;
    }

    public getAv1Capabilities(): Promise<MediaCapabilitiesDecodingInfo | undefined> {
        if (this.supportsAv1) {
            return Promise.resolve(this.supportsAv1);
        } else if (this.checkAv1) {
            return this.checkAv1;
        } else {
            return this.refreshAv1Support();
        }
    }

    public getAv1SupportSync(): MediaCapabilitiesDecodingInfo | undefined {
        return this.supportsAv1;
    }

    /*
     * TODO: it is unknown what different platforms would return for HEVC check,
     * so leave this to be undefined and fill out details later.
     */
    public refreshH265Support(): Promise<MediaCapabilitiesDecodingInfo | undefined> {
        this.checkH265 = Promise.resolve(undefined);
        return this.checkH265;
    }

    public getH265Capabilities(): Promise<MediaCapabilitiesDecodingInfo | undefined> {
        if (this.supportsH265) {
            return Promise.resolve(this.supportsH265);
        } else if (this.checkH265) {
            return this.checkH265;
        } else {
            return this.refreshH265Support();
        }
    }

    public getH265SupportSync(): MediaCapabilitiesDecodingInfo | undefined {
        return this.supportsH265;
    }

    public getDecodeCapability(
        codec: string,
        width: number = 1920,
        height: number = 1080,
        framerate: number = 60
    ): Promise<MediaCapabilitiesDecodingInfo | undefined> {
        const mediaCapabilities: MediaCapabilities = (navigator as any).mediaCapabilities;
        if (!mediaCapabilities) {
            return Promise.resolve(undefined);
        }

        const config: MediaDecodingConfiguration = {
            type: "webrtc",
            video: {
                contentType: "video/" + codec,
                width: width,
                height: height,
                framerate: framerate,
                bitrate: CalculateMaxBitrateKbps(width, height, framerate) * 1000
            }
        };

        return mediaCapabilities.decodingInfo(config).catch(error => {
            Log.w("{5083445}", "{f5c2155}", codec, error);
            return undefined;
        });
    }

    // Initiates a recalculation of the monitor refresh rate
    public async refresh120FpsSupport(): Promise<boolean> {
        const refreshRate = await this.getRefreshRate(true);
        return this.is120Fps(refreshRate);
    }

    // If monitor refresh rate exists in the cache, it will be used
    public async is120FpsSupported(): Promise<boolean> {
        const refreshRate = await this.getRefreshRate(false);
        return this.is120Fps(refreshRate);
    }

    public getRefreshRate(reCalculate?: boolean): Promise<number> {
        if (reCalculate) {
            // reset the cached value
            this.refreshRate = undefined;
        }
        if (this.refreshRate) {
            return Promise.resolve(this.refreshRate);
        } else if (this.refreshRatePromise) {
            return this.refreshRatePromise;
        } else {
            return this.fetchRefreshRate();
        }
    }

    private is120Fps(refreshRate: number) {
        // The refresh rate might not be exact as the number of RAF callbacks might
        //  vary based on the page load. Hence including an error rate of 3fps
        if (refreshRate >= 117) {
            return true;
        }
        return false;
    }

    private fetchRefreshRate(): Promise<number> {
        // Number of seconds to monitor the RAF callbacks. If the page load is heavy, the measurement is not accurate
        const scanIntervalMs: number = 2000;
        // Timeout can occur if the window is resizing continously. Should be greater than monitorInterval
        const timeoutMs: number = 4000;
        this.refreshRatePromise = this.calcRefreshRate(scanIntervalMs, timeoutMs).then(
            (refreshRate: number) => {
                this.refreshRate = refreshRate;
                return this.refreshRate;
            },
            () => {
                // On error, return the cached value if any
                return this.refreshRate ?? 0;
            }
        );
        return this.refreshRatePromise;
    }

    private calcRefreshRate(scanIntervalMs: number, timeoutMs: number): Promise<number> {
        const promise = new Promise<number>((resolve_, reject_) => {
            let aborted = false;
            const timeOutId = window.setTimeout(() => {
                aborted = true;
            }, timeoutMs);
            const resolve = (response: number) => {
                window.clearTimeout(timeOutId);
                resolve_(response);
            };
            const reject = (x?: any) => {
                Log.w("{5083445}", "{0b0c6f9}", x);
                window.clearTimeout(timeOutId);
                reject_();
            };

            if (scanIntervalMs > timeoutMs) {
                reject("Specified timeout less than the scan interval");
            }
            const times: Array<number> = [];
            let refreshRate;
            // Keep a track of the window dimensions and restart the refreshRate calculation if the
            // window size changes. If the window stays in continuous resize, we should timeout
            let width = window.innerWidth;
            let height = window.innerHeight;
            let screenX = window.screenX;
            let screenY = window.screenY;
            const isWindowPosChanged = () => {
                if (
                    window.innerWidth != width ||
                    window.innerHeight != height ||
                    screenX != window.screenX ||
                    screenY != window.screenY
                ) {
                    width = window.innerWidth;
                    height = window.innerHeight;
                    screenX = window.screenX;
                    screenY = window.screenY;
                    return true;
                }
                return false;
            };
            const refreshRateLoop = () => {
                window.requestAnimationFrame(() => {
                    if (aborted) {
                        reject("Timed out during the refreshRate loop");
                    } else {
                        if (isWindowPosChanged()) {
                            Log.w("{5083445}", "{824b461}");
                            times.length = 0;
                            refreshRateLoop();
                        } else {
                            const now = performance.now();
                            // If we have data over scanIntervalMs
                            if (times.length && times[0] <= now - scanIntervalMs) {
                                refreshRate = Math.floor(times.length / (scanIntervalMs / 1000));
                                Log.d("{5083445}", "{8c3d290}", refreshRate, 
                                        times[times.length - 1] - times[0]
                                    );
                                resolve(refreshRate);
                            } else {
                                times.push(now);
                                refreshRateLoop();
                            }
                        }
                    }
                });
            };
            refreshRateLoop();
        });
        return promise;
    }
}

// Create one instance of device capabilities used by all Ragnarok classes.
export let DeviceCapabilities = new DeviceCapabilitiesImpl();

/** Bitwise configuration to allow Av1 for specific streaming resolutions and frame rates */
const EnableAv1Config = {
    None: 0x0000,
    Allow120Fps: 0x0001,
    Allow1440p: 0x0002,
    Allow4k: 0x0004,
    All: 0x0007
};

async function IsAv1Supported(
    streamInfo: StreamInfo,
    platformDetails: PlatformDetails
): Promise<boolean> {
    const allowAv1 =
        RagnarokSettings.enableAv1Support ??
        RagnarokSettings.ragnarokConfig.enableAv1Support ??
        false;
    const allowAv1ByResolutionAndFps =
        RagnarokSettings.ragnarokConfig.enableAv1ByResolutionAndFps ?? EnableAv1Config.All;

    if (!allowAv1) {
        return false;
    }
    if (
        (IsResolution4k(streamInfo.width, streamInfo.height) &&
            !(allowAv1ByResolutionAndFps & EnableAv1Config.Allow4k)) ||
        (IsResolution1440p(streamInfo.width, streamInfo.height) &&
            !(allowAv1ByResolutionAndFps & EnableAv1Config.Allow1440p)) ||
        (streamInfo.fps === 120 && !(allowAv1ByResolutionAndFps & EnableAv1Config.Allow120Fps))
    ) {
        return false;
    }
    if (IsWebOS(platformDetails) && window["lge_webrtc_av1_support"] != undefined) {
        return window["lge_webrtc_av1_support"];
    }
    const result = await DeviceCapabilities.getAv1Capabilities();
    if (result?.powerEfficient) {
        return true;
    }
    return false;
}

async function IsH265Supported(platformDetails: PlatformDetails): Promise<boolean> {
    if (IsWebOS(platformDetails) && window["lge_webrtc_hevc_support"] != undefined) {
        return window["lge_webrtc_hevc_support"];
    }
    if (IsTizenOS(platformDetails)) {
        return true;
    }
    const result = await DeviceCapabilities.getH265Capabilities();
    if (result?.powerEfficient) {
        return true;
    }
    return false;
}

export declare const enum SdpCodecType {
    H264 = "H264",
    H265 = "H265",
    AV1 = "AV1",
    UNKNOWN = "UNKNOWN"
}

export async function GetCodecList(
    streamInfo: StreamInfo,
    platformDetails: PlatformDetails
): Promise<SdpCodecType[]> {
    let codecs = [];
    const codecList = RagnarokSettings.codecList ?? RagnarokSettings.ragnarokConfig.codecList;

    if (codecList) {
        for (const codec of codecList) {
            codecs.push(<SdpCodecType>codec);
        }
    } else {
        const av1Check = IsAv1Supported(streamInfo, platformDetails);
        const h265Check = IsH265Supported(platformDetails);
        if (await av1Check) {
            codecs.push(SdpCodecType.AV1);
        }
        if (await h265Check) {
            codecs.push(SdpCodecType.H265);
        }
        codecs.push(SdpCodecType.H264);
    }
    return codecs;
}
