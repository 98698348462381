export interface IMouseFilter {
    getX(): number;
    getY(): number;
    update(x: number, y: number, ts: number): boolean;
}

export class NullMouseFilter implements IMouseFilter {
    x: number = 0;
    y: number = 0;

    getX(): number {
        return this.x;
    }

    getY(): number {
        return this.y;
    }

    update(x: number, y: number, _ts: number): boolean {
        this.x = x;
        this.y = y;
        return true;
    }
}

export class MouseFilter implements IMouseFilter {
    lastX: number = 0;
    lastY: number = 0;
    lastTs: number = 0;
    estimatedAccelX: number = 0;
    estimatedAccelY: number = 0;
    ignoredX: number = 0;
    ignoredY: number = 0;
    oldX: number = 0;
    oldY: number = 0;
    consecutiveZero: boolean = false;

    getX(): number {
        return this.lastX;
    }

    getY(): number {
        return this.lastY;
    }

    /// This class has logic to block 3 kinds of spurious jumps (here, positive means in the same direction as actual
    /// mouse moves and negative means in the opposite direction):
    ///   1. Huge movements caused by Chrome resetting the cursor position to the center, which are usually negative but
    ///      but are occasionally positive.
    ///   2. Smaller, negative movements that seem to have the same cause as #1. These always seem to have deltas that
    ///      are a multiple of a real event.
    ///   3. Negative movements with timestamps in the past. These have the side-effect of messing up the value of the
    ///      next event too (except in rare cases).
    update(x: number, y: number, ts: number): boolean {
        if (x === 0 && y === 0) {
            // This isn't a spurious jump, but still needs to be filtered out because it will affect mouse spacing on
            // the server
            if (this.consecutiveZero) {
                // A second consecutive zero will cancel out jump #3
                this.oldX = 0;
                this.oldY = 0;
            } else {
                this.consecutiveZero = true;
            }
            return false;
        }
        this.consecutiveZero = false;

        if (this.oldX === 0 && this.oldY === 0) {
            if (ts < this.lastTs) {
                // This event is jump #3
                this.oldX = x;
                this.oldY = y;
                return false;
            }
        } else {
            // Handles the event after a jump #3
            x += this.oldX;
            y += this.oldY;
            this.oldX = 0;
            this.oldY = 0;
        }

        // We calculate the dot product of this event and last event to determine the relative directions of the two.
        // Recall, the dot product is defined as a.b = a1b1 + a2b2 = cos(theta)mag(a)mag(b). This means the dot product
        // is negative if the vectors face away from each other. Going futher, if we want to check for a certain angle
        // we have a.b > cos(theta)mag(a)mag(b), or if we're working with squared values:
        // (a.b)^2 > cos(theta)^2 mag(a)^2 mag(b)^2
        // We will check if the new event is between 155 and 205 degrees away from the last event.
        const COS_25_DEG_SQUARED = 0.9 * 0.9;

        const dotProduct = x * this.lastX + y * this.lastY;
        const magSquared = x * x + y * y;
        const lastMagSquared = this.lastX * this.lastX + this.lastY * this.lastY;

        let allow = true;
        if (
            ts - this.lastTs < 0.95 &&
            dotProduct < 0 &&
            lastMagSquared !== 0 &&
            dotProduct * dotProduct > COS_25_DEG_SQUARED * magSquared * lastMagSquared
        ) {
            // The negative events always seem to be composed of several real events, so determine how many multiples of
            // the last event go into this one and take the decimal part. We need the actual magnitudes for this.
            const mag = Math.sqrt(magSquared);
            const lastMag = Math.sqrt(lastMagSquared);
            const mult = mag / lastMag;
            let frac = Math.abs(mult - Math.trunc(mult));
            if (frac > 0.5) {
                frac = 1 - frac;
            }
            if (frac < 0.1) {
                allow = false;
            }
        }

        // We calculate a threshold based on how quickly the mouse deltas have changed in the past (acceleration) and
        // use it to determine if the current event fits. We do everything here with "squared" values instead of
        // sqrt'ing
        const MULT_PER_MS = 0.1;
        const MIN_THRESHOLD = 90;
        const ACCEL_COEF = 0.6;

        const accelX = x - this.lastX;
        const accelY = y - this.lastY;
        const accel = accelX * accelX + accelY * accelY;
        if (allow) {
            // Spurious jumps tend to come close to a previous event, so reduce our threshold for closer events
            const timeMult = 1 + MULT_PER_MS * Math.max(1, Math.min(16, ts - this.lastTs));
            const thresholdX = timeMult * 2 * Math.abs(this.estimatedAccelX);
            const thresholdY = timeMult * 2 * Math.abs(this.estimatedAccelY);
            const threshold = Math.max(
                MIN_THRESHOLD * MIN_THRESHOLD,
                thresholdX * thresholdX + thresholdY * thresholdY
            );

            allow = accel < threshold;
            if (!allow && (this.ignoredX || this.ignoredY)) {
                // Even if we ignored the last event, use it to give this event a second chance. This helps
                // if we get a huge, sudden (but real) movement. The first event might get ignored but
                // if we get a second similar one in a row we will allow it.
                const ignoredAccelX = x - this.ignoredX;
                const ignoredAccelY = y - this.ignoredY;
                const ignoredAccel = ignoredAccelX * ignoredAccelX + ignoredAccelY * ignoredAccelY;
                allow = ignoredAccel < threshold;
            }
        }
        if (allow) {
            this.estimatedAccelX = this.estimatedAccelX * (1 - ACCEL_COEF) + accelX * ACCEL_COEF;
            this.estimatedAccelY = this.estimatedAccelY * (1 - ACCEL_COEF) + accelY * ACCEL_COEF;
            this.lastX = x;
            this.lastY = y;
            this.lastTs = ts;
            this.ignoredX = 0;
            this.ignoredY = 0;
            return true;
        } else {
            this.ignoredX = x;
            this.ignoredY = y;
            return false;
        }
    }
}
