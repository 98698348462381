(window as any)["GridServer"] = (window as any)["GridServer"] || {};

/* Typings to be exposed to library user */
export * from "./output/interfaces";
export {
    RemoteBitmapCallback,
    GridServerCallbacks,
    GridServer,
    PassThruServer
} from "./output/gridserver";
export * from "./output/settings";
export * from "./output/gserrorcode";
export * from "./output/tracing";
export {
    BrowserName,
    PlatformName,
    getPlatformDetails,
    PlatformDetails,
    DeviceModel,
    TracingInfo,
    createTracerCallback
} from "./output/dependencies";
export * from "./output/gstelemetryinterfaces";
export * from "./output/gstelemetryhandler";
