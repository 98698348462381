import {
    GsInitParams,
    SessionParams,
    ActiveSessionInfo,
    ActiveSessionResultEvent,
    SessionProgressUpdateEvent,
    SessionState,
    AppLaunchMode,
    GS_EVENTS,
    SessionProgressState,
    ServerInfo,
    GridSession,
    ServerError,
    SESSIONMODIFY_ACTION,
    Usage,
    AppLevelProtocol,
    ConnectionInfo,
    MonitorSettings,
    SdrHdrMode,
    TelemetryEventIds
} from "./interfaces";
import {
    AuthType,
    AuthInfo,
    authTokenCallbackType,
    UtilsErrorCode,
    DefaultHttpRequestOptions,
    ErrorDetails,
    performHttpRequest,
    RequestHttpOptions,
    Response,
    GetHexString,
    HttpRequestHeaders,
    getNewGuid,
    UTIL_EVENTS,
    PendingRequest,
    isUnifiedErrorCode,
    LogQueueingEventEmitter,
    TracingCarrier,
    TracingInfo,
    PlatformDetails,
    IsChromeBrowser,
    IsChromeVersionAtLeast,
    PackageVersion,
    IEventEmitter
} from "./dependencies";
import { GridServerSettings } from "./settings";
import { GsErrorCode } from "./gserrorcode";
import { Log } from "./logger";
import { TracingComponent, TracingOperation, Scope } from "./tracing";
import { GsTelemetryHandler } from "./gstelemetryhandler";
import { GridServer_GameLaunch_Request, NetworkTypeEnum } from "./gstelemetryinterfaces";

const LOGTAG = "gridserver";

type ServerInfoMap = Map<string, ServerInfo>;

interface PollingOptions {
    minInterval: number;
    maxInterval: number;
    step: number;
    queueSizePerStep: number;
}

// https://docs.google.com/document/d/1MGe199idCH1jbFns0NrIBVayJTqE2QxOS69uFBVQKyI/edit?usp=sharing
function addClientHeaders(httpHeaders: HttpRequestHeaders, initParams: GsInitParams) {
    httpHeaders["content-type"] = "application/json";
    if (initParams.deviceOs !== undefined) {
        httpHeaders["nv-device-os"] = initParams.deviceOs;
    }
    if (initParams.deviceType !== undefined) {
        httpHeaders["nv-device-type"] = initParams.deviceType;
    }
    if (initParams.deviceMake !== undefined) {
        httpHeaders["nv-device-make"] = initParams.deviceMake;
    }
    if (initParams.deviceModel !== undefined) {
        httpHeaders["nv-device-model"] = initParams.deviceModel;
    }
    if (initParams.clientType !== undefined) {
        httpHeaders["nv-client-type"] = initParams.clientType;
    }
    if (initParams.clientAppVersion !== undefined) {
        httpHeaders["nv-client-version"] = initParams.clientAppVersion;
    }
    if (initParams.clientStreamer !== undefined) {
        httpHeaders["nv-client-streamer"] = initParams.clientStreamer;
    }
    if (initParams.clientId !== undefined) {
        httpHeaders["nv-client-id"] = initParams.clientId;
    }
    if (initParams.ecomEnabled !== undefined) {
        httpHeaders["nv-ecom-enabled"] = initParams.ecomEnabled;
    }
    if (initParams.acceptLanguage !== undefined) {
        httpHeaders["accept-language"] = initParams.acceptLanguage;
    }
    if (initParams.deviceHashId !== undefined) {
        httpHeaders["x-device-id"] = initParams.deviceHashId;
    }
    if (initParams.browserType !== undefined) {
        httpHeaders["nv-browser-type"] = initParams.browserType;
    }

    if (initParams.clientHeaders) {
        for (const [key, value] of initParams.clientHeaders) {
            // Ignore deprecated header
            if (key.toLowerCase() === "x-nv-client-identity") {
                continue;
            }
            httpHeaders[key.toLowerCase()] = value;
        }
    }
}

/**
 * Replace error code with unified error code for status codes ServerInternalError
 * and InvalidServiceResponse returned by PM during session setup phase.
 **/
function setUnifiedErrorCode(error: ServerError, unifiedErrorCode: number) {
    if (
        error.code === GsErrorCode.ServerInternalError ||
        error.code === GsErrorCode.InvalidServiceReponse
    ) {
        if (isUnifiedErrorCode(unifiedErrorCode)) {
            error.code = unifiedErrorCode;
        }
    }
}

export type RemoteBitmapCallback = () => number;

/**
 * An optional object to use when creating a real GridServer, containing callback functions
 * for setting certain per-connection state values.
 */
export declare interface GridServerCallbacks {
    remoteBitmapCallback: RemoteBitmapCallback;
}

/**
 * The interface defines the protocol to connect to PM.
 *
 * GridServer is the proxy class which implements the protocol and provides the actual ability to communicate with PM for session setup and teardown.
 * And it could be instantiated as: const gridServer: GridServer = new GridServer(platformDetails, emitEventsSynchronously);
 *
 * Client needs to register listners for GS_EVENTS as described in interface.ts file for the events it emits.
 *
 * Sample Usage:
 * const gridServer = new GridServer(platformDetails, emitEventsSynchronously);
 * gridServer.initialize({
 *     deviceOs: "WINDOWS",
 *     deviceOsVer: "11.0.22000.0",
 *     deviceType: "DESKTOP",
 *     clientIdentification: "GFN-PC",
 *     clientVersion: "24.0",
 *     clientAppVersion: "2.0.50.104",
 *     clientStreamer: "WEBRTC",
 *     browserType: "CHROME",
 *     deviceHashId: "1234567890",
 *     serverAddress: "server-address.nvidiagrid.net",
 *     autoTokenCallback: <callback function>,
 *     clientHeaders: {
 *         "nv-browser-version": "123.0.1234.12"
 *     },
 *     ...
 * });
 * gridServer.setAuthInfo(AuthInfo.JARVIS, "some token");
 *
 * gridServer.addListener(GS_EVENTS.ACTIVE_SESSIONS_RESULT, (eventData: ActiveSessionResultEvent) => {});
 * gridServer.addListener(GS_EVENTS.PROGRESS_UPDATE, (eventData: SessionProgressUpdateEvent) => {});
 * gridServer.addListener(GS_EVENTS.GET_SESSION_RESULT, (eventData: GetSessionResult) => {});
 *
 * gridServer.putOrPostSession({
 *     streamParams: [...],
 *     appId: 100000000,
 *     shortName: "Fortnite (Epic)",
 *     ...
 * }, SESSIONMODIFY_ACTION.UNKNOWN); // Start a session
 * gridServer.getSession(some_session_id, true); // Queue progress detail will be emitted through the GS_EVENTS.PROGRESS_UPDATE event
 * gridServer.getAllActiveSessions(); // Retrieve current session of the user, session detail will be emitted through the GS_EVENTS.GET_SESSION_RESULT
 * gridServer.sendDeleteRequest(some_session_id); // Delete the existing session
 *
 * gridServer.uninitialize(); // Release all resources
 */
interface IGridServer extends IEventEmitter {
    /**
     * Initialize the grid server.
     * NOTE: should be called before any network operations:
     *         - getAllActiveSessions
     *         - putOrPostSession
     *         - getSession
     *         - sendDeleteRequest
     * @param initParams - interface: GsInitParams
     */
    initialize(initParams: GsInitParams): void;

    /**
     * This method should be called before recreating gridserver object
     **/
    uninitialize(): void;

    /**
     * Update telemetry event ids. Without calling the function results in
     * telemetry ID field to be blank.
     * NOTE: should be called before putOrPostSession.
     * @param telemetryEventIds - interface: TelemetryEventIds
     */
    updateTelemetryEventIds(telemetryEventIds: TelemetryEventIds): void;

    /**
     * Gets the current active sessions for the user.
     * This is an asynchronous API, upon completion ACTIVE_SESSIONS_RESULT and TELEMETRY_HTTP_EVENT are emitted for results.
     * @param tracingCarrier - carrier representing parent tracing context of getAllActiveSessions operation. Adheres to OpenTracing standard.
     **/
    getAllActiveSessions(tracingCarrier?: TracingCarrier): void;

    /**
     * This method returns the current sessionId.
     * NOTE: the session id is set/reset on putOrPostSession calls and will be empty until one is executed.
     * Ideally the session ID is the current one, but it will not be expired if the current session expires.
     **/
    getSessionId(): string;

    /**
     * This method returns the current subSessionId.
     * NOTE: the subsession ID is set/reset on putOrPostSession calls and will be empty until one is executed.
     *       Ideally the subsession ID is the current one, but it will not be expired if the current subsession expires.
     **/
    getSubSessionId(): string;

    /**
     * This method can be used to resume (PUT) or start (POST) a session.
     * Result is returned in promise with GridSession object.
     * @param startParams - object of SessionParams object.
     * @param action - value from SESSIONMODIFY_ACTION for resume or starting session.
     * @param sessionId - sessionId in case of resume
     * @param tracingCarrier - carrier representing parent tracing context of putOrPostSession operation. Adheres to OpenTracing standard.
     **/
    putOrPostSession(
        startParams: SessionParams,
        action: SESSIONMODIFY_ACTION,
        sessionId: string | undefined,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined>;

    /**
     * This method can be used to get a session details belonging to a session id for resume scenario or during polling.
     * Pass isPoll flag true when post request is made for session setup.
     * Result is returned in promise with GridSession object.
     * @param sessionId - sessionId in case of resume.
     * @param isPoll - used in post request.
     * @param tracingCarrier - carrier representing parent tracing context of getSession operation. Adheres to OpenTracing standard.
     **/
    getSession(
        _sessionId: string,
        isPoll: boolean,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined>;

    /**
     * This method can be used to send a delete request for a given session id.
     * Pass isPoll flag true when post request is made for session setup.
     * Result is returned in promise of type void.
     * @param sessionId - sessionId to delete
     * @param tracingCarrier - Tracing information of parent operation
     * @note Will not cancel active operations.
     * @note If putOrPostSession or getSession is in progress, should first call cancelSessionSetup
     **/
    sendDeleteRequest(sessionId: string, tracingCarrier?: TracingCarrier): Promise<void>;

    /**
     * Set the client's authorization information.
     * NOTE: initialize must be called before using this API.
     *       Should be called before the first use of APIs that require authentication with the server.
     *         - getAllActiveSessions
     *         - putOrPostSession
     *         - getSession
     *         - sendDeleteRequest
     *       If not called, Jarvis authentication will be assumed.
     *       In the event of authentication related errors, can be called again to update the existing authentication information.
     * @param authInfo - object
                     { type: enum<auth method for server communication>
                       token: string<token to authenticate server communication>}
     **/
    setAuthInfo(authInfo: AuthInfo): void;

    /**
     * This method can be used to cancel in progress session setup.
     * Should be called only after putOrPostSession or getSession with isPoll = true, before they resolve.
     * Ongoing APIs will throw with either SessionSetupCancelled or SessionSetupCancelledDuringQueuing.
     * @note Will not send DELETE request for the session.
     * @note Should call sendDeleteRequest following putOrPostSession or getSession resolution if the session should be deleted.
     **/
    cancelSessionSetup(): void;

    /**
     * Get zone name.
     * NOTE: will be empty until getSession or putOrPostSession gets called.
     */
    getZoneName(): string;

    /**
     * Get zone address.
     * NOTE: will be empty until getSession or putOrPostSession gets called.
     */
    getZoneAddress(): string;

    /**
     * Get GPU type.
     * NOTE: will be empty until getSession or putOrPostSession gets called.
     */
    getGpuType(): string;

    /**
     * Update telemetry network status. Default: NetworkTypeEnum.UNKNOWN
     * @param network - interface: NetworkTypeEnum
     */
    setNetworkType(network: NetworkTypeEnum): void;
}

export class GridServer extends LogQueueingEventEmitter implements IGridServer {
    private sessionControlServerMap: ServerInfoMap;
    private httpRequestOptions: RequestHttpOptions;
    protected authRequestFunc: authTokenCallbackType | undefined | null;
    protected initParams: GsInitParams | null;
    protected protocol: string;
    protected overrideSignallingInfo: boolean;
    protected subSessionId: string = "";
    private queuePosition: number;
    private pollingOptions: PollingOptions;
    private authInfo: AuthInfo;
    private cancelledSetup: boolean;
    private zoneName?: string;
    private zoneAddress?: string;
    private pollingLogged: boolean = false;
    private subSessionsIdMap: Map<string, string> = new Map();
    private logHandler: Function = this.onLogEvent.bind(this);
    private putOrPostInProgress: boolean = false;
    private putOrPostRequest?: PendingRequest;
    private getSessionRequest?: PendingRequest;
    private tracingInfo?: TracingInfo;
    private gpuType?: string;
    private gsTelemetryHandler: GsTelemetryHandler;
    private clientLocale?: string;
    private launchRequestInfoMap: Map<String, GridServer_GameLaunch_Request> = new Map();
    private platformDetails?: PlatformDetails;
    private gamepadBitmapCallback?: RemoteBitmapCallback;

    constructor(
        platformDetails?: PlatformDetails,
        emitEventsSynchronously?: boolean,
        callbacks?: GridServerCallbacks
    ) {
        super(GS_EVENTS.LOG_EVENT, emitEventsSynchronously);

        // When run from GridApp, Log will already have had a listener attached, so don't
        // add a second one (it causes duplicate logging).
        // When run from the client SDK, Log will not yet have a listener, so add one
        // here.
        if (!Log.hasListener(UTIL_EVENTS.LOG_EVENT)) {
            Log.addListener(UTIL_EVENTS.LOG_EVENT, this.logHandler);
        }
        this.platformDetails = platformDetails;
        this.httpRequestOptions = DefaultHttpRequestOptions;
        let remoteConfigTimeout = 0; // in seconds
        if (GridServerSettings.commonConfig.pmCommunication?.httpConnectionTimeout !== undefined) {
            remoteConfigTimeout +=
                GridServerSettings.commonConfig.pmCommunication.httpConnectionTimeout;
        }
        if (GridServerSettings.commonConfig.pmCommunication?.httpDataReceiveTimeout !== undefined) {
            remoteConfigTimeout +=
                GridServerSettings.commonConfig.pmCommunication.httpDataReceiveTimeout;
        }
        this.httpRequestOptions.timeout = remoteConfigTimeout ? remoteConfigTimeout * 1000 : 13000;
        this.httpRequestOptions.retryCount =
            GridServerSettings.commonConfig.pmCommunication?.httpRetryCount ?? 3;
        this.httpRequestOptions.backOffDelay =
            GridServerSettings.commonConfig.pmCommunication?.httpBackOffDelay ?? 500;
        this.authRequestFunc = null; // @todo figure this out.
        this.sessionControlServerMap = new Map();
        this.initParams = null;
        this.protocol = "";
        this.overrideSignallingInfo = true;
        if (window.location.protocol === "http:") {
            this.overrideSignallingInfo = false;
        }
        this.queuePosition = Number.MAX_VALUE;
        this.pollingOptions = {
            minInterval:
                GridServerSettings.commonConfig.pmCommunication?.pollingIntervalMin ?? 1000,
            maxInterval:
                GridServerSettings.commonConfig.pmCommunication?.pollingIntervalMax ?? 10000,
            step: GridServerSettings.commonConfig.pmCommunication?.pollingIntervalStep ?? 1000,
            queueSizePerStep:
                GridServerSettings.commonConfig.pmCommunication?.pollingQueueSizePerStep ?? 50
        };
        this.authInfo = { type: AuthType.JARVIS };
        this.cancelledSetup = false;
        this.gsTelemetryHandler = new GsTelemetryHandler(this);

        this.gamepadBitmapCallback = callbacks?.remoteBitmapCallback;
    }

    public initialize(initParams: GsInitParams) {
        Log.i("{6bae601}", "{6802390}", PackageVersion);
        this.initParams = initParams;
        addClientHeaders(this.httpRequestOptions.headers, this.initParams);
        this.authRequestFunc = initParams.authTokenCallback;
        this.tracingInfo = initParams.tracingInfo;
        this.protocol = "https://";
        this.gsTelemetryHandler.resetCache();
        Log.d("{6bae601}", "{5af3344}");
    }

    /**
     * This method should be called before recreating gridserver object
     **/
    public uninitialize() {
        Log.removeListener(UTIL_EVENTS.LOG_EVENT, this.logHandler);
        this.removeAllListeners();
    }

    public updateTelemetryEventIds(telemetryEventIds: TelemetryEventIds) {
        this.gsTelemetryHandler.updateTelemetryEventIds(telemetryEventIds);
    }

    static convertSessionStateToString(status: number) {
        switch (status) {
            case 1:
                return SessionState.INITIALIZING;
            case 2:
                return SessionState.READY_FOR_CONNECTION;
            case 3:
                return SessionState.STREAMING;
            case 4:
            case 5:
                return SessionState.PAUSED;
            case 6:
                return SessionState.RESUMING;
            case 7:
                return SessionState.FINISHED;
            default:
                return SessionState.UNKNOWN;
        }
    }

    //@todo Better way is to have generated defs from json schema instead of any
    private extractSessionInformation(jsonSession: any): GridSession {
        let gridSession: GridSession = {
            sessionId: jsonSession["sessionId"],
            subSessionId:
                jsonSession["sessionId"] === this.getSessionId() ? this.getSubSessionId() : "",
            signalConnectionInfo: { ip: "", port: 0, protocol: "" },
            mediaConnectionInfo: [],
            streamInfo: [],
            appId: 0,
            state: GridServer.convertSessionStateToString(jsonSession["status"]),
            appLaunchMode: AppLaunchMode.Default,
            zoneName: this.getZoneName(),
            zoneAddress: this.getZoneAddress(),
            gpuType: this.getGpuType(),
            clientLocale: this.clientLocale ?? ""
        };
        gridSession.appId = jsonSession["sessionRequestData"]
            ? jsonSession["sessionRequestData"]["appId"]
            : 0;

        if (jsonSession["connectionInfo"]) {
            gridSession.mediaConnectionInfo = jsonSession["connectionInfo"] as ConnectionInfo[];
            for (let i = 0; i < gridSession.mediaConnectionInfo.length; i++) {
                let jconnInfo = gridSession.mediaConnectionInfo[i];
                if (jconnInfo.usage == Usage.SIGNALING && jconnInfo.ip) {
                    //backward compatibility with MockPM, to be removed in follow up
                    gridSession.signalConnectionInfo.ip = jconnInfo.ip;
                    gridSession.signalConnectionInfo.port = jconnInfo.port;
                    gridSession.signalConnectionInfo.protocol =
                        jconnInfo.appLevelProtocol === AppLevelProtocol.HTTPS ? "https" : "http";
                    // Until PM is updated to return the https port of reverse proxy, hard code the signal connection info.
                    // Changes to support https are deployed in ZIAR but PM change to return correct port is not ready.
                    // Hence this will work without the need for PM change right now.
                    // In each zone there can be multiple Reverse proxy and we should connect to the one where https port mapping to
                    // gameseat is set. Each of ReverseProxy within a zone will have seperate zone.
                    if (
                        jconnInfo.appLevelProtocol != AppLevelProtocol.HTTPS &&
                        this.overrideSignallingInfo
                    ) {
                        //override http to https
                        gridSession.signalConnectionInfo.port = 49100;
                        gridSession.signalConnectionInfo.protocol = "https";
                        if (jconnInfo.ip.includes(".com") || jconnInfo.ip.includes(".net")) {
                            gridSession.signalConnectionInfo.ip = jconnInfo.ip;
                        } else {
                            let addressParts = jconnInfo.ip.split(".");
                            gridSession.signalConnectionInfo.ip =
                                addressParts[0] +
                                "-" +
                                addressParts[1] +
                                "-" +
                                addressParts[2] +
                                "-" +
                                addressParts[3];
                            let domainUrlIndex =
                                jsonSession["sessionControlInfo"]["ip"].indexOf(".");
                            gridSession.signalConnectionInfo.ip +=
                                jsonSession["sessionControlInfo"]["ip"].substring(domainUrlIndex);
                            Log.d("{6bae601}", "{7d16374}", gridSession.signalConnectionInfo.ip);
                        }
                    }
                } else if (jconnInfo.usage == Usage.VIDEO) {
                    gridSession.signalConnectionInfo.port = 49100;
                    gridSession.signalConnectionInfo.protocol = "https";
                    let addressParts = jconnInfo.ip.split(".");
                    gridSession.signalConnectionInfo.ip =
                        addressParts[0] +
                        "-" +
                        addressParts[1] +
                        "-" +
                        addressParts[2] +
                        "-" +
                        addressParts[3];
                    let domainUrlIndex = jsonSession["sessionControlInfo"]["ip"].indexOf(".");
                    gridSession.signalConnectionInfo.ip +=
                        jsonSession["sessionControlInfo"]["ip"].substring(domainUrlIndex);
                    Log.d("{6bae601}", "{cf7d7ea}", gridSession.signalConnectionInfo.ip);
                } else {
                    continue;
                }
            }
        }

        if (jsonSession["monitorSettings"]) {
            let monitorSettings: MonitorSettings[] = jsonSession[
                "monitorSettings"
            ] as MonitorSettings[];
            for (const monitor of monitorSettings) {
                gridSession.streamInfo.push({
                    width: monitor.widthInPixels,
                    height: monitor.heightInPixels,
                    fps: monitor.framesPerSecond,
                    sdrHdrMode: monitor.sdrHdrMode
                });
            }
        }

        if (jsonSession["gpuType"]) {
            gridSession.gpuType = jsonSession["gpuType"];
        }

        if (jsonSession["sessionRequestData"]) {
            switch (jsonSession["sessionRequestData"]["appLaunchMode"]) {
                case 3:
                    gridSession.appLaunchMode = AppLaunchMode.TouchFriendly;
                    break;
                case 2:
                    gridSession.appLaunchMode = AppLaunchMode.GamepadFriendly;
                    break;
            }
        }

        if (jsonSession["sessionControlInfo"]) {
            gridSession.zoneAddress = jsonSession["sessionControlInfo"]["ip"];
            gridSession.zoneName = jsonSession["sessionControlInfo"]["ip"]
                .split(".")[0]
                .toUpperCase();
        }

        return gridSession;
    }

    private extractSessionList(jsonSessions: any): ActiveSessionInfo[] {
        let sessionList: ActiveSessionInfo[] = [];
        jsonSessions.forEach((item: any) => {
            let gridSession: GridSession = this.extractSessionInformation(item);
            let activeSession: ActiveSessionInfo = {
                sessionId: gridSession.sessionId,
                appId: gridSession.appId,
                state: gridSession.state,
                appLaunchMode: gridSession.appLaunchMode
            };
            sessionList.push(activeSession);
            if (item.sessionControlInfo) {
                this.sessionControlServerMap.set(item.sessionId, {
                    "server": item.sessionControlInfo.ip,
                    "port": item.sessionControlInfo.port
                });
            }
        });
        return sessionList;
    }

    private getGsErrorCode(code: number): number {
        let gsCode = GsErrorCode.DOMExceptionInGridServer; // https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names
        if (code === UtilsErrorCode.NoNetwork) {
            gsCode = GsErrorCode.NoInternetDuringSessionSetup;
        } else if (code === UtilsErrorCode.NetworkError) {
            gsCode = GsErrorCode.NetworkError;
        } else if (code === UtilsErrorCode.AuthTokenNotUpdated) {
            gsCode = GsErrorCode.AuthTokenNotUpdated;
        } else if (code === UtilsErrorCode.ResponseParseFailure) {
            gsCode = GsErrorCode.ResponseParseFailure;
        } else if (code === GsErrorCode.GetActiveSessionServerError) {
            // We throw this gsError from getSessions and hence need this
            gsCode = GsErrorCode.GetActiveSessionServerError;
        }
        return gsCode;
    }

    private isParseError(err: any): boolean {
        let result = false;
        if (
            err instanceof SyntaxError ||
            (err instanceof TypeError &&
                err.message &&
                err.message.includes("Cannot read property"))
        ) {
            result = true;
            Log.e("{6bae601}", "{d8979bb}", err.name, err.message);
        }
        return result;
    }

    /**
     * This method can be used to get all active sessions for current logged in user.
     * Expect ACTIVE_SESSIONS_RESULT and TELEMETRY_HTTP_EVENT event for results.
     **/
    public getAllActiveSessions(tracingCarrier?: TracingCarrier): void {
        Log.d("{6bae601}", "{1aef200}");
        const scope = this.createAndTagServerOperationScope(
            TracingOperation.GetSessionList,
            tracingCarrier
        );
        if (this.initParams == null) {
            let result: ActiveSessionResultEvent = {
                sessionList: [],
                error: {
                    code: GsErrorCode.GridServerNotInitialized,
                    description: "Gridserver not initialized"
                }
            };
            this.emit(GS_EVENTS.ACTIVE_SESSIONS_RESULT, result);
            this.addErrorTags(result.error!, scope);
            scope?.finish();
            return;
        }
        let url = this.protocol + this.initParams.serverAddress + "/v2/session";

        const httpAnalyticsEvent = this.gsTelemetryHandler.getTelemetryHttpEvent(url, "GET");

        let startTime = performance.now();
        let endTime;
        performHttpRequest(url, this.httpRequestOptions, this.authInfo, this.tracingInfo)
            .then((response: Response) => {
                endTime = performance.now();
                Log.d("{6bae601}", "{081857f}");
                Log.d("{6bae601}", "{8996b21}", response.status);
                Log.d("{6bae601}", "{6246dc6}", Log.sanitize(response.data));
                if (response.status !== undefined) {
                    httpAnalyticsEvent.statusCode = String(response.status);
                }

                let responseJSON: any;
                if (response.data !== undefined) {
                    responseJSON = JSON.parse(response.data);
                    if (responseJSON["requestStatus"]) {
                        this.addRequestStatusTags(responseJSON["requestStatus"], scope);
                        httpAnalyticsEvent.requestId = responseJSON["requestStatus"]["requestId"];
                        httpAnalyticsEvent.serverId = responseJSON["requestStatus"]["serverId"];
                        httpAnalyticsEvent.requestStatusCode = String(
                            responseJSON["requestStatus"]["statusDescription"]
                        );
                    }
                }
                httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                if (response.status == 200) {
                    this.addResultSuccessTag(scope);
                    if (responseJSON["sessions"]) {
                        let result: ActiveSessionResultEvent = {
                            sessionList: this.extractSessionList(responseJSON["sessions"])
                        };

                        this.emit(GS_EVENTS.ACTIVE_SESSIONS_RESULT, result);
                    }
                } else {
                    throw {
                        code: GsErrorCode.GetActiveSessionServerError,
                        description: "httperror"
                    };
                }
            })
            .catch(err => {
                let gsError: ErrorDetails = {
                    code: GsErrorCode.InvalidServerResponse,
                    description: err.message
                };
                if (this.isParseError(err)) {
                    gsError.code = GsErrorCode.ResponseParseFailure;
                    gsError.description = "invalid response";
                } else if (err.code) {
                    Log.e("{6bae601}", "{9d2bb75}", GetHexString(err.code));
                    gsError.code = this.getGsErrorCode(err.code);
                    if (gsError.code === GsErrorCode.NetworkError) {
                        this.gsTelemetryHandler.emitDebugEvent(
                            "ActiveSessionFail",
                            url,
                            "retriesFailed"
                        );
                    }
                } else {
                    this.gsTelemetryHandler.emitDebugEvent(
                        "ActiveSessionFail",
                        url,
                        err.name,
                        err.message
                    );
                    const msg = "ActiveSessions invalid server response";
                    Log.e("{6bae601}", "{f0ba3e3}");
                    gsError.description = gsError.description ?? msg;
                }
                endTime = performance.now();
                httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                Log.e("{6bae601}", "{f0c24fe}");
                let result: ActiveSessionResultEvent = {
                    sessionList: [],
                    error: gsError
                };
                this.emit(GS_EVENTS.ACTIVE_SESSIONS_RESULT, result);
                this.addErrorTags(err, scope);
            })
            .finally(() => {
                scope?.finish();
            });
        Log.d("{6bae601}", "{a9e5439}");
    }

    private getSessionRequestData(startParams: SessionParams, initParams: GsInitParams) {
        let appLaunchMode = 1; // Default
        switch (startParams.appLaunchMode) {
            case AppLaunchMode.TouchFriendly:
                Log.d("{6bae601}", "{6d5cf87}");
                appLaunchMode = 3;
                break;
            case AppLaunchMode.GamepadFriendly:
                appLaunchMode = 2;
                break;
            default:
                appLaunchMode = 1;
        }

        let sdrHdrMode = startParams.monitorSettings?.[0]?.sdrHdrMode ?? SdrHdrMode.SDR;

        let monitorSettings = [];
        if (startParams.monitorSettings) {
            monitorSettings = startParams.monitorSettings;
        } else {
            for (const stream of startParams.streamParams!) {
                monitorSettings.push({
                    "heightInPixels": stream.height,
                    "framesPerSecond": stream.fps,
                    "widthInPixels": stream.width
                });
            }
        }

        if (GridServerSettings.hdr) {
            sdrHdrMode = SdrHdrMode.HDR;
            for (const monitorSetting of monitorSettings) {
                monitorSetting.sdrHdrMode = SdrHdrMode.HDR;
            }
        }

        const gamepadBitmap: number = this.gamepadBitmapCallback?.() ?? 0;
        let srd: any = {
            // Ideally all these values need not be sent to server, but our PM considers missing data as invalid.
            "audioMode": 2,
            "remoteControllersBitmap": startParams.remoteControllersBitmap ?? gamepadBitmap,
            "sdrHdrMode": sdrHdrMode,
            "networkTestSessionId": null,
            "availableSupportedControllers": [],
            "clientVersion": initParams.clientVersion,
            "deviceHashId": initParams.deviceHashId,
            "internalTitle": null,
            "clientPlatformName": initParams.clientPlatformName, // long term plan is to get rid of this field
            "metaData": [
                { "key": "isAppLaunchEnabled", "value": "True" },
                { "key": "SubSessionId", "value": this.getSubSessionId() },
                {
                    "key": "wssignaling",
                    "value": GridServerSettings.webSocketSignaling ? "1" : "0"
                }
            ],
            "surroundAudioInfo": 0,
            "clientTimezoneOffset":
                startParams.clientTimeZoneOffset ?? new Date().getTimezoneOffset() * 60 * 1000 * -1,
            "clientIdentification": initParams.clientIdentification,
            "parentSessionId": null,
            "appId": startParams.appId,
            "streamerVersion": 1,
            "clientRequestMonitorSettings": monitorSettings,
            "appLaunchMode": appLaunchMode,
            "sdkVersion": "1.0",
            "enchancedStreamMode": 1,
            "useOps": true,
            "clientDisplayHdrCapabilities": null,
            "accountLinked": startParams.accountLinked ?? false,
            "partnerCustomData": startParams.partnerCustomData ?? "",
            "enablePersistingInGameSettings": startParams.enablePersistingInGameSettings ?? false,
            "secureRTSPSupported": false
        };
        if (startParams.requestedAudioFormat !== undefined) {
            srd["requestedAudioFormat"] = startParams.requestedAudioFormat;
        }
        if (GridServerSettings.webRtcStreamer || initParams.clientPlatformName === "browser") {
            srd["metaData"].push({ "key": "GSStreamerType", "value": "WebRTC" });
        } else {
            // For non WebRTC client currently client must inform server of secureRTSP support. The "wssignaling" : "1" in metadata informs the server to be ready for websocket connection but without secureRTSPSupported, the connection doesnt work.
            // The above combination is broken, however we dont want any clients to perfom non secure request to our server so hard code secureRTSPSupported to true.
            // WebRTC client hardcodes the Signaling port to 49100(secure) in GridServer. PM currently sends all the port for native client, this will be cleaned up in seperate initiative soon.
            // if secureRTSPSupported is set to true in webclient, PM will provide one more port to client which is not needed, hence restrict this to only non web client.
            srd["secureRTSPSupported"] = true;
        }

        for (const key in startParams.metaData) {
            let x: any = {
                key: key,
                value: startParams.metaData[key]
            };
            srd.metaData.push(x);
        }

        return srd;
    }

    private getActionString(action: number): string {
        let actionStr = "";
        switch (action) {
            case SESSIONMODIFY_ACTION.RESUME:
                actionStr = "RESUME";
                break;
            case SESSIONMODIFY_ACTION.PAUSE:
                actionStr = "PAUSE";
                break;
        }
        return actionStr;
    }

    private getCancelError(seatSetUpStep: number): ErrorDetails {
        Log.e("{6bae601}", "{7dc9e2c}");
        let err = {
            code: GsErrorCode.SessionSetupCancelled,
            description: "cancelled session setup"
        };
        if (seatSetUpStep == 1) {
            err.code = GsErrorCode.SessionSetupCancelledDuringQueuing;
        }
        return err;
    }

    protected resetSubSessionId(subSessionId: string, sessionId: string = "") {
        this.subSessionId = subSessionId;
        this.gsTelemetryHandler.setSessionId(sessionId);
        this.gsTelemetryHandler.setSubSessionId(subSessionId);
        const value = this.subSessionsIdMap.get(subSessionId);
        if (value === undefined || value === "") {
            this.subSessionsIdMap.set(subSessionId, sessionId);
        } else {
            Log.w("{6bae601}", "{7dd264b}", sessionId);
        }
    }

    /**
     * This method returns the current sessionId.
     **/
    public getSessionId(): string {
        return this.subSessionsIdMap.get(this.subSessionId) ?? "";
    }

    /**
     * This method returns the current subSessionId.
     **/
    public getSubSessionId(): string {
        return this.subSessionId;
    }

    /**
     * This method can be used to resume (PUT) or start (POST) a session.
     * Result is returned in promise with GridSession object.
     * @param startParams - object of SessionParams object.
     * @param action - value from SESSIONMODIFY_ACTION for resume or starting session.
     * @param sessionId - sessionId in case of resume
     **/
    public putOrPostSession(
        startParams: SessionParams,
        action: SESSIONMODIFY_ACTION,
        sessionId: string | undefined,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined> {
        const scope = this.createAndTagServerOperationScope(
            sessionId ? TracingOperation.PutSession : TracingOperation.PostSession,
            tracingCarrier
        );
        this.resetSubSessionId(getNewGuid(), sessionId);
        if (this.initParams == null) {
            const err = {
                code: GsErrorCode.GridServerNotInitialized,
                description: "Gridserver not initialized"
            };
            return this.finishScopeAndReject(err, scope);
        }
        if (this.putOrPostInProgress) {
            const err = {
                code: GsErrorCode.PutOrPostInProgress,
                description: "Previous PutOrPost call is still in progress"
            };
            return this.finishScopeAndReject(err, scope);
        }
        this.putOrPostInProgress = true;

        this.gsTelemetryHandler.setCmsId(startParams.appId);

        this.cancelledSetup = false;

        let srdObj = this.getSessionRequestData(startParams, this.initParams);

        const launchRequestInfo = this.gsTelemetryHandler.getGameLaunchRequestEvent(
            this.initParams.serverAddress,
            startParams.networkSessionId,
            sessionId
        );

        let sessionRequestObj = { "sessionRequestData": srdObj };

        let body: string;
        if (sessionId) {
            //PUT request should have sessionId
            const metaData: any[] = [];
            let sessionModifyObj = {
                "action": action,
                "data": this.getActionString(action),
                "sessionRequestData": srdObj,
                "metaData": metaData
            };
            body = JSON.stringify(sessionModifyObj);
        } else {
            //it's a POST
            body = JSON.stringify(sessionRequestObj);
        }

        let options: RequestHttpOptions = {
            method: sessionId ? "PUT" : "POST",
            headers: this.httpRequestOptions.headers,
            body: body,
            retryCount: this.httpRequestOptions.retryCount,
            timeout: this.httpRequestOptions.timeout
        };
        Log.i("{6bae601}", "{c6c3ed4}", (sessionId ? "PUT" : "POST"), Log.sanitize(options.body));

        let url = this.protocol + this.initParams.serverAddress + "/v2/session";

        if (sessionId) {
            url = this.getUrlForSession(sessionId);
            this.resetSubSessionId(this.getSubSessionId(), sessionId);
        }

        if (action !== SESSIONMODIFY_ACTION.PAUSE) {
            this.clientLocale = startParams.clientLocale ?? "en_US";
            url += "?keyboardLayout=" + (startParams.keyboardLayout ?? "en_US");
            url += "&languageCode=" + this.clientLocale;
        }

        return new Promise<GridSession | undefined>((resolve, reject) => {
            const requestType = sessionId ? "PUT" : "POST";
            const httpAnalyticsEvent = this.gsTelemetryHandler.getTelemetryHttpEvent(
                url,
                requestType,
                sessionId
            );
            let startTime = performance.now();
            let endTime;
            this.putOrPostRequest = performHttpRequest(
                url,
                options,
                this.authInfo,
                this.tracingInfo
            );
            this.putOrPostRequest
                .then((response: Response) => {
                    this.putOrPostRequest = undefined;
                    Log.d("{6bae601}", "{045b823}", requestType);
                    Log.d("{6bae601}", "{8996b21}", response.status);
                    Log.d("{6bae601}", "{6246dc6}", Log.sanitize(response.data));
                    endTime = performance.now();
                    if (response.status !== undefined) {
                        httpAnalyticsEvent.statusCode = String(response.status);
                    }
                    let responseJSON: any;
                    if (response.data !== undefined) {
                        responseJSON = JSON.parse(response.data);
                        if (responseJSON["requestStatus"]) {
                            this.zoneName = responseJSON["requestStatus"]["serverId"].toUpperCase();
                            launchRequestInfo!.zoneName = this.zoneName ?? "";
                            httpAnalyticsEvent.requestId =
                                responseJSON["requestStatus"]["requestId"];
                            httpAnalyticsEvent.serverId = responseJSON["requestStatus"]["serverId"];
                            httpAnalyticsEvent.requestStatusCode = String(
                                responseJSON["requestStatus"]["statusDescription"]
                            );
                        }
                    }
                    let session;
                    if (responseJSON["session"] !== undefined) {
                        this.resetSubSessionId(
                            this.getSubSessionId(),
                            responseJSON["session"]["sessionId"]
                        );
                        session = this.extractSessionInformation(responseJSON["session"]);
                        this.addSessionInfoTags(responseJSON["session"], scope);
                        if (responseJSON["session"]["sessionControlInfo"]) {
                            this.sessionControlServerMap.set(session.sessionId, {
                                "server": responseJSON["session"]["sessionControlInfo"]["ip"],
                                "port": responseJSON["session"]["sessionControlInfo"]["port"]
                            });
                        }
                        this.gpuType = session.gpuType;
                        httpAnalyticsEvent.sessionId = session.sessionId;
                        launchRequestInfo.sessionId = session.sessionId;
                        launchRequestInfo.subSessionId = this.getSubSessionId();
                        Log.d("{6bae601}", "{30536f4}", session.sessionId);
                    }
                    httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                    launchRequestInfo.launchDuration = httpAnalyticsEvent.callDuration;
                    this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                    if (this.cancelledSetup) {
                        const err = this.getCancelError(0);
                        this.addErrorTags(err, scope);
                        // Send the telemetry for the failing cases as well
                        launchRequestInfo.result = GetHexString(err.code);
                        this.gsTelemetryHandler.emitGameLaunchRequestEvent(launchRequestInfo);
                        reject(err);
                    } else if (response.status === 200) {
                        this.addResultSuccessTag(scope);
                        // If ready for connection, emit GridServer launch request telemetry.
                        if (session?.state == SessionState.READY_FOR_CONNECTION) {
                            launchRequestInfo.result = GetHexString(GsErrorCode.Success);
                            this.gsTelemetryHandler.emitGameLaunchRequestEvent(launchRequestInfo);
                        } else {
                            // Else, we would get into getSession() polling. Add to the cache
                            this.launchRequestInfoMap.set(
                                launchRequestInfo.sessionId,
                                launchRequestInfo
                            );
                        }
                        resolve(session);
                    } else {
                        Log.e("{6bae601}", "{2edef45}", response.status);
                        let sessionList;
                        if (responseJSON["otherUserSessions"] !== undefined) {
                            sessionList = this.extractSessionList(
                                responseJSON["otherUserSessions"]
                            );
                            Log.d("{6bae601}", "{ba4be1c}", sessionList.length);
                        }
                        let err: ServerError = this.getErrorFromServerResponse(
                            responseJSON["requestStatus"]["statusCode"],
                            session?.sessionId,
                            sessionList
                        );
                        setUnifiedErrorCode(err, responseJSON["requestStatus"]["unifiedErrorCode"]);
                        this.addErrorTags(err, scope);
                        // Send the telemetry for the failing cases as well
                        launchRequestInfo.result = GetHexString(err.code);
                        this.gsTelemetryHandler.emitGameLaunchRequestEvent(launchRequestInfo);
                        reject(err);
                    }
                    this.putOrPostInProgress = false;
                })
                .catch(err => {
                    this.putOrPostRequest = undefined;
                    let gsError: ErrorDetails = {
                        code: GsErrorCode.InvalidServerResponse,
                        description: err.message
                    };
                    if (this.cancelledSetup) {
                        gsError = this.getCancelError(0);
                    } else if (this.isParseError(err)) {
                        gsError.code = GsErrorCode.ResponseParseFailure;
                        gsError.description = "invalid response";
                    } else if (err.code) {
                        Log.e("{6bae601}", "{17f93f3}", GetHexString(err.code));
                        gsError.code = this.getGsErrorCode(err.code);
                        if (gsError.code === GsErrorCode.NetworkError) {
                            this.gsTelemetryHandler.emitDebugEvent(
                                sessionId ? "PUTFail" : "POSTFail",
                                url,
                                "retriesFailed"
                            );
                        }
                    } else {
                        this.gsTelemetryHandler.emitDebugEvent(
                            sessionId ? "PUTFail" : "POSTFail",
                            url,
                            err.name,
                            err.message
                        );
                        Log.e("{6bae601}", "{00a6764}");
                        gsError.description = gsError.description ?? "invalid server response";
                    }
                    endTime = performance.now();
                    if (sessionId) {
                        httpAnalyticsEvent.sessionId = sessionId;
                        launchRequestInfo.sessionId = sessionId;
                    }
                    httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                    launchRequestInfo.launchDuration = httpAnalyticsEvent.callDuration;
                    launchRequestInfo.result = GetHexString(gsError.code);
                    this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                    this.gsTelemetryHandler.emitGameLaunchRequestEvent(launchRequestInfo);
                    reject(gsError);
                    this.putOrPostInProgress = false;
                });
        }).finally(() => {
            scope?.finish();
        });
    }

    private getErrorFromServerResponse(
        statusCode: number,
        sessionId?: string,
        sessionList?: ActiveSessionInfo[]
    ): ServerError {
        let error: ServerError = {
            code: GsErrorCode.SessionServerErrorBegin,
            sessionId: sessionId,
            description: "Server http error",
            sessionList: sessionList
        };

        if (statusCode > 0 && statusCode < 0xff) {
            error.code = GsErrorCode.SessionServerErrorBegin + statusCode;
        }

        return error;
    }

    private updateSessionSetupProgress(session: any) {
        let _state = SessionProgressState.CONFIGURING;
        switch (session["seatSetupInfo"]["seatSetupStep"]) {
            case 0:
                _state = SessionProgressState.CONNECTING;
                break;
            case 1:
                _state = SessionProgressState.IN_QUEUE;
                if (session["seatSetupInfo"]["queuePosition"] < this.queuePosition) {
                    this.queuePosition = session["seatSetupInfo"]["queuePosition"];
                }
                break;
            case 5:
                _state = SessionProgressState.PREVIOUS_SESSION_CLEANUP;
                break;
            default:
                this.queuePosition = 0;
        }
        let eventData: SessionProgressUpdateEvent = {
            sessionId: session["sessionId"],
            subSessionId: this.getSubSessionId(),
            queuePosition: _state === SessionProgressState.IN_QUEUE ? this.queuePosition : 0,
            eta: session["seatSetupInfo"]["seatSetupEta"],
            state: _state
        };
        this.emit(GS_EVENTS.PROGRESS_UPDATE, eventData);
    }

    private getPollingInterval(session: any) {
        let pollingInterval = this.pollingOptions.minInterval;
        if (
            session["seatSetupInfo"]["seatSetupStep"] == 1 &&
            this.pollingOptions.queueSizePerStep != 0
        ) {
            pollingInterval +=
                Math.floor(
                    session["seatSetupInfo"]["queuePosition"] / this.pollingOptions.queueSizePerStep
                ) * this.pollingOptions.step;
        }
        return Math.min(pollingInterval, this.pollingOptions.maxInterval);
    }

    private getUrlForSession(sessionId: string) {
        let sessionurl = this.protocol;
        if (this.sessionControlServerMap.has(sessionId)) {
            let sessionControlInfo = this.sessionControlServerMap.get(sessionId);
            if (sessionControlInfo !== undefined) {
                sessionurl += sessionControlInfo.server;
                if (sessionControlInfo.port != 0) {
                    sessionurl += ":" + sessionControlInfo.port;
                }
            }
        } else {
            Log.d("{6bae601}", "{bce4eb2}", sessionId);
            sessionurl += this.initParams!.serverAddress; //use default
        }
        sessionurl += "/v2/session/" + sessionId;
        return sessionurl;
    }

    /**
     * This method can be used to get a session details belonging to a session id for resume scenario or during polling.
     * Pass isPoll flag true when post request is made for session setup.
     * Result is returned in promise with GridSession object.
     * @param sessionId - sessionId in case of resume.
     * @param isPoll - used in post request.
     **/
    public getSession(
        _sessionId: string,
        isPoll: boolean,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined> {
        const scope = this.createAndTagServerOperationScope(
            TracingOperation.GetSession,
            tracingCarrier
        );
        if (this.initParams == null) {
            const err = {
                code: GsErrorCode.GridServerNotInitialized,
                description: "Gridserver not initialized"
            };
            return this.finishScopeAndReject(err, scope);
        }
        let url = this.getUrlForSession(_sessionId);
        let seatSetupStep = 0;

        let launchRequestInfo = this.launchRequestInfoMap.get(_sessionId);
        if (isPoll && !launchRequestInfo) {
            Log.e("{6bae601}", "{bb9b2e3}", _sessionId);
            // Create a new object for telemetry dispatching, but this code should never be hit in real use cases
            let serverAddress: string;
            if (this.sessionControlServerMap.has(_sessionId)) {
                let sessionControlInfo = this.sessionControlServerMap.get(_sessionId);
                serverAddress = sessionControlInfo?.server ?? "";
            } else {
                serverAddress = this.initParams!.serverAddress;
            }
            launchRequestInfo = this.gsTelemetryHandler.getGameLaunchRequestEvent(
                serverAddress,
                this.launchRequestInfoMap.size.toString(), // to identify this error cases from telemetry
                _sessionId
            );
        }

        return new Promise<GridSession | undefined>((resolve, reject) => {
            const httpAnalyticsEvent = this.gsTelemetryHandler.getTelemetryHttpEvent(
                url,
                "GET",
                _sessionId
            );
            let startTime = performance.now();
            let endTime;
            let responseData: string;
            let getSessionRequest = () => {
                Log.d("{6bae601}", "{6e4a289}", isPoll);
                this.getSessionRequest = performHttpRequest(
                    url,
                    this.httpRequestOptions,
                    this.authInfo,
                    this.tracingInfo
                );
                this.getSessionRequest
                    .then((response: Response) => {
                        this.getSessionRequest = undefined;
                        responseData = response.data;
                        if (!this.pollingLogged) {
                            Log.d("{6bae601}", "{4b53f5d}", response.status);
                            Log.d("{6bae601}", "{6246dc6}", Log.sanitize(response.data));
                            this.pollingLogged = true;
                        }

                        endTime = performance.now();
                        if (response.status !== undefined) {
                            httpAnalyticsEvent.statusCode = String(response.status);
                        }
                        let responseJSON: any;
                        if (response.data !== undefined) {
                            responseJSON = JSON.parse(response.data);
                            if (responseJSON["requestStatus"]) {
                                this.addRequestStatusTags(responseJSON["requestStatus"], scope);
                                httpAnalyticsEvent.requestId =
                                    responseJSON["requestStatus"]["requestId"];
                                httpAnalyticsEvent.serverId =
                                    responseJSON["requestStatus"]["serverId"];
                                httpAnalyticsEvent.requestStatusCode = String(
                                    responseJSON["requestStatus"]["statusDescription"]
                                );
                            }
                        }
                        let session;
                        if (responseJSON["session"] !== undefined) {
                            session = this.extractSessionInformation(responseJSON["session"]);
                            seatSetupStep =
                                responseJSON["session"]["seatSetupInfo"]["seatSetupStep"];
                            this.zoneAddress = session.zoneAddress;
                            this.zoneName = session.zoneName;
                            this.gpuType = session.gpuType;

                            let currentServer = this.sessionControlServerMap.get(session.sessionId);
                            const nextServer = responseJSON["session"]["sessionControlInfo"];
                            if (
                                currentServer &&
                                nextServer &&
                                (currentServer.server !== nextServer["ip"] ||
                                    currentServer.port !== nextServer["port"])
                            ) {
                                Log.i("{6bae601}", "{f09f5be}", session.sessionId, currentServer.server, currentServer.port, nextServer["ip"], nextServer["port"]);
                                currentServer.server = nextServer["ip"];
                                currentServer.port = nextServer["port"];
                            }

                            httpAnalyticsEvent.sessionId = session.sessionId;
                        }
                        httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                        if (isPoll) {
                            // Keep accumulating the polling time into the launch duration
                            launchRequestInfo!.launchDuration += httpAnalyticsEvent.callDuration;
                        }
                        // TODO: We should probably only cancel the result if it is a poll, so we don't affect unrelated getSession requests
                        if (this.cancelledSetup) {
                            this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                            const err = this.getCancelError(seatSetupStep);
                            this.addErrorTags(err, scope);

                            if (isPoll) {
                                launchRequestInfo!.result = GetHexString(err.code);
                                this.gsTelemetryHandler.emitGameLaunchRequestEvent(
                                    launchRequestInfo!
                                );
                            }
                            reject(err);
                            this.pollingLogged = false;
                        } else if (response.status === 200) {
                            if (
                                responseJSON["session"]["status"] == 1 ||
                                responseJSON["session"]["status"] == 6
                            ) {
                                if (isPoll) {
                                    this.updateSessionSetupProgress(responseJSON["session"]);
                                    url = this.getUrlForSession(_sessionId);
                                    httpAnalyticsEvent.url = url;

                                    window.setTimeout(
                                        () => getSessionRequest(),
                                        this.getPollingInterval(responseJSON["session"])
                                    );
                                } else {
                                    this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                                }
                            } else if (
                                responseJSON["session"]["status"] == 2 ||
                                responseJSON["session"]["status"] == 3
                            ) {
                                // hack, GS server is sending fake notifications to NGS about stream connected.
                                // we are facing race case while getSession, until thats fixed, consider
                                // playing state as ready for connection.
                                this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                                this.addResultSuccessTag(scope);
                                if (isPoll) {
                                    launchRequestInfo!.zoneName = session
                                        ? session.zoneName
                                        : launchRequestInfo!.zoneName;
                                    launchRequestInfo!.result = GetHexString(GsErrorCode.Success);
                                    this.gsTelemetryHandler.emitGameLaunchRequestEvent(
                                        launchRequestInfo!
                                    );
                                }
                                resolve(session);
                                this.pollingLogged = false;
                            } else {
                                Log.e("{6bae601}", "{ce47cd1}", Log.sanitize(response.data));
                                this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                                const err = {
                                    code: GsErrorCode.SessionFinishedState,
                                    description: "unexpected session state"
                                };
                                this.addErrorTags(err, scope);
                                if (isPoll) {
                                    launchRequestInfo!.result = GetHexString(err.code);
                                    this.gsTelemetryHandler.emitGameLaunchRequestEvent(
                                        launchRequestInfo!
                                    );
                                }
                                reject(err);
                                this.pollingLogged = false;
                            }
                        } else {
                            Log.e("{6bae601}", "{856ad2e}", response.status);
                            Log.e("{6bae601}", "{b3abc37}", Log.sanitize(response.data));
                            this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                            let err: ServerError = this.getErrorFromServerResponse(
                                responseJSON["requestStatus"]["statusCode"]
                            );
                            setUnifiedErrorCode(
                                err,
                                responseJSON["requestStatus"]["unifiedErrorCode"]
                            );
                            this.addErrorTags(err, scope);
                            if (isPoll) {
                                launchRequestInfo!.result = GetHexString(err.code);
                                this.gsTelemetryHandler.emitGameLaunchRequestEvent(
                                    launchRequestInfo!
                                );
                            }
                            reject(err);
                            this.pollingLogged = false;
                        }
                    })
                    .catch(err => {
                        let gsError: ErrorDetails = {
                            code: GsErrorCode.InvalidServerResponse,
                            description: err.message
                        };
                        this.getSessionRequest = undefined;
                        if (this.cancelledSetup) {
                            gsError = this.getCancelError(seatSetupStep);
                        } else if (this.isParseError(err)) {
                            Log.e("{6bae601}", "{0e94ef5}", Log.sanitize(responseData));
                            gsError.code = GsErrorCode.ResponseParseFailure;
                            gsError.description = "invalid response";
                        } else if (err.code) {
                            Log.e("{6bae601}", "{aeeec58}", GetHexString(err.code));
                            gsError.code = this.getGsErrorCode(err.code);
                            if (gsError.code === GsErrorCode.NetworkError) {
                                this.gsTelemetryHandler.emitDebugEvent(
                                    "GETFail",
                                    url,
                                    "retriesFailed"
                                );
                            }
                        } else {
                            this.gsTelemetryHandler.emitDebugEvent(
                                "GETFail",
                                url,
                                err.name,
                                err.message
                            );
                            gsError.description =
                                gsError.description ?? "getSession invalid server response";
                        }
                        endTime = performance.now();
                        httpAnalyticsEvent.statusCode = "0";
                        httpAnalyticsEvent.requestStatusCode = "";
                        httpAnalyticsEvent.sessionId = _sessionId;
                        httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                        this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                        if (isPoll) {
                            launchRequestInfo!.result = GetHexString(gsError.code);
                            this.gsTelemetryHandler.emitGameLaunchRequestEvent(launchRequestInfo!);
                        }
                        reject(gsError);
                        this.pollingLogged = false;
                    });
            };
            getSessionRequest();
        }).finally(() => {
            scope?.finish();
            if (isPoll) {
                this.launchRequestInfoMap.delete(_sessionId);
            }
        });
    }

    /**
     * This method can be used to send a delete request for a given session id.
     * Pass isPoll flag true when post request is made for session setup.
     * Result is returned in promise of type void.
     * @param sessionId - sessionId to delete
     * @param tracingCarrier - Tracing information of parent operation
     * @note Will not cancel active operations.
     * @note If putOrPostSession or getSession is in progress, should first call cancelSessionSetup
     **/

    public sendDeleteRequest(sessionId: string, tracingCarrier?: TracingCarrier): Promise<void> {
        const scope = this.createAndTagServerOperationScope(
            TracingOperation.DeleteSession,
            tracingCarrier
        );
        if (this.initParams == null) {
            const err = {
                code: GsErrorCode.GridServerNotInitialized,
                description: "Gridserver not initialized"
            };
            return this.finishScopeAndReject(err, scope);
        }
        let url = this.getUrlForSession(sessionId);

        return new Promise<void>((resolve, reject) => {
            Log.d("{6bae601}", "{bbad3ce}", sessionId);
            const httpAnalyticsEvent = this.gsTelemetryHandler.getTelemetryHttpEvent(
                url,
                "DELETE",
                sessionId
            );
            // Keep the connection alive to complete this even if the tab is closed
            let keepAlive = true;
            if (this.platformDetails) {
                if (
                    IsChromeBrowser(this.platformDetails) &&
                    !IsChromeVersionAtLeast(this.platformDetails, 81)
                ) {
                    // https://bugs.chromium.org/p/chromium/issues/detail?id=835821
                    // Bug 3923439: Not supported in Chrome versions 80 and below
                    keepAlive = false;
                }
            }
            let deleteOptions: RequestHttpOptions = {
                method: "DELETE",
                headers: this.httpRequestOptions.headers,
                body: "",
                retryCount: this.httpRequestOptions.retryCount,
                timeout: this.httpRequestOptions.timeout,
                keepalive: keepAlive
            };

            let startTime = performance.now();
            let endTime;
            let responseData: string;

            performHttpRequest(url, deleteOptions, this.authInfo, this.tracingInfo)
                .then((response: Response) => {
                    endTime = performance.now();
                    if (response.status !== undefined) {
                        httpAnalyticsEvent.statusCode = String(response.status);
                    }
                    let responseJSON: any;
                    responseData = response.data;
                    if (response.data !== undefined) {
                        responseJSON = JSON.parse(response.data);
                        if (responseJSON["requestStatus"]) {
                            this.addRequestStatusTags(responseJSON["requestStatus"], scope);
                            httpAnalyticsEvent.requestId =
                                responseJSON["requestStatus"]["requestId"];
                            httpAnalyticsEvent.serverId = responseJSON["requestStatus"]["serverId"];
                            httpAnalyticsEvent.requestStatusCode = String(
                                responseJSON["requestStatus"]["statusDescription"]
                            );
                        }

                        if (responseJSON["session"]) {
                            this.addSessionInfoTags(responseJSON["session"], scope);
                        }
                    }
                    httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                    this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                    if (response.status == 200) {
                        Log.d("{6bae601}", "{6206c13}", sessionId);
                        this.addResultSuccessTag(scope);
                        resolve();
                    } else {
                        Log.e("{6bae601}", "{b9440ac}", sessionId, response.status);
                        const err = this.getErrorFromServerResponse(
                            responseJSON["requestStatus"]["statusCode"]
                        );
                        this.addErrorTags(err, scope);
                        reject(err);
                    }
                })
                .catch(err => {
                    let gsError: ErrorDetails = {
                        code: GsErrorCode.InvalidServerResponse,
                        description: err.message
                    };
                    if (this.isParseError(err)) {
                        Log.e("{6bae601}", "{6ca6291}", Log.sanitize(responseData));
                        gsError.code = GsErrorCode.ResponseParseFailure;
                        gsError.description = "invalid response";
                    } else if (err.code) {
                        Log.e("{6bae601}", "{23bd9d8}", GetHexString(err.code));
                        gsError.code = this.getGsErrorCode(err.code);
                        if (gsError.code === GsErrorCode.NetworkError) {
                            this.gsTelemetryHandler.emitDebugEvent(
                                "DELETEFail",
                                url,
                                "retriesFailed"
                            );
                        }
                    } else {
                        this.gsTelemetryHandler.emitDebugEvent(
                            "DELETEFail",
                            url,
                            err.name,
                            err.message
                        );
                        const msg = "delete invalid server response";
                        Log.e("{6bae601}", "{b580911}");
                        gsError.description = gsError.description ?? msg;
                    }
                    endTime = performance.now();
                    httpAnalyticsEvent.sessionId = sessionId;
                    httpAnalyticsEvent.callDuration = Math.round(endTime - startTime);
                    this.gsTelemetryHandler.emitHttpEvent(httpAnalyticsEvent);
                    reject(gsError);
                });
        }).finally(() => {
            scope?.finish();
        });
    }

    public setAuthInfo(authInfo: AuthInfo) {
        this.authInfo = authInfo;
    }

    /**
     * This method can be used to cancel in progress session setup.
     * Should be called only after putOrPostSession or getSession with isPoll = true, before they resolve.
     * Ongoing APIs will throw with either SessionSetupCancelled or SessionSetupCancelledDuringQueuing.
     * @note Will not send DELETE request for the session.
     * @note Should call sendDeleteRequest following putOrPostSession or getSession resolution if the session should be deleted.
     **/
    public cancelSessionSetup() {
        Log.i("{6bae601}", "{c73e640}");
        // mark session cancelled
        this.cancelledSetup = true;
        // cancel in progress session operations
        this.putOrPostRequest?.abort();
        this.getSessionRequest?.abort();
        // clear in progress session operations
        this.putOrPostRequest = undefined;
        this.getSessionRequest = undefined;
    }

    public getZoneName(): string {
        return this.zoneName ?? "";
    }

    public getZoneAddress(): string {
        return this.zoneAddress ?? "";
    }

    public getGpuType(): string {
        return this.gpuType ?? "";
    }

    public setNetworkType(network: NetworkTypeEnum) {
        this.gsTelemetryHandler.setNetworkType(network);
    }

    private createAndTagServerOperationScope(
        operationName: string,
        parent?: TracingCarrier
    ): Scope | undefined {
        if (this.tracingInfo) {
            const scope: Scope = this.tracingInfo.createTracingScopeCallback(operationName, parent);

            const serverOperationTags: Map<string, string> = new Map([
                ["component", TracingComponent.name],
                ["component.version", TracingComponent.version],
                ["client.name", this.initParams!.clientIdentification],
                ["client.version", this.initParams!.clientVersion],
                ["client.os", this.initParams!.deviceOs ?? ""],
                ["client.os.version", this.initParams!.deviceOsVer ?? ""]
            ]);
            for (const [key, value] of serverOperationTags) {
                scope.setTag(key, value);
            }

            return scope;
        }
    }

    private addErrorTags(error: ErrorDetails, scope?: Scope) {
        scope?.setTag("error", "true");
        scope?.setTag("error.description", error.description ?? "");
    }

    private addResultSuccessTag(scope?: Scope) {
        scope?.setTag("error", "false");
    }

    private addSessionInfoTags(jsonSession: any, scope?: Scope) {
        scope?.setTag("session.status", jsonSession["status"] ?? "");
    }

    private addRequestStatusTags(jsonRequestStatus: any, scope?: Scope) {
        if (!scope) {
            return;
        }

        const requestStatusTags: Map<string, string> = new Map([
            ["host.dc", jsonRequestStatus["serverId"] ?? ""],
            ["request.id", jsonRequestStatus["requestId"] ?? ""],
            ["request.status", jsonRequestStatus["statusDescription"] ?? ""]
        ]);
        for (const [key, value] of requestStatusTags) {
            scope.setTag(key, value);
        }
    }

    private finishScopeAndReject(err: ErrorDetails, scope?: Scope) {
        this.addErrorTags(err, scope);
        scope?.finish();
        return Promise.reject(err);
    }
}

export class PassThruServer extends GridServer {
    constructor() {
        super();
    }

    getAllActiveSessions(tracingCarrier?: TracingCarrier) {
        let sendEmptySessionlist = () => {
            Log.d("{6bae601}", "{b575263}");
            let result: ActiveSessionResultEvent = {
                sessionList: []
            };
            this.emit(GS_EVENTS.ACTIVE_SESSIONS_RESULT, result);
        };
        window.setTimeout(sendEmptySessionlist, 1);
    }

    putOrPostSession(
        startParams: SessionParams,
        action: number,
        sessionId: string | undefined,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined> {
        Log.d("{6bae601}", "{71c010f}");
        // todo: we should remove the resetSubSessionId map, no one clears the subsessionId after a session completion.
        // the map just continues to grow!
        this.resetSubSessionId(getNewGuid(), startParams.sessionId ?? "PassThruSessionId");
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                let session: GridSession = {
                    sessionId: this.getSessionId(),
                    subSessionId: this.getSubSessionId(),
                    appId: parseInt(startParams.appId),
                    state: SessionState.READY_FOR_CONNECTION,
                    signalConnectionInfo: { ip: "", port: 49100, protocol: "http" },
                    mediaConnectionInfo: [],
                    streamInfo: [],
                    appLaunchMode: startParams.appLaunchMode
                        ? startParams.appLaunchMode
                        : AppLaunchMode.Default,
                    zoneName: this.getZoneName(),
                    zoneAddress: this.getZoneAddress(),
                    gpuType: this.getGpuType(),
                    clientLocale: ""
                };
                if (this.initParams) {
                    session.signalConnectionInfo.ip = this.initParams.serverAddress;

                    if (startParams.connectionInfo) {
                        session.mediaConnectionInfo = startParams.connectionInfo;
                        for (const connectionInfo of session.mediaConnectionInfo) {
                            if (connectionInfo.usage === Usage.SIGNALING) {
                                session.signalConnectionInfo.ip = connectionInfo.ip;
                                session.signalConnectionInfo.port = connectionInfo.port;
                                session.signalConnectionInfo.protocol =
                                    connectionInfo.appLevelProtocol === AppLevelProtocol.HTTPS
                                        ? "https"
                                        : "http";
                                Log.d("{6bae601}", "{c6b8e0f}", connectionInfo.ip, connectionInfo.port);
                            }
                        }
                    }
                } else {
                    Log.e("{6bae601}", "{a43f57e}");
                }

                if (startParams.monitorSettings) {
                    for (const monitor of startParams.monitorSettings) {
                        session.streamInfo.push({
                            width: monitor.widthInPixels,
                            height: monitor.heightInPixels,
                            fps: monitor.framesPerSecond,
                            sdrHdrMode: monitor.sdrHdrMode
                        });
                    }
                } else {
                    for (const stream of startParams.streamParams!) {
                        session.streamInfo.push({
                            width: stream.width,
                            height: stream.height,
                            fps: stream.fps
                        });
                    }
                }
                Log.d("{6bae601}", "{07dc276}", session.sessionId);
                resolve(session);
            }, 1); // modify this value to show a longer loading screen.
        });
    }

    getSession(
        _sessionId: string,
        isPoll: boolean,
        tracingCarrier?: TracingCarrier
    ): Promise<GridSession | undefined> {
        return new Promise((resolve, reject) => {
            //its a bug if code reaches here.
            let err: ErrorDetails = { code: -1, description: "PassthruPollingNotSupported" };
            reject(err);
        });
    }

    sendDeleteRequest(sessionId: string, tracingCarrier?: TracingCarrier): Promise<void> {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }
}
