import { NvscStreamCaptureModeFlags, NvstConfig } from "../nvstconfig";
import { setUint8Array, Download } from "../util/utils";
import { Log } from "../dependencies";
import { SdpCodecType } from "../util/devicecapabilities";

const LOGTAG = "bitstreamdump";

const VIDEO_BITSTREAM_MAX_SIZE = 1024 * 1024 * 200;

declare interface RTCInsertableStreams {
    readable: ReadableStream;
    writable: WritableStream;
}

declare interface RTCRtpReceiver extends globalThis.RTCRtpReceiver {
    createEncodedStreams?(): RTCInsertableStreams;
}

declare interface RTCRtpSender extends globalThis.RTCRtpSender {
    createEncodedStreams?(): RTCInsertableStreams;
}

declare const enum RTCEncodedVideoFrameType {
    "empty",
    "key",
    "delta"
}

declare interface RTCEncodedVideoFrameMetadata {
    frameId: number;
    dependencies: number[];
    width: number;
    height: number;
    spatialIndex: number;
    temporalIndex: number;
    synchronizationSource: number;
    contributingSources: number[];
}

declare interface RTCEncodedVideoFrame {
    readonly type: RTCEncodedVideoFrameType;
    readonly timestamp: number;
    data: ArrayBuffer;
    getMetadata: () => RTCEncodedVideoFrameMetadata;
}

declare interface RTCEncodedAudioFrameMetadata {
    synchronizationSource: number;
    contributingSources: number[];
}

declare interface RTCEncodedAudioFrame {
    readonly timestamp: number;

    data: ArrayBuffer;
    getMetadata: () => RTCEncodedAudioFrameMetadata;
}

declare interface RTCConfiguration extends globalThis.RTCConfiguration {
    encodedInsertableStreams?: boolean;
}

// These definitions are for the newer version of RTC insertable streams: RTC encoded transforms:
// https://w3c.github.io/webrtc-encoded-transform/
// These aren't yet supported in browsers

// declare interface SFrameTransform {}
// declare interface RTCRtpScriptTransform {}

// declare var RTCRtpScriptTransform: {
//     new (worker: Worker, options?: any, transfer?: any[]): RTCRtpScriptTransform;
// };

// declare interface RTCTransformEvent extends Event {
//     readonly transformer: RTCRtpScriptTransformer;
// }

// declare interface RTCRtpReceiver extends globalThis.RTCRtpReceiver {
//     transform?: RTCRtpTransform;
// }

// declare interface RTCRtpSender extends globalThis.RTCRtpSender {
//     transform?: RTCRtpTransform;
// }

// type RTCRtpTransform = SFrameTransform | RTCRtpScriptTransform;

// declare interface Worker extends globalThis.Worker {
//     onrtctransform: ((event: RTCTransformEvent) => void) | null | undefined;
// }

// declare interface RTCRtpScriptTransformer {
//     readonly readable: ReadableStream;
//     readonly writable: WritableStream;
//     readonly options: any;
// }

interface InsertedStream {
    data: DataView;
    size: number;
    namePrefix: string;
    extension: string;
    isFull: boolean;
}

function getVideoCodecExtension(codec: SdpCodecType): string {
    switch (codec) {
        case SdpCodecType.H264:
            return "h264";
        case SdpCodecType.H265:
            return "hevc"; // For parity with native
        case SdpCodecType.AV1:
            return "av1";
        default:
            return "video";
    }
}

export class BitstreamDump {
    private streams: InsertedStream[] = [];

    // We shouldn't really take video codec in here since it could theoretically be different for different
    // video streams, but this is just a debugging class, so leave it for now
    constructor(private nvstConfig: NvstConfig, private videoCodec: SdpCodecType) {}

    public maybeUpdateRtcConfig(config: RTCConfiguration): void {
        if (this.nvstConfig.clientCapture) {
            config.encodedInsertableStreams = true;
        }
    }

    public start(peerConnection: RTCPeerConnection): void {
        if (this.nvstConfig.clientCapture & NvscStreamCaptureModeFlags.VIDEO) {
            const videoTransceiver = peerConnection
                .getTransceivers()
                .find(x => x.receiver?.track?.kind === "video");
            if (videoTransceiver) {
                const stream: InsertedStream = {
                    data: new DataView(new ArrayBuffer(VIDEO_BITSTREAM_MAX_SIZE)),
                    size: 0,
                    namePrefix: "video",
                    extension: getVideoCodecExtension(this.videoCodec),
                    isFull: false
                };
                this.setupStream(videoTransceiver.receiver, stream);
            }
        }
        // TODO: Audio and voice capture
    }

    public save(): void {
        for (const stream of this.streams) {
            const now = new Date().toISOString();
            const fileName = `ragnarok-${stream.namePrefix}-${now}.${stream.extension}`;
            const content = [new DataView(stream.data.buffer, 0, stream.size)];
            if (Download(content, fileName, "text/plain")) {
                Log.i("{d7392d2}", "{4b3378a}", fileName);
            }
        }
        this.streams = [];
    }

    private setupStream(target: RTCRtpReceiver | RTCRtpSender, stream: InsertedStream): void {
        const { readable, writable } = target.createEncodedStreams!();
        const transform = new TransformStream<
            RTCEncodedVideoFrame | RTCEncodedAudioFrame,
            RTCEncodedVideoFrame | RTCEncodedAudioFrame
        >({
            start: () => {
                Log.i("{d7392d2}", "{b96b3ec}", stream.namePrefix);
            },
            flush: () => {},
            transform: (encodedFrame, controller) => {
                if (!stream.isFull) {
                    const from = new Uint8Array(encodedFrame.data);
                    if (stream.size + from.byteLength <= stream.data.byteLength) {
                        setUint8Array(stream.data, stream.size, from);
                        stream.size += from.byteLength;
                    } else {
                        stream.isFull = true;
                        Log.w("{d7392d2}", "{f35b2f4}", stream.size, stream.namePrefix);
                    }
                }
                controller.enqueue(encodedFrame);
            }
        });
        readable.pipeThrough(transform).pipeTo(writable);

        this.streams.push(stream);
    }
}
