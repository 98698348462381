import { Log } from "./utillogger";
import {
    BrowserName,
    DeviceModel,
    DeviceOS,
    DeviceType,
    DeviceVendor,
    PlatformName
} from "./enumnames";
import {
    DoWork,
    makeWorkerFromInterface,
    makeSharedWorkerFromInterface,
    stopWorker,
    SharedWorker
} from "./workerutils";

const enum WorkerTelemetryType {
    ERROR_EVENT = "ErrorEvent",
    ERROR_STRING = "ErrorString",
    MESSAGE_EVENT = "MessageEvent",
    UNUSED = "WorkerUnused",
    TIMEOUT = "WorkerTimeout",
    CREATE_FAILURE = "WorkerCreateFailure",
    OVERLONG_DELAY = "WorkerOverlongDelay"
}

interface TelemetryHandler {
    emitDebugEvent(
        key1?: string,
        key2?: string,
        key3?: string,
        key4?: string,
        key5?: string,
        sessionId?: string,
        subSessionId?: string
    ): void;
}

const LOGTAG = "platform";

function getDeviceOS(platformName: PlatformName): DeviceOS | undefined {
    let os = undefined;
    switch (platformName) {
        case PlatformName.CHROME_OS:
            os = DeviceOS.CHROMEOS;
            break;
        case PlatformName.WINDOWS:
            os = DeviceOS.WINDOWS;
            break;
        case PlatformName.MAC:
            os = DeviceOS.MACOS;
            break;
        case PlatformName.LINUX:
            os = DeviceOS.LINUX;
            break;
        case PlatformName.IOS:
            os = DeviceOS.IOS;
            break;
        case PlatformName.IPADOS:
            os = DeviceOS.IPADOS;
            break;
        case PlatformName.TIZEN:
            os = DeviceOS.TIZEN;
            break;
        case PlatformName.WEBOS:
            os = DeviceOS.WEBOS;
            break;
        case PlatformName.ANDROID:
            os = DeviceOS.ANDROID;
            break;
        case PlatformName.XBOX:
            os = DeviceOS.XBOX;
            break;
        case PlatformName.STEAMOS:
            os = DeviceOS.STEAMOS;
            break;
        case PlatformName.CCOS:
            os = DeviceOS.CCOS;
            break;
    }
    // @todo Fix for platforms: "SHIELD", "TVOS"
    return os;
}

function getDeviceType(platformName: PlatformName, deviceModel?: DeviceModel): DeviceType {
    switch (deviceModel) {
        case DeviceModel.STEAMDECK:
            return DeviceType.CONSOLE;
        default:
            break;
    }
    switch (platformName) {
        case PlatformName.IOS:
            return DeviceType.PHONE;
        case PlatformName.IPADOS:
            return DeviceType.TABLET;
        case PlatformName.XBOX:
            return DeviceType.CONSOLE;
        case PlatformName.WEBOS:
        case PlatformName.TIZEN: // can we have phones too ?
            return DeviceType.TV;
        default:
            return DeviceType.DESKTOP;
    }
    // @todo Fix for all device types: "DESKTOP", "LAPTOP", "TV", "PHONE", "TABLET"
}

export declare interface PlatformDetails {
    // Name of the detected OS/Platform
    os: PlatformName;
    // Version of the OS/Platform
    osRawVer: string;
    // Version of the OS/Platform (sanitised)
    osVer: string;
    // Name of the detected browser
    browser: BrowserName;
    // Major version of the browser
    browserVer: string;
    // Full version of the browser (sanitised)
    browserFullVer: string;
    // Whether forging of the platform/browser has been detected
    forging: boolean;
    // Whether spoofing of the user agent data has been detected
    spoofing: boolean;
    // A confidence level, from 0 (not confident) to 10 (fully confident),
    // regarding the overall detection.
    //  0: No confidence.
    //  3: Little confidence. UNKNOWN values are definitely unknown, others are low possibility.
    //  5: Some confidence. UNKNOWN values are definitely unknown, others are possible.
    //  6: Some confidence. UNKNOWN values are definitely unknown, other values might be spoofed.
    //  7: Some confidence. UNKNOWN values are definitely unknown, other values are likely.
    //  8: High confidence. Empty/0 values were spoofed and have been replaced.
    //  9: High confidence. Values are expected to be correct or a sub-value
    //                      (e.g. Chromium instead of specific browser name).
    // 10: High confidence. All values are expected to be valid.
    confidence: number;

    // Derived values

    // device vendor
    vendor: DeviceVendor;

    // Generated values

    // deviceType in the format Nvidia backend services expects
    deviceType?: DeviceType;
    // deviceOS in the format Nvidia backend services expects
    deviceOS?: DeviceOS;
    // deviceModel in the format Nvidia backend services expects
    deviceModel?: DeviceModel;

    // Timings

    // How long the entire checking and determining process took
    totalTime: DOMHighResTimeStamp;
}

// TODO: Add checks based on
//       * screen size (distinguish tablet and phone)
//       * additional GL renderers
//       * mismatch between platform and other detection

interface GlDebugInfo {
    vendorName: string;
    rendererName: string;
    present: boolean;
}

declare interface UserAgentData {
    brands: Brand[];
    mobile: boolean;

    platform?: string;
    getHighEntropyValues(hints: string[]): Promise<UADataHEValues>;
}
declare interface Brand {
    brand: string;
    version: string;
}
declare interface UserAgentData_HighEntropyValues {
    platform?: string;
    architecture?: string;
    uaFullVersion?: string;
    platformVersion?: string;
    model?: string;
    bitness?: string;

    brands?: Brand[];
    mobile?: boolean;
}

declare type UADataHEValues = UserAgentData_HighEntropyValues | undefined;

declare interface Navigator {
    userAgentData?: UserAgentData;
}

const UNKNOWN_VERSION = "0";

const INSTANT_TIMEOUT = 0;
const FALLBACK_TIMEOUT = 150;
const LONG_FALLBACK_TIMEOUT = FALLBACK_TIMEOUT * 2;

const MAX_VOICE_CHECK_TOTAL_TIME = 600;

const INVALID_TIMER_ID = 0;

// A class to try to detect the browser, operating system, device type, device vendor and certain version information.
// Much of the code is asynchronous, either intrinsically or due to running in timer callbacks.
// This class gets instantiated early on, but some clients run a lot of code on the main thread during their first
// initialization.  Ensure that any timers with important timeouts are started after that 'heavy' initialization,
// otherwise the timeouts will always be exceeded prior to any work being able to be executed.
class Platform {
    private start: DOMHighResTimeStamp = 0;
    private finish: DOMHighResTimeStamp = 0;

    private voiceIsChromeOS = false;
    private voiceIsChrome = false;
    private voiceIsFirefox = false;
    private voiceIsFirefoxAndroid = false;
    private voiceIsAndroid = false;
    private voiceIsMicrosoft = false;
    private voiceIsApple = false;
    private voiceIsEdge = false;
    private voiceIsWindows = false;
    private voiceIsAppleEllen = true;
    private voiceWasKnown = false;

    private isBrowserEdge = false;
    private isBrowserEdgeLegacy = false;
    private isBrowserEdgeiOS = false;
    private isBrowserSafari = false;
    private isBrowserChrome = false;
    private isBrowserChromeiOS = false;
    private isBrowserNetscape = false;
    private isBrowserFirefoxiOS = false;
    private isBrowserFirefoxTV = false;
    private isBrowserOpera = false;
    private isBrowserOperaiOS = false;
    private isBrowserOperaTouch = false;
    private isBrowserBrave = false;
    private isBrowserYandex = false;
    private isBrowserSamsungChromium = false;
    private isBrowserReactBased = false;

    private isPlatformMacIntel = false;
    private isPlatformiPhone = false;
    private isPlatformWin = false;
    private isPlatformiPad = false;
    private isPlatformLinux = false;
    private isPlatformFreeBsdX86 = false;
    private isPlatAndroid = false;
    private isPlatWebOS = false;
    private isPlatTizen = false;
    private isPlatCcOS = false;

    private isPluginChromeNative = false;
    private isPluginChromePDF = false;
    private isPluginChromiumPDF = false;
    private isPluginEdgePDF = false;
    private isPluginSamsungHealth = false;

    private isBrandChrome = false;
    private isBrandEdge = false;
    private isBrandOpera = false;
    private isBrandYandex = false;
    private isBrandChromium = false;
    private isBrandUnknown = false;

    private isMobileUAD = false;
    private isMobileHE? = false;
    private isMobileUAD_HE? = false;
    private isMobileNavigator = false;

    private isGLXbox = false;
    private isGLXboxSeries = false;
    private isGLSteamDeck = false;

    private isHEModelXbox = false;
    private isHEModelFireTV = false;

    private isHEPlatformWindows = false;
    private isHEPlatformMac = false;
    private isHEPlatformChromeOS = false;
    private isHEPlatformChromiumOS = false;
    private isHEPlatformAndroid = false;
    private isHEPlatformLinux = false;
    private isHEPlatformOverridden = false;

    private supportsAvif = false;

    private supportsBigInt = false;
    private supportsBigInt64Array = false;
    // private supportsCorrect8bitCollationOrderName = false;
    private supportsDecodingInfo = false;
    private supportsPerformanceNavigationTiming = false;
    private supportsWebAssemblyExceptions = false;
    private supportsBroadcastChannel = false;
    private supportsGpuBuffer = false;
    private supportsMediaRecorder = false;

    private supportsDeviceLightEvent = false;
    private supportsAbortSignalAbort = false;
    private supportsBeforeInputEvent = false;
    private supportsIntlDisplayNames = false;
    private supportsIntlCollationOptions = false;

    private supportsApplePay = false;
    private supportsCredential = false;
    private supportsBrowserRuntime = false;

    private supportsSharedWorker = false;

    private supportsLargestContentfulPaint = false;
    private supportsCSSregisterProperty = false;
    private supportsXR = false;
    private supportsGetInstalledRelatedApps = false;
    private supportsBarcodeDetector = false;
    private supportsWakeLock = false;
    private supportsPromiseAny = false;
    private supportsFileSystemHandle = false;
    private supportsAtomicsWaitAsync = false;
    private supportsCSSaspectRatio = false;
    private supportsWebHID = false;
    private supportsOverflowClip = false;

    private mediaPrimaryHover = false;
    //private mediaAnyHover = false;
    private mediaPrimaryNonHover = false;
    //private mediaAnyNonHover = false;
    private mediaPrimaryCoarsePointer = false;
    //private mediaAnyPointer = false;
    private mediaPrimaryFinePointer = false;
    //private mediaAnyFinePointer = false;

    private hasGL = false;
    private hasUaData = false;
    private isUaDataSpoofed = false;
    private useUaData = false;
    private ignoreUaData = false;
    private wereHeChecksLate = false;

    private performedChecks = false;
    private performedVoiceChecks = false;
    private performedGlChecks = false;
    private performedHEChecks = false;

    private voiceCheckTimer = INVALID_TIMER_ID;
    private glCheckTimer = INVALID_TIMER_ID;
    private heCheckTimer = INVALID_TIMER_ID;

    private glCheckFallbackTime = 0;

    private detector?: Promise<PlatformDetails>;

    private platformDetails: PlatformDetails = {
        os: PlatformName.UNKNOWN,
        osRawVer: UNKNOWN_VERSION,
        osVer: UNKNOWN_VERSION,
        browser: BrowserName.UNKNOWN,
        browserVer: UNKNOWN_VERSION,
        browserFullVer: UNKNOWN_VERSION,
        forging: true,
        spoofing: true,
        confidence: 0,
        totalTime: 0,
        vendor: DeviceVendor.UNKNOWN
    };
    private osName?: PlatformName;
    private osRawVersion?: string;
    private osVersion?: string;
    private browserName?: BrowserName;
    private browserVersion?: string;
    private chromiumVersion?: string;
    private browserFullVer?: string;
    private chromeUaVersion?: string;
    private chromeUaMajorVer?: string;
    private deviceName?: string;
    private deviceVendor?: DeviceVendor;
    private devType?: DeviceType;
    private devModel?: DeviceModel;
    private isForged?: boolean;
    private confidenceLevel?: number;

    private telemetry?: TelemetryHandler;
    private queuedTelemetry: { name: string; type: WorkerTelemetryType; data?: any }[] = [];

    constructor() {}

    public detectPlatformDetails(): Promise<PlatformDetails> {
        if (!this.detector) {
            this.detector = this.getDetectorPromise();
        }
        return this.detector!;
    }

    private getDetectorPromise(): Promise<PlatformDetails> {
        this.start = performance.now();

        return new Promise((resolve, reject) => {
            if (this.performedChecks) {
                resolve(this.platformDetails);
                return;
            }

            this.beginChecking().then(() => {
                this.performedChecks = true;
                resolve(this.platformDetails);
            });
        });
    }

    // Need to handle four cases:
    // 1. No speechSynthesis object at al, so just check immediately (voice check is no-op)
    // 2. No onvoiceschanged event, so just check immediately
    // 3. onvoiceschanged event but voices already changed, so process immediately
    // 4. onvoiceschanged event and voices not changed yet, so wait for event to fire
    //
    // However, browsers can block detection of voices but leave the onvoiceschanged event present.
    // The event will then never fire.
    // This could conceivably happen if a user denies permission for voice detection.
    //
    // To accommodate that, a setTimeout() call is made to perform the checking, as well as the event hander.
    // The first time the checks are run, resolve() will be called and 'finish' the Promise.
    // Subsequent attempts to run the checks will return early, and not update the results.
    // This avoids running the checking code multiple times.
    //
    // The checks will only be run if there are voices to check (unless the overall voice checking
    // timeout has passed).  If there aren't and the overall voice checking timeout hasn't been reached,
    // a new timeout callback is scheduled, and the voiceschanged event continues to be listened for.
    // This means that multiple events or timeout callbacks may be needed before the voice checking completes.
    private checkVoices(): Promise<void> {
        const synthesis = window["speechSynthesis"];
        if (!synthesis) {
            // No speech synthesis, so no voices
            return new Promise(resolve => {
                resolve();
            });
        } else {
            const getVoices = synthesis["getVoices"];
            let curVoices = getVoices?.call(synthesis) || [];
            return new Promise((resolve, reject) => {
                if (!getVoices || curVoices.length) {
                    this.checkVoicesInternal(curVoices);
                    resolve();
                } else {
                    let startedVoiceChecking = 0;

                    const checkVoicesLambda = () => {
                        curVoices = getVoices.call(synthesis);
                        const now = performance.now();
                        // If there are no voices, and the overall timeout hasn't been reached (or even started),
                        // wait some more and try again.
                        if (
                            curVoices.length == 0 &&
                            (startedVoiceChecking == 0 ||
                                now - startedVoiceChecking < MAX_VOICE_CHECK_TOTAL_TIME)
                        ) {
                            // Don't use empty voice lists for checking.  Wait for another event callback,
                            // or for the timer check to fire again.
                            // Voices should be available in all normal cases and should appear within a
                            // few hundred milliseconds at worst; the MAX_VOICE_CHECK_TOTAL_TIME is the
                            // maximum total delay time that can be introduced, but the delay won't be
                            // that large unless someone has spoofed the speech synthesis voices to be empty.

                            // Reset the fallback timeout.
                            if (this.voiceCheckTimer) {
                                clearTimeout(this.voiceCheckTimer);
                            }
                            this.voiceCheckTimer = window.setTimeout(
                                checkVoicesLambda,
                                FALLBACK_TIMEOUT
                            );
                            return;
                        }

                        // Clear any pending fallback/timer check.
                        if (this.voiceCheckTimer) {
                            clearTimeout(this.voiceCheckTimer);
                            this.voiceCheckTimer = INVALID_TIMER_ID;
                        }

                        // Check the current set of voices.
                        this.checkVoicesInternal(curVoices);
                        resolve();
                    };

                    // Listen for voiceschanged events.
                    // These can (potentially) fire prior to the 'instant' timeout callback being executed,
                    // and thus in theory can run the voice checking sooner than would otherwise happen.
                    if (synthesis["onvoiceschanged"] !== undefined) {
                        synthesis["onvoiceschanged"] = checkVoicesLambda;
                    }

                    // Run the remainder of the code fully asynchronously.
                    // This firstly allows time for the voices to be loaded, and secondly allows
                    // the timeouts to be relative to when there's actual processing time available.
                    // That is, don't start the timeouts until after the first 'heavy' initialization
                    // some clients do.
                    this.runAfterStartup(() => {
                        // Short-circuit the fallback timeout if voice information has become available.
                        // Otherwise, it takes at least FALLBACK_TIMEOUT milliseconds (or another
                        // voiceschanged event) before the new voices are checked.
                        curVoices = getVoices.call(synthesis);
                        if (curVoices.length) {
                            checkVoicesLambda();
                            return;
                        }

                        this.voiceCheckTimer = window.setTimeout(
                            checkVoicesLambda,
                            FALLBACK_TIMEOUT
                        );
                        startedVoiceChecking = performance.now();
                    });
                }
            });
        }
    }

    private checkVoicesInternal(voices: SpeechSynthesisVoice[]): void {
        if (this.performedVoiceChecks) {
            return;
        }

        for (const voice of voices) {
            const voiceID = voice["voiceURI"] || "";

            // First, check exclusive patterns.
            if (voiceID.startsWith("Chrome OS")) {
                this.voiceIsChromeOS = true;
            } else if (voiceID.startsWith("Google")) {
                this.voiceIsChrome = true;
            } else if (voiceID.includes("moz-tts")) {
                if (voiceID.includes("android")) {
                    this.voiceIsFirefoxAndroid = true;
                    this.isPlatAndroid = true;
                }
                this.voiceIsFirefox = true;
            } else if (voiceID == "English United States") {
                this.voiceIsAndroid = true;
            }

            // Now check for patterns that can be shared with the above.
            if (voiceID.includes("Microsoft")) {
                this.voiceIsMicrosoft = true;
                // NB - Lots of Windows-based browsers use Microsoft voices
                //      However, only Edge has the Microsoft Online voices
                if (voiceID.includes("Online")) {
                    this.voiceIsEdge = true;
                } else {
                    this.voiceIsWindows = true;
                }
            } else if (voiceID.includes("com.apple")) {
                this.voiceIsApple = true;
                if (voiceID.includes("Ellen")) {
                    this.voiceIsAppleEllen = true;
                }
            } else if (voiceID == "Zuzana") {
                // Apple voices on macOS running Chrome don't start with 'com.apple'
                // Luckily, 'Zuzana' is always present AFAICS.
                this.voiceIsApple = true;
            }
        }

        this.voiceWasKnown =
            this.voiceIsApple ||
            this.voiceIsMicrosoft ||
            this.voiceIsChrome ||
            this.voiceIsChromeOS ||
            this.voiceIsFirefox ||
            this.voiceIsAndroid;

        this.performedVoiceChecks = true;
    }

    private async beginChecking() {
        // Perform checks
        await (Promise as any)
            .all([
                this.checkGlDebugInfo(),
                this.checkHighEntropy(),
                this.checkAvif(),
                this.checkVoices(),
                this.checkUserAgentVersion(),
                this.checkWindowProperties(),
                this.checkPlatform(),
                this.checkApplePay(),
                this.checkPlugins(),
                this.checkMobile(),
                this.checkMediaQueries()
            ])
            .then(() => {
                this.validateChecks();
                this.createPlatformDetails();
            });
    }

    private checkWindowProperties() {
        const w = window as any;
        const ownProp = w["hasOwnProperty"];

        const navi = w["navigator"];
        // Browser checks

        if (ownProp.call(w, "MSMediaKeys")) {
            this.isBrowserEdgeLegacy = true;
        } else if (ownProp.call(w, "_firefoxTV_cachedScrollPosition")) {
            // Firefox on FireTV has _firefoxTV_cachedScrollPosition, but frequently with value undefined
            this.isBrowserFirefoxTV = true;
        } else if (
            w["__edgeActiveElement"] !== undefined ||
            w["__edgeTrackingPreventionStatistics"]
        ) {
            // These are injected by Edge on iOS in different circumstances
            this.isBrowserEdgeiOS = true;
        } else if (w["safari"]) {
            this.isBrowserSafari = true;
        } else if (w["opr"]) {
            this.isBrowserOpera = true;
        } else if (w["oprt"]) {
            // oprt is from iOS
            this.isBrowserOperaiOS = true;
        } else if (navi["brave"]) {
            this.isBrowserBrave = true;
        } else if (w["OperaTouch"] !== undefined || w["ethereum"]) {
            // We know we haven't injected MetaMask's crypto wallet, so if
            // window.ethereum is present this must be Opera on mobile.
            // NB window.ethereum is also present in some Brave browsers, so check those before this.
            // OperaTouch is from some versions on Android
            this.isBrowserOperaTouch = true;
            this.isPlatAndroid = true;
        } else if (w["yandex"]) {
            this.isBrowserYandex = true;
        } else if (w["QuickAccess"]) {
            this.isBrowserSamsungChromium = true;
        } else if (w["chrome"]) {
            // Lots of Chromium-based browsers have this property; check them before this!
            this.isBrowserChrome = true;
        } else if (w["netscape"]) {
            this.isBrowserNetscape = true;
        } else if (w["__firefox__"]) {
            // window.__firefox__ is injected by older Firefox on iOS.
            // Newer Firefox on iOS runs that script in a different "content world".
            // TODO Find a new way to detect Firefox on iOS
            this.isBrowserFirefoxiOS = true;
        } else if (w["__gCrWeb"]) {
            // Specially injected by Chrome on iOS platforms
            this.isBrowserChromeiOS = true;
        } else if (w["ReactNativeWebView"]) {
            this.isBrowserReactBased = true;
        }

        // Special platform checks
        if (w["contacts"] !== undefined || w["ContactsManager"] !== undefined) {
            this.isPlatAndroid = true;
        } else if (ownProp.call(w, "onwebOSAccessibilityAlertDone") || w["webOSSystem"]) {
            this.isPlatWebOS = true;
        } else if (w["tizen"] || w["TizenTVApiInfo"] || w["addEdgeEffectONSCROLLTizenUIF"]) {
            this.isPlatTizen = true;
        } else if (w["HardkeyEvent"]) {
            this.isPlatCcOS = true;
        }

        // Feature checks, for checking rendering engine version based on its capabilities.

        const intl = w["Intl"];

        // On iOS, the rendering engine (WebKit) is only updated when the OS updates, so these
        // checks can proxy for the OS version.
        if (navi["mediaCapabilities"]?.["decodingInfo"]) {
            this.supportsDecodingInfo = true;
        }
        if (w["BigInt"]) {
            this.supportsBigInt = true;
            if (w["BigInt64Array"]) {
                this.supportsBigInt64Array = true;
            }
        }
        if (w["PerformanceNavigationTiming"]) {
            this.supportsPerformanceNavigationTiming = true;
        }
        if (w["WebAssembly"] && w["WebAssembly"]["Exception"]) {
            this.supportsWebAssemblyExceptions = true;
        }
        if (w["BroadcastChannel"]) {
            this.supportsBroadcastChannel = true;
        }
        if (w["GPUBuffer"]) {
            this.supportsGpuBuffer = true;
        }
        if (w["SharedWorker"]) {
            this.supportsSharedWorker = true;
        }
        if (w["MediaRecorder"]) {
            this.supportsMediaRecorder = true;
        }

        // Firefox version updates tend to improve/modify JavaScript in some fashion.
        if (ownProp.call(w, "ondevicelight")) {
            this.supportsDeviceLightEvent = true;
        }
        if (w["AbortSignal"]?.["abort"]) {
            this.supportsAbortSignalAbort = true;
        }
        if (ownProp.call(w, "onbeforeinput")) {
            this.supportsBeforeInputEvent = true;
        }
        if (intl) {
            if (intl["DisplayNames"]) {
                this.supportsIntlDisplayNames = true;
            }
            const col = new intl["Collator"]("zh", { collation: "pinyin" });
            this.supportsIntlCollationOptions = col.resolvedOptions()?.collation == "pinyin";
        }

        // Features that can be useful to distinguish different browsers.

        if (w["Credential"]) {
            this.supportsCredential = true;
        }

        if (w["browser"]?.["runtime"]) {
            this.supportsBrowserRuntime = true;
        }

        // Chrome version detection for older Chrome versions, where
        // User Agent Client Hints are not supported, requires feature detection.

        if (w["LargestContentfulPaint"]) {
            this.supportsLargestContentfulPaint = true;
        }
        if (w["CSS"]?.["registerProperty"]) {
            this.supportsCSSregisterProperty = true;
        }
        if (navi["xr"]) {
            this.supportsXR = true;
        }
        if (navi["getInstalledRelatedApps"]) {
            this.supportsGetInstalledRelatedApps = true;
        }
        if (w["BarcodeDetector"]) {
            this.supportsBarcodeDetector = true;
        }
        if (w["WakeLock"]) {
            this.supportsWakeLock = true;
        }
        if (w["Promise"]?.["any"]) {
            this.supportsPromiseAny = true;
        }
        if (w["FileSystemHandle"]) {
            this.supportsFileSystemHandle = true;
        }
        if (w["Atomics"]?.["waitAsync"]) {
            this.supportsAtomicsWaitAsync = true;
        }
        if (w["CSS"]?.["supports"]?.("aspect-ratio: auto")) {
            this.supportsCSSaspectRatio = true;
        }
        if (w["HID"]) {
            this.supportsWebHID = true;
        }
        if (w["CSS"]?.["supports"]?.("overflow: clip")) {
            this.supportsOverflowClip = true;
        }
    }

    private checkPlatform() {
        const platform = navigator.platform;
        if (platform === "MacIntel") {
            this.isPlatformMacIntel = true;
        } else if (platform === "iPhone") {
            this.isPlatformiPhone = true;
        } else if (platform === "Win32") {
            this.isPlatformWin = true;
        } else if (platform === "iPad") {
            this.isPlatformiPad = true;
        } else if (platform === "FreeBSD amd64") {
            this.isPlatformFreeBsdX86 = true;
        } else if (platform === "Windows") {
            // Not used by major browsers, so likely a forged value
            this.isPlatformWin = true;
            this.isForged = true;
        }

        if (platform.startsWith("Linux")) {
            this.isPlatformLinux = true;
        }
    }

    private checkApplePay() {
        const w = window as any;
        const applePayWorks = !!(w["ApplePaySession"] && w["ApplePaySession"]["canMakePayments"]);
        if (applePayWorks) {
            // Apple Pay seems only to work in Safari, and some browsers that are very like Safari on iOS
            // (such as Cloudy, in newer versions).
            this.supportsApplePay = true;
        }
    }

    private checkGlDebugInfo(): Promise<void> {
        // Checking GL debug info can take 10s of milliseconds, due to the getContext() call.
        // If possible, run in a Worker (only if the system supports OffscreenCanvas) to take
        // advantage of multi-threading.
        // Otherwise, run in a setTimeout() call to reduce immediate overhead and potentially
        // allow concurrent waits/delays to overlap with the GL checking
        return new Promise((resolve, reject) => {
            const workerName = "GL";
            const setGlBasedFlags = (glDbgInfo: GlDebugInfo) => {
                if (this.performedGlChecks) {
                    return;
                }

                const renderer = glDbgInfo.rendererName;
                if (renderer.includes("SraKmd")) {
                    // Both Xbox One X and Xbox Series X have this string in their renderers.
                    this.isGLXbox = true;
                    if (renderer.includes("SraKmd_arden")) {
                        this.isGLXboxSeries = true;
                    }
                    const matches = /D3D11-(\d{2,}\.\d{1,}\.\d{5,}\.\d{4,})/.exec(renderer);
                    if (matches && matches.length > 1) {
                        this.osVersion = matches[1];
                    }
                } else if (renderer.includes("AMD VANGOGH")) {
                    this.isGLSteamDeck = true;
                }
                // TODO - check for other GPUs (Mali, Adreno, AMD, etc.) and map to platforms?

                this.hasGL = glDbgInfo.present;

                this.performedGlChecks = true;
            };
            let glCheckFallbackCompleted = false;
            const handleGlInfo = (glDbgInfo: GlDebugInfo) => {
                if (this.glCheckTimer) {
                    // Fallback initialized but not yet executed.
                    clearTimeout(this.glCheckTimer);
                    this.glCheckTimer = INVALID_TIMER_ID;
                } else if (glCheckFallbackCompleted) {
                    // Fallback initialized, but timer is no longer set - ergo fallback has already run.

                    // No point in handling this; the fallback timer has already fired, and will set flags itself.
                    Log.w("{3c72abb}", "{c1bb269}");
                    this.emitWorkerOverlongTelemetry(
                        workerName,
                        this.glCheckFallbackTime - performance.now()
                    );
                    // No point in calling resolve() either; that will have been done by the fallback code.
                    return;
                } else {
                    // We've been called by the Worker code before the fallback had a chance to be initialized.
                    // We *must* set this.performedGlChecks in setGlBasedFlags() to avoid the fallback being started.
                }
                setGlBasedFlags(glDbgInfo);
                resolve();
            };

            let glCheckWithWorker = false;
            let glCheckWithWorkerFailed = false;

            const handleGlError = (error: MessageEvent | ErrorEvent | string) => {
                if (typeof error == "string") {
                    this.emitWorkerErrorStringTelemetry(workerName, error);
                } else if ("data" in error) {
                    // MessageEvent.  Message deserialization error on Worker instance in main thread.
                    this.emitWorkerMessageErrorTelemetry(workerName, error);
                } else {
                    // ErrorEvent.  Either onerror in WorkerGlobalScope, or onerror on Worker instance in main thread.
                    this.emitWorkerErrorTelemetry(workerName, error);
                }
                glCheckWithWorkerFailed = true;
                Log.e("{3c72abb}", "{9e45306}", error);
            };

            let timeoutDelay = INSTANT_TIMEOUT;
            let worker: Worker | undefined;
            try {
                if (self["OffscreenCanvas"]) {
                    glCheckWithWorker = true;

                    worker = makeWorkerFromInterface<GlDebugInfo>(
                        GLWorker,
                        handleGlInfo,
                        handleGlError
                    );

                    // Set the fallback timeout delay to be non-instant; this will call the fallback
                    // processing after a short timeout in case the Worker doesn't work.
                    timeoutDelay = FALLBACK_TIMEOUT;
                }
            } catch (ex) {
                glCheckWithWorkerFailed = true;
                this.emitWorkerCreationErrorTelemetry(workerName, ex);
                Log.w("{3c72abb}", "{caf8cd7}", ex);
                // The fallback code will execute, with INSTANT_TIMEOUT.
            }

            // Run the remainder of the code fully asynchronously.
            // The Worker will run in its separate thread (if available), and can run in parallel with the main
            // thread whilst that is executing the client's first initialization.
            // Use window.setTimeout() to run the rest of the code (that sets up the fallback timer) on the
            // next 'event cycle', which will be after the client's first initialization.
            // This ensures the fallback timeout is counted from when there's actual processing time available.
            this.runAfterStartup(() => {
                if (this.performedGlChecks) {
                    return;
                }

                // Use setTiemout to call the GL checking code asynchronously, both for fallback and for non-Worker.
                //
                // Note that a 0 delay to setTimeout() means "immediately, after current event cycle" - or, for us,
                // when the main thread becomes free.  We want the GL checking to happen in 'dead time' - when the
                // main thread is otherwise blocked - rather than fully synchronously with the rest of the main thread.
                // That should reduce any delays caused by GL checking to the absolute minimum.
                // Using a timeout of 0 (INSTANT_TIMEOUT) will give a greater chance of that happening.
                //
                // Hence, always use setTimeout() - both for the fallback checks (in case the Worker fails) or for the
                // non-Worker case.
                this.glCheckTimer = window.setTimeout(() => {
                    this.glCheckTimer = INVALID_TIMER_ID;
                    this.glCheckFallbackTime = performance.now();
                    if (glCheckWithWorkerFailed) {
                        // Worker creation failed
                        Log.w("{3c72abb}", "{fbe663c}");
                    } else if (glCheckWithWorker) {
                        // Worker took too long
                        Log.w("{3c72abb}", "{2577061}");
                        this.emitWorkerTimeoutTelemetry(workerName);
                        // Do *NOT* stop the worker here.  Instead, allow it to complete and then raise
                        // telemetry / log that fact.
                    } else {
                        // No Worker in use
                        Log.i("{3c72abb}", "{95c252e}");
                        this.emitWorkerUnusedTelemetry(workerName);
                    }
                    const glDbgInfo = new GLWorker().doWork();
                    handleGlInfo(glDbgInfo);
                    glCheckFallbackCompleted = true;
                }, timeoutDelay);
            });
        });
    }

    private internalCheckUserAgentData(uad: UserAgentData) {
        this.hasUaData = true;

        const isMobile = uad.mobile;
        if (isMobile) {
            this.isMobileUAD = true;
        }
        const brands: Brand[] = uad.brands || [];
        let unknownBrands = 0;
        for (const brand of brands) {
            const brandName = brand.brand;
            const brandVersion = brand.version;
            if (brandName === "Google Chrome") {
                this.isBrandChrome = true;
                this.browserVersion = brandVersion;
            } else if (brandName === "Microsoft Edge") {
                this.isBrandEdge = true;
                this.browserVersion = brandVersion;
            } else if (brandName === "Opera") {
                this.isBrandOpera = true;
                this.browserVersion = brandVersion;
            } else if (brandName === "Yandex") {
                this.isBrandYandex = true;
                this.browserVersion = brandVersion;
            } else if (brandName === "Chromium") {
                this.isBrandChromium = true;
                this.browserVersion = this.browserVersion ?? brandVersion;
                this.chromiumVersion = brandVersion;
            } else {
                unknownBrands++;
            }
        }
        // Current expectation is for one arbitrary brand
        if (unknownBrands > 2) {
            this.isBrandUnknown = true;
        }
    }

    private checkMobile() {
        // Not the best check; the UserAgentData one is more reliable, but this is present
        // for other devices/browsers (particularly Safari on iOS/iPadOS/macOS) that don't
        // support UserAgentData.
        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
            this.isMobileNavigator = true;
        }
    }

    private checkMediaQueries() {
        //const now = performance.now();
        const w = window as any;

        this.mediaPrimaryHover = w.matchMedia("(hover: hover)").matches;
        //this.mediaAnyHover = w.matchMedia("(any-hover: hover)").matches;
        this.mediaPrimaryNonHover = w.matchMedia("(hover: none)").matches;
        //this.mediaAnyNonHover = w.matchMedia("(any-hover: none)").matches;
        this.mediaPrimaryCoarsePointer = w.matchMedia("(pointer: coarse)").matches;
        //this.mediaAnyPointer = w.matchMedia("(any-pointer: coarse)").matches;
        this.mediaPrimaryFinePointer = w.matchMedia("(pointer: fine)").matches;
        //this.mediaAnyFinePointer = w.matchMedia("(any-pointer: fine)").matches;
        //const mediaQueryTotalTime = performance.now() - now;
    }

    private checkPlugins() {
        const plugins = navigator.plugins;
        for (const plugin of plugins) {
            const pluginName: string = plugin.name;
            if (pluginName === "Native Client") {
                this.isPluginChromeNative = true;
            } else if (pluginName.startsWith("Microsoft Edge PDF")) {
                this.isPluginEdgePDF = true;
                this.isBrowserEdge = true;
            } else if (pluginName.startsWith("Chrome PDF")) {
                this.isPluginChromePDF = true;
            } else if (pluginName.startsWith("Chromium PDF")) {
                this.isPluginChromiumPDF = true;
            } else if (pluginName.startsWith("PPAPI SAMSUNGHEALTH")) {
                this.isPluginSamsungHealth = true;
                this.isPlatTizen = true;
            }
        }
    }

    private async avifSupported(): Promise<boolean> {
        if (!window.createImageBitmap) return false;
        const avifData =
            "data:image/avif;base64," +
            "AAAAGGZ0eXBhdmlmAAAAAGF2aWZtaWYxAAADm21ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAHBpY3QA" +
            "AAAAAAAAAAAAAAAAAAAADnBpdG0AAAAAAAEAAAAiaWxvYwAAAABEQAABAAEAAAAAA7sAAQAAAAAA" +
            "AAAjAAAAI2lpbmYAAAAAAAEAAAAVaW5mZQIAAAAAAQAAYXYwMQAAAAMbaXBycAAAAvxpcGNvAAAC" +
            "rGNvbHJwcm9mAAACoGxjbXMEMAAAbW50clJHQiBYWVogB+UACAAJAAsAEAAFYWNzcE1TRlQAAAAA" +
            "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAPbWAAEAAAAA0y1sY21zAAAAAAAAAAAAAAAAAAAAAAAAAAAA" +
            "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANZGVzYwAAASAAAABAY3BydAAAAWAAAAA2d3RwdAAA" +
            "AZgAAAAUY2hhZAAAAawAAAAsclhZWgAAAdgAAAAUYlhZWgAAAewAAAAUZ1hZWgAAAgAAAAAUclRS" +
            "QwAAAhQAAAAgZ1RSQwAAAhQAAAAgYlRSQwAAAhQAAAAgY2hybQAAAjQAAAAkZG1uZAAAAlgAAAAk" +
            "ZG1kZAAAAnwAAAAkbWx1YwAAAAAAAAABAAAADGVuVVMAAAAkAAAAHABHAEkATQBQACAAYgB1AGkA" +
            "bAB0AC0AaQBuACAAcwBSAEcAQm1sdWMAAAAAAAAAAQAAAAxlblVTAAAAGgAAABwAUAB1AGIAbABp" +
            "AGMAIABEAG8AbQBhAGkAbgAAWFlaIAAAAAAAAPbWAAEAAAAA0y1zZjMyAAAAAAABDEIAAAXe///z" +
            "JQAAB5MAAP2Q///7of///aIAAAPcAADAblhZWiAAAAAAAABvoAAAOPUAAAOQWFlaIAAAAAAAACSf" +
            "AAAPhAAAtsRYWVogAAAAAAAAYpcAALeHAAAY2XBhcmEAAAAAAAMAAAACZmYAAPKnAAANWQAAE9AA" +
            "AApbY2hybQAAAAAAAwAAAACj1wAAVHwAAEzNAACZmgAAJmcAAA9cbWx1YwAAAAAAAAABAAAADGVu" +
            "VVMAAAAIAAAAHABHAEkATQBQbWx1YwAAAAAAAAABAAAADGVuVVMAAAAIAAAAHABzAFIARwBCAAAA" +
            "DGF2MUOBDQwAAAAAFGlzcGUAAAAAAAAAEAAAABAAAAAoY2xhcAAAAAEAAAABAAAAAQAAAAH////x" +
            "AAAAAv////EAAAACAAAAF2lwbWEAAAAAAAAAAQABBIGCA4QAAAArbWRhdBIACgkfzP/IIEBA0oAy" +
            "FGW+OwPr0bIHHHHBATZPtaQ3RWTA";
        const blob = await fetch(avifData).then(r => r.blob());
        return createImageBitmap(blob).then(
            () => true,
            () => false
        );
    }

    private async checkAvif() {
        // We do not care about AVIF support on non-Chromium-dervide browsers.
        // Since the check is non-trivial, only perform it on the browsers where
        // it makes sense to do so.
        const w = window as any;
        this.supportsAvif = w["chrome"] ? await this.avifSupported() : false;
    }

    private checkHighEntropy(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!(navigator as Navigator).userAgentData) {
                resolve();
                return;
            }

            this.hasUaData = true;
            const setHEBasedFlags = (heValues: UserAgentData_HighEntropyValues) => {
                if (this.performedHEChecks) {
                    return;
                }

                const model = heValues.model;
                const platform = heValues.platform;
                const platformVer = heValues.platformVersion;
                const uaVer = heValues.uaFullVersion;
                const bitness = heValues.bitness;

                // Mobile flag can be in either location, depending on age of browser.
                this.isMobileHE = heValues.mobile;
                this.isMobileUAD_HE = (navigator as Navigator).userAgentData!.mobile;

                // Check for spoofing.
                if (model == "" && platform == "" && platformVer == "" && uaVer == "") {
                    if ((this.isBrowserOperaTouch && this.isPlatAndroid) || this.isPlatTizen) {
                        // Opera on Android still fills-in the UserAgentData with empty values.
                        // Samsung's UWE(Upgraded Web Engine) instances also is in the same condition.
                    } else if (bitness == undefined) {
                        // Electron-based apps on Linux can leave bitness undefined.
                    } else {
                        this.isUaDataSpoofed = true;
                    }
                } else if (bitness == "") {
                    if (platform == "Android") {
                        // Android can still set bitness to an empty string, even when otherwise valid.
                    } else {
                        // On current Windows, Linux and macOS browsers, the built-in spoofing code clears
                        // bitness to an empty string.
                        // Non-spoofed scenarios leave bitness set to a valid string.
                        // Earlier browser engines and other platforms don't have bitness defined.
                        this.isUaDataSpoofed = true;
                    }
                }

                // Check models first, for Xbox
                if (model) {
                    if (model === "Xbox") {
                        this.isHEModelXbox = true;
                    } else if (model.startsWith("AFT")) {
                        // All Amazon FireTV devices start with AFT
                        // See https://developer.amazon.com/docs/fire-tv/identify-amazon-fire-tv-devices.html
                        // TODO: Remove certain non-Amazon vendors with embedded Fire TV products:
                        //       AFTHA001   - Toishiba
                        //       AFTWMST22  - JVC
                        //       AFTTIFF55  - Onida
                        //       AFTWI001   - ok
                        //       AFTDCT31   - Toshiba
                        //       AFTDCT31   - Insignia
                        //       AFTBAMR311 - Toshiba
                        //       AFTEAMR311 - Insignia
                        //       AFTKMST12  - Toshiba
                        //       AFTLE      - Onida
                        //       AFTEUFF014 - Grundig
                        //       AFTEU014   - Grundig
                        //       AFTSO001   - JVC
                        //       AFTMM      - Nebula
                        //       AFTEU011   - Grundig
                        //       AFTJMST12  - Insignia
                        //       AFTRS      - Element
                        //       AFTMM      - TCL
                        this.isHEModelFireTV = true;
                    }
                }

                // Check platforms as well
                if (platform === "Windows") {
                    this.isHEPlatformWindows = true;
                } else if (platform === "macOS") {
                    this.isHEPlatformMac = true;
                } else if (platform === "Chrome OS" || platform === "ChromeOS") {
                    this.isHEPlatformChromeOS = true;
                } else if (platform === "Chromium OS") {
                    this.isHEPlatformChromiumOS = true;
                } else if (platform === "Android") {
                    this.isHEPlatformAndroid = true;
                } else if (platform === "Linux") {
                    if (platformVer == "") {
                        // Android Chromium-based browsers using "desktop site" set platform to "Linux"
                        // and clear the platformVersion value.
                        // The platform version is unobtainable, but set the platform correctly.
                        this.isHEPlatformAndroid = true;
                        this.isHEPlatformOverridden = true;
                    } else {
                        this.isHEPlatformLinux = true;
                    }
                }

                // Record version information

                // It would be nice to use a regex that would only replace "odd" punctuation in
                // the midst of numeric strings (say "14_5_1").
                // One such as the following:
                //   /(?<!\p{L})(?<=\p{AHex})\p{P}(?=\p{AHex})(?!\p{L})/gu
                // which uses lookahead and lookbehind assertions, and Unicode property names.
                // But that requires:
                //  - a newer version of JavaScript than we transpile to
                //  - a newer version of Google's closure-compiler than we use
                //  - a new tsickle to support the newer closure-compile
                //  = a new gstsickle to use the new tsickle

                // So instead, use a simplistic "replace all punctuation" regex
                const sanitiseVer = (version: any) => {
                    if (!version || typeof version != "string") {
                        return undefined;
                    }
                    return version.replace(/[-._,:;|#@]/g, ".");
                };

                const deriveWindowsVer = (version?: string) => {
                    if (!version || !this.chromiumVersion || +this.chromiumVersion < 95) {
                        return version;
                    }

                    // Fomr Chromium 95 onwards, the platformVersion source has changed to be the
                    // UniversalApiContract version.  That changes every time Microsoft releases
                    // a new version of Windows.
                    // Older Windows versions (8.1 and earlier) are now reported as "0.0.0".
                    // Try to map the version to a 'real' Windows build version.
                    const verComponents: number[] = version.split(".").map(x => Number.parseInt(x));
                    switch (verComponents[0]) {
                        case 0:
                            return "8-"; // Windows 8.1 or earlier
                        case 1:
                            return "10.0.10240"; // Windows 10 1507 (10.0.10240.0)
                        case 2:
                            return "10.0.10586"; // Windows 10 1511 (10.0.10586.0)
                        case 3:
                            return "10.0.14393"; // Windows 10 1607 (10.0.14393.0)
                        case 4:
                            return "10.0.15063"; // Windows 10 1703 (10.0.15063.0)
                        case 5:
                            return "10.0.16299"; // Windows 10 1709 (10.0.16299.0)
                        case 6:
                            return "10.0.17134"; // Windows 10 1803 (10.0.17134.1)
                        case 7:
                            return "10.0.17763"; // Windows 10 1809 (10.0.17763.0)
                        case 8:
                            return "10.0.18362+"; // Windows 10 1903 (10.0.18362.0) or 10 1909 (10.0.18363.0)
                        case 9:
                            // Apparently not used.
                            return "10.0.9";
                        case 10:
                            return "10.0.19041+"; // Windows 10 2004 (10.0.19041.0) or newer Windows 10
                        case 11:
                            // Apparently not used.
                            return "10.0.11";
                        case 12:
                            // Apparently not used.
                            return "10.0.12";
                        case 13:
                            // Windows 11 preview
                            return "11.0.0";
                        case 14:
                            return "11.0.22000.0"; // Windows 11 21H2 (10.0.22000.0) or newer

                        default:
                            // Assume all newer versions are Windows 11 for now.
                            return "11.0.22000.0+";
                    }
                };

                const derivePlatformVer = (version?: string) => {
                    if (this.isHEPlatformWindows) {
                        if (!this.isHEModelXbox) {
                            return deriveWindowsVer(version);
                        }
                    }
                    return version;
                };

                // Check Brands information if present
                if (heValues.brands) {
                    this.internalCheckUserAgentData(heValues as UserAgentData);
                }

                this.browserFullVer = sanitiseVer(uaVer);
                this.osRawVersion = platformVer;
                this.osVersion = this.osVersion ?? derivePlatformVer(sanitiseVer(platformVer));

                this.performedHEChecks = true;
            };
            const handleHEValues = (heValues: UADataHEValues) => {
                if (!heValues) {
                    // Do not set any flags.  If this was a failure in the Worker,
                    // the fallback code will execute instead.
                    return;
                }

                if (this.heCheckTimer) {
                    clearTimeout(this.heCheckTimer);
                    this.heCheckTimer = INVALID_TIMER_ID;
                }
                setHEBasedFlags(heValues);
                resolve();
            };

            let heCheckWithWorker = false;
            let heCheckWithWorkerFailed = false;
            const workerName = "HE";

            const handleHEError = (error: MessageEvent | ErrorEvent | string) => {
                if (typeof error == "string") {
                    this.emitWorkerErrorStringTelemetry(workerName, error);
                } else if ("data" in error) {
                    // MessageEvent.  Message deserialization error on Worker instance in main thread.
                    this.emitWorkerMessageErrorTelemetry(workerName, error);
                } else {
                    // ErrorEvent.  Either onerror in WorkerGlobalScope, or onerror on Worker instance in main thread.
                    this.emitWorkerErrorTelemetry(workerName, error);
                }
                heCheckWithWorkerFailed = true;
                Log.e("{3c72abb}", "{217e106}", error);
            };

            let timeoutDelay = INSTANT_TIMEOUT;
            let worker: SharedWorker | undefined;
            try {
                if ((self as any)["SharedWorker"]) {
                    heCheckWithWorker = true;

                    worker = makeSharedWorkerFromInterface<UADataHEValues>(
                        HighEntropyWorker,
                        handleHEValues,
                        handleHEError
                    );

                    // Set the fallback timeout delay to be non-instant; this will call the fallback
                    // processing after a short timeout in case the Worker doesn't work.
                    // Use the longer fallback timeout to handle slower browsers; Edge seems to take
                    // longer when users are spoofing the User Agent Client Hints, Chrome is quicker.
                    timeoutDelay = LONG_FALLBACK_TIMEOUT;
                }
            } catch (ex) {
                heCheckWithWorkerFailed = true;
                Log.w("{3c72abb}", "{a583546}", ex);
                this.emitWorkerCreationErrorTelemetry(workerName, ex);
                // Couldn't get any HE data; leave at defaults
            }

            this.heCheckTimer = window.setTimeout(() => {
                stopWorker(worker);
                if (heCheckWithWorkerFailed) {
                    // SharedWorker creation failed
                    Log.w("{3c72abb}", "{dbf4b42}");
                } else if (heCheckWithWorker) {
                    // SharedWorker took too long
                    Log.w("{3c72abb}", "{0bf7a38}");
                    this.emitWorkerTimeoutTelemetry(workerName);
                    this.wereHeChecksLate = true;
                } else {
                    // No SharedWorker in use
                    Log.i("{3c72abb}", "{7a82b59}");
                    this.emitWorkerUnusedTelemetry(workerName);
                }
                new HighEntropyWorker().doWork().then((heValues: any) => {
                    handleHEValues(heValues);
                });
            }, timeoutDelay);
        });
    }

    private checkUserAgentVersion(): Promise<void> {
        return new Promise(resolve => {
            // TODO: Try to combine the two RegExp checks into one, still keeping it safe for all platforms.
            const chromeRe = /Chrome\/(([0-9]+\.)*[0-9]+)/;
            const uaString = navigator.userAgent;
            if (uaString) {
                const matches = uaString.match(chromeRe);
                this.chromeUaVersion = matches?.[1];
                if (this.chromeUaVersion) {
                    const majorVer = /Chrome\/([0-9]+)/;
                    const majorMatches = uaString.match(majorVer);
                    this.chromeUaMajorVer = majorMatches?.[1];
                }
            }

            resolve();
        });
    }
    private compareBrowserVersions(simpleVer: string, fullVer: string): boolean {
        if (simpleVer == fullVer) {
            // Simple version should be simpler than full version
            return false;
        }

        let re = /^(\d+)[.]?(.*)/;
        let simpleResult = re.exec(simpleVer);
        let fullResult = re.exec(fullVer);

        // The simple result should be the first part of the full result, or the same as the full result.
        // Discrepancies are an indication of spoofing.

        // RegExp.exec() can return null if the match fails.
        // Perform due-diligence checks for incompatibility.
        if (simpleResult == null) {
            if (fullResult == null) {
                // Assume both have non-numeric versions.
                return true;
            } else {
                return false;
            }
        } else {
            if (fullResult == null) {
                return false;
            } else {
                // Have both results, can compare them.

                if (simpleResult[1] == fullResult[1]) {
                    if (simpleResult[2] == "") {
                        // Normally-true case - simple version is just the major number.
                        return true;
                    } else {
                        // Full version information in the simple version is another indication of spoofing.
                        return false;
                    }
                }
            }
        }

        return false;
    }

    private validateChecks() {
        if (!this.hasUaData) {
            // Can't check against UserAgentData if it doesn't exist.
            return;
        }

        // Check for platform inconsistencies and indicate that spoofing has happened

        if (
            this.isPlatformMacIntel != this.isHEPlatformMac ||
            this.isPlatformWin != this.isHEPlatformWindows
        ) {
            this.isUaDataSpoofed = true;
        }

        // Many systems are based on Linux, but report a more specific platform through high entropy data
        // prettier-ignore
        if (
            (this.isHEPlatformChromeOS ||
             this.isHEPlatformChromiumOS ||
             this.isHEPlatformAndroid ||
             this.isHEPlatformLinux) &&
            !this.isPlatformLinux
        ) {
            this.isUaDataSpoofed = true;
        }

        if (
            this.browserVersion &&
            this.browserFullVer &&
            !this.compareBrowserVersions(this.browserVersion, this.browserFullVer)
        ) {
            if (this.isBrandOpera) {
                // Opera reports the Opera version with its brand.
                // Compare the Chromium version to see if there is spoofing.
                if (
                    this.chromiumVersion &&
                    !this.compareBrowserVersions(this.chromiumVersion, this.browserFullVer)
                ) {
                    this.isUaDataSpoofed = true;
                }
            } else {
                this.isUaDataSpoofed = true;
            }
        }

        if (
            (this.isHEPlatformChromeOS && !this.isBrandChrome) ||
            (this.isHEPlatformChromiumOS && !this.isBrandChromium)
        ) {
            // ChromeOS and ChromiumOS only have one browser each.
            // If the high-entropy data indicates the OS, the brand data should indicate the browser as well.

            this.isUaDataSpoofed = true;
        }

        // Check voice inconsistencies with high-entropy data.

        if (this.voiceIsChrome) {
            if (this.voiceIsMicrosoft && !this.isHEPlatformWindows) {
                // Chrome only has Microsoft voices on Windows.
                // Edge never has Chrome voices.
                this.isUaDataSpoofed = true;
            } else if (this.voiceIsApple && !this.isHEPlatformMac) {
                // Only get Apple voices on Mac or iOS/iPadOS - but don't get
                // UserAgentData on iOS/iPadOS.
                this.isUaDataSpoofed = true;
            }
        } else if (this.voiceIsChromeOS) {
            // Chrome OS voices must be from a Chrome OS platform.
            if (!this.isHEPlatformChromeOS) {
                this.isUaDataSpoofed = true;
            }
        } else if (this.voiceIsFirefox) {
            // Firefox doesn't support UserAgentData
            this.isUaDataSpoofed = true;
        } else if (this.voiceIsMicrosoft) {
            // Could be Edge on many different platforms
        } else if (this.voiceIsApple) {
            if (!this.isHEPlatformMac) {
                // Only get Apple voices on Mac or iOS/iPadOS - but don't get
                // UserAgentData on iOS/iPadOS.
                this.isUaDataSpoofed = true;
            }
        }

        this.useUaData = this.hasUaData && !this.isUaDataSpoofed;
        this.ignoreUaData = this.hasUaData && this.isUaDataSpoofed;
    }

    private createPlatformDetails() {
        this.determineResult();
        this.finish = performance.now();

        this.platformDetails.os = this.osName ?? PlatformName.UNKNOWN;
        this.platformDetails.browser = this.browserName ?? BrowserName.UNKNOWN;
        this.platformDetails.osRawVer = this.osRawVersion ?? UNKNOWN_VERSION;
        this.platformDetails.osVer = this.osVersion ?? UNKNOWN_VERSION;
        this.platformDetails.browserVer =
            this.browserVersion ??
            (this.osName === PlatformName.WEBOS ? this.chromeUaMajorVer : undefined) ??
            UNKNOWN_VERSION;
        this.platformDetails.browserFullVer =
            this.browserFullVer ??
            (this.osName === PlatformName.WEBOS ? this.chromeUaVersion : undefined) ??
            UNKNOWN_VERSION;
        this.platformDetails.confidence =
            this.confidenceLevel ??
            (this.osName === PlatformName.UNKNOWN ? 0 : undefined) ??
            (this.browserName === BrowserName.UNKNOWN ? 5 : undefined) ??
            (this.wereHeChecksLate ? 6 : undefined) ??
            (this.ignoreUaData ? 8 : undefined) ??
            10;
        this.platformDetails.forging = this.isForged ?? false;
        this.platformDetails.spoofing = this.ignoreUaData ?? false;

        this.platformDetails.vendor = this.deviceVendor ?? DeviceVendor.UNKNOWN;

        this.platformDetails.deviceOS = getDeviceOS(this.platformDetails.os);
        if (this.isGLSteamDeck) {
            this.platformDetails.deviceModel = DeviceModel.STEAMDECK;
        } else if (this.devModel) {
            this.platformDetails.deviceModel = this.devModel;
        }
        this.platformDetails.deviceType =
            this.devType ??
            getDeviceType(this.platformDetails.os, this.platformDetails.deviceModel);

        this.platformDetails.totalTime = this.finish - this.start;
    }

    private determineResult() {
        if (!this.hasGL && this.isBrowserChrome) {
            // No GL and Chromium-based -> Either spoofed, or a really poor platform.
            // Specifically, Edge on Xbox can spoof like this sometimes.
            this.osName = PlatformName.UNKNOWN;
            this.browserName = BrowserName.UNKNOWN;
            this.isForged = true;
            this.confidenceLevel = 3;
            this.osVersion = UNKNOWN_VERSION;
            this.browserVersion = UNKNOWN_VERSION;
            this.browserFullVer = UNKNOWN_VERSION;
            Log.d("{3c72abb}", "{049d298}");
        } else if (this.voiceIsChromeOS) {
            // Assume Chrome on ChromeOS, using Chrome Browser
            this.osName = PlatformName.CHROME_OS;
            this.setChromeDetails();
        } else if (this.voiceIsChrome) {
            // No Chrome voices except in Chrome browser, and not on Chrome OS
            this.setChromeDetails();
            if (this.voiceIsMicrosoft) {
                this.osName = PlatformName.WINDOWS;
            } else if (this.voiceIsApple) {
                this.osName = PlatformName.MAC;
                this.deviceVendor = DeviceVendor.APPLE;
            } else if (this.useUaData) {
                if (this.isHEPlatformWindows) {
                    this.osName = PlatformName.WINDOWS;
                } else if (this.isHEPlatformMac) {
                    this.osName = PlatformName.MAC;
                    this.deviceVendor = DeviceVendor.APPLE;
                } else if (this.isHEPlatformChromeOS || this.isHEPlatformChromiumOS) {
                    // Very unusual; ChromeOS should not have Chrome voices.
                    this.osName = PlatformName.CHROME_OS;
                    this.isForged = true;
                    this.confidenceLevel = 7;
                } else if (this.isHEPlatformLinux) {
                    // Chrome voices are not present on Android, therefore must be Linux.
                    this.osName = this.getCurrentLinuxOsName();
                }
            } else if (this.isPlatformWin) {
                this.osName = PlatformName.WINDOWS;
            } else if (this.isPlatformMacIntel) {
                this.osName = PlatformName.MAC;
                this.deviceVendor = DeviceVendor.APPLE;
            } else if (this.isPlatformLinux) {
                // Chrome voices are not present on Android, therefore must be Linux.
                this.osName = this.getCurrentLinuxOsName();
            } else if (this.isPlatformFreeBsdX86) {
                this.osName = PlatformName.FREEBSD;
            } else {
                // navigator.platform is not set correctly.
                this.osName = PlatformName.UNKNOWN;
                this.confidenceLevel = 5;
                Log.d("{3c72abb}", "{101497c}");
            }
        } else if (this.voiceIsFirefox) {
            // No Firefox voices except in Firefox browser
            this.setFirefoxDetails();
            if (this.voiceIsMicrosoft) {
                this.osName = PlatformName.WINDOWS;
            } else if (this.voiceIsApple) {
                // Firefox and Apple voices == macOS
                // Firefox on iOS uses Apple voices exclusively
                this.osName = PlatformName.MAC;
                this.deviceVendor = DeviceVendor.APPLE;
            } else if (this.voiceIsFirefoxAndroid) {
                this.osName = PlatformName.ANDROID;
                this.determineAndroidDeviceType();
            } else if (this.isPlatformLinux) {
                this.osName = this.getCurrentLinuxOsName();
            } else if (this.isPlatformFreeBsdX86) {
                this.osName = PlatformName.FREEBSD;
            } else {
                // Not Windows, macOS or Android, so unknown OS
                this.osName = PlatformName.UNKNOWN;
                this.confidenceLevel = 5;
                Log.d("{3c72abb}", "{49fcccb}");
            }
        } else if (this.voiceIsEdge) {
            this.browserName = BrowserName.EDGE;
            if (this.voiceIsWindows) {
                // Edge on Xbox has same voices as on Windows, so add Xbox-specific checks here.
                if ((this.useUaData && this.isHEModelXbox) || this.isGLXbox) {
                    this.osName = PlatformName.XBOX;
                    this.deviceVendor = DeviceVendor.MICROSOFT;
                    if (this.isGLXboxSeries) {
                        this.devModel = DeviceModel.XBOX_SERIES;
                    } else if (this.isGLXbox) {
                        this.devModel = DeviceModel.XBOX_ONE;
                    }
                } else {
                    this.osName = PlatformName.WINDOWS;
                }
            } else if (this.voiceIsApple) {
                // Edge and Apple voices == macOS
                // Edge on iOS uses Apple voices exclusively
                this.osName = PlatformName.MAC;
                this.deviceVendor = DeviceVendor.APPLE;
            } else if (this.voiceIsAndroid) {
                // Newer Edge on Android versions provide Edge voices.
                this.osName = PlatformName.ANDROID;
                this.determineAndroidDeviceType();
            } else if (this.useUaData) {
                if (this.isHEModelXbox) {
                    this.osName = PlatformName.XBOX;
                    this.deviceVendor = DeviceVendor.MICROSOFT;
                    if (this.isGLXboxSeries) {
                        this.devModel = DeviceModel.XBOX_SERIES;
                    } else if (this.isGLXbox) {
                        this.devModel = DeviceModel.XBOX_ONE;
                    }
                } else if (this.isHEPlatformWindows) {
                    this.osName = PlatformName.WINDOWS;
                } else if (this.isHEPlatformMac) {
                    this.osName = PlatformName.MAC;
                    this.deviceVendor = DeviceVendor.APPLE;
                } else if (this.isHEPlatformChromeOS || this.isHEPlatformChromiumOS) {
                    // Very unusual; ChromeOS should not have Edge voices.
                    this.osName = PlatformName.CHROME_OS;
                    this.isForged = true;
                    this.confidenceLevel = 7;
                } else if (this.isHEPlatformAndroid) {
                    // Newer Edge on Android versions provide Edge voices, so check for Android before Linux.
                    this.osName = PlatformName.ANDROID;
                    this.determineAndroidDeviceType();
                } else if (this.isHEPlatformLinux) {
                    this.osName = this.getCurrentLinuxOsName();
                }
            } else if (this.isPlatformWin) {
                this.osName = PlatformName.WINDOWS;
            } else if (this.isPlatformMacIntel) {
                this.osName = PlatformName.MAC;
                this.deviceVendor = DeviceVendor.APPLE;
            } else if (this.isPlatAndroid) {
                // Newer Edge on Android versions provide Edge voices, so check for Android before Linux.
                this.osName = PlatformName.ANDROID;
                this.determineAndroidDeviceType();
            } else if (this.isPlatformLinux) {
                this.osName = this.getCurrentLinuxOsName();
            } else if (this.isPlatformFreeBsdX86) {
                this.osName = PlatformName.FREEBSD;
            } else {
                // navigator.platform is not set correctly.
                this.osName = PlatformName.UNKNOWN;
                this.confidenceLevel = 5;
            }
        } else if (this.voiceIsMicrosoft) {
            // Not ChromeOS, Chrome, Edge or Firefox
            // Microsoft voices require a Microsoft product
            if (this.voiceIsWindows) {
                this.determineWindowsBrowser();
            } else {
                // Neither Windows nor Edge, so something's not right.
                // Assume Windows, and indicate forging.
                this.determineWindowsBrowser();
                this.isForged = true;
                this.confidenceLevel = 5;
            }
        } else if (this.voiceIsApple) {
            this.determineAppleBrowser();
        } else if (this.voiceIsAndroid) {
            this.determineAndroidBrowser();
        } else {
            // Try to detect without voice information
            if (
                (this.useUaData && (this.isHEModelXbox || this.isHEPlatformWindows)) ||
                this.isPlatformWin ||
                this.isGLXbox
            ) {
                this.determineWindowsBrowser();
            } else if (
                (this.useUaData && this.isHEPlatformMac) ||
                this.isPlatformiPad ||
                this.isPlatformiPhone ||
                this.isPlatformMacIntel
            ) {
                this.determineAppleBrowser();
            } else if (
                this.useUaData &&
                (this.isHEPlatformChromeOS || this.isHEPlatformChromiumOS)
            ) {
                this.osName = PlatformName.CHROME_OS;
                this.setChromeDetails();
            } else if (this.isPlatCcOS) {
                this.osName = PlatformName.CCOS;
                this.deviceVendor = DeviceVendor.HKMC;
                this.browserName = BrowserName.CHROMIUM;
            } else if ((this.useUaData && this.isHEPlatformAndroid) || this.isPlatAndroid) {
                this.determineAndroidBrowser();
            } else if (this.useUaData && this.isHEPlatformLinux) {
                this.determineLinuxBrowser();
            } else if (this.isPlatWebOS) {
                this.osName = PlatformName.WEBOS;
                this.deviceVendor = DeviceVendor.LG;
                // webOS TV uses the Blink-based LG browser engine, Chromium based
                // https://webostv.developer.lge.com/discover/specifications/web-engine/
                this.browserName = BrowserName.CHROMIUM;
            } else if (this.isPlatTizen) {
                this.osName = PlatformName.TIZEN;
                this.deviceVendor = DeviceVendor.SAMSUNG;
                this.browserName = BrowserName.SAMSUNG;
                if (window.webapis?.productinfo) {
                    this.osVersion = window.webapis.productinfo.getFirmware();
                    this.deviceName = window.webapis.productinfo.getRealModel();
                } else {
                    Log.e("{3c72abb}", "{844af47}");
                    this.osVersion = UNKNOWN_VERSION;
                    this.deviceName = "";
                }
                this.determineTizenUAInfo();
            } else if (this.isPlatformLinux) {
                // Linux or Linux-derived, based on navigator.platform.
                // More specific tests must come earlier than this.
                this.determineLinuxOrAndroidBrowser();
            } else if (this.isPlatformFreeBsdX86) {
                this.determineFreeBsdBrowser();
            } else {
                if (this.isBrowserChromeiOS || this.isBrowserFirefoxiOS || this.isBrowserEdgeiOS) {
                    // iOS browser, so iOS platform.
                    // Assume iPhone for detection.
                    this.isPlatformiPhone = true;
                    this.determineAppleBrowser();
                    this.confidenceLevel = 7;
                } else if (this.isBrowserSamsungChromium) {
                    // Samsung browser, so Android platform
                    this.determineAndroidBrowser();
                    this.confidenceLevel = 7;
                } else {
                    // TODO Map more browsers to their specific platforms (Opera Touch?)

                    // navigator.platform isn't set correctly (or is unknown).
                    this.osName = PlatformName.UNKNOWN;
                    this.browserName = BrowserName.UNKNOWN;
                    this.confidenceLevel = 0;
                    Log.d("{3c72abb}", "{f2eecc9}");
                }
            }
        }

        if (this.ignoreUaData) {
            // Clean up values when we know them to be invalid:.
            // If UserAgentData is present, then version information comes from it.
            // If, however, that data has been spoofed, re-write the versions to be unknown.
            this.osVersion = UNKNOWN_VERSION;
            this.browserVersion = UNKNOWN_VERSION;
            this.browserFullVer = UNKNOWN_VERSION;
        }
    }

    private determineLinuxOrAndroidBrowser() {
        if (this.isBrowserFirefoxTV) {
            // Don't use FIRETV until all callers understand it.
            //this.osName = PlatformName.FIRETV;
            this.osName = PlatformName.ANDROID;
            this.deviceVendor = DeviceVendor.AMAZON;
            this.setFirefoxDetails();
            this.devType = DeviceType.TV;
        } else if (this.isBrowserSamsungChromium) {
            // First-time detection after starting Samsung browser doesn't load voices correctly,
            // meaning we just know the platform is Linux or Linux-derived and hence come through
            // this code path.
            // However, we *do* detect Samsung browser, so can derive OS, vendor and browser.
            this.osName = PlatformName.ANDROID;
            this.deviceVendor = DeviceVendor.SAMSUNG;
            this.browserName = BrowserName.SAMSUNG;
            this.determineAndroidDeviceType();
        } else {
            if (!this.determineDesktopBrowser()) {
                // TODO Check for platform-specific browsers, and set accordingly, to cope with
                //      additional spoofing of navigator.platform
                // TODO More and better Android and (esp.) Linux browsers
                this.osName = PlatformName.UNKNOWN;
                this.browserName = BrowserName.UNKNOWN;
                this.confidenceLevel = 0;
                Log.d("{3c72abb}", "{be86380}");
            } else if (this.ignoreUaData) {
                // Found a browser, but the UserAgentData is spoofed.
                // Either the browser has implicitly spooofed, or the user has chosen to spoof the user agent.
                // If UserAgentData spoofing doesn't happen, we won't be entering this function at all
                // on modern browsers.
                // If we've successfully found a browser and there's spoofing, assume the platform is Linux.
                // Non-Linux platforms (that are derived from Linux) are detected prior to entering this code path.
                this.osName = this.getCurrentLinuxOsName();
            } else if (
                this.isPluginChromiumPDF &&
                !(this.isPluginChromePDF || this.isPluginEdgePDF)
            ) {
                // Likely an Electron-based app on Linux.
                // Real (older) Chromium will have been detected earlier, using brands.
                // Even older Chromium won't have brands, but will match this path as well.
                this.osName = this.getCurrentLinuxOsName();
                this.browserName = BrowserName.CHROMIUM;
                this.confidenceLevel = 7;
            } else if (this.isPlatformLinux) {
                // Should have checked Linux-derived OS before calling this function, so assume Linux.
                this.osName = PlatformName.LINUX;
            }
        }
    }

    private determineTizenUAInfo() {
        //UA: Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.5) AppleWebKit/537.36 (KHTML, like Gecko) 85.0.4183.93/6.5 TV Safari/537.36
        const browserTizenRe = /(([0-9]+\.)*[0-9]+)\/(([0-9]+\.)*[0-9]+)/;
        const uaString = navigator.userAgent;
        if (uaString) {
            // find first " DD.DD.../DD.DD... "
            const btMatch = uaString.match(browserTizenRe);
            // Match: ["85.0.4183.93/6.5", "85.0.4183.93", "4183.", "6.5", "6."]
            this.browserFullVer = btMatch?.[0];
            this.chromeUaVersion = btMatch?.[1];
            if (this.chromeUaVersion) {
                const majorVer = this.chromeUaVersion.split(".");
                this.chromeUaMajorVer = majorVer?.[0];
                this.browserVersion = this.chromeUaMajorVer;
            }
        }
    }

    private determineLinuxBrowser() {
        this.osName = this.getCurrentLinuxOsName();
        if (this.isMobileUAD || this.isMobileNavigator) {
            // Do not understand mobile Linux platforms well - that are not Android
            this.browserName = BrowserName.UNKNOWN;
            this.confidenceLevel = 3;
        } else if (!this.determineDesktopBrowser()) {
            this.browserName = BrowserName.UNKNOWN;
            this.confidenceLevel = 5;
            Log.d("{3c72abb}", "{54c6569}");
        }
    }

    private determineFreeBsdBrowser() {
        this.osName = PlatformName.FREEBSD;

        if (this.isBrowserChrome) {
            // Detect all Chromium-based browsers as Chrome for now
            this.browserName = BrowserName.CHROME;
        } else if (this.isBrowserNetscape) {
            this.setFirefoxDetails();
        } else {
            // TODO Check for platform-specific browsers, and set accordingly, to cope with
            //      additional spoofing of navigator.platform
            this.browserName = BrowserName.UNKNOWN;
            this.confidenceLevel = 3;
            Log.d("{3c72abb}", "{a2c16e4}");
        }
    }

    private determineAndroidBrowser() {
        this.osName = PlatformName.ANDROID;
        if (this.isBrowserNetscape) {
            this.setFirefoxDetails();
        } else if (this.isBrowserSamsungChromium) {
            this.browserName = BrowserName.SAMSUNG;
            this.deviceVendor = DeviceVendor.SAMSUNG;
        } else if (this.isBrowserBrave) {
            this.browserName = BrowserName.BRAVE;
        } else if (this.isBrowserYandex) {
            this.browserName = BrowserName.YANDEX;
        } else if (this.isBrowserOperaTouch) {
            this.browserName = BrowserName.OPERA;
        } else if (this.useUaData) {
            // Trust the UserAgentData brand information
            if (this.isBrandEdge) {
                this.browserName = BrowserName.EDGE;
            } else if (this.isBrandOpera) {
                this.browserName = BrowserName.OPERA;
            } else if (this.isBrandYandex) {
                this.browserName = BrowserName.YANDEX;
            } else if (this.isBrandChrome) {
                this.setChromeDetails();
            } else if (this.isBrandChromium) {
                if (this.isHEModelFireTV) {
                    // Don't use FIRETV until all callers understand it.
                    //this.osName = PlatformName.FIRETV;
                    this.deviceVendor = DeviceVendor.AMAZON;
                    this.browserName = BrowserName.SILK;
                } else {
                    this.browserName = BrowserName.CHROMIUM;
                }
            }
        } else if (this.isBrowserChrome) {
            if (!this.supportsAvif) {
                // Microsoft Edge or Amazon Silk
                if (this.isMobileNavigator) {
                    this.browserName = BrowserName.EDGE;
                } else {
                    this.browserName = BrowserName.SILK;
                }
            } else {
                // Detect all Chromium-based browsers as Chrome, since we don't have a specific
                // test for Chrome that excludes Chromium-based browsers
                this.setChromeDetails();
            }
        } else {
            // Detect as Chrome (since WebView is based on and provided by Chrome/Chromium)
            this.setChromeDetails();
            this.confidenceLevel = 7;
        }

        this.determineAndroidDeviceType();
    }

    private determineAndroidDeviceType() {
        // First, find 'true' Android DESKTOP situations
        if (!(this.mediaPrimaryHover && !this.mediaPrimaryFinePointer)) {
            if (this.isHEPlatformAndroid) {
                // Can use User Agent Client Hints checks for more detailed detection.

                if (this.isHEPlatformOverridden) {
                    // Android browser with desktop site.
                    if (!(this.mediaPrimaryNonHover && this.mediaPrimaryCoarsePointer)) {
                        // ChromeOS or other desktop-like system.  Not docked.
                        this.devType = DeviceType.DESKTOP;
                    }
                } else {
                    if (this.mediaPrimaryHover && this.mediaPrimaryFinePointer) {
                        // Likely mouse as primary controller, so probably real desktop/laptop, not docked.
                        this.devType = DeviceType.DESKTOP;
                    }
                }
            } else {
                // Firefox and other browsers that don't support User Agent Client Hints.

                if (!(this.mediaPrimaryNonHover && this.mediaPrimaryCoarsePointer)) {
                    this.devType = DeviceType.DESKTOP;
                }
            }
        } else {
            // Hovering but no fine control, so not mouse controlled.
            // Assume mobile device.
        }

        // Make sure to use a default of PHONE for Android systems.
        if (!this.devType) {
            // Detect Android tablets by those devices with a "smallest width" of 600 dp units.
            // For browsers on Android, the screen.width and screen.height are in dp units.
            const shortest = screen.width < screen.height ? screen.width : screen.height;
            this.devType = shortest >= 600 ? DeviceType.TABLET : DeviceType.PHONE;
        }
    }

    private determineWindowsBrowser() {
        this.osName = PlatformName.WINDOWS;
        if (!this.determineDesktopBrowser()) {
            // TODO Check for platform-specific browsers, and set accordingly, to cope with
            //      additional spoofing of navigator.platform
            this.browserName = BrowserName.UNKNOWN;
            this.confidenceLevel = 5;
            Log.d("{3c72abb}", "{44ce1e0}");
        }
    }

    private determineAppleBrowser() {
        // The three Apple platform names (iPad, iPhone and MacIntel) can be used on different devices
        // iPad -     used on iPad   devices when requesting Mobile sites (in Chrome, Safari, Edge)
        // iPhone -   used on iPhone devices when requesting Mobile sites (in Safar)i
        //            used on iPhone devices in all cases (Firefox, Brave, Opera Touch, Chrome)
        // MacIntel - used on macOS  devices always
        //          - used on iPad   devices when requesting Desktop sites (in Safari, Chrome, Edge)
        //          - used on iPad   devices always (in Brave, Firefox, Opera Touch)
        //
        // The default user agent is more accurate, but could be spoofed (including from another platform)

        // TODO Check for platform-specific browsers, and set accordingly, to cope with
        //      additional spoofing of navigator.platform

        this.deviceVendor = DeviceVendor.APPLE;

        if (this.useUaData && this.isHEPlatformMac) {
            // So far, only macOS browsers have the high-entropy user agent data.
            this.osName = PlatformName.MAC;
            if (!this.determineDesktopBrowser()) {
                this.browserName = BrowserName.UNKNOWN;
                this.confidenceLevel = 5;
                Log.d("{3c72abb}", "{b80b712}");
            }
        } else if (this.isPlatformMacIntel) {
            if (this.isMobileNavigator) {
                this.osName = PlatformName.IPADOS;
                this.determineIOSBrowser();
            } else {
                this.osName = PlatformName.MAC;
                if (!this.determineDesktopBrowser()) {
                    this.browserName = BrowserName.UNKNOWN;
                    this.confidenceLevel = 5;
                    Log.d("{3c72abb}", "{0f3296f}");
                }
            }
        } else if (this.isPlatformiPhone) {
            this.osName = PlatformName.IOS;
            this.determineIOSBrowser();
        } else if (this.isPlatformiPad) {
            this.osName = PlatformName.IPADOS;
            this.determineIOSBrowser();
        } else {
            // Apple, so iOS/iPadOS/macOS.
            // But not normal navigator.platform, so assume forging.
            this.isForged = true;
            this.confidenceLevel = 5;
            if (this.isMobileNavigator) {
                // Guess at iOS (not iPadOS)
                this.osName = PlatformName.IOS;
                this.determineIOSBrowser();
                Log.d("{3c72abb}", "{cf1c572}");
            } else {
                this.osName = PlatformName.MAC;
                this.browserName = BrowserName.UNKNOWN;
                Log.d("{3c72abb}", "{1eb1815}");
            }
        }
    }

    private getCurrentLinuxOsName(): PlatformName {
        // Steam deck appears almost exactly the same as Linux. The only real difference is he GL renderer,
        // which is a Steam Deck-specific AMD Ryzen SKU
        if (this.isGLSteamDeck) {
            return PlatformName.STEAMOS;
        } else {
            return PlatformName.LINUX;
        }
    }

    private setIOSVersion() {
        if (this.supportsBigInt) {
            if (this.supportsIntlDisplayNames) {
                if (this.supportsBigInt64Array) {
                    if (this.supportsSharedWorker) {
                        this.osVersion = "16+";
                    } else if (this.supportsGpuBuffer) {
                        // The Ellen voice was reemoved in 15.4 and is still missing in 15.5
                        // It was restored in 15.6
                        // Use the presence of that voice to distinguish between 15.5 and 15.6
                        if (this.voiceIsAppleEllen) {
                            this.osVersion = "15.6+";
                        } else {
                            this.osVersion = "15.5+";
                        }
                    } else if (this.supportsBroadcastChannel) {
                        this.osVersion = "15.4+";
                    } else if (this.supportsWebAssemblyExceptions) {
                        this.osVersion = "15.2+";
                    } else if (this.supportsPerformanceNavigationTiming) {
                        this.osVersion = "15.1+";
                    } else {
                        this.osVersion = "15+";
                    }
                } else {
                    this.osVersion = "14.5+";
                }
            } else {
                if (this.supportsMediaRecorder) {
                    this.osVersion = "14.3+";
                } else {
                    this.osVersion = "14+";
                }
            }
        } else {
            // Don't really support pre-14 versions, so only minimal check here.
            if (this.supportsDecodingInfo) {
                this.osVersion = "13+";
            } else {
                this.osVersion = "12-";
            }
        }
    }

    private determineIOSBrowser() {
        this.setIOSVersion();

        const osVersion = this.osVersion!;

        // iOS versions that we support (14+) should support the Credential object in their WebKit engines.
        // Browsers that don't have this object are not Safari (possibly Stadium, Cloudy, iCab, Lunascape, etc.).
        const missingCredential =
            !this.supportsCredential && !(osVersion.startsWith("12") || osVersion.startsWith("13"));

        // iOS versions 16 and up should support the window.browser.runtime object in the WebKit engines.
        // Browsers missing this object are not Safari.
        const missingSafariExtension =
            !this.supportsBrowserRuntime &&
            !(
                osVersion.startsWith("12") ||
                osVersion.startsWith("13") ||
                osVersion.startsWith("14") ||
                osVersion.startsWith("15")
            );

        if (this.isBrowserFirefoxiOS) {
            this.browserName = BrowserName.FIREFOX;
        } else if (this.isBrowserOperaiOS) {
            this.browserName = BrowserName.OPERA;
        } else if (this.isBrowserBrave) {
            this.browserName = BrowserName.BRAVE;
        } else if (this.isBrowserYandex) {
            this.browserName = BrowserName.YANDEX;
        } else if (this.isBrowserEdgeiOS) {
            this.browserName = BrowserName.EDGE;
        } else if (this.isBrowserChromeiOS) {
            this.browserName = BrowserName.CHROME;
        } else if (this.supportsApplePay && !missingCredential && !missingSafariExtension) {
            this.browserName = BrowserName.SAFARI;
        } else if (this.isBrowserReactBased) {
            // Lunascape is one browser like this
            this.browserName = BrowserName.REACT;
        } else {
            if (missingCredential) {
                // Not known, but with more confidence than just generically Unknown.
                // Possibly someone using a browser to spoof the user agent for access to
                // non-iOS games.
                this.browserName = BrowserName.UNKNOWN;
                this.confidenceLevel = 7;
                Log.d("{3c72abb}", "{5a39f60}");
            } else {
                // WebKit-based browser (since MacIntel and mobile).
                // Detect as Safari?  Or as Unknown?
                this.browserName = BrowserName.UNKNOWN;
                this.confidenceLevel = 5;
                Log.d("{3c72abb}", "{147d6b4}");
            }
        }
    }

    private setFirefoxDetails() {
        this.browserName = BrowserName.FIREFOX;
        if (this.supportsIntlCollationOptions) {
            if (this.supportsIntlDisplayNames) {
                if (this.supportsBeforeInputEvent) {
                    if (this.supportsAbortSignalAbort) {
                        if (!this.supportsDeviceLightEvent) {
                            this.browserVersion = "89+";
                        } else {
                            this.browserVersion = "88";
                        }
                    } else {
                        this.browserVersion = "87";
                    }
                } else {
                    this.browserVersion = "86";
                }
            } else {
                this.browserVersion = "85";
            }
        } else {
            this.browserVersion = "84-";
        }
    }

    private setChromeDetails() {
        this.browserName = BrowserName.CHROME;
        if (this.browserVersion) {
            return;
        }

        // Set browser version from feature detection.
        if (this.supportsLargestContentfulPaint) {
            if (this.supportsCSSregisterProperty) {
                if (this.supportsXR) {
                    if (this.supportsGetInstalledRelatedApps) {
                        if (this.supportsIntlDisplayNames) {
                            if (this.supportsBarcodeDetector) {
                                if (this.supportsWakeLock) {
                                    if (this.supportsPromiseAny) {
                                        if (this.supportsFileSystemHandle) {
                                            if (this.supportsAtomicsWaitAsync) {
                                                if (this.supportsCSSaspectRatio) {
                                                    if (this.supportsWebHID) {
                                                        if (this.supportsOverflowClip) {
                                                            // From Chrome 90, we should be able to use
                                                            // User Agent Client Hints instead of feature detection.
                                                            this.browserVersion = "90+";
                                                        } else {
                                                            this.browserVersion = "89";
                                                        }
                                                    } else {
                                                        this.browserVersion = "88";
                                                    }
                                                } else {
                                                    this.browserVersion = "87";
                                                }
                                            } else {
                                                this.browserVersion = "86";
                                            }
                                        } else {
                                            this.browserVersion = "85";
                                        }
                                    } else {
                                        this.browserVersion = "84";
                                    }
                                } else {
                                    this.browserVersion = "83";
                                }
                            } else {
                                // NB - there's no Chrome 82 to detect.
                                this.browserVersion = "81";
                            }
                        } else {
                            this.browserVersion = "80";
                        }
                    } else {
                        this.browserVersion = "79";
                    }
                } else {
                    this.browserVersion = "78";
                }
            } else {
                this.browserVersion = "77";
            }
        }
    }

    private determineDesktopBrowser(): boolean {
        if (this.isBrowserNetscape) {
            this.setFirefoxDetails();
        } else if (this.isBrowserOpera) {
            this.browserName = BrowserName.OPERA;
        } else if (this.isBrowserBrave) {
            this.browserName = BrowserName.BRAVE;
        } else if (this.isBrowserYandex) {
            this.browserName = BrowserName.YANDEX;
        } else if (this.isBrowserChrome) {
            // Check easy derivations of Chrome before these deeper checks.
            if (this.isGLXbox) {
                this.osName = PlatformName.XBOX;
                this.deviceVendor = DeviceVendor.MICROSOFT;
                this.browserName = BrowserName.EDGE;
                if (this.isGLXboxSeries) {
                    this.devModel = DeviceModel.XBOX_SERIES;
                } else if (this.isGLXbox) {
                    this.devModel = DeviceModel.XBOX_ONE;
                }
            } else if (this.useUaData) {
                if (this.isHEModelXbox) {
                    this.osName = PlatformName.XBOX;
                    this.deviceVendor = DeviceVendor.MICROSOFT;
                    this.browserName = BrowserName.EDGE;
                } else if (this.isBrandEdge) {
                    this.browserName = BrowserName.EDGE;
                } else if (this.isBrandOpera) {
                    this.browserName = BrowserName.OPERA;
                } else if (this.isBrandYandex) {
                    this.browserName = BrowserName.YANDEX;
                } else if (this.isBrandChrome) {
                    this.setChromeDetails();
                } else if (this.isBrandChromium && !this.isBrandUnknown) {
                    // Chromium is often the basis of other browsers, so don't be confident that we have Chromium
                    // if there are other brands as well.
                    this.browserName = BrowserName.CHROMIUM;
                }
            } else if (!this.supportsAvif) {
                // Edge currently does not support AVIF images, but otherwise looks like Chrome.
                // Note: Old versions of Chrome also do not support AVIF, nor does old Chromium.

                // All modern Chrome, Edge and Chromium (at least) support
                // all PDF plugins (Microsoft Edge PDF Viewer, Chrome PDF Viewer and
                // Chromium PDF Viewer).  Therefore, cannot distinguish so assume
                // current worst-case of Edge.
                this.browserName = BrowserName.EDGE;

                // Could be Linux, macOS, Windows, or Xbox Series S/X (early alpha skip-ahead builds of Edge on Xbox).
                // If we already know the OS, use that, else report Unknown.
                this.osName = this.osName ?? PlatformName.UNKNOWN;
                this.confidenceLevel = 7;
            } else {
                // Potentially reduced confidence if we go through this code path.
                if (this.useUaData && this.isBrandChromium) {
                    // Chromium-based, possibly some other brand as well.
                    // Definitely not Chrome or well-known derivative of Chromium, however.
                    this.browserName = BrowserName.CHROMIUM;
                    // Also, not spoofed so safe to set confidence level.
                    this.confidenceLevel = 9;
                } else if (this.voiceWasKnown) {
                    // Voice was known, but not Chrome or Edge.
                    // Not a known Chromium-derivative, such as Brave or Opera.
                    // High confidence that this is actual Chromium.
                    this.browserName = BrowserName.CHROMIUM;
                } else {
                    // Detect all Chrome-based browsers as Chromium, since we don't have a specific
                    // test for Chrome that excludes Chromium-based browsers (other than by brand
                    // which has already been checked or is spoofed, or by voice).
                    this.browserName = BrowserName.CHROMIUM;
                    this.confidenceLevel = 7;
                }
            }
        } else if (this.isBrowserSafari || this.supportsApplePay) {
            this.browserName = BrowserName.SAFARI;
        } else if (this.isBrowserEdgeLegacy) {
            if (this.isGLXbox) {
                this.osName = PlatformName.XBOX;
                this.deviceVendor = DeviceVendor.MICROSOFT;
                this.browserName = BrowserName.EDGE_LEGACY;
            } else {
                this.browserName = BrowserName.EDGE_LEGACY;
            }
        } else {
            // Not detected.
            return false;
        }

        return true;
    }

    // Run a callback after the initial batch of startup code has run.
    private runAfterStartup(callback: Function) {
        window.setTimeout(callback, INSTANT_TIMEOUT);
    }

    public setTelemetryHandler(telemetry: TelemetryHandler) {
        this.telemetry?.emitDebugEvent("TelemetryHandlerChanged");
        this.telemetry = telemetry;
        for (const queuedEvent of this.queuedTelemetry) {
            const name = queuedEvent.name;
            const data = queuedEvent.data;
            const type = queuedEvent.type;
            switch (type) {
                case WorkerTelemetryType.ERROR_EVENT:
                    this.emitWorkerErrorTelemetry(name, data);
                    break;
                case WorkerTelemetryType.MESSAGE_EVENT:
                    this.emitWorkerMessageErrorTelemetry(name, data);
                    break;
                case WorkerTelemetryType.UNUSED:
                    this.emitWorkerUnusedTelemetry(name);
                    break;
                case WorkerTelemetryType.TIMEOUT:
                    this.emitWorkerTimeoutTelemetry(name);
                    break;
                case WorkerTelemetryType.CREATE_FAILURE:
                    this.emitWorkerCreationErrorTelemetry(name, data);
                    break;
                case WorkerTelemetryType.ERROR_STRING:
                    this.emitWorkerErrorStringTelemetry(name, data);
                    break;
                case WorkerTelemetryType.OVERLONG_DELAY:
                    this.emitWorkerOverlongTelemetry(name, data);
                    break;
            }
        }
        this.queuedTelemetry = [];
    }

    // Emit telemetry events, or queue until a listener has been registerd.
    private emitWorkerErrorTelemetry(name: string, data: ErrorEvent) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent(
                "WorkerProblem",
                name,
                `${data.message} in ${data.filename}@${data.lineno}:${data.colno}`,
                JSON.stringify(data.error)
            );
        } else {
            this.queuedTelemetry.push({
                type: WorkerTelemetryType.ERROR_EVENT,
                name: name,
                data: data
            });
        }
    }

    private emitWorkerErrorStringTelemetry(name: string, data: string) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent("WebWorkerProblem", name, data);
        } else {
            this.queuedTelemetry.push({
                type: WorkerTelemetryType.ERROR_STRING,
                name: name,
                data: data
            });
        }
    }

    private emitWorkerMessageErrorTelemetry(name: string, data: MessageEvent) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent("WorkerMessageProblem", JSON.stringify(data));
        } else {
            this.queuedTelemetry.push({
                type: WorkerTelemetryType.MESSAGE_EVENT,
                name: name,
                data: data
            });
        }
    }

    private emitWorkerUnusedTelemetry(name: string) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent("WorkerUnused", name);
        } else {
            this.queuedTelemetry.push({ type: WorkerTelemetryType.UNUSED, name: name });
        }
    }

    private emitWorkerTimeoutTelemetry(name: string) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent("WorkerTimeout", name);
        } else {
            this.queuedTelemetry.push({ type: WorkerTelemetryType.TIMEOUT, name: name });
        }
    }

    private emitWorkerCreationErrorTelemetry(name: string, exception: any) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent(
                "WorkerCreateFailure",
                name,
                exception?.stack ?? "",
                JSON.stringify(exception)
            );
        } else {
            this.queuedTelemetry.push({
                type: WorkerTelemetryType.CREATE_FAILURE,
                name: name,
                data: exception
            });
        }
    }

    private emitWorkerOverlongTelemetry(name: string, delay: number) {
        if (this.telemetry) {
            this.telemetry.emitDebugEvent("WorkerOverlongDelay", name, delay.toString());
        } else {
            this.queuedTelemetry.push({
                type: WorkerTelemetryType.OVERLONG_DELAY,
                name: name,
                data: delay
            });
        }
    }
}

class GLWorker implements DoWork<GlDebugInfo> {
    constructor() {}

    private getGLDebugInfo(): GlDebugInfo {
        return this.glDebugInfo(this.createCanvas());
    }

    private createCanvas(): OffscreenCanvas | HTMLCanvasElement {
        const offscreenCanvasConstructor = self["OffscreenCanvas"];
        if (offscreenCanvasConstructor) {
            return new OffscreenCanvas(1, 1);
        } else {
            return document.createElement("canvas");
        }
    }

    private glDebugInfo(canvas: OffscreenCanvas | HTMLCanvasElement): GlDebugInfo {
        const glCtx = canvas.getContext("webgl");
        const glDbgExt = glCtx && (glCtx as any)["getExtension"]("WEBGL_debug_renderer_info");
        return glDbgExt
            ? {
                  vendorName:
                      glCtx && (glCtx as any)["getParameter"](glDbgExt.UNMASKED_VENDOR_WEBGL),
                  rendererName:
                      glCtx && (glCtx as any)["getParameter"](glDbgExt.UNMASKED_RENDERER_WEBGL),
                  present: true
              }
            : {
                  vendorName: "",
                  rendererName: "",
                  present: false
              };
    }

    doWork(): GlDebugInfo {
        return this.getGLDebugInfo();
    }
}

class HighEntropyWorker implements DoWork<UADataHEValues> {
    constructor() {}

    doWork(): Promise<UADataHEValues> {
        const uad: UserAgentData | undefined = (navigator as Navigator).userAgentData;

        if (!uad) {
            return Promise.resolve(undefined);
        }

        return uad
            .getHighEntropyValues([
                "platform",
                "architecture",
                "uaFullVersion",
                "platformVersion",
                "model",
                "bitness"
            ])
            .then((heValues: UADataHEValues) => {
                if (!heValues) {
                    heValues = {};
                }

                if (!heValues.brands) {
                    heValues.brands = uad.brands;
                }

                if (!heValues.mobile) {
                    heValues.mobile = uad.mobile;
                }

                return heValues;
            });
    }
}

// External API

const platformImplSingleton = new Platform();

// Callers can cache the result of this API to re-use after calling it once on page load
export function GetPlatformDetails(): Promise<PlatformDetails> {
    performance.mark("platformBegin");
    return platformImplSingleton.detectPlatformDetails();
}

// TODO: Remove this alias once clients stop using it.
export const getPlatformDetails = GetPlatformDetails;

export function AddPlatformTelemetry(telemetry: TelemetryHandler) {
    platformImplSingleton.setTelemetryHandler(telemetry);
}
