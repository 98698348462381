//@todo how to eliminate this piece of code in production ?

import { GridServer, GsInitParams, Log } from "../dependencies";

const LOGTAG = "mockpmgridserver";

// these will be useless data in production build
export class MockPMGridServer extends GridServer {
    constructor() {
        super();
        this.overrideSignallingInfo = false; // Only http support as supported in this semi passthru mode.
    }

    initialize(initParams: GsInitParams) {
        super.initialize(initParams);
        this.authRequestFunc = this.generateAuthToken;
        this.protocol = "http://";
    }

    generateAuthToken() {
        // Fake auth token.
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                Log.d("{3660ed9}", "{d80a6e8}");
                resolve("JARVIS auth =testauthToken");
            }, 0);
        });
    }
}
