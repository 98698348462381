import {
    IEventEmitter,
    TelemetryHttpEvent,
    TelemetryEventPayload,
    GSModuleName,
    GdprLevel,
    HTTPVerb,
    IPVersion,
    GS_HttpCallResultDef,
    GS_ExceptionInfoDef,
    GS_DebugInfoDef,
    GS_ClientMetricEventDef,
    GS_Sleep_EventDef,
    GS_FeatureDef,
    FeatureSupportStatus,
    GetHexString,
    Log
} from "../dependencies";
import {
    Connectivity,
    EventName,
    getRagnarokExceptionEvent,
    getRagnarokDebugEvent,
    getClientMetricEvent,
    getRagnarokHttpEvent,
    getRagnarokGamepadEvent,
    getRagnarokSleepEvent,
    RagnarokLaunchEvent
} from "./analytics";
import { RErrorCode } from "../rerrorcode";
import {
    GfnPcTelemetryConfig,
    RagnarokTelemetryConfig,
    Streamer_StartDef,
    Streamer_InputDeviceDef,
    InputDeviceType,
    StreamExitEventData
} from "./telemetryinterfaces";
import { EVENTS } from "../interfaces";
import { TelemetryEventProcessor } from "./telemetryeventprocessor";
import { RagnarokSettings } from "../util/settings";
import { GetCodecType, ToBooleanType, ToResumeType } from "../util/utils";
import { clientShutDownCallbackType } from "../interfaces";
import { NetworkDetector } from "../util/networkdetector";
const LOGTAG = "telemetryhandler";
export class TelemetryHandler {
    private sessionId: string = "";
    private subSessionId: string = "";
    private cmsId: string = "";
    private eventEmitter: IEventEmitter;
    private totalGamepadEventsCount: number = 0;
    private telemetryEventProcessor: TelemetryEventProcessor;
    private exceptionCounts: Map<string, number> = new Map<string, number>();
    private totalExceptionCount: number = 0;
    private clientShutDownCallback?: clientShutDownCallbackType;

    constructor(_eventEmitter: IEventEmitter, telemetryEventProcessor: TelemetryEventProcessor) {
        this.eventEmitter = _eventEmitter;
        this.telemetryEventProcessor = telemetryEventProcessor;
        this.telemetryEventProcessor.setExceptionHandler(this.emitExceptionEvent.bind(this));
    }

    public setClientShutDownCallback(clientShutDownCallback?: clientShutDownCallbackType) {
        this.clientShutDownCallback = clientShutDownCallback;
    }

    public dispatchEvent(event: TelemetryEventPayload) {
        if (this.eventEmitter.hasListener(EVENTS.TELEMETRY_EVENT)) {
            // Emit the event to the upper layer
            this.eventEmitter.emit(EVENTS.TELEMETRY_EVENT, event);
        } else {
            this.telemetryEventProcessor.sendTelemetryEvent(event);
        }
    }

    public emitLaunchEvent(
        sessionId: string,
        subSessionId: string,
        isResume: boolean,
        zoneAddress: string,
        launchDuration: number,
        result: string,
        codec: string,
        cmsId: string,
        networkSessionId: string,
        sessionSetupFailed?: boolean
    ) {
        if (RagnarokSettings.useUITelemetry) {
            const launchAnalyticsEvent: RagnarokLaunchEvent = {
                name: EventName.RAGNAROK_LAUNCH_EVENT,
                gdprLevel: GdprLevel.Functional,
                parameters: {
                    result: result,
                    sessionId: sessionId,
                    subSessionId: subSessionId,
                    zoneAddress: zoneAddress,
                    launchDuration: launchDuration,
                    isResume: ToBooleanType(isResume),
                    codec: codec,
                    cmsId: cmsId,
                    osName: "",
                    osVersion: "",
                    gameShortName: "",
                    streamingProfileGuid: this.telemetryEventProcessor.getStreamingProfileGuid(),
                    systemInfoGuid: this.telemetryEventProcessor.getSystemInfoGuid(),
                    networkSessionId: networkSessionId
                },
                ts: new Date().toISOString(),
                result: result,
                sessionId: sessionId,
                subSessionId: subSessionId,
                zoneAddress: zoneAddress,
                launchDuration: launchDuration,
                isResume: ToBooleanType(isResume),
                codec: codec,
                cmsId: cmsId
            };
            this.dispatchEvent({
                name: launchAnalyticsEvent.name,
                gdprLevel: launchAnalyticsEvent.gdprLevel,
                parameters: launchAnalyticsEvent.parameters,
                ts: launchAnalyticsEvent.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
        // DO not emit the split schema Streamer_Start event for session setup failures
        if (RagnarokSettings.useSplitSchema && !sessionSetupFailed) {
            const launchAnalyticsEvent: Streamer_StartDef = new Streamer_StartDef({
                zoneAddress: zoneAddress,
                networkSessionId: networkSessionId,
                sessionId: sessionId,
                subSessionId: subSessionId,
                resumeType: ToResumeType(isResume),
                overrideConfigType: RagnarokSettings.remoteOverrideInfo.type,
                overrideConfigVersion: RagnarokSettings.remoteOverrideInfo.version,
                result: result,
                codec: GetCodecType(codec),
                ipVersion: IPVersion.UNKNOWN,
                launchDuration: launchDuration,
                networkType: NetworkDetector.getNetworkType(),
                streamingProfileGuid: this.telemetryEventProcessor.getStreamingProfileGuid(),
                systemInfoGuid: this.telemetryEventProcessor.getSystemInfoGuid(),
                cmsId: cmsId
            });
            this.dispatchEvent({
                name: launchAnalyticsEvent.name,
                gdprLevel: launchAnalyticsEvent.gdprLevel,
                parameters: launchAnalyticsEvent.parameters,
                ts: launchAnalyticsEvent.ts,
                clientConfig: RagnarokTelemetryConfig
            });
        }
    }

    /**
     * Exit event handling follows the below precedence:
     * 1. If the client has registered for ShutDownCallback, we call it with the eventPayload
     * 2. If ShutDownCallback not available or fails to send the evnet, send the Exit event using beacon API
     */
    private processExitEvent(eventPayload: TelemetryEventPayload, isLegacyEvent: boolean) {
        let eventSent = false;
        if (this.clientShutDownCallback) {
            eventSent = this.clientShutDownCallback(eventPayload);
            Log.d("{9838627}", "{caf81d9}", eventSent);
        }
        if (!eventSent) {
            Log.d("{9838627}", "{e939db1}");
            this.telemetryEventProcessor.sendExitEvent(eventPayload, isLegacyEvent);
        }
    }

    public sendExitAnalyticsEvent(exitEventData: StreamExitEventData, pollingDone: boolean) {
        let eventPayload: TelemetryEventPayload;
        if (RagnarokSettings.useUITelemetry) {
            eventPayload =
                this.telemetryEventProcessor.getRagnarokStreamExitEventPayload(exitEventData);
            this.processExitEvent(eventPayload, true);
        }
        // DO not emit the split schema Streamer_Exit event for session setup failures
        if (RagnarokSettings.useSplitSchema && !exitEventData.sessionSetupFailed) {
            eventPayload = this.telemetryEventProcessor.getStreamerExitEventPayload(exitEventData);
            this.processExitEvent(eventPayload, false);
        }
        this.telemetryEventProcessor.clearExitEventStore(pollingDone);
    }

    // We cache the exit events periodically. If the client has opted to handle exit events (registered clientShutdownCallback),
    // we should relay these back to the client. If not we send them from within
    public sendCachedExitEvent(pollingDone: boolean): Promise<void> {
        if (this.clientShutDownCallback) {
            Log.d("{9838627}", "{c7dcc4e}");
            return new Promise<void>(() => {
                this.telemetryEventProcessor.getCachedExitEvents().then(exitEventPayloads => {
                    for (const payload of exitEventPayloads) {
                        this.processExitEvent(
                            payload,
                            payload.clientConfig.clientId == GfnPcTelemetryConfig.clientId
                        );
                    }
                    this.telemetryEventProcessor.clearExitEventStore(pollingDone);
                });
            });
        } else {
            Log.d("{9838627}", "{77df598}");
            return this.telemetryEventProcessor.sendCachedExitEvent(pollingDone);
        }
    }

    public emitExceptionEvent(
        error: Error | DOMException | undefined,
        msg: string,
        file: string,
        lineno: number,
        colno: number,
        handled: boolean,
        category?: string
    ) {
        if (!this.canSendExceptionEvent(msg)) {
            return;
        }
        if (error instanceof DOMException) {
            error = { name: error.name, message: error.message };
        }

        if (RagnarokSettings.useSplitSchema) {
            const event = new GS_ExceptionInfoDef({
                filename: file,
                lineno: lineno,
                stacktrace: "",
                colno: colno,
                handled: ToBooleanType(handled),
                category: category ?? "",
                message: msg,
                moduleName: GSModuleName.RAGNAROK,
                sessionId: this.sessionId,
                subSessionId: this.subSessionId
            });
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const event = getRagnarokExceptionEvent(
                this.sessionId,
                this.subSessionId,
                msg,
                error?.stack ?? "",
                file,
                lineno,
                colno,
                handled,
                category
            );
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public emitHttpEvent(event: TelemetryHttpEvent, fromGridServer?: boolean) {
        // If the event is received from gridserver it is legacy schema event, don't send it for RAGNAROK module
        // /sa gridApp.onGsTelemetryHttpEvent
        if (!fromGridServer && RagnarokSettings.useSplitSchema) {
            const gsHttpEvent: GS_HttpCallResultDef = new GS_HttpCallResultDef({
                callDuration: event.callDuration,
                verb: <HTTPVerb>event.verb,
                sessionId: event.sessionId,
                subSessionId: event.subSessionId,
                serverId: event.serverId,
                url: event.url,
                overrideConfigType: RagnarokSettings.remoteOverrideInfo.type,
                overrideConfigVersion: RagnarokSettings.remoteOverrideInfo.version,
                requestStatusCode: event.statusCode,
                requestId: event.requestId,
                networkType: NetworkDetector.getNetworkType(),
                statusCode: event.statusCode,
                cmsId: String(this.cmsId),
                moduleName: GSModuleName.RAGNAROK
            });
            this.dispatchEvent({
                name: gsHttpEvent.name,
                gdprLevel: gsHttpEvent.gdprLevel,
                parameters: gsHttpEvent.parameters,
                ts: gsHttpEvent.ts,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const httpEvent = getRagnarokHttpEvent(
                event.url,
                event.verb,
                event.statusCode,
                event.requestStatusCode,
                event.sessionId,
                event.subSessionId,
                event.requestId,
                event.serverId,
                event.callDuration
            );
            this.dispatchEvent({
                name: httpEvent.name,
                gdprLevel: httpEvent.gdprLevel,
                parameters: httpEvent.parameters,
                ts: httpEvent.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public emitGsFeatureEvent(
        featureName: string,
        supported: boolean,
        defaultEnabled: boolean,
        enabled: boolean,
        reason: string
    ) {
        // Don't check for split schema enablement. This event is only available in the new schema
        const event: GS_FeatureDef = new GS_FeatureDef({
            featureName: featureName,
            supported: supported
                ? FeatureSupportStatus.SUPPORTED
                : FeatureSupportStatus.UNSUPPORTED,
            defaultEnabled: ToBooleanType(defaultEnabled),
            enabled: ToBooleanType(enabled),
            reason: reason,
            moduleName: GSModuleName.RAGNAROK,
            networkType: NetworkDetector.getNetworkType(),
            overrideConfigType: RagnarokSettings.remoteOverrideInfo.type,
            overrideConfigVersion: RagnarokSettings.remoteOverrideInfo.version,
            sessionId: this.sessionId,
            subSessionId: this.subSessionId
        });
        this.dispatchEvent({
            name: event.name,
            gdprLevel: event.gdprLevel,
            ts: event.ts,
            parameters: event.parameters,
            clientConfig: RagnarokTelemetryConfig
        });
    }

    public emitDebugEvent(
        key1?: string,
        key2?: string,
        key3?: string,
        key4?: string,
        key5?: string,
        sessionId?: string,
        subSessionId?: string,
        fromGridServer?: boolean
    ) {
        if (!fromGridServer && RagnarokSettings.useSplitSchema) {
            const event: GS_DebugInfoDef = new GS_DebugInfoDef({
                key1: key1 ?? "",
                key2: key2 ?? "",
                key3: key3 ?? "",
                key4: key4 ?? "",
                key5: key5 ?? "",
                moduleName: GSModuleName.RAGNAROK,
                networkType: NetworkDetector.getNetworkType(),
                overrideConfigType: RagnarokSettings.remoteOverrideInfo.type,
                overrideConfigVersion: RagnarokSettings.remoteOverrideInfo.version,
                cmsId: String(this.cmsId),
                sessionId: this.sessionId,
                subSessionId: this.subSessionId
            });
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                ts: event.ts,
                parameters: event.parameters,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const event = getRagnarokDebugEvent(
                sessionId ?? this.sessionId,
                subSessionId ?? this.subSessionId,
                key1,
                key2,
                key3,
                key4,
                key5
            );
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public emitMetricEvent(
        metricName: string,
        valueString: string,
        valueDouble: number,
        valueInt1: number,
        valueInt2: number,
        valueInt3: number
    ) {
        if (RagnarokSettings.useSplitSchema) {
            const event: GS_ClientMetricEventDef = new GS_ClientMetricEventDef({
                metricName: metricName,
                moduleName: GSModuleName.RAGNAROK,
                valueInt1: valueInt1,
                valueInt2: valueInt2,
                valueInt3: valueInt3,
                valueString: valueString,
                valueDouble: valueDouble,
                networkType: NetworkDetector.getNetworkType(),
                cmsId: String(this.cmsId),
                sessionId: this.sessionId,
                subSessionId: this.subSessionId
            });
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                ts: event.ts,
                parameters: event.parameters,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const event = getClientMetricEvent(
                this.sessionId,
                this.subSessionId,
                metricName,
                valueString,
                valueDouble,
                valueInt1,
                valueInt2,
                valueInt3
            );
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public emitGamepadEvent(
        gamepadName: string,
        vid: string,
        pid: string,
        index: number,
        hapticsSupported: boolean,
        hapticsFeedbackCount: number,
        primary: boolean,
        state: number,
        eventMap: string
    ) {
        const MAX_GAMEPAD_EVENTS_TOTAL: number = 50;

        if (this.totalGamepadEventsCount >= MAX_GAMEPAD_EVENTS_TOTAL) {
            return;
        }
        this.totalGamepadEventsCount++;
        if (RagnarokSettings.useSplitSchema) {
            const event: Streamer_InputDeviceDef = new Streamer_InputDeviceDef({
                deviceName: gamepadName,
                deviceType: InputDeviceType.GAMEPAD,
                vendorId: vid,
                productId: pid,
                deviceIndex: index,
                reportIndex: primary ? 0 : index,
                hapticsSupported: ToBooleanType(hapticsSupported),
                hapticsFeedbackCount: hapticsFeedbackCount,
                state: state,
                eventMapReceived: eventMap,
                eventMapProcessed: eventMap,
                sessionId: this.sessionId,
                subSessionId: this.subSessionId,
                cmsId: String(this.cmsId)
            });
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                ts: event.ts,
                parameters: event.parameters,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const event = getRagnarokGamepadEvent(
                this.sessionId,
                this.subSessionId,
                gamepadName,
                vid,
                pid,
                index,
                hapticsSupported,
                hapticsFeedbackCount,
                primary,
                state,
                eventMap
            );
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public emitSleepEvent(
        sleepTime: number,
        timeToSleep: number,
        sleepSequence: string,
        error: string,
        sessionId: string,
        subSessionId: string
    ) {
        if (RagnarokSettings.useSplitSchema) {
            const event: GS_Sleep_EventDef = new GS_Sleep_EventDef({
                eventSequence: sleepSequence,
                sleepTime: sleepTime,
                error: error,
                timeToSleep: timeToSleep,
                moduleName: GSModuleName.RAGNAROK,
                cmsId: String(this.cmsId),
                sessionId: sessionId,
                subSessionId: subSessionId
            });
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                ts: event.ts,
                parameters: event.parameters,
                clientConfig: RagnarokTelemetryConfig
            });
        }
        if (RagnarokSettings.useUITelemetry) {
            const event = getRagnarokSleepEvent(
                sessionId,
                subSessionId,
                error,
                sleepTime,
                timeToSleep,
                sleepSequence
            );
            this.dispatchEvent({
                name: event.name,
                gdprLevel: event.gdprLevel,
                parameters: event.parameters,
                ts: event.ts!,
                clientConfig: GfnPcTelemetryConfig
            });
        }
    }

    public setSessionId(sessionId: string) {
        this.sessionId = sessionId;
        this.totalGamepadEventsCount = 0;
    }

    public setSubSessionId(subSessionId: string) {
        this.subSessionId = subSessionId;
        this.exceptionCounts.clear();
        this.totalExceptionCount = 0;
    }

    public setGameDetails(cmsId: string, name: string) {
        this.cmsId = cmsId;
        this.telemetryEventProcessor.setGameDetails(cmsId, name);
    }

    private canSendExceptionEvent(message: string) {
        const MAX_EXCEPTIONS_PER_MSG: number = 10;
        const MAX_EXCEPTIONS_TOTAL: number = 50;

        if (this.totalExceptionCount >= MAX_EXCEPTIONS_TOTAL) {
            return false;
        }
        const count = this.exceptionCounts.get(message) ?? 0;
        if (count >= MAX_EXCEPTIONS_PER_MSG) {
            return false;
        }
        this.totalExceptionCount++;
        this.exceptionCounts.set(message, count + 1);
        return true;
    }
}
