import { ErrorDetails, getRErrorDetails, Log } from "../dependencies";

const LOGTAG = "indexdb";

export const enum IndexDbErrorCode {
    //DbCommon Errors
    DbNotYetOpen = 0x01,
    DbClosing = 0x02,

    //DbOpen Errors
    IndexDbObjectNotFound = 0x11,
    DbOpenfailed = 0x12,
    DbOpenBlocked = 0x13,
    DbExceptionInOpen = 0x14,

    //Db Get Errors
    DbGetFailed = 0x20,
    DbExceptionInGet = 0x21,

    //Db Put Errors
    DbPutFailed = 0x30,
    DbExceptionInPut = 0x31,

    //Db Delete Errors
    DbDeleteFailed = 0x40,
    DbExceptionInDelete = 0x41,

    // Db clear Errors
    DbClearFailed = 0x50,
    DbExceptionInClear = 0x51,

    // Db GetAll Errors
    DbGetAllFailed = 0x60,
    DbExceptionInGetAll = 0x61
}

export interface StoreDetails {
    storeName: string;
    storeOptions: IDBObjectStoreParameters;
    storeIndexName: string;
}

export class IndexedDb {
    private indexedDb: IDBFactory = window["indexedDB"];
    private version: number = 2;
    private name: string;
    private storeDetails: StoreDetails[];
    private dbInstance?: IDBDatabase;
    private isDbClosing: boolean = false;
    private didDbCloseUnexpectedly: boolean = false;

    constructor(dbName: string, storeDetails: StoreDetails[]) {
        this.name = dbName;
        this.storeDetails = storeDetails;
    }

    public open(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                if (!!this.indexedDb) {
                    let request: IDBOpenDBRequest = this.indexedDb.open(this.name, this.version);
                    request.onerror = (event: Event) => {
                        reject(
                            getRErrorDetails(
                                IndexDbErrorCode.DbOpenfailed,
                                `${this.name} db opening failed`,
                                request.error
                            )
                        );
                    };

                    request.onsuccess = (event: Event) => {
                        this.createDbInstance(request.result);
                        resolve();
                    };

                    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                        Log.i("{10dc2cc}", "{1f70767}", event.oldVersion, event.newVersion);
                        this.createDbInstance(request.result);
                        // @todo make sure createObjectStore is called with store name that doesn't exist. So possibly copy data and delete if it exists
                        // and write in new store but that change is only needed when the upgrade is done in future.
                        const createObjectStoreFunc = (storeDetails: StoreDetails) => {
                            const objectStore = this.dbInstance?.createObjectStore(
                                storeDetails.storeName,
                                storeDetails.storeOptions
                            );
                            if (storeDetails.storeOptions.keyPath) {
                                objectStore?.createIndex(
                                    storeDetails.storeIndexName,
                                    storeDetails.storeOptions.keyPath,
                                    {
                                        unique: true
                                    }
                                );
                            }
                        };
                        switch (event.oldVersion) {
                            case 0:
                                createObjectStoreFunc(this.storeDetails[0]);
                            case 1:
                                createObjectStoreFunc(this.storeDetails[1]);
                                break;
                        }
                    };

                    request.onblocked = (event: Event) => {
                        reject(
                            getRErrorDetails(
                                IndexDbErrorCode.DbOpenBlocked,
                                `${this.name} db blocked during opening`,
                                request.error
                            )
                        );
                    };
                } else {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.IndexDbObjectNotFound,
                            "Indexdb object not found"
                        )
                    );
                }
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInOpen,
                        `Unexpected Exception in open`,
                        err
                    )
                );
            }
        });
    }

    private getDbStateError(): ErrorDetails | undefined {
        if (!this.indexedDb) {
            return getRErrorDetails(
                IndexDbErrorCode.IndexDbObjectNotFound,
                "Indexdb object not found"
            );
        } else if (!this.dbInstance) {
            return getRErrorDetails(IndexDbErrorCode.DbNotYetOpen, "Db not yet open");
        } else if (this.isDbClosing) {
            const isVisibilityStateHidden = document.visibilityState === "hidden";
            return getRErrorDetails(
                IndexDbErrorCode.DbClosing,
                "Db is closing, unexpectedly: " +
                    this.didDbCloseUnexpectedly +
                    ", visibility state hidden: " +
                    isVisibilityStateHidden
            );
        }
        return undefined;
    }

    public get(storeName: string, key: string[]): Promise<any> {
        const error = this.getDbStateError();
        if (error) {
            return Promise.reject(error);
        }
        return new Promise<any>((resolve, reject) => {
            try {
                const tx = this.dbInstance!.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);

                tx.oncomplete = event => {
                    Log.i("{10dc2cc}", "{82638ba}");
                };

                const queriedObject = store.get(key);
                queriedObject.onsuccess = () => {
                    resolve(queriedObject.result);
                };
                queriedObject.onerror = event => {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.DbGetFailed,
                            `get method failed`,
                            tx.error
                        )
                    );
                };
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInGet,
                        `Unexpected Exception happened in get`,
                        err
                    )
                );
            }
        });
    }

    public set(storeName: string, data: any): Promise<void> {
        const error = this.getDbStateError();
        if (error) {
            return Promise.reject(error);
        }
        return new Promise((resolve, reject) => {
            try {
                const tx = this.dbInstance!.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                tx.oncomplete = event => {
                    Log.i("{10dc2cc}", "{f993875}");
                };

                const result = store.put(data);
                result.onsuccess = () => {
                    resolve();
                };
                result.onerror = event => {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.DbPutFailed,
                            `put method failed`,
                            tx.error
                        )
                    );
                };
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInPut,
                        `Unexpected Exception happened in set`,
                        err
                    )
                );
            }
        });
    }

    public delete(storeName: string, key: string[]): Promise<void> {
        const error = this.getDbStateError();
        if (error) {
            return Promise.reject(error);
        }
        return new Promise<void>((resolve, reject) => {
            try {
                const tx = this.dbInstance!.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                tx.oncomplete = event => {
                    Log.i("{10dc2cc}", "{bc32bd7}");
                };

                const result = store.delete(key);
                result.onsuccess = () => {
                    resolve();
                };
                result.onerror = event => {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.DbDeleteFailed,
                            `delete method failed`,
                            tx.error
                        )
                    );
                };
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInDelete,
                        `Unexpected Exception in delete`,
                        err
                    )
                );
            }
        });
    }

    public clear(storeName: string): Promise<void> {
        const error = this.getDbStateError();
        if (error) {
            return Promise.reject(error);
        }
        return new Promise<void>((resolve, reject) => {
            try {
                const tx = this.dbInstance!.transaction(storeName, "readwrite");
                const store = tx.objectStore(storeName);

                tx.oncomplete = event => {
                    Log.i("{10dc2cc}", "{30f23d1}");
                };

                const result = store.clear();
                result.onsuccess = () => {
                    resolve();
                };
                result.onerror = event => {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.DbClearFailed,
                            `clear method failed)`,
                            tx.error
                        )
                    );
                };
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInClear,
                        `Unexpected Exception happened in clear`,
                        err
                    )
                );
            }
        });
    }

    public getAll(storeName: string): Promise<any[]> {
        const error = this.getDbStateError();
        if (error) {
            return Promise.reject(error);
        }
        return new Promise<any[]>((resolve, reject) => {
            try {
                const tx = this.dbInstance!.transaction(storeName, "readonly");
                const store = tx.objectStore(storeName);

                tx.oncomplete = event => {
                    Log.i("{10dc2cc}", "{59d1f78}");
                };

                const queriedObject = store.getAll();
                queriedObject.onsuccess = () => {
                    resolve(queriedObject.result);
                };
                queriedObject.onerror = event => {
                    reject(
                        getRErrorDetails(
                            IndexDbErrorCode.DbGetAllFailed,
                            `getAll method failed`,
                            tx.error
                        )
                    );
                };
            } catch (err) {
                reject(
                    getRErrorDetails(
                        IndexDbErrorCode.DbExceptionInGetAll,
                        `Unexpected Exception in getAll`,
                        err
                    )
                );
            }
        });
    }

    public close() {
        this.isDbClosing = true;
        this.dbInstance?.close();
    }

    private createDbInstance(instance: IDBDatabase) {
        this.isDbClosing = false;
        this.didDbCloseUnexpectedly = false;
        this.dbInstance = instance;
        this.dbInstance.onversionchange = (event: Event) => {
            Log.w("{10dc2cc}", "{65276d8}");
            this.close();
            this.dbInstance = undefined;
        };
        this.dbInstance.onclose = (event: Event) => {
            Log.w("{10dc2cc}", "{342f7f8}");
            this.isDbClosing = true;
            this.didDbCloseUnexpectedly = true;
        };
    }
}
