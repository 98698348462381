import { RagnarokProfiler } from "../ragnarokprofiler";
import { StatsType, KeyDeprecatedStats } from "./statsinterfaces";
import { VideoFrameMetadata } from "../internalinterfaces";
import { setUint64 } from "../util/utils";
import { statsConfig } from "./webrtcbinarystats";

// https://www.w3.org/TR/webrtc-stats/#dom-rtcinboundrtpstreamstats
export declare interface RTCInboundRtpStreamExtraStats extends RTCInboundRtpStreamStats {
    trackIdentifier: string;
    kind: "video" | "audio";
    mid?: string;
    remoteId?: string;
    framesDecoded?: number;
    keyFramesDecoded?: number;
    framesRendered?: number;
    framesDropped?: number;
    frameWidth?: number;
    frameHeight?: number;
    framesPerSecond?: number;
    qpSum?: number;
    totalDecodeTime?: number;
    totalInterFrameDelay?: number;
    totalSquaredInterFrameDelay?: number;
    pauseCount?: number;
    totalPausesDuration?: number;
    freezeCount?: number;
    totalFreezesDuration?: number;
    lastPacketReceivedTimestamp?: DOMHighResTimeStamp;
    headerBytesReceived?: number;
    packetsDiscarded?: number;
    fecPacketsReceived?: number;
    fecPacketsDiscarded?: number;
    bytesReceived?: number;
    nackCount?: number;
    firCount?: number;
    pliCount?: number;
    totalProcessingDelay?: number;
    estimatedPlayoutTimestamp?: DOMHighResTimeStamp;
    jitterBufferDelay?: number;
    jitterBufferTargetDelay?: number;
    jitterBufferEmittedCount?: number;
    jitterBufferMinimumDelay?: number;
    totalSamplesReceived?: number;
    concealedSamples?: number;
    silentConcealedSamples?: number;
    concealmentEvents?: number;
    insertedSamplesForDeceleration?: number;
    removedSamplesForAcceleration?: number;
    audioLevel?: number;
    totalAudioEnergy?: number;
    totalSamplesDuration?: number;
    framesReceived?: number;
    decoderImplementation?: string;
    playoutId?: string;
    powerEfficientDecoder?: boolean;
    framesAssembledFromMultiplePackets?: number;
    totalAssemblyTime?: number;

    // Non-standard
    perFrameEntries?: string;
}

declare interface VideoPerFrameEntry {
    frameNumber: number;
    decodeTime: number;
    assemblyTime: number;
}

export class BinaryReport {
    private videoPacketsLost: number = 0;
    private videoFrameDecodeTimeAvgMs: number = 0;
    private prevVideoPacketsLost: number = 0;
    private prevVideoFramesDecoded: number = 0;
    private prevVideoDecodeTimeTotalMs: number = 0;
    private framesDecoded: number = 0;
    private pliCount: number = 0;
    private prevPliCount: number = 0;
    private framesDropped: number = 0;
    private prevFramesDropped: number = 0;
    private rvfcStatsCache: ArrayBuffer[] = new Array();
    /**
     * Each call to getStats does not guarantee the new decoded/assembly frames stats get returned,
     * so maintain a tracker here to track the latest frame recorded so far.
     * It is for comparing frame numbers and only take frames with larger frame numbers so that we know which frames are newly updated.
     */
    private lastPfdaFrameNumber: number = -1;

    constructor(private readonly rvfcStatsCacheLimit: number = 120) {}

    private getTimeLapsedSinceStreamBegin(value: number | undefined): number {
        if (value !== undefined) {
            return value - RagnarokProfiler.getStreamBeginTime();
        } else {
            return 0;
        }
    }

    private getRelativeTimestamp(): number {
        // We used to use WebRTC report timestamps, but they don't follow the spec on safari and aren't monotonic on any
        // platform. Generate our own timestamp instead. Internally, most stats use a single timestamp taken at the
        // start of getStats so it isn't very useful anyway. At most, the timestamp will be off by the time it took for
        // the getStats Promise to resolve
        return RagnarokProfiler.getStreamTime();
    }

    public sendTrackStats(track: RTCInboundRtpStreamExtraStats) {
        if (track.kind === "audio") {
            this.sendAudioTrack(track);
        } else {
            this.sendVideoTrack(track);
        }
    }

    private sendAudioTrack(audioTrack: RTCInboundRtpStreamExtraStats) {
        const buffer = new ArrayBuffer(statsConfig.traa.size);
        const view = new DataView(buffer);
        view.setFloat64(0, getVal(audioTrack.audioLevel), true);
        setUint64(getVal(audioTrack.concealedSamples), view, 8, true);
        setUint64(getVal(audioTrack.concealmentEvents), view, 16, true);
        setUint64(getVal(audioTrack.insertedSamplesForDeceleration), view, 24, true);
        view.setFloat64(32, getVal(audioTrack.jitterBufferDelay), true);
        setUint64(getVal(audioTrack.jitterBufferEmittedCount), view, 40, true);
        setUint64(getVal(audioTrack.removedSamplesForAcceleration), view, 48, true);
        setUint64(getVal(audioTrack.silentConcealedSamples), view, 56, true);
        view.setFloat64(64, getVal(audioTrack.totalSamplesReceived), true);
        view.setFloat64(72, getVal(audioTrack.totalSamplesDuration), true);
        view.setFloat64(80, this.getRelativeTimestamp(), true);
        RagnarokProfiler.addStatsReport([buffer], StatsType.TRAA);
    }

    private sendVideoTrack(videoTrack: RTCInboundRtpStreamExtraStats) {
        const recentFramesDropped = getVal(videoTrack.framesDropped);
        if (recentFramesDropped >= this.prevFramesDropped) {
            this.framesDropped = recentFramesDropped - this.prevFramesDropped;
            this.prevFramesDropped = recentFramesDropped;
        }

        const buffer = new ArrayBuffer(statsConfig.trav.size);
        const view = new DataView(buffer);
        view.setUint32(0, getVal(videoTrack.framesDecoded), true);
        view.setUint32(4, recentFramesDropped, true);
        view.setUint32(8, getVal(videoTrack.frameHeight), true);
        view.setUint32(12, getVal(videoTrack.frameWidth), true);
        view.setUint32(16, getVal(videoTrack.framesReceived), true);
        view.setFloat64(20, getVal(videoTrack.jitterBufferDelay), true);
        setUint64(getVal(videoTrack.jitterBufferEmittedCount), view, 28, true);
        view.setFloat64(36, this.getRelativeTimestamp(), true);
        RagnarokProfiler.addStatsReport([buffer], StatsType.TRAV);
    }

    public sendInboundRtpStats(rtp: RTCInboundRtpStreamExtraStats) {
        if (rtp.kind === "audio") {
            this.sendAudioRtp(rtp);
        } else {
            this.sendVideoRtp(rtp);
            this.sendVideoRtpPerFrame(rtp);
        }
    }

    private sendAudioRtp(audioRtp: RTCInboundRtpStreamExtraStats) {
        const buffer = new ArrayBuffer(statsConfig.rtpa.size);
        const view = new DataView(buffer);
        setUint64(getVal(audioRtp.packetsReceived), view, 0, true);
        setUint64(getVal(audioRtp.bytesReceived), view, 8, true);
        setUint64(getVal(audioRtp.packetsLost), view, 16, true);
        view.setFloat64(24, getVal(audioRtp.lastPacketReceivedTimestamp), true);
        view.setFloat64(32, getVal(audioRtp.jitter), true);
        view.setFloat64(40, this.getRelativeTimestamp(), true);
        RagnarokProfiler.addStatsReport([buffer], StatsType.RTPA);
    }

    public getPacketsLost(): number {
        return this.videoPacketsLost;
    }

    public getDecodeTimeAvgMs(): number {
        return Math.round(this.videoFrameDecodeTimeAvgMs);
    }

    public getFramesDecoded(): number {
        return this.framesDecoded;
    }

    public getPliCount(): number {
        return this.pliCount;
    }

    public getFramesDropped(): number {
        return this.framesDropped;
    }

    private updateClientAppFeedbackStats(
        recentPacketsLost: number,
        recentFramesDecoded: number,
        recentTotalDecodeTimeMs: number,
        recentPliCount: number
    ) {
        if (recentPacketsLost >= this.prevVideoPacketsLost) {
            this.videoPacketsLost = recentPacketsLost - this.prevVideoPacketsLost;
            this.prevVideoPacketsLost = recentPacketsLost;
        }

        if (recentPliCount >= this.prevPliCount) {
            this.pliCount = recentPliCount - this.prevPliCount;
            this.prevPliCount = recentPliCount;
        }

        this.framesDecoded = recentFramesDecoded - this.prevVideoFramesDecoded;
        // Repeat old value if no frames are decoded.
        // Very congested decoder will result in PLIs causing decoder going idle during recovery.
        if (this.framesDecoded > 0 && recentTotalDecodeTimeMs > this.prevVideoDecodeTimeTotalMs) {
            this.videoFrameDecodeTimeAvgMs =
                ((recentTotalDecodeTimeMs - this.prevVideoDecodeTimeTotalMs) * 1000) /
                this.framesDecoded;
            this.prevVideoFramesDecoded = recentFramesDecoded;
            this.prevVideoDecodeTimeTotalMs = recentTotalDecodeTimeMs;
        }
    }

    private sendVideoRtp(videoRtp: RTCInboundRtpStreamExtraStats) {
        const packetsLost = getVal(videoRtp.packetsLost);
        const framesDecoded = getVal(videoRtp.framesDecoded);
        const totalDecodeTimeMs = getVal(videoRtp.totalDecodeTime);
        const pliCount = getVal(videoRtp.pliCount);
        this.updateClientAppFeedbackStats(packetsLost, framesDecoded, totalDecodeTimeMs, pliCount);

        const buffer = new ArrayBuffer(statsConfig.rtpv.size);
        const view = new DataView(buffer);
        view.setUint32(0, framesDecoded, true);
        view.setUint32(4, getVal(videoRtp.keyFramesDecoded), true);
        view.setUint32(8, getVal(videoRtp.nackCount), true);
        view.setInt32(12, packetsLost, true);
        view.setInt32(16, pliCount, true);
        setUint64(getVal(videoRtp.bytesReceived), view, 20, true);
        setUint64(getVal(videoRtp.packetsReceived), view, 28, true);
        view.setFloat64(36, totalDecodeTimeMs, true);
        view.setFloat64(44, getVal(videoRtp.totalInterFrameDelay), true);
        view.setFloat64(52, getVal(videoRtp.totalSquaredInterFrameDelay), true);
        view.setFloat64(60, getVal(videoRtp.totalAssemblyTime), true);
        view.setUint32(68, getVal(videoRtp.framesAssembledFromMultiplePackets), true);
        view.setFloat64(72, this.getRelativeTimestamp(), true);
        RagnarokProfiler.addStatsReport([buffer], StatsType.RTPV);
    }

    private sendVideoRtpPerFrame(videoRtp: RTCInboundRtpStreamExtraStats) {
        if (videoRtp.perFrameEntries) {
            // Per frame entries will be available for custom chrome build.
            // It is mainly for recording per frame decode and assembly time.
            // Each call to getStats will return at maximum 128 frame entries,
            // and these subroutine will forward decode and assembly data per entry to the
            // server and qosweb.
            const entries: VideoPerFrameEntry[] = JSON.parse(videoRtp.perFrameEntries);
            const report: ArrayBuffer[] = [];
            let tempPfdaFrameNumber = this.lastPfdaFrameNumber;
            for (let i = 0; i < entries.length; i++) {
                if (entries[i].frameNumber > this.lastPfdaFrameNumber) {
                    const buffer = new ArrayBuffer(statsConfig.pfda.size);
                    const dataView = new DataView(buffer);
                    dataView.setUint32(0, entries[i].frameNumber, true);
                    dataView.setFloat64(4, entries[i].decodeTime, true);
                    dataView.setFloat64(12, entries[i].assemblyTime, true);
                    report.push(buffer);
                }
                tempPfdaFrameNumber = Math.max(tempPfdaFrameNumber, entries[i].frameNumber);
            }
            this.lastPfdaFrameNumber = tempPfdaFrameNumber;
            if (report.length > 0) {
                RagnarokProfiler.addStatsReport(report, StatsType.PFDA);
            }
        }
    }

    public sendDeprStats(deprecatedStats: KeyDeprecatedStats) {
        // Ex: 789244794,-15,-15,-10,-10,-1,-15,-15,432205915,432205926,432205927,432205929,432205929,0,1
        const array = deprecatedStats.timingFrameInfo.split(",", 15);
        if (array.length < 15) {
            return;
        }

        const buffer = new ArrayBuffer(statsConfig.depr.size);
        const view = new DataView(buffer);
        view.setUint32(0, getVal(deprecatedStats.targetDelayMs), true);
        view.setUint32(4, getVal(deprecatedStats.minPlayoutDelayMs), true);
        view.setUint32(8, getVal(deprecatedStats.currentDelayMs), true);
        view.setFloat64(12, this.getRelativeTimestamp(), true);
        view.setFloat64(20, getVal(parseFloat(array[3])), true);
        view.setFloat64(28, getVal(parseFloat(array[4])), true);
        view.setFloat64(36, getVal(parseFloat(array[8])), true);
        view.setFloat64(44, getVal(parseFloat(array[9])), true);
        view.setFloat64(52, getVal(parseFloat(array[10])), true);
        view.setFloat64(60, getVal(parseFloat(array[11])), true);
        view.setUint8(68, getVal(parseFloat(array[13])));
        view.setUint8(69, getVal(parseFloat(array[14])));
        RagnarokProfiler.addStatsReport([buffer], StatsType.DEPR);
    }

    public sendVideoFrameMetadata(cbtimestamp: DOMHighResTimeStamp, metadata: VideoFrameMetadata) {
        const recvTimeMs = metadata.receiveTime ?? 0;
        const presentTimeMs = metadata.presentationTime - recvTimeMs;
        const processTimeMs = (metadata.processingDuration ?? 0) * 1000;
        const timeStamp = this.getTimeLapsedSinceStreamBegin(metadata.presentationTime);

        const buffer = new ArrayBuffer(statsConfig.vfmd.size);
        const view = new DataView(buffer);
        view.setFloat64(0, timeStamp, true);
        view.setUint16(8, Math.min(presentTimeMs * 100, 0xffff), true);
        view.setUint16(10, Math.min(processTimeMs * 100, 0xffff), true);
        this.rvfcStatsCache.push(buffer);
        // Posting message to Webworker for every frame might be causing issues.
        // We cache these per-frame stats here and send them in burts of rvfcStatsCacheLimit
        // Note that the Webrtc stats are cached again in webworker and are uploaded every 5 seconds
        if (this.rvfcStatsCache.length == this.rvfcStatsCacheLimit) {
            this.sendCachedVfmdStats();
        }
    }

    public sendCachedVfmdStats() {
        if (this.rvfcStatsCache.length > 0) {
            RagnarokProfiler.addStatsReport(this.rvfcStatsCache, StatsType.VFMD);
            this.rvfcStatsCache = [];
        }
    }
}

function getVal(val: number | undefined): number {
    return val || 0;
}
