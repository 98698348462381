// This file/module has to be standalone; in particular it cannot depend on
// the logger module, since that module depends on this one.
//
// Therefore, this file cannot log anything itself.

interface _RtcUtilsSettings {
    loggingEnabled: boolean; // Disable log events to client.
    consoleLoggingEnabled: boolean; // Enable log events to UA console. Requires loggingEnabled.
}

/* Note: This variable is not meant to be used by public Clients.
 */
export let RtcUtilsSettings: _RtcUtilsSettings = {
    loggingEnabled: true,
    consoleLoggingEnabled: false
};

/** Free form Ragnarok library configuration parameter.
    To be used only by GFN client and internal dev clients. */
export declare interface RtcUtilConfigData {
    overrideData?: string;
}

/** Returns the boolean representation of the associated string */
export function toBool(value: string): boolean | undefined {
    if (value) {
        switch (value) {
            case "enable":
            case "on":
            case "1":
            case "true":
                return true;
            case "disable":
            case "off":
            case "0":
            case "false":
                return false;
        }
    }
    return undefined;
}

/**
 * This function allows GFN/dev clients to configure the ragnarok library to toggle various features from override tools or from remote config server.
 * This should be invoked before initializing other components of ragnarok library.
 *
 * The RtcUtilConfigData.remoteConfigData is all GFN specific settings, its exposed as a string to prevent dependency on GFN client from configuring values to it.
 * The json data received form the RemoteConfig server is stringified as it is and passed into the library without interpretation.
 *
 *
 * The RtcUtilConfigData.overrideData is a string of feature toggle/options separated by '&' character.
 * The options exposed exposed are:
 *
 *      mode=lean|dev|default           -- Lean mode of ragnarok put the library into minimal functionality mode.
 *                                         Most features will be disabled and users configure enabling of selected features.
 *                                         Dev mode of ragnarok enables dev internal tools for the library (by default enabled for Lean mode)
 *      log=enable|disable              -- Enable log generation from the library.
 *      console=enable|disable          -- Enable console log from the library if log generation is enabled.
 *
 *  Ex: In order to put the ragnarok in Lean mode yet support logging, the settings string would be
 *      "mode=lean&log=enable"
 *  Instead of enable/disable,  on/off or 0/1 can be used as well.
 */

export function ConfigureRtcUtilsSettings(data: RtcUtilConfigData) {
    if (data.overrideData) {
        /* The below code logic is to disable all features when user has selected lean mode.
          However each feature can be individually controlled
          so that performance can be analyzed by turning on one feature at a time. */

        const settings = data.overrideData.toLowerCase();

        /* Using URLSearchParams is simpler even when UI clients give a text option in Override tools to configure ragnarok.
           It splits on '&' and '=', which is what we would need when configured through UI Clients. */
        const settingsMap = new URLSearchParams(settings);
        if (settingsMap.get("mode") === "lean") {
            RtcUtilsSettings.loggingEnabled = false;
        }
        const getSettingBool = (key: string): boolean | undefined => {
            const value: any = settingsMap.get(key);
            return toBool(value);
        };

        RtcUtilsSettings.loggingEnabled = getSettingBool("log") ?? RtcUtilsSettings.loggingEnabled;
        RtcUtilsSettings.consoleLoggingEnabled =
            RtcUtilsSettings.loggingEnabled &&
            (getSettingBool("console") ?? RtcUtilsSettings.consoleLoggingEnabled);
    }
}
