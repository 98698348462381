import {
    GS_EVENTS,
    TelemetryEventIds,
    TelemetryHttpEvent,
    ClientTelemetryConfig
} from "./interfaces";
import { GridServerSettings } from "./settings";
import { IEventEmitter } from "./dependencies";
import {
    GSModuleName,
    HTTPVerb,
    ResumeType,
    CodecType,
    IPVersion,
    GS_HttpCallResultDef,
    GridServer_GameLaunch_Request,
    GridServer_GameLaunch_RequestDef,
    GS_DebugInfoDef,
    NetworkTypeEnum
} from "./gstelemetryinterfaces";
//import GsAnalyticsSchema from "./EventsSchema-GridServer.json";

export class GsTelemetryHandler {
    private eventEmitter: IEventEmitter;

    private networkType: NetworkTypeEnum = NetworkTypeEnum.UNKNOWN;
    private cmsId: string = "";
    private sessionId: string = "";
    private subSessionId: string = "";
    private telemetryEventIds: TelemetryEventIds = {};

    /// TODO: Need to fetch the schema details from the JSON schema file
    /// Importing json file works, but the build fails as the json file is not getting copied into output
    private readonly telemetryConfig: ClientTelemetryConfig = {
        clientId: "93289439828086895",
        clientName: "GFN_GridServer",
        eventSchemaVer: "2.0"
    };

    constructor(_eventEmitter: IEventEmitter) {
        this.eventEmitter = _eventEmitter;
    }

    public updateTelemetryEventIds(telemetryIds: TelemetryEventIds) {
        this.telemetryEventIds = telemetryIds;
    }

    private emitEvent(
        event: GS_HttpCallResultDef | GridServer_GameLaunch_RequestDef | GS_DebugInfoDef
    ) {
        this.eventEmitter.emit(GS_EVENTS.TELEMETRY_EVENT, {
            name: event.name,
            parameters: event.parameters,
            gdprLevel: event.gdprLevel,
            ts: event.ts,
            clientConfig: this.telemetryConfig
        });
    }

    public getTelemetryHttpEvent(
        url: string,
        verb: string,
        sessionId?: string
    ): TelemetryHttpEvent {
        return {
            url,
            verb,
            statusCode: "0",
            requestStatusCode: "",
            sessionId: sessionId ?? "",
            subSessionId: this.subSessionId,
            requestId: "",
            serverId: "",
            callDuration: 0
        };
    }

    public getGameLaunchRequestEvent(
        serverAddress: string,
        networkSessionId?: string,
        sessionId?: string
    ): GridServer_GameLaunch_Request {
        return {
            requestedZoneAddress: serverAddress,
            zoneName: "",
            networkSessionId: networkSessionId ?? "",
            sessionId: sessionId ?? "",
            subSessionId: "",
            resumeType: sessionId ? ResumeType.MANUAL : ResumeType.NONE,
            overrideConfigType: GridServerSettings.remoteOverrideInfo.type,
            overrideConfigVersion: GridServerSettings.remoteOverrideInfo.version,
            result: "",
            codec: CodecType.UNKNOWN,
            ipVersion: IPVersion.UNKNOWN,
            launchDuration: 0,
            networkType: this.networkType,
            streamingProfileGuid: "",
            systemInfoGuid: "",
            cmsId: String(this.cmsId)
        };
    }

    public emitHttpEvent(event: TelemetryHttpEvent) {
        if (this.eventEmitter.hasListener(GS_EVENTS.TELEMETRY_EVENT)) {
            const httpEvent: GS_HttpCallResultDef = new GS_HttpCallResultDef({
                callDuration: event.callDuration,
                verb: event.verb as HTTPVerb,
                sessionId: event.sessionId,
                subSessionId: event.subSessionId,
                serverId: event.serverId,
                url: event.url,
                overrideConfigType: GridServerSettings.remoteOverrideInfo.type,
                overrideConfigVersion: GridServerSettings.remoteOverrideInfo.version,
                requestStatusCode: event.requestStatusCode,
                requestId: event.requestId,
                networkType: this.networkType,
                statusCode: event.statusCode,
                cmsId: String(this.cmsId),
                moduleName: GSModuleName.GRID_SERVER
            });
            this.emitEvent(httpEvent);
        }
        if (this.eventEmitter.hasListener(GS_EVENTS.TELEMETRY_HTTP_EVENT)) {
            this.eventEmitter.emit(GS_EVENTS.TELEMETRY_HTTP_EVENT, event);
        }
    }

    public emitGameLaunchRequestEvent(eventData: GridServer_GameLaunch_Request) {
        if (this.eventEmitter.hasListener(GS_EVENTS.TELEMETRY_EVENT)) {
            const event: GridServer_GameLaunch_RequestDef = new GridServer_GameLaunch_RequestDef(
                eventData
            );
            event.parameters.sessionId = this.sessionId;
            event.parameters.subSessionId = this.subSessionId;
            event.parameters.systemInfoGuid = this.telemetryEventIds.systemInfoGuid ?? "";
            event.parameters.streamingProfileGuid =
                this.telemetryEventIds.streamingProfileGuid ?? "";
            this.emitEvent(event);
        }
    }

    public emitDebugEvent(
        key1?: string,
        key2?: string,
        key3?: string,
        key4?: string,
        key5?: string
    ) {
        if (this.eventEmitter.hasListener(GS_EVENTS.TELEMETRY_EVENT)) {
            const event: GS_DebugInfoDef = new GS_DebugInfoDef({
                key1: key1 ?? "",
                key2: key2 ?? "",
                key3: key3 ?? "",
                key4: key4 ?? "",
                key5: key5 ?? "",
                moduleName: GSModuleName.GRID_SERVER,
                networkType: this.networkType,
                overrideConfigType: GridServerSettings.remoteOverrideInfo.type,
                overrideConfigVersion: GridServerSettings.remoteOverrideInfo.version,
                cmsId: String(this.cmsId),
                sessionId: this.sessionId,
                subSessionId: this.subSessionId
            });
            this.emitEvent(event);
        }
        if (this.eventEmitter.hasListener(GS_EVENTS.TELEMETRY_DEBUG_EVENT)) {
            this.eventEmitter.emit(GS_EVENTS.TELEMETRY_DEBUG_EVENT, {
                sessionId: this.sessionId,
                subSessionId: this.subSessionId,
                key1: key1 ?? "",
                key2: key2 ?? "",
                key3: key3 ?? "",
                key4: key4 ?? "",
                key5: key5 ?? ""
            });
        }
    }

    public setSessionId(sessionId: string) {
        this.sessionId = sessionId;
    }

    public setSubSessionId(subSessionId: string) {
        this.subSessionId = subSessionId;
    }

    public setCmsId(cmsId: string) {
        this.cmsId = cmsId;
    }

    public setNetworkType(network: NetworkTypeEnum) {
        this.networkType = network;
    }

    public resetCache() {
        this.telemetryEventIds = {};
        this.sessionId = "";
        this.subSessionId = "";
        this.cmsId = "";
    }
}
