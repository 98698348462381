import { Log } from "../dependencies";
import { VideoFrameMetadata } from "../internalinterfaces";

const LOGTAG = "ldatcontroller";

export interface FrameLatencyData {
    /** Number of frames submitted for composition */
    presentedFrames: number;
    /** Time video frame callback fired.  Occurs after CompositorFrame produced by renderer is submitted to browser process */
    videoFrameCallbackTime: DOMHighResTimeStamp;
    /** Time user agent submitted frame for composition */
    presentationTime: DOMHighResTimeStamp;
    /** Time encoded frame received by platform, i.e. last packet belonging to frame received over network */
    receiveTime?: DOMHighResTimeStamp;
    /** Duration from submission of encoded packet to decoder until decoded frame ready for presentation */
    processingDuration?: number;
    /** Time browser received mouse input event from OS */
    mouseClickTime: DOMHighResTimeStamp;
    /** Time mousedown callback occurred */
    mouseClickCallbackTime: DOMHighResTimeStamp;
}

export interface FlashCallback {
    (x: FrameLatencyData): void;
}

export const DEFAULT_LUMA_THRESHOLD = 0.06;
export const MOUSE_EVENT_DELAY_MS = 100;
const MAX_HISTORY = 32;

interface FrameTimingData {
    presentedFrames: number;
    videoFrameCallbackTime: DOMHighResTimeStamp;
    presentationTime: DOMHighResTimeStamp;
    receiveTime?: DOMHighResTimeStamp;
    processingDuration?: number;
    luma?: number;
}

interface MouseClickData {
    mouseClickTime: DOMHighResTimeStamp;
    mouseClickCallbackTime: DOMHighResTimeStamp;
}

/**
* Responsible for interpreting mouse click data, luminance data, and frame timing data to compute latency statistics.
  Controls whether operations necessary for the LDAT to run should be performed.
 */
export class LDATController {
    private active: boolean = false;
    private lumaThreshold: number = DEFAULT_LUMA_THRESHOLD;
    private loupePosition: DOMRect = new DOMRect(0, 0, 0, 0);
    private frameTimings: FrameTimingData[] = [];
    private mouseClickData: MouseClickData[] = [];
    private flashCallback?: FlashCallback;
    private videoElement: HTMLVideoElement;
    private shouldUsePointerEvents: boolean;
    private mousedownFunc: any;

    constructor(videoElement: HTMLVideoElement, shouldUsePointerEvents: boolean) {
        this.videoElement = videoElement;
        this.shouldUsePointerEvents = shouldUsePointerEvents;
        this.mousedownFunc = this.onmousedown.bind(this);
    }

    /**
     * Activate or deactivate the LDAT.
     * When active, luminance should be calculated on a per-frame basis to compute latency statistics.
     * @param active
     */
    public setActive(active: boolean): void {
        if (this.active === active) {
            return;
        }
        this.active = active;
        const eventName = this.shouldUsePointerEvents ? "pointerdown" : "mousedown";

        if (active) {
            Log.i("{0c7ed7c}", "{b56e30d}");
            this.videoElement.dispatchEvent(new Event("focus", { bubbles: true }));
            this.videoElement.addEventListener(eventName, this.mousedownFunc);
        } else {
            Log.i("{0c7ed7c}", "{00425c2}");
            this.videoElement.removeEventListener(eventName, this.mousedownFunc);
            this.frameTimings = [];
            this.mouseClickData = [];
        }
    }

    /**
     * @return True if the LDAT is active, false otherwise.
     */
    public isActive(): boolean {
        return this.active;
    }

    /**
     * Set the luminance threshold used to determine whether a flash occurred.
     * Default threshold defined as 6% increase in luminance relative to the prior frame's value
     * @param threshold - Percent increase in luminance between 0 and 1
     */
    public setLuminanceThreshold(threshold: number): void {
        if (threshold <= 0 || threshold >= 1) {
            Log.e("{0c7ed7c}", "{1cbd879}", threshold.toFixed(2));
            return;
        }
        this.lumaThreshold = threshold;
    }

    /**
     * @return The luminance threshold used to determine whether a flash occurred, expressed as percentage.
     */
    public getLuminanceThreshold(): number {
        return this.lumaThreshold;
    }

    /**
     * Set the region to be sampled for luminance changes
     * @param rect Region in abstract 0.0-1.0 coordinate space
     */
    public setLoupePosition(rect: DOMRect): void {
        if (rect.width <= 0 || rect.height <= 0) {
            Log.e("{0c7ed7c}", "{3e0eb8f}", rect.width, rect.height);
            return;
        }
        this.loupePosition = rect;
    }

    /**
     * @return The region to sample for luminance changes in abstract 0.0-1.0 coordinate space
     */
    public getLoupePosition(): DOMRect {
        return this.loupePosition;
    }

    /**
     * Register callback to be invoked when flash is detected in region under the loupe.
     */
    public registerFlashCallback(callback: FlashCallback): void {
        this.flashCallback = callback;
    }

    /**
     * When LDAT is active, use frame timing data to compute latency statistics when a flash occurs.
     * If a flash has been detected for this frame, invoke registered callback and clear stale video frame data from cache.
     */
    public onVideoFrame(now: DOMHighResTimeStamp, metadata: VideoFrameMetadata): void {
        this.frameTimings.push({
            presentedFrames: metadata.presentedFrames,
            videoFrameCallbackTime: now,
            presentationTime: metadata.presentationTime,
            receiveTime: metadata.receiveTime,
            processingDuration: metadata.processingDuration
        });

        const rect = this.convertToVideoCoordinateSpace(this.loupePosition);
        LDATController.GetAvgLuminance(
            this.videoElement,
            rect.x,
            rect.y,
            rect.width,
            rect.height
        ).then((luma: number) => this.recordLuminance(luma, metadata.presentedFrames));
    }

    private onmousedown(evt: MouseEvent | PointerEvent): void {
        if (!this.frameTimings.length) {
            Log.w("{0c7ed7c}", "{fe08a50}");
            return;
        }

        // Limit how often mouse clicks are processed to avoid ambiguity in determining which mouse click caused the flash
        if (
            this.mouseClickData.length &&
            evt.timeStamp - this.mouseClickData[this.mouseClickData.length - 1].mouseClickTime <
                MOUSE_EVENT_DELAY_MS
        ) {
            return;
        }

        this.mouseClickData.push({
            mouseClickTime: evt.timeStamp,
            mouseClickCallbackTime: performance.now()
        });
        while (this.mouseClickData.length > MAX_HISTORY) {
            this.mouseClickData.shift();
        }
    }

    /**
     * Calculates average luminance across all pixels in specified region of video frame.
     * @param source element that holds the video frame data
     * @param x coordinate of region's top-left corner, in coordinate space of source element
     * @param y  coordinate of region's top left corner, in coordinate space of source element
     * @param width of source region to read
     * @param height of source region to read
     * @param callback the function to call when average calculation finished
     * @return Luminance as normalized value between 0 and 1.  Result is delivered asynchronously as Promise.
     * Rejects with -1 if failed to calculate luminance or if functionality not supported on given platform.
     */
    public static GetAvgLuminance(
        source: CanvasImageSource,
        x: number,
        y: number,
        width: number,
        height: number
    ): Promise<number> {
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                const luma = LDATController.GetAvgLuminanceImpl(source, x, y, width, height);
                if (luma === -1) {
                    reject(-1);
                }
                resolve(luma);
            }, 0);
        });
    }

    private static GetAvgLuminanceImpl(
        source: CanvasImageSource,
        x: number,
        y: number,
        width: number,
        height: number
    ): number {
        if (width <= 0 || height <= 0) {
            return -1;
        }

        const canvas = new OffscreenCanvas(width, height);
        const context = canvas.getContext("2d");

        if (!context) {
            Log.e("{0c7ed7c}", "{b72639c}");
            return -1;
        }

        let data;
        try {
            context.drawImage(source, x, y, width, height, 0, 0, width, height);
            data = context.getImageData(0, 0, width, height).data;
        } catch (err) {
            Log.e("{0c7ed7c}", "{b16b792}", err);
            return -1;
        }

        let sum = 0;
        let count = 0;
        for (let i = 0; i < data.length - 3; i += 4) {
            // See https://en.wikipedia.org/wiki/Relative_luminance#Relative_luminance_and_%22gamma_encoded%22_colorspaces
            const luma = 0.2126 * data[i] + 0.7152 * data[i + 1] + 0.0722 * data[i + 2];
            sum += luma;
            count++;
        }

        const luminance = sum / count / 255;
        return luminance;
    }

    private recordLuminance(luma: number, presentedFrames: number) {
        const frameTimingData = this.frameTimings[this.frameTimings.length - 1];

        if (presentedFrames !== frameTimingData.presentedFrames) {
            Log.d("{0c7ed7c}", "{06323ea}", presentedFrames);
            return;
        }

        frameTimingData.luma = luma;

        while (this.frameTimings.length > MAX_HISTORY) {
            this.frameTimings.shift();
        }

        if (this.frameTimings.length == 1) {
            return;
        }

        const prevFrameTimingData = this.frameTimings[this.frameTimings.length - 2];

        // If prior frame missing, compare current frame to two frames prior.
        // Cannot determine whether flash occurred at this frame if prior two frames missing or if luminance data not yet recorded
        if (
            prevFrameTimingData.presentedFrames < presentedFrames - 2 ||
            prevFrameTimingData.luma === undefined ||
            prevFrameTimingData.luma === -1
        ) {
            return;
        }

        const changeInLuma = frameTimingData.luma - prevFrameTimingData.luma;
        if (changeInLuma > this.lumaThreshold) {
            let mouseClickCount = 0;
            let mostRecentMouseClick;
            const frameLatencyData = {
                presentedFrames: presentedFrames,
                videoFrameCallbackTime: frameTimingData.videoFrameCallbackTime,
                presentationTime: frameTimingData.presentationTime,
                receiveTime: frameTimingData.receiveTime,
                processingDuration: frameTimingData.processingDuration,
                mouseClickTime: 0,
                mouseClickCallbackTime: 0
            };

            while (
                this.mouseClickData.length &&
                this.mouseClickData[0].mouseClickTime <
                    (frameTimingData.receiveTime ?? frameTimingData.presentationTime)
            ) {
                mouseClickCount++;
                mostRecentMouseClick = this.mouseClickData.shift();
            }

            if (!mostRecentMouseClick) {
                return;
            }

            if (mouseClickCount > 1) {
                Log.d("{0c7ed7c}", "{a4b8c55}", presentedFrames, mouseClickCount);
            }

            frameLatencyData.mouseClickTime = mostRecentMouseClick.mouseClickTime;
            frameLatencyData.mouseClickCallbackTime = mostRecentMouseClick.mouseClickCallbackTime;

            if (this.flashCallback) {
                this.flashCallback(frameLatencyData);
            }
        }
    }

    /**
     * @return True if entire loupe is positioned over the video element.
     * Returns false if any portion of loupe is outside bounds of video element.
     */
    public isLoupeInPosition() {
        const rect = this.convertToVideoCoordinateSpace(this.loupePosition);
        if (
            rect.x < 0 ||
            rect.y < 0 ||
            rect.x + rect.width > this.videoElement.videoWidth ||
            rect.y + rect.height > this.videoElement.videoHeight
        ) {
            return false;
        }
        return true;
    }

    /**
     * Converts DOMRect from abstract 0.0-1.0 coordinate space to video coordinate space
     */
    private convertToVideoCoordinateSpace(rect: DOMRect): DOMRect {
        const videoWidth = this.videoElement.videoWidth;
        const videoHeight = this.videoElement.videoHeight;
        return new DOMRect(
            rect.x * videoWidth,
            rect.y * videoHeight,
            rect.width * videoWidth,
            rect.height * videoHeight
        );
    }

    /**
     *  Simulates mouse click by dispatching mouse or pointer events on the video element.
     */
    public sendMouseClickEvent(): void {
        if (this.shouldUsePointerEvents) {
            const options = { isPrimary: true, pointerId: 1, button: 0 };
            this.videoElement.dispatchEvent(new PointerEvent("pointerdown", options));
            this.videoElement.dispatchEvent(new PointerEvent("pointerup", options));
        } else {
            this.videoElement.dispatchEvent(new MouseEvent("mousedown"));
            this.videoElement.dispatchEvent(new MouseEvent("mouseup"));
        }
    }
}
