import { Log } from "../dependencies";
import { Download } from "../util/utils";

const LOGTAG = "audiorecorder";

export const enum TriggerState {
    NONE = 0,
    RECORDING,
    LATENCY
}

declare class MediaRecorder extends EventTarget {
    constructor(stream: MediaStream);
    start(): undefined;
    stop(): undefined;
    onstart: () => void;
    onstop: () => void;
    ondataavailable: (msg: MessageEvent) => void;
}

export class AudioRecorder {
    private mediaRecorder?: MediaRecorder;
    private isRecording: boolean = false;
    private recordedBlobs: Array<any> = [];
    private recordedBlobsArray: Array<Array<any>> = [];
    private timerID: number = 0;
    private dumpCount: number = 0;
    private audioTriggerState: TriggerState = TriggerState.NONE;
    private audioStream?: MediaStream;

    public createRecorder() {
        Log.i("{4c846ba}", "{b11cb24}");
        if (this.audioStream) {
            try {
                this.mediaRecorder = new MediaRecorder(this.audioStream);
            } catch (error) {
                Log.e("{4c846ba}", "{0d1420e}", error);
                return;
            }
        } else {
            Log.e("{4c846ba}", "{74475ae}");
            return;
        }

        if (this.mediaRecorder) {
            this.mediaRecorder.onstart = () => {};
            this.mediaRecorder.onstop = () => {
                Log.i("{4c846ba}", "{7a944b4}");
            };
            this.mediaRecorder.ondataavailable = (msg: MessageEvent) => {
                if (msg.data && msg.data.size > 0) {
                    this.recordedBlobs.push(msg.data);
                    this.recordedBlobsArray.push(this.recordedBlobs);
                    ++this.dumpCount;
                    this.recordedBlobs = [];
                }
            };
            Log.i("{4c846ba}", "{ffb713f}", this.mediaRecorder);
        }

        this.isRecording = false;
    }

    public startRecord() {
        let timeoutMSec;
        if (this.audioTriggerState == TriggerState.RECORDING) {
            timeoutMSec = 30 * 60 * 1000;
        } else if (this.audioTriggerState == TriggerState.LATENCY) {
            timeoutMSec = 3 * 1000;
        } else {
            return;
        }

        if (!this.mediaRecorder || this.isRecording) {
            return;
        }
        this.mediaRecorder.start();
        this.isRecording = true;
        this.timerID = window.setTimeout(() => {
            Log.i("{4c846ba}", "{2282d21}");
            this.destroyRecorder();
        }, timeoutMSec);
        Log.i("{4c846ba}", "{4f438e3}");
    }

    private restartRecord() {
        this.destroyRecorder();
        this.createRecorder();
        this.startRecord();
    }

    public destroyRecorder() {
        this.mediaRecorder?.stop();
        this.mediaRecorder = undefined;
        if (this.timerID !== 0) {
            window.clearTimeout(this.timerID);
            this.timerID = 0;
        }
    }

    public initialize(stream: MediaStream) {
        this.audioStream = stream;
    }

    public uninitialize() {
        this.destroyRecorder();
        this.downloadAudio();
        this.audioStream = undefined;
        this.audioTriggerState = TriggerState.NONE;
    }

    public downloadAudio() {
        if (this.audioTriggerState == TriggerState.NONE) {
            return;
        }
        for (let i = 0; i < this.dumpCount; ++i) {
            const fileName = "audioCapture_" + i + ".wmv";
            if (Download(this.recordedBlobsArray[i], fileName, "audio/webm")) {
                Log.i("{4c846ba}", "{c24ed3b}");
            }
        }
        this.dumpCount = 0;
        this.recordedBlobsArray = [];
    }

    public startPcmDump(): Boolean {
        if (this.audioTriggerState == TriggerState.NONE) {
            this.audioTriggerState = TriggerState.RECORDING;
            Log.i("{4c846ba}", "{008dc86}");
            this.createRecorder();
            this.startRecord();
            return true;
        }
        return false;
    }

    public startLatencyDump(): Boolean {
        if (this.audioTriggerState == TriggerState.RECORDING) {
            Log.i("{4c846ba}", "{1f67b97}");
            this.audioTriggerState = TriggerState.LATENCY;
            return true;
        }
        return false;
    }

    public createNewLatencyDump() {
        if (this.audioTriggerState == TriggerState.LATENCY) {
            Log.i("{4c846ba}", "{89b1a9f}");
            this.restartRecord();
        }
    }
}
