import { IEventEmitter, IsChromium, PlatformDetails, Log } from "../dependencies";
import { KeyDeprecatedStats, DeprecatedStats } from "./statsinterfaces";
import { EVENTS, StreamUpdateEvent, Resolution, InputConfigFlags } from "../interfaces";
import {
    INTERNAL_EVENTS,
    VideoFrameMetadata,
    VideoFrameRequestCallback,
    WithRequestVideoFrameCallback
} from "../internalinterfaces";
import { QosCalculator } from "./qoscalculator";
import { StreamStats } from "./streamstats";
import { StreamClient } from "../streamclient";
import { RagnarokProfiler } from "../ragnarokprofiler";
import { RagnarokSettings } from "../util/settings";
import { BinaryReport } from "./binaryreport";
import { TelemetryHandler } from "../telemetry/telemetryhandler";
import { InputChannel } from "../input/inputinterfaces";
import { GamepadHandler } from "../input/gamepadhandler";
import { StaticStreamStats } from "../rinterfaces";
import {
    ShouldEnableCPM,
    GetResolutionString,
    GetPhysicalResolution,
    GetLogicalResolution
} from "../util/utils";
import { DeviceCapabilities } from "../util/devicecapabilities";
import { LDATOverlay, ILDATHandler } from "../debug/ldatoverlay";
import { NvstConfig } from "../nvstconfig";
/**
 *This class has all the logic to execute GetStats method, and calculate few stats for displaying on client side.
 */

const LOGTAG = "clientstatsservice";
const BWE_OFFSET = 1;
const JITTER_OFFSET = 9;
const RTD_OFFSET = 17;
const FPS_OFFSET = 25;

interface InboundVideoCounts {
    currReportFailed: number;
    prevReportFailed: number;
    success: number;
}

interface InboundVideoStats {
    counts: InboundVideoCounts;
    decoder: string;
    fallbackFrameNumber: number;
}

export interface IceStats {
    recentRequestsSent: number;
    recentResponsesReceived: number;
    recentPacketsReceived: number;
}

const enum StreamStatsState {
    OFF,
    STANDARD,
    DETAILED
}

declare interface RTCIceCandidatePairStatsExtras extends RTCIceCandidatePairStats {
    requestsSent: number;
    consentRequestsSent: number;
    requestsReceived: number;
    responsesSent: number;
    responsesReceived: number;
    packetsSent: number;
    packetsReceived: number;
}

interface RtpMapping {
    rtp: number;
    client: number;
}

const ICE_STATS_HISTORY_SIZE = 6;

export class ClientStatsService {
    private peerConnection: RTCPeerConnection;
    private telemetry: TelemetryHandler;
    private running: boolean = true;
    private statsChannel!: RTCDataChannel;
    private statsIntervalId: number = 0;
    private deprecatedstatsIntervalId: number = 0;
    private eventEmitter: IEventEmitter;
    private keyDeprecatedStats: KeyDeprecatedStats;
    //stats cache from previous calls to calculate desired rates
    private videoPacketsReceived: number = 0;
    private videoFramesDecoded: number = 0;
    private audioCodecType: string = "";
    private videoCodecType: string = "";
    private reportCache?: RTCStatsReport;
    private qosCalculator: QosCalculator;
    private streamStats: StreamStats;
    private streamClient: StreamClient;
    private initTypeId: boolean = false;
    private typeToIdMap: Map<string, Array<string>> = new Map<string, Array<string>>();
    private bweMbps: number = 0;
    private jitter: number = 0;
    private rtd: number = 0;
    private gameFps: number = 0;
    private mediaType: "video" | "audio" = "video";
    private shouldEmitInternalStatsEvent: boolean;
    private binaryReport: BinaryReport;
    private streamStatsState: StreamStatsState = StreamStatsState.OFF;
    private nextStreamStatsTs: number = 0;
    private platformDetails: PlatformDetails;
    private inputChannel: InputChannel;
    private gamepadHandler: GamepadHandler;
    private usedHeapSize: number = 0;
    private totalHeapSize: number = 0;
    private staticStreamStats: StaticStreamStats;
    /// Maintains stats on the active candidate pair once a second for ICE_STATS_HISTORY_SIZE seconds
    private iceStats: RTCIceCandidatePairStatsExtras[] = [];
    private videoFrameCallbackFunc: VideoFrameRequestCallback;
    private logicalResolution: Resolution = { width: 0, height: 0 };
    private physicalResolution: Resolution = { width: 0, height: 0 };
    private pendingStats: boolean = false;
    private pendingDeprecatedStats: boolean = false;
    private inboundVideoStats: InboundVideoStats;
    private ldat?: LDATOverlay;
    private useInboundRtpAsTrack: boolean = false;
    private mapRtpTimestampsToFrames: boolean;
    private lastUnsentRtpMapping?: RtpMapping;
    private nextRtpMappingTime: number = 0;
    private hasAudioStats?: boolean;

    constructor(
        eventEmitter: IEventEmitter,
        streamClient: StreamClient,
        pc: RTCPeerConnection,
        nvstConfig: NvstConfig,
        telemetry: TelemetryHandler,
        platformDetails: PlatformDetails,
        inputChannel: InputChannel,
        gamepadHandler: GamepadHandler,
        staticStreamStats: StaticStreamStats,
        configFlags?: InputConfigFlags
    ) {
        this.platformDetails = platformDetails;
        this.eventEmitter = eventEmitter;
        this.streamClient = streamClient;
        this.mapRtpTimestampsToFrames = nvstConfig.video?.[0].mapRtpTimestampsToFrames ?? false;
        this.telemetry = telemetry;
        this.inputChannel = inputChannel;
        this.gamepadHandler = gamepadHandler;
        this.keyDeprecatedStats = {
            ts: 0,
            timingFrameInfo: "",
            targetDelayMs: 0,
            minPlayoutDelayMs: 0,
            currentDelayMs: 0
        };
        this.qosCalculator = new QosCalculator(streamClient.getMaxBitRate());
        this.staticStreamStats = staticStreamStats;

        const videoElement = this.streamClient.getVideoElement()!;
        this.streamStats = new StreamStats(
            videoElement,
            this.platformDetails,
            this.staticStreamStats
        );
        if (RagnarokSettings.isInternalUser) {
            this.ldat = new LDATOverlay(videoElement, configFlags?.cursorType);
        }
        this.peerConnection = pc;
        this.createStatsDataChannel();
        this.shouldEmitInternalStatsEvent = this.eventEmitter.hasListener(
            INTERNAL_EVENTS.STREAMING_STATS
        );
        this.binaryReport = new BinaryReport();
        this.inboundVideoStats = {
            counts: {
                currReportFailed: 0,
                prevReportFailed: 0,
                success: 0
            },
            decoder: "",
            fallbackFrameNumber: -1
        };

        this.videoFrameCallbackFunc = this.videoFrameCallback.bind(this);
    }

    private initMap() {
        this.peerConnection
            .getStats(null)
            .then((report: RTCStatsReport) => {
                for (let stat of report.values()) {
                    if (this.isTypeWhitelisted(stat.type)) {
                        if (stat.kind === "audio") {
                            this.hasAudioStats = true;
                        }

                        const statsArray = this.typeToIdMap.get(stat.type);
                        if (statsArray) {
                            if (!statsArray.includes(stat.id)) {
                                statsArray.push(stat.id);
                            } else {
                                Log.e("{5cea617}", "{76f5968}", stat.id);
                            }
                        } else {
                            this.typeToIdMap.set(stat.type, [stat.id]);
                        }
                    }
                }
                this.useInboundRtpAsTrack = !this.typeToIdMap.has("track");
                Log.i("{5cea617}", "{1317761}", (this.useInboundRtpAsTrack ? "yes" : "no"));
                this.initTypeId = true;
                this.reportCache = report;
                this.processStatsReport(report);
            })
            .catch(exp => this.emitStatsException(exp, "init"))
            .finally(() => this.finalizeGetStats());
    }

    public createStatsDataChannel() {
        let statsChannelParams = {
            ordered: false,
            reliable: false,
            maxRetransmits: 0
        };
        this.statsChannel = this.peerConnection.createDataChannel(
            "stats_channel",
            statsChannelParams
        );
        this.statsChannel.binaryType = "arraybuffer";
        this.streamClient.addDataChannel(this.statsChannel, {
            open: () => this.setIntervals(),
            close: () => this.clearIntervals()
        });
        this.statsChannel.onmessage = (msg: any) => {
            var view = new DataView(msg.data);
            let version = view.getUint8(0);
            if (version >= 2) {
                this.bweMbps = view.getFloat64(BWE_OFFSET, true) / 1000000;
                this.jitter = view.getFloat64(JITTER_OFFSET, true) * 1000;
                this.rtd = view.getFloat64(RTD_OFFSET, true);

                this.streamStats.updateJitter(this.jitter);
                this.streamStats.updateRtd(this.rtd);
                if (version >= 3) {
                    this.gameFps = view.getFloat64(FPS_OFFSET, true);
                    this.streamStats.updateAvgGameFps(this.gameFps);
                }
                this.qosCalculator.calculateBandwidthScoreV2(
                    this.bweMbps,
                    this.streamStats.updateBwu(this.bweMbps)
                );
                this.qosCalculator.calculateLatencyScoreV2(this.rtd);
            } else {
                Log.e("{5cea617}", "{4f9f4b7}", version);
            }
        };
    }

    private setIntervals() {
        this.statsIntervalId = window.setInterval(() => {
            this.sendClientStats();
        }, RagnarokSettings.ragnarokConfig.getStatsInterval ?? 96);
        if (IsChromium()) {
            this.deprecatedstatsIntervalId = window.setInterval(() => {
                this.saveDeprecatedStats();
            }, RagnarokSettings.ragnarokConfig.getDeprecatedStatsInterval ?? 201);
        }

        const videoElement = <WithRequestVideoFrameCallback>this.streamClient.getVideoElement();
        videoElement.requestVideoFrameCallback?.(this.videoFrameCallbackFunc);
    }

    private videoFrameCallback(now: DOMHighResTimeStamp, metadata: VideoFrameMetadata) {
        this.binaryReport.sendVideoFrameMetadata(now, metadata);
        if (this.ldat?.isActive()) {
            this.ldat.onVideoFrame(now, metadata);
        }
        if (this.mapRtpTimestampsToFrames && metadata.rtpTimestamp && metadata.receiveTime) {
            this.lastUnsentRtpMapping = {
                rtp: metadata.rtpTimestamp,
                client: metadata.receiveTime - RagnarokProfiler.getStreamBeginTime()
            };
        }

        if (this.isEnabled()) {
            // Re-register the callback to be notified about the next frame.
            const videoElement = <WithRequestVideoFrameCallback>this.streamClient.getVideoElement();
            videoElement.requestVideoFrameCallback?.(this.videoFrameCallbackFunc);
        }
    }

    private clearIntervals() {
        if (this.statsIntervalId) {
            clearInterval(this.statsIntervalId);
            this.statsIntervalId = 0;
        }
        if (this.deprecatedstatsIntervalId) {
            clearInterval(this.deprecatedstatsIntervalId);
            this.deprecatedstatsIntervalId = 0;
        }
        // We cache the per frame vfmd stats and send them in bursts
        // Ensure we flush any cached stats before disabling the stats
        this.binaryReport.sendCachedVfmdStats();
    }

    public reset() {
        this.clearIntervals();
        this.qosCalculator = new QosCalculator(this.streamClient.getMaxBitRate());
        this.streamStats = new StreamStats(
            this.streamClient.getVideoElement()!,
            this.platformDetails,
            this.staticStreamStats
        );
        this.reportCache = undefined;
    }

    public isEnabled() {
        return this.statsIntervalId != 0;
    }

    public disableStats() {
        if (!this.isEnabled()) {
            return;
        }
        this.reset();
        this.setOnScreenStats(false);
    }

    public enableStats() {
        if (this.isEnabled()) {
            return;
        }
        this.setIntervals();
    }

    public stop() {
        if (!this.running) {
            return;
        }
        this.running = false;
        this.disableStats();
        this.ldat?.stop();
        this.telemetry.emitMetricEvent(
            "InboundVideoStats",
            this.inboundVideoStats.decoder,
            this.inboundVideoStats.counts.success,
            this.inboundVideoStats.counts.currReportFailed,
            this.inboundVideoStats.fallbackFrameNumber,
            this.inboundVideoStats.counts.prevReportFailed
        );
    }

    private isTypeWhitelisted(type: string) {
        let whitelist: string[] = ["track", "transport", "inbound-rtp", "candidate-pair"];
        for (var element of whitelist) {
            if (element === type) return true;
        }
        return false;
    }

    public resetTypeIds() {
        this.initTypeId = false;
    }

    private processStatsReport(report: RTCStatsReport) {
        const STREAM_STATS_INTERVAL_MS = 1000;
        const RTP_MAPPING_INTERVAL = 1000;

        const getStatsT0 = performance.now();
        if (getStatsT0 >= this.nextStreamStatsTs && this.mediaType === "video") {
            this.updateOnScreenStats(report);
            this.updateCandidatePairStats(report);
            if (this.showStreamStats()) {
                this.streamStats.drawStatsOnScreen(
                    this.streamStatsState === StreamStatsState.DETAILED
                );
            }
            const streamEvent: StreamUpdateEvent = this.streamStats.makeStreamUpdateEvent();
            this.eventEmitter.emit(EVENTS.STREAM_STATS_UPDATE, streamEvent);
            this.reportCache = report;
            this.nextStreamStatsTs = getStatsT0 + STREAM_STATS_INTERVAL_MS;
        }
        this.convertReportToBinary(report);
        this.processGarbageCollectionStats();
        this.processInputChannelStats();

        if (getStatsT0 >= this.nextRtpMappingTime && this.lastUnsentRtpMapping) {
            this.sendRtpMapping(this.lastUnsentRtpMapping);
            this.lastUnsentRtpMapping = undefined;

            this.nextRtpMappingTime = getStatsT0 + RTP_MAPPING_INTERVAL;
        }
        if (this.mediaType === "video") {
            if (ShouldEnableCPM(this.platformDetails)) {
                this.sendClientPerfStats();
            }
            this.streamStats.updateQScore(this.qosCalculator.GetStreamQuality());
            const sq = this.qosCalculator.GetStreamQuality();
            this.eventEmitter.emit(EVENTS.STREAMING_QUALITY, sq);
            if (this.shouldEmitInternalStatsEvent)
                this.eventEmitter.emit(
                    INTERNAL_EVENTS.STREAMING_STATS,
                    this.streamStats.getStreamingStats()
                );
            RagnarokProfiler.addQualityScore(sq);
        }
        const getStatsT1 = performance.now();
        let statsTs = getStatsT1 - getStatsT0;
        RagnarokProfiler.addGetStatsTime(statsTs);
        this.streamStats.updateStatsDuration(statsTs);
        if (this.hasAudioStats) {
            this.mediaType = this.mediaType === "video" ? "audio" : "video";
        }
    }

    private convertReportToBinary(report: RTCStatsReport) {
        for (const idArray of this.typeToIdMap.entries()) {
            const type = idArray[0];
            for (const id of idArray[1]) {
                const stats = report.get(id);
                if (!stats) {
                    continue;
                }
                switch (type) {
                    case "track":
                        if (!this.useInboundRtpAsTrack) {
                            this.binaryReport.sendTrackStats(stats);
                        }
                        break;
                    case "inbound-rtp":
                        this.binaryReport.sendInboundRtpStats(stats);
                        if (this.useInboundRtpAsTrack) {
                            this.binaryReport.sendTrackStats(stats);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    public updateDcSendDuration(time: number) {
        this.streamStats.updateDcSendDuration(time);
    }
    public updateBlockedDuration(duration: number) {
        this.streamStats.updateMainThreadBlockDuration(duration);
    }
    public sendClientStats() {
        // Don't start another call to getStats if the previous one hasn't succeeded. This indicates a client perf
        // problem which we shouldn't make worse. This is also especially important for initMap() to ensure we don't
        // add the same IDs to the map multiple times
        if (this.pendingStats) {
            return;
        }
        this.pendingStats = true;

        if (!this.initTypeId) {
            this.initMap();
        } else {
            const receivers = this.peerConnection.getReceivers();
            const receiver = receivers.find(receiver => receiver.track.kind === this.mediaType);
            if (!receiver) return;
            receiver
                .getStats()
                .then(report => this.processStatsReport(report))
                .catch(exp => this.emitStatsException(exp, "standard"))
                .finally(() => this.finalizeGetStats());
        }
    }

    private showStreamStats(): boolean {
        return this.streamStatsState !== StreamStatsState.OFF;
    }

    public toggleOnScreenStats(enableDevStats: boolean) {
        this.setOnScreenStats(!this.showStreamStats(), enableDevStats);
    }

    private setOnScreenStats(show: boolean, enableDevStats: boolean = false) {
        this.streamStats.setShown(show);
        if (show) {
            this.streamStatsState = enableDevStats
                ? StreamStatsState.DETAILED
                : StreamStatsState.STANDARD;
            this.streamStats.drawStatsOnScreen(enableDevStats);
            // Trigger an immediate stats update
            this.nextStreamStatsTs = performance.now();
        } else {
            this.streamStatsState = StreamStatsState.OFF;
        }
    }

    private updateOnScreenStats(report: RTCStatsReport) {
        this.updateInBoundVideoRtpStats(report);
        this.updateTrackStats(report);
        this.streamStats.updateExtendedDebugStats(this.streamClient.getExtendedDebugStats());
    }

    private updateInBoundVideoRtpStats(report: RTCStatsReport) {
        const ids = this.typeToIdMap.get("inbound-rtp");
        if (!ids || !this.reportCache) return;
        const inboundVideo = ids
            .map(id => report.get(id))
            .find(x => x?.kind === "video" || x?.mediaType === "video");
        if (!inboundVideo) {
            this.inboundVideoStats.counts.currReportFailed++;
            return;
        }
        const decoder: string | undefined = inboundVideo.decoderImplementation;
        if (decoder?.indexOf) {
            const isFallback = decoder.indexOf("fallback") !== -1;
            if (isFallback) {
                if (this.inboundVideoStats.fallbackFrameNumber === -1) {
                    this.inboundVideoStats.fallbackFrameNumber =
                        inboundVideo.framesDecoded ?? this.videoFramesDecoded;
                    // TODO: Remove from blob stats after qosweb sources from ETWPrint
                    RagnarokProfiler.onEvent("SoftwareDecodeFallback");
                    const ETWS_SOFTWARE_DECODE_FALLBACK =
                        "Fallback to software decode at frame " +
                        String(this.inboundVideoStats.fallbackFrameNumber);
                    Log.d("{5cea617}", "{0b0c6f9}", ETWS_SOFTWARE_DECODE_FALLBACK);
                    this.streamStats.setSoftwareDecodeFallback(true);
                    this.streamClient.writeEtwPrint(ETWS_SOFTWARE_DECODE_FALLBACK);
                }
            }
            if (decoder !== this.inboundVideoStats.decoder && decoder !== "unknown") {
                this.inboundVideoStats.decoder = decoder;
                this.streamStats.setDecoderImplementation(decoder);
            }
        }
        const prevInboundVideo = this.reportCache.get(inboundVideo.id);
        if (!prevInboundVideo) {
            this.inboundVideoStats.counts.prevReportFailed++;
            return;
        }
        this.inboundVideoStats.counts.success++;
        let codecId = inboundVideo.codecId as string;
        if (codecId) {
            this.videoCodecType = report.get(codecId).mimeType;
            this.streamStats.updateVideoCodec(this.videoCodecType);
        }
        this.qosCalculator.calculateNetworkLossScore(inboundVideo, prevInboundVideo);
        this.videoFramesDecoded = inboundVideo.framesDecoded;
        this.videoPacketsReceived = inboundVideo.packetsReceived;
        this.streamStats.updateFps(inboundVideo, prevInboundVideo);
        this.streamStats.updatepacketLoss(inboundVideo.packetsLost);
        this.streamStats.updateAvgDecodeTime(inboundVideo);
        if (this.videoFramesDecoded) {
            this.streamStats.updateCumulativeAvgDecodeTime(
                (inboundVideo.totalDecodeTime * 1000) / this.videoFramesDecoded
            );
        }
        let framesReceived = report.get(inboundVideo.trackId)?.framesReceived;
        if (framesReceived) {
            this.streamStats.updateInterFrameDelay(
                inboundVideo.totalInterFrameDelay,
                framesReceived
            );
        }
        this.streamStats.updateFrameLoss(inboundVideo.pliCount);
        this.updateDisplayResolution();
    }

    private updateCandidatePairStats(report: RTCStatsReport) {
        const ids = this.typeToIdMap.get("candidate-pair");
        const candidatePair = ids?.map(id => report.get(id)).find(x => x);
        if (!candidatePair) {
            return;
        }
        if (this.iceStats.length >= ICE_STATS_HISTORY_SIZE) {
            this.iceStats.splice(0, 1);
        }
        this.iceStats.push(candidatePair);

        const prevCandidatePair = this.reportCache?.get(candidatePair.id);
        if (!prevCandidatePair) {
            return;
        }
        this.streamStats.updateAvgStreamingRate(candidatePair, prevCandidatePair);
    }

    private updateTrackStats(report: RTCStatsReport) {
        const type = this.useInboundRtpAsTrack ? "inbound-rtp" : "track";
        let ids = this.typeToIdMap.get(type);
        if (!ids) {
            return;
        }
        const videoTrack = ids
            .map(id => report.get(id))
            .find(x => x?.kind === "video" || x?.frameHeight !== undefined);
        if (!videoTrack) {
            return;
        }
        this.streamStats.updateStreamingResolution(videoTrack.frameWidth, videoTrack.frameHeight);
        this.streamStats.updateFrameDecoded(videoTrack.framesDecoded);
        this.streamStats.updateFrameReceived(videoTrack.framesReceived);
        this.streamStats.updateFramesDropped(videoTrack.framesDropped);
    }

    private saveDeprecatedStats() {
        if (this.pendingDeprecatedStats) {
            return;
        }
        this.pendingDeprecatedStats = true;
        const rejectionHandler = (exp: DOMException) => {
            this.emitStatsException(exp, "deprecated");
            this.pendingDeprecatedStats = false;
        };
        (<any>this.peerConnection)
            .getStats((report: DeprecatedStats) => {
                for (let stat of report.result()) {
                    if (stat.type != "ssrc") continue;
                    for (let name of stat.names()) {
                        if (stat.stat("mediaType") === "video") {
                            this.keyDeprecatedStats.ts = RagnarokProfiler.getStreamTime();
                            this.keyDeprecatedStats.timingFrameInfo =
                                stat.stat("googTimingFrameInfo");
                            this.keyDeprecatedStats.targetDelayMs = +stat.stat("googTargetDelayMs");
                            this.keyDeprecatedStats.minPlayoutDelayMs =
                                +stat.stat("googMinPlayoutDelayMs");
                            this.keyDeprecatedStats.currentDelayMs =
                                +stat.stat("googCurrentDelayMs");
                        }
                        break;
                    }
                }
                this.binaryReport.sendDeprStats(this.keyDeprecatedStats);
                this.pendingDeprecatedStats = false;
            }, rejectionHandler)
            .catch(rejectionHandler);
    }

    private sendClientPerfStats() {
        const APP_FEEDBACK_TYPE = 1;
        const APP_FEEDBACK_VERSION = 1;

        const dataBuffer = new ArrayBuffer(12);
        const dataBufferView = new DataView(dataBuffer);
        const offset = 0;

        dataBufferView.setUint8(offset, APP_FEEDBACK_TYPE);
        dataBufferView.setUint8(offset + 1, APP_FEEDBACK_VERSION);
        dataBufferView.setUint16(offset + 2, this.binaryReport.getPacketsLost(), true);
        dataBufferView.setUint16(offset + 4, this.binaryReport.getDecodeTimeAvgMs(), true);
        dataBufferView.setUint16(offset + 6, this.binaryReport.getFramesDecoded(), true);
        dataBufferView.setUint16(offset + 8, this.binaryReport.getPliCount(), true);
        dataBufferView.setUint16(offset + 10, this.binaryReport.getFramesDropped(), true);

        this.maybeSendMessage(dataBuffer, "clientperf");
    }

    private sendRtpMapping(mapping: RtpMapping) {
        const RTP_MAPPING_TYPE = 2;
        const RTP_MAPPING_VERSION = 1;
        // We can support multiple timestamps in one message by making this dynamic in the future
        const RTP_COUNT = 1;

        const dataBuffer = new ArrayBuffer(15);
        const dataBufferView = new DataView(dataBuffer);
        const offset = 0;

        dataBufferView.setUint8(offset, RTP_MAPPING_TYPE);
        dataBufferView.setUint8(offset + 1, RTP_MAPPING_VERSION);
        dataBufferView.setUint8(offset + 2, RTP_COUNT);
        dataBufferView.setUint32(offset + 3, mapping.rtp, true);
        dataBufferView.setFloat64(offset + 7, mapping.client, true);

        this.maybeSendMessage(dataBuffer, "rtpmapping");
    }

    private maybeSendMessage(buffer: ArrayBuffer, where: string): void {
        try {
            if (this.statsChannel.readyState === "open") {
                this.statsChannel.send(buffer);
            }
        } catch (exp) {
            let msg = "statsChannel send exception";
            Log.e("{5cea617}", "{78e74c5}", exp);
            this.emitStatsException(exp, where);
        }
    }

    private emitStatsException(exp: Error | DOMException, where: string) {
        this.telemetry.emitExceptionEvent(exp,
            `Exception in ${where} getStats`,
            LOGTAG + ".ts",
            0,
            0,
            true,
            "getStats");
    }

    private finalizeGetStats(): void {
        this.pendingStats = false;
    }

    private processGarbageCollectionStats() {
        let memoryInfo = (performance as any).memory;
        if (!memoryInfo) {
            return;
        }

        const currentUsedHeapSize = memoryInfo.usedJSHeapSize;
        const currentTotalHeapSize = memoryInfo.totalJSHeapSize;

        let deltaUsedHeapSize = currentUsedHeapSize - this.usedHeapSize;
        let deltaTotalHeapSize = currentTotalHeapSize - this.totalHeapSize;

        this.usedHeapSize = currentUsedHeapSize;
        this.totalHeapSize = currentTotalHeapSize;

        // only report when there is a decrease in heap size
        if (deltaUsedHeapSize >= 0 && deltaTotalHeapSize >= 0) {
            return;
        }
        RagnarokProfiler.addGarbageCollectionStats(deltaUsedHeapSize, deltaTotalHeapSize);
    }

    private processInputChannelStats() {
        RagnarokProfiler.addInputChannelStats(
            this.inputChannel.bufferedAmount,
            this.gamepadHandler.getMainThreadSchedulingDelay()
        );

        // Reset needed since we send the max delay every stats reporting interval
        this.gamepadHandler.resetMainThreadSchedulingDelay();
    }

    public getFramesDecoded() {
        return this.videoFramesDecoded;
    }

    public getVideoCodec() {
        return this.videoCodecType;
    }
    public getAudioCodec() {
        return this.audioCodecType;
    }

    public packetsReceived() {
        return this.videoPacketsReceived;
    }

    public getIceStats(): IceStats | undefined {
        const handleNan = (x: number) => (isNaN(x) ? -1 : x);

        if (this.iceStats.length < 2) {
            return undefined;
        }
        const oldPair = this.iceStats[0];
        const newPair = this.iceStats[this.iceStats.length - 1];
        return {
            recentRequestsSent: handleNan(
                newPair.requestsSent -
                    oldPair.requestsSent +
                    newPair.consentRequestsSent -
                    oldPair.consentRequestsSent
            ),
            recentResponsesReceived: handleNan(
                newPair.responsesReceived - oldPair.responsesReceived
            ),
            recentPacketsReceived: handleNan(newPair.packetsReceived - oldPair.packetsReceived)
        };
    }

    private async updateDisplayResolution() {
        const physicalResolution = GetPhysicalResolution();
        const logicalResolution = GetLogicalResolution();
        const videoFramesDecoded = this.videoFramesDecoded;

        const isEqual = (x: Resolution, y: Resolution) => {
            return x.width === y.width && x.height === y.height;
        };

        if (
            isEqual(physicalResolution, this.physicalResolution) &&
            isEqual(logicalResolution, this.logicalResolution)
        ) {
            return;
        }

        this.physicalResolution = physicalResolution;
        this.logicalResolution = logicalResolution;
        const refreshRate = await DeviceCapabilities.getRefreshRate();

        const ETWS_DISPLAY_RESOLUTION = `Stream[0]: Client display[-1] resolution : {physical : ${GetResolutionString(
            physicalResolution
        )}@${refreshRate}, logical : ${GetResolutionString(
            logicalResolution
        )}@${refreshRate}} at frame#${videoFramesDecoded}`;

        Log.i("{5cea617}", "{0b0c6f9}", ETWS_DISPLAY_RESOLUTION);
        this.streamClient.writeEtwPrint(ETWS_DISPLAY_RESOLUTION);
    }

    public getLdatHandler(): ILDATHandler | undefined {
        return this.ldat;
    }
}
