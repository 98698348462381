import { RagnarokSettings } from "../util/settings";
import { GestureHandler } from "./gesturedetector";
import { LatencyIndicator } from "../debug/latencyindicator";
import { TouchPosSize, WarpTouch } from "../util/utils";
import { BoundaryPair, VideoState } from "../rinterfaces";
import { PlatformDetails, IsSafari, IsTouchCapable, Log } from "../dependencies";

export const enum TouchType {
    DOWN = 0x0001,
    UP = 0x0002,
    MOVE = 0x0004,
    CANCEL = 0x0008
}

const LOGTAG = "touchlistener";

/// Maximum number of touches we can support.  (Arbitrary - 10 fingers * 4 types)
export const MAX_TOUCH_COUNT = 40;

/// Minimum scaked touch coordinate
const CLAMP_LO = 0;
/// Maximum scaled touch coordinate
const CLAMP_HI = 65535;

export interface TouchDataHandler {
    addTouchEvent(
        idx: number,
        id: number,
        touchType: TouchType,
        x: number,
        y: number,
        radiusX: number,
        radiusY: number,
        timestampMs: number
    ): boolean;

    sendTouchPacket(count: number): boolean;
}

class TouchRecord implements TouchPosSize {
    // Touch.identifier from the browser
    public readonly identifier: number;
    // Identifier we refer to this touch for our network protocol
    public readonly protocolId: number;
    // Last known position of the touch.
    public clientX: number = 0;
    public clientY: number = 0;
    public radiusX: number = 0;
    public radiusY: number = 0;
    // Whether to warp this touch point whenever it's updated
    private warp: boolean = false;

    constructor(touch: Touch, protocolId: number) {
        this.identifier = touch.identifier;
        this.protocolId = protocolId;
        // Use page{X,Y} instead of client{X,Y} to address GSDEV-6433
        // Some iPhones appear to get a 20-pixel vertical scroll after rotating from portrait to landscape
        // and back with the keyboard showing.  Using the page coordinates compensates for this.
        this.clientX = touch.pageX;
        this.clientY = touch.pageY;
        this.radiusX = touch.radiusX;
        this.radiusY = touch.radiusY;
        if (RagnarokSettings.touchWarp) {
            this.warpTouch();
        } else {
            this.warp = false;
        }
    }

    update(touch: Touch) {
        // Use page{X,Y} instead of client{X,Y} to address GSDEV-6433
        this.clientX = touch.pageX;
        this.clientY = touch.pageY;
        this.radiusX = touch.radiusX;
        this.radiusY = touch.radiusY;
        if (this.warp) {
            this.warpTouch();
        }
    }

    private warpTouch() {
        const warpedPt = WarpTouch(this);
        // Continue warping touches until the touch leaves the warp regions
        // after that we don't need to warp for further touches moved because
        // they are not affected by the touch dead areas.
        //
        // However, note that we continue warping during the initial touches
        // moved until we exit the dead areas in order to avoid a jump.
        this.warp = Math.abs(warpedPt.y - this.clientY) > 0.01;
        this.clientX = warpedPt.x;
        this.clientY = warpedPt.y;
    }
}

class TouchStorage {
    public readonly captureTs: DOMHighResTimeStamp;
    public readonly callbackTs: DOMHighResTimeStamp;

    public readonly touchType: TouchType;

    public readonly posX: number;
    public readonly posY: number;
    public readonly radX: number;
    public readonly radY: number;

    public readonly id: number;
    public readonly protocolId: number;

    constructor(
        touch: Touch,
        protocolId: number,
        type: TouchType,
        ts: DOMHighResTimeStamp,
        captureTs: DOMHighResTimeStamp
    ) {
        this.protocolId = protocolId;
        this.id = touch.identifier;
        this.touchType = type;
        this.posX = touch.pageX;
        this.posY = touch.pageY;
        this.radX = touch.radiusX;
        this.radY = touch.radiusY;
        this.callbackTs = ts;
        this.captureTs = captureTs;
    }
}

export class TouchListener {
    static isSupported(): boolean {
        return RagnarokSettings.forceTouchCapable || IsTouchCapable();
    }

    private isSafari: boolean;

    private scaleX: number = 0;
    private scaleY: number = 0;
    private margins: BoundaryPair = { horizontal: 1.0, vertical: 1.0 };

    private activeTouches = new Map<number, TouchRecord>();
    private activeProtocolIds = new Set<number>();

    private droppedEventsCount = 0;

    private storedTouches: TouchStorage[] = [];
    private storedTouchesTimer: number = 0;
    private trimStoredTouchesFunc: Function;
    private logStoredTouchesFunc: Function;

    private clearedAllTouches: boolean = true;

    // Store a touch point for potential logging.
    private storeTouch(
        touch: Touch,
        touchRecord: TouchRecord,
        touchType: TouchType,
        callbackTs: DOMHighResTimeStamp,
        evt: TouchEvent
    ) {
        if (!RagnarokSettings.storeTouch) {
            return;
        }

        let touchStore = new TouchStorage(
            touch,
            touchRecord.protocolId,
            touchType,
            callbackTs,
            evt.timeStamp
        );
        this.storedTouches.push(touchStore);
    }

    private trimStoredTouches() {
        // Clean up oldest touches.
        // Keep the touches that started up to 30 seconds before the last stored touch.
        const length = this.storedTouches.length;
        if (!length) return;
        let latestTs = this.storedTouches[length - 1].callbackTs;
        let oldestTs = latestTs - 30 * 1000;
        let trimIndex = -1;

        // Find highest index who's touch is older than the oldestTs to keep.
        // Assumes values are stored in montonically-increasing order of performance.now() values.
        for (let idx = 0; idx < length; idx++) {
            if (this.storedTouches[idx].callbackTs < oldestTs) {
                trimIndex = idx;
            } else {
                break;
            }
        }

        // Remove all older touches
        this.storedTouches.splice(0, trimIndex + 1);
    }

    private logStoredTouches() {
        // Log the stored touch information.
        for (let touch of this.storedTouches) {
            Log.d("{ec05004}", "{3c6a946}", touch.captureTs.toFixed(2), touch.callbackTs.toFixed(2), 
                    touch.id
                , touch.protocolId, touch.touchType, touch.posX.toFixed(
                    2
                ), touch.posY.toFixed(2), touch.radX.toFixed(0), touch.radY.toFixed(0));
        }
        this.storedTouches = [];
    }

    // Add a new to active touches
    // Call from touchstart
    private addNewTouch(touch: Touch): TouchRecord {
        let newId = 0;
        while (this.activeProtocolIds.has(newId)) {
            newId++;
        }
        let record = new TouchRecord(touch, newId);
        this.activeTouches.set(touch.identifier, record);
        this.activeProtocolIds.add(newId);
        return record;
    }

    // Remove a touch from active touches via its Touch.identifier property.
    // Call from touchend/touchcancel
    private removeTouch(identifier: number) {
        let touch = this.activeTouches.get(identifier);
        if (!touch) {
            return;
        }
        this.activeTouches.delete(identifier);
        this.activeProtocolIds.delete(touch.protocolId);
    }

    private shouldHandleTouch(touch: Touch): boolean {
        return touch.target === this.target;
    }

    private sendTouches(
        touchesToSend: (Touch | TouchRecord)[],
        touchType: TouchType,
        timeStamp: number
    ) {
        let count: number = 0;

        for (let touch of touchesToSend) {
            if (count > MAX_TOUCH_COUNT) {
                Log.e("{ec05004}", "{2faaf33}", 
                        touchesToSend.length - count
                    );
                // TODO Send events in multiple chunks
                return;
            }
            const id = this.activeTouches.get(touch.identifier)?.protocolId;
            if (id === undefined) {
                Log.e("{ec05004}", "{960ee4f}", touch.identifier);
                // Ignore touch
                continue;
            }

            // TODO Modify the protocol to use scaled radius values, not pixels.
            let scaledX = this.scaleX * (touch.clientX - this.margins.horizontal);
            let scaledY = this.scaleY * (touch.clientY - this.margins.vertical);

            const radiusX = this.scaleX * touch.radiusX;
            const radiusY = this.scaleY * touch.radiusY;

            // Allow touches to be slightly outside the session area, by the touch radius.
            // Should make it easier to (start a) touch at the edges of the session area.
            const minX = CLAMP_LO - radiusX;
            const maxX = CLAMP_HI + radiusX;
            const minY = CLAMP_LO - radiusY;
            const maxY = CLAMP_HI + radiusY;

            if (scaledX < minX || scaledX > maxX || scaledY < minY || scaledY > maxY) {
                // Out-of-range touch.
                switch (touchType) {
                    case TouchType.UP:
                    case TouchType.CANCEL:
                        // Send this touch point event to the server.
                        // Sends an end coordinate pair that is the event's coordinate pair clamped to the session area.
                        break;
                    case TouchType.DOWN:
                        // Do not send an explicit DOWN.
                        // Future MOVEs will be sent if they are in range; the server will synthesize a DOWN if needed.
                        continue;
                    case TouchType.MOVE:
                        // Ignore out-of-range touches - those that are off-screen or outside the session area.
                        // A touch sequence that re-enters the session area will get a single move to the new location.
                        continue;
                    default:
                        // Unknown touch type, just ignore.  (Shouldn't be possible.)
                        continue;
                }
            }

            // Clamp the touch coordinates to the session area.
            scaledX = Math.min(Math.max(scaledX, CLAMP_LO), CLAMP_HI);
            scaledY = Math.min(Math.max(scaledY, CLAMP_LO), CLAMP_HI);

            if (
                !this.touchDataHandler.addTouchEvent(
                    count,
                    id,
                    touchType,
                    scaledX,
                    scaledY,
                    touch.radiusX,
                    touch.radiusY,
                    timeStamp
                )
            ) {
                this.droppedEventsCount += touchesToSend.length - count;
                break;
            }
            count++;
        }

        if (count == 0) {
            // No valid events to send
            return;
        }

        if (count > touchesToSend.length) {
            // More events than desired.
            Log.w("{ec05004}", "{4e60a66}", count, touchesToSend.length);
            return;
        }

        if (!this.touchDataHandler.sendTouchPacket(count)) {
            Log.e("{ec05004}", "{56a8404}");
        }
    }

    private touchStartListener = (evt: TouchEvent) => {
        const now = performance.now();
        let touchHandled = false;

        const touches = evt.changedTouches;
        let touchesToSend: TouchRecord[] = [];
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            if (this.shouldHandleTouch(touch)) {
                touchHandled = true;

                LatencyIndicator.getInstance().toggleIndicator();

                let touchRecord = this.addNewTouch(touch);
                touchesToSend.push(touchRecord);

                this.storeTouch(touch, touchRecord, TouchType.DOWN, now, evt);
            }
        }

        this.sendTouches(
            touchesToSend,
            TouchType.DOWN,
            this.isSafari ? performance.now() : evt.timeStamp
        );

        if (RagnarokSettings.storeTouch && RagnarokSettings.storeTouchGesture) {
            if (this.activeTouches.size == 8 && this.clearedAllTouches) {
                this.trimStoredTouchesFunc();
                this.logStoredTouchesFunc();
            }
        }

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    };

    private touchMoveListener = (evt: TouchEvent) => {
        const now = performance.now();
        let touchHandled = false;

        const touches = evt.changedTouches;
        let touchesToSend: TouchRecord[] = [];
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            if (this.shouldHandleTouch(touch)) {
                let touchRecord = this.activeTouches.get(touch.identifier);
                if (touchRecord) {
                    touchHandled = true;

                    touchRecord.update(touch);
                    touchesToSend.push(touchRecord);

                    this.storeTouch(touch, touchRecord, TouchType.MOVE, now, evt);
                }
            }
        }

        this.sendTouches(
            touchesToSend,
            TouchType.MOVE,
            this.isSafari ? performance.now() : evt.timeStamp
        );

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    };

    private touchEnd(evt: TouchEvent, type: TouchType) {
        const now = performance.now();
        let touchHandled = false;

        let removedIds = [];
        const touches = evt.changedTouches;
        let touchesToSend: TouchRecord[] = [];
        for (let i = 0; i < touches.length; i++) {
            const touch = touches[i];
            if (this.shouldHandleTouch(touch)) {
                let touchRecord = this.activeTouches.get(touch.identifier);
                removedIds.push(touch.identifier);
                if (touchRecord) {
                    touchHandled = true;

                    LatencyIndicator.getInstance().toggleIndicator();

                    touchRecord.update(touch);
                    touchesToSend.push(touchRecord);

                    this.storeTouch(touch, touchRecord, type, now, evt);
                }
            }
        }

        this.sendTouches(touchesToSend, type, this.isSafari ? performance.now() : evt.timeStamp);
        for (const id of removedIds) {
            this.removeTouch(id);
        }

        if (RagnarokSettings.storeTouch) {
            if (this.activeTouches.size == 0) {
                this.clearedAllTouches = true;
            } else {
                this.clearedAllTouches = false;
            }
        }

        if (this.gestureHandler.shouldPreventDefaultTouch() && touchHandled) {
            evt.preventDefault();
        }
    }

    private touchCancelListener = (evt: TouchEvent) => {
        this.touchEnd(evt, TouchType.CANCEL);
    };

    private touchEndListener = (evt: TouchEvent) => {
        this.touchEnd(evt, TouchType.UP);
    };

    constructor(
        private target: HTMLElement,
        private videoAddEventListener: (eventName: string, handler: any, options?: any) => void,
        private videoRemoveEventListener: (eventName: string, handler: any, options?: any) => void,
        private touchDataHandler: TouchDataHandler,
        private gestureHandler: GestureHandler,
        platformDetails: PlatformDetails
    ) {
        this.trimStoredTouchesFunc = this.trimStoredTouches.bind(this);
        this.logStoredTouchesFunc = this.logStoredTouches.bind(this);
        this.isSafari = IsSafari(platformDetails);
    }

    start() {
        this.droppedEventsCount = 0;
        const options = {
            passive: false
        };
        this.videoAddEventListener("touchstart", this.touchStartListener, options);
        this.videoAddEventListener("touchmove", this.touchMoveListener, options);
        this.videoAddEventListener("touchcancel", this.touchCancelListener, options);
        this.videoAddEventListener("touchend", this.touchEndListener, options);

        if (RagnarokSettings.storeTouch) {
            Log.d("{ec05004}", "{72764d2}", performance.now());

            // Clear stored touches, ready for new input.
            this.storedTouches = [];
            // Every so often, prune the list of stored touches so as to keep no more than
            // 30 seconds worth of data.
            this.storedTouchesTimer = window.setInterval(
                this.trimStoredTouchesFunc,
                1 * 60 * 1000 /* 1 minute */
            );
        }
    }

    stop() {
        if (RagnarokSettings.storeTouch) {
            if (this.storedTouchesTimer) {
                clearTimeout(this.storedTouchesTimer);
            }

            this.trimStoredTouchesFunc();
            this.logStoredTouchesFunc();
        }

        // Cancel any touches we're currently tracking.
        if (this.activeTouches.size) {
            this.sendTouches(
                Array.from(this.activeTouches.values()),
                TouchType.CANCEL,
                performance.now()
            );
            this.activeTouches.clear();
            this.activeProtocolIds.clear();
        }
        if (this.droppedEventsCount) {
            Log.w("{ec05004}", "{10858ae}", this.droppedEventsCount);
        }
        const options = {
            passive: false
        };
        this.videoRemoveEventListener("touchstart", this.touchStartListener, options);
        this.videoRemoveEventListener("touchmove", this.touchMoveListener, options);
        this.videoRemoveEventListener("touchcancel", this.touchCancelListener, options);
        this.videoRemoveEventListener("touchend", this.touchEndListener, options);
    }

    updateVideoState(videoState: VideoState, margins: BoundaryPair, videoZoomFactor: number) {
        this.margins = margins;
        this.scaleX = 65535.0 / videoState.displayVideoWidth;
        this.scaleY = 65535.0 / videoState.displayVideoHeight;

        this.scaleX /= videoZoomFactor;
        this.scaleY /= videoZoomFactor;
    }
}
