import { LogImpl } from "@gamestream/ragnarok";
import { AwsS3Client } from "./awsS3client";

export class LogBuffer {
    private static readonly FULL_MESSAGE = "Log is full\n";

    private buffer: Uint8Array;
    private maxLength: number;
    private encoder: TextEncoder = new TextEncoder();
    private logIndex: number = 0;
    private full: boolean = false;
    private logfile?: string;
    private sessionId: string;
    private subSessionId: string;
    private s3Client: AwsS3Client = new AwsS3Client();

    constructor(length: number, logfile?: string) {
        this.buffer = new Uint8Array(length + LogBuffer.FULL_MESSAGE.length);
        this.maxLength = length;
        this.logfile = logfile;
    }

    public clear() {
        this.encoder = new TextEncoder();
        this.logIndex = 0;
        this.full = false;
    }

    public log(message: string) {
        if (this.full) {
            return;
        }
        let bytes = this.encoder.encode(message + "\n");
        if (this.logIndex + bytes.byteLength > this.maxLength) {
            this.full = true;
            bytes = this.encoder.encode(LogBuffer.FULL_MESSAGE);
        }
        this.buffer.set(bytes, this.logIndex);
        this.logIndex += bytes.byteLength;
    }

    public download() {
        // Create a hidden link element and simulate a click on it. This is the only way
        // to give the downloaded file a custom filename.
        const link = document.createElement("a");
        link.style.display = "none";
        const blob = new Blob([new DataView(this.buffer.buffer, 0, this.logIndex)], {
            type: "text/plain"
        });
        const url = URL.createObjectURL(blob);
        link.href = url;
        if (this.logfile) {
            link.download = this.logfile;
        } else {
            link.download = `omniverse-streaming-${new Date().toISOString()}.log`;
        }
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    public setIds(sessionId: string, subSessionId: string) {
        this.sessionId = sessionId;
        this.subSessionId = subSessionId;
    }

    public upload(userId: string) {
        this.s3Client.uploadSubSession(
            this.sessionId,
            this.subSessionId,
            userId,
            this.buffer.subarray(0, this.logIndex)
        );
    }
}

export let Log = new LogImpl();
