import { VirtualGamepad, VirtualGamepadHandler } from "./virtualgamepad";
import { RagnarokSettings } from "../util/settings";
import { LatencyIndicator } from "../debug/latencyindicator";
import { TelemetryHandler } from "../telemetry/telemetryhandler";
import { GamepadDataHandler, VibrationHandler, Zoneless } from "../interfaces";
import { GamepadRSDMM } from "./gamepadrsdmm";
import { PlatformName, BrowserName, IsXboxEdge, PlatformDetails, Log } from "../dependencies";

const LOGTAG = "gamepadhandler";

interface ButtonMappingFunc {
    (controller: Gamepad): number;
}
interface TriggerMappingFunc {
    (controller: Gamepad): number;
}
interface AxesMappingFunc {
    (controller: Gamepad): readonly number[];
}

interface TelemetryData {
    gamepadName: string;
    state: number;
    buttons: number;
    triggers: number;
    axes: number[];
}

interface GamepadDetail {
    device: Gamepad;
    standard: boolean;
    getButtonMapping: ButtonMappingFunc;
    getTriggerMapping: TriggerMappingFunc;
    getAxesMapping: AxesMappingFunc;
    telemetryData: TelemetryData;
    simplifiedHaptics?: boolean;
}

declare interface GamepadEvent extends Event {
    readonly gamepad: Gamepad;
}

/// Timeout for gamepad scanning and updates when no gamepad has been detected.
/// This reduces unnecessary processing when no gamepad is connected.
const GAMEPAD_TICK_IDLE_MS = 100;
const GAMEPAD_TICK_DEFAULT_MS = 4;

/// Maximum number of 'instant' updates for the gamepad, before increasing the
/// timeout for the next update.
const MAX_GAMEPAD_INSTANT_UPDATES = 2;

/// Minimum amount of axis variation before we send an update to the server.
/// Avoids constant updates for 'noisy' axes, which show movement even with no-one touching them.
const MIN_GAMEPAD_AXIS_VARIATION = 0.01;

/// Milliseconds between haptics effects changes for controllers that require simplified haptics.
const MINIMAL_SIMPLIFIED_HAPTICS_DELAY = 500;

/// (Partial) Duration of a haptic effect that has no specified duration itself.
const PARTIAL_HAPTIC_DURATION = 1000;

// Bitmask for gamepad buttons in input protocol
// prettier-ignore
export const enum BUTTONS {
    A = 0x1000,
    B = 0x2000,
    X = 0x4000,
    Y = 0x8000,
    LB = 0x0100,      // Left bumper
    RB = 0x0200,      // Right bumper
    BACK = 0x0020,
    START = 0x0010,
    THUMBL = 0x0040,  // Left thumbstick
    THUMBR = 0x0080,  // Right thumbstick
    DPADU = 0x0001,   // D-Pad Up
    DPADD = 0x0002,   // D-Pad Down
    DPADL = 0x0004,   // D-Pad Left
    DPADR = 0x0008    // D-Pad Right
}

// Bitmask for gamepad triggers in input protocol
export const enum TRIGGERS {
    RT = 0xff00,
    LT = 0x00ff
}

class GamepadSnapshot {
    buttons: number;
    axes: readonly number[];
    triggers: number;
    timestamp: number;

    constructor(controller: GamepadDetail) {
        this.buttons = controller.getButtonMapping(controller.device);
        this.axes = controller.getAxesMapping(controller.device);
        this.triggers = controller.getTriggerMapping(controller.device);
        // Don't use the timestamp given in controller.device because it has an incorrect epoch on Safari.
        // Per the spec, it's supposed to be performance.timing.navigationStart.
        this.timestamp = performance.now();
    }

    private controllerAxesDiffer(previous: readonly number[], current: readonly number[]): boolean {
        const minVariation = MIN_GAMEPAD_AXIS_VARIATION;
        for (let i = 0; i < previous.length; i++) {
            if (Math.abs(previous[i] - current[i]) > minVariation) {
                // Noticeable variation to previous.
                return true;
            }
        }
        return false;
    }

    equalTo(rhs: GamepadSnapshot): boolean {
        return (
            this.buttons == rhs.buttons &&
            this.triggers == rhs.triggers &&
            !this.controllerAxesDiffer(this.axes, rhs.axes)
        );
    }
}

declare global {
    type GamepadHapticActuatorTypeEnhanced = "vibration" | "dual-rumble";

    interface GamepadHapticEffectParameters {
        duration: number; // ms
        startDelay: number; // ms
        weakMagnitude: number; // 0.0 - 1.0
        strongMagnitude: number; // 0.0 - 1.0
    }

    interface GamepadHapticActuatorEnhanced {
        type: GamepadHapticActuatorTypeEnhanced;
        playEffect?(
            type: GamepadHapticActuatorTypeEnhanced,
            params: GamepadHapticEffectParameters
        ): Promise<any>;
    }

    interface Gamepad {
        vibrationActuator?: GamepadHapticActuatorEnhanced;
    }
}

const EMPTY_EFFECT = {
    startDelay: 0,
    duration: 0,
    weakMagnitude: 0,
    strongMagnitude: 0
};

export class GamepadHandler implements VibrationHandler {
    private gamepadDataHandlers: GamepadDataHandler[];
    private telemetry?: TelemetryHandler;
    private gamepadConnectedFunc: any;
    private gamepadDisconnectedFunc: any;
    private gamepadDetails: (GamepadDetail | null)[];
    private gamepadBitmap: number = 0;
    private gamepadTimer: number = 0;
    private gamepadPollInterval: number;
    private gamepadTickFunc: any;
    private gamepadSnapshotPrevious: (GamepadSnapshot | null)[];
    private gamepadTimestamps: number[];
    private hapticsStrongMagnitude: number[] = [];
    private hapticsWeakMagnitude: number[] = [];
    private lastHapticsEffect: number[] = [];
    private hapticsSupported: boolean = false;
    private hapticsEnabled: boolean = false;
    private hapticsState: boolean = false;
    private allowHaptics: boolean;
    private isUserInputEnable: boolean = false;
    private firstTimeInputEnabled: boolean = false;
    private inputEnabledStateBeforeUserIdlePendingOverlay: boolean;
    private virtualGamepad: VirtualGamepad;
    private virtualGamepadHandler: VirtualGamepadHandler;
    private windowAddEventListener: any;
    private windowRemoveEventListener: any;
    private gamepadDataSender: GamepadDataHandler | undefined;
    private rsdmmHandler: GamepadRSDMM | undefined;
    private rsdmmActive: boolean = false;
    private prevThumbstickDown: number = 0;
    private seenThumbUp = false;
    private isSafariOlder: boolean = false;
    private isSafari13: boolean = false;
    private isSafari14: boolean = false;
    private isChromeOs: boolean = false;
    private isWebOs: boolean = false;
    private isTizenOs: boolean = false;
    private platformDetails: PlatformDetails;
    private gamepadScannedOnce: boolean = false;
    private virtualGamepadTelemetry?: TelemetryData;
    private previousGamepadTickTs?: number;
    private maxSchedulingDelay: number = 0;
    constructor(platformDetails: PlatformDetails, zoneless?: Zoneless) {
        this.windowAddEventListener =
            zoneless && zoneless.windowAddEventListener
                ? zoneless.windowAddEventListener.bind(window)
                : window.addEventListener.bind(window);
        this.windowRemoveEventListener =
            zoneless && zoneless.windowRemoveEventListener
                ? zoneless.windowRemoveEventListener.bind(window)
                : window.removeEventListener.bind(window);

        this.gamepadConnectedFunc = this.gamepadConnected.bind(this);
        this.gamepadDisconnectedFunc = this.gamepadDisconnected.bind(this);
        this.gamepadTickFunc = this.gamepadTick.bind(this);
        this.gamepadDetails = [];
        this.gamepadPollInterval = GAMEPAD_TICK_IDLE_MS;
        this.gamepadSnapshotPrevious = [];
        this.gamepadTimestamps = [];
        this.gamepadDataHandlers = [];

        this.platformDetails = platformDetails;
        const osName = platformDetails.os;
        const osVersion = platformDetails.osVer;
        const isSafari =
            (osName == PlatformName.IOS || osName == PlatformName.IPADOS) &&
            platformDetails.browser == BrowserName.SAFARI;
        this.isSafari13 = isSafari && osVersion.startsWith("13");
        this.isSafari14 = isSafari && osVersion.startsWith("14");
        this.isSafariOlder = isSafari && osVersion == "12-";
        this.isChromeOs = osName == PlatformName.CHROME_OS;
        this.isWebOs = osName == PlatformName.WEBOS;
        this.isTizenOs = osName == PlatformName.TIZEN;

        this.inputEnabledStateBeforeUserIdlePendingOverlay = true;

        this.virtualGamepad = {
            v_index: 0,
            v_enabled: false,
            v_connected: false,
            v_buttons: 0,
            v_trigger: 0,
            v_axes: [],
            v_updated: false
        };

        this.virtualGamepadHandler = new VirtualGamepadHandler(this.virtualGamepad);

        this.allowHaptics =
            RagnarokSettings.allowHaptics ?? RagnarokSettings.ragnarokConfig.allowHaptics ?? true;

        Log.d("{515a922}", "{b0c674b}");
    }

    private connectVirtualGamepad(): boolean {
        if (!this.virtualGamepad.v_connected) {
            Log.i("{515a922}", "{132d93a}", this.virtualGamepad.v_index);
            this.virtualGamepad.v_connected = true;
            this.virtualGamepadTelemetry = {
                gamepadName: "Nvidia Virtual Gamepad",
                state: 0,
                buttons: 0,
                triggers: 0,
                axes: []
            };
            this.addGamepadCommon(this.virtualGamepad.v_index, false);
            return true;
        }
        return false;
    }

    private sendVirtualGamepadTelemetry() {
        if (this.virtualGamepadTelemetry) {
            this.telemetry?.emitGamepadEvent(
                this.virtualGamepadTelemetry.gamepadName,
                "0",
                "0",
                0,
                false,
                0,
                true,
                this.virtualGamepadTelemetry.state,
                this.getEventMap(this.virtualGamepadTelemetry)
            );
            this.virtualGamepadTelemetry = undefined;
        }
    }

    private disconnectVirtualGamepad(): boolean {
        if (this.virtualGamepad.v_connected) {
            Log.i("{515a922}", "{98ac7c2}", this.virtualGamepad.v_index);
            this.virtualGamepad.v_connected = false;
            this.sendVirtualGamepadTelemetry();
            return this.removeGamepadCommon(this.virtualGamepad.v_index);
        }
        return false;
    }

    /// The gamepad bitmap is 16 bits. The lower 8 bits indicate if a gamepad is plugged in
    private getGamepadBitMask(index: number): number {
        return 1 << index;
    }

    /// The gamepad bitmap is 16 bits. The upper 8 bits indicate if a plugged-in gamepad is a Xinput/Microsoft (MS) one
    private getMsBitMask(index: number): number {
        return 1 << (index + 8);
    }

    private addGamepadCommon(index: number, xinput: boolean) {
        // Common setup for virtual and physical controllers.
        if (this.gamepadBitmap == 0) {
            this.gamepadPollInterval =
                RagnarokSettings.gamepadPollInterval ??
                RagnarokSettings.ragnarokConfig.gamepadPollInterval ??
                GAMEPAD_TICK_DEFAULT_MS;
            this.resetGamepadTimer();
        }
        this.gamepadBitmap |= this.getGamepadBitMask(index);
        const msMask = this.getMsBitMask(index);
        if (xinput) {
            this.gamepadBitmap |= msMask;
        } else {
            this.gamepadBitmap &= ~msMask;
        }
    }

    private removeGamepadCommon(index: number): boolean {
        if (index === this.virtualGamepad.v_index) {
            // Only continue if both physical and virtual gamepad are gone
            let details = !!this.gamepadDetails[index];
            if (this.virtualGamepad.v_connected || details) {
                return false;
            }
        }

        this.gamepadBitmap &= ~this.getGamepadBitMask(index);
        this.gamepadBitmap &= ~this.getMsBitMask(index);
        if (this.gamepadBitmap == 0) {
            this.gamepadPollInterval = GAMEPAD_TICK_IDLE_MS;
            this.resetGamepadTimer();
        }

        return true;
    }

    public removeGamepadDataHandler(gamepadDataHandler: GamepadDataHandler) {
        const index = this.gamepadDataHandlers.indexOf(gamepadDataHandler);
        if (index > -1) {
            this.gamepadDataHandlers.splice(index, 1);
        }
    }

    public addGamepadDataHandler(gamepadDataHandler: GamepadDataHandler) {
        this.gamepadDataHandlers.push(gamepadDataHandler);
    }

    private resetGamepadDataHandlerState(gamepadDataHandler: GamepadDataHandler) {
        for (let details of this.gamepadDetails) {
            if (!details) {
                continue;
            }

            let controller = details.device;

            if (!this.isSuitableGamepad(controller)) {
                // No support for non-standard mappings yet
                continue;
            }

            gamepadDataHandler.gamepadStateUpdateHandler(
                0, // 0-based index of gamepad
                controller.index,
                0, // buttons
                0, // triggers
                [0, 0, 0, 0], // axes
                performance.now(), // timestamp
                this.gamepadBitmap,
                controller.id
            );
            gamepadDataHandler.finalizeGamepadData(1);
        }
    }

    public setGamepadDataSender(gamepadDataSender: GamepadDataHandler) {
        if (this.gamepadDataSender) {
            this.removeGamepadDataHandler(this.gamepadDataSender);
            this.resetGamepadDataHandlerState(this.gamepadDataSender);
        }
        this.gamepadDataSender = gamepadDataSender;
        this.addGamepadDataHandler(this.gamepadDataSender);
    }

    public setGamepadRSDMM(rsdmmHandler: GamepadRSDMM) {
        if (RagnarokSettings.rsdmm) {
            if (this.rsdmmHandler) {
                this.removeGamepadDataHandler(this.rsdmmHandler);
            }
            this.rsdmmHandler = rsdmmHandler;
            // Don't automatically add the RSDMM handler to the gamepadDataHandler set.
            // That will happen when activating RSDMM mode.
        }
    }

    public enterRsdmmMode() {
        if (this.gamepadDataSender) {
            this.removeGamepadDataHandler(this.gamepadDataSender);
            this.resetGamepadDataHandlerState(this.gamepadDataSender);
        }
        if (this.rsdmmHandler) {
            this.addGamepadDataHandler(this.rsdmmHandler);
            if (this.isUserInputEnable) {
                this.rsdmmHandler.start();
            }
            this.rsdmmActive = true;
        }
    }

    public exitRsdmmMode() {
        if (this.rsdmmHandler) {
            this.rsdmmHandler.stop();
            this.rsdmmHandler.reset();
            this.removeGamepadDataHandler(this.rsdmmHandler);
            this.rsdmmActive = false;
        }
        if (this.gamepadDataSender) {
            this.addGamepadDataHandler(this.gamepadDataSender);
        }
    }

    /**
     * Toggle RSDMM state, or set it to the provided state (if different from current).
     */
    public toggleRsdmmMode(enable?: boolean) {
        const alreadyActive = this.rsdmmActive;

        if (enable == alreadyActive) {
            return;
        }

        if (alreadyActive) {
            this.exitRsdmmMode();
        } else {
            this.enterRsdmmMode();
        }
    }

    public isRsdmmActive(): boolean {
        return this.rsdmmActive;
    }

    public addTelemetry(telemetry: TelemetryHandler) {
        this.telemetry = telemetry;
    }

    private getPlugState(state: number, onEnd: boolean = false): number {
        if (!this.gamepadScannedOnce) {
            // gamepad is connected in the beginning of stream
            state |= 2;
        }
        if (onEnd) {
            state |= 1;
        }
        return state;
    }

    private getTelemetryData(controller: Gamepad, gamepadName: string): TelemetryData {
        return {
            gamepadName,
            state: this.gamepadDetails[controller.index]
                ? this.gamepadDetails[controller.index]!.telemetryData.state
                : this.getPlugState(0),
            buttons: this.gamepadDetails[controller.index]
                ? this.gamepadDetails[controller.index]!.telemetryData.buttons
                : 0,
            triggers: this.gamepadDetails[controller.index]
                ? this.gamepadDetails[controller.index]!.telemetryData.triggers
                : 0,
            axes: this.gamepadDetails[controller.index]
                ? this.gamepadDetails[controller.index]!.telemetryData.axes
                : []
        };
    }

    private getStandardGamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: true,
            getButtonMapping: this.getStandardButtons,
            getTriggerMapping: this.getStandardTrigger,
            getAxesMapping: this.getStandardAxes,
            telemetryData: this.getTelemetryData(controller, "Standard Gamepad")
        };
    }

    private getStandardGamepadSimplifiedHapticsDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: true,
            getButtonMapping: this.getStandardButtons,
            getTriggerMapping: this.getStandardTrigger,
            getAxesMapping: this.getStandardAxes,
            telemetryData: this.getTelemetryData(controller, "Standard Gamepad"),
            simplifiedHaptics: true
        };
    }

    private getShieldGamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: true,
            getButtonMapping: this.getStandardButtons,
            getTriggerMapping: this.getShieldTrigger,
            getAxesMapping: this.getStandardAxes,
            telemetryData: this.getTelemetryData(controller, "Nvidia Shield Gamepad")
        };
    }

    private getSafari13GamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: true, // standard-ish
            getButtonMapping: this.getSafari13Buttons,
            getTriggerMapping: this.getStandardTrigger,
            getAxesMapping: this.getSafari13Axes,
            telemetryData: this.getTelemetryData(controller, "Standard Gamepad")
        };
    }

    private getDualSenseGamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: false,
            getButtonMapping: this.getDualSenseButtons.bind(this),
            getTriggerMapping: this.getAxesTrigger.bind(this, 3, 4),
            getAxesMapping: this.get0125Axes,
            telemetryData: this.getTelemetryData(controller, "Dual Sense Gamepad")
        };
    }

    private getXboxSeriesGamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: false,
            getButtonMapping: this.getXboxSeriesButtons.bind(this),
            getTriggerMapping: this.getAxesTrigger.bind(this, 3, 4),
            getAxesMapping: this.get0125Axes,
            telemetryData: this.getTelemetryData(controller, "Xbox Series Gamepad")
        };
    }

    private getXboxSeriesWiredGamepadDetail(controller: Gamepad): GamepadDetail {
        return {
            device: controller,
            standard: false,
            getButtonMapping: this.getXboxSeriesWiredButtons.bind(this),
            getTriggerMapping: this.getAxesTrigger.bind(this, 2, 5),
            getAxesMapping: this.get0134Axes,
            telemetryData: this.getTelemetryData(controller, "Xbox Series Wired Gamepad")
        };
    }

    private getGamepadDetail(controller: Gamepad): GamepadDetail | null {
        // Must check in the correct order - gamepads that claim 'standard' but aren't must be checked before
        // general 'standard' gamepads, for example.

        // Safari gamepad support (iOS/iPadOS versions):
        //  12 and earlier - we cannot support
        //  13             - supportable, but not necessarily all controls
        //  14 beta        - supportable, but doesn't claim 'standard' mapping
        //  14 and later   - supportable with 'standard' mapping

        if (this.isSafari14) {
            // Workaround Safari 14 beta bug:
            // https://bugs.webkit.org/show_bug.cgi?id=215486
            // Just assume all Safari 14 gamepads are standard until this is fixed.
            return this.getStandardGamepadDetail(controller);
        } else if (this.isSafari13) {
            return this.getSafari13GamepadDetail(controller);
        } else if (this.isSafariOlder) {
            // Really can't support older Safari versions.
            return null;
        } else if (this.isShieldGamepad(controller) && !(this.isWebOs || this.isTizenOs)) {
            // Shield gamepad is completely standard on WebOS and Tizen, no need to special case it
            return this.getShieldGamepadDetail(controller);
        } else if (this.isBluetoothXboxWithHapticsProblems(controller)) {
            return this.getStandardGamepadSimplifiedHapticsDetail(controller);
        } else if (this.isStandardGamepad(controller)) {
            return this.getStandardGamepadDetail(controller);
        } else if (this.isDualSenseGamepad(controller)) {
            return this.getDualSenseGamepadDetail(controller);
        } else if (this.isXboxSeries(controller)) {
            return this.getXboxSeriesGamepadDetail(controller);
        } else if (this.isXboxSeriesWired(controller)) {
            return this.getXboxSeriesWiredGamepadDetail(controller);
        } else {
            Log.e("{515a922}", "{be4bfd9}", controller);
            return null;
        }
    }

    private gamepadBitmapUpdateHandler() {
        for (const gamepadDataHandler of this.gamepadDataHandlers) {
            gamepadDataHandler.gamepadBitmapUpdateHandler(this.gamepadBitmap);
        }
    }

    private gamepadConnected(evt: GamepadEvent) {
        if (this.addGamepad(evt.gamepad, evt.gamepad.index)) {
            this.gamepadBitmapUpdateHandler();
        }
    }

    private gamepadDisconnected(evt: GamepadEvent) {
        if (this.deleteGamepad(evt.gamepad.index, true)) {
            this.gamepadBitmapUpdateHandler();
        }
    }

    private resetGamepadTimer() {
        if (this.gamepadTimer != 0) {
            clearInterval(this.gamepadTimer);
            this.gamepadTimer = 0;
        }
        if (this.isUserInputEnable && !RagnarokSettings.gamepadRaf) {
            this.gamepadTimer = window.setInterval(this.gamepadTickFunc, this.gamepadPollInterval);
        } else {
            this.gamepadTimer = 0;
        }
    }

    private getDeviceIds(deviceId: string): [string, string] {
        let result: [string, string] = ["-1", "-1"];
        // string looks like: Logitech Dual Action (STANDARD GAMEPAD Vendor: 046d Product: c216)
        const regex = /Vendor:[ ]+([\w\d]+).+Product:[ ]+([\w\d]+)/;
        const groups = regex.exec(deviceId);
        if (groups && groups.length >= 3) {
            result = [groups[1], groups[2]];
        }
        return result;
    }

    public disconnectAllGamepads() {
        if (this.gamepadBitmap) {
            let isPrimary = true;
            for (let details of this.gamepadDetails) {
                if (!details) {
                    continue;
                }
                details.telemetryData.state = this.getPlugState(details.telemetryData.state, true);
                this.deleteGamepad(details.device.index, true, isPrimary);
                isPrimary = false;
            }
            this.sendVirtualGamepadTelemetry();
            this.gamepadBitmap = 0;
            this.virtualGamepad.v_connected = false;
            this.gamepadBitmapUpdateHandler();
            this.gamepadPollInterval = GAMEPAD_TICK_IDLE_MS;
            this.resetGamepadTimer();
            this.gamepadDetails = [];
            this.gamepadScannedOnce = false;
        }
    }

    private isSuitableGamepad(gamepad: Gamepad) {
        if (gamepad.mapping == "standard" || this.isSafari14 || this.isSafari13) {
            return true;
        }
        if (this.isDualSenseGamepad(gamepad)) {
            return true;
        }
        if (this.isXboxSeries(gamepad) || this.isXboxSeriesWired(gamepad)) {
            return true;
        }

        return false;
    }

    private isStandardGamepad(gamepad: Gamepad) {
        return gamepad.mapping == "standard";
    }

    private isBluetoothXboxWithHapticsProblems(gamepad: Gamepad) {
        // Xbox controllers when connected over Bluetooth can have expensive haptics,
        // so use a more simplified approach for them.
        // NB: This won't match on Windows, but I've not seen the problem on Windows, only macOS.
        // prettier-ignore
        return (
            gamepad.id.includes("Vendor: 045e") &&
            (gamepad.id.includes("Product: 0b13") || // Xbox Series
             gamepad.id.includes("Product: 0b20") || // Xbox One (1708) - updated firmware?
             gamepad.id.includes("Product: 0b05") || // Xbox Elite II
             gamepad.id.includes("Product: 02e0") || // Xbox One S rev 1
             gamepad.id.includes("Product: 02fd"))   // Xbox One S rev 2
        );
    }

    private isShieldGamepad(gamepad: Gamepad) {
        return gamepad.mapping == "standard" && gamepad.id.includes("Vendor: 0955");
    }

    private isDualSenseGamepad(gamepad: Gamepad) {
        return (
            gamepad.mapping != "standard" &&
            gamepad.id.includes("Vendor: 054c") &&
            gamepad.id.includes("Product: 0ce6")
        );
    }

    private isXboxSeries(gamepad: Gamepad) {
        return (
            gamepad.mapping != "standard" &&
            gamepad.id.includes("Vendor: 045e") &&
            gamepad.id.includes("Product: 0b13")
        );
    }

    private isXboxSeriesWired(gamepad: Gamepad) {
        return (
            this.isChromeOs &&
            gamepad.mapping != "standard" &&
            gamepad.id.includes("Vendor: 045e") &&
            gamepad.id.includes("Product: 0b12")
        );
    }

    private isXinputGamepad(gamepad: Gamepad): boolean {
        return gamepad.id.includes("Xbox") || gamepad.id.includes("xinput");
    }

    /// Add gamepad details to our set of details, updating gamepad state.
    /// Returns true if this modifies the number of gamepads,
    /// rather than replacing an existing gamepad detail with a new detail.
    private addGamepad(gamepad: Gamepad, index: number): boolean {
        if (this.isSuitableGamepad(gamepad)) {
            const gamepadDetail = this.getGamepadDetail(gamepad);
            if (gamepadDetail) {
                this.hapticsSupported =
                    this.hapticsSupported || this.hasHaptics(gamepadDetail.device);
                this.setAndSendHapticsEnabled();
                const oldBitmap = this.gamepadBitmap;
                const prevGpValid: boolean = !!this.gamepadDetails[index];
                this.gamepadDetails[index] = gamepadDetail;
                if (this.gamepadTimestamps[index] === undefined) {
                    this.gamepadTimestamps[index] = 0;
                }
                const xinput = this.isXinputGamepad(gamepad);
                this.addGamepadCommon(index, xinput);

                const bitmapChanged = oldBitmap !== this.gamepadBitmap;
                if (bitmapChanged) {
                    const action = prevGpValid ? "Changing" : "Adding";
                    Log.i("{515a922}", "{96051ae}", action, index, xinput, gamepadDetail.device.id);
                }
                return bitmapChanged;
            } else {
                // Handle suitable gamepads for which we cannot get details.
                // These will be those which we deny, due to some quirks in behaviour.
                for (const gamepadDataHandler of this.gamepadDataHandlers) {
                    gamepadDataHandler.connectUnsupportedGamepad(gamepad);
                }

                return this.deleteGamepad(index);
            }
        } else {
            // Gamepad is not supported
            for (const gamepadDataHandler of this.gamepadDataHandlers) {
                gamepadDataHandler.connectUnsupportedGamepad(gamepad);
            }

            // Cope with disconnecting a suitable and connecting an unsuitable gamepad within one update timeout
            return this.deleteGamepad(index);
        }
    }

    private updateGamepad(gamepad: Gamepad, index: number): void {
        // Known to be suitable.
        // Gamepad detail only needs updating, not re-creating from scratch.
        let detail: GamepadDetail = this.gamepadDetails[index]!;
        detail.device = gamepad;
        // Haptics support is unlikely to change
        this.hapticsSupported = this.hapticsSupported || this.hasHaptics(gamepad);
        this.setAndSendHapticsEnabled();
        // Bitmap will not change.
        // Timestamp wil already be set
    }

    private isPrimaryGamepad(index: number): boolean {
        let result = false;
        for (const details of this.gamepadDetails) {
            if (details) {
                if (index === details.device.index) {
                    result = true;
                }
                break;
            }
        }
        return result;
    }

    private deleteGamepad(
        index: number,
        reportTelemetry: boolean = false,
        isPrimary: boolean | undefined = undefined
    ): boolean {
        let details = this.gamepadDetails[index];
        if (details) {
            Log.i("{515a922}", "{1b26b8a}", index, details.device.id);
            const lastSnapshot = this.gamepadSnapshotPrevious[index];
            if (lastSnapshot) {
                const timeSinceUpdate = performance.now() - lastSnapshot.timestamp;
                if (timeSinceUpdate >= 3000 && lastSnapshot.axes.some(x => Math.abs(x) > 0.5)) {
                    Log.w("{515a922}", "{fde5612}", timeSinceUpdate, lastSnapshot.axes.join());
                    this.telemetry?.emitDebugEvent(
                        "GamepadStuck",
                        details.device.id,
                        timeSinceUpdate.toString(),
                        lastSnapshot.axes.join()
                    );
                }
            }
            if (reportTelemetry) {
                const [vid, pid] = this.getDeviceIds(details.device.id);
                this.telemetry?.emitGamepadEvent(
                    details.telemetryData.gamepadName,
                    vid,
                    pid,
                    details.device.index,
                    details.device.hapticActuators
                        ? details.device.hapticActuators.length > 0
                        : false,
                    0,
                    isPrimary ?? this.isPrimaryGamepad(details.device.index),
                    details.telemetryData.state,
                    this.getEventMap(details.telemetryData)
                );
            }
            delete this.gamepadDetails[index];
            delete this.gamepadTimestamps[index];
            delete this.gamepadSnapshotPrevious[index];
            this.hapticsSupported = this.isHapticsSupported();
            this.setAndSendHapticsEnabled();
            return this.removeGamepadCommon(index);
        }
        return false;
    }

    private gamepadAlreadySeen(gamepad: Gamepad, index: number): boolean {
        // If we've previously seen a gamepad with the same ID at the same index
        // then assume it's the same gamepad.  Even if it isn't, if the IDs and
        // indices are the same, they are for all intents and purposes
        // identical.
        return gamepad.id != undefined && gamepad.id == this.gamepadDetails[index]?.device?.id;
    }

    private scangamepads() {
        let sendUpdate: boolean = false;
        if (this.virtualGamepad.v_enabled && !this.virtualGamepad.v_connected) {
            sendUpdate = this.connectVirtualGamepad();
        } else if (!this.virtualGamepad.v_enabled && this.virtualGamepad.v_connected) {
            sendUpdate = this.disconnectVirtualGamepad();
        }

        let gamepads: (Gamepad | null)[] = navigator.getGamepads();
        for (var i = 0; i < gamepads.length; i++) {
            let gp = gamepads[i];
            if (gp) {
                if (this.gamepadAlreadySeen(gp, i)) {
                    // Already seen this device, so just update rather than add a new gamepad.
                    this.updateGamepad(gp, i);
                } else {
                    if (this.addGamepad(gp, i)) {
                        sendUpdate = true;
                    }
                }
            } else {
                if (this.deleteGamepad(i, true)) {
                    sendUpdate = true;
                }
            }
        }
        if (sendUpdate) {
            this.gamepadBitmapUpdateHandler();
        }
        this.gamepadScannedOnce = true;
    }

    private getStandardButtons(controller: Gamepad): number {
        let buttons = controller.buttons;
        return (
            (buttons[0].value ? BUTTONS.A : 0) | // A
            (buttons[1].value ? BUTTONS.B : 0) | // B
            (buttons[2].value ? BUTTONS.X : 0) | // X
            (buttons[3].value ? BUTTONS.Y : 0) | // Y
            (buttons[4].value ? BUTTONS.LB : 0) | // LB
            (buttons[5].value ? BUTTONS.RB : 0) | // RB
            //(buttons[6].value ? 0x     : 0) |  // trigger left  (no button)
            //(buttons[7].value ? 0x     : 0) |  // trigger right (no button)
            (buttons[8] && buttons[8].value ? BUTTONS.BACK : 0) | // Back
            (buttons[9] && buttons[9].value ? BUTTONS.START : 0) | // Start
            (buttons[10] && buttons[10].value ? BUTTONS.THUMBL : 0) | // Thumbstick Left
            (buttons[11] && buttons[11].value ? BUTTONS.THUMBR : 0) | // Thumbstick Right
            (buttons[12] && buttons[12].value ? BUTTONS.DPADU : 0) | // D-pad Up
            (buttons[13] && buttons[13].value ? BUTTONS.DPADD : 0) | // D-pad Down
            (buttons[14] && buttons[14].value ? BUTTONS.DPADL : 0) | // D-pad Left
            (buttons[15] && buttons[15].value ? BUTTONS.DPADR : 0) | // D-pad Right
            0
        );
    }

    private getEventMap(data: TelemetryData): string {
        const buttons = data.buttons;
        const triggers = data.triggers;
        const axes = data.axes;
        return (
            "A:" +
            ((BUTTONS.A & buttons) !== 0 ? "1" : "0") +
            "_B:" +
            ((BUTTONS.B & buttons) !== 0 ? "1" : "0") +
            "_X:" +
            ((BUTTONS.X & buttons) !== 0 ? "1" : "0") +
            "_Y:" +
            ((BUTTONS.Y & buttons) !== 0 ? "1" : "0") +
            "_LB:" +
            ((BUTTONS.LB & buttons) !== 0 ? "1" : "0") + // Left bumber
            "_LSB:" +
            ((BUTTONS.THUMBL & buttons) !== 0 ? "1" : "0") + // Left Stick press
            "_RB:" +
            ((BUTTONS.RB & buttons) !== 0 ? "1" : "0") + // Right bumper
            "_RSB:" +
            ((BUTTONS.THUMBR & buttons) !== 0 ? "1" : "0") + // Right stick press
            "_LT:" +
            ((TRIGGERS.LT & triggers) !== 0 ? "1" : "0") + // Trigger Left
            "_RT:" +
            ((TRIGGERS.RT & triggers) !== 0 ? "1" : "0") + // Trigger right
            "_DU:" +
            ((BUTTONS.DPADU & buttons) !== 0 ? "1" : "0") + // Dpad up
            "_DD:" +
            ((BUTTONS.DPADD & buttons) !== 0 ? "1" : "0") + // Dpad down
            "_DL:" +
            ((BUTTONS.DPADL & buttons) !== 0 ? "1" : "0") + // Dpad left
            "_DR:" +
            ((BUTTONS.DPADR & buttons) !== 0 ? "1" : "0") + // Dpad right
            "_ST:" +
            ((BUTTONS.START & buttons) !== 0 ? "1" : "0") + // Start button
            "_BA:" +
            ((BUTTONS.BACK & buttons) !== 0 ? "1" : "0") + // Back button
            //"_SE:" + ((0x8000&buttons) !== 0 ? "1" : "0") + // select button
            "_LS:" +
            (axes.length >= 2 && (axes[0] === 2 || axes[1] === 2) ? "1" : "0") + // Left stick move
            "_RS:" +
            (axes.length === 4 && (axes[2] === 2 || axes[3] === 2) ? "1" : "0") // Right stick move
        );
    }

    private getStandardTrigger(controller: Gamepad): number {
        // Triggers are buttons [6,7] in standard gamepad, but axes in nvst protocol
        let triggerL = Math.round(controller.buttons[6].value * 255);
        let triggerR = Math.round(controller.buttons[7].value * 255);

        return ((triggerR & 0xff) << 8) | (triggerL & 0xff);
    }

    private getShieldTrigger(controller: Gamepad): number {
        // Triggers are reversed on a Shield controller
        let triggerL = Math.round(controller.buttons[7].value * 255);
        let triggerR = Math.round(controller.buttons[6].value * 255);

        return ((triggerR & 0xff) << 8) | (triggerL & 0xff);
    }

    private getAxesTrigger(hIndex: number, vIndex: number, controller: Gamepad): number {
        // Triggers are axes on some controllers, running from -1 (unpressed) to 1 (pressed)
        let triggerL = Math.round((controller.axes[hIndex] + 1.0) * 127.5);
        let triggerR = Math.round((controller.axes[vIndex] + 1.0) * 127.5);

        return ((triggerR & 0xff) << 8) | (triggerL & 0xff);
    }

    private getStandardAxes(controller: Gamepad): readonly number[] {
        // Axes in standard Gamepad object are:
        //  0,1 - left stick  X,Y  (-ve left, -ve up)
        //  2,3 - right stick X,Y  (-ve left, -ve up)
        return controller.axes;
    }

    // Safari 13 Gamepad Layout:
    // https://trac.webkit.org/browser/webkit/trunk/Source/WebCore/platform/gamepad/cocoa/GameControllerGamepad.mm?rev=243901#L59
    private getSafari13Axes(controller: Gamepad): readonly number[] {
        return [controller.axes[0], -controller.axes[1], controller.axes[2], -controller.axes[3]];
    }

    private get0125Axes(controller: Gamepad): readonly number[] {
        return [controller.axes[0], controller.axes[1], controller.axes[2], controller.axes[5]];
    }

    private get0134Axes(controller: Gamepad): readonly number[] {
        return [controller.axes[0], controller.axes[1], controller.axes[3], controller.axes[4]];
    }

    private getSafari13Buttons(controller: Gamepad): number {
        let buttons = controller.buttons;
        let axes = controller.axes;
        return (
            (buttons[0].value ? BUTTONS.A : 0) | // A
            (buttons[1].value ? BUTTONS.B : 0) | // B
            (buttons[2].value ? BUTTONS.X : 0) | // X
            (buttons[3].value ? BUTTONS.Y : 0) | // Y
            (buttons[4].value ? BUTTONS.LB : 0) | // LB
            (buttons[5].value ? BUTTONS.RB : 0) | // RB
            //(buttons[6].value ? 0x     : 0) |  // trigger left  (no button)
            //(buttons[7].value ? 0x     : 0) |  // trigger right (no button)
            // Back doesn't exist in Safari 13. FIXME: Use partial virtual gamepad here
            // Start doesn't exist in Safari 13. ""
            // Thumbstick Left doesn't exist in Safari 13. ""
            // Thumbstick Right doesn't exist in Safari 13. ""
            (axes[5] > 0 ? BUTTONS.DPADU : 0) | // D-pad Up
            (axes[5] < 0 ? BUTTONS.DPADD : 0) | // D-pad Down
            (axes[4] < 0 ? BUTTONS.DPADL : 0) | // D-pad Left
            (axes[4] > 0 ? BUTTONS.DPADR : 0) | // D-pad Right
            0
        );
    }

    private getDpadFromAxis(axisValue: number): number {
        let dpadAxis = Number.parseFloat(axisValue.toFixed(5));
        switch (dpadAxis) {
            case 0.71429:
                return BUTTONS.DPADL; // Left
            case -0.42857:
                return BUTTONS.DPADR; // Right
            case -1.0:
                return BUTTONS.DPADU; // Up
            case 0.14286:
                return BUTTONS.DPADD; // Down
            case 1.28571:
                return 0; // Centred
            case 1.0:
                return BUTTONS.DPADU | BUTTONS.DPADL; // Up+Left
            case -0.71429:
                return BUTTONS.DPADU | BUTTONS.DPADR; // Up+Right
            case 0.42857:
                return BUTTONS.DPADD | BUTTONS.DPADL; // Down+Left
            case -0.14286:
                return BUTTONS.DPADD | BUTTONS.DPADR; // Down+Right
            default:
                return 0;
        }
    }

    private getDpadFromTwoAxes(horiz: number, vert: number): number {
        return (
            (vert < 0 ? BUTTONS.DPADU : 0) | // D-pad Up
            (vert > 0 ? BUTTONS.DPADD : 0) | // D-pad Down
            (horiz < 0 ? BUTTONS.DPADL : 0) | // D-pad Left
            (horiz > 0 ? BUTTONS.DPADR : 0) | // D-pad Right
            0
        );
    }

    private getDualSenseButtons(controller: Gamepad): number {
        let buttons = controller.buttons;

        const dPad = this.isChromeOs
            ? this.getDpadFromTwoAxes(controller.axes[6], controller.axes[7])
            : this.getDpadFromAxis(controller.axes[9]);
        return (
            (buttons[1].value ? BUTTONS.A : 0) | // X      => A
            (buttons[2].value ? BUTTONS.B : 0) | // Circle => B
            (buttons[0].value ? BUTTONS.X : 0) | // Square => X
            (buttons[3].value ? BUTTONS.Y : 0) | // Circle => Y
            (buttons[4].value ? BUTTONS.LB : 0) | // L1 => LB
            (buttons[5].value ? BUTTONS.RB : 0) | // R1 => RB
            //(buttons[6].value ? 0x     : 0) |  // trigger left  (no button)
            //(buttons[7].value ? 0x     : 0) |  // trigger right (no button)
            (buttons[8].value ? BUTTONS.BACK : 0) | // Share => Back
            (buttons[9].value ? BUTTONS.START : 0) | // Menu  => Start
            (buttons[10].value ? BUTTONS.THUMBL : 0) | // Thumbstick Left
            (buttons[11].value ? BUTTONS.THUMBR : 0) | // Thumbstick Right
            dPad
        );
    }

    private getXboxSeriesButtons(controller: Gamepad): number {
        let buttons = controller.buttons;

        const dPad = this.getDpadFromAxis(controller.axes[9]);
        return (
            (buttons[0].value ? BUTTONS.A : 0) | // A
            (buttons[1].value ? BUTTONS.B : 0) | // B
            (buttons[3].value ? BUTTONS.X : 0) | // X
            (buttons[4].value ? BUTTONS.Y : 0) | // Y
            (buttons[6].value ? BUTTONS.LB : 0) | // LB
            (buttons[7].value ? BUTTONS.RB : 0) | // RB
            //(buttons[].value ? 0x     : 0) |  // trigger left  (no button)
            //(buttons[].value ? 0x     : 0) |  // trigger right (no button)
            (buttons[10].value ? BUTTONS.BACK : 0) | // Share => Back
            (buttons[11].value ? BUTTONS.START : 0) | // Menu  => Start
            (buttons[13].value ? BUTTONS.THUMBL : 0) | // Thumbstick Left
            (buttons[14].value ? BUTTONS.THUMBR : 0) | // Thumbstick Right
            dPad
        );
    }

    private getXboxSeriesWiredButtons(controller: Gamepad): number {
        let buttons = controller.buttons;

        const dPad = this.getDpadFromTwoAxes(controller.axes[6], controller.axes[7]);
        return (
            (buttons[0].value ? BUTTONS.A : 0) | // A
            (buttons[1].value ? BUTTONS.B : 0) | // B
            (buttons[2].value ? BUTTONS.X : 0) | // X
            (buttons[3].value ? BUTTONS.Y : 0) | // Y
            (buttons[4].value ? BUTTONS.LB : 0) | // LB
            (buttons[5].value ? BUTTONS.RB : 0) | // RB
            (buttons[6].value ? BUTTONS.BACK : 0) | // Share => Back
            (buttons[7].value ? BUTTONS.START : 0) | // Menu  => Start
            (buttons[9].value ? BUTTONS.THUMBL : 0) | // Thumbstick Left
            (buttons[10].value ? BUTTONS.THUMBR : 0) | // Thumbstick Right
            //(buttons[].value ? 0x     : 0) |  // trigger left  (no button)
            //(buttons[].value ? 0x     : 0) |  // trigger right (no button)
            dPad
        );
    }

    public postRender() {
        if (RagnarokSettings.gamepadEnabled && RagnarokSettings.gamepadRaf) {
            this.gamepadTick();
        }
    }

    public virtualGamepadUpdateHandler() {
        for (const gamepadDataHandler of this.gamepadDataHandlers) {
            gamepadDataHandler.virtualGamepadUpdateHandler(
                this.virtualGamepad.v_buttons,
                this.virtualGamepad.v_trigger,
                this.virtualGamepad.v_index,
                this.virtualGamepad.v_axes,
                this.gamepadBitmap
            );
        }
    }

    public getMainThreadSchedulingDelay(): number {
        return this.maxSchedulingDelay;
    }

    public resetMainThreadSchedulingDelay() {
        this.maxSchedulingDelay = 0;
    }

    private updateMainThreadSchedulingDelay() {
        const currentGamepadTickTs = performance.now();
        if (this.previousGamepadTickTs) {
            const schedulingDelay =
                currentGamepadTickTs - this.previousGamepadTickTs - this.gamepadPollInterval;
            this.maxSchedulingDelay = Math.max(this.maxSchedulingDelay, schedulingDelay);
        }
        this.previousGamepadTickTs = currentGamepadTickTs;
    }

    private gamepadTick() {
        this.updateMainThreadSchedulingDelay();
        this.scangamepads();
        let bitmap = 0;
        let packetCount = 0;
        for (let details of this.gamepadDetails) {
            if (!details) {
                continue;
            }

            let controller = details.device;

            // To avoid too many spurious updates, don't try to process or send data:
            //  * If the gamepad isn't one we can use
            //  * If the timestamp hasn't changed since last time we polled
            //  * If the details haven't changed (enough) since last time

            if (!this.isSuitableGamepad(controller)) {
                // No support for non-standard mappings yet
                continue;
            }

            if (details.device.timestamp <= this.gamepadTimestamps[controller.index]) {
                // No update; skip
                continue;
            } else {
                this.gamepadTimestamps[controller.index] = details.device.timestamp;
            }

            const prevSnapshot = this.gamepadSnapshotPrevious[controller.index];
            const curSnapshot = new GamepadSnapshot(details);
            if (prevSnapshot && prevSnapshot.equalTo(curSnapshot)) {
                // No difference in state, or not sufficient to send an update
                continue;
            } else {
                this.gamepadSnapshotPrevious[controller.index] = curSnapshot;
            }

            //
            // !! Keeping but commenting out this code.  For now, when both
            // !! physical and virtual gamepad are active, both are functional
            // !! to match the Android implementation.
            //
            // if (
            //     this.virtualGamepad.v_connected &&
            //     controller.index === this.virtualGamepad.v_index
            // ) {
            //     continue;
            // }
            //

            // Handle gamepad updates from here on.
            let trigger = details.getTriggerMapping(controller);
            // D-Pad is presented as buttons in standard gamepad, unlike in SDL
            //  (where it's axes).
            // Therefore, just treat it like a set of normal buttons and not
            //  worry about dPadNibble() etc.
            let buttons = details.getButtonMapping(controller);
            let axes = details.getAxesMapping(controller);

            if (RagnarokSettings.rsdmmThumbstickToggle) {
                const buttonType = IsXboxEdge(this.platformDetails)
                    ? BUTTONS.THUMBL
                    : BUTTONS.THUMBR;
                if (buttons & buttonType) {
                    // Has to be two press/release pairs to toggle.
                    // Otherwise down and movement (which is easy to happen) toggles and the state changes unexpectedly.

                    if (this.seenThumbUp) {
                        this.seenThumbUp = false;
                        const thumbNow = performance.now();
                        if (thumbNow < this.prevThumbstickDown + 500) {
                            // Toggle RSDMM mode
                            this.toggleRsdmmMode();
                            this.prevThumbstickDown = 0;
                        } else {
                            this.prevThumbstickDown = thumbNow;
                        }
                    }
                } else {
                    this.seenThumbUp = true;
                }
            }

            // Update all data handlers
            for (const gamepadDataHandler of this.gamepadDataHandlers) {
                gamepadDataHandler.gamepadStateUpdateHandler(
                    packetCount,
                    controller.index,
                    buttons,
                    trigger,
                    axes,
                    details.device.timestamp,
                    this.gamepadBitmap,
                    controller.id
                );
            }
            packetCount++;
            this.updateGamepadTelemetry(details.telemetryData, buttons, trigger, axes);
        }
        if (packetCount) {
            LatencyIndicator.getInstance().toggleIndicator();
            for (const gamepadDataHandler of this.gamepadDataHandlers) {
                gamepadDataHandler.finalizeGamepadData(packetCount);
            }
        }

        if (this.virtualGamepad.v_connected && this.virtualGamepad.v_updated) {
            this.virtualGamepadUpdateHandler();
            this.virtualGamepad.v_updated = false;
            if (this.virtualGamepadTelemetry) {
                this.updateGamepadTelemetry(
                    this.virtualGamepadTelemetry,
                    this.virtualGamepad.v_buttons,
                    this.virtualGamepad.v_trigger,
                    this.virtualGamepad.v_axes
                );
            }
        }
    }

    private updateGamepadTelemetry(
        telemetry: TelemetryData,
        buttons: number,
        trigger: number,
        axes: ReadonlyArray<number>
    ) {
        telemetry.buttons |= buttons;
        telemetry.triggers |= trigger;
        if (telemetry.axes.length === 0) {
            telemetry.axes = [axes[0], axes[1], axes[2], axes[3]];
        } else {
            telemetry.axes[0] =
                telemetry.axes[0] == 2 ? 2 : telemetry.axes[0] !== axes[0] ? 2 : telemetry.axes[0];
            telemetry.axes[1] =
                telemetry.axes[1] == 2 ? 2 : telemetry.axes[1] !== axes[1] ? 2 : telemetry.axes[1];
            telemetry.axes[2] =
                telemetry.axes[2] == 2 ? 2 : telemetry.axes[2] !== axes[2] ? 2 : telemetry.axes[2];
            telemetry.axes[3] =
                telemetry.axes[3] == 2 ? 2 : telemetry.axes[3] !== axes[3] ? 2 : telemetry.axes[3];
        }
    }

    private sendGamepadHaptics(enable: boolean) {
        if (this.hapticsState == enable) {
            return;
        } else {
            this.hapticsState = enable;
        }

        if (this.allowHaptics) {
            for (const gamepadDataHandler of this.gamepadDataHandlers) {
                gamepadDataHandler.sendGamepadHapticsControl?.(enable);
            }
        }
    }

    public enableUserInput() {
        this.isUserInputEnable = true;
        this.allowHaptics =
            RagnarokSettings.allowHaptics ?? RagnarokSettings.ragnarokConfig.allowHaptics ?? true;
        this.setAndSendHapticsEnabled();
        if (RagnarokSettings.gamepadEnabled) {
            this.windowAddEventListener("gamepadconnected", this.gamepadConnectedFunc);
            this.windowAddEventListener("gamepaddisconnected", this.gamepadDisconnectedFunc);
            this.resetGamepadTimer();
            if (this.rsdmmActive) {
                this.rsdmmHandler!.start();
            }
        }
    }

    public disableUserInput() {
        this.isUserInputEnable = false;
        this.setAndSendHapticsEnabled();
        if (RagnarokSettings.gamepadEnabled) {
            this.windowRemoveEventListener("gamepadconnected", this.gamepadConnectedFunc);
            this.windowRemoveEventListener("gamepaddisconnected", this.gamepadDisconnectedFunc);
            this.resetGamepadTimer();
            if (this.rsdmmActive) {
                this.rsdmmHandler!.stop();
                this.rsdmmHandler!.reset();
            }
        }
    }

    public getVirtualGamepadHandler(): VirtualGamepadHandler {
        return this.virtualGamepadHandler;
    }

    // VibrationHandler interface

    private isHapticsSupported(): boolean {
        let supported = false;
        for (let details of this.gamepadDetails) {
            if (details) {
                supported = supported || this.hasHaptics(details.device);
            }
        }
        return supported;
    }

    private setAndSendHapticsEnabled() {
        this.hapticsEnabled = this.isUserInputEnable && this.hapticsSupported;
        this.sendGamepadHaptics(this.hapticsEnabled);

        if (!this.hapticsEnabled) {
            // Clear haptics timers and reset the playing effect.
            let gamepads: (Gamepad | null)[] = navigator.getGamepads();
            for (let gamepad of gamepads) {
                if (gamepad) {
                    this.hapticsStrongMagnitude[gamepad.index] = 0;
                    this.hapticsWeakMagnitude[gamepad.index] = 0;
                    gamepad.vibrationActuator?.playEffect?.("dual-rumble", EMPTY_EFFECT);
                }
            }
        }
    }

    private hasHaptics(controller: Gamepad) {
        // TODO Support 'vibration' type controllers (if any exist) as well.
        return controller.vibrationActuator?.type == "dual-rumble";
    }

    public handleSimpleVibration(index: number, leftMotorSpeed: number, rightMotorSpeed: number) {
        // Weidly, seem to get two calls within a few milliseconds with the exact same values.

        if (!this.hapticsEnabled) {
            return;
        }

        // Server sends magnitude values but no duration with a simple command.  Therefore,
        // set up a one second effect and, when it finished, play it again until the server
        // sends either new effect values or the end-of-effect values, or the focus is lost
        // or the session ends.

        let details = this.gamepadDetails[index];
        if (details) {
            let gamepad = details.device;
            if (gamepad) {
                if (leftMotorSpeed != 0 || rightMotorSpeed != 0) {
                    const strongMag = leftMotorSpeed / 65535.0;
                    const weakMag = rightMotorSpeed / 65535.0;
                    const now = performance.now();

                    let delay = now - this.lastHapticsEffect[index];

                    this.hapticsStrongMagnitude[index] = strongMag;
                    this.hapticsWeakMagnitude[index] = weakMag;
                    if (!details.simplifiedHaptics || delay > MINIMAL_SIMPLIFIED_HAPTICS_DELAY) {
                        this.lastHapticsEffect[index] = now;

                        const vibrateController = () => {
                            gamepad.vibrationActuator?.playEffect?.("dual-rumble", {
                                startDelay: 0,
                                duration: PARTIAL_HAPTIC_DURATION,
                                weakMagnitude: this.hapticsWeakMagnitude[index],
                                strongMagnitude: this.hapticsStrongMagnitude[index]
                            });
                        };
                        vibrateController();
                    }
                } else {
                    // Stopping the effect.
                    // Clear any timer.
                    this.lastHapticsEffect[index] = performance.now();
                    this.hapticsStrongMagnitude[index] = 0;
                    this.hapticsWeakMagnitude[index] = 0;
                    // Now reset the effect to off.  (Not strictly needed, will clear within 150 ms anyway.)
                    gamepad.vibrationActuator?.playEffect?.("dual-rumble", EMPTY_EFFECT);
                }
            }
        }
    }

    public getBitmap(): number {
        this.scangamepads();

        return this.gamepadBitmap;
    }
}
