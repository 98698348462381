import { EventEmitter } from "./eventemitter";
import { UTIL_EVENTS, LogEvent, LogLevel } from "./interfaces";
import { RtcUtilsSettings } from "./settings";

type LogFunction = (tag: string, msg: string, ...jsonObject: any[]) => void;
type FormatFunction = (msg: string, ...args: any[]) => string;

declare interface LogInterface {
    d: LogFunction;
    w: LogFunction;
    e: LogFunction;
    i: LogFunction;
    format: FormatFunction;
    //commit can mean sending logs buffer to some http server
    commit(logevent: LogEvent): void;
    sanitize(string?: string): string | undefined;
}

// Only exported for dev client:
export class LogImpl extends EventEmitter implements LogInterface {
    // A no-op logging function for when logging is disabled
    private _nop: LogFunction;
    private _nopf: FormatFunction;
    // Cached closures for emitted logging.
    private _d: LogFunction;
    private _i: LogFunction;
    private _w: LogFunction;
    private _e: LogFunction;
    private _f: FormatFunction;
    private queue: LogEvent[] = [];

    constructor() {
        super(true);

        this._nop = (tag: string, msg: string, ...jsonObject: any[]) => {};
        this._d = (tag: string, msg: string, ...jsonObject: any[]) =>
            this.emitLogMsg(LogLevel.DEBUG, tag, msg, jsonObject);
        this._i = (tag: string, msg: string, ...jsonObject: any[]) =>
            this.emitLogMsg(LogLevel.INFO, tag, msg, jsonObject);
        this._w = (tag: string, msg: string, ...jsonObject: any[]) =>
            this.emitLogMsg(LogLevel.WARN, tag, msg, jsonObject);
        this._e = (tag: string, msg: string, ...jsonObject: any[]) =>
            this.emitLogMsg(LogLevel.ERROR, tag, msg, jsonObject);
        this._nopf = (msg: string, ...args: any[]) => {
            return "";
        };
        this._f = (msg: string, ...args: any[]) => {
            return this.formatString(msg, ...args);
        };
    }

    public get d(): LogFunction {
        if (RtcUtilsSettings.consoleLoggingEnabled) {
            return console.debug.bind(console, "%s DEBUG [%s] %s@@", this.renderDate(new Date()));
        } else {
            return RtcUtilsSettings.loggingEnabled ? this._d : this._nop;
        }
    }

    public get w(): LogFunction {
        if (RtcUtilsSettings.consoleLoggingEnabled) {
            return console.warn.bind(console, "%s WARN  [%s] %s@@", this.renderDate(new Date()));
        } else {
            return RtcUtilsSettings.loggingEnabled ? this._w : this._nop;
        }
    }

    public get i(): LogFunction {
        if (RtcUtilsSettings.consoleLoggingEnabled) {
            return console.info.bind(console, "%s INFO  [%s] %s@@", this.renderDate(new Date()));
        } else {
            return RtcUtilsSettings.loggingEnabled ? this._i : this._nop;
        }
    }

    public get e(): LogFunction {
        if (RtcUtilsSettings.consoleLoggingEnabled) {
            return console.error.bind(console, "%s ERROR [%s] %s@@", this.renderDate(new Date()));
        } else {
            // Always emit errors
            return this._e;
        }
    }

    public sanitize(msg?: string): string | undefined {
        if (!msg) {
            return msg;
        }

        interface SanitizationParams {
            secretBlock: RegExp;
            replaceStr: string;
        }
        const sanitizationGroups: SanitizationParams[] = [
            {
                secretBlock: /(partnerCustomData" *: *")((\\"|[^"])*)(?=")/g,
                replaceStr: "$1***"
            },
            {
                secretBlock: /("clientIp" *: *")(\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?=")/g,
                replaceStr: "$1***"
            }
        ];

        for (const group of sanitizationGroups) {
            msg = msg.replace(group.secretBlock, group.replaceStr);
        }
        return msg;
    }

    public get format(): FormatFunction {
        return this._f;
    }

    public commit(logevent: LogEvent): void {
        if (this.hasListener(UTIL_EVENTS.LOG_EVENT)) {
            this.emit(UTIL_EVENTS.LOG_EVENT, logevent);
        } else {
            this.queue.push(logevent);
        }
    }

    public stringifyArgs(...args: any[]): string {
        if (args.length) {
            return `##${JSON.stringify(args)}`;
        }
        return "";
    }

    /// Formats a string with substituions {<digit>}
    private formatString(str: string, ...args: any[]) {
        return str.replace(/{(\d+)}/g, function (match, number, _offset, _string) {
            return typeof args[number] != "undefined" ? args[number] : match;
        });
    }

    private emitLogMsg(level: string, tag: string, msg: string, args: any[]): void {
        let logmsg = `${msg}${this.stringifyArgs(...args)}`;

        let logevent: LogEvent = {
            logModule: "R",
            timeStamp: this.renderDate(new Date()),
            logLevel: level,
            logtag: tag,
            logstr: logmsg
        };
        this.commit(logevent);
    }

    public addListener(eventname: string, handler: Function) {
        super.addListener(eventname, handler);
        if (eventname == UTIL_EVENTS.LOG_EVENT && this.queue.length > 0) {
            for (const logevent of this.queue) {
                this.commit(logevent);
            }
            this.queue = [];
        }
    }

    private renderDate(date: Date): string {
        const lpad = (value: string, chars: number, padWith: string): string => {
            const howMany = chars - value.length;
            if (howMany > 0) {
                let res: string = "";
                for (let i = 0; i < howMany; i++) {
                    res += padWith;
                }
                res += value;
                return res;
            }
            return value;
        };

        const fullYear = (d: Date): string => {
            return lpad(d.getFullYear().toString(), 4, "0");
        };

        const month = (d: Date): string => {
            return lpad((d.getMonth() + 1).toString(), 2, "0");
        };

        const day = (d: Date): string => {
            return lpad(d.getDate().toString(), 2, "0");
        };

        const hours = (d: Date): string => {
            return lpad(d.getHours().toString(), 2, "0");
        };

        const minutes = (d: Date): string => {
            return lpad(d.getMinutes().toString(), 2, "0");
        };

        const seconds = (d: Date): string => {
            return lpad(d.getSeconds().toString(), 2, "0");
        };

        const millis = (d: Date): string => {
            return lpad(d.getMilliseconds().toString(), 3, "0");
        };

        const dateSeparator = "-";
        let ds: string = "";
        // yyyy-mm-dd hh:mm:ss,m
        ds =
            fullYear(date) +
            dateSeparator +
            month(date) +
            dateSeparator +
            day(date) +
            " " +
            hours(date) +
            ":" +
            minutes(date) +
            ":" +
            seconds(date) +
            "." +
            millis(date);

        return ds;
    }
}

// Singleton LogImpl instance.  All users of LogImpl should use this instead.
export let Log = new LogImpl();
