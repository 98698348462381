// Interfaces in this file are only used by ragnarok library.
import { AppLaunchMode, IEventEmitter, StreamInfo } from "./dependencies";
import { MicState, StartSessionResultEvent, StreamingTerminatedEvent } from "./interfaces";
import { RErrorCode } from "./rerrorcode";

export class AckIdGenerator {
    private ackId: number = 1;
    constructor() {}
    public getNextAckId(): number {
        return this.ackId++;
    }
}

export declare interface WebSocketMsg {
    peer_info?: { id: number; name: string };
    peer_msg?: { from: number; to: number; msg: string };
    ackid?: number; // id of msg which needs acknowledgement. Its presence indicates it needs acknowledgement
    ack?: number; // acknowledgement of received msg
    hb?: number; // heartbeat msg from server to client
    stats?: ArrayBuffer;
    error?: string;
}

export interface BoundaryPair {
    horizontal: number;
    vertical: number;
}

export interface ExtendedDebugStats {
    isVideoElementPaused: boolean;
    isAudioElementPaused: boolean;
    isUserInputEnabled: boolean;
    isVirtualKeyboardVisible: boolean;
    micState: MicState;
    isRsdmmActive: boolean;
    keyboardLayout: string;
    appLaunchMode: AppLaunchMode;
}

export declare interface StatsHeader {
    stats: { from: number; to: number };
    ackid?: number;
}

// @todo https://github.com/angular/tsickle/issues/1067 IStreamCallbacks interface (extends) is WAR for
// broken & in tsickle closure. We should rather have used (IStreamCallbacks & IEventEmitter) in constructor for parent
export interface IStreamCallbacks extends IEventEmitter {
    onSessionStart(data: StartSessionResultEvent): void;
    onStreamStop(data: StreamingTerminatedEvent): void;
    onUserIdleClear(): void;
}

export declare interface WebSocketClose {
    error: boolean;
    code?: number;
    reason?: string;
    wasClean?: boolean;
}

export declare interface WorkerResponse {
    statsStarted?: boolean;
    log?: string;
    exception?: string;
    wsClose?: WebSocketClose;
    wsMessage?: WebSocketMsg;
    wsOpening?: boolean;
    wsOpen?: boolean;
}

export interface LogCallbackType {
    (msg: string): void;
}

export interface WebSocketHandler {
    messageHandler: (data: WebSocketMsg) => void;
    closeHandler: (data: WebSocketClose) => void;
    openingHandler: () => void;
    openHandler: () => void;
}

export interface LogCallbacks {
    info: LogCallbackType;
    exception: LogCallbackType;
}

export interface WebSocketImplCallbacks {
    wsHandler: WebSocketHandler;
    logCallback: LogCallbacks;
}

/**
 * Video Stream progress reported by server on the control channel.
 * Provides the client with more granular details when reporting exit codes.
 */
export const enum VideoStreamProgress {
    NO_INFO = 0x00,
    SETUP_BEGIN = 0x01,
    SETUP_END = 0x02,
    ADAPTER_INIT_BEGIN = 0x03,
    ADAPTER_INIT_END = 0x04,
    FRAMEPROVIDER_INIT_BEGIN = 0x05,
    FRAMERPROVIDER_INIT_END = 0x06,
    ENCODER_INIT_BEGIN = 0x07,
    ENCODER_INIT_END = 0x08
}

export const enum LowAudioVolumeType {
    PERMISSION_DENIED = "permissionDenied",
    VISIBILITY_CHANGE = "visibilityChange",
    DEVICE_CHANGE = "deviceChange"
}

export interface StaticStreamStats {
    zoneName: string;
    clientAppVersion: string;
    appId: number;
    requestedRegion: string;
    gpuType: string;
    streamInfo: StreamInfo;
    clientLocale: string;
}

export interface VideoState {
    displayVideoWidth: number; // Width of video on display, in CSS pixels
    displayVideoHeight: number; // Height of video on display, in CSS pixels
    scalingFactor: number;
    topPadding: number;
    leftPadding: number;
    videoWidth: number; // Width of video sent
    videoHeight: number; // Height of video sent
    viewportHeight: number;
}

export interface DataChannelParams {
    // If specified, the error code to report when the channel closes with an error.
    // Also determines whether streaming will wait to start until this channel is open and stop if this channel closes
    errorCode?: RErrorCode;
    // Custom open handler
    open?: () => void;
    // Custom close handler
    close?: () => void;
}
