import { GamepadDataHandler } from "../interfaces";
import { InputMediaElement } from "../input/inputinterfaces";
import { RagnarokSettings } from "../util/settings";
import { Log } from "../dependencies";

const LOGTAG = "gamepadTester";
const SUPPORTED_BUTTON_COUNT = 16;
const SUPPORTED_GAMEPAD_COUNT = 4;

interface GamepadData {
    index: number;
    buttons: number;
    trigger: number;
    axes: readonly number[];
    name: string;
}

/**
 * This is the main class which provides ability to test multiple connected gamepads.
 */
export class GamepadTester implements GamepadDataHandler {
    private gamepadTesterElementId: string = "gamepadTester";
    private visible: boolean = false;
    private gamepads: (GamepadData | null)[] = [];
    private unsupportedGamepads: (GamepadData | null)[] = [];

    constructor() {
        if (RagnarokSettings.gamepadTesterEnabled) {
            document.body.appendChild(this.initGamepadTesterElement());
            this.start();
        }
    }

    public gamepadBitmapUpdateHandler(gamepadBitmap: number) {
        for (let gamepadIndex = 0; gamepadIndex < SUPPORTED_GAMEPAD_COUNT; gamepadIndex++) {
            if ((gamepadBitmap & (1 << gamepadIndex)) == 0) {
                this.gamepads[gamepadIndex] = null; // Remove gamepad from cache
            }
        }
    }

    public gamepadStateUpdateHandler(
        count: number,
        index: number,
        buttons: number,
        trigger: number,
        axes: readonly number[],
        ts: number,
        gamepadBitmap: number,
        name: string
    ) {
        this.gamepads[index] = {
            index: index,
            buttons: buttons,
            trigger: trigger,
            axes: axes,
            name: name
        };
    }

    public connectUnsupportedGamepad(gamepad: Gamepad) {
        if (gamepad.index <= 3) {
            this.unsupportedGamepads[gamepad.index] = {
                index: gamepad.index,
                buttons: -1,
                trigger: -1,
                axes: [-1],
                name: gamepad.id
            };
        }
    }

    public disconnectUnsupportedGamepad(index: number) {
        if (index <= 3) {
            this.unsupportedGamepads[index] = null;
        }
    }

    private initGamepadTesterElement(): HTMLElement {
        let gamepadTester = document.createElement("div");
        gamepadTester.id = this.gamepadTesterElementId;
        gamepadTester.className = "genericdiv";
        gamepadTester.style.display = "block";
        this.visible = true;

        let connectedGamepadsText = document.createElement("p");
        connectedGamepadsText.style.color = "white";
        connectedGamepadsText.style.position = "relative";
        connectedGamepadsText.innerHTML = `Connected GamePads:`;
        gamepadTester.appendChild(connectedGamepadsText);

        let style = document.createElement("style");
        style.appendChild(
            document.createTextNode(".gamepad:nth-child(even) { float:left; clear: left; }")
        );
        style.appendChild(
            document.createTextNode(".gamepad:nth-child(odd) { float:right; clear: right; }")
        );

        document.head.appendChild(style);
        return gamepadTester;
    }

    public toggleGamepadTester(videoTagElement: InputMediaElement) {
        let gamepadTesterElement = document.getElementById(this.gamepadTesterElementId);
        if (gamepadTesterElement) {
            gamepadTesterElement.remove();
            this.gamepads = []; // Clear the cache
            this.visible = false;
        } else {
            // Create a new GamepadTester element to be a sibling of the Stream Video
            videoTagElement.insertAdjacentElement("afterend", this.initGamepadTesterElement());
            this.start();
        }
    }

    private start() {
        let animationFrame: number;
        let gamepadTesterContainer = document.getElementById(this.gamepadTesterElementId)!;
        if (gamepadTesterContainer == null) {
            Log.e("{e13a879}", "{0c955af}");
            return;
        }

        //Analog Stick Positions
        let gamepadWidth = window.innerWidth * 0.49;
        let gamepadHeight = window.innerHeight * 0.4;
        let leftJoyX = gamepadWidth * 0.3;
        let rightJoyX = gamepadWidth * 0.7;
        let leftJoyY = gamepadHeight * 0.8;
        let rightJoyY = gamepadHeight * 0.8;
        let joyStickMultiplier = gamepadWidth * 0.07;

        const getJoystickPosition = (gamepad: GamepadData) => {
            if (gamepad)
                return {
                    left: {
                        X: gamepad.axes![0],
                        Y: gamepad.axes![1]
                    },
                    right: {
                        X: gamepad.axes![2],
                        Y: gamepad.axes![3]
                    }
                };
            else return null;
        };

        const initJoystickCSS = (joystick: HTMLElement) => {
            joystick.style.position = "absolute";
            joystick.style.width = "2%";
            joystick.style.height = "3.5%";
            joystick.style.backgroundColor = "red";
            joystick.style.borderRadius = "50%";
            joystick.style.transform = "translate(-50%, -50%)";
        };

        const setJoyStickPositions = (gamepad: GamepadData) => {
            // Obtain current joystick position
            let axes = getJoystickPosition(gamepad);

            if (
                // Check for Joystick Leftstick Element
                gamepadTesterContainer
                    .querySelector(`.gamepad${gamepad.index}`)!
                    .querySelector(".leftStick") === null
            ) {
                // Create Joystick Leftstick Element
                let newJoystick = document.createElement("div");
                newJoystick.className = "gamepadJoystickPointer leftStick";
                initJoystickCSS(newJoystick);
                newJoystick.style.left = `${
                    leftJoyX + <number>axes!.left.X * joyStickMultiplier
                }px`;
                newJoystick.style.top = `${leftJoyY + <number>axes!.left.Y * joyStickMultiplier}px`;
                gamepadTesterContainer
                    .querySelector(`.gamepad${gamepad.index}`)!
                    .querySelector(".gamepadContainer")!
                    .appendChild(newJoystick);
            } else {
                // Update Joystick Leftstick Element
                let joystick = gamepadTesterContainer
                    .querySelector<HTMLElement>(`.gamepad${gamepad.index}`)!
                    .querySelector<HTMLElement>(".leftStick")!;
                joystick.style.opacity = "1";
                joystick.style.left = `${leftJoyX + joyStickMultiplier * <number>axes!.left.X}px`;
                joystick.style.top = `${leftJoyY + <number>axes!.left.Y * joyStickMultiplier}px`;
            }

            if (
                // Check for Joystick Rightstick Element
                gamepadTesterContainer
                    .querySelector<HTMLElement>(`.gamepad${gamepad.index}`)!
                    .querySelector(".rightStick") === null
            ) {
                // Create Joystick Rightstick Element
                let newJoystick = document.createElement("div");
                newJoystick.className = "gamepadJoystickPointer rightStick";
                initJoystickCSS(newJoystick);
                newJoystick.style.left = `${
                    rightJoyX + <number>axes!.right.X * joyStickMultiplier
                }px`;
                newJoystick.style.top = `${
                    rightJoyY + <number>axes!.right.Y * joyStickMultiplier
                }px`;
                gamepadTesterContainer
                    .querySelector(`.gamepad${gamepad.index}`)!
                    .querySelector(".gamepadContainer")!
                    .appendChild(newJoystick);
            } else {
                // Update Joystick Rightstick Element
                let joystick = gamepadTesterContainer
                    .querySelector<HTMLElement>(`.gamepad${gamepad.index}`)!
                    .querySelector<HTMLElement>(".rightStick")!;
                joystick.style.opacity = "1";
                joystick.style.left =
                    (rightJoyX + <number>axes!.right.X * joyStickMultiplier).toString() + "px";
                joystick.style.top =
                    (rightJoyY + <number>axes!.right.Y * joyStickMultiplier).toString() + "px";
            }
        };

        const renderButtons = (canvas: HTMLCanvasElement, buttonIndex: number, fill: boolean) => {
            var ctx = canvas.getContext("2d");
            ctx!.beginPath();
            if (fill) {
                ctx!.fillStyle = "green";
            } else {
                ctx!.fillStyle = "grey";
            }

            /**
             * The below decimals multiplied by the canvas.width represent the position on the screen.
             * Thus, canvas.width * 0.50 would be halfway across the width of the screen and
             * canvas.height * 0.50 would be halfway across the height of the screen.
             */
            switch (buttonIndex) {
                case 0:
                    // D-pad Up
                    ctx!.moveTo(canvas.width * 0.14, canvas.height * 0.48);
                    ctx!.lineTo(canvas.width * 0.19, canvas.height * 0.48);
                    ctx!.lineTo(canvas.width * 0.165, canvas.height * 0.54);
                    ctx!.lineTo(canvas.width * 0.14, canvas.height * 0.48);
                    break;
                case 1:
                    // D-pad Down
                    ctx!.moveTo(canvas.width * 0.14, canvas.height * 0.66);
                    ctx!.lineTo(canvas.width * 0.19, canvas.height * 0.66);
                    ctx!.lineTo(canvas.width * 0.165, canvas.height * 0.61);
                    ctx!.lineTo(canvas.width * 0.14, canvas.height * 0.66);
                    break;
                case 2:
                    // D-pad Left
                    ctx!.moveTo(canvas.width * 0.09, canvas.height * 0.55);
                    ctx!.lineTo(canvas.width * 0.09, canvas.height * 0.61);
                    ctx!.lineTo(canvas.width * 0.135, canvas.height * 0.57);
                    ctx!.lineTo(canvas.width * 0.09, canvas.height * 0.55);
                    break;
                case 3:
                    // D-pad Right
                    ctx!.moveTo(canvas.width * 0.24, canvas.height * 0.55);
                    ctx!.lineTo(canvas.width * 0.24, canvas.height * 0.61);
                    ctx!.lineTo(canvas.width * 0.195, canvas.height * 0.57);
                    ctx!.lineTo(canvas.width * 0.24, canvas.height * 0.55);
                    break;
                case 4:
                    // Start
                    ctx!.arc(
                        canvas.width * 0.7,
                        canvas.height * 0.4,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 5:
                    // Back
                    ctx!.arc(
                        canvas.width * 0.3,
                        canvas.height * 0.4,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 6:
                    // Thumstick Left
                    ctx!.arc(
                        canvas.width * 0.3,
                        canvas.height * 0.8,
                        canvas.width * 0.07,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 7:
                    // Thumbstick Right
                    ctx!.arc(
                        canvas.width * 0.7,
                        canvas.height * 0.8,
                        canvas.width * 0.07,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 8:
                    // Left Bumper
                    ctx!.rect(
                        canvas.width * 0.13,
                        canvas.height * 0.23,
                        canvas.width * 0.07,
                        canvas.height * 0.05
                    );
                    break;
                case 9:
                    // Right Bumper
                    ctx!.rect(
                        canvas.width * 0.815,
                        canvas.height * 0.23,
                        canvas.width * 0.07,
                        canvas.height * 0.05
                    );
                    break;
                case 12:
                    // A
                    ctx!.arc(
                        canvas.width * 0.85,
                        canvas.height * 0.65,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 13:
                    // B
                    ctx!.arc(
                        canvas.width * 0.9,
                        canvas.height * 0.55,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 14:
                    // X
                    ctx!.arc(
                        canvas.width * 0.8,
                        canvas.height * 0.55,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case 15:
                    // Y
                    ctx!.arc(
                        canvas.width * 0.85,
                        canvas.height * 0.45,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );
                    break;
                case -1:
                    // Left Trigger
                    ctx!.rect(
                        canvas.width * 0.155,
                        canvas.height * 0.07,
                        canvas.width * 0.02,
                        canvas.height * 0.12
                    );
                    break;
                case -2:
                    // Right Trigger
                    ctx!.rect(
                        canvas.width * 0.84,
                        canvas.height * 0.07,
                        canvas.width * 0.02,
                        canvas.height * 0.12
                    );
                    break;
                /*
                B16 - MENU | Currently Unsupported DNE
                    ctx!.arc(
                        canvas.width * 0.5,
                        canvas.height * 0.65,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );

                B17 - TOUCHPAD | Currently Unsupported DNE
                    ctx!.arc(
                        canvas.width * 0.5,
                        canvas.height * 0.5,
                        canvas.width * 0.02,
                        0,
                        2 * Math.PI
                    );

                */
            }
            ctx!.closePath();
            ctx!.fill();
        };

        const initGamepadDiv = (gamepad: GamepadData) => {
            // Create Gamepad Field if DNE
            if (gamepadTesterContainer.querySelector(`.gamepad${gamepad.index}`) === null) {
                // Gamepad Parent
                let gamepadElement = document.createElement("div");
                gamepadElement.className = `gamepad gamepad${gamepad.index}`;

                // Gamepad Description
                let gamepadID = document.createElement("p");
                gamepadID.className = "active";
                gamepadID.style.margin = "0px";
                let gamepadIDText = document.createElement("span");
                gamepadIDText.className = "gamepadPlayerNumber";
                gamepadIDText.style.color = "white";
                gamepadIDText.style.position = "relative";
                gamepadIDText.innerHTML = `P${gamepad.index + 1}|${gamepad.name}`;
                gamepadIDText.style.fontSize = "1.5vw";
                gamepadID.appendChild(gamepadIDText);
                gamepadElement.appendChild(gamepadID);

                // Create the Gamepad container for Gamepad display
                let gamepadContainer = document.createElement("div");
                gamepadContainer.className = "gamepadContainer";
                gamepadContainer.style.position = "relative";
                gamepadContainer.style.width = `${gamepadWidth + 2 /*canvas border*/}px`;
                gamepadContainer.style.height = `${gamepadHeight}px`;

                // Create the Canvas to Draw the Gamepad Layout
                let canvas = document.createElement("canvas");
                canvas.className = "canvas";
                canvas.style.width = `${gamepadWidth}px`;
                canvas.style.height = `${gamepadHeight}px`;
                canvas.style.border = "1px solid white";

                gamepadContainer.appendChild(canvas);

                // Add Gamepad Display to Gamepad Div
                gamepadElement.appendChild(gamepadContainer);

                // Add Gamepad Div to DOM
                gamepadTesterContainer.appendChild(gamepadElement);
            }
        };

        const gameLoop = () => {
            if (this.visible) {
                for (let i = 0; i < this.gamepads.length; i++) {
                    let gamepad = this.gamepads[i];

                    if (gamepad) {
                        if (
                            // Check HTML Element for the Gamepad Exists
                            !gamepadTesterContainer.querySelector<HTMLElement>(
                                `.gamepad${gamepad.index}`
                            )
                        ) {
                            initGamepadDiv(gamepad);
                        }
                        setJoyStickPositions(gamepad);

                        let canvas = gamepadTesterContainer
                            .querySelector<HTMLElement>(`.gamepad${gamepad.index}`)!
                            .querySelector<HTMLCanvasElement>("canvas")!;
                        for (
                            let buttonIndex = 0;
                            buttonIndex < SUPPORTED_BUTTON_COUNT;
                            buttonIndex++
                        ) {
                            // Check for pressed buttons
                            if ((gamepad.buttons & (1 << buttonIndex)) != 0) {
                                renderButtons(canvas, buttonIndex, /*fill*/ true);
                            } else {
                                renderButtons(canvas, buttonIndex, /*fill*/ false);
                            }
                        }

                        // Left Trigger
                        if ((gamepad.trigger & 0xff) != 0) {
                            renderButtons(canvas, -1, /*fill*/ true);
                        } else {
                            renderButtons(canvas, -1, /*fill*/ false);
                        }
                        // Right Trigger
                        if ((gamepad.trigger & (0xff << 8)) != 0) {
                            renderButtons(canvas, -2, /*fill*/ true);
                        } else {
                            renderButtons(canvas, -2, /*fill*/ false);
                        }
                    } else {
                        if (this.unsupportedGamepads[i]) {
                            // Gamepad exists, but is unsupported
                            if (
                                // Check HTML Element for the Gamepad Exists
                                !gamepadTesterContainer.querySelector<HTMLElement>(`.gamepad${i}`)
                            ) {
                                initGamepadDiv(this.unsupportedGamepads[i]!);

                                let canvas = gamepadTesterContainer
                                    .querySelector<HTMLElement>(`.gamepad${i}`)!
                                    .querySelector<HTMLCanvasElement>("canvas")!;
                                let ctx = canvas.getContext("2d")!;
                                ctx.font = "20px Arial";
                                ctx.fillStyle = "white";
                                ctx.textAlign = "center";
                                ctx.fillText(
                                    "Unsupported Gamepad",
                                    canvas.width / 2,
                                    canvas.height / 2
                                );
                            }
                        } else {
                            // Disconnected constroller - remove containers
                            let child = gamepadTesterContainer.querySelector(`.gamepad${i}`);
                            if (child) {
                                gamepadTesterContainer.removeChild(child);
                            }
                        }
                    }
                }

                animationFrame = window.requestAnimationFrame(gameLoop);
            } else {
                // If not visible, stop the animation loop
                window.cancelAnimationFrame(animationFrame);
            }
        };

        gameLoop();
    }

    // Unused interface functions
    public finalizeGamepadData(count: number) {}

    public virtualGamepadUpdateHandler(
        buttons: number,
        trigger: number,
        index: number,
        axes: readonly number[],
        gamepadBitmap: number
    ) {}
}
