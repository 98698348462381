import { GetHexString, Log } from "../dependencies";
import { RErrorCode } from "../rerrorcode";
import { TelemetryHandler } from "../telemetry/telemetryhandler";

const LOGTAG = "sleepdetector";
// Choose short enough time so that last errorcode doesn't get updated when user opens the lid by immediately clicking on play
// and long enough time so that setTimeout call doesn't happening too frequently.
const PERIODIC_CALLBACK_INTERVAL_MS = 10 * 1000;
// 1-2 secs delay was observed between 2 calls. This also means system should be let to sleep for minimum 10 secs
const DELTA_SELF_PING_MS = 10 * 1000;
const MAX_TIMEOUT_ALLOWED = 1 * 60 * 60 * 1000; //1 hour
const DELTA_SLEEP_PING_ERROR_CASE = 20 * 1000;

export class SleepDetector {
    private sessionId: string = "";
    private subSessionId: string = "";
    private lastTimeSelfPing: number = 0;
    private lastSubSessionIdSentOnSleep: string = "";
    private lastSessionErrorCode: number = 0;
    private lastSessionErrorTs: number = 0;
    private sleepDetectIntervalId: number = 0;
    private lastSleepDetectionTs: number = 0;
    private sleepTime: number = 0;
    private telemetry: TelemetryHandler;
    constructor(telemetry: TelemetryHandler) {
        this.telemetry = telemetry;
    }

    startSleepDetectionTimer() {
        this.stopSleepDetectionTimer();
        this.lastTimeSelfPing = new Date().getTime();
        this.lastSessionErrorCode = 0;
        this.lastSessionErrorTs = 0;
        this.lastSleepDetectionTs = 0;
        this.sleepTime = 0;
        Log.i("{0f7a7d0}", "{d5ff5ba}");
        this.sleepDetectIntervalId = window.setInterval(
            () => this.periodicSelfPing(),
            PERIODIC_CALLBACK_INTERVAL_MS
        );
    }

    stopSleepDetectionTimer() {
        Log.i("{0f7a7d0}", "{206f492}");
        if (this.sleepDetectIntervalId) {
            window.clearInterval(this.sleepDetectIntervalId);
            this.sleepDetectIntervalId = 0;
        }
    }

    private reportSleepEvent(sleepCaseMsg: string) {
        if (this.lastSubSessionIdSentOnSleep !== this.subSessionId) {
            this.telemetry.emitSleepEvent(
                this.sleepTime,
                this.lastTimeSelfPing - this.lastSessionErrorTs, //approx time taken to sleep after lid close, granularity is PERIODIC_CALLBACK_INTERVAL_MS unfortunatley
                sleepCaseMsg,
                GetHexString(this.lastSessionErrorCode),
                this.sessionId,
                this.subSessionId
            );
            this.lastSubSessionIdSentOnSleep = this.subSessionId;
        }
        this.stopSleepDetectionTimer();
    }

    private didSleep(currentTime: number): boolean {
        return (
            currentTime - this.lastTimeSelfPing - PERIODIC_CALLBACK_INTERVAL_MS > DELTA_SELF_PING_MS
        );
    }

    private setSleepTime() {
        this.sleepTime =
            this.lastSleepDetectionTs - this.lastTimeSelfPing - PERIODIC_CALLBACK_INTERVAL_MS;
    }

    periodicSelfPing() {
        try {
            let currentTime = new Date().getTime();
            if (this.didSleep(currentTime)) {
                this.lastSleepDetectionTs = currentTime;
                this.setSleepTime();
            }

            // handle cases:
            // pings -> error -> sleep -> ping
            // error -> pings -> sleep -> ping
            if (this.didSleep(currentTime) && this.lastSessionErrorCode != 0) {
                this.reportSleepEvent(
                    this.lastTimeSelfPing - this.lastSessionErrorTs > 0 ? "epsp" : "esp"
                );
            } else if (currentTime - this.lastTimeSelfPing > MAX_TIMEOUT_ALLOWED) {
                this.stopSleepDetectionTimer();
            }
            this.lastTimeSelfPing = currentTime;
        } catch (ex) {}
    }

    // it returns false for pesp and epsp cases
    wasSleepExit(errorCode: number): boolean {
        if (
            errorCode === RErrorCode.ServerDisconnectedIntended ||
            errorCode === RErrorCode.Success
        ) {
            this.stopSleepDetectionTimer();
            return false;
        }
        let currentTime = new Date().getTime();
        this.lastSessionErrorCode = errorCode;
        this.lastSessionErrorTs = currentTime;
        Log.i("{0f7a7d0}", "{425293a}", String(currentTime - this.lastTimeSelfPing), this.lastSleepDetectionTs);
        // pings -> sleep -> error
        if (this.didSleep(currentTime)) {
            this.lastSleepDetectionTs = currentTime;
            this.setSleepTime(); //could be off by PERIODIC_CALLBACK_INTERVAL_MS
            this.reportSleepEvent("pse");
        }
        // handle case:
        // pings -> sleep -> ping -> error -> ping, only consider sleep in last DELTA_SLEEP_PING_ERROR_CASE secs
        else if (
            this.lastSleepDetectionTs !== 0 &&
            currentTime - this.lastSleepDetectionTs < DELTA_SLEEP_PING_ERROR_CASE
        ) {
            this.reportSleepEvent("pspe");
        }
        // sleep already detected by another mechanism
        // e.g. pc signaling state set to closed
        else if (
            errorCode === RErrorCode.SystemSleepDuringSessionSetup ||
            errorCode === RErrorCode.SystemSleepDuringStreaming
        ) {
            this.lastSleepDetectionTs = currentTime;
            this.reportSleepEvent("other");
        }
        return this.lastSleepDetectionTs !== 0;
    }

    setSessionId(sessionId: string) {
        this.sessionId = sessionId;
    }

    setSubSessionId(subSessionId: string) {
        this.subSessionId = subSessionId;
    }
}
