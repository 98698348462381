import {
    FORMAT_TEXT_MAP,
    TracingCarrier,
    Span,
    SpanContext,
    Tracer,
    createTracerCallback,
    TracingInfo
} from "./dependencies";
import { Log } from "./logger";

const LOGTAG = "tracing";

export const TracingComponent = {
    name: "gridserver-core",
    version: "1.0"
};

export const enum TracingOperation {
    PostSession = "PostSession",
    PutSession = "PutSession",
    DeleteSession = "DeleteSession",
    GetSession = "GetSession",
    GetSessionList = "GetSessionList"
}

declare interface TracingOptions {
    access_token: string;
    component_name: string;
    collector_host: string;
    collector_port: number;
    disable_clock_skew_correction: boolean;
}

/**
 * Formalizes activation and deactivation of a span.
 **/
export class Scope {
    private parentContext?: SpanContext;
    private activeContext?: SpanContext;
    constructor(private managedSpan?: Span) {
        this.parentContext = ScopeManager.getInstance().context();
        if (managedSpan) {
            this.activeContext = managedSpan.context();
        }
    }

    public context(): SpanContext | undefined {
        return this.activeContext;
    }

    public setTag(key: string, value: string): void {
        try {
            this.managedSpan?.setTag(key, value);
        } catch (error) {
            Log.e("{b2c86d8}", "{fe582b7}", error);
        }
    }

    public addTags(tags: { [key: string]: any }): void {
        try {
            this.managedSpan?.addTags(tags);
        } catch (error) {
            Log.e("{b2c86d8}", "{fe582b7}", error);
        }
    }

    public finish(): void {
        try {
            this.managedSpan?.finish();
        } catch (error) {
            Log.e("{b2c86d8}", "{42fbb42}", error);
        }
        if (this.activeContext === ScopeManager.getInstance().context()) {
            ScopeManager.getInstance().setContext(this.parentContext);
        } else {
            Log.e("{b2c86d8}", "{035c442}");
        }
    }
}
/**
 * Distributed tracing interface responsible for keeping track of active tracing context and starting new spans.  Grants access to active span through a scope.
 **/
class ScopeManager {
    private static instance: ScopeManager;
    private activeContext?: SpanContext;
    private tracer?: Tracer;

    private constructor() {}

    public static getInstance(): ScopeManager {
        if (!ScopeManager.instance) {
            ScopeManager.instance = new ScopeManager();
        }
        return ScopeManager.instance;
    }

    public initialize(tracer?: Tracer) {
        this.tracer = tracer;
    }

    public context(): SpanContext | undefined {
        return this.activeContext;
    }

    public setContext(context?: SpanContext) {
        this.activeContext = context;
    }

    public injectScope(scope: Scope): TracingCarrier {
        const context = scope.context();
        if (!this.tracer || !context) {
            return {};
        }
        let carrier: TracingCarrier = {};
        try {
            this.tracer.inject(context, FORMAT_TEXT_MAP, carrier);
        } catch (error) {
            Log.e("{b2c86d8}", "{f04018e}", error);
        }
        return carrier;
    }

    private extract(carrier: TracingCarrier): SpanContext | null {
        let spanContext: SpanContext | null = null;
        try {
            spanContext = this.tracer!.extract(FORMAT_TEXT_MAP, carrier) ?? null;
        } catch (error) {
            Log.e("{b2c86d8}", "{e933712}", error);
        }
        return spanContext;
    }

    public start(name: string, carrier?: TracingCarrier): Scope {
        if (!this.tracer) {
            Log.w("{b2c86d8}", "{dae9266}");
            return new Scope();
        }
        if (carrier) {
            const parent: SpanContext | null = this.extract(carrier);
            return this.startScopedSpan(name, parent);
        }
        return this.startScopedSpan(name);
    }

    private startScopedSpan(spanName: string, parent?: SpanContext | null): Scope {
        parent = parent ?? this.activeContext;
        let span: Span | undefined;
        try {
            if (parent) {
                span = this.tracer!.startSpan(spanName, { childOf: parent });
            } else {
                span = this.tracer!.startSpan(spanName);
            }
        } catch (error) {
            Log.e("{b2c86d8}", "{52b1e15}", error);
        }
        const scope = new Scope(span);
        this.activeContext = scope.context();
        return scope;
    }
}

/**
 * Start a new span, representing an individual unit of work.
 * Returns a Scope that encapsualtes this new span.
 * @param operationName - name of operation/ span to create
 * @param carrier - Representation of parent span, adhering to the OpenTracing carrier standard.
 * @sa https://opentracing.io/docs/overview/inject-extract/#required-carriers
 **/
function createTracingScope(operationName: string, carrier?: TracingCarrier): Scope {
    return ScopeManager.getInstance().start(operationName, carrier);
}

/** Inject the provided scope into a tracing carrier and returns the carrier. */
function injectTracingScope(scope: Scope): TracingCarrier {
    return ScopeManager.getInstance().injectScope(scope);
}

export function enableTracing(createTracer?: createTracerCallback): TracingInfo | undefined {
    const tracingOptions: TracingOptions = {
        access_token:
            "5O7v0gCu2/EAlVGR+9AuxkzqUEwMAFY4x2ZbD2CPw8f1HmxJWcx+jDUnaN/8qH0IE5SMeTtmbMJLwrc6SoE=",
        component_name: "gfn-web-gamestream",
        collector_host: "ls.dtrace.nvidia.com",
        collector_port: 443,
        disable_clock_skew_correction: true
    };
    const tracer = createTracer ? createTracer(tracingOptions) : undefined;
    ScopeManager.getInstance().initialize(tracer);
    if (tracer) {
        return {
            createTracingScopeCallback: createTracingScope,
            injectTracingScopeCallback: injectTracingScope
        };
    }
    return undefined;
}
