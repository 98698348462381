import { EVENTS, MicState } from "./interfaces";
import {
    IEventEmitter,
    IsChromeOS,
    IsiDevice,
    PlatformDetails,
    IsiOSVersionAtLeast,
    IsWebOS,
    IsTizenOS,
    Log
} from "./dependencies";
import { RagnarokSettings } from "./util/settings";
import { TelemetryHandler } from "./telemetry/telemetryhandler";
import { LowAudioVolumeType } from "./rinterfaces";
import { GetAudioContext } from "./util/utils";

/**
 *This class has all the logic to capture mic audio. Gridapp provides
 * the reference of this class for clients to resume and pause mic audio capture.
 */

const LOGTAG = "miccapturer";

const enum AudioInputType {
    NO_AUDIO_INPUT = "No Audio Input",
    BUILT_IN_MIC = "Built-in Microphone",
    HEADSET_MIC = "Headset Microphone"
}

export class MicCapturer {
    private eventEmitter: IEventEmitter | null = null;
    private peerConnection: any;
    // Contains actual microphone stream, if any
    private micCaptureStream: MediaStream | null;
    // Prevent capture being started multiple times (in case callback is called
    // multiple times
    private micCaptureStarting: boolean;
    // Prevent race between two subsequent pause/resume calls
    private paused: boolean;
    // Whether mic capture is supported (will be disabled in case client is on
    // non-https origins (except localhost) or if AudioContext is not supported)
    // Note: navigator.mediaDevices is null for non-HTTPS origins except localhost
    // (can be enabled temporarily by adding IP to "Insecure origins treated as secure"
    // inside chrome://flags)
    private static micSupported: boolean =
        navigator.mediaDevices != null &&
        ((<any>window).AudioContext || (<any>window).webkitAudioContext || false);
    // Current state of mic permission
    private currentState: MicState = MicState.UNINITIALIZED;
    // Set mic enabled/disabled, will determine whether to automatically start
    // mic capture when initializing (eg. in case of resume)
    private micEnabled: boolean = false;
    private videoElement: HTMLVideoElement | null;
    private audioElement: HTMLAudioElement | null;
    private brokenMics: Set<string> = new Set();
    private platformDetails: PlatformDetails;
    private telemetry: TelemetryHandler;
    private ios15: boolean = false;
    private initialized: boolean = false;
    private trackEndedTime: number = 0;
    private audioVolumeLow: boolean = false;
    private willAudioVolumeBeLow: boolean = false;
    private userDisabledMic: boolean = false;
    private audioInputType?: AudioInputType;
    private audioInputCounts = { noAudioInput: 0, builtInMic: 0, headsetMic: 0 };
    private lowAudioVolumeCounts: Map<LowAudioVolumeType, number> = new Map([
        [LowAudioVolumeType.PERMISSION_DENIED, 0],
        [LowAudioVolumeType.VISIBILITY_CHANGE, 0],
        [LowAudioVolumeType.DEVICE_CHANGE, 0]
    ]);
    private micHasBeenStarted: boolean = false;
    constructor(platformDetails: PlatformDetails, telemetry: TelemetryHandler) {
        this.platformDetails = platformDetails;
        this.telemetry = telemetry;
        this.peerConnection = null;
        this.micCaptureStream = null;
        this.micCaptureStarting = false;
        this.paused = false;
        this.videoElement = null;
        this.audioElement = null;

        if (IsiOSVersionAtLeast(platformDetails, 15)) {
            this.ios15 = true;
        }
    }

    private getMicStateEnum(state: string): MicState {
        let micState: MicState = MicState.PERMISSION_DENIED;
        if (state == "granted") {
            micState = MicState.STOPPED;
        } else if (state == "prompt") {
            micState = MicState.PERMISSION_PENDING;
        }
        return micState;
    }

    public getCurrentMicStatus(): Promise<MicState> {
        if (RagnarokSettings.micEnabled === false) {
            return Promise.resolve(MicState.UNINITIALIZED);
        }
        if (window.navigator.permissions) {
            return window.navigator.permissions
                .query({ name: <PermissionName>"microphone" })
                .then((permissionStatus: PermissionStatus) => {
                    return this.getMicStateEnum(permissionStatus.state);
                })
                .catch(ex => {
                    return MicState.UNINITIALIZED;
                });
        } else {
            return Promise.resolve(MicState.UNINITIALIZED);
        }
    }

    /**
     * Emits current mic permission state to client via MIC_CAPTURER event.
     * It could result in mic popup to get user permission.
     **/
    public emitMicState() {
        if (RagnarokSettings.micEnabled === false) {
            return;
        }
        // Return current state if starting/in stable state
        // Else, query current state and report
        if (
            this.micCaptureStarting ||
            this.currentState == MicState.STARTED ||
            this.currentState == MicState.PERMISSION_DENIED ||
            this.currentState == MicState.UNSUPPORTED ||
            this.currentState == MicState.ERROR
        ) {
            this.updateState(this.currentState);
        } else {
            // Query current state using browser API
            this.getCurrentMicStatus().then(state => {
                if (state !== MicState.UNINITIALIZED) {
                    this.updateState(state);
                }
            });
        }
    }

    public static isMicSupported() {
        return MicCapturer.micSupported;
    }

    private updateState(status: MicState, shouldEmit: boolean = true) {
        this.currentState = status;
        if (this.currentState === MicState.STARTED) {
            this.micHasBeenStarted = true;
        }
        if (this.eventEmitter && shouldEmit) {
            this.eventEmitter.emit(EVENTS.MIC_CAPTURE, { state: this.currentState });
        }
    }

    private async getUserMedia(audioOptions: MediaTrackConstraints): Promise<void> {
        this.micCaptureStarting = true;
        return navigator.mediaDevices
            .getUserMedia({ audio: audioOptions })
            .then((media_obj: MediaStream) => {
                Log.d("{223689a}", "{b204737}");
                // will emit update once track is replaced
                this.updateState(MicState.STOPPED, false);
                this.micCaptureStarting = false;
                this.micCaptureStream = media_obj;

                if (IsiDevice(this.platformDetails)) {
                    const micTrack = media_obj.getTracks()[0];
                    micTrack.onended = () => {
                        this.trackEndedTime = performance.now();
                        Log.i("{223689a}", "{6b60d82}");
                        this.stopMicRecording();
                    };
                }

                // TODO: Remove use of this deprecated API
                (this.micCaptureStream as any).oninactive = () => {
                    // This is triggered when mic is unplugged, will be handled by ondeviceChange callback
                    Log.d("{223689a}", "{eee54f1}");
                    this.brokenMics.clear();
                    this.micCaptureStream = null;
                };

                if (!this.paused) {
                    var sender = this.peerConnection.getSenders()[0];
                    if (!sender) {
                        Log.e("{223689a}", "{f7f0250}");
                        this.micCaptureStream = null;
                    } else {
                        sender
                            .replaceTrack(media_obj.getTracks()[0])
                            .then(() => {
                                Log.i("{223689a}", "{6c92139}");
                                // War for low audio volume issue
                                // Playing the audio/video element again fixes it
                                if (
                                    IsiDevice(this.platformDetails) &&
                                    !IsiOSVersionAtLeast(this.platformDetails, 15, 4) &&
                                    this.videoElement!.muted == false
                                ) {
                                    let playPromise = null;
                                    if (this.audioElement!.srcObject) {
                                        playPromise = this.audioElement!.play();
                                    } else if (this.videoElement!.srcObject) {
                                        playPromise = this.videoElement!.play();
                                        Log.i("{223689a}", "{ef4dea7}");
                                    }

                                    if (playPromise) {
                                        playPromise
                                            .then(() => {
                                                Log.d("{223689a}", "{5593cbe}");

                                                this.resetAudio();
                                            })
                                            .catch((error: any) => {
                                                this.resetAudio();
                                                try {
                                                    this.telemetry.emitDebugEvent(
                                                        "WAR: Play Error",
                                                        error?.name,
                                                        error?.message
                                                    );
                                                    Log.e("{223689a}", "{69aae72}", error?.name, error?.message);
                                                } catch (ex) {}
                                            });
                                    } else {
                                        Log.e("{223689a}", "{b6a6508}");
                                    }
                                }
                                this.updateState(MicState.STARTED);
                                this.userDisabledMic = false;
                                if (IsiOSVersionAtLeast(this.platformDetails, 15, 4)) {
                                    // Enabling the mic resolves the low audio volume issues
                                    this.audioVolumeLow = false;
                                    this.setWillAudioVolumeBeLow(false);
                                }
                            })
                            .catch((err: any) => {
                                Log.e("{223689a}", "{5c42246}", err);
                                this.micCaptureStream = null;
                                this.updateState(MicState.ERROR);
                            });
                    }
                } else {
                    Log.d("{223689a}", "{21e76e1}");
                    this.micCaptureStream = null;
                    this.updateState(MicState.ERROR);
                }
            })
            .catch((err: any) => {
                if (
                    IsiDevice(this.platformDetails) &&
                    !IsiOSVersionAtLeast(this.platformDetails, 15, 4)
                ) {
                    this.resetAudio();
                }

                this.micCaptureStarting = false;
                if (err instanceof DOMException && (<DOMException>err).name === "NotAllowedError") {
                    Log.e("{223689a}", "{3f9bd66}");
                    this.updateState(MicState.PERMISSION_DENIED);
                    if (this.willAudioVolumeBeLow && !this.audioVolumeLow) {
                        this.recordLowAudioDebugEvent(LowAudioVolumeType.PERMISSION_DENIED);
                    }
                } else if (
                    err instanceof DOMException &&
                    (<DOMException>err).name === "NotFoundError"
                ) {
                    Log.e("{223689a}", "{7f04869}");
                    this.updateState(MicState.NO_SUITABLE_DEVICE);
                } else if (
                    err instanceof DOMException &&
                    (<DOMException>err).name === "NotReadableError"
                ) {
                    this.brokenMics.add((audioOptions.deviceId as string) ?? "default");
                    Log.e("{223689a}", "{af7e08c}", (audioOptions.deviceId as string) ?? "default", 
                            err.name
                        , err.message);
                    navigator.mediaDevices
                        .enumerateDevices()
                        .then(devices => {
                            for (const device of devices) {
                                if (
                                    device.kind === "audioinput" &&
                                    device.deviceId !== "default" &&
                                    !this.brokenMics.has(device.deviceId)
                                ) {
                                    Log.d("{223689a}", "{655d8b0}", device.deviceId, device.label);
                                    audioOptions.deviceId = device.deviceId;
                                    this.getUserMedia(audioOptions);
                                    break;
                                }
                            }
                        })
                        .catch(err => {
                            Log.e("{223689a}", "{930e826}", err.name, err.message);
                        });
                    this.updateState(MicState.ERROR);
                } else {
                    Log.e("{223689a}", "{c361fc5}", err.name, err.message);
                    this.updateState(MicState.ERROR);
                }
            });
    }

    /**
     * Start capturing audio on default device (async).
     * State will be emitted on the EventEmitter
     **/
    public async startMicCaptureOnDefaultDeviceWithFallback(): Promise<void> {
        if (!this.initialized) {
            return;
        }
        if (!MicCapturer.micSupported) {
            Log.e("{223689a}", "{999b07b}");
            this.updateState(MicState.UNSUPPORTED);
            return;
        }

        this.micEnabled = true;

        if (this.micCaptureStream != null || this.micCaptureStarting) {
            // Mic capture has already started/is starting, possibly on a callback
            // Just return here, and let the callback update state
            return;
        }

        this.paused = false;
        // will emit update once we get the mic media
        this.updateState(MicState.PERMISSION_PENDING, false);

        let audioOptions: MediaTrackConstraints = {
            sampleRate: 48000
        };

        // Disabling the AGC2 in microphone capture
        // This is causing high CPU usage on Chromebooks
        // https://issuetracker.google.com/issues/172340627
        if (IsChromeOS(this.platformDetails)) {
            (<any>audioOptions).googAutoGainControl2 = false;
        }

        navigator.mediaDevices.ondevicechange = (ev: Event) => {
            Log.i("{223689a}", "{50d0169}", !!this.micCaptureStream, this.micCaptureStarting);
            if (this.micCaptureStream == null && !this.micCaptureStarting) {
                Log.d("{223689a}", "{c849290}");
                this.startMicCaptureOnDefaultDeviceWithFallback();
            }
        };

        await this.getUserMedia(audioOptions);

        if (!this.audioInputType) {
            this.extractAudioInputType();
        }
    }

    /**
     * Create MediaStream which generates silence.
     * The AudioContext for the track is stopped immediately.
     **/
    private getSilenceMediaStream(): MediaStream {
        let ctx = GetAudioContext(48000);
        if (ctx) {
            let dst = ctx.createMediaStreamDestination();
            let track = dst.stream.getAudioTracks()[0];
            ctx.close();
            track.enabled = true;
            let stream: MediaStream = new MediaStream([track]);
            return stream;
        }

        // Should never reach here, as we would set mic supported false before
        throw new Error("Mic stream is not supported");
    }

    public initialize(
        pc: any,
        eventEmitter: IEventEmitter,
        videoElement: HTMLVideoElement,
        audioElement: HTMLAudioElement
    ) {
        if (RagnarokSettings.micEnabled === false) {
            return;
        }

        this.peerConnection = pc;
        this.eventEmitter = eventEmitter;
        this.videoElement = videoElement;
        this.audioElement = audioElement;
        if (MicCapturer.micSupported) {
            // Initially open the track with a generative AudioContext
            // This allows the initial connection to continue without waiting for getUserMedia
            // which seems to take a large amount of time (~ 5-15 sec)
            let silenceStream = this.getSilenceMediaStream();
            if (silenceStream == null) {
                Log.w("{223689a}", "{360f38b}");
                return;
            }
            this.peerConnection.addTrack(silenceStream.getAudioTracks()[0], silenceStream);
            if (this.micEnabled) {
                this.startMicCaptureOnDefaultDeviceWithFallback();
            }
        } else {
            Log.w("{223689a}", "{5813c2b}");
        }

        this.initialized = true;
    }

    /**
     * Stop mic capture.
     * This method will stop any ongoing mic capture and replace the sender
     * with a silence stream. Mic capture will always be stopped, even if the
     * replacement fails.
     * This method will emit an event on the eventStream to indicate that
     * stream has stopped
     **/
    public stopMicRecording() {
        if (!this.initialized) {
            return;
        }
        if (!MicCapturer.micSupported) {
            this.updateState(MicState.UNSUPPORTED);
            return;
        }
        this.micEnabled = false;
        this.paused = true;
        const prevState = this.currentState;

        // Reset callback
        navigator.mediaDevices.ondevicechange = () => {};

        if (this.peerConnection) {
            Log.d("{223689a}", "{6faf5bb}");
            let stream = this.getSilenceMediaStream();
            var sender = this.peerConnection.getSenders()[0];
            if (!sender) {
                Log.e("{223689a}", "{f7f0250}");
                this.micCaptureStream = null;
            } else {
                sender
                    .replaceTrack(stream.getTracks()[0])
                    .then(() => {
                        Log.d("{223689a}", "{797fa55}");
                        // will emit update state once we stop the tracks
                        this.updateState(MicState.STOPPED, false);
                        if (prevState === MicState.STARTED) {
                            this.userDisabledMic = true;
                        }
                    })
                    .catch((err: any) => {
                        Log.e("{223689a}", "{79f26cc}", err);
                        // will emit update state once we stop the tracks
                        this.updateState(MicState.ERROR, false);
                    })
                    .finally(() => {
                        let stream = this.micCaptureStream;
                        this.micCaptureStream = null;

                        if (stream) {
                            stream.getTracks().forEach((track: MediaStreamTrack) => {
                                track.stop();
                            });
                        }
                        this.updateState(this.currentState);
                        if (
                            IsiDevice(this.platformDetails) &&
                            !IsiOSVersionAtLeast(this.platformDetails, 15, 4)
                        ) {
                            this.resetAudio();
                        }
                    });
            }
        } else {
            Log.e("{223689a}", "{9e75fa2}");
            this.updateState(MicState.UNINITIALIZED);
        }
    }

    /**
     * Client shouldn't call this method, ragnarok will shutdown capture.
     **/
    public shutdown() {
        if (RagnarokSettings.micEnabled === false) {
            return;
        }

        if (this.micCaptureStream != null) {
            let stream = this.micCaptureStream;
            this.brokenMics.clear();
            this.micCaptureStream = null;

            // Reset callback
            if (navigator.mediaDevices) {
                navigator.mediaDevices.ondevicechange = () => {};
            }

            stream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
        }
        this.micEnabled = false;
        this.updateState(MicState.STOPPED);

        this.telemetry.emitMetricEvent(
            "AudioInputType",
            this.micHasBeenStarted ? "started" : "not started",
            0,
            this.audioInputCounts.noAudioInput,
            this.audioInputCounts.builtInMic,
            this.audioInputCounts.headsetMic
        );

        if (IsiOSVersionAtLeast(this.platformDetails, 15, 4)) {
            this.telemetry.emitMetricEvent(
                "LowAudioVolume",
                "",
                0,
                this.lowAudioVolumeCounts.get(LowAudioVolumeType.PERMISSION_DENIED)!,
                this.lowAudioVolumeCounts.get(LowAudioVolumeType.VISIBILITY_CHANGE)!,
                this.lowAudioVolumeCounts.get(LowAudioVolumeType.DEVICE_CHANGE)!
            );
        }

        this.peerConnection = null;
        this.eventEmitter = null;
        this.videoElement = null;
        this.audioElement = null;

        this.initialized = false;

        this.audioInputCounts = { noAudioInput: 0, builtInMic: 0, headsetMic: 0 };
        for (const [key, _] of this.lowAudioVolumeCounts) {
            this.lowAudioVolumeCounts.set(key, 0);
        }
        this.audioVolumeLow = false;
        this.userDisabledMic = false;
        this.setWillAudioVolumeBeLow(false);
        this.micHasBeenStarted = false;
    }

    public resetAudio() {
        if (!RagnarokSettings.allowAudioReset) {
            return;
        }
        if (!this.ios15) {
            return;
        }
        const stream = <MediaStream | undefined>(
            (this.audioElement?.srcObject ?? this.videoElement?.srcObject)
        );
        if (!stream) {
            return;
        }
        Log.i("{223689a}", "{ad4b8c2}");
        const audioTrack = stream.getAudioTracks()[0];
        audioTrack.enabled = false;
        setTimeout(() => {
            audioTrack.enabled = true;
        }, 0);
    }

    public getMicState(): MicState {
        return this.currentState;
    }

    public getTrackEndedTime(): number {
        return this.trackEndedTime;
    }

    /**
     * @param low - if true, inform the miccapturer that the audio volume will be low if mic permission are denied.
     * This is due to an iOS 15.4+ bug and used to provide telemetry that tracks the bug's user impact.
     * @see Bug 3560855
     */
    public setWillAudioVolumeBeLow(low: boolean): void {
        this.willAudioVolumeBeLow = low;
    }

    /**
     * @returns True if the audio volume is currently low, false otherwise
     */
    public isAudioVolumeLow(): boolean {
        return this.audioVolumeLow;
    }

    /**
     * @returns True if the mic has been disabled by the user. Returns false when the mic is capturing and when the mic has never been enabled.
     */
    public didUserDisableMic(): boolean {
        return this.userDisabledMic;
    }

    public extractAudioInputType(): Promise<void> {
        return navigator.mediaDevices
            .enumerateDevices()
            .then((devices: MediaDeviceInfo[]) => {
                let audioInputDeviceCount = 0;
                Log.d("{223689a}", "{fa1ec18}", JSON.stringify(devices));
                for (const device of devices) {
                    if (device.kind == "audioinput") {
                        audioInputDeviceCount++;
                    }
                }

                switch (audioInputDeviceCount) {
                    case 0:
                        this.audioInputType = AudioInputType.NO_AUDIO_INPUT;
                        this.audioInputCounts.noAudioInput++;
                        break;
                    case 1:
                        this.audioInputType = AudioInputType.BUILT_IN_MIC;
                        this.audioInputCounts.builtInMic++;
                        break;
                    default:
                        this.audioInputType = AudioInputType.HEADSET_MIC;
                        this.audioInputCounts.headsetMic++;
                        break;
                }
                Log.d("{223689a}", "{0d14ab6}", this.audioInputType);
            })
            .catch((err: any) => {
                Log.e("{223689a}", "{21131d5}");
            });
    }

    /**
     * @returns True if audio input type is built-in microphone
     */
    public isUsingBuiltInMic(): boolean {
        return this.audioInputType === AudioInputType.BUILT_IN_MIC;
    }

    /**
     * Emit debug event to idenitfy that user has run into low audio volume issue.
     * @key - Specifies type of low audio volume event
     */
    public recordLowAudioDebugEvent(key: LowAudioVolumeType): void {
        this.audioVolumeLow = true;
        this.lowAudioVolumeCounts.set(key, (this.lowAudioVolumeCounts.get(key) ?? 0) + 1);
        Log.d("{223689a}", "{30f8637}", key);
    }

    public onDeviceChange(deviceChangeCount: number) {
        this.extractAudioInputType().then(() => {
            if (
                deviceChangeCount !== 0 &&
                IsiOSVersionAtLeast(this.platformDetails, 15, 4) &&
                !this.isAudioVolumeLow() &&
                this.isUsingBuiltInMic() &&
                (this.micEnabled || this.didUserDisableMic())
            ) {
                this.recordLowAudioDebugEvent(LowAudioVolumeType.DEVICE_CHANGE);
            }
        });
    }

    public shouldDefaultEnableMic() {
        const defaultDisabledOnPlatform =
            IsWebOS(this.platformDetails) ||
            IsTizenOS(this.platformDetails) ||
            IsiDevice(this.platformDetails);
        return !defaultDisabledOnPlatform;
    }
}
