import {
    IsiPhone,
    IsiPad,
    GetHexString,
    IsiDevice,
    PlatformDetails,
    CodecType,
    BooleanType,
    ResumeType,
    IsWindowsOS,
    IsMacOS,
    IsLinuxOS,
    IsChromeOS,
    IsiOSVersionAtLeast,
    BrowserName,
    IsChromium,
    Log
} from "../dependencies";
import {
    Resolution,
    StreamingResolution,
    StreamingProfilePreset,
    StreamingSettings,
    BrowserFeature
} from "../interfaces";
import { RagnarokSettings } from "./settings";
import { RErrorCode } from "../rerrorcode";

const LOGTAG = "utils";

/************************************ Utils Exposed to Client ******************************************/

/**
 * DEPRECATED - Use ChooseStreamingSettings instead
 * Returns the streaming resolution to request from the server given
 * the active screen dimensions.
 * This API may not take device capabilities into account if they have not been cached.
 * If device capability needs to be taken into account, AreVideoSettingsSupported should be
 * used to remove unsupported resolutions from candidateResolutions prior to calling this API.
 * @param streamingProfilePreset - StreamingProfilePreset
        enum<chosen streaming profile>
        If Balanced, will choose highest resolution, else will choose lowest
 * @param candidateResolutions - StreamingResolution[]
        Object<width, height>
        List of resolutions to consider for streaming
        If empty, recommended resolution will come from a hardcoded list
 * @return - StreamingResolution
     Recommended resolution to stream at given the active screen dimensions.
**/
export function ChooseStreamingResolution(
    streamingProfilePreset: StreamingProfilePreset = StreamingProfilePreset.BALANCED,
    candidateResolutions?: StreamingResolution[]
): StreamingResolution {
    const candidateSettings = candidateResolutions?.map(res => {
        return { resolution: res, frameRate: 60 };
    });
    const selectedSettings = ChooseStreamingSettingsImpl(
        streamingProfilePreset,
        candidateSettings,
        GetStreamingSettingsExceptions(),
        screen.width,
        screen.height
    );
    return selectedSettings.resolution;
}

/**
 * Returns the streaming resolution to request from the server given the active screen dimensions.
 * This API may not take device capabilities into account if they have not been cached.
 * Should call InitializeUtils prior to this to guarantee capabilities are taken into account.
 * @param streamingProfilePreset - StreamingProfilePreset
     enum<chosen streaming profile>
     If Balanced, will choose highest resolution, else will choose lowest
 * @param candidateSettings - StreamingSettings[]
     Object<Object<width, height>, frameRate>
     List of resolutions and frame rates to consider for streaming
     If empty, recommended resolution will come from a hardcoded list
 * @param platformDetails - PlatformDetails
     Obtained from GetPlatformDetails
 * @return - StreamingSettings
     Recommended stream settings at given the active screen dimensions and device capabilities.
 **/
export function ChooseStreamingSettings(
    streamingProfilePreset: StreamingProfilePreset = StreamingProfilePreset.BALANCED,
    candidateSettings: StreamingSettings[],
    platformDetails: PlatformDetails
): StreamingSettings {
    return ChooseStreamingSettingsImpl(
        streamingProfilePreset,
        candidateSettings,
        GetStreamingSettingsExceptions(platformDetails),
        screen.width,
        screen.height
    );
}

/**
 * Calculate the recommended max streaming bitrate in Kbps given the streaming resolution and fps.
 * @param width - number
 *   streaming resolution width
 * @param height - number
 *   streaming resolution height
 * @param fps - number
 *   streaming resolution fps
 * @return - number
 *   the recommended max streaming bitrate
 */
export function CalculateMaxBitrateKbps(width: number, height: number, fps: number): number {
    const bIsResolution1440p = IsResolution1440p(width, height);
    const bIsResolution4k = IsResolution4k(width, height);

    const MULTIPLIER_30_FPS = 1.25;
    const adjustedFps = fps === 30 ? fps * MULTIPLIER_30_FPS : Math.min(fps, 60);

    let bitsPerPixel = 0;

    let maxRateKbps = width * height * adjustedFps;
    if (bIsResolution1440p || bIsResolution4k) {
        bitsPerPixel =
            RagnarokSettings.bitsPerPixel1440p ??
            RagnarokSettings.ragnarokConfig.bitsPerPixel1440p ??
            0.325;

        let maxRateKbps1080p = MODE_1080P_WIDTH * MODE_1080P_HEIGHT * adjustedFps;

        let maxRateKbps1440p = MODE_1440P_WIDTH * MODE_1440P_HEIGHT * adjustedFps;
        maxRateKbps1440p = maxRateKbps1080p + (maxRateKbps1440p - maxRateKbps1080p) / 3;

        if (bIsResolution4k) {
            maxRateKbps = maxRateKbps1440p + (maxRateKbps - maxRateKbps1440p) / 12;
        } else {
            maxRateKbps = maxRateKbps1080p + (maxRateKbps - maxRateKbps1080p) / 3;
        }
    } else {
        bitsPerPixel =
            RagnarokSettings.bitsPerPixel ?? RagnarokSettings.ragnarokConfig.bitsPerPixel ?? 0.3;
    }

    const BITRATE_ADJUSTMENT_FACTOR = 1.2;
    maxRateKbps = (maxRateKbps * bitsPerPixel) / (BITRATE_ADJUSTMENT_FACTOR * 1000);

    if (fps >= 120 && !bIsResolution4k) {
        maxRateKbps = Math.min(50000, Math.round(maxRateKbps * 1.15));
    }

    return Math.round(maxRateKbps);
}

/**
 * Calculate estimated data usage for a given combination of fps and bitrateKbps values
 * @param fps - number
     Streaming refresh rate
 * @param bitrateKbps - number
     Streaming bitrate in Kbps
 * @return - number
     Data usage Estimation in GB per hour.
**/
export function CalculateDataUsage(fps: number, bitrateKbps: number) {
    // Streaming Bitrate formula (data usage estimate):
    // Default Mode [60Hz]
    //     streamingBitrate = 0.678*maxBitrate + 910569
    // Turbo Mode [120Hz]
    //     streamingBitrate  = 0.916*maxBitrate + 293098
    // Note:
    //  streamingBitrate and maxBitrate should be in the units of [bps: bits per seconds]
    //  GBph [Giga Bytes per hour] = (bps * 3600 * 10^-9) / 8
    //  Mbps [Mega Bits per sec] = (bps * 10^-6)
    //  GBph@30Hz = 0.625 * GBph@60Hz

    const A60 = 0.678;
    const A120 = 0.916;
    const B60 = 910569;
    const B120 = 293098;
    const adjust30 = 0.625;
    const multiplyFactor = 0.95;
    const bitratebps = bitrateKbps * 1000;

    const streamingBitrate = fps === 120 ? A120 * bitratebps + B120 : A60 * bitratebps + B60;
    // convert bps to GB:   GBph [Giga Bytes per hour] = (bps * 3600 * 10^-9) / 8
    let dataUsageGB = (streamingBitrate * 3600) / 1000000000 / 8;

    if (fps === 30) {
        dataUsageGB = dataUsageGB * adjust30;
    }

    // round data usage to integer
    dataUsageGB = dataUsageGB * multiplyFactor;
    dataUsageGB = Math.round(dataUsageGB);

    return dataUsageGB;
}

/**
 * Initialize the utility functions
 * Client should call prior to using other utility APIs
 * If not called, other APIs will still work but may take longer or return less accurate results
 */
export function InitializeUtils() {
    for (const task of tasksToRunOnInit) {
        task();
    }
}

/**
 * Checks whether or not the device supports a given feature
 * @param browserFeature - BrowserFeature
     enum<feature to check>
     See definition in interfaces.ts for supported feature checks
     If value is not part of defined enums, will return false
 * @param platformDetails - PlatformDetails
     Obtained from GetPlatformDetails
 * @return - boolean
     Whether or not the device supports the specified feature
**/
export function IsFeatureSupported(
    browserFeature: BrowserFeature,
    platformDetails: PlatformDetails
): boolean {
    switch (browserFeature) {
        case BrowserFeature.Streaming:
            return IsGamestreamSupported(platformDetails);
        default:
            return false;
    }
}

/**
 * This method returns the channel count supported by default device of client.
 * @return Channel count - 2(STEREO), 6(SURROUND_5_1) or 8(SURROUND_7_1)
 **/
export function GetSupportedAudioChannelCount(): number {
    const audioCtx = GetAudioContext();
    if (audioCtx) {
        const channelCount = audioCtx.destination.maxChannelCount;
        audioCtx.close();
        Log.i("{d988e7f}", "{fa78e0a}", channelCount);
        return channelCount;
    } else {
        Log.w("{d988e7f}", "{d018fac}");
        return 2;
    }
}

/************************************ Utils Exposed to Client (END) ******************************************/

/************************************ Internal Utils ******************************************/

// Limit resolutions to be constrained to closest aspect ratio and max resolution.
// We can happily letterbox or pillarbox as needed, but we
// don't want to miss selecting a resolution because it is too large
// in one dimension or another for streaming.
function LimitResolution(
    resolution: [number, number],
    limits: [number, number][]
): [number, number] {
    if (limits.length == 0) return resolution;

    class AspectRatio {
        public w: number;
        public h: number;
        public a: number;
        constructor(w: number, h: number) {
            this.w = w;
            this.h = h;
            this.a = w / h;
        }
        public clip(f: AspectRatio) {
            if (Math.abs(f.a - this.a) < 0.05) return f;

            if (f.a > this.a) {
                // f is wider than this, clip sides
                // stream will be letterboxed
                return new AspectRatio(Math.floor(f.w * (this.a / f.a)), f.h);
            } else {
                // f is wider than this, clip top/bottom
                // stream will be pillarboxed
                return new AspectRatio(f.w, Math.floor(f.h * (f.a / this.a)));
            }
        }
        public toTuple(): [number, number] {
            return [this.w, this.h];
        }
    }

    const ratios = limits.map(a => new AspectRatio(a[0], a[1]));

    const r = new AspectRatio(resolution[0], resolution[1]);
    let b = ratios[0];
    for (let i = 1; i < ratios.length; i++) {
        let a = ratios[i];
        let rd = Math.abs(r.a - a.a);
        let bd = Math.abs(r.a - b.a);

        if (rd < bd) {
            b = a;
        }
    }

    const clipped = b.clip(r);

    // Limit to max if appropriate
    if (clipped.w > b.w) {
        return b.toTuple();
    }

    return clipped.toTuple();
}

function MakeLandscapeResolution(res: [number, number]): [number, number] {
    if (res[0] < res[1]) {
        return [res[1], res[0]];
    }
    return res;
}

/**
 * Enum that specifies which exceptions should be made during streaming settings selection
 * Can have multiple exceptions, by ORing exceptions together
 */
export const enum StreamingSettingsException {
    NONE = 0x00,
    IPHONE = 0x01,
    IPAD = 0x02,
    TIZEN4K = 0x04,
    WEBOS4K = 0x08
}

export function ChooseStreamingSettingsImpl(
    streamingProfilePreset: StreamingProfilePreset,
    candidateSettings: StreamingSettings[] | undefined,
    exception: StreamingSettingsException,
    screenWidth: number,
    screenHeight: number
): StreamingSettings {
    const iPhone = !!(exception & StreamingSettingsException.IPHONE);
    const iPad = !!(exception & StreamingSettingsException.IPAD);
    const tizen4k = !!(exception & StreamingSettingsException.TIZEN4K);
    const webOs4k = !!(exception & StreamingSettingsException.WEBOS4K);
    if (tizen4k || webOs4k) {
        screenWidth = 3840;
        screenHeight = 2160;
    }
    if (candidateSettings && candidateSettings.length > 0) {
        [screenWidth, screenHeight] = MakeLandscapeResolution([screenWidth, screenHeight]);
        // 13" iPads aren't large enough to get 1600x1200 normally, but it is the best resolution since i devices are pixel dense
        // override the dimensions to get the right resolution
        // TODO: find a way to remove this, we don't want any hard coding on the client
        if (
            iPad &&
            screenWidth >= 1366 &&
            streamingProfilePreset === StreamingProfilePreset.BALANCED
        ) {
            screenWidth = 1600;
            screenHeight = 1200;
        }

        const screenAspectRatio = screenWidth / screenHeight;
        const screenPixelCount = screenWidth * screenHeight;
        // only consider 120+ FPS settings if allowed (and there are non-120 FPS options)
        const shouldAllow120Fps = false;
        if (!shouldAllow120Fps) {
            const filteredSettings = candidateSettings.filter(settings => settings.frameRate < 120);
            if (filteredSettings.length) {
                candidateSettings = filteredSettings;
            }
        }
        // sort settings so that the first element is the best match
        // 1. closest aspect ratio
        // 2. resolution fits in the screen
        // 3. if both meet 1 and 2, choose based on fps* then resolution**
        // 4. if neither meets 1 and 2, choose based on resolution** then fps*
        // *  Highest fps for balanced and competitive, only recommend 120 if allowed, lowest for datasaver
        // ** Largest resolution for balanced preset, smallest for competitve and datasaver
        // return: negative value if settings a should be recommended over b, positive if b over a
        const settingsCompare = (a: StreamingSettings, b: StreamingSettings) => {
            const aRes = a.resolution;
            const bRes = b.resolution;
            const aspectRatioDiff =
                Math.abs(aRes.width / aRes.height - screenAspectRatio) -
                Math.abs(bRes.width / bRes.height - screenAspectRatio);
            // To minimize letter boxing, choose the closest aspect ratio
            if (Math.abs(aspectRatioDiff) > 0.05) {
                return aspectRatioDiff;
            }
            // If balanced, we want to select the largest profile with a good aspect ratio, so descending order
            const resolutionPresetSelector =
                streamingProfilePreset === StreamingProfilePreset.BALANCED ? -1 : 1;
            // In datasaver, we want to select the lowest frame rate, so ascending order
            const frameRatePresetSelector =
                streamingProfilePreset === StreamingProfilePreset.DATASAVER ? 1 : -1;
            const frameRateSelection = frameRatePresetSelector * (a.frameRate - b.frameRate);

            const aPixelCount = aRes.width * aRes.height;
            const bPixelCount = bRes.width * bRes.height;
            const pixelCountDiff = aPixelCount - bPixelCount;

            if (aPixelCount <= screenPixelCount) {
                if (bPixelCount <= screenPixelCount) {
                    // both fit in screen, choose based on preset mode
                    if (a.frameRate !== b.frameRate) {
                        return frameRateSelection;
                    } else {
                        return resolutionPresetSelector * pixelCountDiff;
                    }
                } else {
                    return -1; // a fits but b doesn't, put a closer to front
                }
            } else if (bPixelCount <= screenPixelCount) {
                return 1; // b fits but a doesn't, put b closer to front
            } else {
                // neither fit in screen, choose smallest
                if (pixelCountDiff) {
                    return pixelCountDiff;
                } else {
                    return frameRateSelection;
                }
            }
        };

        candidateSettings.sort(settingsCompare);
        return candidateSettings[0];
    } else {
        let selectedSettings: StreamingSettings = {
            resolution: { width: 1280, height: 720 },
            frameRate: 60
        };
        if (iPhone) {
            let [width, height] = MakeLandscapeResolution([screenWidth, screenHeight]);
            let aspect = width / height;
            // iPhones with notches ideally want 1376x640, and the server fallback will
            // give them 720p.
            //
            // 16:9 iPhones always get 720p, this is the closest resolution we support that matches
            // their aspect ratio. They have high enough density displays that they could show
            // more, but if we stream that then user interface elements will appear too small.
            // Revisit this once we have support for hidpi streaming.
            if (aspect > 2.0) {
                selectedSettings.resolution = { width: 1376, height: 640 };
            }
        } else if (iPad) {
            if (streamingProfilePreset != StreamingProfilePreset.BALANCED) {
                // return our lowest 4:3 resolution for datasaver and competitive presets
                selectedSettings.resolution = { width: 1024, height: 768 };
            } else {
                // Similar story for iPads, if we just go by the pixels on the screen they would
                // always get the max resolution stream, but you wouldn't be able to tap on
                // anything. Pick a happy middle ground for them.
                let [width, _] = MakeLandscapeResolution([screenWidth, screenHeight]);
                if (width <= 1024) {
                    // <= 10" iPads
                    selectedSettings.resolution = { width: 1024, height: 768 };
                } else if (width < 1366) {
                    // ~11" iPads
                    selectedSettings.resolution = { width: 1112, height: 834 };
                } else {
                    // 13" iPad
                    selectedSettings.resolution = { width: 1600, height: 1200 };
                }
            }
        } else {
            // Everything else (Chromebooks, PCs, etc).
            // Clip and limit resolution

            // Limits for balanced preset
            const upperLimits: [number, number][] = [
                [1920, 1200], // 16:10
                [1920, 1080], // 16:9
                [1280, 1024], // 5:4
                [1600, 1200] // 4:3
            ];

            if (tizen4k) {
                // add 4k to head of list
                upperLimits.unshift([3840, 2160]);
            }

            // Limits for competitive and data saver
            const reducedLimits: [number, number][] = [
                [1280, 800], // 16:10
                [1280, 720], // 16:9
                [1280, 1024], // 5:4
                [1024, 768] // 4:3
            ];
            let res: [number, number] = [screenWidth, screenHeight];
            const limits =
                streamingProfilePreset == StreamingProfilePreset.BALANCED
                    ? upperLimits
                    : reducedLimits;
            res = LimitResolution(res, limits);

            selectedSettings.resolution = { width: res[0], height: res[1] };
        }
        return selectedSettings;
    }
}

function GetStreamingSettingsExceptions(platformDetails?: PlatformDetails) {
    let exception = StreamingSettingsException.NONE;
    if (IsiPhone(platformDetails)) {
        exception |= StreamingSettingsException.IPHONE;
    }
    if (IsiPad(platformDetails)) {
        exception |= StreamingSettingsException.IPAD;
    }
    // this will only be set on Tizen, and if webapis.js was loaded
    // IsTV() won't accept NULL PlatformDetails, so just check this way.
    if (window.webapis?.productinfo) {
        if (window.webapis.productinfo.isUdPanelSupported()) {
            exception |= StreamingSettingsException.TIZEN4K;
        }
    }
    if (window["lge_webrtc_hevc_support"]) {
        exception |= StreamingSettingsException.WEBOS4K;
    }
    return exception;
}

/**
 * Checks whether or not the device supports the video settings for streaming
 * Note: Does not take monitor dimensions into account for resolutions
 * This API may not take device capabilities into account if they have not been cached.
 * Should call InitializeUtils prior to this to guarantee capabilities are taken into account.
 * @param candidateSettings - StreamingSettings[]
     Object<Object<width, height>, frameRate>
     List of resolutions and frame rates to check for capability
 * @param platformDetails - PlatformDetails
     Obtained from GetPlatformDetails
 * @return - StreamingSettings[]
     List of settings supported by the device.
     Guaranteed to be a subset of candidateSettings.
     Can change between subsequent calls (e.g. new monitor with new refresh rate introduced)
**/
export function FilterSupportedStreamingSettings(
    candidateSettings: StreamingSettings[],
    platformDetails: PlatformDetails
): StreamingSettings[] {
    // TODO: Filter out settings based on device capabilities
    // for now don't block anything according to current POR
    return candidateSettings;
}

export interface EdgeInsets {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

// Returns the safe areas of the window
// For example, on an iPhone, this may include insets for the notch and the home indicator
// On an iPad, this may include insets for the status bar and possibly the home indicator
// The results of this function will change when viewport / orientation changes.
export function GetSafeAreas(): EdgeInsets {
    if (!getComputedStyle(document.documentElement)) {
        return { top: 0, left: 0, bottom: 0, right: 0 };
    }
    if (!getComputedStyle(document.documentElement).getPropertyValue("--sat")) {
        document.documentElement.style.setProperty("--sat", "env(safe-area-inset-top)");
        document.documentElement.style.setProperty("--sar", "env(safe-area-inset-right)");
        document.documentElement.style.setProperty("--sab", "env(safe-area-inset-bottom)");
        document.documentElement.style.setProperty("--sal", "env(safe-area-inset-left)");
    }
    return {
        top: parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat")),
        left: parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sal")),
        bottom: parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab")),
        right: parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sar"))
    };
}

function clamp(v: number, lo: number, hi: number): number {
    if (v < lo) return lo;
    if (v > hi) return hi;
    return v;
}

export interface TouchPoint {
    x: number;
    y: number;
}

export interface TouchPosSize {
    clientX: number;
    clientY: number;
    radiusX: number;
    radiusY: number;
}

export function WarpTouch(touch: TouchPosSize): TouchPoint {
    let safe = GetSafeAreas();
    safe.top = Math.max(safe.top, 21); // always reserve status bar height at top
    safe.bottom = clamp(safe.bottom, 0, 10); // bottom safe area on devices that have it actually reject a lot less than the height of the whole safe area.
    let pt = { x: touch.clientX, y: touch.clientY };
    let anchorY = 0.5;
    if (safe.top > 0 && touch.clientY - touch.radiusY < safe.top) {
        // Compute fraction of upper half of touch that overlaps the safe area
        const touchTop = touch.clientY - Math.min(touch.radiusY, 21.0);
        let frac = (safe.top - touchTop) / touch.radiusY;
        frac = clamp(frac, 0.0, 1.0);
        // use quadratic warp so we warp more as we get closer to edge
        // halve frac to apply to whole touch area
        anchorY -= 0.5 * (frac * frac);
    } else if (
        safe.bottom > 0 &&
        touch.clientY + touch.radiusY > window.innerHeight - safe.bottom
    ) {
        // Same idea as above, but for lower half. Note that we use
        // a smaller cap for the radius for lower half than top
        // because it is possible to touch closer to the bottom
        // than it is to the top.
        const touchBottom = touch.clientY + Math.min(touch.radiusY, 10.0);
        let frac = (touchBottom - (window.innerHeight - safe.bottom)) / touch.radiusY;
        frac = clamp(frac, 0.0, 1.0);
        anchorY += 0.5 * (frac * frac);
    }
    pt.y = clamp(pt.y - touch.radiusY + anchorY * 2.0 * touch.radiusY, 0.0, window.innerHeight);
    return pt;
}

export function ConvertErrorOnConnectivityTest(errorCode: number): number {
    let newCode = errorCode;
    if (
        RagnarokSettings.ragnarokConfig.offlineErrorsStreaming &&
        RagnarokSettings.ragnarokConfig.offlineErrorsStreaming.includes(GetHexString(errorCode))
    ) {
        newCode = RErrorCode.NoInternetDuringStreaming;
    } else if (
        RagnarokSettings.ragnarokConfig.offlineErrorsSessionSetup &&
        RagnarokSettings.ragnarokConfig.offlineErrorsSessionSetup.includes(GetHexString(errorCode))
    ) {
        newCode = RErrorCode.NoInternetDuringSessionSetup;
    } else {
        switch (errorCode) {
            case RErrorCode.StreamerIceReConnectionFailed:
            case RErrorCode.StreamerReConnectionFailed:
                newCode = RErrorCode.NoInternetDuringStreaming;
                break;
            case RErrorCode.NetworkError:
                newCode = RErrorCode.NoInternetDuringSessionSetup;
                break;
        }
    }

    if (newCode !== errorCode) {
        Log.i("{d988e7f}", "{b84d4c8}", GetHexString(errorCode), GetHexString(newCode));
    }
    return newCode;
}

export function ConvertErrorOnSleep(errorCode: number, platformDetails: PlatformDetails): number {
    let newCode = errorCode;
    if (
        RagnarokSettings.ragnarokConfig.sleepErrorsStreaming &&
        RagnarokSettings.ragnarokConfig.sleepErrorsStreaming.includes(GetHexString(errorCode))
    ) {
        newCode = RErrorCode.SystemSleepDuringStreaming;
    } else if (
        RagnarokSettings.ragnarokConfig.sleepErrorsSessionSetup &&
        RagnarokSettings.ragnarokConfig.sleepErrorsSessionSetup.includes(GetHexString(errorCode))
    ) {
        newCode = RErrorCode.SystemSleepDuringSessionSetup;
    } else {
        switch (errorCode) {
            case RErrorCode.StreamerIceReConnectionFailed:
                if (IsiDevice(platformDetails)) {
                    newCode = RErrorCode.SystemSleepDuringStreaming;
                }
                break;
            case RErrorCode.ServerDisconnectedPeerRemovedByServer:
                newCode = RErrorCode.SystemSleepDuringStreaming;
                break;
            case RErrorCode.InvalidSessionIdNotFound:
                newCode = RErrorCode.SystemSleepDuringSessionSetup;
                break;
        }
    }

    if (newCode !== errorCode) {
        Log.i("{d988e7f}", "{49e2182}", GetHexString(errorCode), GetHexString(newCode));
    }
    return newCode;
}

export function IsStreamingErrorCategory(errorCode: number): boolean {
    return (RErrorCode.StreamerErrorCategory ^ errorCode) >> 8 === 0;
}

export function ShouldRunConnectivityTest(errorCode: number): boolean {
    return IsStreamingErrorCategory(errorCode) || errorCode === RErrorCode.NetworkError;
}

// \todo transition to setBigUint64 after transition to es2020
export function setUint64(
    value: number,
    dataBufferView: DataView,
    offset: number,
    littleEndian: boolean,
    scale: number = 1
): void {
    const lower = Math.floor(value * scale) & 0xffffffff;
    const upper = Math.floor((value / 0x100000000) * scale);
    if (littleEndian) {
        dataBufferView.setUint32(offset, lower, true);
        dataBufferView.setUint32(offset + 4, upper, true);
    } else {
        dataBufferView.setUint32(offset, upper, false);
        dataBufferView.setUint32(offset + 4, lower, false);
    }
}

// \todo transition to getBigUint64 after transition to es2020
export function getUint64(dataBufferView: DataView, offset: number, littleEndian: boolean): number {
    let lower = 0;
    let upper = 0;
    if (littleEndian) {
        lower = dataBufferView.getUint32(offset, true);
        upper = dataBufferView.getUint32(offset + 4, true);
    } else {
        upper = dataBufferView.getUint32(offset, false);
        lower = dataBufferView.getUint32(offset + 4, false);
    }
    return upper * 0x100000000 + lower;
}

export function CanResume(code: number, platformDetails: PlatformDetails): boolean {
    let result = false;
    switch (code) {
        // List of resumable codes
        case RErrorCode.StreamInputChannelError:
        case RErrorCode.StreamCursorChannelError:
        case RErrorCode.StreamControlChannelError:
        case RErrorCode.StreamerIceReConnectionFailed:
        case RErrorCode.StreamerReConnectionFailed:
        case RErrorCode.StreamerNoVideoFramesLossyNetwork:
        case RErrorCode.NoInternetDuringStreaming:
        case RErrorCode.ServerDisconnectedPeerRemovedByServer:
            result = true;
            break;
        case RErrorCode.SystemSleepDuringStreaming:
            if (IsiDevice(platformDetails)) {
                result = true;
            }
            break;
    }
    return result;
}

const MODE_1080P_WIDTH = 1920;
const MODE_1080P_HEIGHT = 1080;
const MODE_UWFHD_1080P_WIDTH = 2560;
const MODE_QHDLIKE_PIXEL_THRESHOLD = MODE_UWFHD_1080P_WIDTH * MODE_1080P_HEIGHT;
const MODE_1440P_WIDTH = 2560;
const MODE_1440P_HEIGHT = 1440;
const MODE_UWQHD_1440P_WIDTH = 3440;
const MODE_4KLIKE_PIXEL_THRESHOLD = MODE_UWQHD_1440P_WIDTH * MODE_1440P_HEIGHT;

export function IsResolution1440p(width: number, height: number) {
    const pixelCount = width * height;
    return pixelCount >= MODE_QHDLIKE_PIXEL_THRESHOLD && pixelCount < MODE_4KLIKE_PIXEL_THRESHOLD;
}

export function IsResolution4k(width: number, height: number) {
    const pixelCount = width * height;
    return pixelCount >= MODE_4KLIKE_PIXEL_THRESHOLD;
}

// codecString is of the format 'video/H264'
export function GetCodecType(codecString?: string): CodecType {
    let codecType: CodecType = CodecType.UNKNOWN;
    if (codecString) {
        codecString = codecString.toUpperCase();
        if (codecString.includes("H264")) {
            codecType = CodecType.H264;
        } else if (codecString.includes("H265")) {
            codecType = CodecType.HEVC;
        }
    }
    return codecType;
}

export function ShouldEnableCPM(platformDetails: PlatformDetails): boolean {
    // Only enable CPM on platforms where it is verified
    // WebOS is known to have innacurate decode time stats
    const allowCPMOnPlatform =
        IsChromeOS(platformDetails) ||
        IsWindowsOS(platformDetails) ||
        IsMacOS(platformDetails) ||
        IsLinuxOS(platformDetails) ||
        IsiDevice(platformDetails);
    return RagnarokSettings.ragnarokConfig.enableCpm ?? allowCPMOnPlatform;
}

export function ToBooleanType(x?: boolean): BooleanType {
    if (x !== undefined) {
        return x ? BooleanType.TRUE : BooleanType.FALSE;
    }
    return BooleanType.UNDEFINED;
}

export function ToResumeType(isResume: boolean): ResumeType {
    return isResume ? ResumeType.MANUAL : ResumeType.NONE;
}

/**
 * Tasks to be ran on startup
 * Tasks must be asynchronous and non-blocking
 * Tasks must have any required arguments already bound
 */
let tasksToRunOnInit: VoidFunction[] = [];
export function RunTaskOnInit(task: VoidFunction) {
    tasksToRunOnInit.push(task);
}

/**
 * @return String representation of resolution in format {width}x{height}
 */
export function GetResolutionString(resolution: Resolution): string {
    return `${resolution.width.toFixed()}x${resolution.height.toFixed()}`;
}

export function GetLogicalResolution(): Resolution {
    return { width: screen.width, height: screen.height };
}

export function GetPhysicalResolution(): Resolution {
    return {
        width: screen.width * window.devicePixelRatio,
        height: screen.height * window.devicePixelRatio
    };
}

/**
 * Copies bytes from a Uint8Array to a DataView
 * @param to Target data view
 * @param toOffset Offset into DataView to start copying bytes
 * @param from Source array. The entire array will be copied
 */
export function setUint8Array(to: DataView, toOffset: number, from: Uint8Array) {
    const numBytes = from.byteLength;
    // Copying byte-by-byte like this is ~20x faster than calling Uint8Array.set() on current Chrome versions
    for (let i = 0; i < numBytes; ++i) {
        to.setUint8(toOffset + i, from[i]);
    }
}

/**
 * Reports whether or not the device meets the minimum requirements for gamestreaming
 * Based on browser/OS/version/features
 */
function IsGamestreamSupported(platformDetails: PlatformDetails): boolean {
    if (IsiDevice(platformDetails)) {
        // All browsers on iOS are WebKit based, WebKit got some required fixes in iOS 14
        return IsiOSVersionAtLeast(platformDetails, 14);
    }

    let isStreamingSupported = false;
    if (IsChromium()) {
        // Chrome 77 has some required patches for gamestreaming
        // However, Chrome that old does not provide user agent hints needed to reliably get the version
        // Just allow all Chromium browsers for now
        // Can update versions as we find minimum required versions and ways to detect them
        isStreamingSupported = true;
    }
    // Non-Chromium supported browser list
    // CHROME, CHROMIUM, WEBOS, SAMSUNG, SILK, OPERA, YANDEX, EDGE, BRAVE, SILK, REACTNATIVE covered by IsChromium check
    // Firefox missing required SDP APIs
    switch (platformDetails.browser) {
        case BrowserName.EDGE_LEGACY:
        case BrowserName.SAFARI:
            isStreamingSupported = true;
            break;
        default:
            break;
    }

    return isStreamingSupported;
}

/**
 * Saves content to file name provided.
 * @return True if successfully downloaded content, false otherwise.
 */
export function Download(content: any, fileName: string, mimeType: string): boolean {
    try {
        // Create a hidden link element and simulate a click on it. This is the only way
        // to give the downloaded file a custom filename.
        const link = document.createElement("a");
        link.style.display = "none";
        const blob = new Blob(content, {
            type: mimeType
        });
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        return true;
    } catch (err) {
        Log.e("{d988e7f}", "{8d57ba9}", err);
        return false;
    }
}

/**
 * @return Audio context if supported by browser
 */
export function GetAudioContext(sampleRate?: number): AudioContext | undefined {
    const audioContext =
        (<any>window).AudioContext || (<any>window).webkitAudioContext || undefined;
    if (audioContext) {
        let audioCtx = undefined;
        if (sampleRate) {
            audioCtx = new audioContext({ sampeleRate: sampleRate });
        } else {
            audioCtx = new audioContext();
        }
        return audioCtx;
    } else {
        Log.w("{d988e7f}", "{b74c037}");
        return undefined;
    }
}

/**
 *
 * @param a Object a
 * @param b Object b
 * @return True if objects have same properties with same values
 */
export function IsEqualShallow(a: Object, b: Object) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) {
        return false;
    }
    for (const key of keysA) {
        if (a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

/**
 *
 * @return True if pointer events are supported, false otherwise
 */
export function SupportsPointerEvents(): boolean {
    return !!PointerEvent?.prototype;
}

/************************************ Internal Utils (END) ******************************************/
