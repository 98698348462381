import { NetworkTypeEnum, Log } from "../dependencies";

const LOGTAG = "NetworkDetector";

declare interface NetworkUpdateCallback {
    (network: NetworkTypeEnum): void;
}

declare interface LGNetworkEvent extends Event {
    detail: {
        state: string;
    };
}

class NetworkDetectorImpl {
    private networkTypeMap: Map<string, NetworkTypeEnum> = new Map<string, NetworkTypeEnum>([
        ["Ethernet", NetworkTypeEnum.ETHERNET],
        ["5GHz_WiFi", NetworkTypeEnum.WIFI_5_0],
        ["2.4GHz_WiFi", NetworkTypeEnum.WIFI_2_4],
        ["Others", NetworkTypeEnum.OTHER],
        ["disconnected", NetworkTypeEnum.UNKNOWN]
    ]);
    private network: NetworkTypeEnum = NetworkTypeEnum.UNKNOWN;
    private networkUpdateCallbacks: NetworkUpdateCallback[] = [];

    constructor() {
        window.addEventListener("GFNNetworkInfo", (evt: Event) => {
            const e = evt as LGNetworkEvent;
            Log.d("{aac1655}", "{2925c9d}", e.detail.state);
            this.updateNetwork(e.detail.state);
        });
    }

    // Invoke callbacks to update network status
    private updateNetwork(net: string) {
        const networkType = this.networkTypeMap.get(net);
        if (networkType) {
            this.network = networkType;
            for (const callback of this.networkUpdateCallbacks) {
                callback(this.getNetworkType());
            }
        } else {
            Log.d("{aac1655}", "{ed2fb91}", net);
        }
    }

    public registerNetworkCallback(callback: NetworkUpdateCallback) {
        this.networkUpdateCallbacks.push(callback);
    }

    public unregisterNetworkCallback(callback: NetworkUpdateCallback) {
        const idx = this.networkUpdateCallbacks.findIndex(e => e == callback);
        if (idx !== -1) {
            this.networkUpdateCallbacks.splice(idx, 1);
        }
    }

    public getNetworkType(): NetworkTypeEnum {
        return this.network;
    }
}

export const NetworkDetector = new NetworkDetectorImpl();
