import { StatsHeader, WebSocketClose, WebSocketMsg, WorkerResponse } from "./rinterfaces";
import {
    Perf,
    ClientEvent,
    WorkerMessage,
    StreamingQualityWithTs,
    MtbDuration,
    InputChannelStats,
    GarbageCollectionStats
} from "./ragnarokprofiler";

import { WebrtcBinaryStats } from "./stats/webrtcbinarystats";
import { WebSocketImpl } from "./websocketimpl";

const ctx: Worker = self as any;

/* This worker thread periodically uploads the browser perf and other metrics at regular interval to the server.
   Protocol Description: https://confluence.nvidia.com/display/GAMESTREAM/Browser+Stats+Wire+Protocol
   Note:  The initial version of this file had RagnarokWorker class but unfortunately the setInterval call
   to the member function of the class did not work. Couldn't figure out the reason.
   The same code in mainthread worked. Hence removed class and split everything into functions for now.
*/
const ChunkHeaderSize = 9;
const PerfEntrySize = 13;
const EventEntrySize = 72;
const UploadInterval = 5000;
const StreamingQualityEntrySize = 12;
const MtbDurationSize = 10;
const InputChannelEntrySize = 12;
const GarbageCollectionEntrySize = 16;

let perfs: Perf[] = [];
let clientEvents: ClientEvent[] = [];
let webrtcBinaryStats: WebrtcBinaryStats = new WebrtcBinaryStats();
let sessionId: string = ""; // SessionId
let url: string = ""; // Connection URL for WebSocket.
let statsHeaderObj: StatsHeader | null = null;
let webSocket: WebSocketImpl | undefined = undefined;
let uploadIntervalId: number = 0; // Timer Id
let streamingQuality: StreamingQualityWithTs[] = [];
let mtbDurations: MtbDuration[] = [];
let inputChannelStats: InputChannelStats[] = [];
let garbageCollectionStats: GarbageCollectionStats[] = [];
let serverSupportsAck = false;
let nextAckIdToSendForStats = 0;

function workerLogger(logmsg: string) {
    let msg: WorkerResponse = {
        log: logmsg
    };
    ctx.postMessage(msg);
}

/* Right now we are uploading only exception message.
   Once the exception handler is re-factored we can collect more data.
*/
function workerException(expmsg: string) {
    let msg: WorkerResponse = {
        exception: expmsg
    };
    ctx.postMessage(msg);
}

function clearCachedData() {
    perfs = [];
    clientEvents = [];
    webrtcBinaryStats.resetLists();
    streamingQuality = [];
    mtbDurations = [];
    inputChannelStats = [];
    garbageCollectionStats = [];
}

function writeChunkHeader(
    dataBufferView: DataView,
    offset: number,
    chunkType: string,
    version: number,
    elementCount: number,
    sizeOfElement: number
) {
    for (let i = 0; i < 4; i++) {
        dataBufferView.setUint8(offset + i, chunkType.charCodeAt(i));
    }
    dataBufferView.setUint8(offset + 4, version);
    dataBufferView.setUint16(offset + 5, elementCount, true);
    dataBufferView.setUint16(offset + 7, sizeOfElement, true);
}

function writePerf(dataBufferView: DataView, offset: number) {
    for (let i = 0; i < perfs.length; i++) {
        dataBufferView.setFloat64(offset, perfs[i].RAFTS, true);
        /* The below values are in millisec.microsec format. Covert into microsec.
           In order to reduce the upload bytes size collecting as UInt16 ~ 65msec. */
        dataBufferView.setUint16(offset + 8, Math.min(perfs[i].DCSend * 1000, 0xffff), true);
        dataBufferView.setUint16(offset + 10, Math.min(perfs[i].GetStats * 1000, 0xffff), true);
        dataBufferView.setUint8(offset + 12, perfs[i].FrameInfo);
        offset += PerfEntrySize;
    }
}

function writeSqScore(dataBufferView: DataView, offset: number) {
    for (let i = 0; i < streamingQuality.length; i++) {
        dataBufferView.setUint8(offset, streamingQuality[i].qualityScore);
        dataBufferView.setUint8(offset + 1, streamingQuality[i].bandwidthScore);
        dataBufferView.setUint8(offset + 2, streamingQuality[i].latencyScore);
        dataBufferView.setUint8(offset + 3, streamingQuality[i].networkLossScore);
        dataBufferView.setFloat64(offset + 4, streamingQuality[i].timestamp, true);
        offset += StreamingQualityEntrySize;
    }
}

function writeClientEvents(dataBufferView: DataView, offset: number) {
    for (let i = 0; i < clientEvents.length; i++) {
        dataBufferView.setFloat64(offset, clientEvents[i].TS, true);
        offset = offset + 8;
        let eventstr = clientEvents[i].eventtype;
        let j = 0;
        for (; j < eventstr.length && j < 63; j++) {
            dataBufferView.setUint8(offset + j, eventstr.charCodeAt(j));
        }
        dataBufferView.setUint8(offset + j, 0x00);
        offset = offset + 64;
    }
}

function writeBlockedDurations(dataBufferView: DataView, offset: number) {
    // Send values in milliseconds, not us as other times (getstats, DC send).
    // Gives greater usable range on the server - scaling to us would
    // limit the range to 50-65ms, with everything larger reported as 0xffff.
    for (let i = 0; i < mtbDurations.length; i++) {
        dataBufferView.setFloat64(offset, mtbDurations[i].timestamp, true);
        dataBufferView.setUint16(offset + 8, Math.min(mtbDurations[i].duration, 0xffff), true);
        offset = offset + MtbDurationSize;
    }
}

function writeInputChannelStats(dataBufferView: DataView, offset: number) {
    for (let i = 0; i < inputChannelStats.length; i++) {
        dataBufferView.setFloat64(offset, inputChannelStats[i].timestamp, true);
        dataBufferView.setUint16(
            offset + 8,
            Math.min(inputChannelStats[i].bufferedAmount, 0xffff),
            true
        );
        dataBufferView.setUint16(
            offset + 10,
            Math.min(inputChannelStats[i].maxSchedulingDelay, 0xffff),
            true
        );
        offset += InputChannelEntrySize;
    }
}

function asInt32(num: number): number {
    if (num < 0) {
        return Math.max(num, -0x80000000);
    }
    return Math.min(num, 0xffffffff);
}

function writeGarbageCollectionStats(dataBufferView: DataView, offset: number) {
    for (let i = 0; i < garbageCollectionStats.length; i++) {
        dataBufferView.setFloat64(offset, garbageCollectionStats[i].timestamp, true);
        dataBufferView.setInt32(
            offset + 8,
            asInt32(garbageCollectionStats[i].deltaUsedHeapSize),
            true
        );
        dataBufferView.setInt32(
            offset + 12,
            asInt32(garbageCollectionStats[i].deltaTotalHeapSize),
            true
        );
        offset += GarbageCollectionEntrySize;
    }
}

function uploadData() {
    if ((perfs.length || clientEvents.length) && statsHeaderObj) {
        try {
            statsHeaderObj.ackid = serverSupportsAck ? nextAckIdToSendForStats : undefined;
            const header = JSON.stringify(statsHeaderObj);
            const len = header.length;
            const totalLen = len + 2; // 2 bytes for the size of the header.
            const headerBytesBuffer = new ArrayBuffer(totalLen);
            const headerView = new DataView(headerBytesBuffer);
            headerView.setUint16(0, len);
            for (var i = 2; i < totalLen; ++i) {
                headerView.setUint8(i, header.charCodeAt(i - 2));
            }
            const headerBufferBytes = new Uint8Array(headerBytesBuffer);
            const perfDataSize = PerfEntrySize * perfs.length;
            const eventsDataSize = EventEntrySize * clientEvents.length;
            const sqEventsDataSize = StreamingQualityEntrySize * streamingQuality.length;
            const durationDataSize = MtbDurationSize * mtbDurations.length;
            const inputChannelDataSize = InputChannelEntrySize * inputChannelStats.length;
            const garbageCollectionDataSize =
                GarbageCollectionEntrySize * garbageCollectionStats.length;
            let totalSize = ChunkHeaderSize + headerBufferBytes.length;
            if (perfDataSize) {
                totalSize += ChunkHeaderSize + perfDataSize;
            }
            if (eventsDataSize) {
                totalSize += ChunkHeaderSize + eventsDataSize;
            }
            if (sqEventsDataSize) {
                totalSize += ChunkHeaderSize + sqEventsDataSize;
            }
            if (durationDataSize) {
                totalSize += ChunkHeaderSize + durationDataSize;
            }
            if (inputChannelDataSize) {
                totalSize += ChunkHeaderSize + inputChannelDataSize;
            }
            if (garbageCollectionDataSize) {
                totalSize += ChunkHeaderSize + garbageCollectionDataSize;
            }

            totalSize += webrtcBinaryStats.size();
            const dataBuffer = new ArrayBuffer(totalSize);
            const dataBufferView = new DataView(dataBuffer);
            let offset = 0;
            new Uint8Array(dataBuffer).set(headerBufferBytes);
            offset += headerBufferBytes.length;
            writeChunkHeader(dataBufferView, offset, "BPRF", 1, 0, 0);
            offset += ChunkHeaderSize;
            if (perfDataSize) {
                writeChunkHeader(dataBufferView, offset, "PERF", 2, perfs.length, PerfEntrySize);
                offset += ChunkHeaderSize;
                writePerf(dataBufferView, offset);
                offset += perfDataSize;
            }
            if (eventsDataSize) {
                writeChunkHeader(
                    dataBufferView,
                    offset,
                    "EVNT",
                    1,
                    clientEvents.length,
                    EventEntrySize
                );
                offset += ChunkHeaderSize;
                writeClientEvents(dataBufferView, offset);
                offset += eventsDataSize;
            }
            if (sqEventsDataSize) {
                writeChunkHeader(
                    dataBufferView,
                    offset,
                    "SQEV",
                    1,
                    streamingQuality.length,
                    StreamingQualityEntrySize
                );

                offset += ChunkHeaderSize;
                writeSqScore(dataBufferView, offset);
                offset += sqEventsDataSize;
            }
            if (durationDataSize) {
                writeChunkHeader(
                    dataBufferView,
                    offset,
                    "MTBD",
                    1,
                    mtbDurations.length,
                    MtbDurationSize
                );
                offset += ChunkHeaderSize;
                writeBlockedDurations(dataBufferView, offset);
                offset += durationDataSize;
            }
            if (inputChannelDataSize) {
                writeChunkHeader(
                    dataBufferView,
                    offset,
                    "INPT",
                    1,
                    inputChannelStats.length,
                    InputChannelEntrySize
                );
                offset += ChunkHeaderSize;
                writeInputChannelStats(dataBufferView, offset);
                offset += inputChannelDataSize;
            }
            if (garbageCollectionDataSize) {
                writeChunkHeader(
                    dataBufferView,
                    offset,
                    "GRBG",
                    1,
                    garbageCollectionStats.length,
                    GarbageCollectionEntrySize
                );
                offset += ChunkHeaderSize;
                writeGarbageCollectionStats(dataBufferView, offset);
                offset += garbageCollectionDataSize;
            }

            let buffer = new Uint8Array(dataBuffer);
            webrtcBinaryStats.write(buffer, offset);
            webSocket?.send({
                stats: dataBuffer,
                ackid: serverSupportsAck ? nextAckIdToSendForStats : undefined
            });
        } catch (err) {
            workerException(
                "Exception in perf/stats upload. Error : " + err.message + " stack: " + err.stack
            );
        }
    }
    clearCachedData();
}

function wsMessageHandler(data: WebSocketMsg) {
    if (!serverSupportsAck && data.ackid !== undefined) {
        serverSupportsAck = true;
    }
    const response: WorkerResponse = { wsMessage: data };
    ctx.postMessage(response);
}

function wsCloseHandler(data: WebSocketClose) {
    const response: WorkerResponse = { wsClose: data };
    ctx.postMessage(response);
}

function wsOpenHandler() {
    const response: WorkerResponse = { wsOpen: true };
    ctx.postMessage(response);
}

function wsOpeningHandler() {
    const response: WorkerResponse = { wsOpening: true };
    ctx.postMessage(response);
}

function createWebSocket(maxReceivedAckId: number, reconnect?: boolean) {
    webSocket = new WebSocketImpl(sessionId, {
        wsHandler: {
            messageHandler: wsMessageHandler,
            openHandler: wsOpenHandler,
            closeHandler: wsCloseHandler,
            openingHandler: wsOpeningHandler
        },
        logCallback: {
            info: workerLogger,
            exception: workerException
        }
    });
    webSocket.initialize(url, maxReceivedAckId, serverSupportsAck, reconnect);
    wsOpeningHandler();
}

ctx.onmessage = function (message: MessageEvent) {
    try {
        const data: WorkerMessage = message.data;
        if (data.initMessage) {
            sessionId = data.initMessage.sessionId;
            workerLogger("{6ccab8d}");
        } else if (data.perf) {
            perfs.push(data.perf);
        } else if (data.clientEvent) {
            clientEvents.push(data.clientEvent);
        } else if (data.startStats) {
            statsHeaderObj = data.startStats.statsHeader;
            const response: WorkerResponse = {
                statsStarted: true
            };
            // keep uploading performance data every 5 seconds.
            uploadIntervalId = self.setInterval(() => uploadData(), UploadInterval);
            ctx.postMessage(response);
            workerLogger("{5209d98}");
        } else if (data.stopStats) {
            self.clearInterval(uploadIntervalId);
            uploadData();
            clearCachedData();
            workerLogger("{b58b6ad}");
        } else if (data.webrtcStats) {
            if (data.ackid) {
                nextAckIdToSendForStats = data.ackid;
            }
            if (data.webrtcStats.stats) {
                webrtcBinaryStats.addReport(data.webrtcStats);
            }
        } else if (data.sq) {
            streamingQuality.push(data.sq);
        } else if (data.startWebSocket) {
            url = data.startWebSocket.signInURL;
            serverSupportsAck = data.startWebSocket.serverSupportsAck;
            createWebSocket(data.startWebSocket.maxReceivedAckId, data.startWebSocket.reconnect);
        } else if (data.stopWebSocket) {
            webSocket?.uninitialize();
            serverSupportsAck = false;
        } else if (data.send) {
            webSocket?.send(data.send);
        } else if (data.duration) {
            mtbDurations.push(data.duration);
        } else if (data.inputChannelStats) {
            inputChannelStats.push(data.inputChannelStats);
        } else if (data.garbageCollectionStats) {
            garbageCollectionStats.push(data.garbageCollectionStats);
        }
    } catch (exp) {
        workerException("Worker onmessage exception: " + exp);
    }
};
