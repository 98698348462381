/**
 * Represents TEXT_MAP encoding defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/inject-extract/#required-carriers
 */
export const FORMAT_TEXT_MAP = "text_map";

/**
 * Represents carrier used for inter-process communication to "carry" tracing state across process boundaries,
 * as defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/inject-extract/#the-basic-approach-inject-extract-and-carriers
 */
export declare interface TracingCarrier {
    [key: string]: string;
}

/**
 * A named, timed operation representing a piece of workflow in a distributed system,
 * as defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/spans/#what-is-a-span
 */
export declare interface Span {
    context(): SpanContext;
    setTag(key: string, value: any): this;
    addTags(keyValueMap: { [key: string]: any }): this;
    finish(finishTime?: number): void;
}

/**
 * Carries span data across process boundaries, as defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/spans/#spancontext
 */
export declare interface SpanContext {
    toTraceId(): string;
    toSpanId(): string;
}

/**
 * Comprises optional parameters to pass when creating a span from tracer implementation.
 * @sa Tracer.startSpan
 */
declare interface SpanOptions {
    childOf?: Span | SpanContext;
    tags?: { [key: string]: any };
    startTime?: number;
}

/**
 * Actual implementation responsible for recording spans and publishing their data,
 * as defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/tracers/#tracer-interface
 */
export declare interface Tracer {
    inject(spanContext: SpanContext | Span, format: string, carrier: any): void;
    extract(format: string, carrier: any): SpanContext;
    startSpan(name: string, options?: SpanOptions): Span;
}

export declare interface createTracerCallback {
    (options: any): Tracer;
}

/**
 * Formalizes activation and deactivation of a span, as defined in OpenTracing standard.
 * @sa https://opentracing.io/docs/overview/scopes-and-threading/
 */
export interface Scope {
    context(): SpanContext | undefined;
    setTag(key: string, value: string): void;
    addTags(tags: { [key: string]: any }): void;
    finish(): void;
}

export interface CreateTracingScopeType {
    (operationName: string, carrier?: TracingCarrier): Scope;
}

export interface InjectTracingScopeType {
    (scope: Scope): TracingCarrier;
}

export declare interface TracingInfo {
    /** This function returns a tracing scope that manages the newly created span.*/
    createTracingScopeCallback: CreateTracingScopeType;
    /** This function returns a carrier that allows trace propagation across processes.*/
    injectTracingScopeCallback: InjectTracingScopeType;
}
