import { ConfigureRtcUtilsSettings, toBool } from "./dependencies";
import { Log } from "./logger";
import { OverrideConfigTypeEnum } from "./gstelemetryinterfaces";
/* Note: For public client usage, please skip to RemoteConfigData interface definition. */

const LOGTAG = "gridserversettings";

interface _GridServerSettings {
    commonConfig: CommonConfig; // parameters for communication with the PM
    remoteOverrideInfo: RemoteOverrideInfo; // remote override config details for telemetry
    loggingEnabled: boolean; // Disable log events to client.
    consoleLoggingEnabled: boolean; // Enable log events to UA console. Requires loggingEnabled.
    webSocketSignaling: boolean; // Use WebSockets for signaling communication.
    webRtcStreamer: boolean; // Set GSStreamer metadata to WebRTC, no matter the platform name
    hdr?: boolean; // Whether or not to request an HDR stream
}

declare interface PmCommunication {
    httpBackOffDelay?: number;
    httpRetryCount?: number;
    httpDataReceiveTimeout?: number;
    httpConnectionTimeout?: number;
    pollingIntervalMin?: number;
    pollingIntervalMax?: number;
    pollingIntervalStep?: number;
    pollingQueueSizePerStep?: number;
}

export declare interface CommonConfig {
    pmCommunication?: PmCommunication;
}

/* Note: This variable is not meant to be used by public Clients.
 */
export let GridServerSettings: _GridServerSettings = {
    commonConfig: {},
    remoteOverrideInfo: {
        type: OverrideConfigTypeEnum.UNKNOWN,
        version: ""
    },
    loggingEnabled: true,
    consoleLoggingEnabled: false,
    webSocketSignaling: true,
    webRtcStreamer: false
};

export declare interface RemoteOverrideInfo {
    type: OverrideConfigTypeEnum;
    version: string;
}

/** The GFN specific remote config data fetched from RemoteConfig server */
declare interface RemoteConfigData {
    /**  common fields for all clients, contains PM communication params etc*/
    common?: string;
    /**  remote config file version */
    version?: string;
}

/** Interfaces for GXT override config data fetched from GXT server */
declare interface GxtConfigDebugInfo {
    revision: string;
}

declare interface GxtConfigParams {
    name: string;
    value: Object;
    version: string;
}
export declare interface GxtRemoteConfigData {
    debugInfo: GxtConfigDebugInfo;
    params: GxtConfigParams[];
}

/** Free form GridServer library configuration parameter.
    To be used only by GFN client and internal dev clients. */
export declare interface GridServerConfigData {
    remoteConfigData?: RemoteConfigData;
    /* Will be set to a JSON formatted string when the override config is fetched from GXT. Should be mutually exclusive to remoteConfigData*/
    gxtOverrideData?: string;
    /* Free form string containing internal override configurations */
    overrideData?: string;
}

/**
 * This function allows GFN/dev clients to configure the GridServer library to toggle various features from override tools or from remote config server.
 * This should be invoked before initializing other components of GridServer library.
 *
 * The GridServerConfigData's remoteConfigData and gxtOverrideData are all GFN specific settings, exposed as a string to prevent dependency on
 * GFN client from configuring values to it. The json data received from the RemoteConfig/GxtConfig server is stringified as it is and passed
 * into the library without interpretation.
 *
 * The GridServerConfigData.overrideData is a string of feature toggle/options separated by '&' character.
 * The options exposed exposed are:
 *
 *      mode=lean|dev|default           -- Lean mode of GridServer put the library into minimal functionality mode.
 *                                         Most features will be disabled and users configure enabling of selected features.
 *                                         Dev mode of GridServer enables dev internal tools for the library (by default enabled for Lean mode)
 *      log=enable|disable              -- Enable log generation from the library.
 *      console=enable|disable          -- Enable console log from the library if log generation is enabled.
 *      hdr=on|off                      -- Whether or not to request an HDR stream.
 *
 *  Ex: In order to put the ragnarok in Lean mode yet support logging, the settings string would be
 *      "mode=lean&log=enable"
 *  Instead of enable/disable,  on/off or 0/1 can be used as well.
 */

export function ConfigureGridServerSettings(data: GridServerConfigData) {
    Log.i("{27b0dc4}", "{539b3db}", JSON.stringify(data));
    ConfigureRtcUtilsSettings(data);
    // Note: This function is at app start, let the exception bubble up and fail.
    //       The remote config data can be handled within try catch as it will be part of prod.
    if (data.gxtOverrideData) {
        const configData = <GxtRemoteConfigData>JSON.parse(data.gxtOverrideData);
        // Parse the 'pmCommunication' config block
        if (configData.params) {
            for (const element of configData.params) {
                if (element.name === "pmCommunication") {
                    GridServerSettings.commonConfig.pmCommunication = <PmCommunication>(
                        element.value
                    );
                    // Set the override config details for telemetry, only when we have some override settings
                    GridServerSettings.remoteOverrideInfo = {
                        type: OverrideConfigTypeEnum.GXT,
                        version: element.version
                    };
                }
            }
        }
    } else if (data.remoteConfigData) {
        if (data.remoteConfigData.common) {
            GridServerSettings.commonConfig = <CommonConfig>(
                JSON.parse(data.remoteConfigData.common)
            );
            // Set the override config details for telemetry, only when we have some override settings
            GridServerSettings.remoteOverrideInfo = {
                type: OverrideConfigTypeEnum.RCONFIG,
                version: data.remoteConfigData.version ?? ""
            };
        }
    }

    if (data.overrideData) {
        /* The below code logic is to disable all features when user has selected lean mode.
          However each feature can be individually controlled
          so that performance can be analyzed by turning on one feature at a time. */

        const settings = data.overrideData.toLowerCase();

        /* Using URLSearchParams is simpler even when UI clients give a text option in Override tools to configure ragnarok.
           It splits on '&' and '=', which is what we would need when configured through UI Clients. */
        const settingsMap = new URLSearchParams(settings);
        if (settingsMap.get("mode") === "lean") {
            GridServerSettings.loggingEnabled = false;
        }
        const getSettingBool = (key: string): boolean | undefined => {
            const value: any = settingsMap.get(key);
            return toBool(value);
        };

        GridServerSettings.loggingEnabled =
            getSettingBool("log") ?? GridServerSettings.loggingEnabled;
        GridServerSettings.consoleLoggingEnabled =
            GridServerSettings.loggingEnabled &&
            (getSettingBool("console") ?? GridServerSettings.consoleLoggingEnabled);

        GridServerSettings.webSocketSignaling =
            getSettingBool("websocketsignaling") ?? GridServerSettings.webSocketSignaling;
        GridServerSettings.webRtcStreamer =
            getSettingBool("webrtcstreamer") ?? GridServerSettings.webRtcStreamer;
        GridServerSettings.hdr = getSettingBool("hdr") ?? GridServerSettings.hdr;
    }
}
