/* Note: For public client usage, please skip to RemoteConfigData interface definition. */
import {
    ConfigureGridServerSettings,
    CommonConfig,
    AppLaunchMode,
    RemoteOverrideInfo,
    OverrideConfigTypeEnum,
    MetaData,
    GxtRemoteConfigData,
    ClientType,
    Log,
    DeviceOS,
    DeviceType,
    ClientStreamer
} from "../dependencies";

const LOGTAG = "ragnaroksettings";

interface _RagnarokSettings {
    ragnarokConfig: RagnarokConfig; // Ragnarok-specific remote config
    clientConfigOverride: string; // nvscClientConfig override string
    remoteOverrideInfo: RemoteOverrideInfo; // remote override config details for telemetry
    leanMode: boolean; // Run ragnarok in limited mode. All the below functionality will be disabled.
    loggingEnabled: boolean; // Disable log events to client.
    consoleLoggingEnabled: boolean; // Enable log events to UA console. Requires loggingEnabled.
    gamepadEnabled: boolean; // Full Gamepad functionality.
    webrtcStatsEnabled: boolean; // ChromeWebRTC  getStats call.
    statsUploadEnabled: boolean; // Upload client metrics form worker thread.
    micEnabled?: boolean; // Mic support during streaming.
    mouseFilter?: boolean; // Filter mouse events.
    unadjustedMovement?: boolean; // Force unadjusted movement on or off.
    maxBitrate: number; // Max bitrate to request. 0 means default.
    resWidth: number; // Resolution width
    resHeight: number; // Resolution height
    gamepadRaf: boolean; // Use requestAnimationFrame() for gamepad update tick.
    gamepadPollInterval?: number; // Interval between non-rAF ticks for gamepad updates.
    advancedGestures: boolean; // Enable support for additional gestures, such as scroll, drag, and hold.
    forceTouchCapable: boolean; // Force detection of the device as touch-capable.
    touchWarp: boolean; // Warp touches near the top and bottom of the screen to workaround WebKit touch dead zones.
    storeTouch: boolean; // Store 30 seconds worth of touches for logging whenever input stops.
    storeTouchGesture: boolean; // Whether to use a gesture to trigger logging of stored touches.
    latencyTest: boolean; // Show input indicator for latency test.
    gamepadTesterEnabled: boolean; // Show the Gamepad Tester page from the Query Parameters
    deviceOs?: DeviceOS; // Device OS for session request. See gridserver-core/interfaces.ts for valid values
    deviceType?: DeviceType; // Device type for session request. See gridserver-core/interfaces.ts for valid values
    deviceModel?: string; // Device model for session request
    clientStreamer?: ClientStreamer; // Client streamer for session request. See gridserver-core/interfaces.ts for valid values
    clientId?: string; // Client ID for session request. Refers to Client ID registered in LCARS/Chroma for this client.
    clientPlatformName?: string; // Client platform name for session request. See gridserver-core/interfaces.ts for valid values
    clientType?: ClientType; // Client type for session request. See gridserver-core/interfaces.ts for valid values
    appLaunchMode?: number; // App launch mode for session request. See gridserver-core/interfaces.ts for valid values
    rsdmm: boolean; // Use Right-stick Dynamic Mouse Mode.  Default on.
    rsdmmThumbstickToggle: boolean; // Allow toggling RSDMM with double-click of right thumbstick
    allowSourceReset: boolean; // Allow iOS 15.1 blank video on background WAR
    allowAudioReset: boolean; // Allow iOS 15 audio latency WAR
    allowAutoplayChange: boolean; // Allow iOS 15 blank video on launch WAR
    allowEnableMic: boolean; // Allow iOS 15.4 enable mic WAR
    allowMutingVideo: boolean; // Allow iOS 15.4 mute video element WAR
    bitsPerPixel?: number; // Coefficient for 1080p and lower bitrate calculation
    allowSendingTelemetry: boolean; // In test client stream test we disable sending telemetry to stage server.
    fps: number; // Stream FPS to request.
    isInternalUser?: boolean; // Whether user is internal user.  Enables all dev feature toggles for internal users.
    bitsPerPixel1440p?: number; // Coefficient for 1440p and higher bitrate calculation
    useUITelemetry: boolean; // Whether to send telemetry events defined in GFN-UI-Client Schema
    useSplitSchema: boolean; // Whether to send telemetry events defined in Ragnarok Schema
    sessionMetaData?: MetaData; // Metadata to send in session request
    is1440pSupported?: boolean; // Whether or not ragnarok should claim 1440p is supported
    stutterIndicator?: boolean; // Whether or not to allow toggling the stutter indicators
    combineStreams?: boolean; // Whether or not to combine separate streams into one media stream
    allowHaptics?: boolean; // Whether to allow controller haptics.
    enableAv1Support?: boolean; // Whether to allow AV1 support
    codecList?: string[]; // list of supported codecs in descending order according to priority
    hdr?: boolean; // Whether or not to request an HDR stream
}

declare interface RagnarokConfig {
    mouseCoalesceInterval?: number;
    connectivityCheckTimeout?: number;
    signInTimeout?: number;
    offlineErrorsStreaming?: string[];
    offlineErrorsSessionSetup?: string[];
    sleepErrorsStreaming?: string[];
    sleepErrorsSessionSetup?: string[];
    nvscClientConfigFields?: string[];
    gamepadPollInterval?: number;
    getStatsInterval?: number;
    getDeprecatedStatsInterval?: number;
    enableCpm?: boolean;
    bitsPerPixel?: number;
    useSplitSchema?: boolean; // If this is false, useUITelemetry has no effect
    useUITelemetry?: boolean; // false: stop sending events as per the GFN-Client event's schema
    userTerminatedMicRecorderThreshold?: number; // See ragnarok-core/gridapp.ts visibilitychange function for definition
    bitsPerPixel1440p?: number;
    combineStreams?: boolean;
    allowHaptics?: boolean; // Permit controller haptics; allowed by default.
    codecList?: string[]; // list of supported codecs in descending order according to priority
    /** Bitwise setting to enable Av1 for specific streaming resolutions and frame rates.  See EnableAv1Config in devicecapabilities.ts.  Note that enableAv1Support takes precedence over this field if specified.*/
    enableAv1ByResolutionAndFps?: number;
    enableAv1Support?: boolean; // Allow AV1 for all resolutions.
    terminateUnhandledException?: boolean; // Whether to terminate stream when encountering unhandled exception; terminates by default
    sendInsetValueUpdateEvents?: boolean; // Whether to send safe zone InsetValueUpdate client metric events; allowed by default.
    sendNonEssentialMetricEvents?: boolean; // Whether to send metric events that are considered as non-essential.
}

/* Note: This variable is not meant to be used by public Clients.
 */
export let RagnarokSettings: _RagnarokSettings = {
    ragnarokConfig: {},
    clientConfigOverride: "",
    remoteOverrideInfo: {
        type: OverrideConfigTypeEnum.UNKNOWN,
        version: ""
    },
    leanMode: false,
    loggingEnabled: true,
    consoleLoggingEnabled: false,
    gamepadEnabled: true,
    webrtcStatsEnabled: true,
    statsUploadEnabled: true,
    maxBitrate: 0,
    resWidth: 0,
    resHeight: 0,
    gamepadRaf: false,
    advancedGestures: true,
    forceTouchCapable: false,
    touchWarp: true,
    storeTouch: false,
    storeTouchGesture: false,
    latencyTest: false,
    gamepadTesterEnabled: false,
    rsdmm: true,
    rsdmmThumbstickToggle: false,
    allowSourceReset: true,
    allowAudioReset: true,
    allowAutoplayChange: true,
    allowEnableMic: true,
    allowMutingVideo: true,
    allowSendingTelemetry: true,
    fps: 0,
    useUITelemetry: false,
    useSplitSchema: true
};

/** The GFN specific remote config data fetched from RemoteConfig server */
export declare interface RemoteConfigData {
    /**  common fields for all clients, contains PM communication params etc*/
    common?: string;
    /**  ragnarok specific fields */
    ragnarok?: string;
    /**  remote config file version */
    version?: string;
}

/** Free form Ragnarok library configuration parameter.
    To be used only by GFN client and internal dev clients. */
export declare interface RagnarokConfigData {
    remoteConfigData?: RemoteConfigData;
    /* Will be set to a JSON formatted string when the override config is fetched from GXT. Should be mutually exclusive to remoteConfigData*/
    gxtOverrideData?: string;
    /* Free form string containing internal override configurations */
    overrideData?: string;
    clientConfigOverride?: string;
    isInternalUser?: boolean;
}

/** Returns the boolean representation of the associated string */
function toBool(value: string): boolean | undefined {
    if (value) {
        switch (value.toLowerCase()) {
            case "enable":
            case "on":
            case "1":
            case "true":
                return true;
            case "disable":
            case "off":
            case "0":
            case "false":
                return false;
        }
    }
    return undefined;
}

/** Returns the integer representation of the associated string */
function toInt(value: string): number | undefined {
    if (value) {
        const num = Number.parseInt(value);
        if (!Number.isNaN(num)) {
            return num;
        }
    }
    return undefined;
}

/** Returns the numerical representation of the associated string */
function toNum(value: string): number | undefined {
    if (value) {
        const num = Number.parseFloat(value);
        if (!Number.isNaN(num)) {
            return num;
        }
    }
    return undefined;
}

/** Returns the array representation of the associated string */
function toArray(value: string): string[] | undefined {
    if (value) {
        return value.split(",");
    }
    return undefined;
}

/**
 * This function allows GFN/dev clients to configure the ragnarok library to toggle various features from override tools or from remote config server.
 * This should be invoked before initializing other components of ragnarok library.
 *
 * The RagnarokConfigData's remoteConfigData and gxtOverrideData are all GFN specific settings, exposed as a string to prevent dependency on
 * GFN client from configuring values to it. The json data received from the RemoteConfig/GxtConfig server is stringified as it is and passed
 * into the library without interpretation.
 *
 *
 * The RagnarokConfigData.overrideData is a string of feature toggle/options separated by '&' character.
 * The options exposed exposed are:
 *
 *      mode=lean|dev|default           -- Lean mode of ragnarok put the library into minimal functionality mode.
 *                                         Most features will be disabled and users configure enabling of selected features.
 *                                         Dev mode of ragnarok enables dev internal tools for the library (by default enabled for Lean mode)
 *      log=enable|disable              -- Enable log generation from the library.
 *      console=enable|disable          -- Enable console log from the library if log generation is enabled.
 *      gamepad=enable|disable          -- Enable/disable gamepad functionality.
 *      webrtcstats=enable|disable      -- Enable/disable calling GetStats of WebRTC library.
 *                                         Note: This is equivalent of Ctrl+Alt+F5 toggle.
 *      statsupload=enable|disable      -- Enable/disable uploading of client metrics/perf metrics to server.
 *                                         Note: statsupload=disable&webrtcstats=disable is equivalent to Ctrl+Alt+F4 toggle.
 *      mic=enable|disable              -- Enable/disable client mic capture during streaming.
 *      mousefilter=enable|disable      -- Enable/disable mouse event filtering. Intended for automated testing.
 *      bitrate=<number>|0              -- Sets the max bitrate. 0 will use the default. If value is above 100 treat as Kbps else Mbps.
 *      advancedgestures=enable|disable -- Enable/disable drag, mouse scroll, and video pan/zoom gestures.
 *      touch=enable|disable            -- Enable/disable sending of touch events.
 *                                         Note: Enabling this disables touch-as-mouse.
 *      latency=enable|disable          -- Enable/disable input indicator. Intended for input latency testing.
 *      audioreset=enable|disable       -- Enable/disable iOS 15 WAR for high audio latency.
 *      sourcereset=enable|disable      -- Enable/disable iOS 15.1 WAR for blank video on app background.
 *      autoplaychange=enable|disable   -- Enable/disable iOS 15 WAR for blank video on game launch
 *      ioswars=enable|disable          -- Enable/disable all iOS 15 WARs.
 *      bitsperpixel=<number>           -- 1080p and lower bits per pixel coefficient in max bitrate calculation.
 *      client id=<string>              -- Client ID for session request. Refers to Client ID registered in LCARS/Chroma for this client.
 *      fps=<number>                    -- Stream FPS to request. 0 will use normal requested.
 *      resolution=<number>x<number>    -- Resolution width and height to request, e.g. 1920x1080.
 *      bitsperpixel1440p=<number>      -- 1440p and up bits per pixel coefficient in max bitrate calculation.
 *      ussesplitschema=enable|disable  -- Enable/disable using split schema to generate telemetry.
 *      usseuitelemetry=enable|disable  -- Enable/disable generating telemetry for the UI client schema.
 *      meta=<string>~<string>          -- Metadata to send in session set up request, of the form key~value.
 *      1440p=true|false                -- Whether or not ragnarok should claim 1440p is supported.
 *      stutter=enable|disable          -- Enable/disable toggling stutter indicators.
 *      combinestreams=enable|disable   -- Whether or not ragnarok should combine separate streams
 *      av1=enable|disable              -- Enable/disable AV1 support
 *      codeclist=<codec>,<codec>,...   -- Comma-separated list of supported codecs in descending order according to priority, e.g "AV1,H264"
 *      internaluser=true|false         -- Whether user is internal user
 *      hdr=on|off                      -- Whether or not to request an HDR stream.
 *
 *  Ex: In order to put the ragnarok in Lean mode yet support logging, the settings string would be
 *      "mode=lean&log=enable"
 *  Instead of enable/disable,  on/off or 0/1 can be used as well.
 */

export function ConfigureRagnarokSettings(data: RagnarokConfigData) {
    Log.i("{fbd6ce8}", "{4acfb3a}", JSON.stringify(data));
    if (data.clientConfigOverride) {
        RagnarokSettings.clientConfigOverride = data.clientConfigOverride;
    }
    // Note: This function is at app start, let the exception bubble up and fail.
    //       The remote config data can be handled within try catch as it will be part of prod.
    if (data.gxtOverrideData) {
        const configData = <GxtRemoteConfigData>JSON.parse(data.gxtOverrideData);
        // Parse the 'ragnarok' config block
        if (configData.params) {
            for (const element of configData.params) {
                if (element.name === "ragnarok") {
                    RagnarokSettings.ragnarokConfig = <RagnarokConfig>element.value;
                    // Set the override config details for telemetry, only when we have some override settings
                    RagnarokSettings.remoteOverrideInfo = {
                        type: OverrideConfigTypeEnum.GXT,
                        version: element.version
                    };
                }
            }
        }
    } else if (data.remoteConfigData) {
        if (data.remoteConfigData.ragnarok) {
            RagnarokSettings.ragnarokConfig = <RagnarokConfig>(
                JSON.parse(data.remoteConfigData.ragnarok)
            );
            // Set the override config details for telemetry, only when we have some override settings
            RagnarokSettings.remoteOverrideInfo = {
                type: OverrideConfigTypeEnum.RCONFIG,
                version: data.remoteConfigData.version ?? ""
            };
        }
    }

    RagnarokSettings.useSplitSchema =
        RagnarokSettings.ragnarokConfig.useSplitSchema ?? RagnarokSettings.useSplitSchema;

    // Stop sending GFNPC Client schema telemetry only if the new Ragnarok Schema telemetry is
    // enabled and the GFNPC Client schema telemetry is explicitly disabled in override config.
    // We intend to keep sending the older events until all the dashboards and visualizations are
    // migrated to using the new events' schema.
    //
    // If useSplitSchema is false, useUITelemetry will always be true
    // irresepctive of the 'useUITelemetry' override config setting.
    //
    // Both these settings can be overriden further using query parameters as well. See below
    if (
        RagnarokSettings.useSplitSchema &&
        RagnarokSettings.ragnarokConfig.useUITelemetry == false
    ) {
        RagnarokSettings.useUITelemetry = false;
    }
    if (data.isInternalUser) {
        RagnarokSettings.isInternalUser = data.isInternalUser;
    }
    if (data.overrideData) {
        /* Using URLSearchParams is simpler even when UI clients give a text option in Override tools to configure ragnarok.
           It splits on '&' and '=', which is what we would need when configured through UI Clients. */
        const casedSettingsMap = new URLSearchParams(data.overrideData);
        let settingsMap = new URLSearchParams();
        for (const [key, value] of casedSettingsMap.entries()) {
            settingsMap.append(key.toLowerCase(), value);
        }
        /* The below code logic is to disable all features when user has selected lean mode.
          However each feature can be individually controlled
          so that performance can be analyzed by turning on one feature at a time. */
        if (settingsMap.get("mode")?.toLowerCase() === "lean") {
            RagnarokSettings.leanMode = true;
            RagnarokSettings.loggingEnabled = false;
            RagnarokSettings.gamepadEnabled = false;
            RagnarokSettings.webrtcStatsEnabled = false;
            RagnarokSettings.statsUploadEnabled = false;
            RagnarokSettings.micEnabled = false;
            // Enable internal user features
            RagnarokSettings.stutterIndicator = true;
            RagnarokSettings.isInternalUser = true;
        } else if (settingsMap.get("mode")?.toLowerCase() === "dev") {
            RagnarokSettings.stutterIndicator = true;
            RagnarokSettings.isInternalUser = true;
        }
        const getSettingBool = (key: string): boolean | undefined => {
            const value: any = settingsMap.get(key);
            return toBool(value);
        };

        const getSettingInt = (key: string): number | undefined => {
            const value: any = settingsMap.get(key);
            return toInt(value);
        };

        const getSettingNum = (key: string): number | undefined => {
            const value: any = settingsMap.get(key);
            return toNum(value);
        };

        const getSettingArray = (key: string): string[] | undefined => {
            const value: any = settingsMap.get(key);
            return toArray(value);
        };

        RagnarokSettings.loggingEnabled = getSettingBool("log") ?? RagnarokSettings.loggingEnabled;
        RagnarokSettings.consoleLoggingEnabled =
            RagnarokSettings.loggingEnabled &&
            (getSettingBool("console") ?? RagnarokSettings.consoleLoggingEnabled);
        RagnarokSettings.gamepadEnabled =
            getSettingBool("gamepad") ?? RagnarokSettings.gamepadEnabled;
        RagnarokSettings.webrtcStatsEnabled =
            getSettingBool("webrtcstats") ?? RagnarokSettings.webrtcStatsEnabled;
        RagnarokSettings.statsUploadEnabled =
            getSettingBool("statsupload") ?? RagnarokSettings.statsUploadEnabled;
        RagnarokSettings.micEnabled = getSettingBool("mic") ?? RagnarokSettings.micEnabled;
        RagnarokSettings.mouseFilter =
            getSettingBool("mousefilter") ?? RagnarokSettings.mouseFilter;
        RagnarokSettings.unadjustedMovement =
            getSettingBool("unadjustedmovement") ?? RagnarokSettings.unadjustedMovement;
        RagnarokSettings.maxBitrate = getSettingInt("bitrate") ?? RagnarokSettings.maxBitrate;
        if (RagnarokSettings.maxBitrate > 0 && RagnarokSettings.maxBitrate <= 100) {
            RagnarokSettings.maxBitrate *= 1000; // Convert Mbps to Kbps.
        }
        RagnarokSettings.gamepadTesterEnabled =
            getSettingBool("gamepadtest") ?? RagnarokSettings.gamepadTesterEnabled;
        RagnarokSettings.allowSendingTelemetry =
            getSettingBool("allowtelemetry") ?? RagnarokSettings.allowSendingTelemetry;

        const requestRes = settingsMap.get("resolution");
        if (requestRes) {
            const resInfo = requestRes.toLowerCase().split("x");
            if (resInfo.length == 2) {
                const width = parseInt(resInfo[0]);
                const height = parseInt(resInfo[1]);
                if (width && height) {
                    // not doing anymore check, values are passed by dev/qa users.
                    RagnarokSettings.resWidth = width;
                    RagnarokSettings.resHeight = height;
                } else {
                    Log.e("{fbd6ce8}", "{c87835d}", requestRes);
                }
            } else {
                Log.e("{fbd6ce8}", "{c87835d}", requestRes);
            }
        }
        RagnarokSettings.fps = getSettingInt("fps") ?? RagnarokSettings.fps;

        RagnarokSettings.gamepadRaf = getSettingBool("gamepadraf") ?? RagnarokSettings.gamepadRaf;
        RagnarokSettings.gamepadPollInterval =
            getSettingInt("gamepadpoll") ?? RagnarokSettings.gamepadPollInterval;
        RagnarokSettings.advancedGestures =
            getSettingBool("advancedgestures") ?? RagnarokSettings.advancedGestures;
        RagnarokSettings.forceTouchCapable =
            getSettingBool("forcetouchdevice") ?? RagnarokSettings.forceTouchCapable;
        let touchSetting = getSettingBool("touch");
        if (touchSetting !== undefined) {
            RagnarokSettings.appLaunchMode = touchSetting
                ? AppLaunchMode.TouchFriendly
                : AppLaunchMode.Default;
            RagnarokSettings.forceTouchCapable = touchSetting;
        }

        RagnarokSettings.storeTouch = getSettingBool("storetouch") ?? RagnarokSettings.storeTouch;
        RagnarokSettings.storeTouchGesture =
            getSettingBool("storetouchgesture") ?? RagnarokSettings.storeTouchGesture;
        RagnarokSettings.touchWarp = getSettingBool("touchwarp") ?? RagnarokSettings.touchWarp;
        RagnarokSettings.latencyTest = getSettingBool("latency") ?? RagnarokSettings.latencyTest;

        RagnarokSettings.deviceOs = (settingsMap.get("deviceos") ??
            RagnarokSettings.deviceOs) as DeviceOS;
        RagnarokSettings.deviceType = (settingsMap.get("devicetype") ??
            RagnarokSettings.deviceType) as DeviceType;
        RagnarokSettings.clientStreamer = (settingsMap.get("clientstreamer") ??
            RagnarokSettings.clientStreamer) as ClientStreamer;
        RagnarokSettings.clientId = settingsMap.get("clientid") ?? RagnarokSettings.clientId;
        RagnarokSettings.clientPlatformName =
            settingsMap.get("clientplatformname") ?? RagnarokSettings.clientPlatformName;
        RagnarokSettings.clientType = (settingsMap.get("clienttype") ??
            RagnarokSettings.clientType) as ClientType;
        RagnarokSettings.appLaunchMode =
            getSettingInt("applaunchmode") ?? RagnarokSettings.appLaunchMode;

        RagnarokSettings.rsdmm = getSettingBool("rsdmm") ?? RagnarokSettings.rsdmm;
        RagnarokSettings.rsdmmThumbstickToggle =
            getSettingBool("rsdmmtoggle") ?? RagnarokSettings.rsdmmThumbstickToggle;

        if (!(getSettingBool("ioswars") ?? true)) {
            RagnarokSettings.allowAudioReset = false;
            RagnarokSettings.allowAutoplayChange = false;
            RagnarokSettings.allowSourceReset = false;
            RagnarokSettings.allowEnableMic = false;
            RagnarokSettings.allowMutingVideo = false;
        }
        RagnarokSettings.allowAudioReset =
            getSettingBool("audioreset") ?? RagnarokSettings.allowAudioReset;
        RagnarokSettings.allowSourceReset =
            getSettingBool("sourcereset") ?? RagnarokSettings.allowSourceReset;
        RagnarokSettings.allowAutoplayChange =
            getSettingBool("autoplaychange") ?? RagnarokSettings.allowAutoplayChange;

        RagnarokSettings.bitsPerPixel =
            getSettingNum("bitsperpixel") ?? RagnarokSettings.bitsPerPixel;
        RagnarokSettings.bitsPerPixel1440p =
            getSettingNum("bitsperpixel1440p") ?? RagnarokSettings.bitsPerPixel1440p;

        RagnarokSettings.useSplitSchema =
            getSettingBool("usesplitschema") ?? RagnarokSettings.useSplitSchema;
        RagnarokSettings.useUITelemetry =
            getSettingBool("useuitelemetry") ?? RagnarokSettings.useUITelemetry;

        RagnarokSettings.is1440pSupported =
            getSettingBool("1440p") ?? RagnarokSettings.is1440pSupported;

        RagnarokSettings.stutterIndicator =
            getSettingBool("stutter") ?? RagnarokSettings.stutterIndicator;

        RagnarokSettings.combineStreams =
            getSettingBool("combinestreams") ?? RagnarokSettings.combineStreams;

        if (settingsMap.has("meta")) {
            RagnarokSettings.sessionMetaData = {};
            for (const metaValue of settingsMap.getAll("meta")) {
                const metaParts = metaValue.split("~");
                if (metaParts.length === 2) {
                    RagnarokSettings.sessionMetaData[metaParts[0]] = metaParts[1];
                }
            }
        }

        RagnarokSettings.allowHaptics = getSettingBool("haptics") ?? RagnarokSettings.allowHaptics;
        RagnarokSettings.enableAv1Support =
            getSettingBool("av1") ?? RagnarokSettings.enableAv1Support;
        RagnarokSettings.codecList = getSettingArray("codeclist") ?? RagnarokSettings.codecList;
        RagnarokSettings.isInternalUser =
            getSettingBool("internaluser") ?? RagnarokSettings.isInternalUser;
        RagnarokSettings.hdr = getSettingBool("hdr") ?? RagnarokSettings.hdr;
    }
    if (!RagnarokSettings.useUITelemetry) {
        Log.i("{fbd6ce8}", "{f5e4f3d}");
    }

    /**
     * rtcUtils only consumes the local overrides and its better to keep it that way.
     * If there exists a rConfig that needs to be passed down to rtcUtils, we will add it to the
     * overrides string in the URLSearchParams format before calling ConfigureGridServerSettings.
     *
     * let overrideString = data.overrideData ?? "";
     * // Add all required flags and then assign it back to the data.overrideData
     * overrideString += "&delayBetweenHttpRetries=100";
     * data.overrideData = overrideString;
     *
     **/

    ConfigureGridServerSettings(data);
}
