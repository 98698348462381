import { GamepadDataHandler } from "../interfaces";
import { BUTTONS, TRIGGERS } from "./gamepadhandler";
import { IsXboxEdge, PlatformDetails } from "../dependencies";

// Bitmask of all buttons pressed for a mousedown/mouseup event.
// When a button is released, it's entry in the bitfield should be zeroed.
const enum BUTTONS_BITMASK {
    NONE = 0,
    PRIMARY = 1,
    SECONDARY = 2,
    AUXILIARY = 4
}

// Which button was responsible for the mousedown/mouseup event.
// Must be set for both down and up events.

const enum BUTTON_VALUE {
    MAIN = 0,
    AUXILIARY = 1,
    SECONDARY = 2
}

const BUTTON_MAP = new Map([
    [BUTTON_VALUE.MAIN, BUTTONS_BITMASK.PRIMARY],
    [BUTTON_VALUE.SECONDARY, BUTTONS_BITMASK.SECONDARY],
    [BUTTON_VALUE.AUXILIARY, BUTTONS_BITMASK.AUXILIARY]
]);

export class GamepadRSDMM implements GamepadDataHandler {
    private rsdmmTimer: number = 0;
    private rsdmmTickFunc: any;
    private rsdmmPollInterval: number = 8;
    private prevH: number = 0;
    private prevV: number = 0;
    private accumulatedH: number = 0;
    private accumulatedV: number = 0;
    private button1 = false;
    private button2 = false;
    private scale = 12;
    private eventElement: HTMLElement;
    private moveName: string = "mousemove";
    private downName: string = "mousedown";
    private upName: string = "mouseup";
    // Require near full press of triggers for action
    // Do not require complete press in case some gamepads do not have complete range
    private rtThreshold = 0.9 * TRIGGERS.RT;
    private ltThreshold = 0.9 * TRIGGERS.LT;
    private stickIndexX: number = 2;
    private stickIndexY: number = 3;
    private platformDetails: PlatformDetails;

    constructor(element: HTMLElement, platformDetails: PlatformDetails) {
        this.platformDetails = platformDetails;
        this.eventElement = element;
        this.rsdmmTickFunc = this.rsdmmTick.bind(this);
        if (IsXboxEdge(this.platformDetails)) {
            this.stickIndexX = 0;
            this.stickIndexY = 1;
        }
    }

    // Handles the resulting update of the gamepad state
    public gamepadStateUpdateHandler(
        count: number,
        index: number,
        buttons: number,
        trigger: number,
        inputaxes: readonly number[],
        ts: number,
        gamepadBitmap: number,
        name: string
    ) {
        // Axes [0,1] are left and [2,3] are right stick X,Y (-ve left, -ve up)
        const DEAD_ZONE = 0.1;
        const trimDeadZone = (axes: readonly number[]): readonly number[] => {
            const trimAxis = (index: number) => {
                return -DEAD_ZONE < axes[index] && axes[index] < DEAD_ZONE ? 0 : axes[index];
            };

            const outputAxes: number[] = [];

            outputAxes[0] = trimAxis(0);
            outputAxes[1] = trimAxis(1);
            outputAxes[2] = trimAxis(2);
            outputAxes[3] = trimAxis(3);

            return outputAxes;
        };

        const axes: readonly number[] = trimDeadZone(inputaxes);

        // Synthesize a mouse event from the right stick:
        const horiz = axes[this.stickIndexX];
        const vert = axes[this.stickIndexY];

        // Reduce the deltas of events based on distance from center.
        const eventScale =
            // Axes^2 will range from 0..1
            // Hypot calculation sqrt(axis+axis) would range from 0 to 1.4 if the joystick opening were square, but
            // since it is round, the diagonals limit the values to make the hypot approximately max 1.0 everywhere
            // (with some mechanical compliance), so clamp to max of 1.0
            // 3.0 * min(1.0,hypot(axes)) ranges from 0 to ~3
            //
            // We want the opposite range, so that the scaling is greatest closest to center.  So subtract above from 4.
            //

            4.0 - 3.0 * Math.min(1.0, Math.sqrt(horiz * horiz + vert * vert));

        // Set the values used by the timer to synthesize mouse movement events.
        // TODO: possibly refine scaling factor further
        this.prevH = (horiz * this.scale) / eventScale;
        this.prevV = (vert * this.scale) / eventScale;

        // Now generate any button events,
        const button1 =
            !!(buttons & (BUTTONS.A | BUTTONS.THUMBR)) ||
            (trigger & TRIGGERS.RT) > this.rtThreshold;
        const button2 = (trigger & TRIGGERS.LT) > this.ltThreshold;
        const oldButton1 = this.button1;
        const oldButton2 = this.button2;

        this.button1 = button1;
        this.button2 = button2;

        if (!button1 && oldButton1) {
            // Button 1 up event
            this.sendMouseButtonEvent(BUTTON_VALUE.MAIN, false);
        }
        if (!button2 && oldButton2) {
            // Button 2 up event
            this.sendMouseButtonEvent(BUTTON_VALUE.SECONDARY, false);
        }
        if (button1 && !oldButton1) {
            // Button 1 down event
            this.sendMouseButtonEvent(BUTTON_VALUE.MAIN, true);
        }
        if (button2 && !oldButton2) {
            // Button 2 down event
            this.sendMouseButtonEvent(BUTTON_VALUE.SECONDARY, true);
        }
    }

    private rsdmmTick(): void {
        // reset accumulated values when we cross zero so the fractional movements don't build up
        // TODO: zero when we stop as well?
        this.accumulatedH =
            Math.sign(this.accumulatedH) != Math.sign(this.prevH)
                ? this.prevH
                : this.accumulatedH + this.prevH;
        this.accumulatedV =
            Math.sign(this.accumulatedV) != Math.sign(this.prevV)
                ? this.prevV
                : this.accumulatedV + this.prevV;

        let deltaX: number = 0;
        let deltaY: number = 0;

        // accumulate fractional values until abs(value) > 1 then we send.
        if (this.accumulatedH >= 1 || this.accumulatedH <= -1) {
            deltaX = Math.trunc(this.accumulatedH);
            this.accumulatedH -= deltaX;
        }

        if (this.accumulatedV >= 1 || this.accumulatedV <= -1) {
            deltaY = Math.trunc(this.accumulatedV);
            this.accumulatedV -= deltaY;
        }

        if (deltaX != 0 || deltaY != 0) {
            const init = {
                movementX: deltaX,
                movementY: deltaY,
                isPrimary: true
            };
            let event: MouseEvent;
            if (this.moveName == "pointerrawupdate" || this.moveName == "pointermove") {
                event = new PointerEvent(this.moveName, init);
            } else event = new MouseEvent("mousemove", init);

            this.eventElement.dispatchEvent(event);
        }
    }

    private makeButtonMask(): number {
        return (
            (this.button1 ? BUTTONS_BITMASK.PRIMARY : 0) |
            (this.button2 ? BUTTONS_BITMASK.SECONDARY : 0)
        );
    }

    private sendMouseButtonEvent(changedButton: number, down: boolean) {
        let init = {
            button: changedButton,
            buttons: this.makeButtonMask()
        };
        const event = new MouseEvent(down ? this.downName : this.upName, init);
        this.eventElement.dispatchEvent(event);
    }

    public start() {
        if (this.rsdmmTimer) {
            this.stop();
        }
        this.rsdmmTimer = window.setInterval(this.rsdmmTickFunc, this.rsdmmPollInterval);
    }

    public stop() {
        if (this.rsdmmTimer) {
            clearInterval(this.rsdmmTimer);
            this.rsdmmTimer = 0;
        }
    }

    public setMoveType(moveName: string) {
        this.moveName = moveName;
    }

    public setDownUpTypes(downName: string, upName: string) {
        this.downName = downName;
        this.upName = upName;
    }

    public reset() {
        // clear buttons if held
        if (this.button1) {
            this.sendMouseButtonEvent(BUTTON_VALUE.MAIN, false);
        }
        if (this.button2) {
            this.sendMouseButtonEvent(BUTTON_VALUE.SECONDARY, false);
        }

        this.prevH = this.prevV = 0;
        this.accumulatedH = this.accumulatedV = 0;
        this.button1 = this.button2 = false;
    }

    // Unused GamepadDataHandler functions
    public gamepadBitmapUpdateHandler(gamepadBitmap: number) {}

    public finalizeGamepadData(count: number) {}

    public virtualGamepadUpdateHandler(
        buttons: number,
        trigger: number,
        index: number,
        axes: readonly number[],
        gamepadBitmap: number
    ) {}

    public connectUnsupportedGamepad(gamepad: Gamepad) {}

    public disconnectUnsupportedGamepad(index: number) {}
}
