// Helper utilities to create Workers or SharedWorkers ("Workers") from classes.
// All code to be run in a Worker should be in a class that implements DoWork.

// Call makeWorkerFromInterface() or makeSharedWorkerFromInterface() with the class
// definition object (*not* an instance!), which must implement DoWork and hence the
// doWork() function.  Said function must return an object that can be transferred
// through a Worker's or a MessagePort's postMessage() function.

// Note:
//   Logging DOES NOT WORK in a Worker (yet)!
//   The Worker will be ephemeral - close it by calling stopWorker().
//   Sometimes Workers don't work - have a fallback as well!

// Implementation note:
//  The regular expression in the class source code extraction (in addClassHierarchy)
//  is necessary because of the development client's local-compile stage, with any classes
//  that are derived from classes in another module.
//  That makes derived classes have the source form:
//     class DerivedWorker extends <moduleref>.<classname>
//  The <moduleref> string appears in the source but is only meaningful in-place,
//  and specifically is invalid when the code is running in a Worker.
//  The regexp attempts to fix that, as well as *not* breaking things when running in
//  the production mode - which doesn't have that 'namespace' issue.

import { Log } from "./utillogger";

const LOGTAG = "workerlogger";

export const enum Commands {
    CREATE,
    CREATE_SUCCESS,
    CLOSE,
    WORK,
    RESULT,
    ERROR
}

export interface CmdMsg {
    cmd: Commands;
    data: any;
}

export interface DoWork<Result> {
    doWork(): Result | Promise<Result>;
}

export type WorkerResultCallback<Result> = (workerResult: Result) => void;

function addClassHierarchy(interfaceRef: Function, blobStrings: string[]): void {
    let classes: Function[] = [];
    do {
        classes.push(interfaceRef);
        interfaceRef = Object.getPrototypeOf(interfaceRef);
    } while (interfaceRef.name != "");

    // Must reverse the hierarchy.  Classes in JavaScript are not 'hoisted', so
    // the base class definitions must precede the derived class definitions.
    // The normal hierarchy walk above would have the derived classes first.
    classes.reverse();

    for (let klaz of classes) {
        blobStrings.push(klaz.toString().replace(/(extends )[^ {]*\.([^ ]*[ {])/, "$1$2"));
    }
}

function makeWorkerUrlFromInterface<Result>(
    interfaceRef: { new (): DoWork<Result> },
    messageHandler: Function,
    errorHandler: Function,
    connectHandler?: Function
): string {
    let blobStrings: string[] = [];

    addClassHierarchy(interfaceRef, blobStrings);

    // Add the message handling function for dedicated Workers
    blobStrings.push(`${messageHandler.toString()}`);

    if (connectHandler) {
        blobStrings.push(`${connectHandler.toString()}`);
        const onconnectStr = `onconnect = (event) => { ${connectHandler.name}(${interfaceRef.name}, event, ${messageHandler.name}); };`;
        blobStrings.push(onconnectStr);

        // The connectHandler must set up a port.onmessage handler if it wants one.
    } else {
        const onmessageStr = `onmessage = (event) => { ${messageHandler.name}(${interfaceRef.name}, event); };`;
        blobStrings.push(onmessageStr);
    }

    blobStrings.push(`${errorHandler.toString()}`);
    const onerrorStr = `onerror = (event) => { ${errorHandler.name}(event); };`;
    blobStrings.push(onerrorStr);

    const workerBlob = new Blob(blobStrings, { type: "text/javascript" });
    return URL.createObjectURL(workerBlob);
}

export function makeWorkerFromInterface<Result>(
    interfaceRef: { new (): DoWork<Result> },
    callback: WorkerResultCallback<Result>,
    errorCallback: WorkerResultCallback<MessageEvent | ErrorEvent | string>
): Worker {
    // The following check:
    //  ensures that the DoWork-generating interface reference is usable
    //  prevents the Closure Compiler from 'optimizing' away the body of the class
    if (!(interfaceRef.prototype as unknown as DoWork<Result>).doWork) {
        throw new Error("Invalid Worker class provided");
    }

    const workerUrl = makeWorkerUrlFromInterface<Result>(
        interfaceRef,
        commonMessageHandler,
        commonErrorHandler
    );

    const worker = new Worker(workerUrl);
    // Immediately revoke the object URL; it's no longer needed directly.
    URL.revokeObjectURL(workerUrl);
    // Setup the worker and the result callback.
    setupWorkerAndCallback<Result>(worker, callback, errorCallback);

    return worker;
}

export interface SharedWorker extends AbstractWorker {
    port: MessagePort;
}

export function makeSharedWorkerFromInterface<Result>(
    interfaceRef: { new (): DoWork<Result> },
    callback: WorkerResultCallback<Result>,
    errorCallback: WorkerResultCallback<MessageEvent | ErrorEvent | string>
): SharedWorker {
    const w = window as any;
    if (typeof w["SharedWorker"] == "undefined") {
        throw new Error("SharedWorker not supported");
    }

    // The following check:
    //  ensures that the DoWork-generating interface reference is usable
    //  prevents the Closure Compiler from 'optimizing' away the body of the class
    if (!(interfaceRef.prototype as unknown as DoWork<Result>).doWork) {
        throw new Error("Invalid Worker class provided");
    }

    const workerUrl = makeWorkerUrlFromInterface<Result>(
        interfaceRef,
        commonMessageHandler,
        commonErrorHandler,
        connectHandler
    );

    const worker: SharedWorker = new w["SharedWorker"](workerUrl);
    // Immediately revoke the object URL; it's no longer needed directly.
    URL.revokeObjectURL(workerUrl);
    // Setup the worker and the result callback.
    setupWorkerAndCallback<Result>(worker, callback, errorCallback);

    return worker;
}

const workerCloseTimers = new Map<Worker | MessagePort, number>();

function isWorkerStopping(worker: Worker | MessagePort): boolean {
    return workerCloseTimers.has(worker);
}

function getWorkerOrPort(worker: Worker | SharedWorker) {
    return "port" in worker ? worker.port : worker;
}

export function stopWorker(worker?: Worker | SharedWorker) {
    if (!worker) {
        return;
    }

    const workerPort = getWorkerOrPort(worker);
    stopWorkerInternal(workerPort);
}

function stopWorkerInternal(workerPort: Worker | MessagePort) {
    if (isWorkerStopping(workerPort)) {
        return;
    }

    const postMessage: Function = workerPort.postMessage.bind(workerPort);

    const terminateWorker = () => {
        const timer = workerCloseTimers.get(workerPort);
        if (timer) {
            clearTimeout(timer);
            workerCloseTimers.delete(workerPort);
        }

        // Only dedicated Workers have a terminate() function.
        // For shared workers, the best we can do is close our MessagePort. The shared worker
        // may still be running, but it won't communicate with us
        if (workerPort instanceof Worker) {
            workerPort.terminate();
        } else if (workerPort instanceof MessagePort) {
            workerPort.close();
        }
    };

    // Make sure to close the worker; it seems to take a huge amount of CPU otherwise.
    postMessage({ cmd: Commands.CLOSE, data: null });
    // Use a timeout to give the Worker some time to exit cleanly.
    const timer = window.setTimeout(terminateWorker, 150);
    workerCloseTimers.set(workerPort, timer);
}

// Setup the message and error callbacks for a worker
function setupWorkerAndCallback<Result>(
    worker: Worker | SharedWorker,
    callback: WorkerResultCallback<Result>,
    errorCallback: WorkerResultCallback<MessageEvent | ErrorEvent | string>
) {
    const workerPort = getWorkerOrPort(worker);

    const postMessage: Function = workerPort.postMessage.bind(workerPort);

    let hadError = false;
    const errHandler = (error: MessageEvent | ErrorEvent | string) => {
        if (hadError) {
            return;
        }
        hadError = true;
        Log.e("{10ca8e2}", "{03baf25}", error, typeof error);

        stopWorkerInternal(workerPort);

        errorCallback(error);
    };

    workerPort.onmessage = event => {
        const cmdMsg = event.data as CmdMsg;
        let cmd: Commands = cmdMsg.cmd;
        let data: any = cmdMsg.data;

        switch (cmd) {
            case Commands.CREATE_SUCCESS:
                postMessage({ cmd: Commands.WORK, data: null });
                break;
            case Commands.RESULT: {
                callback(data);

                stopWorkerInternal(workerPort);
                break;
            }
            case Commands.ERROR:
                errHandler(data);
                break;
        }
    };

    // messageerror - received a non-deserializable Message.
    if ("onmessageerror" in workerPort) {
        // SharedWorker's MessagePort
        workerPort["onmessageerror"] = errHandler;
    }

    // Worker and SharedWorker's error handler.
    // Should include errors propagated from the Worker context.
    worker["onerror"] = errHandler;

    // Post a message to create the DoWork instance class.
    postMessage({ cmd: Commands.CREATE, data: null });
}

// The connect handler function that runs in the SharedWorker context.
// Not used for dedicated Workers.
function connectHandler(
    func: { new (): DoWork<any> },
    event: MessageEvent,
    messageHandler: Function
) {
    const thisObj = globalThis as any;
    thisObj.port = event.ports[0];
    thisObj.port.onmessage = messageHandler.bind(self, func);
}

// The message handler function that runs in the Worker or SharedWorker context.
function commonMessageHandler(func: { new (): DoWork<any> }, event: MessageEvent) {
    const thisObj = globalThis as any;

    // Create global object to hold all message handlers in this SharedWorker.
    thisObj.msgHandlers = thisObj.msgHandlers ?? {};

    const cmdMsg = event.data as CmdMsg;
    let cmd: Commands = cmdMsg.cmd;
    let data: any = cmdMsg.data;

    // TODO:  Update this for different worker global scopes (dedicated, shared, service)
    let messagePoster = "port" in thisObj ? thisObj["port"] : (self as any);
    //let messagePoster = self as any; // Dedicated Worker
    //let messagePoster = thisObj.port; // Shared Worker
    //let messagePoster = ; // Service Worker

    switch (cmd) {
        case Commands.CREATE:
            const msgHandler = new func();
            thisObj.msgHandlers[func.name] = msgHandler;

            messagePoster["postMessage"]({ cmd: Commands.CREATE_SUCCESS, data: null });
            break;
        case Commands.CLOSE:
            // Close Worker
            self?.close?.();
            break;
        case Commands.WORK: {
            const msgHandler = thisObj.msgHandlers[func.name];

            // Use Promise.resolve() to handle async as well as normal functions.
            // Normal functions return their value, async return a Promise.
            // Promise.resolve() allows using .then for results of both forms.
            Promise.resolve(msgHandler.doWork()).then(value => {
                messagePoster["postMessage"]({ cmd: Commands.RESULT, data: value });
            });
            break;
        }
        // TODO: Allow handling custom messages.
        default:
            throw new Error(`Unknown message: ${cmd}:${data}`);
    }
}

// The error handling function that runs in the Worker or SharedWorker context.
// Should capture all uncaught exceptions and errors, and report them to the main thread.
function commonErrorHandler(event: ErrorEvent) {
    const thisObj = globalThis as any;
    // TODO:  Update this for different worker global scopes (dedicated, shared, service)
    let messagePoster = "port" in thisObj ? thisObj["port"] : (self as any);
    messagePoster["postMessage"]({ cmd: Commands.ERROR, data: event });
}
