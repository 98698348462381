/**
 * @fileoverview added by tsickle
 * Generated from: gridserver-core/index-output.ts
 * @suppress {checkTypes,const,extraRequire,missingOverride,missingRequire,missingReturn,unusedPrivateMembers,uselessCode}
 */
goog.module('gridserver_core.index_output');
var module = module || { id: 'index-output.ts' };
goog.require('tslib');
const tsickle_interfaces_1 = goog.requireType("gridserver_core.output.interfaces");
const tsickle_gridserver_2 = goog.requireType("gridserver_core.output.gridserver");
const tsickle_settings_3 = goog.requireType("gridserver_core.output.settings");
const tsickle_gserrorcode_4 = goog.requireType("gridserver_core.output.gserrorcode");
const tsickle_tracing_5 = goog.requireType("gridserver_core.output.tracing");
const tsickle_dependencies_6 = goog.requireType("gridserver_core.output.dependencies");
const tsickle_gstelemetryinterfaces_7 = goog.requireType("gridserver_core.output.gstelemetryinterfaces");
const tsickle_gstelemetryhandler_8 = goog.requireType("gridserver_core.output.gstelemetryhandler");
((/** @type {?} */ (window)))["GridServer"] = ((/** @type {?} */ (window)))["GridServer"] || {};
/* Typings to be exposed to library user */
const interfaces_1 = goog.require('gridserver_core.output.interfaces');
/** @typedef {!tsickle_interfaces_1.GsInitParams} */
exports.GsInitParams; // re-export typedef
/** @typedef {!tsickle_interfaces_1.MetaData} */
exports.MetaData; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ActiveSessionInfo} */
exports.ActiveSessionInfo; // re-export typedef
/** @typedef {!tsickle_interfaces_1.StreamParam} */
exports.StreamParam; // re-export typedef
/** @typedef {!tsickle_interfaces_1.SessionParams} */
exports.SessionParams; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ActiveSessionResultEvent} */
exports.ActiveSessionResultEvent; // re-export typedef
/** @typedef {!tsickle_interfaces_1.SessionProgressUpdateEvent} */
exports.SessionProgressUpdateEvent; // re-export typedef
/** @typedef {!tsickle_interfaces_1.TelemetryHttpEvent} */
exports.TelemetryHttpEvent; // re-export typedef
/** @typedef {!tsickle_interfaces_1.TelemetryDebugEvent} */
exports.TelemetryDebugEvent; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ClientTelemetryConfig} */
exports.ClientTelemetryConfig; // re-export typedef
/** @typedef {!tsickle_interfaces_1.TelemetryEventPayload} */
exports.TelemetryEventPayload; // re-export typedef
/** @typedef {!tsickle_interfaces_1.TelemetryEventIds} */
exports.TelemetryEventIds; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ServerInfo} */
exports.ServerInfo; // re-export typedef
/** @typedef {!tsickle_interfaces_1.StreamInfo} */
exports.StreamInfo; // re-export typedef
/** @typedef {!tsickle_interfaces_1.GridSession} */
exports.GridSession; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ServerError} */
exports.ServerError; // re-export typedef
/** @typedef {!tsickle_interfaces_1.ConnectionInfo} */
exports.ConnectionInfo; // re-export typedef
/** @typedef {!tsickle_interfaces_1.MonitorSettings} */
exports.MonitorSettings; // re-export typedef
const gridserver_1 = goog.require('gridserver_core.output.gridserver');
exports.GridServer = gridserver_1.GridServer;
exports.PassThruServer = gridserver_1.PassThruServer;
/** @typedef {!tsickle_gridserver_2.RemoteBitmapCallback} */
exports.RemoteBitmapCallback; // re-export typedef
/** @typedef {!tsickle_gridserver_2.GridServerCallbacks} */
exports.GridServerCallbacks; // re-export typedef
const settings_1 = goog.require('gridserver_core.output.settings');
exports.ConfigureGridServerSettings = settings_1.ConfigureGridServerSettings;
exports.GridServerSettings = settings_1.GridServerSettings;
/** @typedef {!tsickle_settings_3.CommonConfig} */
exports.CommonConfig; // re-export typedef
/** @typedef {!tsickle_settings_3.RemoteOverrideInfo} */
exports.RemoteOverrideInfo; // re-export typedef
/** @typedef {!tsickle_settings_3.GxtRemoteConfigData} */
exports.GxtRemoteConfigData; // re-export typedef
/** @typedef {!tsickle_settings_3.GridServerConfigData} */
exports.GridServerConfigData; // re-export typedef
const gserrorcode_1 = goog.require('gridserver_core.output.gserrorcode');
const tracing_1 = goog.require('gridserver_core.output.tracing');
exports.enableTracing = tracing_1.enableTracing;
exports.TracingComponent = tracing_1.TracingComponent;
exports.Scope = tracing_1.Scope;
const dependencies_1 = goog.require('gridserver_core.output.dependencies');
exports.getPlatformDetails = dependencies_1.getPlatformDetails;
/** @typedef {!tsickle_dependencies_6.PlatformDetails} */
exports.PlatformDetails; // re-export typedef
/** @typedef {!tsickle_dependencies_6.TracingInfo} */
exports.TracingInfo; // re-export typedef
/** @typedef {!tsickle_dependencies_6.createTracerCallback} */
exports.createTracerCallback; // re-export typedef
const gstelemetryinterfaces_1 = goog.require('gridserver_core.output.gstelemetryinterfaces');
exports.TelemetryEventBase = gstelemetryinterfaces_1.TelemetryEventBase;
exports.GridServer_GameLaunch_RequestDef = gstelemetryinterfaces_1.GridServer_GameLaunch_RequestDef;
exports.GS_FeatureDef = gstelemetryinterfaces_1.GS_FeatureDef;
exports.GS_SystemAPICallResultDef = gstelemetryinterfaces_1.GS_SystemAPICallResultDef;
exports.GS_DebugInfoDef = gstelemetryinterfaces_1.GS_DebugInfoDef;
exports.GS_ExceptionInfoDef = gstelemetryinterfaces_1.GS_ExceptionInfoDef;
exports.GS_ClientMetricEventDef = gstelemetryinterfaces_1.GS_ClientMetricEventDef;
exports.GS_ConnectivityCheckDef = gstelemetryinterfaces_1.GS_ConnectivityCheckDef;
exports.GS_Sleep_EventDef = gstelemetryinterfaces_1.GS_Sleep_EventDef;
exports.GS_HttpCallResultDef = gstelemetryinterfaces_1.GS_HttpCallResultDef;
exports.GS_PropertyChange_EventDef = gstelemetryinterfaces_1.GS_PropertyChange_EventDef;
exports.GS_CodecError_EventDef = gstelemetryinterfaces_1.GS_CodecError_EventDef;
/** @typedef {!tsickle_gstelemetryinterfaces_7.GridServer_GameLaunch_Request} */
exports.GridServer_GameLaunch_Request; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_Feature} */
exports.GS_Feature; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_SystemAPICallResult} */
exports.GS_SystemAPICallResult; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_DebugInfo} */
exports.GS_DebugInfo; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_ExceptionInfo} */
exports.GS_ExceptionInfo; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_ClientMetricEvent} */
exports.GS_ClientMetricEvent; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_ConnectivityCheck} */
exports.GS_ConnectivityCheck; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_Sleep_Event} */
exports.GS_Sleep_Event; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_HttpCallResult} */
exports.GS_HttpCallResult; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_PropertyChange_Event} */
exports.GS_PropertyChange_Event; // re-export typedef
/** @typedef {!tsickle_gstelemetryinterfaces_7.GS_CodecError_Event} */
exports.GS_CodecError_Event; // re-export typedef
const gstelemetryhandler_1 = goog.require('gridserver_core.output.gstelemetryhandler');
exports.GsTelemetryHandler = gstelemetryhandler_1.GsTelemetryHandler;
