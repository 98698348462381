import { GetHexString, Log } from "../dependencies";
import { RErrorCode } from "../rerrorcode";
import { RNotificationCode } from "../interfaces";

const LOGTAG = "serverclienterrormap";

export declare interface ExitMessage {
    code?: number;
    nvstResult?: number;
    stopData?: string;
}

//We should find a way to auto generate this. We might have a new set in future for NvRtc not same as nvstreamer.
const TerminationReason = {
    SERVER_TERMINATED_INTENDED: 0x0100,
    SERVER_TERMINATED_HOTKEY: 0x0101,
    SERVER_TERMINATED_USER_LOGGED_IN_DIFFERENT_ACCOUNT: 0x0102,
    SERVER_TERMINATED_WINDOWED_MODE: 0x0103,
    SERVER_TERMINATED_USER_IDLE: 0x0104,
    SERVER_TERMINATED_UNAUTHORIZED_PROCESS_DETECTED: 0x0105,
    SERVER_TERMINATED_MALICIOUS_PROCESS_DETECTED: 0x0106,
    SERVER_TERMINATED_UNKNOWN_PROCESS_DETECTED: 0x0107,
    SERVER_TERMINATED_MINER_PROCESS_DETECTED: 0x0108,
    SERVER_TERMINATED_STREAMING_UNSUPPORTED: 0x0109,
    SERVER_TERMINATED_ANOTHER_CLIENT: 0x010a,
    SERVER_TERMINATED_CODE_INTEGRITY_VIOLATION: 0x010b,
    SERVER_TERMINATED_UNAUTHORIZED_ACTIVITY_DETECTED: 0x010c,
    PM_TERMINATED_UNKNOWN_FROM_PM: 0x0200,
    PM_TERMINATED_USER_ENTITLED_MINUTES_EXCEEDED: 0x0201,
    PM_TERMINATED_CLIENT_RECONNECT_TIME_LIMIT_EXCEEDED: 0x0202,
    PM_TERMINATED_OPERATOR_COMMANDED_TERMINATION: 0x0203,
    PM_TERMINATED_CONCURRENT_SESSION_LIMIT_EXCEEDED: 0x0204,
    PM_TERMINATED_MAX_SESSION_TIME_LIMIT_EXCEEDED: 0x0205,
    PM_TERMINATED_BIFROST_INITIATED_SESSION_PAUSE: 0x0206,
    PM_TERMINATED_SYSTEM_COMMANDED_TERMINATION: 0x0207,
    PM_TERMINATED_MULTIPLE_LOGIN: 0x0208,
    PM_TERMINATED_MAINTENANCE_MODE: 0x0209,
    CTMT_TERMINATED_USER_OWNERSHIP_ERROR: 0x0300,
    SERVER_TERMINATED_NO_RESPONSE: 0x8101,
    SERVER_TERMINATED_REMOTE_INPUT_ERROR: 0x8102,
    SERVER_TERMINATED_FRAME_GRAB_FAILED: 0x8103,
    SERVER_TERMINATED_CONFIG_UNAVAILABLE: 0x8104,
    SERVER_TERMINATED_INVALID_COMMAND: 0x8105,
    SERVER_TERMINATED_INVALID_MOUSE_STATE: 0x8106,
    SERVER_TERMINATED_NETWORK_ERROR: 0x8107,
    SERVER_TERMINATED_GAME_LAUNCH_FAILED: 0x8108,
    SERVER_TERMINATED_VIDEO_FIRST_FRAME_SEND_FAILED: 0x810a,
    SERVER_TERMINATED_VIDEO_NEXT_FRAME_SEND_FAILED: 0x810b,
    SERVER_TERMINATED_FRAME_GRAB_TIMEOUT: 0x8110,
    SERVER_TERMINATED_FRAME_ENCODE_TIMEOUT: 0x8111,
    SERVER_TERMINATED_FRAME_SEND_TIMEOUT: 0x8112,
    SERVER_TERMINATED_VFP_PROTECTED_CONTENT: 0x9302,

    CLIENT_DISCONNECT_END_SESSION: 0x0000,
    CLIENT_DISCONNECT_KEEP_SESSION_ALIVE: 0x0001,
    SESSION_TRU_CLIENT_FIRST: 0x8000,
    CLIENT_TIMED_OUT: 0x8000,
    CLIENT_DISCONNECT_UNINTENDED: 0x8001,
    CLIENT_DISCONNECT_UNINTENDED_NO_VIDEO_FRAMES_LOSSY_NETWORK: 0x8002,
    CLIENT_DISCONNECT_UNINTENDED_NO_SERVER_HEART_BEAT: 0x8003,
    CLIENT_DISCONNECT_UNINTENDED_CONTROL_SOCKET_ERROR: 0x8004,
    CLIENT_DISCONNECT_UNINTENDED_NO_VIDEO_PACKETS_EVER: 0x8005,
    CLIENT_DISCONNECT_UNINTENDED_NO_VIDEO_PACKETS_RECENTLY: 0x8006,
    CLIENT_DISCONNECT_UNINTENDED_NO_RTP_PING: 0x8007,
    CLIENT_DISCONNECT_UNINTENDED_STUN_FAILED: 0x8008
};

// This list is the intersection of codes used on the server and codes that have corresponding RErrorCodes
const NvstResult = {
    NVST_DISCONN_INTENDED: 0x80030000,
    NVST_DISCONN_HOTKEY: 0x80030001,
    NVST_DISCONN_TIMEOUT: 0x80030002,
    NVST_DISCONN_LAUNCH_FAILED: 0x80030003,
    NVST_DISCONN_USER_CHANGED: 0x80030004,
    NVST_DISCONN_WINDOWED_MODE: 0x80030006,
    NVST_DISCONN_USER_IDLE: 0x80030007,
    NVST_DISCONN_UNKNOWN_FROM_PM: 0x80030008,
    NVST_DISCONN_USER_ENTITLEMENT_TIMEOUT: 0x80030009,
    NVST_DISCONN_CLIENT_RECONNECT_TIMEOUT: 0x8003000a,
    NVST_DISCONN_OPERATOR_COMMANDED_TERMINATION: 0x8003000b,
    NVST_DISCONN_MAX_SESSION_TIME_LIMIT: 0x8003000c,
    NVST_DISCONN_MAX_CONCURRENT_SESSION_LIMIT: 0x8003000d,
    NVST_DISCONN_BIFROST_INITIATED_SESSION_PAUSE: 0x8003000e,
    NVST_DISCONN_SERVER_TERMINATED_FRAME_GRAB_FAILED: 0x8003000f,
    NVST_DISCONN_PM_COMMANDED_TERMINATION: 0x80030013,
    NVST_DISCONN_SERVER_TERMINATED_INTENDED_UNAUTHORIZED_PROCESS: 0x80030015,
    NVST_DISCONN_SERVER_TERMINATED_MULTIPLE_LOGIN: 0x80030016,
    NVST_DISCONN_SERVER_TERMINATED_INTENDED_MALICIOUS_PROCESS: 0x80030017,
    NVST_DISCONN_SERVER_TERMINATED_INTENDED_UNKNOWN_PROCESS: 0x80030018,
    NVST_DISCONN_SERVER_TERMINATED_INTENDED_MINER_PROCESS: 0x80030019,
    NVST_DISCONN_SERVER_TERMINATED_FRAME_GRAB_TIMEOUT: 0x8003001b,
    NVST_DISCONN_SERVER_TERMINATED_FRAME_ENCODE_TIMEOUT: 0x8003001c,
    NVST_DISCONN_SERVER_TERMINATED_FRAME_SEND_TIMEOUT: 0x8003001d,
    NVST_DISCONN_SERVER_TERMINATED_ANOTHER_CLIENT: 0x8003001e,
    NVST_DISCONN_SERVER_TERMINATED_CODE_INTEGRITY_VIOLATION: 0x8003001f,
    NVST_DISCONN_SERVER_TERMINATED_UNAUTHORIZED_ACTIVITY_DETECTED: 0x80030020,
    NVST_DISCONN_SERVER_VFP_PROTECTED_CONTENT: 0x800e9302,
    NVST_DISCONN_SERVER_TERMINATED_CLOSED: 0x80030023,
    NVST_NETERR_CONNLOST: 0x80040002,
    NVST_NETERR_CLIENT_TIMED_OUT: 0x80040004,
    NVST_NETERR_CLIENT_DISCONNECT_UNINTENDED: 0x80040005,
    NVST_NETERR_SERVER_TERMINATED_UNINTENDED: 0x8004000b,
    NVST_NETERR_SERVER_TERMINATED_REMOTE_INPUT_ERROR: 0x8004000c,
    NVST_NETERR_SERVER_TERMINATED_CONFIG_UNAVAILABLE: 0x8004000d,
    NVST_NETERR_SERVER_TERMINATED_INVALID_COMMAND: 0x8004000e,
    NVST_NETERR_SERVER_TERMINATED_INVALID_MOUSE_STATE: 0x8004000f
};

const TimerNotificationServerCode = {
    UNKNOWN: 0x0000,
    ENTITLEMENT_WARNING_1: 0x0001,
    ENTITLEMENT_WARNING_2: 0x0002,
    ENTITLEMENT_FINAL: 0x0003,
    USER_INPUT_IDLE_WARNING: 0x0004,
    USER_INPUT_IDLE_FINAL: 0x0005,
    MAX_USAGE_LIMIT_WARNING: 0x0006,
    MAX_USAGE_LIMIT_FINAL: 0x0007,
    MAX_CONCURRENT_SESSION_LIMIT: 0x0008
};

export function getRNotificationCode(serverCode: number) {
    let clientTimerNotificationCode = RNotificationCode.Unknown;
    //Ignore final events as they are terminal and client will get stopevent
    Log.d("{c00be32}", "{44bd2b6}", serverCode);
    switch (serverCode) {
        case TimerNotificationServerCode.ENTITLEMENT_WARNING_1:
        case TimerNotificationServerCode.ENTITLEMENT_WARNING_2:
            clientTimerNotificationCode = RNotificationCode.ApproachingEntitlementTimeout;
            break;
        case TimerNotificationServerCode.ENTITLEMENT_FINAL: //ignore
            break;
        case TimerNotificationServerCode.USER_INPUT_IDLE_WARNING:
            clientTimerNotificationCode = RNotificationCode.ApproachingIdleTimeout;
            break;
        case TimerNotificationServerCode.USER_INPUT_IDLE_FINAL: //ignore
            break;
        case TimerNotificationServerCode.MAX_USAGE_LIMIT_WARNING:
            clientTimerNotificationCode = RNotificationCode.ApproachingSessionMaxTimeLimit;
            break;
        case TimerNotificationServerCode.MAX_USAGE_LIMIT_FINAL: //ignore
        case TimerNotificationServerCode.MAX_CONCURRENT_SESSION_LIMIT: //ignore
            break;
    }
    return clientTimerNotificationCode;
}

export function getClientTerminationReason(err: number) {
    if ((err ^ RErrorCode.StreamDisconnectedFromServer) >> 8 == 0) {
        return TerminationReason.SERVER_TERMINATED_INTENDED;
    }
    let clientTerminationReason = TerminationReason.CLIENT_DISCONNECT_UNINTENDED;
    switch (err) {
        case RErrorCode.Success:
        case RErrorCode.SystemSleepDuringStreaming:
        case RErrorCode.NoInternetDuringStreaming:
            clientTerminationReason = TerminationReason.CLIENT_DISCONNECT_END_SESSION;
            break;
        case RErrorCode.StreamerIceConnectionFailed:
        case RErrorCode.StreamerReConnectionFailed:
            clientTerminationReason = TerminationReason.CLIENT_DISCONNECT_UNINTENDED_STUN_FAILED;
            break;
        case RErrorCode.StreamControlChannelError:
            clientTerminationReason =
                TerminationReason.CLIENT_DISCONNECT_UNINTENDED_CONTROL_SOCKET_ERROR;
            break;
        case RErrorCode.StreamerNoVideoPacketsReceivedEver:
            clientTerminationReason =
                TerminationReason.CLIENT_DISCONNECT_UNINTENDED_NO_VIDEO_PACKETS_EVER;
            break;
        case RErrorCode.StreamerNoVideoFramesLossyNetwork:
            clientTerminationReason =
                TerminationReason.CLIENT_DISCONNECT_UNINTENDED_NO_VIDEO_FRAMES_LOSSY_NETWORK;
            break;
        case RErrorCode.PauseSession:
            clientTerminationReason = TerminationReason.CLIENT_DISCONNECT_KEEP_SESSION_ALIVE;
            break;
        default:
            Log.d("{c00be32}", "{233ad08}", GetHexString(err));
    }
    return clientTerminationReason;
}

export function getRErrorCodeForExitMessage(exitMessage: ExitMessage): number {
    if (exitMessage.stopData) {
        const reason = getStopDataReason(exitMessage.stopData);
        if (reason) {
            Log.d("{c00be32}", "{921626d}", reason);
            return getRErrorCode(reason);
        }
    }

    if (exitMessage.nvstResult) {
        Log.d("{c00be32}", "{f30bc33}", exitMessage.nvstResult);
        return getRErrorCodeForNvstResult(exitMessage.nvstResult);
    } else if (exitMessage.code) {
        Log.d("{c00be32}", "{6210ab0}", exitMessage.code);
        return getRErrorCode(exitMessage.code);
    }
    return RErrorCode.StreamDisconnectedFromServer;
}

export function getRErrorCode(serverErr: number): number {
    let clientErr = RErrorCode.StreamDisconnectedFromServer;
    switch (serverErr) {
        case TerminationReason.SERVER_TERMINATED_INTENDED:
            clientErr = RErrorCode.ServerDisconnectedIntended;
            break;
        case TerminationReason.SERVER_TERMINATED_HOTKEY:
            clientErr = RErrorCode.ServerDisconnectedHotKey;
            break;
        case TerminationReason.SERVER_TERMINATED_USER_LOGGED_IN_DIFFERENT_ACCOUNT:
            clientErr = RErrorCode.ServerDisconnecteduserLoggedinDifferenAccount;
            break;
        case TerminationReason.SERVER_TERMINATED_WINDOWED_MODE:
            clientErr = RErrorCode.ServerDisconnectedWindowedMode;
            break;
        case TerminationReason.SERVER_TERMINATED_USER_IDLE:
            clientErr = RErrorCode.ServerDisconnectedUserIdle;
            break;
        case TerminationReason.SERVER_TERMINATED_UNAUTHORIZED_PROCESS_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedUnAuthorizedProcessDetected;
            break;
        case TerminationReason.SERVER_TERMINATED_MALICIOUS_PROCESS_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedMaliciousProcessDetected;
            break;
        case TerminationReason.SERVER_TERMINATED_UNKNOWN_PROCESS_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedUnKnownProcessDetected;
            break;
        case TerminationReason.SERVER_TERMINATED_MINER_PROCESS_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedMinerProcessDetected;
            break;
        case TerminationReason.SERVER_TERMINATED_STREAMING_UNSUPPORTED:
            clientErr = RErrorCode.ServerDisconnectedStreamingUnsupported;
            break;
        case TerminationReason.SERVER_TERMINATED_ANOTHER_CLIENT:
            clientErr = RErrorCode.ServerDisconnectedAnotherClient;
            break;
        case TerminationReason.SERVER_TERMINATED_CODE_INTEGRITY_VIOLATION:
            clientErr = RErrorCode.ServerDisconnectedCodeIntegrityViolation;
            break;
        case TerminationReason.SERVER_TERMINATED_UNAUTHORIZED_ACTIVITY_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedUnauthorizedActivityDetected;
            break;
        case TerminationReason.PM_TERMINATED_UNKNOWN_FROM_PM:
            clientErr = RErrorCode.ServerDisconnectedUnknownFromPm;
            break;
        case TerminationReason.PM_TERMINATED_USER_ENTITLED_MINUTES_EXCEEDED:
            clientErr = RErrorCode.ServerDisconnectedUserEntitledMinutesExceeded;
            break;
        case TerminationReason.PM_TERMINATED_CLIENT_RECONNECT_TIME_LIMIT_EXCEEDED:
            clientErr = RErrorCode.ServerDisconnectedClientReconnectTimeLimitExceeded;
            break;
        case TerminationReason.PM_TERMINATED_OPERATOR_COMMANDED_TERMINATION:
            clientErr = RErrorCode.ServerDisconnectedOperatorCommandedTermination;
            break;
        case TerminationReason.PM_TERMINATED_CONCURRENT_SESSION_LIMIT_EXCEEDED:
            clientErr = RErrorCode.ServerDisconnectedConcurrentSessionLimitExceeded;
            break;
        case TerminationReason.PM_TERMINATED_MAX_SESSION_TIME_LIMIT_EXCEEDED:
            clientErr = RErrorCode.ServerDisconnectedMaxSessionTimeLimitExceeded;
            break;
        case TerminationReason.PM_TERMINATED_BIFROST_INITIATED_SESSION_PAUSE:
            clientErr = RErrorCode.ServerDisconnectedBifrostInitiatedSessionPause;
            break;
        case TerminationReason.PM_TERMINATED_SYSTEM_COMMANDED_TERMINATION:
            clientErr = RErrorCode.ServerDisconnectedSystemCommandTermination;
            break;
        case TerminationReason.PM_TERMINATED_MULTIPLE_LOGIN:
            clientErr = RErrorCode.ServerDisconnectedMultipleLogin;
            break;
        case TerminationReason.PM_TERMINATED_MAINTENANCE_MODE:
            clientErr = RErrorCode.ServerDisconnectedMaintenanceMode;
            break;
        case TerminationReason.SERVER_TERMINATED_NO_RESPONSE:
            clientErr = RErrorCode.ServerDisconnectedNoResponce;
            break;
        case TerminationReason.SERVER_TERMINATED_REMOTE_INPUT_ERROR:
            clientErr = RErrorCode.ServerDisconnectedRemoteInputError;
            break;
        case TerminationReason.SERVER_TERMINATED_FRAME_GRAB_FAILED:
            clientErr = RErrorCode.ServerDisconnectedFrameGrabFailed;
            break;
        case TerminationReason.SERVER_TERMINATED_CONFIG_UNAVAILABLE:
            clientErr = RErrorCode.ServerDisconnectedConfigUnAvailable;
            break;
        case TerminationReason.SERVER_TERMINATED_INVALID_COMMAND:
            clientErr = RErrorCode.ServerDisconnectedInvalidCommand;
            break;
        case TerminationReason.SERVER_TERMINATED_INVALID_MOUSE_STATE:
            clientErr = RErrorCode.ServerDisconnectedInvalidMouseState;
            break;
        case TerminationReason.SERVER_TERMINATED_NETWORK_ERROR:
            clientErr = RErrorCode.ServerDisconnectedNetworkError;
            break;
        case TerminationReason.SERVER_TERMINATED_GAME_LAUNCH_FAILED:
            clientErr = RErrorCode.ServerDisconnectedGameLaunchFailed;
            break;
        case TerminationReason.SERVER_TERMINATED_VIDEO_FIRST_FRAME_SEND_FAILED:
            clientErr = RErrorCode.ServerDisconnectedVideoFirstFrameSendFailed;
            break;
        case TerminationReason.SERVER_TERMINATED_VIDEO_NEXT_FRAME_SEND_FAILED:
            clientErr = RErrorCode.ServerDisconnectedVideoNextFrameSendFailed;
            break;
        case TerminationReason.SERVER_TERMINATED_FRAME_GRAB_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameGrabTimedOut;
            break;
        case TerminationReason.SERVER_TERMINATED_FRAME_ENCODE_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameEncodeTimedOut;
            break;
        case TerminationReason.SERVER_TERMINATED_FRAME_SEND_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameSendTimedOut;
            break;
        case TerminationReason.SERVER_TERMINATED_VFP_PROTECTED_CONTENT:
            clientErr = RErrorCode.ServerDisconnectedProtectedContent;
            break;
        case TerminationReason.CLIENT_TIMED_OUT:
            clientErr = RErrorCode.ServerDisconnectedNetworkTimedOut;
            break;
        case TerminationReason.CTMT_TERMINATED_USER_OWNERSHIP_ERROR:
            clientErr = RErrorCode.ServerDisconnectedGameNotOwnedByUser;
            break;
        default:
            Log.d("{c00be32}", "{1be40ed}", GetHexString(serverErr));
            break;
    }
    return clientErr;
}

export function getRErrorCodeForNvstResult(nvstResult: number): number {
    let clientErr = RErrorCode.StreamDisconnectedFromServer;
    switch (nvstResult) {
        case NvstResult.NVST_DISCONN_INTENDED:
            clientErr = RErrorCode.ServerDisconnectedIntended;
            break;
        case NvstResult.NVST_DISCONN_HOTKEY:
            clientErr = RErrorCode.ServerDisconnectedHotKey;
            break;
        case NvstResult.NVST_DISCONN_TIMEOUT:
            clientErr = RErrorCode.ServerInternalTimeout;
            break;
        case NvstResult.NVST_DISCONN_LAUNCH_FAILED:
            clientErr = RErrorCode.ServerDisconnectedGameLaunchFailed;
            break;
        case NvstResult.NVST_DISCONN_USER_CHANGED:
            clientErr = RErrorCode.ServerDisconnecteduserLoggedinDifferenAccount;
            break;
        case NvstResult.NVST_DISCONN_WINDOWED_MODE:
            clientErr = RErrorCode.ServerDisconnectedWindowedMode;
            break;
        case NvstResult.NVST_DISCONN_USER_IDLE:
            clientErr = RErrorCode.ServerDisconnectedUserIdle;
            break;
        case NvstResult.NVST_DISCONN_UNKNOWN_FROM_PM:
            clientErr = RErrorCode.ServerDisconnectedUnknownFromPm;
            break;
        case NvstResult.NVST_DISCONN_USER_ENTITLEMENT_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedUserEntitledMinutesExceeded;
            break;
        case NvstResult.NVST_DISCONN_CLIENT_RECONNECT_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedClientReconnectTimeLimitExceeded;
            break;
        case NvstResult.NVST_DISCONN_OPERATOR_COMMANDED_TERMINATION:
            clientErr = RErrorCode.ServerDisconnectedOperatorCommandedTermination;
            break;
        case NvstResult.NVST_DISCONN_MAX_SESSION_TIME_LIMIT:
            clientErr = RErrorCode.ServerDisconnectedMaxSessionTimeLimitExceeded;
            break;
        case NvstResult.NVST_DISCONN_MAX_CONCURRENT_SESSION_LIMIT:
            clientErr = RErrorCode.ServerDisconnectedConcurrentSessionLimitExceeded;
            break;
        case NvstResult.NVST_DISCONN_BIFROST_INITIATED_SESSION_PAUSE:
            clientErr = RErrorCode.ServerDisconnectedBifrostInitiatedSessionPause;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_FRAME_GRAB_FAILED:
            clientErr = RErrorCode.ServerDisconnectedFrameGrabFailed;
            break;
        case NvstResult.NVST_DISCONN_PM_COMMANDED_TERMINATION:
            clientErr = RErrorCode.ServerDisconnectedSystemCommandTermination;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_INTENDED_UNAUTHORIZED_PROCESS:
            clientErr = RErrorCode.ServerDisconnectedUnAuthorizedProcessDetected;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_MULTIPLE_LOGIN:
            clientErr = RErrorCode.ServerDisconnectedMultipleLogin;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_INTENDED_MALICIOUS_PROCESS:
            clientErr = RErrorCode.ServerDisconnectedMaliciousProcessDetected;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_INTENDED_UNKNOWN_PROCESS:
            clientErr = RErrorCode.ServerDisconnectedUnKnownProcessDetected;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_INTENDED_MINER_PROCESS:
            clientErr = RErrorCode.ServerDisconnectedMinerProcessDetected;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_FRAME_GRAB_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameGrabTimedOut;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_FRAME_ENCODE_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameEncodeTimedOut;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_FRAME_SEND_TIMEOUT:
            clientErr = RErrorCode.ServerDisconnectedFrameSendTimedOut;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_ANOTHER_CLIENT:
            clientErr = RErrorCode.SessionTerminatedByAnotherClient;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_CODE_INTEGRITY_VIOLATION:
            clientErr = RErrorCode.ServerDisconnectedCodeIntegrityViolation;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_UNAUTHORIZED_ACTIVITY_DETECTED:
            clientErr = RErrorCode.ServerDisconnectedUnauthorizedActivityDetected;
            break;
        case NvstResult.NVST_DISCONN_SERVER_TERMINATED_CLOSED:
            clientErr = RErrorCode.ServerDisconnectedIntended;
            break;
        case NvstResult.NVST_NETERR_CONNLOST:
            clientErr = RErrorCode.StreamerNetworkError;
            break;
        case NvstResult.NVST_NETERR_CLIENT_TIMED_OUT:
            clientErr = RErrorCode.ServerDisconnectedNetworkTimedOut;
            break;
        case NvstResult.NVST_NETERR_CLIENT_DISCONNECT_UNINTENDED:
            clientErr = RErrorCode.StreamerNetworkError;
            break;
        case NvstResult.NVST_NETERR_SERVER_TERMINATED_UNINTENDED:
            clientErr = RErrorCode.StreamerNetworkError;
            break;
        case NvstResult.NVST_NETERR_SERVER_TERMINATED_REMOTE_INPUT_ERROR:
            clientErr = RErrorCode.ServerDisconnectedRemoteInputError;
            break;
        case NvstResult.NVST_NETERR_SERVER_TERMINATED_CONFIG_UNAVAILABLE:
            clientErr = RErrorCode.ServerDisconnectedConfigUnAvailable;
            break;
        case NvstResult.NVST_NETERR_SERVER_TERMINATED_INVALID_COMMAND:
            clientErr = RErrorCode.ServerDisconnectedInvalidCommand;
            break;
        case NvstResult.NVST_NETERR_SERVER_TERMINATED_INVALID_MOUSE_STATE:
            clientErr = RErrorCode.ServerDisconnectedInvalidMouseState;
            break;
        case NvstResult.NVST_DISCONN_SERVER_VFP_PROTECTED_CONTENT:
            clientErr = RErrorCode.ServerDisconnectedProtectedContent;
            break;
        default:
            Log.d("{c00be32}", "{094c45e}", GetHexString(nvstResult));
            break;
    }
    return clientErr;
}

function getStopDataReason(b64StopData: string): number | null {
    // See P4 for a description of how this is serialized:
    // Mjolnir/StreamSdk/helpers/shutdown/StreamerShutdownData.h
    const enum SsdType {
        SESSION_TERMINATION_REASON = 0,
        BLOB = 1 // Currently unused
    }

    if (b64StopData.length < 2) {
        return null;
    }
    const stopData = atob(b64StopData);
    let offset = 0;
    // Reads a 16-bit big-endian integer from stopData, incrementing the offset by 2 bytes.
    const read16 = (): number => {
        const result = stopData.charCodeAt(offset) * 0x100 + stopData.charCodeAt(offset + 1);
        offset += 2;
        return result;
    };
    // First 2 bytes is a type. We only care about SESSION_TERMINATION_REASON
    // because that's the only one actually sent.
    const type = read16();
    if (type == SsdType.SESSION_TERMINATION_REASON) {
        // For SESSION_TERMINATION_REASON, the next 2 bytes are the termination reason.
        if (b64StopData.length < offset + 2) {
            return null;
        }
        return read16();
    }
    return null;
}
