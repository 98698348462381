//import RagnarokSchema from "./EventsSchema-Ragnarok.json";

import {
    GdprLevel,
    ResumeType,
    OverrideConfigTypeEnum,
    CodecType,
    IPVersion,
    NetworkTypeEnum,
    BooleanType,
    TelemetryEventIds,
    TelemetryEventBase,
    ClientTelemetryConfig,
    TelemetryEventPayload
} from "../dependencies";

// This config needs to be fetched from EventsSchema-Ragnarok.json
export const RagnarokTelemetryConfig: ClientTelemetryConfig = {
    clientName: "Ragnarok",
    clientId: "62677554106929725",
    eventSchemaVer: "3.0"
};

// The legacy events are placed in the GFN_PC_Client schema and we need its clientId to dispatch legacy telemetry.
// The eventSchemaVer will be updated in updateEventDataElements
export let GfnPcTelemetryConfig: ClientTelemetryConfig = {
    clientName: "GFN_Client",
    clientId: "78589530426925203",
    eventSchemaVer: "23.0"
};

export declare const enum InputDeviceType {
    UNKNOWN = "UNKNOWN",
    GAMEPAD = "GAMEPAD",
    KEYBOARD = "KEYBOARD",
    MOUSE = "MOUSE",
    TOUCH_INPUT = "TOUCH_INPUT"
}

export declare const enum HotPlugType {
    PLUG = "PLUG",
    UNPLUG = "UNPLUG"
}

export declare const enum HdrStatus {
    UNKNOWN = "UNKNOWN",
    SUPPORTED = "SUPPORTED",
    SUPPORTED_DISABLED = "SUPPORTED_DISABLED",
    UNSUPPORTED = "UNSUPPORTED"
}

export declare interface Resolution {
    refreshRate: number;
    width: number;
    height: number;
}
/////// Event interface Declarations ////////
export declare interface Streamer_Start {
    zoneAddress: string;
    networkSessionId: string;
    sessionId: string;
    subSessionId: string;
    resumeType: ResumeType;
    overrideConfigType: OverrideConfigTypeEnum;
    overrideConfigVersion: string;
    result: string;
    codec: CodecType;
    ipVersion: IPVersion;
    launchDuration: number;
    networkType: NetworkTypeEnum;
    streamingProfileGuid: string;
    systemInfoGuid: string;
    cmsId: string;
}

export declare interface Streamer_Exit {
    zoneAddress: string;
    networkSessionId: string;
    sessionId: string;
    subSessionId: string;
    resumeType: ResumeType;
    overrideConfigType: OverrideConfigTypeEnum;
    overrideConfigVersion: string;
    exitReason: string;
    result: string;
    frameCount: number;
    codec: CodecType;
    ipVersion: IPVersion;
    streamDuration: number;
    networkType: NetworkTypeEnum;
    streamingProfileGuid: string;
    systemInfoGuid: string;
    cmsId: string;
}

export declare interface Streamer_InputDevice {
    deviceName: string;
    deviceType: InputDeviceType;
    vendorId: string;
    productId: string;
    deviceIndex: number;
    reportIndex: number;
    hapticsSupported: BooleanType;
    hapticsFeedbackCount: number;
    state: number;
    eventMapReceived: string;
    eventMapProcessed: string;
    sessionId: string;
    subSessionId: string;
    cmsId: string;
}

export declare interface Streamer_HotPlugEvent {
    deviceType: InputDeviceType;
    deviceIndex: number;
    reportIndex: number;
    bitmap: number;
    action: HotPlugType;
    sessionId: string;
    subSessionId: string;
}

export declare interface Streamer_DecoderCaps {
    codec: CodecType;
    resolutions: Resolution[];
    maxBitrateMbps: number;
    profile: string;
    level: string;
    systemInfoGuid: string;
    isHardwareCodec: BooleanType;
}

export declare interface Streamer_DisplayCaps {
    displayModes: Resolution[];
    hdrStatus: HdrStatus;
    systemInfoGuid: String;
}

export declare interface Streamer_EncoderCaps {
    codec: CodecType;
    resolutions: Resolution[];
    maxBitrateMbps: number;
    profile: String;
    level: String;
    systemInfoGuid: String;
}

/////// Event Type Declarations ////////
export const EventTypes = {
    Streamer_Start: {
        name: "Streamer_Start",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_Exit: {
        name: "Streamer_Exit",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_InputDevice: {
        name: "Streamer_InputDevice",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_HotPlugEvent: {
        name: "Streamer_HotPlugEvent",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_DecoderCaps: {
        name: "Streamer_DecoderCaps",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_DisplayCaps: {
        name: "Streamer_DisplayCaps",
        gdprLevel: GdprLevel.Functional
    },
    Streamer_EncoderCaps: {
        name: "Streamer_EncoderCaps",
        gdprLevel: GdprLevel.Functional
    }
};

/////// Event Class Declarations ////////
export class Streamer_StartDef extends TelemetryEventBase<Streamer_Start> {
    readonly name: string = EventTypes.Streamer_Start.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_Start.gdprLevel;
    constructor(info: Streamer_Start) {
        super(info);
    }
}

export class Streamer_ExitDef extends TelemetryEventBase<Streamer_Exit> {
    readonly name: string = EventTypes.Streamer_Exit.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_Exit.gdprLevel;
    constructor(info: Streamer_Exit) {
        super(info);
    }
}

export class Streamer_InputDeviceDef extends TelemetryEventBase<Streamer_InputDevice> {
    readonly name: string = EventTypes.Streamer_InputDevice.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_InputDevice.gdprLevel;
    constructor(info: Streamer_InputDevice) {
        super(info);
    }
}

export class Streamer_HotPlugEventDef extends TelemetryEventBase<Streamer_HotPlugEvent> {
    readonly name: string = EventTypes.Streamer_HotPlugEvent.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_HotPlugEvent.gdprLevel;
    constructor(info: Streamer_HotPlugEvent) {
        super(info);
    }
}

export class Streamer_DecoderCapsDef extends TelemetryEventBase<Streamer_DecoderCaps> {
    readonly name: string = EventTypes.Streamer_DecoderCaps.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_DecoderCaps.gdprLevel;
    constructor(info: Streamer_DecoderCaps) {
        super(info);
    }
}

export class Streamer_DisplayCapsDef extends TelemetryEventBase<Streamer_DisplayCaps> {
    readonly name: string = EventTypes.Streamer_DisplayCaps.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_DisplayCaps.gdprLevel;
    constructor(info: Streamer_DisplayCaps) {
        super(info);
    }
}

export class Streamer_EncoderCapsDef extends TelemetryEventBase<Streamer_EncoderCaps> {
    readonly name: string = EventTypes.Streamer_EncoderCaps.name;
    readonly gdprLevel: GdprLevel = EventTypes.Streamer_EncoderCaps.gdprLevel;
    constructor(info: Streamer_EncoderCaps) {
        super(info);
    }
}

//////////// Common telemetry interfaces ////////////
export declare interface FormattedEventDetail {
    name: string;
    ts: string;
    parameters: object;
    experiments?: object;
}

/**
 * This interface represents all the common data which ragnarok needs to send telemetry events independently to telemetry server.
 */
export declare interface JsEventsCommonData {
    clientId?: string;
    clientVer?: string;
    eventSchemaVer?: string;
    eventSysVer?: string;
    eventProtocol?: string;
    deviceId?: string;
    userId?: string;
    externalUserId?: string;
    idpId?: string;
    sessionId?: string;
    platform?: string;
    sentTs?: string;
    events?: FormattedEventDetail[];
    gdprFuncOptIn?: string; // this comes from starfleet via client
    gdprTechOptIn?: string;
    gdprBehOptIn?: string;
    deviceGdprFuncOptIn?: string; // this comes from EULA before login. Full when agree.
    deviceGdprTechOptIn?: string;
    deviceGdprBehOptIn?: string;
    deviceOS?: string;
    deviceOSVersion?: string;
    deviceType?: string;
    deviceModel?: string;
    deviceMake?: string;
    clientType?: string;
    clientVariant?: string;
    integrationId?: string;
    browserType?: string;
}

export declare interface JsEventsConfig {
    server: string; // telemetry server url
    version: string;
}

/**
 * This is the root interface used by client to pass common telemetry data to ragnarok.
 */
export declare interface EventDataElements {
    commonData?: JsEventsCommonData;
    experiments?: object; // This needs to be written to events in JsEventsCommonData
    config?: JsEventsConfig;
    telemetryEventIds?: TelemetryEventIds;
}

// db schema
export declare interface TelemetryEventDbSchema {
    userId: string;
    sessionId: string;
    name: string;
    eventPayload?: TelemetryEventPayload;
}

/**
 * Internal interface that describes all the required information to dispatch the exit event telemetry
 */
export declare interface StreamExitEventData {
    exitErrorCode: string;
    sessionId: string;
    subSessionId: string;
    zoneAddress: string;
    streamDuration: number;
    frameCount: number;
    codec: string; // in legacy format 'video/H264'
    isResume: boolean;
    connectivity: string;
    sleep: boolean;
    networkTestSessionId: string;
    sessionSetupFailed: boolean;
}
