import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

export class AwsS3Client {
    private s3: S3Client;
    constructor() {
        this.s3 = new S3Client({
            region: "us-west-1",
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: "us-west-1" }),
                identityPoolId: "us-west-1:051265e4-cd6b-4ae7-8396-baf0f79b86ef"
            })
        });
    }

    uploadSubSession(sessionId: string, subSessionId: string, userId: string, logs: Uint8Array) {
        if (subSessionId.length === 0) {
            alert("SubSession not found");
            return;
        }
        this.s3
            .send(
                new PutObjectCommand({
                    Bucket: "nvrtcclientlogs",
                    Key: `${sessionId}(${userId})/${subSessionId}`,
                    Body: logs
                })
            )
            .then(data => {
                alert("Successfully uploaded logs.");
            })
            .catch(err => {
                alert("There was an error uploading logs: " + err.message);
            });
    }
}
