import { StreamingQuality } from "../interfaces";

const LOGTAG = "qoscalculator";

export class QosCalculator {
    private maxBitrate: number;
    private minBitrate: number;
    private maxLatency: number;
    private minLatency: number;
    private maxQosScore: number;
    //Calculate Scores
    private latencyScore: number;
    private networkLossScore: number;
    private bandwidthScore: number;
    private iirFilterFactor: number;
    private qualityScore: number;
    private lowBandwidthUtilThreshold: number;

    constructor(maxBitRate: number) {
        this.maxBitrate = maxBitRate / 1000;
        this.minBitrate = 0;
        this.maxLatency = 250;
        this.minLatency = 10;
        this.iirFilterFactor = 16;
        this.qualityScore = 100;
        this.latencyScore = 100;
        this.bandwidthScore = 100;
        this.networkLossScore = 100;
        this.maxQosScore = 100;
        this.lowBandwidthUtilThreshold = 25;
    }

    public calculateLatencyScore(currentReport: RTCIceCandidatePairStats) {
        if ((<any>currentReport).currentRoundTripTime > this.maxLatency) {
            this.latencyScore = 0;
        } else {
            let currentRttInMs = (<any>currentReport).currentRoundTripTime * 1000;
            let currentScore =
                (100 * (this.maxLatency - currentRttInMs)) / (this.maxLatency - this.minLatency);
            if (currentScore === undefined || isNaN(currentScore)) return;
            this.latencyScore = this.applyIIRFilter(
                this.latencyScore,
                currentScore,
                this.iirFilterFactor
            );
            this.latencyScore = Math.min(this.latencyScore, this.maxQosScore);
        }
    }

    public calculateLatencyScoreV2(rtd: number) {
        let currentScore = 0;
        if (rtd < this.maxLatency) {
            let currentRttInMs = rtd;
            currentScore =
                (100 * (this.maxLatency - currentRttInMs)) / (this.maxLatency - this.minLatency);
            if (currentScore === undefined || isNaN(currentScore)) currentScore = 0;
        }
        this.latencyScore = this.applyIIRFilter(
            this.latencyScore,
            currentScore,
            this.iirFilterFactor
        );
        this.latencyScore = Math.min(this.latencyScore, this.maxQosScore);
    }

    public calculateBandwidthScoreV2(bandwidthMbps: number, bwu: number) {
        let currentScore = 100;
        if (bwu >= this.lowBandwidthUtilThreshold) {
            currentScore =
                ((bandwidthMbps - this.minBitrate) / (this.maxBitrate - this.minBitrate)) * 100;
        }
        if (currentScore > 100) currentScore = 100;
        if (currentScore === undefined || isNaN(currentScore)) return 0;
        this.bandwidthScore = this.applyIIRFilter(
            this.bandwidthScore,
            currentScore,
            this.iirFilterFactor
        );
        this.bandwidthScore = Math.min(this.bandwidthScore, this.maxQosScore);
    }

    public calculateNetworkLossScore(
        currInboundVideo: RTCInboundRtpStreamStats,
        prevInboundVideo: RTCInboundRtpStreamStats
    ) {
        let prevPacketReceived = prevInboundVideo.packetsReceived!;
        let prevPacketLost = prevInboundVideo.packetsLost!;
        let intervalPacketReceived = currInboundVideo.packetsReceived! - prevPacketReceived;
        let intervalPacketLost = currInboundVideo.packetsLost! - prevPacketLost;
        let fractionPacketLost = intervalPacketLost / (intervalPacketReceived + intervalPacketLost);
        let currentScore = 100 - fractionPacketLost * 100;
        if (isNaN(currentScore) || currentScore === undefined) currentScore = 0;
        let prevPliCount = prevInboundVideo.pliCount!;
        let currentPliCount = currInboundVideo.pliCount!;

        let frameLost = currentPliCount - prevPliCount;
        // Frames can be dropped due to multiple reason, here consider only network related drops
        if (frameLost > 0 && intervalPacketLost) currentScore = 0;
        for (let i = 0; i < frameLost + 1; i++) {
            this.networkLossScore = this.applyIIRFilter(
                this.networkLossScore,
                currentScore,
                this.iirFilterFactor / 2
            );
        }
    }

    public GetStreamQuality() {
        let currentScore = Math.min(this.latencyScore, this.networkLossScore, this.bandwidthScore);

        this.qualityScore = this.applyIIRFilter(
            this.qualityScore,
            currentScore,
            this.iirFilterFactor / 4
        );
        let streamQuality: StreamingQuality = {
            latencyScore: this.latencyScore,
            networkLossScore: this.networkLossScore,
            bandwidthScore: this.bandwidthScore,
            qualityScore: this.qualityScore
        };
        return streamQuality;
    }

    private applyIIRFilter(avgValue: number, input: number, coeff: number) {
        return (avgValue * (coeff - 1)) / coeff + input / coeff;
    }
}
