import { Log } from "../dependencies";
import {
    LDATController,
    FrameLatencyData,
    DEFAULT_LUMA_THRESHOLD,
    MOUSE_EVENT_DELAY_MS
} from "./ldatcontroller";
import { VideoFrameMetadata } from "../internalinterfaces";
import { Download, SupportsPointerEvents } from "../util/utils";
import { CursorType } from "../interfaces";

const LOGTAG = "ldatoverlay";

const DEFAULT_SHOT_COUNT = 20;
const DEFAULT_SHOT_DELAY_MS = 1000;

const SHOW_HOTKEYS_LABEL = "Show hotkeys";
const HIDE_HOTKEYS_LABEL = "Hide hotkeys";

export declare interface ILDATHandler {
    stop(): void;
    reset(): void;
    toggleVisibility(): void;
    isVisible(): boolean;
    toggle(): void;
    isActive(): boolean;
    allowPointerLock(): boolean;
    toggleAutoFire(): void;
    saveLog(): void;
    centerLoupe(): void;
}

export class LDATOverlay implements ILDATHandler {
    private visible: boolean = false;
    private controller: LDATController;
    private videoElement: HTMLVideoElement;
    private fullscreenChangeFunc: any;
    private mouseDownFunc: any;
    private mouseMoveFunc: any;
    private overlay: HTMLDivElement;
    private lumaSlider!: HTMLInputElement;
    private lumaSpan!: HTMLSpanElement;
    private startButton!: HTMLInputElement;
    private helpDialogueButton!: HTMLInputElement;
    private loupe: HTMLDivElement;
    private loupeHeader!: HTMLDivElement;
    private warning: HTMLDivElement;
    private helpDialogue: HTMLDivElement;
    private notification: HTMLDivElement;
    private latencyData: FrameLatencyData[] = [];
    private autoFireInputs!: HTMLDivElement;
    private autoFireCheckbox!: HTMLInputElement;
    private shotCountSlider!: HTMLInputElement;
    private shotCountSpan!: HTMLSpanElement;
    private shotDelaySlider!: HTMLInputElement;
    private shotDelaySpan!: HTMLSpanElement;
    private autoFireInProgress: boolean = false;
    private autoFireIntervalId: number = 0;
    private shotCount: number = 0;
    private loupePositionX!: HTMLInputElement;
    private loupePositionY!: HTMLInputElement;
    private moveOnClickCheckBox!: HTMLInputElement;
    private supportsPointerEvents: boolean;
    private shouldUsePointerUpDownEvents: boolean;
    private prevMoveEvent = { x: 0, y: 0 };

    constructor(videoElement: HTMLVideoElement, cursorType: CursorType = CursorType.SOFTWARE) {
        this.videoElement = videoElement;
        this.supportsPointerEvents = SupportsPointerEvents();
        this.shouldUsePointerUpDownEvents =
            cursorType == CursorType.FREE && this.supportsPointerEvents;
        this.controller = new LDATController(videoElement, this.shouldUsePointerUpDownEvents);

        this.fullscreenChangeFunc = this.onfullscreenchange.bind(this);
        this.mouseDownFunc = this.onmousedown.bind(this);
        this.mouseMoveFunc = this.onmousemove.bind(this);
        this.overlay = this.createOverlay();
        this.loupe = this.createLoupe();
        this.warning = this.createWarning();
        this.helpDialogue = this.createHelpDialogue();
        this.notification = this.createNotification();

        videoElement.insertAdjacentElement("afterend", this.overlay);
        this.overlay.insertAdjacentElement("afterend", this.loupe);
        this.loupe.insertAdjacentElement("afterend", this.warning);
        this.overlay.insertAdjacentElement("beforebegin", this.helpDialogue);
        this.helpDialogue.insertAdjacentElement("beforebegin", this.notification);

        this.controller.registerFlashCallback(this.flashCallback.bind(this));
        this.styleAutoFireOverlay(false);
        this.setMoveOnClick(false);
        this.styleLoupe(true);
    }

    /**
     * Stop the LDAT.
     * Should only be invoked during session teardown.
     */
    public stop(): void {
        this.overlay.remove();
        this.loupe.remove();
        this.warning.remove();
        this.helpDialogue.remove();
        this.notification.remove();
        this.setVisible(false);
        this.latencyData = [];
    }

    /**
     * Reset the LDAT.
     * Restores default settings and clears any stored data.
     */
    public reset(): void {
        this.setActive(false);
        this.setValueAndDispatchInputEvent(this.lumaSlider, DEFAULT_LUMA_THRESHOLD);
        this.setValueAndDispatchInputEvent(this.shotCountSlider, DEFAULT_SHOT_COUNT);
        this.setValueAndDispatchInputEvent(this.shotDelaySlider, DEFAULT_SHOT_DELAY_MS);
        this.styleAutoFireOverlay(false);
        this.setMoveOnClick(false);
        this.styleLoupe(true);
        this.styleHelpDialogue(false);
        this.loupePositionX.value = "";
        this.loupePositionY.value = "";
        this.latencyData = [];
    }

    /**
     * Toggle the LDAT's visibility.
     * If the LDAT is currently not visible, then display the interface.
     * If the LDAT is currently visible, then minimize the interface.
     */
    public toggleVisibility(): void {
        this.setVisible(!this.isVisible());
    }

    private setVisible(visible: boolean): void {
        if (visible !== this.visible) {
            Log.i("{50c79eb}", "{5595c14}", visible);
            this.visible = visible;

            for (const element of [this.overlay, this.loupe, this.helpDialogue]) {
                if (element === this.helpDialogue && visible) {
                    this.styleHelpDialogue(this.helpDialogueButton.value !== SHOW_HOTKEYS_LABEL);
                    continue;
                }
                element.style.display = visible ? "block" : "none";
            }
            const mouseDownFuncName = this.shouldUsePointerUpDownEvents
                ? "pointerdown"
                : "mousedown";
            const mouseMoveFuncName = this.supportsPointerEvents ? "pointermove" : "mousemove";

            if (visible) {
                this.updateLoupePosition();
                this.makeDraggable(this.loupe, this.loupeHeader);

                this.lumaSlider.oninput = evt => {
                    const threshold = parseFloat((evt.target as HTMLInputElement).value);
                    this.controller.setLuminanceThreshold(threshold);
                    this.lumaSpan.innerHTML = this.asPercentage(threshold);
                };
                this.autoFireCheckbox.oninput = evt => {
                    this.styleAutoFireOverlay((evt.target as HTMLInputElement).checked);
                };
                this.shotCountSlider.oninput = evt => {
                    this.shotCountSpan.innerHTML = (evt.target as HTMLInputElement).value;
                };
                this.shotDelaySlider.oninput = evt => {
                    this.shotDelaySpan.innerHTML = this.inMilliseconds(
                        (evt.target as HTMLInputElement).value
                    );
                };
                this.moveOnClickCheckBox.oninput = evt => {
                    this.setMoveOnClick((evt.target as HTMLInputElement).checked);
                };

                const onLoupePositionInput = (evt: Event, element: HTMLInputElement) => {
                    const x = parseInt((evt.target as HTMLInputElement).value);
                    element.value = Math.max(Math.min(x, 100), 0).toFixed(0);
                    this.positionLoupeFromUserInput();
                };
                this.loupePositionX.oninput = evt => onLoupePositionInput(evt, this.loupePositionX);
                this.loupePositionY.oninput = evt => onLoupePositionInput(evt, this.loupePositionY);

                this.startButton.onclick = _ => this.setActive(this.startButton.value === "Start");
                this.helpDialogueButton.onclick = _ =>
                    this.styleHelpDialogue(this.helpDialogueButton.value === SHOW_HOTKEYS_LABEL);
                this.loupe.onmouseup = this.updateLoupePosition.bind(this);
                this.loupe.onmousedown = _ => {
                    if (this.isActive()) {
                        // When the LDAT is active, the video element must be positioned behind the loupe
                        // Send mouse click to video element so that pointer can be locked if need be
                        this.controller.sendMouseClickEvent();
                    }
                };
                [
                    "fullscreenchange",
                    "webkitfullscreenchange",
                    "mozfullscreenchange",
                    "msfullscreenchange"
                ].forEach(eventType =>
                    document.addEventListener(eventType, this.fullscreenChangeFunc)
                );
                this.videoElement.addEventListener(mouseDownFuncName, this.mouseDownFunc);
                this.videoElement.addEventListener(mouseMoveFuncName, this.mouseMoveFunc);
            } else {
                this.setActive(false);
                this.lumaSlider.oninput = null;
                this.autoFireCheckbox.oninput = null;
                this.shotCountSlider.oninput = null;
                this.shotDelaySlider.oninput = null;
                this.moveOnClickCheckBox.oninput = null;
                this.loupePositionX.oninput = null;
                this.loupePositionY.oninput = null;
                this.startButton.onclick = null;
                this.helpDialogueButton.onclick = null;
                this.loupe.onmouseup = null;
                this.loupe.onmousedown = null;
                this.loupeHeader.onmousedown = null;
                [
                    "fullscreenchange",
                    "webkitfullscreenchange",
                    "mozfullscreenchange",
                    "msfullscreenchange"
                ].forEach(eventType =>
                    document.removeEventListener(eventType, this.fullscreenChangeFunc)
                );
                this.videoElement.removeEventListener(mouseDownFuncName, this.mouseDownFunc);
                this.videoElement.removeEventListener(mouseMoveFuncName, this.mouseMoveFunc);
            }
        }
    }

    /**
     * @return True if LDAT overlay is visible, false otherwise.
     */
    public isVisible(): boolean {
        return this.visible;
    }

    /**
     * Toggle whether the LDAT is active by starting or stopping the LDAT.
     * If the LDAT is currently inactive, then start the LDAT.
     * If the LDAT is currently active, then stop the LDAT.
     * When active, the area under the loupe is continuously sampled for changes in luminance.
     */
    public toggle(): void {
        this.setActive(!this.isActive());
    }

    private setActive(active: boolean): void {
        if (active && !this.controller.isLoupeInPosition()) {
            this.showLoupePositionWarning();
            return;
        }

        if (active && this.autoFireInProgress) {
            Log.e("{50c79eb}", "{89742e9}");
            return;
        }

        this.controller.setActive(active);
        this.styleLoupe();

        if (active) {
            this.startButton.value = "Stop";
            if (this.isAutoFire()) {
                Log.i("{50c79eb}", "{5974b2c}");
                this.autoFireInProgress = true;
                this.shotCount = parseInt(this.shotCountSlider.value);
                const shotDelayMs = parseInt(this.shotDelaySlider.value);
                this.autoFireIntervalId = window.setInterval(() => this.autoFire(), shotDelayMs);
            }
        } else {
            this.startButton.value = "Start";
            if (this.autoFireInProgress) {
                if (this.autoFireIntervalId) {
                    Log.i("{50c79eb}", "{d340c50}");
                    this.resetAutoFire();
                } else {
                    Log.w("{50c79eb}", "{6d8948b}");
                }
            }
        }
    }

    /**
     * @return True if the LDAT is active, false otherwise.
     */
    public isActive(): boolean {
        return this.controller.isActive();
    }

    /**
     * @return True if requesting pointer lock on the video element is allowed.
     * Returns false if pointer lock should not occur on the video element.
     * Pointer lock is prevented when the LDAT should interact with mouse events.
     */
    public allowPointerLock(): boolean {
        return !this.isVisible() || this.isActive();
    }

    /**
     * Toggle whether the LDAT is in autofire mode.
     */
    public toggleAutoFire(): void {
        this.styleAutoFireOverlay(!this.isAutoFire());
    }

    private isAutoFire(): boolean {
        return this.autoFireCheckbox.checked;
    }

    /**
     * Save latency measurements to log file.
     * File is located in downloads folder.
     */
    public saveLog(): void {
        if (!this.latencyData.length) {
            Log.w("{50c79eb}", "{2884507}");
            return;
        }
        const fileName = `LDAT_${new Date().toISOString()}.csv`;
        const content = [
            [
                "Sample #",
                "Total",
                "Render",
                "Decode",
                "Begin",
                "Server + Rtd + Receive",
                "Input Callback"
            ].join(",")
        ];

        for (let i = 0; i < this.latencyData.length; i++) {
            const data = this.latencyData[i];
            const decodeTime = data.processingDuration ?? "";
            const beginTime =
                data.receiveTime && data.processingDuration
                    ? data.presentationTime - data.processingDuration - data.receiveTime
                    : "";
            const serverRtdReceiveTime = data.receiveTime
                ? data.receiveTime - data.mouseClickCallbackTime
                : "";

            content.push(
                [
                    i,
                    data.videoFrameCallbackTime - data.mouseClickTime,
                    data.videoFrameCallbackTime - data.presentationTime,
                    decodeTime,
                    beginTime,
                    serverRtdReceiveTime,
                    data.mouseClickCallbackTime - data.mouseClickTime
                ].join(",")
            );
        }

        if (Download([content.join("\n")], fileName, "text/plain")) {
            Log.i("{50c79eb}", "{690879e}", fileName);
        }
    }

    /**
     * Position loupe in center of video element.
     * Accounts for padding.
     */
    public centerLoupe(): void {
        Log.i("{50c79eb}", "{d2811a7}");
        this.loupePositionX.value = "50";
        this.loupePositionY.value = "50";
        this.positionLoupe(0.5, 0.5);
    }

    /**
     * When LDAT is active, inform controller of frame timing data.
     * Controller will use this data to compute latency statistics when a flash occurs.
     * Should only be invoked when LDAT is active.
     */
    public onVideoFrame(now: DOMHighResTimeStamp, metadata: VideoFrameMetadata): void {
        this.controller.onVideoFrame(now, metadata);
    }

    private createDiv(): HTMLDivElement {
        const div = document.createElement("div");
        div.style.display = "none";
        div.style.position = "absolute";
        div.style.color = "white";
        div.style.backgroundColor = "rgb(105, 105, 105, 0.7)";
        div.style.padding = "2px";
        return div;
    }

    private createTitleDiv(title: string): HTMLDivElement {
        const div = document.createElement("div");
        div.style.width = "100%";
        div.style.borderBottom = "1px dashed darkgray";
        div.style.paddingBottom = "2px";
        div.innerHTML = title;
        return div;
    }

    private createButton(value: string): HTMLInputElement {
        const element = document.createElement("input");
        element.type = "button";
        element.value = value;
        element.style.margin = "5px";
        element.style.backgroundColor = "rgb(132, 130, 143, .7)";
        element.style.color = "white";
        element.style.padding = "8px 40px";
        element.style.border = "none";
        element.style.borderRadius = "4px";
        element.style.cursor = "pointer";
        return element;
    }

    private createCheckBox(): HTMLInputElement {
        const element = document.createElement("input");
        element.type = "checkbox";
        return element;
    }

    private createLabel(label: string): HTMLLabelElement {
        const element = document.createElement("label");
        element.innerHTML = label;
        return element;
    }

    private createRangeInput(
        value: number,
        min: number,
        max: number,
        step: number = 1
    ): HTMLInputElement {
        const input = document.createElement("input");
        input.type = "range";
        input.min = min.toString();
        input.max = max.toString();
        input.step = step.toString();
        input.value = value.toString();
        return input;
    }

    private createOverlay(): HTMLDivElement {
        const div = this.createDiv();
        const title = this.createTitleDiv("Latency Display Analysis Tool (LDAT)");
        this.startButton = this.createButton("Start");
        this.helpDialogueButton = this.createButton(SHOW_HOTKEYS_LABEL);

        div.style.bottom = "0px";
        div.style.left = "0px";
        div.style.margin = "5px";

        this.helpDialogueButton.style.border = "1px solid white";
        this.helpDialogueButton.style.fontSize = "10px";
        this.helpDialogueButton.style.padding = "5px";
        this.helpDialogueButton.style.position = "absolute";
        this.helpDialogueButton.style.right = "0px";

        div.appendChild(title);
        div.appendChild(this.startButton);
        div.appendChild(this.helpDialogueButton);
        div.appendChild(this.createLumaThreshold());
        div.appendChild(this.createAutoFireOverlay());
        div.appendChild(this.createLoupePositionOverlay());

        this.moveOnClickCheckBox = this.createCheckBox();
        const moveOnClickInfo = document.createElement("span");

        moveOnClickInfo.title =
            "Simulate mouse move event whenever a mouse click is detected.  Introduces two 300 ms delays between move events and requires a minimum shot delay of 1 sec.";
        moveOnClickInfo.innerHTML = "<sup> i </sup>";

        div.appendChild(this.createLabel("Move On Click"));
        div.appendChild(moveOnClickInfo);
        div.appendChild(this.moveOnClickCheckBox);

        return div;
    }

    private createLumaThreshold(): HTMLDivElement {
        const div = document.createElement("div");
        const defaultThreshold = this.controller.getLuminanceThreshold();
        this.lumaSlider = this.createRangeInput(defaultThreshold, 0.01, 0.2, 0.01);
        this.lumaSpan = document.createElement("span");
        const label = this.createLabel("Activation Level");
        const lumaInfo = document.createElement("span");

        this.lumaSpan.innerHTML = this.asPercentage(defaultThreshold);

        lumaInfo.style.paddingRight = "2px";
        lumaInfo.title = "% increase in luminance used to determine whether a flash occurred";
        lumaInfo.innerHTML = "<sup> i </sup>";

        div.appendChild(label);
        div.appendChild(lumaInfo);
        div.appendChild(this.lumaSlider);
        div.appendChild(this.lumaSpan);

        return div;
    }

    private createAutoFireOverlay(): HTMLDivElement {
        const div = document.createElement("div");
        this.autoFireInputs = document.createElement("div");
        this.autoFireCheckbox = this.createCheckBox();
        this.shotCountSlider = this.createRangeInput(DEFAULT_SHOT_COUNT, 5, 100, 5);
        this.shotDelaySlider = this.createRangeInput(
            DEFAULT_SHOT_DELAY_MS,
            MOUSE_EVENT_DELAY_MS,
            5000,
            100
        );
        const labelCheckbox = this.createLabel("Autofire");
        const labelShotCount = this.createLabel("# Shots");
        const labelShotDelay = this.createLabel("Shot Delay");
        this.shotCountSpan = document.createElement("span");
        this.shotDelaySpan = document.createElement("span");

        this.shotCountSpan.innerHTML = this.shotCountSlider.value;
        this.shotDelaySpan.innerHTML = this.inMilliseconds(this.shotDelaySlider.value);

        this.autoFireInputs.appendChild(labelShotCount);
        this.autoFireInputs.appendChild(this.shotCountSlider);
        this.autoFireInputs.appendChild(this.shotCountSpan);
        this.autoFireInputs.appendChild(document.createElement("br"));
        this.autoFireInputs.appendChild(labelShotDelay);
        this.autoFireInputs.appendChild(this.shotDelaySlider);
        this.autoFireInputs.appendChild(this.shotDelaySpan);

        div.appendChild(labelCheckbox);
        div.appendChild(this.autoFireCheckbox);
        div.appendChild(this.autoFireInputs);

        return div;
    }

    private styleAutoFireOverlay(autoFire: boolean) {
        this.autoFireCheckbox.checked = autoFire;
        this.shotCountSlider.disabled = !autoFire;
        this.shotDelaySlider.disabled = !autoFire;
        this.autoFireInputs.style.color = autoFire ? "white" : "gray";
    }

    private createLoupePositionOverlay(): HTMLDivElement {
        const div = document.createElement("div");
        const label = this.createLabel("Loupe Position:");
        const labelX = this.createLabel("X");
        const labelY = this.createLabel("Y");

        labelX.style.paddingLeft = "10px";
        labelY.style.paddingLeft = "10px";
        labelX.style.paddingRight = "2px";
        labelY.style.paddingRight = "2px";

        const createNumberInput = () => {
            const input = document.createElement("input");
            input.type = "number";
            input.min = "0";
            input.max = "100";
            input.step = "1";
            input.placeholder = "Int";
            input.style.width = "40px";
            return input;
        };
        this.loupePositionX = createNumberInput();
        this.loupePositionY = createNumberInput();

        div.appendChild(label);
        div.appendChild(labelX);
        div.appendChild(this.loupePositionX);
        div.appendChild(this.createLabel("%"));
        div.appendChild(labelY);
        div.appendChild(this.loupePositionY);
        div.appendChild(this.createLabel("%"));

        return div;
    }

    private createLoupe(): HTMLDivElement {
        const div = this.createDiv();
        const title = this.createTitleDiv("Loupe");
        this.loupeHeader = document.createElement("div");

        this.loupeHeader.style.cursor = "move";
        this.loupeHeader.style.width = "100%";
        this.loupeHeader.style.height = "100%";
        this.loupeHeader.innerHTML =
            "Click here to drag the loupe to the area of the screen where flashes will occur.  Then, press start.";

        div.appendChild(title);
        div.appendChild(this.loupeHeader);

        return div;
    }

    /**
     * Positions loupe centered at (x, y), where x and y are normalized values 0.0 - 1.0 in video coordinate space
     */
    private positionLoupe(x: number, y: number): void {
        const videoRect = this.videoElement.getBoundingClientRect();
        const loupeRect = this.loupe.getBoundingClientRect();
        const { paddingTop, paddingRight, paddingBottom, paddingLeft } = this.getPadding(
            this.videoElement
        );

        const videoPannelHeight = videoRect.height - paddingTop - paddingBottom;
        const videoPanelWidth = videoRect.width - paddingLeft - paddingRight;

        // Position loupe relative to computed video panel dimensions.  Includes padding to account for black bars if present.
        const top = videoRect.top + paddingTop + videoPannelHeight * y - loupeRect.height * y;
        const left = videoRect.left + paddingLeft + videoPanelWidth * x - loupeRect.width * x;

        this.loupe.style.top = top.toString() + "px";
        this.loupe.style.left = left.toString() + "px";
        this.updateLoupePosition();
    }

    private positionLoupeFromUserInput() {
        const asInt = (x: string): number => {
            // If input cannot be converted to numeric representation, select midpoint, i.e. 50%
            return parseInt(x || "50") / 100;
        };
        this.positionLoupe(asInt(this.loupePositionX.value), asInt(this.loupePositionY.value));
    }

    private styleLoupe(defaultPosition: boolean = false): void {
        if (defaultPosition) {
            this.loupe.style.bottom = "5px";
            this.loupe.style.left = "300px";
            this.loupe.style.top = "";
            this.loupe.style.right = "";
            this.loupe.style.width = "150px";
            this.loupe.style.height = "150px";
        }

        if (this.isActive()) {
            this.loupeHeader.style.display = "none";
            this.loupe.style.border = "2px rgb(76, 175, 80) solid";
            this.loupe.style.backgroundColor = "transparent";
        } else {
            this.loupeHeader.style.display = "block";
            this.loupe.style.border = "none";
            this.loupe.style.backgroundColor = "rgb(105, 105, 105, 0.7)";
        }
    }

    private setMoveOnClick(enable: boolean) {
        this.moveOnClickCheckBox.checked = enable;
    }

    private onmousedown(evt: MouseEvent | PointerEvent): void {
        if (this.moveOnClickCheckBox.checked && this.isActive() && !(evt as any).fromSelf) {
            const MOVE_UNITS = 64;
            const enum eventType {
                UP,
                DOWN,
                MOVE
            }

            const dispatchEvent = (type: eventType, options: any) => {
                switch (type) {
                    case eventType.UP:
                        this.videoElement.dispatchEvent(
                            this.shouldUsePointerUpDownEvents
                                ? new PointerEvent("pointerup", options)
                                : new MouseEvent("mouseup", options)
                        );
                        break;
                    case eventType.DOWN:
                        const downEvent = this.shouldUsePointerUpDownEvents
                            ? new PointerEvent("pointerdown", options)
                            : new MouseEvent("mousedown", options);
                        (downEvent as any).fromSelf = true;
                        this.videoElement.dispatchEvent(downEvent);
                        break;
                    case eventType.MOVE:
                        this.videoElement.dispatchEvent(
                            this.supportsPointerEvents
                                ? new PointerEvent("pointermove", options)
                                : new MouseEvent("mousemove", options)
                        );
                        this.videoElement.dispatchEvent(
                            new PointerEvent("pointerrawupdate", options)
                        );
                        break;
                    default:
                        break;
                }
            };

            const upDownOptions = { button: 2, isPrimary: true, pointerId: 1 };
            const moveOptions = {
                clientX: (Math.max(evt.clientX, 0) || this.prevMoveEvent.x) + MOVE_UNITS,
                clientY: Math.max(evt.clientY, 0) || this.prevMoveEvent.y,
                movementX: MOVE_UNITS,
                isPrimary: true
            };

            // Press secondary mouse button
            dispatchEvent(eventType.DOWN, upDownOptions);
            // Delay required to ensure applications can handle each input event and do so sequentially
            const DELAY_MS = 300;
            window.setTimeout(() => {
                // Move right MOVE_UNITS
                dispatchEvent(eventType.MOVE, moveOptions);
                window.setTimeout(() => {
                    moveOptions.clientX -= MOVE_UNITS;
                    moveOptions.movementX = -MOVE_UNITS;
                    // Move left MOVE_UNITS
                    dispatchEvent(eventType.MOVE, moveOptions);
                    // Release secondary mouse button
                    window.setTimeout(() => dispatchEvent(eventType.UP, upDownOptions), DELAY_MS);
                }, DELAY_MS);
            }, DELAY_MS);
        }
    }

    private onmousemove(evt: MouseEvent | PointerEvent): void {
        this.prevMoveEvent = { x: evt.clientX, y: evt.clientY };
    }

    private createWarning(): HTMLDivElement {
        const div = this.createDiv();
        div.style.position = "relative";
        div.style.backgroundColor = "gray";
        div.style.textAlign = "center";
        div.innerHTML =
            "Warning: Must place loupe over video element to activate the LDAT.  Please reposition the loupe and then click start.";
        return div;
    }

    private createHelpDialogue(): HTMLDivElement {
        const div = this.createDiv();
        const title = this.createTitleDiv("LDAT Hot Keys");
        const list = document.createElement("ul");

        div.style.bottom = "195px";
        div.style.left = "0px";
        div.style.margin = "5px";
        list.style.listStyle = "none";
        list.style.paddingLeft = "2px";
        list.style.margin = "5px";

        for (const item of [
            "Enter - Start/Stop",
            "R - Reset",
            "A - Turn On/Off Auto-Fire Mode",
            "S - Save Log File",
            "C - Center Loupe on Screen"
        ]) {
            const li = document.createElement("li");
            li.innerHTML = item;
            list.append(li);
        }

        div.appendChild(title);
        div.appendChild(list);

        return div;
    }

    private styleHelpDialogue(visible: boolean): void {
        if (visible) {
            this.helpDialogueButton.value = HIDE_HOTKEYS_LABEL;
            this.helpDialogue.style.display = "block";
        } else {
            this.helpDialogueButton.value = SHOW_HOTKEYS_LABEL;
            this.helpDialogue.style.display = "none";
        }
    }

    private createNotification(): HTMLDivElement {
        const div = this.createDiv();
        div.style.bottom = "325px";
        div.style.left = "0px";
        div.style.margin = "5px";
        return div;
    }

    private updateLoupePosition(): void {
        const videoRect = this.videoElement.getBoundingClientRect();
        const loupeRect = this.loupe.getBoundingClientRect();

        if (
            this.videoElement.style.paddingTop ||
            this.videoElement.style.paddingRight ||
            this.videoElement.style.paddingBottom ||
            this.videoElement.style.paddingLeft
        ) {
            const { paddingTop, paddingRight, paddingBottom, paddingLeft } = this.getPadding(
                this.videoElement
            );

            videoRect.x += paddingLeft;
            videoRect.y += paddingTop;
            videoRect.width -= paddingLeft + paddingRight;
            videoRect.height -= paddingTop + paddingBottom;
        }

        loupeRect.x = (loupeRect.x - videoRect.x) / videoRect.width;
        loupeRect.y = (loupeRect.y - videoRect.y) / videoRect.height;
        loupeRect.width /= videoRect.width;
        loupeRect.height /= videoRect.height;

        this.controller.setLoupePosition(loupeRect);
    }

    private showLoupePositionWarning(): void {
        this.warning.style.display = "block";
        window.setTimeout(() => (this.warning.style.display = "none"), 5000);
    }

    private onfullscreenchange(evt: Event) {
        this.updateLoupePosition();
    }

    private flashCallback(data: FrameLatencyData) {
        this.latencyData.push(data);
        const latency = (data.videoFrameCallbackTime - data.mouseClickTime).toFixed();
        const msg = `Flash occurred at frame
            ${data.presentedFrames}. Latency: ${latency} ms`;
        Log.i("{50c79eb}", "{0b0c6f9}", msg);
        this.notification.innerHTML = msg;
        this.notification.style.display = "block";

        window.setTimeout(() => {
            this.notification.innerHTML = "";
            this.notification.style.display = "none";
        }, 5000);
    }

    private autoFire() {
        if (this.shotCount-- > 0) {
            this.controller.sendMouseClickEvent();
        } else {
            Log.i("{50c79eb}", "{d37e51d}");
            this.resetAutoFire();
            this.setActive(false);
        }
    }

    private resetAutoFire(): void {
        window.clearInterval(this.autoFireIntervalId);
        this.autoFireIntervalId = 0;
        this.autoFireInProgress = false;
    }

    private makeDraggable(element: HTMLDivElement, header: HTMLDivElement): void {
        let x = 0,
            y = 0,
            dx = 0,
            dy = 0;
        const onmouseup = document.onmouseup;
        const onmousemove = document.onmousemove;

        header.onmousedown = (evt: MouseEvent) => {
            evt.preventDefault();
            x = evt.clientX;
            y = evt.clientY;
            document.onmouseup = () => {
                document.onmouseup = onmouseup;
                document.onmousemove = onmousemove;
            };
            document.onmousemove = (evt: MouseEvent) => {
                evt.preventDefault();
                dx = x - evt.clientX;
                dy = y - evt.clientY;
                x = evt.clientX;
                y = evt.clientY;
                element.style.top = element.offsetTop - dy + "px";
                element.style.left = element.offsetLeft - dx + "px";
            };
        };
    }

    private asPercentage(x: number): string {
        return (x * 100).toFixed() + "%";
    }

    private inMilliseconds(x: string): string {
        return x + " ms";
    }

    private setValueAndDispatchInputEvent(element: HTMLInputElement, value: number) {
        element.value = value.toString();
        element.dispatchEvent(new Event("input"));
    }

    private getPadding(element: HTMLElement): {
        paddingTop: number;
        paddingRight: number;
        paddingBottom: number;
        paddingLeft: number;
    } {
        const paddingToInt = (padding?: string): number => {
            if (!padding) {
                return 0;
            }
            const n = padding.length;
            if (padding.substring(n - 1) === "%") {
                return parseInt(padding.substring(0, n - 1));
            } else if (padding.substring(n - 2) === "px") {
                return parseInt(padding.substring(0, n - 2));
            } else {
                Log.w("{50c79eb}", "{3351f27}", padding);
                return 0;
            }
        };
        return {
            paddingTop: paddingToInt(element.style.paddingTop),
            paddingRight: paddingToInt(element.style.paddingRight),
            paddingBottom: paddingToInt(element.style.paddingBottom),
            paddingLeft: paddingToInt(element.style.paddingLeft)
        };
    }
}
