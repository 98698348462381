import {
    IsiOSVersionAtLeast,
    IsiPhone,
    IsSafari,
    PlatformDetails,
    SdrHdrMode,
    StreamInfo,
    Log
} from "./dependencies";
import { RErrorCode } from "./rerrorcode";
import { RagnarokSettings } from "./util/settings";
import { IsResolution1440p, IsResolution4k, ShouldEnableCPM } from "./util/utils";

const LOGTAG = "nvstconfig";

const ENABLE_SPLIT_ENCODE = 0x1;
const ENABLE_4K_2PASS_RC = 0x2;
const FEATURE_4K_1x_VBV = 0x4;
const FEATURE_SPATIAL_AQ = 0x8;
const NVST_STC_QOS_STATS = 0x00000002;
const DFC_ALGO_VERSION_1 = 1;
const FEATURE_FAKE_ENCODE_FPS = 0x20;
const NVSC_ENC_PRESET_LOW_LATENCY_HP = 6;
const NVST_CSCM_LIMITED_YCBCR_BT2020 = 4;

export const enum NvscStreamCaptureModeFlags {
    NONE = 0x00000000,
    VIDEO = 0x00000001,
    AUDIO = 0x00000002,
    VOICE = 0x00000004
}

// Any fields added here should also be added to generateAnswerSdp and updateConfig
export interface NvstVideoConfig {
    clientViewportWd: number;
    clientViewportHt: number;
    maxFps: number;
    maximumBitrateKbps: number;
    initialBitrateKbps: number;
    initialPeakBitrateKbps: number;
    minimumBitrateKbps: number;
    mapRtpTimestampsToFrames: boolean;
    drcDfcEnabled?: boolean;
    sdrHdrMode?: SdrHdrMode;
}

export interface NvstConfig {
    video: NvstVideoConfig[];
    clientCapture: number;
}

export function getDefaultNvstVideoConfig(
    param: StreamInfo,
    maxBitrate: number,
    mapRtpTimestampsToFrames: boolean,
    drcDfcEnabled?: boolean
): NvstVideoConfig {
    const MIN_BITRATE = 4000;
    maxBitrate = Math.max(MIN_BITRATE, maxBitrate);
    const initialBitrate = Math.max(MIN_BITRATE, Math.round(maxBitrate / 4));
    return {
        clientViewportWd: param.width,
        clientViewportHt: param.height,
        maxFps: param.fps,
        drcDfcEnabled: drcDfcEnabled,
        maximumBitrateKbps: maxBitrate,
        initialBitrateKbps: initialBitrate,
        initialPeakBitrateKbps: initialBitrate,
        mapRtpTimestampsToFrames,
        minimumBitrateKbps: MIN_BITRATE,
        sdrHdrMode: param.sdrHdrMode
    };
}

export interface NvstAnswer {
    config?: NvstConfig;
    answer?: string;
    error?: RErrorCode;
}

export function handleNvstOffer(
    config: NvstConfig,
    serverSdpStr: string,
    serverOverride: string,
    platformDetails: PlatformDetails
): NvstAnswer {
    // We handle the offer in several steps:
    // 1. Parse the offer SDP
    // 2. Generate an initial answer SDP that contains all streams the server sent us and applies any modifications
    //    that we need based on NvstConfig and other client variables. The localSdp variable will only contain
    //    attributes that our client changed
    // 3. Apply overrides from RemoteConfig, server CSR/config file, and client confile text box. These all modify
    //    our localSdp
    // 4. Update our NvstConfig based on the new localSdp. This allows overrides that are intended to affect client
    //    behavior to work
    // 5. Serialize localSdp, which contains all the fields we changed, into a string to send back to the server
    const remoteSdp = parseOfferSdp(serverSdpStr);
    if (!remoteSdp) {
        return { error: RErrorCode.StreamerNvstSdpParseFailure };
    }
    const localSdp = generateAnswerSdp(remoteSdp, config, platformDetails);
    // Don't fail on remote config or server overrides. These overrides can apply to multiple server versions, some of
    // which will be too old to include the fields. For client overrides, we should fail because this can only be set
    // directly by the user, and this gives them immediate feedback that their overrides are wrong
    overrideSdp(getRemoteConfigOverride(), localSdp, remoteSdp, "remoteconfig");
    overrideSdp(serverOverride, localSdp, remoteSdp, "server");
    if (RagnarokSettings.isInternalUser) {
        if (!overrideSdp(RagnarokSettings.clientConfigOverride, localSdp, remoteSdp, "client")) {
            return { error: RErrorCode.StreamerInvalidClientOverride };
        }
    }
    if (!updateConfig(config, localSdp)) {
        return { error: RErrorCode.StreamerConfigUpdateFailure };
    }

    return {
        config,
        answer: serializeAnswer(localSdp, remoteSdp)
    };
}

const enum MediaType {
    VIDEO = "video",
    AUDIO = "audio",
    APPLICATION = "application",
    MIC = "mic"
}

type AttributeMap = Map<string, string>;

interface Media {
    mediaType: MediaType;
    msid: string;
    attributes: AttributeMap;
}

interface Sdp {
    origin: string;
    time: string;
    generalAttributes: AttributeMap;
    media: Media[];
}

function splitLines(s: string): string[] {
    return s.split(/\r?\n/);
}

// Parses the given SDP, only caring about the subset of the standard used by NVST.
// Whats implemented:
// - Validating origin (o=) and time (t=) are included so we can pass them back to the server
// - Media descriptions
// - Attributes with values
// Differences from spec:
// - a=msid is required for each media description
// - Session-level attributes don't cascade to media-level
// - m= lines are not validated; only the type is checked
// Example NVST SDP:
//   v=0
//   o=SdpTest test_id_13 14 IN IPv4 127.0.0.1
//   s=VideoStreamConfig test session
//   a=general.test:0
//   t=0 0
//   m=video 0 RTP/AVP
//   i=DeviceString, DeviceName
//   a=msid:stream_1
//   a=video.perfIndicatorEnabled:0
function parseOfferSdp(sdpStr: string): Sdp | undefined {
    let sdp: Sdp = {
        origin: "",
        time: "",
        generalAttributes: new Map<string, string>(),
        media: []
    };
    let attributes = new Map<string, string>();
    let msid: string | undefined = undefined;
    let mediaType: MediaType | undefined = undefined;
    let inMedia = false;
    const handleDescEnd = (): boolean => {
        if (inMedia) {
            if (mediaType && msid) {
                sdp.media.push({
                    mediaType,
                    msid,
                    attributes
                });
                mediaType = undefined;
                msid = undefined;
            } else {
                Log.e("{eb7c2d0}", "{adc37a7}");
                return false;
            }
        } else {
            sdp.generalAttributes = attributes;
            inMedia = true;
        }
        attributes = new Map<string, string>();
        return true;
    };

    for (const line of splitLines(sdpStr)) {
        if (line.length < 2 || line[1] !== "=") {
            continue;
        }

        const type = line[0];
        const value = line.substr(2);
        if (type == "m") {
            // A m= line marks the start of a media description. Flush all the variables we've set
            // for the current media description (or session description).
            if (!handleDescEnd()) {
                return undefined;
            }

            const parts = value.split(" ");
            mediaType = <MediaType>parts[0];
        } else if (type == "a") {
            // Attributes are of the form "a=name:value". The ":value" is optional
            const parts = value.split(":", 2);
            if (parts.length === 1) {
                // Flag attributes are valid but not used in NVST
                continue;
            }
            if (parts.length !== 2) {
                Log.e("{eb7c2d0}", "{f6bdb84}", value);
                return undefined;
            }
            const attribute = parts[0];
            const attributeValue = parts[1];
            // MSID is used by NVST to identify streams. We'll need it to generate the answer
            if (attribute === "msid") {
                if (msid === undefined) {
                    msid = attributeValue;
                } else {
                    Log.e("{eb7c2d0}", "{dc7fbec}", attributeValue);
                    return undefined;
                }
            } else {
                attributes.set(attribute, attributeValue);
            }
        } else if (type == "t") {
            if (inMedia) {
                Log.e("{eb7c2d0}", "{14fc069}");
                return undefined;
            }
            sdp.time = value;
        } else if (type == "o") {
            if (inMedia) {
                Log.e("{eb7c2d0}", "{eb0287a}");
                return undefined;
            }
            sdp.origin = value;
        }
    }

    if (!handleDescEnd()) {
        return undefined;
    }
    if (sdp.time === "" || sdp.origin === "") {
        Log.e("{eb7c2d0}", "{8e7e486}");
        return undefined;
    }

    return sdp;
}

/**
 * Apply the given override string to the local SDP, using the remote SDP to know which fields exist.
 * @returns True if all overrides were successfully applied or false if at least one override wasn't present in the
 *          server SDP
 */
function overrideSdp(override: string, localSdp: Sdp, remoteSdp: Sdp, source: string): boolean {
    const regex = /^([^[.]+)(?:\[(\d+)\])?\.([^:]+): *(.+)$/;

    // When we hit an issue, we set this to false and continue through the rest of the config instead of stopping
    let success = true;
    for (const line of splitLines(override)) {
        const groups = regex.exec(line);
        if (groups) {
            // Given "video[0].abc.def: ghi", groups would be:
            // prefix: video, index: 0, name: abc.def, value: ghi
            const prefix = groups[1];
            const index = groups[2] !== undefined ? Number.parseInt(groups[2]) : undefined;
            const name = groups[3];
            const value = groups[4];
            const path = prefix + "." + name;

            const media = getMediaTypeForPrefix(prefix);
            const localConfig = getMediaConfig(localSdp, media, index);
            const remoteConfig = getMediaConfig(remoteSdp, media, index);
            if (!localConfig || !remoteConfig) {
                Log.w("{eb7c2d0}", "{a649ad8}", source, media, index);
                success = false;
                continue;
            }
            if (!remoteConfig.has(path)) {
                Log.w("{eb7c2d0}", "{cc4fdef}", source, path, value);
                success = false;
                continue;
            }
            Log.d("{eb7c2d0}", "{2beb68f}", source, path, value);

            localConfig.set(path, value);
        }
    }
    return success;
}

function getMediaTypeForPrefix(prefix: string): MediaType | undefined {
    switch (prefix) {
        case "video":
        case "vqos":
        case "qscore":
        case "bwe":
        case "clientPerfBr":
        case "packetPacing":
            return MediaType.VIDEO;
        case "audio":
        case "aqos":
        case "audioBitrate":
            return MediaType.AUDIO;
        case "ri":
            return MediaType.APPLICATION;
        case "mic":
            return MediaType.MIC;
        default:
            return undefined;
    }
}

function setVideoProfile(
    attributes: Map<string, string>,
    video: NvstVideoConfig,
    platformDetails: PlatformDetails
) {
    // Subset of GFN gaming VCT profile that is needed for browser streaming
    // ref: https://beagle.nvidia.com/xref/gcomp_dev/src/Mjolnir/StreamSdk/source/common/config/NvscClientConfigDefaults.cpp?r=31198832#293
    attributes.set("vqos.fec.rateDropWindow", "10");
    // Set a minimum number of FEC packets to apply to a frame. This will help the problem of normal FEC percentages
    // only producing a single repair packet for small frames.
    attributes.set("vqos.fec.minRequiredFecPackets", "2");
    attributes.set("vqos.drc.minRequiredBitrateCheckEnabled", "1");
    // Enable Unified Blit for all GFN sessions
    attributes.set("video.dx9EnableNv12", "1");
    attributes.set("runtime.serverTraceCapture", NVST_STC_QOS_STATS.toString());
    attributes.set("vqos.qpg.enable", "1");
    // Enable OWD Congestion control only for GFN use case
    attributes.set("bwe.useOwdCongestionControl", "1");
    // Enable Nack only for GFN use case
    attributes.set("video.enableRtpNack", "1");
    attributes.set("vqos.bw.txRxLag.minFeedbackTxDeltaMs", "200");
    // Enable 5 groups, 1msec sleep, 10 packets packet pacing config for 1440p/4k streaming.
    attributes.set("vqos.fec.repairMinPercent", "5");
    attributes.set("vqos.fec.repairPercent", "5");
    attributes.set("vqos.fec.repairMaxPercent", "35");
    attributes.set("vqos.drc.bitrateIirFilterFactor", "18");
    // Needs to be in sync with MAXIMUM_PACKETIZATION_SIZE in WebRtcVideoRtpSender
    attributes.set("video.packetSize", "1140");
    attributes.set("packetPacing.minNumPacketsPerGroup", "15");
    attributes.set("vqos.bllFec.enable", "0");

    const b120FpsOrHigher = video.maxFps >= 120;
    const b120Fps = video.maxFps === 120;
    const b240Fps = video.maxFps === 240;

    const b1440pResolution = IsResolution1440p(video.clientViewportWd, video.clientViewportHt);
    const b4kResolution = IsResolution4k(video.clientViewportWd, video.clientViewportHt);
    const b1440pOr4kResolution = b1440pResolution || b4kResolution;
    const bShouldEnableCPM = ShouldEnableCPM(platformDetails);

    if (bShouldEnableCPM) {
        attributes.set("vqos.resControl.cpmRtc.featureMask", "3");
    }

    if (b120FpsOrHigher) {
        // Cap refresh rate to 60 to avoid allocating higher bitrates to Turbo Mode.
        //sm.RefreshRate = 60;
        attributes.set("bwe.iirFilterFactor", "8");
        attributes.set("vqos.drc.enable", "0");
        attributes.set("vqos.dfc.enable", "1");
        attributes.set("vqos.dfc.decodeFpsAdjPercent", "85");
        attributes.set("vqos.dfc.targetDownCooldownMs", "250");
        // Use DFC 1.0, which will set the minimum target fps to 60 for resolutions > 720p
        attributes.set("vqos.dfc.dfcAlgoVersion", DFC_ALGO_VERSION_1.toString());
        attributes.set("vqos.dfc.minTargetFps", "60");
        if (bShouldEnableCPM) {
            // Flag is used in DFC algo (which is only enabled in turbo mode), does not control CPM in browser client
            attributes.set("vqos.cpm.flags", "1");
        }

        attributes.set(
            "video.encoderFeatureSetting",
            (
                ENABLE_SPLIT_ENCODE |
                ENABLE_4K_2PASS_RC |
                FEATURE_4K_1x_VBV |
                FEATURE_FAKE_ENCODE_FPS |
                FEATURE_SPATIAL_AQ
            ).toString()
        );
        // Use LLHP preset for Turbo Mode sessions.
        attributes.set("video.encoderPreset", NVSC_ENC_PRESET_LOW_LATENCY_HP.toString());
        // Disallow DFC based on decode timings
        attributes.set("vqos.resControl.dfc.useClientFpsPerf", "0");
        // Control conditions when CPM can kick in
        attributes.set("vqos.resControl.cpmRtc.badNwSkipFramesCount", " 600");
        attributes.set("vqos.resControl.cpmRtc.decodeTimeThresholdMs", "9");
        if (b120Fps) {
            // Delink streamingFps from gameFps
            attributes.set("video.fbcDynamicFpsGrabTimeoutMs", "6");

            attributes.set("vqos.resControl.cpmRtc.serverResolutionUpdateCoolDownCount", "6000");
        } else if (b240Fps) {
            attributes.set("video.fbcDynamicFpsGrabTimeoutMs", "18");

            attributes.set("vqos.resControl.cpmRtc.serverResolutionUpdateCoolDownCount", "12000");
            // Disable wait for next grab when NVIDIA FRL is ON.
            attributes.set("video.enableNextCaptureMode", "1");
            // TODO: Set IIR filter factor, do not have data for it yet
            attributes.set("vqos.maxStreamFpsEstimate", "240");
            // Enable Split Encode for 240Hz sessions and allow split encode to be turned off dynamically
            // based on resolution
            attributes.set("video.videoSplitEncodeStripsPerFrame", "3");
            attributes.set("video.updateSplitEncodeStateDynamically", "1");
        }
    }

    const MAX_BITRATE_KBPS_LIMIT_FOR_1440P_BELOW_RESOLUTIONS = 42000;

    if (
        b1440pOr4kResolution ||
        video.maximumBitrateKbps >= MAX_BITRATE_KBPS_LIMIT_FOR_1440P_BELOW_RESOLUTIONS
    ) {
        if (b120Fps) {
            attributes.set("packetPacing.numGroups", "3");
        } else {
            attributes.set("packetPacing.numGroups", "5");
        }
        attributes.set("packetPacing.maxDelayUs", "1000");
        attributes.set("packetPacing.minNumPacketsFrame", "10");
        attributes.set("video.rtpNackQueueLength", "1024");
        attributes.set("video.rtpNackQueueMaxPackets", "512");
        attributes.set("video.rtpNackMaxPacketCount", "25");
        // Non-4K resolutions >= 1440p need DRC tweaks to help maintain a high resolution.
        attributes.set("vqos.drc.qpMaxResThresholdAdj", "4");
        attributes.set("vqos.grc.qpMaxResThresholdAdj", "4");
        attributes.set("vqos.drc.iirFilterFactor", "100");
        attributes.set("vqos.qpg.enable", "0");
        // 4k/1440p HEVC/AV1 uses split encode with 3-strips on ADA GPU for GFN use case.
        // TODO: Would be nice to tie to HEVC/AV1 instead, but we don't know which codec the server will use for each stream
        // It Has no effect when H264 is used, so we can set it generally here
        if (b1440pOr4kResolution) {
            attributes.set("video.videoSplitEncodeStripsPerFrame", "3");
            attributes.set("video.updateSplitEncodeStateDynamically", "1");
        }
        // 4k GFN streaming modifications
        if (b4kResolution) {
            attributes.set("video.encoderPreset", NVSC_ENC_PRESET_LOW_LATENCY_HP.toString());
            attributes.set("vqos.drc.add1440pResLevelFor4kDrcTable", "1");
            // Higher QP is generally less noticeable at high resolutions, so raise the DRC thresholds.
            attributes.set("vqos.drc.minAdaptiveQpThreshold", "40");
            attributes.set("vqos.grc.minAdaptiveQpThreshold", "40");
            attributes.set("vqos.drc.upperQpThreshold", "40");
            attributes.set("vqos.grc.upperQpThreshold", "40");
            attributes.set("vqos.drc.qpMaxResThresholdAdj", "5");
            attributes.set("vqos.grc.qpMaxResThresholdAdj", "5");
            // Be less aggressive in downgrades to help maintain 4K resolution.
            attributes.set("vqos.drc.iirFilterFactor", "100");
            // Lowering the min FEC percent leaves more bandwidth available for video.
            attributes.set("vqos.fec.repairMinPercent", "0");
            // Disable QPG for 4K resolution.
            attributes.set("vqos.qpg.enable", "0");
        }
    }
    // core streaming configs
    attributes.set("video.clientViewportWd", video.clientViewportWd.toString());
    attributes.set("video.clientViewportHt", video.clientViewportHt.toString());
    attributes.set("video.maxFPS", video.maxFps.toString());
    attributes.set("video.initialBitrateKbps", video.initialBitrateKbps.toString());
    attributes.set("video.initialPeakBitrateKbps", video.initialPeakBitrateKbps.toString());
    attributes.set("vqos.bw.maximumBitrateKbps", video.maximumBitrateKbps.toString());
    attributes.set("vqos.bw.minimumBitrateKbps", video.minimumBitrateKbps.toString());
    // OneSDK defaults to 16 ref frames, force 4 for all browser clients to keep parity with RTC SDK
    // Using 16 ref frames effectively requires special client support which is only in our native clients
    attributes.set("video.maxNumReferenceFrames", "4");
    // We will send RTP timestamps to the server so it can generate client frame numbers for us
    attributes.set("video.mapRtpTimestampsToFrames", video.mapRtpTimestampsToFrames ? "1" : "0");
    // Force CSC mode to 3 (NVST_CSCM_FULL_YCBCR_BT709) to keep parity with RTC SDK when using OneSDK
    attributes.set("video.encoderCscMode", "3");
    if (IsiPhone()) {
        attributes.set("vqos.drc.stepDownMinHeight", "480");
        // Enable x16 DRC alignment on old iOS versions. This probably isn't necessary,
        // but we can't test anything this old because QA has no phones with these versions
        if (!IsiOSVersionAtLeast(platformDetails, 14, 5)) {
            const aspect = (video.clientViewportWd / video.clientViewportHt) * 100;
            attributes.set("vqos.drc.stepDownResolutionAlignment", "16");
            attributes.set("vqos.drc.stepDownTargetAspectRatioX100", aspect.toFixed(0));
        }
    }
    if (video.drcDfcEnabled !== undefined) {
        // Configs for "adjust for poor network condition"
        // https://gitlab-master.nvidia.com/nvrtcclient/nvrtcclient/-/merge_requests/1720#note_12600645
        const enabled = video.drcDfcEnabled ? "1" : "0";
        if (b120FpsOrHigher) {
            attributes.set("vqos.dfc.adjustResAndFps", enabled);
        } else {
            attributes.set("vqos.drc.enable", enabled);
        }
    }
    // Only allow HDR via internal settings
    if (RagnarokSettings.hdr && video.sdrHdrMode === SdrHdrMode.HDR) {
        attributes.set("video.encoderCscMode", NVST_CSCM_LIMITED_YCBCR_BT2020.toString());
        attributes.set("video.dynamicRangeMode", "1");
    }
}

function generateAnswerSdp(
    remoteSdp: Sdp,
    config: NvstConfig,
    platformDetails: PlatformDetails
): Sdp {
    const localSdp: Sdp = {
        origin: remoteSdp.origin,
        time: remoteSdp.time,
        generalAttributes: new Map<string, string>(),
        media: []
    };

    localSdp.generalAttributes.set(
        "general.clientSupportsIntraRefresh",
        !IsSafari(platformDetails) ? "1" : "0"
    );
    localSdp.generalAttributes.set(
        "general.clientCapture",
        remoteSdp.generalAttributes.get("general.clientCapture") ?? "0"
    );

    let videoIdx = 0;
    for (const stream of remoteSdp.media) {
        const attributes = new Map<string, string>();
        // The server can send multiple video streams. Set the config for all video streams sent.
        if (stream.mediaType === MediaType.VIDEO && videoIdx < config.video.length) {
            setVideoProfile(attributes, config.video[videoIdx], platformDetails);
            videoIdx++;
        }
        localSdp.media.push({
            mediaType: stream.mediaType,
            msid: stream.msid,
            attributes
        });
    }
    return localSdp;
}

function updateConfig(config: NvstConfig, sdp: Sdp): boolean {
    let success = true;
    const getNumber = (attributes: AttributeMap, name: string): number => {
        const value = attributes.get(name);
        if (value) {
            const num = parseInt(value);
            if (!Number.isNaN(num)) {
                return num;
            }
        }
        Log.e("{eb7c2d0}", "{0a60213}", name);
        success = false;
        return 0;
    };
    const getBool = (attributes: AttributeMap, name: string): boolean => {
        return getNumber(attributes, name) > 0;
    };

    config.clientCapture = getNumber(sdp.generalAttributes, "general.clientCapture");

    // In situations where multiple video streams exist, update config for all streams
    let videoIdx = 0;
    for (const stream of sdp.media) {
        if (stream.mediaType === MediaType.VIDEO && videoIdx < config.video.length) {
            const video = config.video[videoIdx];
            const attributes = stream.attributes;
            video.clientViewportWd = getNumber(attributes, "video.clientViewportWd");
            video.clientViewportHt = getNumber(attributes, "video.clientViewportHt");
            video.maxFps = getNumber(attributes, "video.maxFPS");
            video.initialBitrateKbps = getNumber(attributes, "video.initialBitrateKbps");
            video.initialPeakBitrateKbps = getNumber(attributes, "video.initialPeakBitrateKbps");
            video.maximumBitrateKbps = getNumber(attributes, "vqos.bw.maximumBitrateKbps");
            video.minimumBitrateKbps = getNumber(attributes, "vqos.bw.minimumBitrateKbps");
            video.mapRtpTimestampsToFrames = getBool(attributes, "video.mapRtpTimestampsToFrames");
            videoIdx++;
        }
    }
    return success;
}

function getMediaConfig(sdp: Sdp, mediaType?: MediaType, index?: number): AttributeMap | undefined {
    if (mediaType === undefined) {
        return sdp.generalAttributes;
    } else {
        return sdp.media.filter(x => x.mediaType === mediaType)[index ?? 0]?.attributes;
    }
}

function getRemoteConfigOverride(): string {
    return RagnarokSettings.ragnarokConfig.nvscClientConfigFields?.join("\n") ?? "";
}

function serializeAnswer(localSdp: Sdp, remoteSdp: Sdp): string {
    let answer = `v=0
o=${localSdp.origin}
s=-
t=${localSdp.time}
`;
    const addConfigs = (localConfigs: AttributeMap, remoteConfigs: AttributeMap) => {
        for (const [name, value] of localConfigs) {
            if (value !== remoteConfigs.get(name)) {
                answer += `a=${name}:${value}\n`;
            }
        }
    };
    addConfigs(localSdp.generalAttributes, remoteSdp.generalAttributes);
    for (const stream of localSdp.media) {
        answer += `m=${stream.mediaType} 0 RTP/AVP\n`;
        answer += `a=msid:${stream.msid}\n`;
        const remoteMedia = remoteSdp.media.find(x => x.msid === stream.msid);
        addConfigs(stream.attributes, remoteMedia!.attributes);
    }
    return answer;
}
