// TODO revisit this and remove unnecessary methods
import { authTokenCallbackType, AuthType, AuthInfo, ErrorDetails } from "./interfaces";
import { Log } from "./utillogger";
import { PlatformDetails } from "./platform";
import { PlatformName, BrowserName, DeviceModel } from "./enumnames";
import { Scope, CreateTracingScopeType, TracingInfo } from "./tracing";
export * from "./sdp";
import { RtcUtilsSettings } from "./settings";

const LOGTAG = "utils";

export function IsXbox(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.XBOX;
}

export function IsEdge(platform: PlatformDetails): boolean {
    return platform.browser === BrowserName.EDGE;
}

export function IsXboxEdge(platform: PlatformDetails): boolean {
    return IsXbox(platform) && IsEdge(platform);
}

export function IsiOS(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.IOS;
}

export function IsiPadOS(platform: PlatformDetails): boolean {
    return platform.os === PlatformName.IPADOS;
}

export function IsiDevice(platform: PlatformDetails): boolean {
    return IsiOS(platform) || IsiPadOS(platform);
}

/**
 * Returns true if the input string is an ipv4 address format. X.X.X.X -> X:<0 - 255>
 **/
export function IsValidIPv4(ipaddress: string): boolean {
    if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            ipaddress
        )
    ) {
        return true;
    }
    return false;
}

/**
 * Returns an randomly generated number in string format for the requested length.
 **/
export function GetRandNumericString(length: number): string {
    let multiplier = 1;
    for (let x = 1; x < length; x++) {
        multiplier = multiplier * 10;
    }
    let randnum = "" + Math.floor(Math.random() * multiplier);
    randnum = randnum.padEnd(length, "0");
    return randnum;
}

/**
 * Convert a signed integer value to an unsigned integer value.
 *
 * Returns signed 2's complement for negative numbers and results in no-op for positive numbers.
 *
 * Unified error codes are represented as 32 bit unsigned integers.
 *  Because Javascript's number type is not a 32 bit integer, these codes can be sent as a negative value.
 * For example, the code 0xC1C8B0B0 is sent as -1043812176, or -0x3E374F50 in hex.  This code is converted
 * to 0xFFFFFFFFC1C8B0B0, which after extracting the rightmost 32 bits results in the original code, 0xC1C8B0B0.
 *
 * https://confluence.nvidia.com/display/GNCE/New+Schema+Format
 **/
export function convertToUnsignedInt(code: number): number {
    return code >>> 0;
}

/**
 * Returns an hex format of an integer code. The hex string starts with 0x and has 8 upper case hex format.
 **/
export function GetHexString(code: number): string {
    code = convertToUnsignedInt(code);
    let hex = ("00000000" + code.toString(16).toUpperCase()).slice(-8);
    return "0x" + hex;
}

/**  Returns true if origin service bits are set.
 **/
export function isUnifiedErrorCode(code: number): boolean {
    code = convertToUnsignedInt(code);
    const originService = code & 0x00ff0000;
    return originService !== 0;
}

/**
 * Returns true if the browser is Chromium-based, else false.
 * Intentionally true for Edge Chromium, modern Opera, etc.
 */
export function IsChromium(): boolean {
    var isChromium = (window as any).chrome;

    return !!isChromium;
}

/**
 * Returns true if the browser is Safari, else false.
 **/
export function IsSafari(platformDetails: PlatformDetails): boolean {
    return platformDetails.browser === BrowserName.SAFARI;
}

/**
 * Returns true if this is an iPhone or an iPod Touch.
 */
export function IsiPhone(platformDetails?: PlatformDetails): boolean {
    if (platformDetails) {
        return platformDetails.os === PlatformName.IOS;
    }
    return /iPhone|iPod/.test(navigator.userAgent);
}

/**
 * Returns true if this is an iPad.
 */
export function IsiPad(platformDetails?: PlatformDetails): boolean {
    if (platformDetails) {
        return platformDetails.os === PlatformName.IPADOS;
    }
    return /^(?!.*chrome).*safari/i.test(navigator.userAgent) && !IsiPhone() && IsTouchDevice();
}

/**
 * Returns true if this is a WebOS device.
 */
export function IsWebOS(platformDetails: PlatformDetails): boolean {
    return platformDetails.os === PlatformName.WEBOS;
}

export function IsChromeOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.CHROME_OS;
}

export function IsWindowsOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.WINDOWS;
}

export function IsMacOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.MAC;
}

export function IsTizenOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.TIZEN;
}

export function IsLinuxOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.LINUX;
}

export function IsAndroidOS(platformDetails: PlatformDetails) {
    return platformDetails.os === PlatformName.ANDROID;
}

export function IsSteamDeck(platformDetails: PlatformDetails) {
    return platformDetails.deviceModel === DeviceModel.STEAMDECK;
}

/**
 * Error codes return by utils lib
 */
export const enum UtilsErrorCode {
    NoNetwork = 0xc0f21001, // An http operation could not performed because system is not connected to internet.
    NetworkError = 0xc0f21002, // Network error while performing http request.
    AuthTokenNotUpdated = 0xc0f22001, //client was not able to provide the auth token.
    ResponseParseFailure = 0xc0f22003 // JSON parse error
}

/**
 * type to be used for header in performHttpRequestxxx options arg.
 */
export interface HttpRequestHeaders {
    [key: string]: string;
}

export interface RequestHttpOptions {
    method?: string;
    headers: HttpRequestHeaders;
    body?: string;
    retryCount?: number;
    timeout?: number;
    backOffDelay?: number;
    keepalive?: boolean; // only used in the context of fetchAPI
}

export interface Response {
    status: number;
    data: string;
    retries: number;
}

export interface PendingRequest extends Promise<Response> {
    abort: () => void;
}

export const DefaultHttpRequestOptions: RequestHttpOptions = {
    method: "GET",
    headers: {},
    body: "",
    retryCount: 0,
    timeout: 0,
    keepalive: false
};

const TracingComponent = {
    name: "rtcutils-core",
    version: "1.0"
};

function extractHttpRequestTags(method: string, urlString: string): Map<string, string> {
    let httpRequestTags: Map<string, string> = new Map([
        ["component", TracingComponent.name],
        ["component.version", TracingComponent.version],
        ["http.method", method],
        ["http.url", urlString],
        ["type", ""],
        ["http.hostname", ""],
        ["http.path", ""]
    ]);

    try {
        const url: URL = new URL(urlString);
        httpRequestTags.set("type", url.protocol);
        httpRequestTags.set("http.hostname", url.hostname);
        httpRequestTags.set("http.path", url.pathname);
    } catch (err) {
        Log.e("{d988e7f}", "{8dcb70c}");
    }

    return httpRequestTags;
}

function createAndTagHttpRequestScope(
    method: string,
    url: string,
    headers: HttpRequestHeaders,
    createTracingScopeCallback?: CreateTracingScopeType
): Scope | undefined {
    if (createTracingScopeCallback) {
        const scope: Scope = createTracingScopeCallback(method);
        scope.addTags(headers);

        const httpRequestTags: Map<string, string> = extractHttpRequestTags(method, url);
        for (const [key, value] of httpRequestTags) {
            scope.setTag(key, value);
        }

        return scope;
    }
    return undefined;
}

function addResultSuccessTag(status: number, scope?: Scope) {
    if (scope) {
        scope.setTag("error", "false");
        scope.setTag("http.status_code", status.toString());
    }
}

function addErrorTags(scope?: Scope) {
    if (scope) {
        scope.setTag("error", "true");
    }
}

/**
   * Performs an asynchronous http(s) request. Returns a promise which resolves when the http request completed (irrespective of http status code).
   *         Promise will be rejected in case of network errors.
   *         resolve( { status: <http status code> , data: <http response> } )
   *         reject ( { code: <integer error code> } )
   * Call the abort method on the returned Promise to abort any pending network requests and reject the Promise. The
   * rejected object will be of the form: { aborted: true }
   * @param url- http url
   * @param options - optional http method, headers and other settings:
          {method: string;       http method - GET(default), POST, PUT, DELETE
          headers: {};          Key value pairs of headers.
          body: string;         Request body for POST and PUT request.
          retryCount: number;   Number of retires in error cases.
          timeout: number;      timeout duration.
          keepalive: boolean;   Keeps the connection alive even if the tab is closed.}
   * @param tracingInfo - an optional argument used to support distributed tracing.  If provided, a new span will be created for this http request and will be included in the request headers.
  **/
export function performHttpRequest(
    url: string,
    options: RequestHttpOptions = DefaultHttpRequestOptions,
    authInfo?: AuthInfo,
    tracingInfo?: TracingInfo
): PendingRequest {
    let retryCount = options.retryCount === undefined ? 1 : options.retryCount + 1;
    const max_retry_count = retryCount;
    let aborted = false;
    let abortFetch: (() => void) | undefined = undefined;

    const backOffIncrement = options.backOffDelay ?? 500;
    let backOffTime = backOffIncrement;

    const method = options.method ?? "GET";
    const scope = createAndTagHttpRequestScope(
        method,
        url,
        options.headers,
        tracingInfo?.createTracingScopeCallback
    );
    const tracingCarrier = scope ? tracingInfo!.injectTracingScopeCallback(scope) : {};
    let requestHeaders = new Headers();
    const addReqHeaders = (authInfo?: AuthInfo) => {
        if (authInfo) {
            switch (authInfo.type) {
                case AuthType.JWT_GFN:
                    if (authInfo.token) {
                        requestHeaders.set("authorization", "GFNJWT " + authInfo.token);
                    }
                    break;
                case AuthType.JWT_PARTNER:
                    if (authInfo.token) {
                        requestHeaders.set("authorization", "GFNPartnerJWT auth=" + authInfo.token);
                    }
                    break;
            }
        }
        if (!(options.headers === undefined)) {
            Object.keys(options.headers).forEach(key => {
                requestHeaders.set(key, options.headers[key]);
            });
        }
        Object.keys(tracingCarrier).forEach(key => {
            requestHeaders.set(key, tracingCarrier[key]);
        });
    };
    addReqHeaders(authInfo);

    const promise = new Promise<Response>((resolve_, reject_) => {
        const resolve = (response: Response) => {
            addResultSuccessTag(response.status, scope);
            abortFetch = undefined;
            resolve_(response);
        };
        const reject = (x?: any) => {
            addErrorTags(scope);
            abortFetch = undefined;
            reject_(x);
        };
        const rejectAbort = () => {
            reject({
                aborted: true,
                retries: max_retry_count - retryCount - 1
            });
        };
        const performRequest = () => {
            const retryRequest = (errorCode: number): boolean => {
                if (retryCount > 0) {
                    Log.d("{d988e7f}", "{97555ed}", errorCode, retryCount);
                    window.setTimeout(performRequest, backOffTime);
                    backOffTime += backOffIncrement;
                    return true;
                }
                return false;
            };
            if (aborted) {
                rejectAbort();
                return;
            }
            if (!navigator.onLine) {
                reject({
                    code: UtilsErrorCode.NoNetwork,
                    description: "No network",
                    retries: max_retry_count - retryCount - 1
                });
            } else {
                const controller = new AbortController();
                let timeoutId: any = null;
                if (options.timeout) {
                    timeoutId = window.setTimeout(() => controller.abort(), options.timeout);
                }
                abortFetch = () => controller.abort();
                let reqInit: RequestInit = {
                    method: method,
                    headers: requestHeaders,
                    mode: "cors",
                    keepalive: options.keepalive,
                    signal: controller.signal
                };
                if (method !== "GET" && method !== "HEAD") {
                    reqInit.body = options.body;
                }
                let fetchResponseStatus: number;
                fetch(url, reqInit)
                    .then(response => {
                        retryCount--;
                        fetchResponseStatus = response.status;
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                        }
                        return response.text();
                    })
                    .then(data => {
                        const retryCodes = [503];
                        // If server returned 503 and no valid reponse, then retry
                        if (!data && retryCodes.includes(fetchResponseStatus)) {
                            if (retryCount > 0) {
                                // We have a retriable error. Throw here and retry in catch
                                throw {
                                    code: fetchResponseStatus
                                };
                            } else {
                                // We have exhautsed all re-tries. Proceed to resolve as the caller will parse the
                                // status codes and convert them to UEC (GsErrorCode/RErrorCode).
                                Log.w("{d988e7f}", "{d639e52}", fetchResponseStatus, max_retry_count);
                            }
                        }
                        resolve({
                            status: fetchResponseStatus,
                            data: data,
                            retries: max_retry_count - retryCount - 1
                        });
                    })
                    .catch(err => {
                        // Either a network/fetch error or when we explicity threw on an error
                        const errCode: number = "code" in err ? err!.code : 0;
                        if (errCode == 0) {
                            retryCount--; // decrememt on network/fetch error. Actual error cases from server are handled in then()
                            if (options.keepalive) {
                                // Bug: 3923439: https://bugs.chromium.org/p/chromium/issues/detail?id=835821
                                // Preflight request for requests with keepalive specified is not supported in Chrome version 80 and below.
                                // Note that, a failure here does not mean we hit this issue, but there is no other way to determine the
                                // reason for the failure.
                                // The 'err' will always be the generic: "TypeError: Failed to fetch" string

                                // WAR: If Fetch fails when keepalive is set to true, we set it to false before retyring.
                                options.keepalive = false;
                            }
                        }
                        // if the retry is scheduled, returns true
                        if (!retryRequest(errCode)) {
                            // Failing after max_retry_count. If we are here, it could only be for network/fetch errors
                            // For error codes received from server, we should not get into catch, as we check the retry count before throwing
                            Log.w("{d988e7f}", "{dff1a05}", max_retry_count, err);
                            reject({
                                code: UtilsErrorCode.NetworkError,
                                retries: max_retry_count - retryCount - 1
                            });
                        }
                    });
            }
        };
        performRequest();
    }).finally(() => {
        scope?.finish();
    });
    return Object.assign(promise, {
        abort: () => {
            aborted = true;
            abortFetch?.();
        }
    });
}

export const CLIENT_VERSION = "24.0";
export const CLIENT_IDENTIFICATION = "GFN-PC";

function mapPlatformToUserAgent(platform: string) {
    let str = platform;
    switch (platform) {
        case PlatformName.MAC:
            str = "MacOSX";
            break;
        case PlatformName.IPADOS:
            str = PlatformName.IOS;
            break;
        default:
            str = platform;
    }
    return str;
}

export function IsTouchDevice(): boolean {
    // If the primary mode of pointer input is 'coarse' we are very likely on a mostly-touch or touch-only device.
    // TODO Consider TV systems that use an on-screen pointer, which could easily be 'coarse' as well.
    return window.matchMedia?.("(pointer:coarse)")?.matches;
}

export function IsTouchCapable(): boolean {
    // If any pointer input is 'coarse', then touch is a possible but not a primary pointer input.
    // Do not use this to enable the TouchFriendly app launch mode.
    return !!(window as any)["TouchEvent"] && window.matchMedia?.("(any-pointer:coarse)")?.matches;
}

export function IsTV(platformDetails: PlatformDetails): boolean {
    // TODO Exclude Tizen mobile devices from this check
    return platformDetails.os === PlatformName.TIZEN || platformDetails.os === PlatformName.WEBOS;
}

export function getNewGuid(): string {
    let array = new Uint8Array(36);
    window.crypto.getRandomValues(array);
    let guid = "";

    for (let i = 0; i < 36; i++) {
        let randomNumber = array[i] % 16;
        if (i == 8 || i == 13 || i == 18 || i == 23) {
            guid += "-";
        } else if (i == 14) {
            guid += "4";
        } else if (i == 19) {
            randomNumber = (randomNumber & 0x3) | 0x8;
            guid += randomNumber.toString(16);
        } else {
            guid += randomNumber.toString(16);
        }
    }
    Log.i("{d988e7f}", "{534eaf4}", guid);
    return guid;
}

// Check each part individually to see which is greater
// If they are equal, move on to the next part until we've checked all
function VersionListIsAtLeast(actual: number[], desired: number[]): boolean {
    for (let i = 0; i < desired.length && i < actual.length; ++i) {
        const actualPart = actual[i];
        const desiredPart = desired[i];
        if (actualPart > desiredPart) {
            return true;
        } else if (actualPart < desiredPart) {
            return false;
        }
    }
    return true;
}

export function IsChromeBrowser(platformDetails: PlatformDetails) {
    return platformDetails.browser == BrowserName.CHROME;
}

export function IsChromeVersionAtLeast(
    platformDetails: PlatformDetails,
    major: number,
    minor?: number,
    build?: number,
    patch?: number
): boolean {
    if (platformDetails.browser != BrowserName.CHROME) {
        return false;
    }
    const desired = [major, minor ?? 0, build ?? 0, patch ?? 0];
    try {
        // The chrome version is split into 4 parts: w.x.y.z
        const actual = platformDetails.browserFullVer.split(".").map(x => Number.parseInt(x));
        return VersionListIsAtLeast(actual, desired);
    } catch (_ex) {
        Log.w("{d988e7f}", "{bcd8f94}");
    }
    // Return true even if we fail to parse the version. This probably means a newer version of Chrome that
    // changed the version format.
    return true;
}

// Check the Safari application version
export function IsSafariVersionAtLeast(
    platformDetails: PlatformDetails,
    major: number,
    minor?: number,
    patch?: number
): boolean {
    if (platformDetails.browser != BrowserName.SAFARI) {
        return false;
    }

    const desired = [major, minor ?? 0, patch ?? 0];
    try {
        // The Safari version is split into 3 parts: major.minor.patch
        const actual = platformDetails.browserFullVer.split(".").map(x => Number.parseInt(x));
        return VersionListIsAtLeast(actual, desired);
    } catch (_ex) {
        Log.w("{d988e7f}", "{07fd1dd}");
    }
    // Return true even if we fail to parse the version. This probably means a newer version of Safari that
    // changed the version format.
    return true;
}

/**
 * @param platformDetails - interface: PlatformDetails
 * @param exact - If true, match the exact OS version.  Otherwise, check if OS version is at least the version provided
 * @param major - OS major version
 * @param minor - OS minor version [optional]
 **/
function IsiOSVersionHelper(
    platformDetails: PlatformDetails,
    exact: boolean,
    major: number,
    minor?: number
): boolean {
    if (!IsiDevice(platformDetails)) {
        return false;
    }

    const desired = [major, minor ?? 0];
    try {
        const numericalVersion = platformDetails.osVer.replace(/[^0-9.]/, "");
        const actual = numericalVersion.split(".").map(x => Number.parseInt(x));
        if (actual.length === 1) {
            actual.push(0);
        }
        if (exact) {
            return actual.every((val, index) => val === desired[index]);
        } else {
            return VersionListIsAtLeast(actual, desired);
        }
    } catch (_ex) {
        Log.w("{d988e7f}", "{cf4149f}");
    }
    // Return true even if we fail to parse the version, unless matching exact version. This probably means a newer version of Safari that
    // changed the version format.
    return exact ? false : true;
}

/**
 * @param platformDetails - interface: PlatformDetails
 * @param major - OS major version
 * @param minor - OS minor version [optional]
 * @returns true if OS version matches the major and minor version provided, false otherwise
 **/
export function IsiOSVersion(
    platformDetails: PlatformDetails,
    major: number,
    minor?: number
): boolean {
    return IsiOSVersionHelper(platformDetails, true, major, minor);
}

/**
 * @param platformDetails - interface: PlatformDetails
 * @param major - OS major version
 * @param minor - OS minor version [optional]
 * @returns true if OS version is at least the major and minor version provided, false otherwise
 **/
export function IsiOSVersionAtLeast(
    platformDetails: PlatformDetails,
    major: number,
    minor?: number
): boolean {
    return IsiOSVersionHelper(platformDetails, false, major, minor);
}

// TODO: Rework the interfaces and use of fetch/XMLHttpRequest to use standard types
//       Raname the Response type used by performRequest() to be more specific
//       Use the standard Response type for customFetch(), and delete the two interfaces below

// interface Body {
//     readonly body: ReadableStream<Uint8Array> | null;
//     readonly bodyUsed: boolean;
//     arrayBuffer(): Promise<ArrayBuffer>;
//     blob(): Promise<Blob>;
//     formData(): Promise<FormData>;
//     json(): Promise<any>;
//     text(): Promise<string>;
// }

export interface FetchResponse {
    //    readonly headers: Headers;
    //    readonly ok: boolean;
    //    readonly redirected: boolean;
    readonly status: number;
    //    readonly statusText: string;
    //    readonly trailer: Promise<Headers>;
    //    readonly type: ResponseType;
    //    readonly url: string;
    //    clone(): FetchResponse;
}

export function customFetch(url: string, timeoutMs = 500, data = {}): Promise<FetchResponse> {
    const controller = new AbortController();
    window.setTimeout(() => {
        controller.abort();
    }, timeoutMs);
    return fetch(url, { ...data, signal: controller.signal })
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
}

export function getRErrorDetails(
    code: number,
    description?: string,
    error?: Error | DOMException | null
): ErrorDetails {
    return {
        code,
        description,
        error: error !== null ? error : undefined
    };
}
