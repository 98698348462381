import { LatencyIndicator } from "../debug/latencyindicator";

/**
 * All buttons which virtual gamepad can have.
 */
export const enum VirtualButton {
    A = 0x1000,
    B = 0x2000,
    X = 0x4000,
    Y = 0x8000,
    LB = 0x0100,
    RB = 0x0200,
    LT = 0xff,
    RT = 0xff00,
    BACK = 0x0020,
    START = 0x0010,
    STICK_LEFT = 0x0040,
    STICK_RIGHT = 0x0080,
    DPAD_UP = 0x0001,
    DPAD_DOWN = 0x0002,
    DPAD_LEFT = 0x0004,
    DPAD_RIGHT = 0x0008
}

/**
 * VirtualGamepad represents the storage for virtual gamepad events passed
 * from client.
 */
export interface VirtualGamepad {
    v_index: number;
    v_enabled: boolean;
    v_connected: boolean;
    v_buttons: number;
    v_trigger: number;
    v_axes: number[];
    v_updated: boolean;
}

/**
 * This is the virtual gamepad interface for client. Gridapp provides the
 * reference of this class for clients to enable/disable and pass input.
 *
 * The interface defines the protocol to interact with VirtualGamepadHandler.
 * The actual instance could be obtained by using the call:
 * const virtualGamepadHandler: VirtualGamepadHandler = gridApp.getVirtualGamepadHandler();
 *
 * We need this way for client to pass gamepad input because we want to keep
 * all UI things out of the Ragnarok library.
 */
interface IVirtualGamepadHandler {
    /**
     * Client should call this when virtual gamepad UI is shown.
     */
    enable(): void;

    /**
     * Client should call this when virtual gamepad UI is hidden.
     */
    disable(): void;

    /**
     * Clients should call this when virtual gamepad buttons/triggers/axes are
     * pressed or released, and virtual gamepad input is to be sent to server.
     * Clients should pass all valid arguments and must clear all values to
     * return to controls to a neutral state.
     * @param buttons - buttons that need to be updated. Accept multiple buttons at a time.
     * @param trigger - triggers that need to be updated. Accept multiple triggers at a time.
     * @param axes - axes that need to be updated. Accept mutiple axes at a time.
     */
    updateInput(buttons: number, trigger: number, axes: number[]): void;
}

export class VirtualGamepadHandler implements IVirtualGamepadHandler {
    private virtualGamepad: VirtualGamepad;
    constructor(virtualGamepad: VirtualGamepad) {
        this.virtualGamepad = virtualGamepad;
    }

    enable() {
        this.virtualGamepad.v_enabled = true;
    }

    disable() {
        this.virtualGamepad.v_enabled = false;
    }

    updateInput(buttons: number, trigger: number, axes: number[]) {
        if (this.virtualGamepad.v_enabled) {
            LatencyIndicator.getInstance().toggleIndicator();

            this.virtualGamepad.v_buttons = buttons;
            this.virtualGamepad.v_trigger = trigger;
            this.virtualGamepad.v_axes = axes;
            this.virtualGamepad.v_updated = true;
        }
    }
}
