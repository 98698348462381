import { EventEmitter } from "./eventemitter";
import { LogEvent } from "./interfaces";

export class LogQueueingEventEmitter extends EventEmitter {
    private logEventName: string;
    private logQueue: LogEvent[] = [];

    constructor(logEventName: string, emitSynchronously?: boolean) {
        super(emitSynchronously);
        this.logEventName = logEventName;
    }

    protected onLogEvent(logEvent: LogEvent) {
        if (this.hasListener(this.logEventName)) {
            this.emit(this.logEventName, logEvent);
        } else {
            this.logQueue.push(logEvent);
        }
    }

    public addListener(eventname: string, handler: Function) {
        super.addListener(eventname, handler);
        if (eventname == this.logEventName && this.logQueue.length > 0) {
            for (const logevent of this.logQueue) {
                this.emit(this.logEventName, logevent);
            }
            this.logQueue = [];
        }
    }
}
