import { GdprLevel, BooleanType } from "../dependencies";
import { NetworkDetector } from "../util/networkdetector";
import { ToBooleanType } from "../util/utils";

/**
 *Boolean type as understood in eventSchema
 */
const enum ModuleName {
    Unknown = "Unknown",
    Ragnarok = "Ragnarok"
}

/**
 *This is the base interface for telemetry event sent with event name EVENTS.ANALYTICS_EVENT
 *Based on name, client will know what event it is, before sending event to telemetry server
 * client will also have to append parameters which are sent with every event like osName, userId etc.
 */
export declare interface TelemetryEvent {
    readonly name: string;
    readonly gdprLevel: GdprLevel;
    // @todo make these params required once telemetry completely moves to ragnarok
    ts?: string;
}

export declare interface CommonParameters {
    sessionId: string;
    subSessionId: string;
    osName: string;
    osVersion: string;
    gameShortName: string;
}

/**
 *Event interface for Http events sent for all non- peroidic http calls in library
 */
export declare interface RagnarokHttpEventParameters extends CommonParameters {
    url: string;
    verb: string;
    statusCode: string;
    requestStatusCode: string;
    requestId: string;
    serverId: string;
    callDuration: number;
}

export declare interface RagnarokHttpEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokHttpEventParameters;
    // below fields are deprecated
    url: string;
    verb: string;
    statusCode: string;
    requestStatusCode: string;
    sessionId: string;
    subSessionId: string;
    requestId: string;
    serverId: string;
    callDuration: number;
}

/**
 *Event interface for Gamepad events sent for in library
 */
export declare interface RagnarokGamepadEventParameters extends CommonParameters {
    gamepadName: string;
    vid: string;
    pid: string;
    index: number;
    hapticsSupported: BooleanType;
    hapticsFeedbackCount: number;
    primary: BooleanType;
    state: number;
    eventMap: string;
}

export declare interface RagnarokGamepadEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokGamepadEventParameters;
    // below fields are deprecated
    gamepadName: string;
    vid: string;
    pid: string;
    index: number;
    hapticsSupported: BooleanType;
    hapticsFeedbackCount: number;
    primary: BooleanType;
    state: number;
    eventMap: string;
    sessionId: string;
    subSessionId: string;
}

/**
 *Launch event sent when game launch attempt is made.
 */
export declare interface RagnarokLaunchEventParameters extends CommonParameters {
    result: string;
    zoneAddress: string;
    launchDuration: number;
    isResume: BooleanType;
    codec: string;
    cmsId: string;
    streamingProfileGuid: string;
    systemInfoGuid: string;
    networkSessionId: string;
}

export declare interface RagnarokLaunchEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokLaunchEventParameters;
    // below fields are deprecated
    result: string;
    sessionId: string;
    subSessionId: string;
    zoneAddress: string;
    launchDuration: number;
    isResume: BooleanType;
    codec: string;
    cmsId: string;
}

export declare interface RagnarokStreamExitEventParameters extends CommonParameters {
    result: string;
    zoneAddress: string;
    streamDuration: number;
    frameCount: number;
    isResume: BooleanType;
    codec: string;
    connectivity: string;
    sleep: BooleanType;
    cmsId: string;
    streamingProfileGuid: string;
    systemInfoGuid: string;
    networkSessionId: string;
}

/**
 *Exit event sent when session ends
 */
export declare interface RagnarokStreamExitEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokStreamExitEventParameters;
    // below fields are deprecated
    result: string;
    sessionId: string;
    subSessionId: string;
    zoneAddress: string;
    streamDuration: number;
    frameCount: number;
    isResume: BooleanType;
    codec: string;
    connectivity: string;
    sleep: BooleanType;
    cmsId: string;
}

/**
 * Ragnarok-internal debug event
 */
export declare interface RagnarokDebugEventParameters extends CommonParameters {
    key1: string;
    key2: string;
    key3: string;
    key4: string;
    key5: string;
}

export declare interface RagnarokDebugEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokDebugEventParameters;
    // below fields are deprecated
    sessionId: string;
    subSessionId: string;
    key1: string;
    key2: string;
    key3: string;
    key4: string;
    key5: string;
}

/**
 *Exception event referring caught/uncaught exceptions during session
 */
export declare interface RagnarokExceptionEventParameters extends CommonParameters {
    message: string;
    stacktrace: string;
    filename: string;
    lineno: number;
    colno: number;
    handled: BooleanType;
    category: string;
}

export declare interface RagnarokExceptionEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokExceptionEventParameters;
    // below fields are deprecated
    sessionId: string;
    subSessionId: string;
    message: string;
    stacktrace: string;
    filename: string;
    lineno: number;
    colno: number;
    handled: BooleanType;
    category: string;
}

/**
 *Sleep event sent when game ends due to system sleep.
 */
export declare interface RagnarokSleepEventParameters extends CommonParameters {
    error: string;
    sleepTime: number;
    timeToSleep: number;
    eventSequence: string;
}

export declare interface RagnarokSleepEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: RagnarokSleepEventParameters;
    // below fields are deprecated
    error: string;
    sessionId: string;
    subSessionId: string;
    sleepTime: number;
    timeToSleep: number;
    eventSequence: string;
}

/**
 *Ragnarok Client metric event.
 */
export declare interface ClientMetricEventParameters extends CommonParameters {
    metricName: string;
    module: ModuleName;
    valueString: string;
    valueDouble: number;
    valueInt1: number;
    valueInt2: number;
    valueInt3: number;
    networkType: string;
}

export declare interface ClientMetricEvent extends TelemetryEvent {
    // @todo make this param required once telemetry completely moves to ragnarok and client has removed all interface references
    parameters?: ClientMetricEventParameters;
    // below fields are deprecated
    sessionId: string;
    subSessionId: string;
    metricName: string;
    module: ModuleName;
    valueString: string;
    valueDouble: number;
    valueInt1: number;
    valueInt2: number;
    valueInt3: number;
}

export const enum EventName {
    RAGNAROK_HTTP_EVENT = "Ragnarok_Http_Event",
    RAGNAROK_LAUNCH_EVENT = "Ragnarok_Launch_Event",
    RAGNAROK_STREAM_EXIT_EVENT = "Ragnarok_Stream_Exit_Event",
    RAGNAROK_EXCEPTION_EVENT = "Ragnarok_Exception_Event",
    RAGNAROK_DEBUG_EVENT = "Ragnarok_Debug_Event",
    RAGNAROK_SLEEP_EVENT = "Ragnarok_Sleep_Event",
    RAGNAROK_GAMEPAD_EVENT = "RagnarokGamepadEvent",
    CLIENT_METRIC_EVENT = "ClientMetricEvent" // schema styling has changed to this form now
}

export const enum Connectivity {
    UNDEFINED = "undefined",
    ONLINE = "online",
    OFFLINE = "offline",
    OFFLINE_WRONG_STATUS = "offline_wrong_status",
    TIMEOUT = "timeout"
}

export function getRagnarokExceptionEvent(
    sessionId: string,
    subSessionId: string,
    message: string,
    stacktrace: string,
    filename: string,
    lineno: number,
    colno: number,
    handled: boolean,
    category?: string
): RagnarokExceptionEvent {
    return {
        name: EventName.RAGNAROK_EXCEPTION_EVENT,
        gdprLevel: GdprLevel.Technical,
        parameters: {
            sessionId,
            subSessionId,
            message,
            stacktrace,
            filename,
            lineno,
            colno,
            handled: ToBooleanType(handled),
            category: category ?? "",
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: ""
        },
        ts: new Date().toISOString(),
        sessionId,
        subSessionId,
        message,
        stacktrace,
        filename,
        lineno,
        colno,
        handled: ToBooleanType(handled),
        category: category ?? ""
    };
}

export function getRagnarokHttpEvent(
    url: string,
    verb: string,
    statusCode: string,
    requestStatusCode: string,
    sessionId: string,
    subSessionId: string,
    requestId: string,
    serverId: string,
    callDuration: number
): RagnarokHttpEvent {
    return {
        name: EventName.RAGNAROK_HTTP_EVENT,
        gdprLevel: GdprLevel.Functional,
        parameters: {
            url,
            verb,
            statusCode,
            requestStatusCode,
            sessionId,
            subSessionId,
            requestId,
            serverId,
            callDuration,
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: ""
        },
        ts: new Date().toISOString(),
        url,
        verb,
        statusCode,
        requestStatusCode,
        sessionId,
        subSessionId,
        requestId,
        serverId,
        callDuration
    };
}

export function getRagnarokStreamExitEvent(
    result: string,
    sessionId: string,
    subSessionId: string,
    zoneAddress: string,
    streamDuration: number,
    frameCount: number,
    codec: string,
    isResume: boolean,
    osName: string,
    osVersion: string,
    gameShortName: string,
    streamingProfileGuid: string,
    systemInfoGuid: string,
    cmsId?: string,
    connectivity?: string,
    sleep?: boolean,
    networkTestSessionId?: string
): RagnarokStreamExitEvent {
    return {
        name: EventName.RAGNAROK_STREAM_EXIT_EVENT,
        gdprLevel: GdprLevel.Functional,
        parameters: {
            result,
            sessionId,
            subSessionId,
            zoneAddress,
            streamDuration: Math.round(streamDuration),
            frameCount,
            codec,
            isResume: ToBooleanType(isResume),
            connectivity: connectivity ?? Connectivity.UNDEFINED,
            sleep: ToBooleanType(sleep),
            cmsId: cmsId ?? "",
            osName,
            osVersion,
            gameShortName,
            streamingProfileGuid,
            systemInfoGuid,
            networkSessionId: networkTestSessionId ?? ""
        },
        ts: new Date().toISOString(),
        result,
        sessionId,
        subSessionId,
        zoneAddress,
        streamDuration: Math.round(streamDuration),
        frameCount,
        isResume: ToBooleanType(isResume),
        codec,
        connectivity: connectivity ?? Connectivity.UNDEFINED,
        sleep: ToBooleanType(sleep),
        cmsId: cmsId ?? ""
    };
}

export function getRagnarokDebugEvent(
    sessionId: string,
    subSessionId: string,
    key1?: string,
    key2?: string,
    key3?: string,
    key4?: string,
    key5?: string
): RagnarokDebugEvent {
    return {
        name: EventName.RAGNAROK_DEBUG_EVENT,
        gdprLevel: GdprLevel.Technical,
        parameters: {
            sessionId,
            subSessionId,
            key1: key1 ?? "",
            key2: key2 ?? "",
            key3: key3 ?? "",
            key4: key4 ?? "",
            key5: key5 ?? "",
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: ""
        },
        ts: new Date().toISOString(),
        sessionId,
        subSessionId,
        key1: key1 ?? "",
        key2: key2 ?? "",
        key3: key3 ?? "",
        key4: key4 ?? "",
        key5: key5 ?? ""
    };
}

export function getRagnarokSleepEvent(
    sessionId: string,
    subSessionId: string,
    error: string,
    sleepTime: number,
    timeToSleep: number,
    eventSequence: string
): RagnarokSleepEvent {
    return {
        name: EventName.RAGNAROK_SLEEP_EVENT,
        gdprLevel: GdprLevel.Technical,
        parameters: {
            sessionId,
            subSessionId,
            error,
            sleepTime,
            timeToSleep,
            eventSequence,
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: ""
        },
        ts: new Date().toISOString(),
        sessionId,
        subSessionId,
        error,
        sleepTime,
        timeToSleep,
        eventSequence
    };
}

export function getRagnarokGamepadEvent(
    sessionId: string,
    subSessionId: string,
    gamepadName: string,
    vid: string,
    pid: string,
    index: number,
    hapticsSupported: boolean,
    hapticsFeedbackCount: number,
    primary: boolean,
    state: number,
    eventMap: string
): RagnarokGamepadEvent {
    return {
        name: EventName.RAGNAROK_GAMEPAD_EVENT,
        gdprLevel: GdprLevel.Technical,
        parameters: {
            sessionId,
            subSessionId,
            gamepadName,
            vid,
            pid,
            index,
            hapticsSupported: ToBooleanType(hapticsSupported),
            hapticsFeedbackCount,
            primary: ToBooleanType(primary),
            state,
            eventMap,
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: ""
        },
        ts: new Date().toISOString(),
        sessionId,
        subSessionId,
        gamepadName,
        vid,
        pid,
        index,
        hapticsSupported: ToBooleanType(hapticsSupported),
        hapticsFeedbackCount,
        primary: ToBooleanType(primary),
        state,
        eventMap
    };
}

export function getClientMetricEvent(
    sessionId: string,
    subSessionId: string,
    metricName: string,
    valueString: string,
    valueDouble: number,
    valueInt1: number,
    valueInt2: number,
    valueInt3: number
): ClientMetricEvent {
    return {
        name: EventName.CLIENT_METRIC_EVENT,
        gdprLevel: GdprLevel.Functional,
        parameters: {
            module: ModuleName.Ragnarok,
            sessionId,
            subSessionId,
            metricName,
            valueString,
            valueDouble,
            valueInt1,
            valueInt2,
            valueInt3,
            osName: "", // These common fields are intentionally left blank here as they will be set in telemetry eventprocesssor.
            osVersion: "",
            gameShortName: "",
            networkType: NetworkDetector.getNetworkType()
        },
        ts: new Date().toISOString(),
        module: ModuleName.Ragnarok,
        sessionId,
        subSessionId,
        metricName,
        valueString,
        valueDouble,
        valueInt1,
        valueInt2,
        valueInt3
    };
}
