import { StreamClient } from "../streamclient";
import { TelemetryHandler } from "../telemetry/telemetryhandler";
import {
    IEventEmitter,
    IsWindowsOS,
    IsChromeOS,
    IsiDevice,
    IsMacOS,
    PlatformDetails,
    IsLinuxOS,
    IsTizenOS,
    IsAndroidOS,
    IsChromium,
    IsTouchDevice,
    IsChromeVersionAtLeast,
    IsTV,
    IsWebOS,
    BrowserName,
    Log
} from "../dependencies";
import { GestureDetector, GestureHandler, TouchRecord } from "./gesturedetector";
import { TouchListener } from "./touchlistener";
import {
    EVENTS,
    RNotificationCode,
    StreamingEvent,
    InputConfigFlags,
    CursorType,
    Zoneless,
    TextCompositionEvent,
    InputType
} from "../interfaces";
import { RagnarokProfiler } from "../ragnarokprofiler";
import { VirtualGamepadHandler } from "./virtualgamepad";
import { VIRTUAL_KEYS, CODE_TO_VK_MAP, KEY_TO_VK_MAP, CHAR_TO_VK_MAP } from "./keycodes";
import { RagnarokSettings } from "../util/settings";
import { InputChannel, InputMediaElement } from "./inputinterfaces";
import { PacketId, InputModifierFlags, LockKeyBitMask } from "./inputpacketinfo";
import { GamepadHandler } from "./gamepadhandler";
import { LatencyIndicator } from "../debug/latencyindicator";
import { GamepadTester } from "../debug/gamepadtester";
import { InputPacketHandler, KBEvents, MoveEventBuffer } from "./inputpackethandler";
import { GamepadRSDMM } from "./gamepadrsdmm";
import { BoundaryPair, IStreamCallbacks, VideoState } from "../rinterfaces";
import { SafeZoneHandler } from "../safezonehandler";
import { SupportsPointerEvents } from "../util/utils";
import { IMouseFilter, MouseFilter, NullMouseFilter } from "./mousefilter";

const LOGTAG = "inputhandler";

interface Cursor {
    imageStr: string;
    hotspotX: number;
    hotspotY: number;
    mimeTypeStr: string;
}

interface CursorExt extends Cursor {
    imageElement?: HTMLImageElement;
    scale: number;
}

interface CursorState {
    absPositioning: boolean;
    confined: boolean;
    // X coordinate of the cursor, in display-video space
    absX: number;
    // Y coordinate of the cursor, in display-video space
    absY: number;
    // X offset to be added to next relative movement, in display physical space
    remX: number;
    // Y offset to be added to next relative movement, in display physical space
    remY: number;
    style: string;
    imagePendingDecode?: HTMLImageElement;
    image?: HTMLImageElement;
    hotspotX: number;
    hotspotY: number;
    width: number;
    height: number;
    scale: number;
}

interface CursorCanvasState {
    dpiRatio: number;
    cursorScaling: number;
    graphicsContext?: CanvasRenderingContext2D;
    dirty: boolean;
    imageChanged: boolean;
    imageDimensionsChanged: boolean;
    showing: boolean;
}

interface LocalStatistics {
    rafTime: number;
    droppedVideoFrames: number;
    totalVideoFrames: number;
}

const zIndexDefault = "200";

const defaultCursor: Cursor = {
    imageStr:
        "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYAAAAOAAAADAAAABwAAAAYAAAAOAAABDAAAAZwAAAHYAAAB4AAAAf+AAAH/AAAB/gAAAfwAAAH4AAAB8AAAAeAAAAHAAAABgAAAAQAAAAAAAAAAAAAAAAAAAA////////////////////////////////////////////5////8P///+D////h////wf//98P///OD///xh///8Af///AP///wAH//8AD///AB///wA///8Af///AP///wH///8D////B////w////8f////P////3/////////8=",
    hotspotX: 2,
    hotspotY: 1,
    mimeTypeStr: "image/x-icon"
};
const Cursors: Cursor[] = [
    {
        // None - shouldn't ever get used
        imageStr: "",
        hotspotX: 0,
        hotspotY: 0,
        mimeTypeStr: ""
    },
    // Default - pointer
    defaultCursor,
    {
        // I-beam
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfvwAAAAAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAfPwAAAAAAAAAAAAAAAAAA/////////////////////////////////////+BA///Agf///z////8/////P////z////8/////P////z////8/////P////z////8/////P////z////8/////P////z////8/////P////z///+BA///Agf////////////8=",
        hotspotX: 8,
        hotspotY: 13,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Wait
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/8AAAzzAAAHDgAABfoAAAb2AAADbAAAAfgAAABgAAAAYAAAAGAAAABgAAAAYAAAAGAAAABgAAAB+AAAA/wAAAaWAAAH/gAABWoAAA//AAAP/wAAAAAAAAAAAAA//////////////////////////////////////////+AAf//gAH//4AB//+AAf//wAP//8AD///gB///8A////gf///8P////D////w////8P////D////gf///wD///4Af//8AD///AA///gAH//4AB//+AAf//gAH///////8=",
        hotspotX: 7,
        hotspotY: 12,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Crosshair
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQAAABjAAABgMAAAQBAAAIAIAACACAABAAQAAAIAAAEABAAAgAgAAIAIAABgMAAAICAAABjAAAAFAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////4////5T///53P//+97///fff//333//79+//+AAP//v37//999///fff//53P///d3///5T////j////////8=",
        hotspotX: 8,
        hotspotY: 8,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Wait arrow
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD4AAAAiAAAAPgAAABwAAAYIAAAOCAAADAgAABwcAAAYNgAAOCoABDA+AAZwAAAHYAAAB4AAAAf+AAAH/AAAB/gAAAfwAAAH4AAAB8AAAAeAAAAHAAAABgAAAAQAAAAAAAAAAAAAAAAAAAA///////////////////////+A////gP///4D///+A///5wf//8OP//+Dj///h4///wcH/98OA//ODgP/xh4D/8AeA//AP///wAH//8AD///AB///wA///8Af///AP///wH///8D////B////w////8f////P////3/////////8=",
        hotspotX: 2,
        hotspotY: 1,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Size up-left down-right (NW-SE)
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP4AAAB+AAAAPgAAAB4AAABuAAAA5gAAAcIAAEOAAABnAAAAdgAAAHgAAAB8AAAAfgAAAH8AAAAAAAAAAAAAA/////////////////////////////////////////////////////////////////////////////////wA///+AP///wD///+A////gP///wD//34A//88GP//GDz//wB+//8A////Af///wH///8A////AH///wA////////8=",
        hotspotX: 9,
        hotspotY: 8,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Size up-right down-left (NE-SW)
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfwAAAH4AAAB8AAAAeAAAAHYAAABnAAAAQ4AAAAHCAAAA5gAAAG4AAAAeAAAAPgAAAH4AAAD+AAAAAAAAAAAAA////////////////////////////////////////////////////////////////////////////////wA///8Af///AP///wH///8B////AP///wB+//8YPP//PBj//34A////AP///4D///+A////AP///gD///wA///////8=",
        hotspotX: 9,
        hotspotY: 9,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Size left right (E-W)
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAEAABgBgAA4AcAAeAHgAPv98AH7/fgA+/3wAHgB4AA4AcAAGAGAAAgBAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////+/3///P8///j/H//w/w//4P8H/8AAA/+AAAH/AAAA/4AAAf/AAAP/4P8H//D/D//4/x///P8///7/f//////8=",
        hotspotX: 13,
        hotspotY: 8,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Size up down (N-S)
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAADgAAAB8AAAA/gAAAf8AAAP/gAAAAAAAADgAAAA4AAAAOAAAADgAAAA4AAAAOAAAADgAAAA4AAAAAAAAA/+AAAH/AAAA/gAAAHwAAAA4AAAAEAAAAAAAAAAAAAA//////////////////////////////////////+/////H////g////wH///4A///8AH//+AA///AAH///g////4P///+D////g////4P///+D////g////4P///AAH//4AD///AB///4A////Af///4P////H////7////////8=",
        hotspotX: 9,
        hotspotY: 12,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Size all (move)
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAABwAAAA+AAAAHAAAABwAAAAcAAAAHAAAABwAAAgACAAf3fwAP93+AB/d/AAIAAgAABwAAAAcAAAAHAAAABwAAAAcAAAAPgAAABwAAAAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/////////////////////////////f////j////wf///4D///8Af///wf///8H///vB7//zwef/4AAD/8AAAf+AAAD/wAAB/+AAA//zwef/+8Hv///B////wf///wB///+A////wf///+P////3///////////////////////8=",
        hotspotX: 13,
        hotspotY: 12,
        mimeTypeStr: "image/x-icon"
    },
    // No - slashed circle, crossbones.  Use default instead
    defaultCursor,
    {
        // Hand
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH+AAAB/gAAA/4AAAP/AAAH/wAAB/8AAA//gAAP/4AAH/+AADttgABzbYAAY2wAAANsAAADYAAAAwAAAAMAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////+Af///AD///wA///4AP//+AB///AAf//wAH//4AA//+AAP//AAD//gAA//wAAP/8IAH//mAH///gD///4H///+H////z////////8=",
        hotspotX: 8,
        hotspotY: 3,
        mimeTypeStr: "image/x-icon"
    },
    {
        // Help - arrow w/ question mark
        imageStr:
            "AAABAAEAICACAAEAAQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAGAAAAAAAAAAAAAAAAAAAABgAAAAYAAAYHAAAOAYAADADAABwwwAAYMMAAODDABDAZgAZwDwAHYAAAB4AAAAf+AAAH/AAAB/gAAAfwAAAH4AAAB8AAAAeAAAAHAAAABgAAAAQAAAAAAAAAAAAAAAAAAAA/////////////+f////D////5//////////n////w///58P//8PB//+D4P//hzB//wYYf98OGH/ODhh/xh8A/8Afgf/AP8P/wAH//8AD///AB///wA///8Af///AP///wH///8D////B////w////8f////P////3/////////8=",
        hotspotX: 2,
        hotspotY: 1,
        mimeTypeStr: "image/x-icon"
    }
];

export interface Measurements {
    baseTime: number;
    baseTotalVideoFrames: number;
    animationFrameCount: number;
    sendInputCount: number;
    sendInputOver5ms: number;
    sendInputOver10ms: number;
    singleDroppedFrames: number;
    multiDroppedFrames: number;
    aggregatedCount: number;
    oversizedEventCount: number;
}

const enum RawUpdateState {
    OFF = 0,
    ON_4MS,
    ON_8MS,
    ON_16MS,
    COUNT
}

/// Approximate max number of mouse events we want in one packet. This fits a mouse group of 18 packets, 18 more
/// individual ungrouped mouse events, an extra non-move event.
const MAX_MOUSE_EVENTS = 36;

declare interface ResizeObserverSize {
    inlineSize: number;
    blockSize: number;
}

declare interface ResizeObserverEntry {
    target: Element;
    contentRect: DOMRectReadOnly;
    borderBoxSize: readonly ResizeObserverSize[];
    contentBoxSize: readonly ResizeObserverSize[];
    devicePixelContentBoxSize: readonly ResizeObserverSize[];
}

declare class ResizeObserver {
    constructor(
        callback: (entries: readonly ResizeObserverEntry[], observer: ResizeObserver) => void
    );

    observe(target: Element, options?: { box?: "content-box" | "border-box" }): void;
    unobserve(target: Element): void;
    disconnect(): void;
}

declare interface IntersectionObserverInit {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
}

declare interface IntersectionObserverEntry {
    readonly boundingClientRect: DOMRectReadOnly;
    readonly intersectionRatio: number;
    readonly intersectionRect: DOMRectReadOnly;
    readonly isIntersecting: boolean;
    readonly rootBounds: DOMRectReadOnly | null;
    readonly target: Element;
    readonly time: number;
}

declare class IntersectionObserver {
    constructor(
        callback: (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => void,
        options?: IntersectionObserverInit
    );

    readonly root: Element | null;
    readonly rootMargin: string;
    readonly thresholds: ReadonlyArray<number>;
    disconnect(): void;
    observe(target: Element): void;
    takeRecords(): IntersectionObserverEntry[];
    unobserve(target: Element): void;
}

const enum MouseButtons {
    LEFT_CLICK = 0,
    MIDDLE_CLICK,
    RIGHT_CLICK,
    MOUSE_FOUR,
    MOUSE_FIVE
}

const MAX_ZOOM_FACTOR = 3;
const MIN_ZOOM_FACTOR = 1.1;

const CHINESE_TRADITIONAL_LAYOUT = "zh-TW";

export class InputHandler implements GestureHandler {
    private videoTagElement: InputMediaElement;
    private videoState: VideoState;
    private keydownFunc: any;
    private keyupFunc: any;
    private mousedownFunc: any;
    private pointerdownFunc: any;
    private mouseupFunc: any;
    private pointerupFunc: any;
    private mousewheelFunc: any;
    private pointerMoveFunc: any;
    private pointerRawUpdateFunc: any;
    private freeMouseMoveFunc: any;
    private freePointerMoveFunc: any;
    private videoBlurFunc: any;
    private videoFocusFunc: any;
    private fullscreenEventFunc: any;
    private pointerLockEventFunc: any;
    private pointerLockErrorFunc: any;
    private preRenderFunc: any;
    private cursorType: CursorType;
    private cursorState: CursorState;
    private cursorCanvasState: CursorCanvasState;
    private cursorCache: CursorExt[];
    private cursorStyles: string[];
    private cursorCanvas: HTMLCanvasElement | null;
    private cursorCanvasId: string;
    private gestureDetector?: GestureDetector;
    private touchListener?: TouchListener;
    private eventEmitter: IEventEmitter;
    private rendering: boolean;
    private resizedFunc: any;
    private viewportResizedFunc: any;
    private popStateFunc: any;
    private textInputFunc: any;
    private textCompositionFunc: any;
    private pointerLockElement: HTMLElement | null;
    private consecutivePointerLockFailedAttempts: number = 0;
    private allowPointerLock: boolean = false;
    private fullscreen: boolean = false;
    private focused: boolean;
    private lastLockKeysState: number;
    private pressedKeys: Set<number>;
    private supportsNumAndScrollLock: boolean;
    private supportsRawUpdate: boolean = false;
    private supportsPointerEvents: boolean = false;
    private supportsCoalescedEvents: boolean = false;
    private isChromeOs: boolean;
    private isMacOs: boolean;
    private isChromium: boolean;
    private isAndroidOs: boolean;
    private isTouchDevice: boolean;
    private ignoredLockKeys: number[];
    private telemetry: TelemetryHandler;
    private enabledInputs: InputType = InputType.None;
    private isVirtualKeyboardVisible: boolean = false;
    private enabledInputsBeforeUserIdlePendingOverlay?: InputType;
    private _isUserIdleTimeoutPending: boolean;
    private historyProtected: boolean;
    private preventNavigation: boolean;
    private streamClient: StreamClient;
    private unadjustedMovementAllowed: boolean = false;
    private unadjustedMovementActive: boolean = false;
    private pointerLockReturnsPromise: boolean = false;
    private measurements: Measurements;
    private localStats: LocalStatistics;
    private perf: PerformanceObserver | null;
    private rawUpdateState: RawUpdateState = RawUpdateState.OFF;
    private rawCoalesceInterval: number = 0;
    private useVkCodes: boolean = true;
    private mouseFilter: IMouseFilter;
    private windowAddEventListener: any;
    private documentAddEventListener: any;
    private videoAddEventListener: any;
    private windowRemoveEventListener: any;
    private documentRemoveEventListener: any;
    private videoRemoveEventListener: any;
    private gamepadHandler: GamepadHandler;
    private rsdmmHandler: GamepadRSDMM;
    private statsGestureTimerId: number = 0;
    private resizeObserver?: ResizeObserver;
    private intersectionObserver?: IntersectionObserver;
    private videoZoomFactor: number = 1;
    private videoOffsetX: number = 0;
    private videoOffsetY: number = 0;
    private zoomInProgress: boolean = false;
    private touchDelay: TouchDelay = new TouchDelay();
    private gamepadTester: GamepadTester;
    private packetHandler: InputPacketHandler;
    private moveEventBuffer: MoveEventBuffer;
    private gamepadTesterEnabled: boolean = false;
    private idleInputListenerFunc: any;
    private textInputElement?: HTMLInputElement;
    private textCompositionInProgress: boolean = false;
    private textInputDetected: boolean = false;
    private platformDetails: PlatformDetails;
    // TODO: Make this configurable with a remote config option
    private flagTranslateToggleChineseKey: boolean = true;
    private tradChineseLayout: boolean = false;
    private callbacks: IStreamCallbacks;
    private nonpassiveOptions: AddEventListenerOptions = { passive: false };
    private twoFingerTapCount: number = 0;
    private threeFingerTapCount: number = 0;
    private scaleMovementByDpr: boolean;
    private safeZoneHandler: SafeZoneHandler;
    private safeZoneTimeoutId: number = 0;

    constructor(
        streamClient: StreamClient,
        videoElement: InputMediaElement,
        inputChannel: InputChannel,
        telemetry: TelemetryHandler,
        eventEmitter: IEventEmitter,
        configFlags: InputConfigFlags,
        sendRawTouchinput: boolean,
        gamepadTester: GamepadTester,
        gamepadHandler: GamepadHandler,
        platformDetails: PlatformDetails,
        callbacks: IStreamCallbacks,
        safeZoneHandler: SafeZoneHandler,
        textInputElement?: HTMLInputElement
    ) {
        this.platformDetails = platformDetails;
        this.streamClient = streamClient;
        this.videoTagElement = videoElement;
        this.telemetry = telemetry;
        this.callbacks = callbacks;
        this.safeZoneHandler = safeZoneHandler;

        // Using video element or screen dimensions to start here ensures the cursor's initial position
        // is correct. If we initialize these to zero, the cursor will only be ble to start at (0, 0).
        /// TODO: Store our cursor coordinates normalized to 0-65535 so we don't need to change them
        /// when the video dimensions change.
        this.videoState = {
            displayVideoWidth: videoElement.clientWidth || window.screen.width,
            displayVideoHeight: videoElement.clientHeight || window.screen.height,
            scalingFactor: 1,
            leftPadding: 0,
            topPadding: 0,
            videoWidth: 0,
            videoHeight: 0,
            viewportHeight: 0
        };

        const zoneless = (window as any).zoneless as Zoneless;
        this.windowAddEventListener =
            zoneless?.windowAddEventListener?.bind(window) ?? window.addEventListener.bind(window);
        this.windowRemoveEventListener =
            zoneless?.windowRemoveEventListener?.bind(window) ??
            window.removeEventListener.bind(window);
        this.documentAddEventListener =
            zoneless?.documentAddEventListener?.bind(document) ??
            document.addEventListener.bind(document);
        this.documentRemoveEventListener =
            zoneless?.documentRemoveEventListener?.bind(document) ??
            document.removeEventListener.bind(document);
        this.videoAddEventListener =
            zoneless?.videoAddEventListener?.bind(videoElement) ??
            zoneless?.documentAddEventListener?.bind(document) ??
            videoElement.addEventListener.bind(videoElement);
        this.videoRemoveEventListener =
            zoneless?.videoRemoveEventListener?.bind(videoElement) ??
            zoneless?.documentRemoveEventListener?.bind(document) ??
            videoElement.removeEventListener.bind(videoElement);

        this.keydownFunc = this.keydown.bind(this);
        this.keyupFunc = this.keyup.bind(this);
        this.mousedownFunc = this.mousedown.bind(this);
        this.pointerdownFunc = this.pointerdown.bind(this);
        this.mouseupFunc = this.mouseup.bind(this);
        this.pointerupFunc = this.pointerup.bind(this);
        this.mousewheelFunc = this.mousewheel.bind(this);
        this.pointerMoveFunc = this.pointermove.bind(this);
        this.pointerRawUpdateFunc = this.pointerrawupdate.bind(this);
        this.freeMouseMoveFunc = this.freeMouseMove.bind(this);
        this.freePointerMoveFunc = this.freePointerMove.bind(this);
        this.fullscreenEventFunc = this.fullscreenEventHandler.bind(this);
        this.pointerLockEventFunc = this.pointerLockChange.bind(this);
        this.pointerLockErrorFunc = this.pointerLockError.bind(this);
        this.preRenderFunc = this.preRender.bind(this);
        this.videoBlurFunc = this.onVideoBlur.bind(this);
        this.videoFocusFunc = this.onVideoFocus.bind(this);
        this.resizedFunc = this.resized.bind(this);
        this.viewportResizedFunc = this.viewportResized.bind(this);
        this.popStateFunc = this.popstate.bind(this);
        this.idleInputListenerFunc = this.idleInputListener.bind(this);
        this.textInputFunc = this.textInputHandler.bind(this);
        this.textCompositionFunc = this.textCompositionHandler.bind(this);
        this.rendering = false;
        this.cursorType = configFlags.cursorType ?? CursorType.SOFTWARE;
        Log.i("{8bacfa5}", "{3277c0e}", this.cursorType);
        this.cursorState = {
            absPositioning: false,
            confined: false,
            absX: 0,
            absY: 0,
            remX: 0,
            remY: 0,
            style:
                this.makeCursorUrl(
                    defaultCursor.imageStr,
                    defaultCursor.mimeTypeStr,
                    defaultCursor.hotspotX,
                    defaultCursor.hotspotY
                ) + ", auto",
            hotspotX: 0,
            hotspotY: 0,
            width: 0,
            height: 0,
            scale: 1
        };
        this.cursorCanvasState = {
            dpiRatio: 1,
            cursorScaling: 1,
            dirty: false,
            imageChanged: false,
            imageDimensionsChanged: false,
            showing: false
        };
        this.cursorCache = [];
        this.cursorStyles = [
            "none",
            "default",
            "text",
            "wait",
            "crosshair",
            "progress",
            "nwse-resize",
            "nesw-resize",
            "ew-resize",
            "ns-resize",
            "move",
            "default",
            "pointer",
            "help"
        ];
        this.cursorCanvasId = "canvasId1";
        if (this.cursorType == CursorType.SOFTWARE) {
            this.cursorCanvas = this.getCursorCanvas(this.videoTagElement);
        } else {
            this.cursorCanvas = null;
        }

        this.gamepadTester = gamepadTester;

        this.preventNavigation = !!configFlags.preventNavigation;

        this.measurements = {
            baseTime: 0,
            baseTotalVideoFrames: 0,
            animationFrameCount: 0,
            sendInputCount: 0,
            sendInputOver5ms: 0,
            sendInputOver10ms: 0,
            singleDroppedFrames: 0,
            multiDroppedFrames: 0,
            aggregatedCount: 0,
            oversizedEventCount: 0
        };

        this.moveEventBuffer = new MoveEventBuffer(MAX_MOUSE_EVENTS);

        this.packetHandler = new InputPacketHandler(
            this,
            this.moveEventBuffer,
            this.measurements,
            this.videoState,
            this.streamClient,
            inputChannel,
            this.telemetry
        );

        if (sendRawTouchinput) {
            if (TouchListener.isSupported()) {
                this.touchListener = this.createTouchListener();
                Log.i("{8bacfa5}", "{60f3637}");
            } else {
                Log.w("{8bacfa5}", "{32104d3}");
            }
        } else {
            Log.i("{8bacfa5}", "{6be89bf}");
        }

        if (GestureDetector.isSupported() && !IsTV(this.platformDetails)) {
            this.gestureDetector = this.createGestureDetector();
        }

        this.eventEmitter = eventEmitter;
        // NVST protocol format: https://confluence.nvidia.com/display/GAMESTREAM/NVSC+RemoteInput+protocol

        this.pointerLockElement = null;
        this.lastLockKeysState = 0;
        this.pressedKeys = new Set();
        this.focused = false;

        this.supportsNumAndScrollLock = IsWindowsOS(this.platformDetails);
        this.supportsRawUpdate = "onpointerrawupdate" in this.videoTagElement;
        this.supportsPointerEvents = SupportsPointerEvents();
        this.supportsCoalescedEvents =
            this.supportsPointerEvents && "getCoalescedEvents" in PointerEvent.prototype;
        Log.i("{8bacfa5}", "{f30b8ee}", (this.supportsCoalescedEvents ? "supported" : "not supported"));
        this.isChromeOs = IsChromeOS(this.platformDetails);
        this.isMacOs = IsMacOS(this.platformDetails);
        this.isAndroidOs = IsAndroidOS(this.platformDetails);

        this.isChromium = IsChromium();

        this.isTouchDevice = IsTouchDevice();

        // Ignore none, some, or all of the lock keys depending on the platform.
        // If possible, we try to send these through the lock keys state message and ignore the key press.
        // On client platforms that don't keep track of certain key states, send the key press instead.
        if (this.supportsNumAndScrollLock) {
            // Caps lock, num lock, and scroll lock. All lock key states are valid on the client.
            Log.d("{8bacfa5}", "{da5ae1d}");
            this.ignoredLockKeys = [
                VIRTUAL_KEYS.CODE_CAPITAL,
                VIRTUAL_KEYS.CODE_NUMLOCK,
                VIRTUAL_KEYS.CODE_SCROLL
            ];
        } else if (this.isChromeOs || IsLinuxOS(this.platformDetails)) {
            // All lock keys are unsupported or broken here.
            Log.d("{8bacfa5}", "{b7becf9}");
            this.ignoredLockKeys = [];
        } else {
            // Verified on macOS, iPhone, and iPad. Assume support from other platforms.
            Log.d("{8bacfa5}", "{098ce9c}");
            this.ignoredLockKeys = [VIRTUAL_KEYS.CODE_CAPITAL];
        }

        this._isUserIdleTimeoutPending = false;
        this.historyProtected = false;
        // local statistics
        this.localStats = {
            rafTime: 0,
            droppedVideoFrames: 0,
            totalVideoFrames: 0
        };

        if (PerformanceObserver) {
            let self = this;
            this.perf = new PerformanceObserver(list => this.perfCallback(list));
        } else {
            this.perf = null;
        }

        if (this.supportsRawUpdate) {
            const updateOverride = getRawUpdateOverride();
            if (updateOverride !== undefined) {
                this.setRawUpdate(updateOverride);
            } else if (this.isChromeOs) {
                this.setRawUpdate(RawUpdateState.ON_16MS);
            } else {
                this.setRawUpdate(RawUpdateState.ON_4MS);
            }
            const overridden = updateOverride !== undefined;
            Log.i("{8bacfa5}", "{20be0c0}", this.rawCoalesceInterval, (overridden ? " (overridden)" : ""));
        }

        this.unadjustedMovementAllowed =
            RagnarokSettings.unadjustedMovement ??
            getDefaultUnadjustedMovement(this.platformDetails);

        const avoidFilter =
            this.isChromeOs &&
            IsChromeVersionAtLeast(this.platformDetails, 84, 0, 4147, 94) &&
            !IsChromeVersionAtLeast(this.platformDetails, 88, 0, 4324, 139);
        const needsFilter =
            IsWindowsOS(this.platformDetails) ||
            !avoidFilter ||
            !IsChromeVersionAtLeast(this.platformDetails, 84, 0, 4147, 94);

        if (RagnarokSettings.mouseFilter ?? needsFilter) {
            this.mouseFilter = new MouseFilter();
        } else {
            this.mouseFilter = new NullMouseFilter();
        }

        [
            "fullscreenchange",
            "webkitfullscreenchange",
            "mozfullscreenchange",
            "msfullscreenchange"
        ].forEach(eventType => document.addEventListener(eventType, this.fullscreenEventFunc));
        ["pointerlockchange", "mozpointerlockchange"].forEach(eventType =>
            document.addEventListener(eventType, this.pointerLockEventFunc)
        );
        ["pointerlockerror", "mozpointerlockerror"].forEach(eventType =>
            document.addEventListener(eventType, this.pointerLockErrorFunc)
        );
        Log.d("{8bacfa5}", "{cc9c05b}");

        if (this.cursorCanvas) {
            this.setCursorCanvasPosition(this.cursorCanvas, this.videoTagElement);
        }

        if ((<any>window).ResizeObserver) {
            this.resizeObserver = new ResizeObserver(_entries => {
                this.resized();
            });
            this.resizeObserver.observe(this.videoTagElement);
        } else {
            // Listen for resizes
            window.addEventListener("resize", this.resizedFunc);
        }
        if (
            (IsiDevice(platformDetails) || IsTizenOS(platformDetails)) &&
            (<any>window).IntersectionObserver
        ) {
            this.intersectionObserver = new IntersectionObserver(
                _entries => {
                    this.resized();
                },
                {
                    threshold: [1.0]
                }
            );
            this.intersectionObserver.observe(this.videoTagElement);
        }
        // The resize event on video elements is for the video stream width/height rather than the element
        // width/height, so we need this in addition to ResizeObserver/IntersectionObserver/window resize.
        this.videoTagElement.addEventListener("resize", this.resizedFunc);

        const viewport = (<any>window).visualViewport;
        if (viewport) {
            this.videoState.viewportHeight = viewport.height;
            viewport.addEventListener("resize", this.viewportResizedFunc);
        }

        // we might already be in fullscreen
        this.fullscreenEventHandler({} as Event);
        this.changeFocusHandling(true);
        this.focused = true;
        if (
            this.perf &&
            PerformanceObserver.supportedEntryTypes &&
            PerformanceObserver.supportedEntryTypes.includes("longtask")
        ) {
            this.perf.observe({ entryTypes: ["longtask"] });
        }

        this.gamepadHandler = gamepadHandler;
        this.gamepadHandler.setGamepadDataSender(this.packetHandler);
        this.packetHandler.addVibrationHandler(this.gamepadHandler);
        this.rsdmmHandler = new GamepadRSDMM(this.videoTagElement, this.platformDetails);
        this.gamepadHandler.setGamepadRSDMM(this.rsdmmHandler);
        this.gamepadHandler.addTelemetry(this.telemetry);

        if (RagnarokSettings.latencyTest) {
            LatencyIndicator.getInstance().initialize(this.videoTagElement, this.platformDetails);
        }

        if (IsTV(this.platformDetails) || this.isAndroidOs || IsiDevice(this.platformDetails)) {
            this.textInputElement = textInputElement;
        }

        // There was an issue until Chrome 99 where movementX/movementY didn't take devicePixelRatio into account, so
        // we needed to handle it. We were checking for isChromium to enable the WAR, but this makes version checks
        // hard. So, keep the WAR enabled for TVs (because we know those are old Chromium versions) and older Chrome
        // versions, and disable it for other Chromium-based browsers regardless of versions. Chromium 99 was released a
        // year ago, so this should be okay
        if (IsWebOS(this.platformDetails) || IsTizenOS(this.platformDetails)) {
            this.scaleMovementByDpr = true;
        } else if (
            platformDetails.browser == BrowserName.CHROME &&
            !IsChromeVersionAtLeast(platformDetails, 99, 0, 4844, 44) &&
            !this.isMacOs
        ) {
            this.scaleMovementByDpr = true;
        } else {
            this.scaleMovementByDpr = false;
        }
    }

    public get isUserIdleTimeoutPending() {
        return this._isUserIdleTimeoutPending;
    }

    uninitialize() {
        this.setUserIdleTimeoutPending(false);
        const videoPaused = this.videoTagElement.paused;
        const videoState =
            videoPaused !== undefined ? (videoPaused ? "paused" : "playing") : "unknown";
        if (this.videoTagElement.paused || (this.videoTagElement.currentTime ?? 1) < 1) {
            this.telemetry.emitDebugEvent(
                "VideoPaused",
                videoState,
                this.videoTagElement.currentTime?.toFixed(2)
            );
        }
        Log.i("{8bacfa5}", "{fdc8bf7}", videoState, this.videoTagElement.currentTime);
        this.intersectionObserver?.disconnect();
        this.resizeObserver?.disconnect();
        this.packetHandler.stop();
        if (this.perf) {
            this.perf.disconnect();
        }
        this.toggleUserInput(false);
        window.removeEventListener("resize", this.resizedFunc);
        this.videoTagElement.removeEventListener("resize", this.resizedFunc);
        (<any>window).visualViewport?.removeEventListener("resize", this.viewportResizedFunc);

        this.gamepadHandler.disconnectAllGamepads();
        this.packetHandler.removeVibrationHandler(this.gamepadHandler);

        ["pointerlockerror", "mozpointerlockerror"].forEach(eventType =>
            document.removeEventListener(eventType, this.pointerLockErrorFunc)
        );
        ["pointerlockchange", "mozpointerlockchange"].forEach(eventType =>
            document.removeEventListener(eventType, this.pointerLockEventFunc)
        );
        [
            "fullscreenchange",
            "webkitfullscreenchange",
            "mozfullscreenchange",
            "msfullscreenchange"
        ].forEach(eventType => document.removeEventListener(eventType, this.fullscreenEventFunc));

        this.rsdmmHandler.stop();
        this.gamepadHandler.removeGamepadDataHandler(this.rsdmmHandler);
        this.gamepadHandler.removeGamepadDataHandler(this.packetHandler);
        if (this.gamepadTesterEnabled) {
            this.gamepadHandler.removeGamepadDataHandler(this.gamepadTester);
            this.gamepadTester.toggleGamepadTester(this.videoTagElement);
        }

        this.changeFocusHandling(false);
        this.clearSafeZoneTimeout();
        Log.d("{8bacfa5}", "{b6b2756}");
    }

    private createGestureDetector(): GestureDetector {
        const gestureTarget = this.videoTagElement;
        return new GestureDetector(
            gestureTarget,
            this.videoAddEventListener,
            this.videoRemoveEventListener,
            this as GestureHandler
        );
    }

    private createTouchListener(): TouchListener {
        return new TouchListener(
            this.videoTagElement,
            this.videoAddEventListener,
            this.videoRemoveEventListener,
            this.packetHandler,
            this as GestureHandler,
            this.platformDetails
        );
    }

    changeFocusHandling(enable: boolean) {
        if (enable) {
            window.addEventListener("blur", this.videoBlurFunc);
            window.addEventListener("focus", this.videoFocusFunc);
        } else {
            window.removeEventListener("blur", this.videoBlurFunc);
            window.removeEventListener("focus", this.videoFocusFunc);
        }
    }

    // Update the lock keys state on the server.
    // Ignore CapsLock state if specified; only for Traditional Chinese input toggle.
    private updateLockKeysState(evt: KeyboardEvent) {
        // The caps lock key on external keyboards is bugged on ChromeOS.
        // It will always set the caps lock modifier key in the down event and unset it in the up event.
        // So, ignore the modifier keys in this case and send the key directly to the server.
        if (this.isChromeOs && evt.keyCode === VIRTUAL_KEYS.CODE_CAPITAL) {
            return;
        }
        // iOS only sets the CapsLock modifier properly on the caps lock keydown/up.
        if (IsiDevice(this.platformDetails) && evt.keyCode !== VIRTUAL_KEYS.CODE_CAPITAL) {
            return;
        }
        let state = 0;
        // Not all these modifier keys work on all platforms according to:
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState
        state |= LockKeyBitMask.NVST_LKB_CAPS_VALID;

        // Must not send Caps Lock state changes when using the key as a toggle between Chinese
        // and Latin input.
        let allowCapsLock: boolean = !this.chineseTogglePermitted();

        if (allowCapsLock && evt.getModifierState("CapsLock")) {
            state |= LockKeyBitMask.NVST_LKB_CAPS;
        }

        if (this.supportsNumAndScrollLock) {
            state |= LockKeyBitMask.NVST_LKB_NUM_VALID;
            state |= LockKeyBitMask.NVST_LKB_SCROLL_VALID;
            if (evt.getModifierState("NumLock")) {
                state |= LockKeyBitMask.NVST_LKB_NUM;
            }
            if (evt.getModifierState("ScrollLock")) {
                state |= LockKeyBitMask.NVST_LKB_SCROLL;
            }
        }

        if (state == this.lastLockKeysState) {
            return;
        }
        this.lastLockKeysState = state;

        this.packetHandler.sendLockKeyState(state);
    }

    private preRender(_timestamp: number) {
        RagnarokProfiler.onPreRender();
        if (!RagnarokSettings.leanMode) {
            const now = window.performance.now();
            const frameCounts = getVideoFrameCounts(this.videoTagElement);
            if (frameCounts) {
                const totalDroppedVideoFrames = frameCounts.dropped;
                const droppedFrames = totalDroppedVideoFrames - this.localStats.droppedVideoFrames;
                const decodedFrames = frameCounts.decoded - this.localStats.totalVideoFrames;

                this.localStats.droppedVideoFrames = totalDroppedVideoFrames;
                this.localStats.totalVideoFrames = frameCounts.decoded;
                if (droppedFrames == 1) {
                    this.measurements.singleDroppedFrames++;
                } else if (droppedFrames > 1) {
                    this.measurements.multiDroppedFrames += droppedFrames;
                }
                RagnarokProfiler.onFrameInfo(decodedFrames, droppedFrames);
            }
            this.localStats.rafTime = now;
        }
        window.setTimeout(() => this.postRender());

        if (this.cursorCanvasState.dirty) {
            this.cursorCanvasState.dirty = false;
            this.drawCursor(
                this.cursorState.absPositioning,
                this.cursorState.absX,
                this.cursorState.absY
            );
        }
    }

    private postRender() {
        if (this.rendering) {
            window.requestAnimationFrame(this.preRenderFunc);
        }
        // When using raw pointer updates, the event handler will manage sending scheduled inputs.
        if (this.rawUpdateState === RawUpdateState.OFF) {
            this.packetHandler.sendScheduledPackets();
        }

        this.gamepadHandler.postRender();

        if (!RagnarokSettings.leanMode) {
            this.handleMeasurements();
        }
    }

    private handleMeasurements() {
        const INTERVAL_MS = 10000;

        const now = window.performance.now();
        if (now > this.measurements.baseTime + INTERVAL_MS) {
            if (this.measurements.baseTime !== 0) {
                Log.i("{8bacfa5}", "{5fa9066}", this.measurements.animationFrameCount, this.measurements.sendInputCount, this.measurements.sendInputOver5ms, this.measurements.sendInputOver10ms, (this.localStats.totalVideoFrames -
                            this.measurements.baseTotalVideoFrames), this.measurements.singleDroppedFrames, this.measurements.multiDroppedFrames, this.measurements.aggregatedCount, this.measurements.oversizedEventCount);
            }

            this.measurements.baseTime = now;
            this.measurements.baseTotalVideoFrames = this.localStats.totalVideoFrames;
            this.measurements.animationFrameCount = 1;
            this.measurements.sendInputCount = 0;
            this.measurements.sendInputOver5ms = 0;
            this.measurements.sendInputOver10ms = 0;
            this.measurements.singleDroppedFrames = 0;
            this.measurements.multiDroppedFrames = 0;
            this.measurements.aggregatedCount = 0;
            this.measurements.oversizedEventCount = 0;
        } else {
            this.measurements.animationFrameCount += 1;
        }
    }

    virtualGamepadUpdateHandler() {
        if (this.packetHandler.channelOpen()) {
            this.gamepadHandler.virtualGamepadUpdateHandler();
        }
    }

    resized(_evt?: Event) {
        this.updateVideoState(this.videoTagElement);
        if (this.cursorCanvas) {
            this.setCursorCanvasPosition(this.cursorCanvas, this.videoTagElement);
        }

        this.touchListener?.updateVideoState(
            this.videoState,
            this.getMargins(),
            this.videoZoomFactor
        );
    }

    viewportResized(evt: Event) {
        this.videoState.viewportHeight = (<any>window).visualViewport!.height;
        this.applyVideoTransforms(this.videoOffsetX, this.videoOffsetY, this.videoZoomFactor);
    }

    popstate(evt: Event) {
        Log.d("{8bacfa5}", "{b6fbd34}");
        history.pushState(null, document.title, location.href);
    }

    private getVirtualKeycode(evt: KeyboardEvent): number {
        if (this.useVkCodes) {
            if (this.isAndroidOs && this.isVirtualKeyboardVisible) {
                // On Android, when textcomposition is not true, we receive keyDown/KeyUp
                // with valid data for control characters (BackSpace, Enter, etc), but:
                //
                //     softKB inputs are recevied via KeyboardEvent.key, and KeyboardEvent.code is ""
                // externalKb inputs are recevied via KeyboardEvent.code as usual.
                //
                // I have not seen the 'evt.key' filled with any values we have defined in KEY_TO_VK_MAP
                // even when entering using korean and chineses.
                //
                // When external KB is connected, isVirtualKeyboardVisible is expecetd to be false.
                // However, currently UI is setting it to true. So checking for valid code first.
                let vkCode = evt.code?.length
                    ? CODE_TO_VK_MAP.get(evt.code)
                    : CODE_TO_VK_MAP.get(evt.key);
                return vkCode ?? VIRTUAL_KEYS.CODE_NONE;
            } else {
                let vkCode = evt.code ? CODE_TO_VK_MAP.get(evt.code) : KEY_TO_VK_MAP.get(evt.key);
                return vkCode ?? VIRTUAL_KEYS.CODE_NONE;
            }
        } else {
            return evt.keyCode;
        }
    }

    private japaneseSpecialKey(evt: KeyboardEvent) {
        function isKanaKey(key: string) {
            return (
                key == "Hiragana" /* Kana */ ||
                key == "Katakana" /* Shift+Kana */ ||
                key == "Romaji" /* Alt+Kana */ ||
                key == "KanaMode" /* Alt+Kana */
            );
        }
        if (
            evt.code == "CapsLock" &&
            (evt.key == "Alphanumeric" || evt.key == "Katakana" || evt.key == "Hiragana")
        ) {
            // Japanese key Eisu/Caps Lock
            // NOTE:    evt.keyCodes are not 20 (VIRTUAL_KEYS.CODE_CAPITAL) but
            //          240 (Alphanumeric), 241 (Katakana), 242 (Hiragana)
            // Allow through to the session; treat as Caps Lock
            return true;
        } else if (evt.code == "KanaMode" && isKanaKey(evt.key)) {
            // Japanese 106 Hiragana/Katakana (Kana) key (2nd to the right of space) combinations
            return true;
        }
        return false;
    }

    private koreanSpecialKey(evt: KeyboardEvent) {
        // Korean 103 Hangul key (to the right of space).
        return evt.code == "Lang1" || evt.key == "HangulMode";
    }

    private chineseTogglePermitted(): boolean {
        return this.flagTranslateToggleChineseKey && this.isMacOs && this.tradChineseLayout;
    }

    private tradChineseToggleKey(evt: KeyboardEvent): boolean {
        if (!this.chineseTogglePermitted()) {
            return false;
        }
        return evt.code == "CapsLock";
    }

    keydown(evt: KeyboardEvent) {
        let sendGeneratedUpEvent = false;
        this.textInputDetected = false; // tracks if there is a input event between keyDown and keyUp events

        if (!this.focused) {
            // On Safari, we can receive extra keypresses after we lose focus.
            // Since we release keys on focus lost, this can cause an extra key press to get sent.
            // Ignore all such keypresses.
            return;
        }

        // Prevent most browser hotkeys from activating.
        if (this.shouldPreventDefaultKb(evt)) {
            evt.preventDefault();
        }

        let tradCnToggle = this.tradChineseToggleKey(evt);
        if (tradCnToggle) {
            sendGeneratedUpEvent = sendGeneratedUpEvent || tradCnToggle;
        }

        this.updateLockKeysState(evt);

        const jpSpecialKey = this.japaneseSpecialKey(evt);
        sendGeneratedUpEvent = sendGeneratedUpEvent || jpSpecialKey;

        const krSpecialKey = this.koreanSpecialKey(evt);
        sendGeneratedUpEvent = sendGeneratedUpEvent || krSpecialKey;

        // Japanese and Chinese keyboards can use Caps Lock in a different way, including
        // special key combinations, so don't always ignore Caps Lock on those keyboards.
        if (this.ignoredLockKeys.includes(evt.keyCode) && !jpSpecialKey && !tradCnToggle) {
            // On mac when caps lock is enabled only keydown callback is invoked.
            // Upon clearing of the capslock a keyup is sent for it.
            // To avoid dealing with this, handle lock keys in updateLockKeysState above.
            return;
        }

        const keycode: number = tradCnToggle
            ? VIRTUAL_KEYS.CODE_LSHIFT
            : this.getVirtualKeycode(evt);
        if (keycode === VIRTUAL_KEYS.CODE_NONE || this.pressedKeys.has(keycode)) {
            return;
        }
        this.pressedKeys.add(keycode);

        let sendEvent: boolean = false;
        const ldat = this.streamClient.getLdatHandler();

        if (keycode == VIRTUAL_KEYS.CODE_OEM_PLUS) {
            // Ignore this event if it was used to send the latency trigger
            sendEvent = !this.streamClient.sendLatencyTrigger();
        } else if (evt.ctrlKey && evt.altKey) {
            // Possible hotkey.
            //Support stutter indicator for explicit override
            if (
                (RagnarokSettings.isInternalUser || RagnarokSettings.stutterIndicator) &&
                (keycode == VIRTUAL_KEYS.CODE_F8 || keycode == VIRTUAL_KEYS.CODE_OEM_4)
            ) {
                // Handle Ctrl+Alt+ (F8/`) key combination.
                this.streamClient.toggleStutterIndicator();
            }
            // Support all other feature toggles for internal users only.
            else if (RagnarokSettings.isInternalUser) {
                // Support OnScreenStats for all modes
                if (keycode == VIRTUAL_KEYS.CODE_F6 /*F6*/) {
                    this.streamClient.toggleOnScreenStats(evt.shiftKey);
                } else if (keycode == VIRTUAL_KEYS.CODE_D) {
                    this.streamClient.sendPcmDumpTrigger();
                } else if (keycode == VIRTUAL_KEYS.CODE_F7 || keycode == VIRTUAL_KEYS.CODE_OEM_3) {
                    // Handle Ctrl+Alt+ (F7/`) key combination.
                    this.streamClient.togglePerfIndicator();
                } else if (keycode == VIRTUAL_KEYS.CODE_8) {
                    // Handle Ctrl+Alt+8 key combination to toggle GpuViewCapture on server
                    this.streamClient.toggleGpuViewCapture();
                } else if (keycode == VIRTUAL_KEYS.CODE_F4 /*F4*/) {
                    this.streamClient.toggleProfiler();
                } else if (keycode == VIRTUAL_KEYS.CODE_F5 /*F5*/) {
                    this.streamClient.toggleWebRTCStats();
                } else if (keycode == VIRTUAL_KEYS.CODE_F9 /*F9*/) {
                    this.unadjustedMovementAllowed = !this.unadjustedMovementAllowed;
                    const message =
                        "UnadjustedMovement: " + (this.unadjustedMovementAllowed ? "ON" : "OFF");
                    this.streamClient.showDebugMessage(message);
                    Log.i("{8bacfa5}", "{796c5cb}", (this.unadjustedMovementAllowed ? "ON" : "OFF"));
                } else if (keycode == VIRTUAL_KEYS.CODE_G /*g*/) {
                    this.gamepadTesterEnabled = !this.gamepadTesterEnabled;
                    this.gamepadTester.toggleGamepadTester(this.videoTagElement);

                    if (this.gamepadTesterEnabled) {
                        this.gamepadHandler.addGamepadDataHandler(this.gamepadTester);
                    } else {
                        this.gamepadHandler.removeGamepadDataHandler(this.gamepadTester);
                    }
                } else if (keycode == VIRTUAL_KEYS.CODE_0) {
                    let status = "UNSUPPORTED";
                    if (this.supportsRawUpdate) {
                        this.setRawUpdate((this.rawUpdateState + 1) % RawUpdateState.COUNT);
                        switch (this.rawUpdateState) {
                            case RawUpdateState.ON_4MS:
                                status = "4ms";
                                break;
                            case RawUpdateState.ON_8MS:
                                status = "8ms";
                                break;
                            case RawUpdateState.ON_16MS:
                                status = "16ms";
                                break;
                            default:
                                status = "OFF";
                                break;
                        }
                    }
                    const message = "Non-vsync mouse events: " + status;
                    this.streamClient.showDebugMessage(message);
                    Log.i("{8bacfa5}", "{bac28ff}", status);
                } else if (keycode == VIRTUAL_KEYS.CODE_OEM_MINUS /*-*/) {
                    this.useVkCodes = !this.useVkCodes;
                    const message = "Position dependent keys: " + (this.useVkCodes ? "ON" : "OFF");
                    this.streamClient.showDebugMessage(message);
                    Log.i("{8bacfa5}", "{61d2ab5}", (this.useVkCodes ? "ON" : "OFF"));
                } else if (keycode == VIRTUAL_KEYS.CODE_L) {
                    ldat?.toggleVisibility();
                    this.updatePointerLock();
                } else if (keycode == VIRTUAL_KEYS.CODE_S) {
                    this.safeZoneHandler.toggleDisplaySafeZone();
                } else {
                    sendEvent = true;
                }
            } else {
                sendEvent = true;
            }
        } else if (ldat?.isVisible()) {
            if (keycode == VIRTUAL_KEYS.CODE_RETURN) {
                ldat.toggle();
                this.updatePointerLock();
            } else if (keycode == VIRTUAL_KEYS.CODE_R) {
                ldat.reset();
                this.updatePointerLock();
            } else if (keycode == VIRTUAL_KEYS.CODE_A) {
                ldat.toggleAutoFire();
            } else if (keycode == VIRTUAL_KEYS.CODE_S) {
                ldat.saveLog();
            } else if (keycode == VIRTUAL_KEYS.CODE_C) {
                ldat.centerLoupe();
            } else {
                sendEvent = true;
            }
        } else {
            sendEvent = true;
        }

        if (sendEvent) {
            LatencyIndicator.getInstance().toggleIndicator();
            this.packetHandler.sendKeyboardEvent(
                PacketId.PACKET_KEYDOWN,
                keycode,
                this.getModifierFlags(evt),
                evt.timeStamp
            );
        }

        if (evt.key == "Hankaku" || evt.key == "Zenkaku") {
            // For Japanese keyboards, the 'Backquote' key is effectively a lock state toggle,
            // between Hankaku and Zenkaku. A keydown event is generated when entering each
            // lock state, and keyup when exiting.
            // Therefore, we receive a keyup for one state and keydown for the other, which
            // leaves the key in a down state.
            // If the server is not using a Japanese keyboard, this looks just like auto-repeat,
            // generating lots of (in English US) tilde (~) or backquote (`) characters.

            if (keycode == VIRTUAL_KEYS.CODE_OEM_3) {
                sendGeneratedUpEvent = true;
            }
        }

        if (this.isAndroidOs && this.isVirtualKeyboardVisible) {
            if (evt.code == "Backspace") {
                // For softkb input input evt.code will not be filled.
                // On Android, when external KB is connected and virtualKb is also open, the keyUp for backspace
                // is not getting triggered. This causes either all subsequent backpsaces to be ignored or to
                // send backspaces repeatedly if no other key is input immediately.

                sendGeneratedUpEvent = true;
            }

            if (evt.code == "" && evt.key == "Enter") {
                // On Softkb enter, if there is a composition in progress, end it
                const compositionEndEvt: CompositionEvent = new CompositionEvent("compositionend", {
                    data: this.textInputElement?.value
                });
                this.textInputElement?.dispatchEvent(compositionEndEvt);
            }
        }

        if (sendGeneratedUpEvent) {
            this.packetHandler.sendKeyboardEvent(
                PacketId.PACKET_KEYUP,
                keycode,
                this.getModifierFlags(evt),
                evt.timeStamp
            );
            this.pressedKeys.delete(keycode);
        }
    }

    keyup(evt: KeyboardEvent) {
        let sendGeneratedDownEvent = false;

        if (
            this.isAndroidOs &&
            this.isVirtualKeyboardVisible &&
            evt.code == "" && // this will rule out input from external kb
            evt.key == "Unidentified"
        ) {
            // WAR for Andorid softkb where backspaces are consumed by the IME compositor
            // If there is no input event between a keyDown and keyUp event, then treat it
            // as backspace and send keycode to server
            if (!this.textInputDetected) {
                this.packetHandler.sendKeyboardEvent(
                    PacketId.PACKET_KEYDOWN,
                    VIRTUAL_KEYS.CODE_BACK,
                    0,
                    evt.timeStamp
                );
                this.packetHandler.sendKeyboardEvent(
                    PacketId.PACKET_KEYUP,
                    VIRTUAL_KEYS.CODE_BACK,
                    0,
                    evt.timeStamp
                );
                return;
            }
        }

        let tradCnToggle = this.tradChineseToggleKey(evt);
        if (tradCnToggle) {
            sendGeneratedDownEvent = sendGeneratedDownEvent || tradCnToggle;
        }

        this.updateLockKeysState(evt);

        if (this.ignoredLockKeys.includes(evt.keyCode) && !tradCnToggle) {
            return;
        }

        const keycode: number = tradCnToggle
            ? VIRTUAL_KEYS.CODE_LSHIFT
            : this.getVirtualKeycode(evt);

        if (sendGeneratedDownEvent) {
            this.packetHandler.sendKeyboardEvent(
                PacketId.PACKET_KEYDOWN,
                keycode,
                this.getModifierFlags(evt),
                evt.timeStamp
            );
            this.pressedKeys.add(keycode);
        }

        if (!this.pressedKeys.has(keycode)) {
            return;
        }
        this.pressedKeys.delete(keycode);

        LatencyIndicator.getInstance().toggleIndicator();
        this.packetHandler.sendKeyboardEvent(
            PacketId.PACKET_KEYUP,
            keycode,
            this.getModifierFlags(evt),
            evt.timeStamp
        );
    }

    pointerdown(evt: PointerEvent) {
        if (shouldIgnorePointerEvent(evt)) {
            return;
        }
        evt.preventDefault();
        this.mousedown(evt);
        if (this.cursorType === CursorType.FREE) {
            this.videoTagElement.setPointerCapture(evt.pointerId);
        }
    }

    mousedown(evt: MouseEvent) {
        // If latency is enable then we will create dump on mouse event
        this.streamClient.eventTriggerLatencyDump();

        // If we get a mousedown, we are for sure in focus. Set it here in case we receive
        // mousedown before the focus event.
        this.focused = true;
        if (this.shouldPointerLock() && !this.isPointerLocked()) {
            this.updatePointerLock(true);
            // Update the cursor position to the new click
            this.setCursorPosFromOffset(evt.offsetX, evt.offsetY);

            this.scheduleCursorDraw();
            const coordX = this.cursorState.absX;
            const coordY = this.cursorState.absY;
            // Send the position update to the server
            this.packetHandler.sendCursorPos(true, coordX, coordY, evt.timeStamp); // TODO: handle relative cursor
            // Ignore the mousedown if we used it to lock the pointer
            return;
        }

        LatencyIndicator.getInstance().toggleIndicator();
        this.packetHandler.sendMouseDown(evt.button, evt.timeStamp);
    }

    pointerup(evt: PointerEvent) {
        if (shouldIgnorePointerEvent(evt)) {
            return;
        }
        evt.preventDefault();
        this.mouseup(evt);
        // We don't need to releasePointerCapture() here because it's automatic upon receiving pointerup
    }

    mouseup(evt: MouseEvent) {
        LatencyIndicator.getInstance().toggleIndicator();
        this.packetHandler.sendMouseUp(evt.button, evt.timeStamp);
    }

    mousewheel(evt: WheelEvent) {
        this.packetHandler.sendMouseWheel(evt.deltaY, evt.timeStamp);
        evt.preventDefault();
    }

    freePointerMove(evt: PointerEvent) {
        if (shouldIgnorePointerEvent(evt)) {
            return;
        }
        evt.preventDefault();

        // Chorded button presses will come through pointermove
        if (evt.button !== -1) {
            // The bit in evt.buttons used for mouse buttons 1 and 2 (middle and right click) is swapped
            // All others are a normal shift: 1 << evt.button
            let maskShift: number;
            switch (evt.button) {
                case 1:
                    maskShift = 2;
                    break;
                case 2:
                    maskShift = 1;
                    break;
                default:
                    maskShift = evt.button;
                    break;
            }
            if (evt.buttons & (1 << maskShift)) {
                this.packetHandler.sendMouseDown(evt.button, evt.timeStamp);
            } else {
                this.packetHandler.sendMouseUp(evt.button, evt.timeStamp);
            }
        }
        this.freeMouseMove(evt);
    }

    freeMouseMove(evt: MouseEvent) {
        this.setCursorPosFromOffset(evt.offsetX, evt.offsetY);
        this.packetHandler.sendCursorPos(
            true,
            this.cursorState.absX,
            this.cursorState.absY,
            evt.timeStamp
        );
    }

    private handlePointerEvent(evt: PointerEvent | MouseEvent) {
        if (evt instanceof PointerEvent) {
            if (shouldIgnorePointerEvent(evt)) {
                return;
            }
            evt.preventDefault();
        }
        let callbackTime = performance.now();
        if (!this.focused || (this.shouldPointerLock() && !this.isPointerLocked())) {
            // If we don't have the pointer lock that we need, don't move the mouse.
            return;
        }
        if (evt.movementX === 0 && evt.movementY === 0) {
            return;
        }

        let eventsPerAggregation = 1;
        let eventCount = 0;
        const absolute = this.cursorState.absPositioning;
        const firstEventIndex = this.moveEventBuffer.moveEventIndex;
        const processMovement = (ev: MouseEvent) => {
            if (!this.mouseFilter.update(ev.movementX, ev.movementY, ev.timeStamp)) {
                return;
            }

            let coordX = this.mouseFilter.getX();
            let coordY = this.mouseFilter.getY();
            if (absolute) {
                if (this.cursorState.confined) {
                    this.setCursorPosFromMovement(coordX, coordY);
                } else {
                    this.setCursorPosFromOffset(ev.offsetX, ev.offsetY);
                }

                this.cursorCanvasState.dirty = true;
                coordX = this.cursorState.absX;
                coordY = this.cursorState.absY;
            } else {
                // not absolute
                if (this.scaleMovementByDpr) {
                    // Normalize relative co-ordinates on Chromium browsers (incl. Chrome).
                    // Chromium reports movementX/movementY in physical pixel coordinates,
                    // not scaling for DPI.
                    // These should be reported in CSS pixels to use the same coordinate system
                    // as screenX/screenY do.
                    // Other browsers (Firefox, Safari) report in CSS pixels.
                    let dpr = this.cursorCanvasState.dpiRatio;

                    // Apply the previously stored remainder before scaling this event.
                    coordX += this.cursorState.remX;
                    coordY += this.cursorState.remY;

                    // Calculate and store the remainder of the scaling from this event, to apply to the next event.
                    this.cursorState.remX = coordX % dpr;
                    this.cursorState.remY = coordY % dpr;

                    // Scale this event by the DPI to get CSS pixels.
                    coordX /= dpr;
                    coordY /= dpr;
                }
            }
            const aggregate = eventCount % eventsPerAggregation !== 0;
            this.moveEventBuffer.addMoveEvent(
                absolute,
                coordX,
                coordY,
                ev.timeStamp,
                0,
                callbackTime,
                aggregate
            );
            eventCount++;
        };
        if (evt instanceof PointerEvent && this.supportsCoalescedEvents) {
            const coalesced: PointerEvent[] = (evt as any).getCoalescedEvents();
            if (coalesced.length == 0) {
                // No coalesced events in this PointerEvent; treat as a single event.
                processMovement(evt);
            } else {
                // If we receive too many events at once, aggregate it into one event no matter what.
                // No matter how we aggregate it, the server won't space it out correctly.
                const forceOneEvent = coalesced.length > 2 * MAX_MOUSE_EVENTS;
                // We try to leave room for 4 mouse events in the buffer so we don't overrun it if we
                // get a couple more events after a large group.
                const allowedEvents = forceOneEvent
                    ? 1
                    : Math.max(MAX_MOUSE_EVENTS - this.moveEventBuffer.moveEventIndex - 4, 1);
                if (coalesced.length > allowedEvents) {
                    eventsPerAggregation = Math.ceil(coalesced.length / allowedEvents);
                    this.measurements.aggregatedCount += coalesced.length;
                }
                for (let ev of coalesced) {
                    processMovement(ev);
                }
            }
        } else {
            processMovement(evt);
        }

        // We only let the first mouse event in a packet be a group because the group header size is significant
        // if applied to every mouse event (19 byte header vs 22-26 byte event), which limits the number of mouse
        // events we can send at once. The group is only used to provide send and callback times for server
        // telemetry, and including it for the first event in each packet fulfills this usecase without bloating
        // the packets.
        if (
            this.packetHandler.protocolVersion > 1 &&
            firstEventIndex === 0 &&
            this.moveEventBuffer.moveEventIndex > firstEventIndex
        ) {
            // Mark all the moves we just added as being part of one group.
            this.moveEventBuffer.setGroupSize(
                firstEventIndex,
                this.moveEventBuffer.moveEventIndex - firstEventIndex
            );
        }
    }

    private pointermove(evt: PointerEvent | MouseEvent) {
        this.handlePointerEvent(evt);
    }

    private pointerrawupdate(evt: PointerEvent) {
        // Check if there are scheduled inputs before handlePointerEvent adds any
        const alreadyScheduled = this.packetHandler.hasScheduledPackets();

        this.handlePointerEvent(evt);

        // If there weren't any inputs scheduled before but there are now, we need to send or
        // schedule the current batch.
        if (!alreadyScheduled && this.packetHandler.hasScheduledPackets()) {
            const msSinceSend = performance.now() - this.packetHandler.lastMoveSendTime;

            // Make sure we don't send events too frequently. We do a similar thing on native because
            // mouse events can come as fast as 1000 times a second, which can overwhelm the network.
            if (msSinceSend >= this.rawCoalesceInterval) {
                this.packetHandler.sendScheduledPackets();
            } else {
                this.packetHandler.timeScheduledPackets(this.rawCoalesceInterval - msSinceSend);
            }
        }
    }

    // Sets the cursor position from a CSS offset from the cursor canvas or video element
    private setCursorPosFromOffset(offsetX: number, offsetY: number) {
        // The offset includes letterbox/pillarbox padding, so remove it
        this.setCursorPosFromDisplayVideo(
            offsetX - this.videoState.leftPadding,
            offsetY - this.videoState.topPadding
        );
    }

    // Sets the cursor position from a relative movementX/Y
    private setCursorPosFromMovement(movementX: number, movementY: number) {
        const scale = this.scaleMovementByDpr ? this.cursorCanvasState.dpiRatio : 1.0;
        this.setCursorPosFromDisplayVideo(
            this.cursorState.absX + movementX / scale,
            this.cursorState.absY + movementY / scale
        );
    }

    // Sets the cursor position using normalized uint16 coordinates provided by the server
    private setCursorPosFromServer(normX: number, normY: number) {
        this.setCursorPosFromDisplayVideo(
            (normX * this.videoState.displayVideoWidth) / 65535,
            (normY * this.videoState.displayVideoHeight) / 65535
        );
    }

    // Sets the cursor position using display-video space coordinates
    // They will be clamped to the rectangle that contains the actual displayed video
    private setCursorPosFromDisplayVideo(x: number, y: number) {
        this.cursorState.absX = Math.min(Math.max(x, 0), this.videoState.displayVideoWidth);
        this.cursorState.absY = Math.min(Math.max(y, 0), this.videoState.displayVideoHeight);
    }

    getCursorCanvas(videoTagElement: InputMediaElement): HTMLCanvasElement {
        let element: HTMLElement | null = document.getElementById(this.cursorCanvasId);
        if (
            element &&
            element instanceof HTMLCanvasElement &&
            element.parentElement === videoTagElement.parentElement
        ) {
            return element;
        } else {
            if (element) {
                // Choose new canvas ID
                let canvasId: string;
                do {
                    canvasId = "canvasId" + Math.round(Math.random() * 10000);
                    element = document.getElementById(canvasId);
                } while (element);
                this.cursorCanvasId = canvasId;
            }
            return this.createCursorCanvasElement(videoTagElement);
        }
    }

    createCursorCanvasElement(videoTagElement: InputMediaElement): HTMLCanvasElement {
        let cursorCanvas: HTMLCanvasElement = document.createElement("canvas");
        cursorCanvas.id = this.cursorCanvasId;
        cursorCanvas.style.touchAction = "none";
        cursorCanvas.style.pointerEvents = "none";
        // This ensures the browser puts the cursor on its own layer, putting transform on the GPU.
        cursorCanvas.style.willChange = "transform";
        this.setCursorCanvasPosition(cursorCanvas, videoTagElement);
        videoTagElement.insertAdjacentElement("afterend", cursorCanvas);

        return cursorCanvas;
    }

    private setCursorCanvasPosition(
        cursorCanvas: HTMLCanvasElement,
        videoTagElement: InputMediaElement
    ) {
        const zIndex = zIndexDefault;
        const bounding = videoTagElement.getBoundingClientRect();
        const parentBounding =
            videoTagElement.parentElement?.getBoundingClientRect() ?? new DOMRect(0, 0, 0, 0);
        const translationLimits = this.getTranslationLimits();
        const left =
            bounding.left -
            parentBounding.left -
            this.videoOffsetX +
            translationLimits.horizontal +
            (this.videoZoomFactor - 1) * this.videoState.leftPadding;
        const top =
            bounding.top -
            parentBounding.top -
            this.videoOffsetY +
            translationLimits.vertical +
            (this.videoZoomFactor - 1) * this.videoState.topPadding;

        cursorCanvas.style.position = "absolute";
        cursorCanvas.style.left = left + "px";
        cursorCanvas.style.top = top + "px";
        cursorCanvas.style.zIndex = zIndex;

        this.updateCursorCanvasState(cursorCanvas);
    }

    clientAspectGreater(client: number, session: number): boolean {
        let diff = Math.abs(client - session);
        if (diff < 0.01) {
            // Close enough to be considered the same.
            return false;
        } else {
            return client > session;
        }
    }

    private updateVideoState(videoElement: InputMediaElement) {
        if (videoElement.videoWidth <= 0 || videoElement.videoHeight <= 0) {
            // No session size yet, so ignore this state update.
            return;
        }

        let scalingFactor = 1.0;
        let topPadding = 0.0;
        let leftPadding = 0.0;
        let videoRatio = videoElement.videoWidth / videoElement.videoHeight;
        let elementRatio = videoElement.clientWidth / videoElement.clientHeight;

        let displayVideoWidth: number;
        let displayVideoHeight: number;

        // There can be padding, either above/below (letterbox) or
        // left/right (pillarbox) of the video (remote session) content.
        // Take ratio of element's actual width(height) and video's real width(height).
        // (Ratio of CSS pixels to server pixels.)
        // That will be the scaling factor for the entire video;
        // the video aspect doesn't change.
        // Use that scaling factor as multiplier for received event co-ordinates,
        // *after* those co-ordinates have been corrected for the padding bars.

        if (this.clientAspectGreater(elementRatio, videoRatio)) {
            // Pillarbox
            scalingFactor = videoElement.clientHeight / videoElement.videoHeight;
            displayVideoWidth = videoElement.videoWidth * scalingFactor;
            displayVideoHeight = videoElement.videoHeight * scalingFactor;
            let totalPadding = videoElement.clientWidth - displayVideoWidth;
            leftPadding = totalPadding / 2;
        } else {
            // Letterbox
            scalingFactor = videoElement.clientWidth / videoElement.videoWidth;
            displayVideoWidth = videoElement.videoWidth * scalingFactor;
            displayVideoHeight = videoElement.videoHeight * scalingFactor;
            let totalPadding = videoElement.clientHeight - displayVideoHeight;
            topPadding = totalPadding / 2;
        }

        let horizontalScaleFactor = 1;
        let verticalScaleFactor = 1;

        if (this.videoState.displayVideoWidth && this.videoState.displayVideoHeight) {
            horizontalScaleFactor = displayVideoWidth / this.videoState.displayVideoWidth;
            verticalScaleFactor = displayVideoHeight / this.videoState.displayVideoHeight;
            // Scale our absolute coordinates to the new coordinate space
            this.cursorState.absX *= horizontalScaleFactor;
            this.cursorState.absY *= verticalScaleFactor;
        }

        this.videoState.displayVideoWidth = displayVideoWidth;
        this.videoState.displayVideoHeight = displayVideoHeight;
        this.videoState.scalingFactor = scalingFactor;
        this.videoState.topPadding = topPadding;
        this.videoState.leftPadding = leftPadding;
        this.videoState.videoWidth = videoElement.videoWidth;
        this.videoState.videoHeight = videoElement.videoHeight;
        // Scale translations to the new coordinate space
        this.applyVideoTransforms(
            this.videoOffsetX * horizontalScaleFactor,
            this.videoOffsetY * verticalScaleFactor,
            this.videoZoomFactor
        );

        this.safeZoneHandler.updateVideoState(
            this.videoState.topPadding,
            this.videoState.leftPadding
        );
        this.sendSafeZone();
    }

    private updateCursorCanvasState(cursorCanvas: HTMLCanvasElement) {
        // Cache the graphics context for efficiency
        let ctx = cursorCanvas.getContext("2d");
        if (ctx) {
            this.cursorCanvasState.graphicsContext = ctx;

            const dpr = window.devicePixelRatio;
            // Make sure we re-render the cursor if the DPR changed
            if (this.cursorCanvasState.dpiRatio != dpr) {
                Log.i("{8bacfa5}", "{b786ba9}", dpr);

                this.cursorCanvasState.dpiRatio = dpr;
                this.cursorCanvasState.imageChanged = true;
                this.cursorCanvasState.imageDimensionsChanged = true;
                this.scheduleCursorDraw();
            }
        }
    }

    private getCursorScaling(ratio: number): number {
        // Calculate cursor scaling factor - that is the multiplier used to
        // convert the cursor's pixel size to physical pixels.
        // To avoid blurred cursors this should be an integer multiplier
        // of the cursor's size.

        // Don't factor in the stream size because it will cause a mismatch in cursor size between
        // the hardware cursor the user is used to and our software cursor.
        // On Windows, the cursor scale rounds down to 100%, 150%, 200%, 300%, etc. Note there's no 250%.
        // To avoid non-integer scaling, round down to 100%, 200%, etc. except for the range [150, 200).
        // These will be too small at 100%, so use 200% for them instead.
        // On macOS, only 100% or 200% scaling will be visible here, so this algorithm will not change the scaling.

        // Floating point threshold to avoid in-exact numbers messing up these calculations.
        const threshold = 0.001;
        // Special case [150, 200) to be 200% since 100% will be too small.
        if (ratio >= 1.5 - threshold && ratio < 2 - threshold) {
            return 2;
        }
        // Avoid setting the scaling to 0 if dpiRatio < 1
        return Math.max(1, Math.floor(ratio + threshold));
    }

    // Input coordinates must be pre-scaled to account for the session size:
    drawCursor(absolute: boolean, x: number, y: number) {
        if (!this.cursorCanvas) {
            return;
        }
        if (!this.touchListener && absolute) {
            const translationLimits = this.getTranslationLimits();
            this.drawCursorPadded(
                x * this.videoZoomFactor + this.videoOffsetX - translationLimits.horizontal,
                y * this.videoZoomFactor + this.videoOffsetY - translationLimits.vertical,
                this.videoState.leftPadding,
                this.videoState.topPadding
            );
        } else {
            this.hideCursor(this.cursorCanvas);
        }
    }

    private parseStyle(cursorStyle: string, cursorCacheId: number = -1, cursorScale: number = 1) {
        if (cursorStyle && cursorStyle.startsWith("url")) {
            //Log.d(LOGTAG,'Creating cursor');
            // Parse from cursor style, which is 'url(data<mime_type>;base64,<image_data_string>) <hotspotX> <hotspotY>, auto'
            let endIndex = cursorStyle.indexOf(")");
            let imageStr = cursorStyle.substring(4, endIndex);

            let image: HTMLImageElement;
            let imageAlreadyDecoded = false;
            if (cursorCacheId > -1) {
                const entry = this.cursorCache[cursorCacheId].imageElement;
                if (!entry) {
                    image = new Image();
                    this.cursorCache[cursorCacheId].imageElement = image;
                } else {
                    image = entry;
                    if (image.width === 0 && image.height === 0) {
                        // We already started decoding this image. When the existing decode finishes,
                        // that callback will update the cursor. We don't need to do anything else.
                        this.cursorState.imagePendingDecode = image;
                        return;
                    }
                    imageAlreadyDecoded = true;
                }
            } else {
                image = new Image();
            }
            if (!imageAlreadyDecoded) {
                if (!image.decode && image.decoding) {
                    image.decoding = "sync";
                }
                image.src = imageStr;
            }

            let updateImage = () => {
                let hotspotEndIndex = cursorStyle.indexOf(",", endIndex + 1);
                if (hotspotEndIndex < 0) {
                    hotspotEndIndex = cursorStyle.length;
                }
                let hotspotStr = cursorStyle.substring(endIndex + 1, hotspotEndIndex).trim();
                let xEndIndex = hotspotStr.indexOf(" ");
                let yEndIndex = hotspotStr.indexOf(",", xEndIndex + 1);
                if (yEndIndex < 0) {
                    yEndIndex = hotspotStr.length;
                }
                let hotspotX = hotspotStr.substring(0, xEndIndex);
                let hotspotY = hotspotStr.substring(xEndIndex + 1, yEndIndex);

                if (image.width == 0 || image.height == 0) {
                    // If the image really hasn't been decoded by the time this function is called,
                    // just give up and continue with the previous cursor.
                    return;
                }
                this.cursorCanvasState.imageChanged = true;
                this.cursorCanvasState.imageDimensionsChanged =
                    !this.cursorState.image ||
                    image.width !== this.cursorState.image.width ||
                    image.height !== this.cursorState.image.height;

                this.cursorState.image = image;
                this.cursorState.hotspotX = parseInt(hotspotX) | 0;
                this.cursorState.hotspotY = parseInt(hotspotY) | 0;
                this.cursorState.width = image.width;
                this.cursorState.height = image.height;
                this.cursorState.scale = cursorScale;
                this.scheduleCursorDraw();
            };
            if (!imageAlreadyDecoded && image.decode) {
                // Mark this image as pending decode. When decode succeeds or fails, the cursor will
                // only be updated if this image is still the one pending decode.
                // If this changes in the meantime, that means some other decode callback will update
                // the cursor or it's been updated with an already-decoded image.
                this.cursorState.imagePendingDecode = image;
                // Wait until the image is decoded before using it.
                image
                    .decode()
                    .then(() => {
                        if (this.cursorState.imagePendingDecode === image) {
                            this.cursorState.imagePendingDecode = undefined;
                            updateImage();
                        }
                    })
                    .catch(_encodingError => {
                        if (this.cursorState.imagePendingDecode === image) {
                            this.cursorState.imagePendingDecode = undefined;
                            // Switch to fallback cursor
                            this.parseStyle(
                                this.makeCursorUrl(
                                    defaultCursor.imageStr,
                                    defaultCursor.mimeTypeStr,
                                    defaultCursor.hotspotX,
                                    defaultCursor.hotspotY
                                )
                            );
                        }
                    });
            } else {
                this.cursorState.imagePendingDecode = undefined;
                updateImage();
            }
        } else if (cursorStyle && cursorStyle === "none") {
            //Log.d("{8bacfa5}", "{8e8afab}", this.cursorCanvas.width, this.cursorCanvas.height);
            this.hideCursor(this.cursorCanvas!);
        } else {
            // Try to use in-built cursors
            let styleIndex = this.cursorStyles.indexOf(cursorStyle);
            if (styleIndex) {
                let cursor = Cursors[styleIndex];
                if (cursor) {
                    this.parseStyle(
                        this.makeCursorUrl(
                            cursor.imageStr,
                            cursor.mimeTypeStr,
                            cursor.hotspotX,
                            cursor.hotspotY
                        )
                    );
                    return;
                }
            }
            // Switch to fallback cursor
            this.parseStyle(
                this.makeCursorUrl(
                    defaultCursor.imageStr,
                    defaultCursor.mimeTypeStr,
                    defaultCursor.hotspotX,
                    defaultCursor.hotspotY
                )
            );
        }
    }

    // Draws a cursor on the cursor canvas, at position (x, y)
    // Pass in padding offsets (in CSS pixels) relative to the session element's container.
    drawCursorPadded(x: number, y: number, leftPadding: number, topPadding: number) {
        if (!this.cursorCanvas) {
            return;
        }

        const dpr = this.cursorCanvasState.dpiRatio;
        const cursorScaling = this.getCursorScaling(dpr / this.cursorState.scale);
        const hotspotScale = cursorScaling / dpr;
        const adjustedX = x - this.cursorState.hotspotX * hotspotScale + leftPadding;
        const adjustedY = y - this.cursorState.hotspotY * hotspotScale + topPadding;

        // These coordinates are in CSS pixels, which will eventually be converted by the browser
        // to screen pixels by multiplying by DPR. Unless a zoom is in progress, make sure our cursor
        // gets rendered on a pixel boundary so it's not blurry. Do not try to round during a zoom
        // to avoid cursor wiggle.
        let scaleX = adjustedX * dpr;
        let scaleY = adjustedY * dpr;
        if (!this.zoomInProgress) {
            scaleX = Math.round(scaleX);
            scaleY = Math.round(scaleY);
        }

        scaleX = scaleX / dpr;
        scaleY = scaleY / dpr;

        if (this.cursorCanvasState.imageChanged && this.cursorState.image) {
            this.cursorCanvasState.imageChanged = false;

            const cursorPixelWidth = this.cursorState.width * cursorScaling;
            const cursorPixelHeight = this.cursorState.height * cursorScaling;
            const ctx = this.cursorCanvasState.graphicsContext!;
            ctx.clearRect(0, 0, this.cursorCanvas.width, this.cursorCanvas.height);
            if (this.cursorCanvasState.imageDimensionsChanged) {
                this.cursorCanvasState.imageDimensionsChanged = false;

                // Style dimensions are in CSS pixels, but the canvas dimensions are in screen pixels.
                // The style dimensions will be scaled by the browser back up to correct number of
                // screen pixels
                this.cursorCanvas.style.width = cursorPixelWidth / dpr + "px";
                this.cursorCanvas.style.height = cursorPixelHeight / dpr + "px";
                // We need an integer number of pixels here, so make sure we don't cut off an edge
                this.cursorCanvas.width = Math.ceil(cursorPixelWidth);
                this.cursorCanvas.height = Math.ceil(cursorPixelHeight);
                // Changing the canvas dimensions resets the context to its default settings.
                // So, we need to reapply these every time the cursor dimensions change
                ctx.scale(1, 1);
                ctx.imageSmoothingEnabled = false;
            }
            ctx.drawImage(this.cursorState.image, 0, 0, cursorPixelWidth, cursorPixelHeight);
        }
        if (!this.cursorCanvasState.showing) {
            this.cursorCanvasState.showing = true;
            this.cursorCanvas.style.visibility = "visible";
        }
        this.cursorCanvas.style.transform = "translate(" + scaleX + "px, " + scaleY + "px)";
    }

    hideCursor(canvas: HTMLCanvasElement) {
        if (this.cursorCanvasState.showing) {
            this.cursorCanvasState.showing = false;
            canvas.style.visibility = "hidden";
            this.cursorState.image = undefined;
        }
    }

    updateCursorStyle(style: string) {
        this.cursorState.style = style;
    }

    handleSystemCursor(id: number, x: number | null, y: number | null) {
        if (!this.cursorState.absPositioning && id !== 0 && x !== null && y !== null) {
            // Only accept the new server position if we're transitioning from a hidden to visible cursor.
            this.setCursorPosFromServer(x, y);
        }
        this.setCursorMovementAbsolute(this.cursorStyles[id] != "none");
        this.updatePointerLock();

        this.updateCursorStyle(this.cursorStyles[id]);
        this.updateHardwareCursor();
        this.scheduleCursorDraw();
    }

    handleBitmapCursor(
        id: number,
        hotspotX: number,
        hotspotY: number,
        mimeType: string,
        image: string,
        x: number | null,
        y: number | null,
        scale?: number
    ) {
        let cursor: CursorExt;
        if (image.length > 0) {
            // Cache cursor
            this.cursorCache[id] = {
                imageStr: image,
                hotspotX: hotspotX,
                hotspotY: hotspotY,
                mimeTypeStr: mimeType,
                // Intentionally check for scale to be not undefined AND not zero.
                // If the server doesn't support the cursor scale value, it will not include it or set it to zero
                scale: scale ? scale : 1
            };
        }
        cursor = this.cursorCache[id];
        if (cursor === undefined) {
            Log.e("{8bacfa5}", "{0196577}", id);
            return;
        }

        if (!this.cursorState.absPositioning && id !== 0 && x !== null && y !== null) {
            // Only accept the new server position if we're transitioning from a hidden to visible cursor.
            this.setCursorPosFromServer(x, y);
        }
        this.setCursorStyleImageInternal(cursor, id);
    }

    makeCursorUrl(image: string, mimeType: string, hotspotX: number, hotspotY: number) {
        let imghdr = "data:" + mimeType + ";base64,";
        let imgdata = imghdr + image;
        return "url(" + imgdata + ") " + hotspotX + " " + hotspotY;
    }

    setCursorStyleImageInternal(cursor: CursorExt, cursorCacheId: number) {
        this.setCursorMovementAbsolute(true);
        this.updatePointerLock();

        let cursorStyle =
            this.makeCursorUrl(
                cursor.imageStr,
                cursor.mimeTypeStr,
                cursor.hotspotX,
                cursor.hotspotY
            ) + ", auto";
        this.updateCursorStyle(cursorStyle);
        this.updateHardwareCursor();
        this.parseStyle(cursorStyle, cursorCacheId, cursor.scale);
    }

    updateHardwareCursor() {
        const target = this.videoTagElement;
        const showDefault =
            !this.isUserInputEnabled() ||
            (this.shouldPointerLock() && !this.isPointerLocked()) ||
            this.cursorType == CursorType.FREE;
        if (showDefault) {
            // If we're in a case where we're not accepting user input, show the hardware cursor.
            // This makes sure they can see where their cursor is pointing.
            target.style.cursor = "default";
        } else if (this.cursorCanvas) {
            target.style.cursor = "none";
        } else {
            target.style.cursor = this.cursorState.style;
        }
    }

    private scheduleCursorDraw() {
        this.cursorCanvasState.dirty = true;
    }

    private setCursorMovementAbsolute(absolute: boolean) {
        this.cursorState.absPositioning = absolute;
        if (!absolute) {
            this.cursorState.remX = 0;
            this.cursorState.remY = 0;
        }
    }

    setCursorConfinement(confine: boolean) {
        this.cursorState.confined = confine;
        this.updatePointerLock();
    }

    fullscreenEventHandler(event: Event) {
        const doc: any = window.document;
        const fullscreen = !!(
            document.fullscreen ||
            doc.webkitIsFullScreen == true ||
            doc.mozFullScreen ||
            doc.msFullscreenElement
        );
        Log.d("{8bacfa5}", "{77df54c}", (fullscreen ? "fullscreen" : "not fullscreen"));

        this.fullscreen = fullscreen;
        if (fullscreen) {
            // If we're entering fullscreen, we must be in a user interaction.
            this.updatePointerLock(true);
        } else {
            // If we left fullscreen, don't try to take the pointer lock again until we get a user interaction.
            this.allowPointerLock = false;
            this.updatePointerLock();
        }

        this.resized(event);

        if (fullscreen) {
            if (window.isSecureContext) {
                const keyboard = (<any>window.navigator).keyboard;
                // Not all browsers support navigator.keyboard and keyboard.lock
                if (keyboard && keyboard.lock) {
                    // Allow Alt+Tab to work as expected.
                    // Lock and capture other keys - sequences (generally Ctrl) with these keys cause issues
                    keyboard.lock([
                        // Leaves fullscreen if entered by our button
                        "Escape",

                        // Leaves fullscreen if entered by browser menu or F11 key (or programmatically, without this lock)
                        "F11",

                        // Navigates browser
                        "BrowserBack",
                        "BrowserForward",
                        "BrowserRefresh",
                        "BrowserHome",
                        "BrowserFavorites",
                        "BrowserSearch",
                        "BrowserStop",
                        // Power control
                        "Sleep",
                        "Power",
                        "WakeUp",

                        // ChromeOS interactions
                        "KeyT", // Ctrl+Alt+T opens Terminal tab on ChromeOS
                        "KeyZ", // Ctrl+Alt+Z opens Chrome spoken feedback (ChromeOS?)
                        "Slash", // Ctrl+Alt+/ opens Keyboard shortcuts on ChromeOS

                        // ChromeOS shelf icons (Alt+<digit> clicks icon on shelf)
                        "Digit1",
                        "Digit2",
                        "Digit3",
                        "Digit4",
                        "Digit5",
                        "Digit6",
                        "Digit7",
                        "Digit8",
                        "Digit9",

                        // ChromeOS System settings, specific apps, etc.
                        "KeyM", // Alt+Shift+M opens Files app, Ctrl+Search+M opens Magnifier
                        "KeyD", // Ctrl+Search+D opens Magnifier
                        "KeyN", // Alt+Shift+N opens notifications
                        "KeyS", // Alt+Shift+S opens status area
                        "KeyK", // Shift+Search+K shows list of input methods/layouts
                        "KeyL", // Alt+Shift+L highlights launcher
                        "Space", // Ctrl+Space/Ctrl+Shift+Space switch to next/previous input method

                        // Generic controls
                        "PrintScreen", // Captures screenshot

                        // Start new apps
                        "LaunchApp1",
                        "LaunchApp2",
                        "LaunchMail",

                        // macOS
                        "Comma", // Cmd+Comma opens app preferneces
                        "Semicolon", // Cmd+Shift+Semicolon opens Spelling and grammer

                        "ArrowLeft", // Cmd+Left opnavigates back
                        "ArrowRight", // Cmd+Right navigates forwards
                        "BracketLeft", // Cmd+[ navigates back
                        "BracketRight", // Cmd+] navigates forwards
                        "KeyW", // Cmd+W closes current Tab
                        "KeyQ", // Cmd+Q closes application (long hold)
                        "KeyR", // Cmd+R refreshes current Tab
                        "KeyY", // Cmd+Y opens History
                        "KeyO", // Cmd+O opens Open File window
                        "KeyP", // Cmd+P opens Print window
                        "KeyF", // Cmd+F opens Find
                        "KeyG" // Cmd+G opens Repeat Find / Find Again
                    ]);
                }
            }

            this.videoTagElement.onclick = null;
            this.videoTagElement.removeAttribute("controls");

            this.updateHardwareCursor();
        } else {
            this.releasePressedKeys();

            if (window.isSecureContext) {
                const keyboard = (window.navigator as any).keyboard;
                // Not all browsers support navigator.keyboard and keyboard.unlock
                if (keyboard && keyboard.unlock) {
                    keyboard.unlock();
                }
            }
        }
    }

    pointerLockChange(_: Event) {
        if (document.pointerLockElement instanceof HTMLElement) {
            this.pointerLockElement = document.pointerLockElement;
        } else {
            this.pointerLockElement = null;
            if (this.shouldPointerLock()) {
                Log.i("{8bacfa5}", "{d4b3de3}");
                // If the user explicitly leaves pointer lock, we won't be able to automatically
                // go in and out of it without another user interaction
                this.allowPointerLock = false;
                // User left pointer lock, so this will display the hardware cursor
                this.updateHardwareCursor();
            }
        }
    }

    pointerLockError(_: Event) {
        // We use the promise for pointer lock errors when possible
        if (this.pointerLockReturnsPromise) {
            return;
        }
        this.pointerLockElement = null;
        // We don't know whether we're using the Promise-based requestPointerLock until we actually call it.
        // If the first call to the non-Promise rPL happens to request unadjustedMovement, it will fail.
        // Handle that case here by disabling unadjusted movement.
        if (this.unadjustedMovementAllowed) {
            this.unadjustedMovementAllowed = false;
            Log.e("{8bacfa5}", "{7599808}");
            this.updatePointerLock();
        } else {
            Log.e("{8bacfa5}", "{d633cd1}");
            this.handlePointerLockFailed();
        }
    }

    private handlePointerLockFailed() {
        this.updateHardwareCursor();
    }

    shouldPointerLock() {
        return (
            this.isUserInputEnabled() &&
            this.cursorType != CursorType.FREE &&
            this.focused &&
            (!this.cursorState.absPositioning || this.cursorState.confined) &&
            (this.streamClient.getLdatHandler()?.allowPointerLock() ?? true)
        );
    }

    isPointerLocked() {
        return this.pointerLockElement !== null;
    }

    updatePointerLock(userInteraction?: boolean) {
        if (userInteraction) {
            this.allowPointerLock = true;
        }
        const shouldLock = this.shouldPointerLock();
        const isLocked = this.isPointerLocked();
        const shouldUnadjusted = this.unadjustedMovementAllowed && !this.cursorState.absPositioning;
        const isUnadjusted = this.unadjustedMovementActive;
        if (
            this.allowPointerLock &&
            shouldLock &&
            (!isLocked || shouldUnadjusted !== isUnadjusted)
        ) {
            const target = this.videoTagElement;
            // Optimistically set pointerLockElement before we know pointer lock succeeded.
            // If pointer lock fails, we'll set it back to null.
            // This ensures isPointerLocked() returns true as soon as updatePointerLock is called.
            // This also fixes a bug where we wouldn't exit pointer lock if a request was still pending.
            this.pointerLockElement = target;
            // On Andorid, PointerLock is not supported, but target.rPL returns true. If we see rPL
            // failing consecutively for 3 times with UnknownError, we take it as unsupported.
            if (!!target.requestPointerLock && this.consecutivePointerLockFailedAttempts < 3) {
                if (this.isChromium) {
                    // Note that for this to be active the flag chrome://flags/#enable-pointer-lock-options
                    // needs to be enabled.  Otherwise, it will have no effect.
                    const options = {
                        unadjustedMovement: shouldUnadjusted
                    };
                    const promise = <Promise<void> | undefined>(
                        (target as any).requestPointerLock(options)
                    );
                    if (promise) {
                        this.pointerLockReturnsPromise = true;
                        promise
                            .then(() => {
                                this.unadjustedMovementActive = shouldUnadjusted;
                                // Reset the counter
                                this.consecutivePointerLockFailedAttempts = 0;
                            })
                            .catch((ex: DOMException) => {
                                this.pointerLockElement = null;
                                // Although we don't rely on it right now, this Promise is rejected before
                                // pointerlockerror is called
                                if (ex.name === "NotSupportedError" && shouldUnadjusted) {
                                    Log.i("{8bacfa5}", "{08fd91e}");
                                    this.unadjustedMovementAllowed = false;
                                    this.updatePointerLock();
                                } else {
                                    // Android pointer lock fires PointerLockResult::kUnknownError
                                    // https://bugs.chromium.org/p/chromium/issues/detail?id=1235139
                                    if (ex.name == "UnknownError") {
                                        this.consecutivePointerLockFailedAttempts++;
                                        Log.w("{8bacfa5}", "{9f2ba9f}", this.consecutivePointerLockFailedAttempts);
                                    } else {
                                        Log.e("{8bacfa5}", "{4cdd98f}", ex.name, ex.message);
                                    }
                                    this.handlePointerLockFailed();
                                }
                            });
                    }
                } else {
                    target.requestPointerLock();
                }
            }
        } else if (!shouldLock && isLocked) {
            this.pointerLockElement = null;
            if (document.exitPointerLock) {
                document.exitPointerLock();
            }
        }
    }

    private enableBackPrevention() {
        if (!this.historyProtected) {
            this.historyProtected = true;
            history.pushState(null, document.title, location.href);
            window.addEventListener("popstate", this.popStateFunc);
        }
    }

    private disableBackPrevention() {
        if (this.historyProtected) {
            window.removeEventListener("popstate", this.popStateFunc);
            history.back();
            this.historyProtected = false;
        }
    }

    private isTrueTouchActive() {
        return this.touchListener && !this.isVirtualKeyboardVisible;
    }

    private enableUserInput(inputs: InputType): boolean {
        const wereAllDisabled = !this.isUserInputEnabled();
        const addedInputs = (this.enabledInputs ^ inputs) & inputs;
        if (!addedInputs) {
            return false;
        }
        this.enabledInputs |= addedInputs;
        // set once only if all were disabled
        if (wereAllDisabled) {
            this.rendering = true;
            window.requestAnimationFrame(this.preRenderFunc);
        }

        if (addedInputs & InputType.Gamepad) {
            this.gamepadHandler.enableUserInput();
        }

        if (addedInputs & InputType.Mouse) {
            // Do not add mouse click handlers for iOS. Hardware mouse is not supported
            // and we can't accurately filter out mouse events that the browser converts
            // from touch events.
            /// TODO: Look into using pointer events on iOS and other platforms
            if (!IsiDevice(this.platformDetails)) {
                this.addClickListeners();
            }
            this.documentAddEventListener("wheel", this.mousewheelFunc, this.nonpassiveOptions);
            this.addMoveListener();
        }

        if (addedInputs & InputType.Keyboard) {
            this.documentAddEventListener("keydown", this.keydownFunc);
            this.documentAddEventListener("keyup", this.keyupFunc);

            this.textInputElement?.addEventListener("input", this.textInputFunc);
            this.textInputElement?.addEventListener("compositionstart", this.textCompositionFunc);
            this.textInputElement?.addEventListener("compositionupdate", this.textCompositionFunc);
            this.textInputElement?.addEventListener("compositionend", this.textCompositionFunc);
        }

        if (addedInputs & InputType.Touch) {
            if (this.isTrueTouchActive()) {
                this.touchListener!.start();
            } else if (this.gestureDetector) {
                this.gestureDetector.start();
            }
        }

        if (wereAllDisabled) {
            if (this.cursorCanvas) {
                this.cursorCanvas.style.display = "block";
                this.videoTagElement.style.cursor = "none";
            }

            // Prevent Back navigation
            if (this.preventNavigation) {
                this.enableBackPrevention();
            }
        }
        return true;
    }

    private disableUserInput(inputs: InputType): boolean {
        const removedInputs = this.enabledInputs & inputs;
        if (!removedInputs) {
            return false;
        }
        this.enabledInputs ^= removedInputs;
        // only reset if all disabled
        const allDisabled = !this.isUserInputEnabled();
        if (allDisabled) {
            this.rendering = false;
        }

        if (removedInputs & InputType.Gamepad) {
            this.gamepadHandler.disableUserInput();
        }

        if (removedInputs & InputType.Mouse) {
            if (!IsiDevice(this.platformDetails)) {
                this.removeClickListeners();
            }
            this.documentRemoveEventListener("wheel", this.mousewheelFunc, this.nonpassiveOptions);
            this.removeMoveListener();
        }

        if (removedInputs & InputType.Keyboard) {
            this.documentRemoveEventListener("keydown", this.keydownFunc);
            this.documentRemoveEventListener("keyup", this.keyupFunc);
            this.textInputElement?.removeEventListener("input", this.textInputFunc);
            this.textInputElement?.removeEventListener(
                "compositionstart",
                this.textCompositionFunc
            );
            this.textInputElement?.removeEventListener(
                "compositionupdate",
                this.textCompositionFunc
            );
            this.textInputElement?.removeEventListener("compositionend", this.textCompositionFunc);
        }

        if (removedInputs & InputType.Touch) {
            if (this.gestureDetector) {
                this.gestureDetector.stop();
            }
            if (this.touchListener) {
                this.touchListener.stop();
            }
            if (this.statsGestureTimerId !== 0) {
                this.clearStatsGestureTimer();
            }
            this.touchDelay.clear();
        }

        if (allDisabled) {
            if (this.cursorCanvas) {
                this.cursorCanvas.style.display = "none";
                this.videoTagElement.style.cursor = "default";
            }

            if (this.preventNavigation) {
                this.disableBackPrevention();
            }
        }
        return true;
    }

    public toggleUserInput(enable: boolean, inputs?: InputType) {
        Log.d("{8bacfa5}", "{2424824}", enable, inputs);
        const changedInputs = inputs ?? InputType.All;
        const state = enable
            ? this.enableUserInput(changedInputs)
            : this.disableUserInput(changedInputs);
        if (state) {
            this.updatePointerLock();
        }
    }

    public isUserInputEnabled(): boolean {
        return this.enabledInputs !== InputType.None;
    }

    private setRawUpdate(newState: RawUpdateState) {
        if (!this.supportsRawUpdate || this.rawUpdateState === newState) {
            return;
        }
        const hasMouseInput = this.enabledInputs & InputType.Mouse;
        if (hasMouseInput) {
            this.removeMoveListener();
        }
        this.rawUpdateState = newState;
        if (hasMouseInput) {
            this.addMoveListener();
        }
        switch (newState) {
            case RawUpdateState.ON_4MS:
                this.rawCoalesceInterval = 4;
                break;
            case RawUpdateState.ON_8MS:
                this.rawCoalesceInterval = 8;
                break;
            case RawUpdateState.ON_16MS:
                this.rawCoalesceInterval = 16;
                break;
            default:
                this.rawCoalesceInterval = 0;
                break;
        }
    }

    private addMoveListener() {
        if (this.cursorType == CursorType.FREE) {
            this.rsdmmHandler.setMoveType(this.getMoveEventName());
            this.videoAddEventListener(this.getMoveEventName(), this.getFreeMoveFunc());
        } else if (this.rawUpdateState !== RawUpdateState.OFF) {
            this.rsdmmHandler.setMoveType("pointerrawupdate");
            this.videoAddEventListener("pointerrawupdate", this.pointerRawUpdateFunc);
        } else {
            this.rsdmmHandler.setMoveType(this.getMoveEventName());
            this.videoAddEventListener(this.getMoveEventName(), this.pointerMoveFunc);
        }
    }

    private removeMoveListener() {
        if (this.cursorType == CursorType.FREE) {
            this.videoRemoveEventListener(this.getMoveEventName(), this.getFreeMoveFunc());
        } else if (this.rawUpdateState !== RawUpdateState.OFF) {
            this.videoRemoveEventListener("pointerrawupdate", this.pointerRawUpdateFunc);
        } else {
            this.videoRemoveEventListener(this.getMoveEventName(), this.pointerMoveFunc);
        }
    }

    private addClickListeners() {
        if (this.cursorType == CursorType.FREE && this.supportsPointerEvents) {
            this.rsdmmHandler.setDownUpTypes("pointerdown", "pointerup");
            this.videoAddEventListener("pointerdown", this.pointerdownFunc);
            this.videoAddEventListener("pointerup", this.pointerupFunc);
        } else {
            this.rsdmmHandler.setDownUpTypes("mousedown", "mouseup");
            this.videoAddEventListener("mousedown", this.mousedownFunc);
            this.videoAddEventListener("mouseup", this.mouseupFunc);
        }
    }

    private removeClickListeners() {
        if (this.cursorType == CursorType.FREE && this.supportsPointerEvents) {
            this.videoRemoveEventListener("pointerdown", this.pointerdownFunc);
            this.videoRemoveEventListener("pointerup", this.pointerupFunc);
        } else {
            this.videoRemoveEventListener("mousedown", this.mousedownFunc);
            this.videoRemoveEventListener("mouseup", this.mouseupFunc);
        }
    }

    onVideoBlur(_: Event) {
        this.focused = false;
        Log.d("{8bacfa5}", "{8744dbe}");
        this.releasePressedKeys();
    }

    onVideoFocus(_: Event) {
        this.focused = true;
        // If we receive focus while in fullscreen, the user probably alt tabbed back to us.
        if (this.fullscreen) {
            this.updatePointerLock(true);
        }
        if (this.cursorCanvas) {
            this.updateCursorCanvasState(this.cursorCanvas);
        }
        this.sendSafeZone();
        Log.d("{8bacfa5}", "{cb19d31}");
    }

    private getShiftModifierFlag(evt: KeyboardEvent): number {
        let flag = undefined;

        // WAR for iOS.
        // A complete solution requires iOS-specific keyboard layout map, or mapping to a known layout.
        if (IsiDevice(this.platformDetails)) {
            // When a keyboard event is originated from a virtual keyboard,
            // its `shiftKey` might differ from the one typed by a physical keyboard.
            // For instance, "@" is { keyCode: 50, shiftKey: true } on physical keyboard,
            //                      { keyCode: 50, shiftKey: false } on virtual keyboard (tested with iPhone).
            // To inject correct key on the server, we add SHFIT modifier regardless what `shiftKey` is for
            // some characters that must be shifted.
            // Note that this only works when the server is running US keyboard layout map.
            const keysRequiredToBeShifted = '!@#$%^&*()~_+{}|:"<>?';
            // Further, certain characters must not be shifted, so we explicitly remove the SHIFT modifier.
            const keysRequiredToBeUnshifted = "1234567890`-=[]\\;',./";
            if (evt.key.length === 1) {
                if (keysRequiredToBeShifted.includes(evt.key)) {
                    flag = InputModifierFlags.NVST_MF_SHIFT;
                } else if (keysRequiredToBeUnshifted.includes(evt.key)) {
                    flag = InputModifierFlags.NVST_MF_NONE;
                }
            }
        }

        if (flag === undefined && evt.shiftKey && !evt.code.startsWith("Shift")) {
            flag = InputModifierFlags.NVST_MF_SHIFT;
        }
        return flag ?? InputModifierFlags.NVST_MF_NONE;
    }

    private getModifierFlags(evt: KeyboardEvent): number {
        let flags = InputModifierFlags.NVST_MF_NONE;
        if (evt.ctrlKey && !evt.code.startsWith("Control")) {
            flags |= InputModifierFlags.NVST_MF_CONTROL;
        }
        if (evt.altKey && !evt.code.startsWith("Alt")) {
            flags |= InputModifierFlags.NVST_MF_ALT;
        }
        if (evt.metaKey && !evt.code.startsWith("Meta")) {
            flags |= InputModifierFlags.NVST_MF_META;
        }
        flags |= this.getShiftModifierFlag(evt);
        return flags;
    }

    public sendHeartbeatEvent() {
        // Make sure to revert to previous input state.
        if (this.enabledInputsBeforeUserIdlePendingOverlay !== undefined) {
            this.toggleUserInput(
                false,
                ~this.enabledInputsBeforeUserIdlePendingOverlay & InputType.All
            );
            this.enabledInputsBeforeUserIdlePendingOverlay = undefined;
        }

        this.packetHandler.sendHeartbeatEvent();
    }

    public clearIdleTimeout() {
        this.setUserIdleTimeoutPending(false);
        let timerEvent: StreamingEvent = {
            streamingWarnings: {
                code: RNotificationCode.ClearUserIdleTimeOut
            }
        };
        this.eventEmitter.emit(EVENTS.STREAMING_EVENT, timerEvent);
        this.sendHeartbeatEvent();
        this.callbacks.onUserIdleClear();
    }

    private getIdleEvents(): string[] {
        return [this.getMoveEventName(), "pointerdown", "touchstart"];
    }

    private idleInputListener() {
        if (this._isUserIdleTimeoutPending) {
            this.clearIdleTimeout();
        }
    }

    public setUserIdleTimeoutPending(value: boolean) {
        this._isUserIdleTimeoutPending = value;
        // if input already disabled means we have some overlay already, like go to fullscreen, enable input (if not already) just to
        // listen input for resetting idle timer on server. We could also check if we are in non-fullscreen instead
        // of checking enabledInputs flag. Assumption is client calls toggleUserInput(false) on all popups except userIdleWarning popup.
        if (value && this.enabledInputs !== InputType.All) {
            this.enabledInputsBeforeUserIdlePendingOverlay = this.enabledInputs;
            this.toggleUserInput(true);
        }
        if (value) {
            this.getIdleEvents().forEach(event =>
                this.documentAddEventListener(event, this.idleInputListenerFunc)
            );
        } else {
            // Must clear all idle listeners on false to avoid leaking listeners.
            this.getIdleEvents().forEach(event =>
                this.documentRemoveEventListener(event, this.idleInputListenerFunc)
            );
        }
    }

    releasePressedKeys() {
        if (this.pressedKeys.size == 0) {
            return;
        }
        Log.i("{8bacfa5}", "{7998ee8}", this.pressedKeys.size);
        this.pressedKeys.forEach(keyCode => {
            this.packetHandler.sendKeyboardEvent(PacketId.PACKET_KEYUP, keyCode, 0);
        });
        this.pressedKeys.clear();
    }

    private getMoveEventName() {
        if (this.supportsPointerEvents) {
            return "pointermove";
        } else {
            return "mousemove";
        }
    }

    private getFreeMoveFunc(): Function {
        if (this.supportsPointerEvents) {
            return this.freePointerMoveFunc;
        } else {
            return this.freeMouseMoveFunc;
        }
    }

    private perfCallback(list: PerformanceObserverEntryList) {
        list.getEntriesByType("longtask").forEach(entry => {
            let duration = Math.round(entry.duration);
            RagnarokProfiler.addMainThreadBlockDuration(duration, entry.startTime);
            this.streamClient.updateBlockedDuration(duration);
            Log.i("{8bacfa5}", "{7071359}", duration);
        });
    }

    getVirtualGamepadHandler(): VirtualGamepadHandler {
        return this.gamepadHandler.getVirtualGamepadHandler();
    }

    public sendTextInput(text: ArrayBuffer) {
        this.packetHandler.sendTextInput(text);
    }

    private sendAndClearText(text: string) {
        const encoder = new TextEncoder();
        const unicodeText = encoder.encode(text);
        this.sendTextInput(unicodeText.buffer);

        this.textInputElement!.value = "";
    }

    private hasVirtualKeyCodeMapping(text: String): boolean {
        for (let i = text.length - 1; i >= 0; i--) {
            if (CHAR_TO_VK_MAP.get(text.charAt(i)) === undefined) {
                return false;
            }
        }
        return true;
    }

    private sendCharCodesAndClearText(text: string) {
        // On Android, the NVST_LKB_CAPS is not being set for softKB but it gets set for external kb.
        // We use this.lastLockKeysState to detrmine the CAPS lock state on the server and adjust the
        // ShiftModifier accordingly.
        let isCapsLockOn = false;
        if (this.lastLockKeysState & LockKeyBitMask.NVST_LKB_CAPS_VALID) {
            isCapsLockOn = (LockKeyBitMask.NVST_LKB_CAPS & this.lastLockKeysState) != 0;
        }

        // If we are in this function, it is gauranteed that the input text is comprised of charcodes between
        // 32 and 126 (printable chars in the ascii charset). Process the text char-by-char and send the
        // keycodes directy to the server.
        for (let i = 0; i < text.length; i++) {
            const vkmap = CHAR_TO_VK_MAP.get(text.charAt(i));
            if (!vkmap) {
                Log.e("{8bacfa5}", "{67554be}", text.charAt(i));
                return;
            }

            const keycode = vkmap!.vkCode;
            let flags = vkmap!.shift
                ? InputModifierFlags.NVST_MF_SHIFT
                : InputModifierFlags.NVST_MF_NONE;

            if (keycode >= VIRTUAL_KEYS.CODE_A && keycode <= VIRTUAL_KEYS.CODE_Z) {
                // Only CAPITAL letter keycodes are sent to server (See CODE_TO_VK_MAP in keycodes.ts).
                // Determine the shift modifier based on the CAPS lock state on the server
                if (isCapsLockOn) {
                    flags = ~flags;
                }
            }
            // If shift modifier is to be applied, send the LSHIFT keydown and keyup
            if (flags) {
                this.packetHandler.sendKeyboardEvent(
                    PacketId.PACKET_KEYDOWN,
                    VIRTUAL_KEYS.CODE_LSHIFT,
                    0,
                    performance.now()
                );
            }
            this.packetHandler.sendKeyboardEvent(
                PacketId.PACKET_KEYDOWN,
                keycode,
                flags,
                performance.now()
            );
            this.packetHandler.sendKeyboardEvent(
                PacketId.PACKET_KEYUP,
                keycode,
                flags,
                performance.now()
            );
            if (flags) {
                this.packetHandler.sendKeyboardEvent(
                    PacketId.PACKET_KEYUP,
                    VIRTUAL_KEYS.CODE_LSHIFT,
                    0,
                    performance.now()
                );
            }
        }
        // This will clear any composition that is underway
        this.textInputElement!.value = "";
    }

    private textCompositionHandler(e: CompositionEvent) {
        switch (e.type) {
            case "compositionstart":
                this.textCompositionInProgress = true;
                break;
            case "compositionupdate":
                {
                    // For iOS, composition events occur only for IME langs
                    // For other platforms, when when non-ascii inputs are detected, set IME recommendation
                    const imeRecommendation =
                        IsiDevice(this.platformDetails) || !this.hasVirtualKeyCodeMapping(e.data);
                    const compositionEvent: TextCompositionEvent = {
                        compositionText: e.data,
                        imeRecommendation: imeRecommendation
                    };
                    this.eventEmitter.emit(EVENTS.TEXT_COMPOSITION, compositionEvent);
                }
                break;
            case "compositionend":
                this.textCompositionInProgress = false;
                // Trim the extra space at the end ONLY for Android to not alter the current behaviour but we
                // need to revist this. Having extra space while entering passwords will be incorrect.
                const inputText = this.isAndroidOs ? e.data.trim() : e.data;
                if (this.isAndroidOs && this.hasVirtualKeyCodeMapping(inputText)) {
                    // Loop through the final composed string and send charcodes individually.
                    this.sendCharCodesAndClearText(inputText);
                } else {
                    // Send text as unicode string
                    this.sendAndClearText(inputText);
                }

                // indicate to client that composition has ended
                {
                    const compositionEvent: TextCompositionEvent = { compositionText: "" };
                    this.eventEmitter.emit(EVENTS.TEXT_COMPOSITION, compositionEvent);
                }
                break;
            default:
                break;
        }
    }

    private textInputHandler() {
        // If we are not in text composition mode, send the text to the server
        // When in composition mode, this gets handled in compositionend event
        this.textInputDetected = true;
        if (!this.textCompositionInProgress) {
            if (!this.isAndroidOs) {
                // Send unicode text to server
                this.sendAndClearText(this.textInputElement!.value);
            } else {
                if (!this.hasVirtualKeyCodeMapping(this.textInputElement!.value)) {
                    // Received non-ascii input. Notify client so they can bring
                    // up the client IME dialog.
                    const compositionEvent: TextCompositionEvent = {
                        compositionText: this.textInputElement!.value,
                        imeRecommendation: true
                    };
                    this.eventEmitter.emit(EVENTS.TEXT_COMPOSITION, compositionEvent);

                    // clientIME is only enabled for telco regions. Even when we show the snackbar,
                    // the user might ignore the ime recommendation and continue typing.
                    // So just send the input in unicode to the server.
                    this.sendAndClearText(this.textInputElement!.value);
                } else {
                    // For ascii input, send the keycodes to server.
                    this.sendCharCodesAndClearText(this.textInputElement!.value);
                }
            }
        }
        // On some devices, when in composition mode, sending each character as they are input to the server
        // causes the inputs to be sent in CAPS due to text capitalization settings. Hence when in composition
        // mode, we will allow the system's IME composition to complete and then handle sending the keyCodes to
        // the server
    }

    public setVirtualKeyboardState(visible: boolean) {
        Log.i("{8bacfa5}", "{60b2d45}", visible);
        if (this.isVirtualKeyboardVisible === visible) {
            return;
        }
        this.isVirtualKeyboardVisible = visible;

        if (this.gestureDetector && this.touchListener) {
            if (this.isVirtualKeyboardVisible) {
                this.touchListener.stop();
                this.gestureDetector.start();
            } else {
                this.gestureDetector.stop();
                if (this.videoZoomFactor !== 1) {
                    this.applyVideoTransforms(0, 0, 1);
                }
                this.touchListener.start();
            }
        }
    }

    /** Returns true if virtual keyboard is visible, false otherwise.*/
    public getVirtualKeyboardState(): boolean {
        return this.isVirtualKeyboardVisible;
    }

    public setKeyboardLayout(layout: string) {
        if (layout == CHINESE_TRADITIONAL_LAYOUT) {
            this.tradChineseLayout = true;
        } else {
            this.tradChineseLayout = false;
        }
    }

    private getTranslationLimits(): BoundaryPair {
        return {
            horizontal: (this.videoState.displayVideoWidth * (this.videoZoomFactor - 1)) / 2,
            vertical: (this.videoState.displayVideoHeight * (this.videoZoomFactor - 1)) / 2
        };
    }

    private touchMove(target: HTMLElement, touch: TouchRecord, timestamp: number) {
        // Make sure any delayed mouse clicks happen before we move the cursor again
        this.touchDelay.flushImmediately();

        const targetRect = target.getBoundingClientRect();
        const offsetX = (touch.clientX - targetRect.left) / this.videoZoomFactor;
        const offsetY = (touch.clientY - targetRect.top) / this.videoZoomFactor;

        this.setCursorPosFromOffset(offsetX, offsetY);
        this.scheduleCursorDraw();
        this.packetHandler.sendCursorPos(
            true,
            this.cursorState.absX,
            this.cursorState.absY,
            timestamp
        );
    }

    private applyVideoTransforms(offsetX: number, offsetY: number, zoomFactor: number) {
        // set zoom factor first, so that translation limits are determined based on the new factor
        this.videoZoomFactor = zoomFactor;

        let translationLimits = this.getTranslationLimits();
        // take padding into account
        translationLimits.horizontal -= this.videoState.leftPadding;
        translationLimits.vertical -= this.videoState.topPadding;
        // allow overscroll to bring the bottom of the video to the bottom of the viewport
        // useful when the keyboard is up
        let viewportOverscroll = Math.max(
            0,
            this.videoState.displayVideoHeight -
                this.videoState.viewportHeight +
                2 * this.videoState.topPadding
        );

        let upwardLimit = Math.max(translationLimits.vertical + viewportOverscroll, 0);
        let downwardLimit = Math.max(translationLimits.vertical, 0);
        translationLimits.horizontal = Math.max(translationLimits.horizontal, 0);

        offsetX = Math.min(
            Math.max(offsetX, -1 * translationLimits.horizontal),
            translationLimits.horizontal
        );
        offsetY = Math.min(Math.max(offsetY, -1 * upwardLimit), downwardLimit);

        this.videoTagElement.style.transform = `translate3d(${offsetX}px,${offsetY}px,0px) scale3d(${zoomFactor},${zoomFactor},1)`;

        this.videoOffsetX = offsetX;
        this.videoOffsetY = offsetY;

        this.scheduleCursorDraw();
    }

    public clientRequestVideoTransform(offsetX: number, offsetY: number, zoomFactor: number) {
        // update the viewport in case the client is making the request on a viewport resize, and we haven't been notified yet
        const viewport = (<any>window).visualViewport;
        if (viewport) {
            this.videoState.viewportHeight = viewport.height;
        }
        this.applyVideoTransforms(offsetX, offsetY, zoomFactor);
    }

    shouldPreventDefaultTouch(): boolean {
        // This is called for touch events on the video element, so we can reset the idle timeout.
        // Touch events outside the video element use the idleInputListenerFunc to clear the idle timeout.
        if (this._isUserIdleTimeoutPending) {
            this.clearIdleTimeout();
            return false;
        }
        // keyboard state does not correctly track when using a floating or split keyboard
        // if that is fixed, we can call preventDefault only when the keyboard is up to keep the text element in focus
        // return this.isVirtualKeyboardVisible;
        // let browser restore focus
        return this.focused;
    }

    shouldPreventDefaultKb(evt: KeyboardEvent): boolean {
        if (this.streamClient.getLdatHandler()?.isVisible()) {
            return false;
        }
        if (this.isChromeOs) {
            switch (evt.code) {
                case "Tab":
                    return evt.altKey ? false : true;
                case "ZoomToggle":
                case "SelectTask":
                case "BrightnessDown":
                case "BrightnessUp":
                case "AudioVolumeMute":
                case "AudioVolumeDown":
                case "AudioVolumeUp":
                    return false;
            }
        }

        return true;
    }

    private clearStatsGestureTimer(): void {
        window.clearTimeout(this.statsGestureTimerId);
        this.statsGestureTimerId = 0;
        this.twoFingerTapCount = 0;
        this.threeFingerTapCount = 0;
    }

    tap(target: HTMLElement, timestamp: number, lastTouch: TouchRecord, touchCount: number) {
        this.touchDelay.flushImmediately();
        switch (touchCount) {
            case 1:
                this.touchMove(target, lastTouch, timestamp);
                this.touchDelay.delay(() => {
                    this.emulateMouseClick(MouseButtons.LEFT_CLICK, timestamp);
                });
                break;
            case 2:
                this.twoFingerTapCount++;
                if (this.statsGestureTimerId === 0) {
                    this.statsGestureTimerId = window.setTimeout(() => {
                        if (this.twoFingerTapCount === 2) {
                            this.streamClient.toggleOnScreenStats();
                        } else if (this.twoFingerTapCount === 3) {
                            this.streamClient.toggleOnScreenStats(RagnarokSettings.isInternalUser);
                        }
                        this.clearStatsGestureTimer();
                    }, 300);
                }
                this.emulateMouseClick(MouseButtons.RIGHT_CLICK, timestamp);
                break;
            case 3:
                this.threeFingerTapCount++;
                if (RagnarokSettings.isInternalUser && this.statsGestureTimerId === 0) {
                    this.statsGestureTimerId = window.setTimeout(() => {
                        if (this.threeFingerTapCount === 2) {
                            this.safeZoneHandler.toggleDisplaySafeZone();
                        }
                        this.clearStatsGestureTimer();
                    }, 300);
                }
                this.emulateMouseClick(MouseButtons.MIDDLE_CLICK, timestamp);

                break;
            case 4:
                this.emulateMouseClick(MouseButtons.MOUSE_FOUR, timestamp);
                break;
            case 5:
                this.emulateMouseClick(MouseButtons.MOUSE_FIVE, timestamp);
                break;
        }
    }

    private emulateMouseClick(button: MouseButtons, timestamp: number) {
        this.packetHandler.sendMouseDown(button, timestamp);
        this.touchDelay.delay(() => {
            this.packetHandler.sendMouseUp(button, timestamp);
        });
    }

    holdBegin(target: HTMLElement, timestamp: number, touch: TouchRecord) {
        if (this.videoZoomFactor === 1) {
            this.touchMove(target, touch, timestamp);
            this.touchDelay.delay(() => {
                this.packetHandler.sendMouseDown(MouseButtons.LEFT_CLICK, timestamp);
            });
        }
    }

    holdEnd(target: HTMLElement, timestamp: number) {
        if (this.videoZoomFactor === 1) {
            // Make sure the mouse down from holdBegin is sent before the up
            this.touchDelay.flushImmediately();
            this.packetHandler.sendMouseUp(MouseButtons.LEFT_CLICK, timestamp);
        }
    }

    drag(target: HTMLElement, timestamp: number, touch: TouchRecord) {
        // Drag gesture should pan if part of the video is not visible or streaming a true touch app
        if (
            this.videoZoomFactor !== 1 ||
            this.isVirtualKeyboardVisible ||
            this.touchListener !== undefined
        ) {
            // pan
            this.applyVideoTransforms(
                this.videoOffsetX + touch.deltaX,
                this.videoOffsetY + touch.deltaY,
                this.videoZoomFactor
            );
        } else {
            // mouse move
            this.touchMove(target, touch, timestamp);
        }
    }

    scroll(target: HTMLElement, timestamp: number, touches: TouchRecord[]) {
        if (touches.length > 0) {
            this.packetHandler.sendMouseWheel(Math.sign(touches[0].deltaY), timestamp); // TODO: translate magnitude of drag to magnitude of the scroll
        }
    }

    panZoom(target: HTMLElement, timestamp: number, touches: TouchRecord[]) {
        if (touches.length === 2) {
            this.zoomInProgress = true;

            const firstTouch = touches[0];
            const secondTouch = touches[1];

            const currentDistance = Math.hypot(
                firstTouch.clientX - secondTouch.clientX,
                firstTouch.clientY - secondTouch.clientY
            );
            const previousDistance = Math.hypot(
                firstTouch.clientX - firstTouch.deltaX - (secondTouch.clientX - secondTouch.deltaX),
                firstTouch.clientY - firstTouch.deltaY - (secondTouch.clientY - secondTouch.deltaY)
            );

            const bounding = this.videoTagElement.getBoundingClientRect();
            const translationLimits = this.getTranslationLimits();

            let zoomFactor = this.videoZoomFactor * (currentDistance / previousDistance);
            zoomFactor = Math.min(Math.max(zoomFactor, 1), MAX_ZOOM_FACTOR);

            let offsetX = (firstTouch.clientX + secondTouch.clientX) / 2 - bounding.left; // touch center
            offsetX +=
                this.videoOffsetX -
                translationLimits.horizontal -
                (bounding.width - offsetX) / this.videoZoomFactor; // relative offset of touch center from video center
            offsetX = (zoomFactor / this.videoZoomFactor - 1) * offsetX * -1; // scale offset
            offsetX += this.videoOffsetX + (firstTouch.deltaX + secondTouch.deltaX) / 2; // add pan distance

            let offsetY = (firstTouch.clientY + secondTouch.clientY) / 2 - bounding.top; // touch center
            offsetY +=
                this.videoOffsetY -
                translationLimits.vertical -
                (bounding.height - offsetY) / this.videoZoomFactor; // relative offset of touch center from video center
            offsetY = (zoomFactor / this.videoZoomFactor - 1) * offsetY * -1; // scale offset
            offsetY += this.videoOffsetY + (firstTouch.deltaY + secondTouch.deltaY) / 2; // add pan distance

            this.applyVideoTransforms(offsetX, offsetY, zoomFactor);
        }
    }

    panZoomEnd(target: HTMLElement, timestamp: number) {
        this.zoomInProgress = false;
        if (this.videoZoomFactor < MIN_ZOOM_FACTOR) {
            this.applyVideoTransforms(this.videoOffsetX, this.videoOffsetY, 1);
        } else {
            this.scheduleCursorDraw();
        }
    }

    private getMargins(): BoundaryPair {
        const boundingRect = this.videoTagElement.getBoundingClientRect();
        return {
            horizontal:
                boundingRect.left +
                window.pageXOffset +
                this.videoState.leftPadding * this.videoZoomFactor,
            vertical:
                boundingRect.top +
                window.pageYOffset +
                this.videoState.topPadding * this.videoZoomFactor
        };
    }

    /**
     * If keyboard input is kept enabled when the GFN virtual keyboard is on,
     * inputs meant to navigate it will affect the stream unintentionally.
     * So utilize keydown/keyup methods for GFN virtual Keyboard input only.
     */
    public sendKeyEvent(event: KeyboardEvent) {
        const keyCode = this.getVirtualKeycode(event);
        if (keyCode) {
            if (event.type === "keydown") {
                this.keydown(event);
            } else if (event.type === "keyup") {
                this.keyup(event);
            }
        } else if (event.type === "keydown" && event.key.length === 1) {
            // For symbols that are not in virtual key list. eg. £, €, ™, ©, ¢, etc.
            const encoder = new TextEncoder();
            const unicodeText = encoder.encode(event.key);
            this.sendTextInput(unicodeText.buffer);
        }
    }

    private sendSafeZone(): void {
        this.clearSafeZoneTimeout();

        // Use 350ms delay before notifying safezone handler to ensure any orientation change is detectable
        this.safeZoneTimeoutId = window.setTimeout(() => {
            this.safeZoneHandler.send();
        }, 350);
    }

    private clearSafeZoneTimeout(): void {
        if (this.safeZoneTimeoutId !== 0) {
            window.clearTimeout(this.safeZoneTimeoutId);
            this.safeZoneTimeoutId = 0;
        }
    }
}

function getDefaultUnadjustedMovement(platformDetails: PlatformDetails): boolean {
    // Dynamically enable the origin trial for requestPointerLock unadjustedMovement if we're:
    // - On Windows and Chrome 84+: Chrome 83 had a bug that broke pointer lock
    // - On ChromeOS/macOS and Chrome 86+: First version to be fully implemented on ChromeOS/macOS
    if (IsWindowsOS(platformDetails)) {
        return IsChromeVersionAtLeast(platformDetails, 84, 0, 4147, 78);
    } else if (IsChromeOS(platformDetails) || IsMacOS(platformDetails)) {
        return IsChromeVersionAtLeast(platformDetails, 86, 0, 4240, 198);
    } else {
        return false;
    }
}

function getRawUpdateOverride(): RawUpdateState | undefined {
    const override = RagnarokSettings.ragnarokConfig.mouseCoalesceInterval;
    if (override !== undefined) {
        const SUPPORTED = new Map([
            [0, RawUpdateState.OFF],
            [4, RawUpdateState.ON_4MS],
            [8, RawUpdateState.ON_8MS],
            [16, RawUpdateState.ON_16MS]
        ]);
        return SUPPORTED.get(override);
    }
    return undefined;
}

// This class only exists because some games/apps don't handle mouse moves and clicks immediately after one another.
// It's an app bug but we still have to deal with it. Basically, this does a best-effort delay on a given event. To
// preserve ordering, if another event comes before the previous delay elapses, we give up and call the previous
// callback early. This class is pretty hacky to support chained delay() calls, needed for tap (move -> down -> up all
// need delays)
class TouchDelay {
    static TOUCH_DELAY_MS: number = 30;

    private timerId: number = 0;
    private callback?: VoidFunction;
    private flushing: boolean = false;

    // Immediately calls the currently-delayed input callback, if any
    // Any calls to delay() during the flush will complete immediately
    public flushImmediately() {
        this.flushing = true;
        this.resetTimer();
        this.callCallback();
        this.flushing = false;
    }

    // Clears the currently-delayed input callback without calling it
    public clear() {
        this.callback = undefined;
        this.resetTimer();
    }

    // Schedules the given callback to be called in TOUCH_DELAY_MS
    // Only delay() can be called from within a callback. Calling other members can cause unexpected behavior
    public delay(callback: VoidFunction) {
        if (this.flushing) {
            callback();
            return;
        }
        this.flushImmediately();

        this.callback = callback;
        this.timerId = window.setTimeout(() => {
            this.timerId = 0;
            this.callCallback();
        }, TouchDelay.TOUCH_DELAY_MS);
    }

    private callCallback() {
        // Set this.callback to undefined before calling it in case it calls delay() with a new callback
        const callback = this.callback;
        if (callback) {
            this.callback = undefined;
            callback();
        }
    }

    private resetTimer() {
        if (this.timerId !== 0) {
            window.clearTimeout(this.timerId);
            this.timerId = 0;
        }
    }
}

function getVideoFrameCounts(
    videoTagElement: InputMediaElement
): { decoded: number; dropped: number } | undefined {
    if (!!videoTagElement.getVideoPlaybackQuality) {
        const quality = videoTagElement.getVideoPlaybackQuality();
        return {
            decoded: quality.totalVideoFrames,
            dropped: quality.droppedVideoFrames
        };
    } else if (videoTagElement.webkitDecodedFrameCount !== undefined) {
        return {
            decoded: videoTagElement.webkitDecodedFrameCount,
            dropped: videoTagElement.webkitDroppedFrameCount ?? 0
        };
    }
    return undefined;
}

function shouldIgnorePointerEvent(evt: PointerEvent): boolean {
    if (!evt.isPrimary) {
        return true;
    }
    // GestureHandler/TouchListener is always available to handle touch/pen events, so ignore those here
    if (evt.pointerType === "touch" || evt.pointerType === "pen") {
        return true;
    }
    return false;
}
