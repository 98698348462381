import { WebrtcStats, StatsType } from "./statsinterfaces";

export const ChunkHeaderSize = 9;
export const statsConfig = {
    depr: {
        size: 70,
        version: 1,
        name: "DEPR"
    },
    rtpv: {
        size: 80,
        version: 3,
        name: "RTPV"
    },
    rtpa: {
        size: 48,
        version: 1,
        name: "RTPA"
    },
    traa: {
        size: 88,
        version: 1,
        name: "TRAA"
    },
    trav: {
        size: 44,
        version: 1,
        name: "TRAV"
    },
    vfmd: {
        size: 12,
        version: 1,
        name: "VFMD"
    },
    pfda: {
        size: 20,
        version: 1,
        name: "PFDA"
    },
    none: {
        size: 0,
        version: 1,
        name: ""
    }
};

export class WebrtcBinaryStats {
    private statsMap = new Map<StatsType, ArrayBuffer[]>();
    constructor() {
        this.resetLists();
    }

    public size() {
        let totalSize = 0;
        for (let [key, array] of this.statsMap) {
            for (let value of array) {
                totalSize += value.byteLength;
            }
        }
        totalSize += this.statsMap.size * ChunkHeaderSize;
        return totalSize;
    }

    public addReport(report: WebrtcStats) {
        let buffer = this.statsMap.get(report.type);
        if (!buffer) {
            buffer = new Array();
            this.statsMap.set(report.type, buffer);
        }
        for (const element of report.stats) {
            buffer!.push(element);
        }
    }

    public write(dest: Uint8Array, startOffset: number): number {
        let offset = startOffset;
        for (let [key, arrayBuffer] of this.statsMap) {
            offset += this.createHeader(dest, offset, key, arrayBuffer.length);
            offset += this.writeStatsToBuffer(dest, arrayBuffer, offset);
        }
        return offset;
    }

    public resetLists() {
        this.statsMap.clear();
    }

    private createHeader(
        dest: Uint8Array,
        offset: number,
        type: StatsType,
        elementCount: number
    ): number {
        let config = this.getStatsConfig(type);
        let chunkType = config.name;
        let dataBufferView = new DataView(dest.buffer);
        for (let i = 0; i < 4; i++) {
            dataBufferView.setUint8(offset + i, chunkType.charCodeAt(i));
        }
        dataBufferView.setUint8(offset + 4, config.version);
        dataBufferView.setUint16(offset + 5, elementCount, true);
        dataBufferView.setUint16(offset + 7, config.size, true);
        return ChunkHeaderSize;
    }

    private getStatsConfig(type: StatsType) {
        switch (type) {
            case StatsType.DEPR:
                return statsConfig.depr;
            case StatsType.RTPV:
                return statsConfig.rtpv;
            case StatsType.RTPA:
                return statsConfig.rtpa;
            case StatsType.TRAV:
                return statsConfig.trav;
            case StatsType.TRAA:
                return statsConfig.traa;
            case StatsType.VFMD:
                return statsConfig.vfmd;
            case StatsType.PFDA:
                return statsConfig.pfda;
            default:
                return statsConfig.none;
        }
    }

    private writeStatsToBuffer(dest: Uint8Array, source: ArrayBuffer[], offset: number): number {
        let byteCount = 0;
        for (let value of source) {
            let arr = new Uint8Array(value);
            dest.set(arr, offset + byteCount);
            byteCount += value.byteLength;
        }
        return byteCount;
    }
}
