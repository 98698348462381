import { Connectivity, getRagnarokStreamExitEvent, RagnarokStreamExitEvent } from "./analytics";
import {
    IPVersion,
    GdprLevel,
    ErrorDetails,
    GetHexString,
    RequestHttpOptions,
    performHttpRequest,
    Response,
    TelemetryEventPayload,
    Log
} from "../dependencies";
import {
    GfnPcTelemetryConfig,
    RagnarokTelemetryConfig,
    JsEventsConfig,
    EventDataElements,
    TelemetryEventDbSchema,
    FormattedEventDetail,
    Streamer_ExitDef,
    StreamExitEventData
} from "./telemetryinterfaces";
import { IndexedDb, StoreDetails } from "../util/indexdb";
import { RagnarokSettings } from "../util/settings";
import { GetCodecType, ToResumeType } from "../util/utils";
import { NetworkDetector } from "../util/networkdetector";

const LOGTAG = "telemetryeventprocessor";

const EXIT_EVENT_STORE_NAME = "exit-events";
const TELEMETRY_EVENTS_STORE_NAME = "telemetry-events";
const STREAM_EXIT_EVENT = 0;
const LEGACY_EXIT_EVENT = 1;

export declare interface exceptionHandlerCallbackType {
    (
        error: Error | DOMException | undefined,
        msg: string,
        file: string,
        lineno: number,
        colno: number,
        handled: boolean,
        category?: string
    ): void;
}

export class TelemetryEventProcessor {
    // We will maintain 2 cached events, one for the newer split schema and one for the legacy schema
    // 0th index: STREAM_EXIT_EVENT - Split schema based Streamer_Exit
    // 1st index: LEGACY_EXIT_EVENT - Legacy schema based RagnarokExitEvent
    private cachedExitEvents: TelemetryEventDbSchema[] = [
        {
            userId: "",
            sessionId: "",
            eventPayload: undefined,
            name: ""
        },
        {
            userId: "",
            sessionId: "",
            eventPayload: undefined,
            name: ""
        }
    ];

    private eventDataElements: EventDataElements = {};
    private exceptionHandler?: exceptionHandlerCallbackType;
    private idb: IndexedDb;
    private openPromise: Promise<void>;
    // Below 4 flags are needed to avoid infinite indexdb operation failure and exception cycle
    private cacheExitEventInDbExceptionSentOnce = false;
    private clearExitEventStoreInDbExceptionSentOnce = false;
    private cacheTelemetryEventInDbExceptionSentOnce = false;
    private clearTelemetryEventStoreInDbExceptionSentOnce = false;
    private gameShortName: string = "";
    private gameCmsId: string = "";
    private pendingTelemetry: any[] = [];
    constructor() {
        const storeDetails: StoreDetails[] = [
            {
                storeName: EXIT_EVENT_STORE_NAME,
                storeOptions: { keyPath: ["userId", "sessionId", "name"] },
                storeIndexName: "exit-event-index"
            },
            {
                storeName: TELEMETRY_EVENTS_STORE_NAME,
                storeOptions: {
                    keyPath: ["userId", "sessionId", "name", "eventPayload.ts"]
                },
                storeIndexName: "telemetry-event-index"
            }
        ];
        this.idb = new IndexedDb("ragnarok", storeDetails);
        this.openPromise = this.idb.open();
    }

    public setExceptionHandler(handler: exceptionHandlerCallbackType) {
        this.exceptionHandler = handler;
    }

    private haveConsentToSend(consent: string): boolean {
        let canSend = false;
        if (this.eventDataElements.commonData) {
            if (
                consent === GdprLevel.Functional &&
                (this.eventDataElements.commonData.deviceGdprFuncOptIn === "Full" ||
                    this.eventDataElements.commonData.deviceGdprFuncOptIn === "Temp")
            ) {
                canSend = true;
            } else if (
                consent === GdprLevel.Technical &&
                this.eventDataElements.commonData.gdprTechOptIn === "Full"
            ) {
                canSend = true;
            } else if (
                consent === GdprLevel.Behavioral &&
                this.eventDataElements.commonData.gdprBehOptIn === "Full"
            ) {
                canSend = true;
            }
        }
        return canSend;
    }

    public sendTelemetryEvent(event: TelemetryEventPayload) {
        if (!this.eventDataElements.commonData) {
            this.pendingTelemetry.push(event);
            return;
        }
        if (!this.haveConsentToSend(event.gdprLevel)) {
            return;
        }

        // For Legacy events, add the common fields. The new events will not have osName
        // in the payload and will be skipped.
        if ("osName" in event.parameters!) {
            event.parameters!.osName = this.getOsName();
            event.parameters!.osVersion = this.getOsVer();
            event.parameters!.gameShortName = this.gameShortName;
        }
        if (window.navigator.onLine) {
            this.sendHttpTelemetryRequest(this.constructEventDataElements(event));
        } else {
            const eventDbFormat: TelemetryEventDbSchema = {
                userId: this.eventDataElements.commonData!.userId!,
                sessionId: this.eventDataElements.commonData!.sessionId!,
                name: event.name,
                eventPayload: event
            };
            this.cacheTelemetryEventInDb(eventDbFormat);
        }
    }

    private constructEventDataElements(event: TelemetryEventPayload): EventDataElements {
        let localEventObj: EventDataElements = {};
        if (!this.eventDataElements.commonData) {
            return localEventObj;
        }
        const formattedEventDetail: FormattedEventDetail = {
            name: event.name,
            ts: event.ts!,
            parameters: event.parameters!,
            experiments: this.eventDataElements.experiments
        };

        // Make a deep copy of common data before modifying any properties
        this.copyCommonEventDataElements(localEventObj, this.eventDataElements);
        const commonData = localEventObj.commonData!;
        // Use the incoming event's schema config details to dispatch the event
        commonData.clientId = event.clientConfig.clientId;
        commonData.eventSchemaVer = event.clientConfig.eventSchemaVer;
        commonData.sentTs = new Date().toISOString();
        commonData.events = [formattedEventDetail];
        return localEventObj;
    }

    private sendHttpTelemetryRequest(telemetryEventData: EventDataElements) {
        if (telemetryEventData.config?.server && telemetryEventData.config?.version) {
            const options: RequestHttpOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Event-Protocol": telemetryEventData.commonData?.eventProtocol ?? "1.4"
                },
                body: JSON.stringify(telemetryEventData.commonData),
                retryCount: 3,
                timeout: 2000
            };
            Log.i("{f7c1592}", "{4d9b16c}", RagnarokSettings.allowSendingTelemetry, options.body);
            if (RagnarokSettings.allowSendingTelemetry) {
                performHttpRequest(this.getTelemetryServerUrl(telemetryEventData.config), options)
                    .then((response: Response) => {})
                    .catch(err => {});
            }
        }
    }

    public setGameDetails(cmsId: string, name: string) {
        this.gameCmsId = cmsId;
        this.gameShortName = name;
    }

    public sendExitEvent(eventPayload: TelemetryEventPayload, isLegacyEvent: boolean) {
        Log.d("{f7c1592}", "{767ba2f}", isLegacyEvent);
        if (RagnarokSettings.useUITelemetry && isLegacyEvent) {
            this.updateCachedExitEventData(LEGACY_EXIT_EVENT, eventPayload);
            this.sendExitEventUsingBeacon(
                this.constructEventDataElements(
                    this.cachedExitEvents[LEGACY_EXIT_EVENT].eventPayload!
                )
            );
        }
        if (RagnarokSettings.useSplitSchema && !isLegacyEvent) {
            this.updateCachedExitEventData(STREAM_EXIT_EVENT, eventPayload);
            this.sendExitEventUsingBeacon(
                this.constructEventDataElements(
                    this.cachedExitEvents[STREAM_EXIT_EVENT].eventPayload!
                )
            );
        }
    }

    public clearExitEventStore(pollingDone: boolean) {
        Log.d("{f7c1592}", "{badefed}", pollingDone);
        this.clearExitEventStoreInDb(pollingDone);
        this.cachedExitEvents[STREAM_EXIT_EVENT].eventPayload = undefined;
        this.cachedExitEvents[LEGACY_EXIT_EVENT].eventPayload = undefined;
    }

    /// Helper function to manage the EventDataElements copy. Assignment causes all these point
    // to the same reference and the cached events gets messed up.
    private copyCommonEventDataElements(target: EventDataElements, source: EventDataElements) {
        // Deep copy commonData
        target.commonData = source.commonData ? Object.assign({}, source.commonData) : undefined;

        // The following fields are common properties and will be same across all events.
        // Shallow copy is sufficient.
        target.experiments = []; // Do not send the experiments' data with the events
        target.config = source.config;
        target.telemetryEventIds = source.telemetryEventIds;
    }

    // Called by the gridapp relayed from the UI layer to update the telemetry common fields
    public updateEventDataElements(eventDataElements: EventDataElements) {
        // Make a copy of commonData so we don't modify the incoming object's references
        this.copyCommonEventDataElements(this.eventDataElements, eventDataElements);
        if (this.eventDataElements.commonData) {
            // If there are pending events, flush them
            Log.d("{f7c1592}", "{66f209c}");
            for (let event of this.pendingTelemetry) {
                this.sendTelemetryEvent(event);
            }
            this.pendingTelemetry = [];
        }
        for (const cachedEvent of this.cachedExitEvents) {
            cachedEvent.userId = this.eventDataElements.commonData?.userId!;
            cachedEvent.sessionId = this.eventDataElements.commonData?.sessionId!;
        }
    }

    private updateCachedExitEventData(exitEventIndex: number, event: TelemetryEventPayload) {
        this.cachedExitEvents[exitEventIndex].userId =
            this.eventDataElements.commonData?.userId ?? "";
        this.cachedExitEvents[exitEventIndex].sessionId =
            this.eventDataElements.commonData?.sessionId ?? "";
        this.cachedExitEvents[exitEventIndex].name = event.name;
        this.cachedExitEvents[exitEventIndex].eventPayload = event;
    }

    public updateCachedExitEvent(
        exitErrorCode: string,
        sessionId: string,
        subSessionId: string,
        zoneAddress: string,
        streamDuration: number,
        frameCount: number,
        codec: string, // in legacy format 'video/H264'
        isResume: boolean
    ) {
        const exitData: StreamExitEventData = {
            exitErrorCode: exitErrorCode,
            sessionId: sessionId,
            subSessionId: subSessionId,
            zoneAddress: zoneAddress,
            streamDuration: streamDuration,
            frameCount: frameCount,
            codec: codec,
            isResume: isResume,
            connectivity: "",
            sleep: false,
            networkTestSessionId: "",
            sessionSetupFailed: false
        };
        if (RagnarokSettings.useUITelemetry) {
            const eventPayload = this.getRagnarokStreamExitEventPayload(exitData);
            this.updateCachedExitEventData(LEGACY_EXIT_EVENT, eventPayload);
        }
        if (RagnarokSettings.useSplitSchema) {
            const eventPayload = this.getStreamerExitEventPayload(exitData);
            this.updateCachedExitEventData(STREAM_EXIT_EVENT, eventPayload);
        }
    }

    public getRagnarokStreamExitEventPayload(exitData: StreamExitEventData): TelemetryEventPayload {
        const exitEvent: RagnarokStreamExitEvent = getRagnarokStreamExitEvent(
            exitData.exitErrorCode,
            exitData.sessionId,
            exitData.subSessionId,
            exitData.zoneAddress,
            exitData.streamDuration,
            exitData.frameCount,
            exitData.codec,
            exitData.isResume,
            this.getOsName(),
            this.getOsVer(),
            this.gameShortName,
            this.getStreamingProfileGuid(),
            this.getSystemInfoGuid(),
            String(this.gameCmsId),
            exitData.connectivity,
            exitData.sleep,
            exitData.networkTestSessionId
        );
        return {
            name: exitEvent.name,
            gdprLevel: exitEvent.gdprLevel,
            parameters: exitEvent.parameters,
            ts: exitEvent.ts!,
            clientConfig: GfnPcTelemetryConfig
        };
    }

    public getStreamerExitEventPayload(exitData: StreamExitEventData): TelemetryEventPayload {
        let exitReason = exitData.exitErrorCode ?? "";
        if (exitData.sleep) {
            exitReason = "SleepExit";
        } else if (exitData.connectivity?.startsWith(Connectivity.ONLINE)) {
            exitReason = exitData.connectivity ?? "";
        }
        const event: Streamer_ExitDef = new Streamer_ExitDef({
            zoneAddress: exitData.zoneAddress,
            networkSessionId: exitData.networkTestSessionId ?? "",
            sessionId: exitData.sessionId,
            subSessionId: exitData.subSessionId,
            resumeType: ToResumeType(exitData.isResume),
            overrideConfigType: RagnarokSettings.remoteOverrideInfo.type,
            overrideConfigVersion: RagnarokSettings.remoteOverrideInfo.version,
            exitReason: exitReason,
            result: exitData.exitErrorCode,
            frameCount: exitData.frameCount,
            codec: GetCodecType(exitData.codec),
            ipVersion: IPVersion.UNKNOWN,
            streamDuration: Math.round(exitData.streamDuration),
            networkType: NetworkDetector.getNetworkType(),
            streamingProfileGuid: this.getStreamingProfileGuid(),
            systemInfoGuid: this.getSystemInfoGuid(),
            cmsId: String(this.gameCmsId)
        });
        return {
            name: event.name,
            gdprLevel: event.gdprLevel,
            parameters: event.parameters,
            ts: event.ts,
            clientConfig: RagnarokTelemetryConfig
        };
    }

    private handleCatch(err: ErrorDetails, funcName: string, shouldSendException: boolean) {
        Log.e("{f7c1592}", "{146ec91}", funcName, GetHexString(err.code), err.description);
        if (shouldSendException) {
            const msg = err.error?.message ?? err.description ?? `Exception in ${funcName}`;
            const filename = LOGTAG + ".ts";
            if (this.exceptionHandler) {
                this.exceptionHandler(err.error, msg, filename, err.code, 0, true, err.error?.name);
            } else {
                Log.w("{f7c1592}", "{12e0846}");
            }
        }
    }

    private isPrimaryKeyValid(telemetryEvent: TelemetryEventDbSchema): boolean {
        return !!telemetryEvent.userId && !!telemetryEvent.sessionId && !!telemetryEvent.name;
    }

    private cacheTelemetryEventInDb(cachedTelemetryEvent: TelemetryEventDbSchema) {
        if (!this.isPrimaryKeyValid(cachedTelemetryEvent)) {
            return;
        }
        // intentionally resolving it, as we want to not block anything
        this.openPromise
            .then(() => this.idb.set(TELEMETRY_EVENTS_STORE_NAME, cachedTelemetryEvent))
            .then(() => {
                Log.i("{f7c1592}", "{f03d70b}");
            })
            .catch((err: ErrorDetails) => {
                this.handleCatch(
                    err,
                    "cacheTelemetryEventInDb",
                    !this.cacheTelemetryEventInDbExceptionSentOnce
                );
                if (!this.cacheTelemetryEventInDbExceptionSentOnce) {
                    this.cacheTelemetryEventInDbExceptionSentOnce = true;
                }
            });
    }

    public cacheExitEventInDb(): Promise<void> {
        if (
            (RagnarokSettings.useUITelemetry &&
                !this.isPrimaryKeyValid(this.cachedExitEvents[LEGACY_EXIT_EVENT])) ||
            (RagnarokSettings.useSplitSchema &&
                !this.isPrimaryKeyValid(this.cachedExitEvents[STREAM_EXIT_EVENT]))
        ) {
            return Promise.resolve();
        }
        // intentionally resolving it, as we want to not block anything
        return this.openPromise
            .then(() => {
                if (RagnarokSettings.useUITelemetry) {
                    this.idb.set(EXIT_EVENT_STORE_NAME, this.cachedExitEvents[LEGACY_EXIT_EVENT]);
                }
            })
            .then(() => {
                if (RagnarokSettings.useSplitSchema) {
                    this.idb.set(EXIT_EVENT_STORE_NAME, this.cachedExitEvents[STREAM_EXIT_EVENT]);
                }
            })
            .then(() => {
                Log.i("{f7c1592}", "{5d026f8}");
            })
            .catch((err: ErrorDetails) => {
                this.handleCatch(
                    err,
                    "cacheExitEventInDb",
                    !this.cacheExitEventInDbExceptionSentOnce
                );
                if (!this.cacheExitEventInDbExceptionSentOnce) {
                    this.cacheExitEventInDbExceptionSentOnce = true;
                }
            });
    }

    private getTelemetryServerUrl(config: JsEventsConfig): string {
        return config.server + "/" + config.version + "/events/json";
    }

    private sendExitEventUsingBeacon(eventDataElements: EventDataElements) {
        if (
            eventDataElements.config?.server &&
            eventDataElements.config?.version &&
            eventDataElements.experiments
        ) {
            if (eventDataElements.commonData) {
                eventDataElements.commonData.sentTs = new Date().toISOString();
            }
            const blobData = JSON.stringify(eventDataElements.commonData);
            const formData = new Blob([blobData], {
                type: "text/plain"
            });
            let result = false;
            if (RagnarokSettings.allowSendingTelemetry) {
                result = navigator.sendBeacon(
                    this.getTelemetryServerUrl(eventDataElements.config),
                    formData
                );
            }
            Log.i("{f7c1592}", "{f797b28}", result, RagnarokSettings.allowSendingTelemetry, blobData);
        }
    }

    private clearExitEventStoreInDb(pollingDone: boolean) {
        if (pollingDone) {
            // This is little tricky, we don't want to unintenaionally clear cache with valid case.
            // we need to think about multitab, launch error in next session, error in current session during stream cases etc
            this.openPromise
                .then(() => this.idb.clear(EXIT_EVENT_STORE_NAME))
                .then(() => {
                    Log.i("{f7c1592}", "{1b8f127}");
                })
                .catch((err: ErrorDetails) => {
                    this.handleCatch(
                        err,
                        "clearExitEventStoreInDb",
                        !this.clearExitEventStoreInDbExceptionSentOnce
                    );
                    if (!this.clearExitEventStoreInDbExceptionSentOnce) {
                        this.clearExitEventStoreInDbExceptionSentOnce = true;
                    }
                });
        }
    }

    public getCachedExitEvents(): Promise<TelemetryEventPayload[]> {
        return new Promise<TelemetryEventPayload[]>(() => {
            let eventPayloads: TelemetryEventPayload[] = [];
            this.idb
                .getAll(EXIT_EVENT_STORE_NAME)
                .then((exitEvents: TelemetryEventDbSchema[]) => {
                    Log.i("{f7c1592}", "{d938300}", exitEvents.length);
                    if (exitEvents.length > 0) {
                        for (const telemetryEvent of exitEvents) {
                            if (telemetryEvent.eventPayload) {
                                eventPayloads.push(telemetryEvent.eventPayload);
                            }
                        }
                    }
                    return eventPayloads;
                })
                .catch((err: ErrorDetails) => {
                    this.handleCatch(err, "getCachedExitEvents", true);
                    return eventPayloads;
                });
        });
    }

    public sendCachedExitEvent(pollingDone: boolean): Promise<void> {
        // intentionally always resolve, as we don't want to block anything
        return this.openPromise
            .then(() => this.idb.getAll(EXIT_EVENT_STORE_NAME))
            .then((exitEvents: TelemetryEventDbSchema[]) => {
                Log.i("{f7c1592}", "{ca568ff}", exitEvents.length);
                if (exitEvents.length > 0) {
                    for (const telemetryEvent of exitEvents) {
                        if (telemetryEvent.eventPayload) {
                            this.sendExitEventUsingBeacon(
                                this.constructEventDataElements(telemetryEvent.eventPayload)
                            );
                        }
                    }
                    this.clearExitEventStore(pollingDone);
                }
            })
            .catch((err: ErrorDetails) => {
                this.handleCatch(err, "sendCachedExitEvent", true);
            });
    }

    private clearTelemetryEventStoreInDb() {
        this.openPromise
            .then(() => this.idb.clear(TELEMETRY_EVENTS_STORE_NAME))
            .then(() => {
                Log.i("{f7c1592}", "{8f62936}");
            })
            .catch((err: ErrorDetails) => {
                this.handleCatch(
                    err,
                    "clearEventStoreInDb",
                    !this.clearTelemetryEventStoreInDbExceptionSentOnce
                );
                if (!this.clearTelemetryEventStoreInDbExceptionSentOnce) {
                    this.clearTelemetryEventStoreInDbExceptionSentOnce = true;
                }
            });
    }

    public sendAllCachedTelemetryEvents() {
        if (window.navigator.onLine) {
            this.openPromise
                .then(() => this.idb.getAll(TELEMETRY_EVENTS_STORE_NAME))
                .then((telemetryEvents: TelemetryEventDbSchema[]) => {
                    Log.i("{f7c1592}", "{d4e574c}", telemetryEvents.length);
                    if (telemetryEvents.length > 0) {
                        this.clearTelemetryEventStoreInDb();
                        for (const telemetryEvent of telemetryEvents) {
                            if (telemetryEvent.eventPayload) {
                                this.sendHttpTelemetryRequest(
                                    this.constructEventDataElements(telemetryEvent.eventPayload)
                                );
                            }
                        }
                    }
                })
                .catch((err: ErrorDetails) => {
                    Log.e("{f7c1592}", "{aaaa3de}", err.code, err.description);
                });
        }
    }

    public resetDataOnNewSubSession(sessionId: string, subSessionId: string) {
        this.cacheExitEventInDbExceptionSentOnce = false;
        this.clearExitEventStoreInDbExceptionSentOnce = false;
        this.cacheTelemetryEventInDbExceptionSentOnce = false;
        this.clearTelemetryEventStoreInDbExceptionSentOnce = false;
    }

    public getOsName() {
        return this.eventDataElements?.commonData?.deviceOS ?? "";
    }

    public getOsVer() {
        return this.eventDataElements?.commonData?.deviceOSVersion ?? "";
    }

    public getStreamingProfileGuid() {
        return this.eventDataElements?.telemetryEventIds?.streamingProfileGuid ?? "";
    }

    public getSystemInfoGuid() {
        return this.eventDataElements?.telemetryEventIds?.systemInfoGuid ?? "";
    }
}
