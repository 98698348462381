import { performHttpRequest, RequestHttpOptions } from "@gamestream/ragnarok";
import { AuthProvider } from "./authprovider";

enum AccountProviderTypes {
    Steam = "steam",
    Epic = "epic",
    Uplay = "uplay",
    Unknown = "unknown"
}

enum HttpMethods {
    GET = "GET",
    DELETE = "DELETE",
    POST = "POST"
}

export interface LinkStatus {
    display_name: string;
    expire_timestamp: number;
    platform: string;
    platform_user_id?: string;
}

interface RedirectDataBlock {
    display_name?: string;
    expires_in?: number;
    platform?: string;
    platform_user_id?: string;
}

const linkRedirectOrigin = "https://geforcenow-stage.nvidia.com";

const CommonConfigs = {
    clientId: "gfn-web",
    redirectUrl: `${linkRedirectOrigin}/redirect/starfleet-oauth-redirect.html`,
    alsAuthHedaer: "Bearer",
    gpssAuthHeader: "GFNJWT"
};

export interface CancelablePromise<T> extends Promise<T> {
    cancel: () => void;
}

export abstract class AccountProvider {
    private supportedGames: number[];
    private enabled: boolean = true;
    private static cache: Map<string, LinkStatus> = new Map<string, LinkStatus>();
    protected provider: AccountProviderTypes = AccountProviderTypes.Unknown;
    protected static isStaging: boolean = false;

    constructor(provider: AccountProviderTypes, supportedGames: number[]) {
        this.provider = provider;
        this.supportedGames = supportedGames;
    }

    public static setStaging(isStaging: boolean) {
        this.isStaging = isStaging;
    }

    public static async syncAll(
        authProvider: AuthProvider,
        errorHandler: (err: Error) => void = () => {}
    ): Promise<Array<LinkStatus>> {
        const errorCatch = (err: Error) => {
            errorHandler(err);
            return [] as LinkStatus[];
        };
        const authInfoCallback = authProvider.getAuthInfo();
        return Promise.all([
            AccountLinkingProvider.syncPlatforms(
                authProvider,
                authInfoCallback.then(authInfo => {
                    return `${CommonConfigs.alsAuthHedaer} ${authInfo.token}`;
                })
            ).catch(errorCatch),
            OwnershipSyncProvider.syncPlatforms(
                authProvider,
                authInfoCallback.then(authInfo => {
                    return `${CommonConfigs.gpssAuthHeader} ${authInfo.token}`;
                })
            ).catch(errorCatch)
        ]).then(([status1, status2]) => {
            return status1.concat(status2);
        });
    }

    public async isAccountLinkedForGame(
        authProvider: AuthProvider,
        gameId: number
    ): Promise<boolean> {
        if (!this.isEnabled()) {
            return false;
        }
        return (
            !!this.supportedGames.find(supported => supported === gameId) &&
            (await this.checkLink(authProvider))
        );
    }

    public setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public getName(): string {
        return this.provider;
    }

    public static isAccountLinkingSupported() {
        return window.location.origin === linkRedirectOrigin;
    }

    public static expired(expire_timestamp: number): boolean {
        if (expire_timestamp == undefined) {
            return true;
        }
        return expire_timestamp < AccountProvider.ms2s(performance.now());
    }

    protected static cacheSet(
        authProvider: AuthProvider,
        displayName: string,
        expireIn: number,
        platform: string,
        platformUserId?: string
    ): LinkStatus {
        platform = platform.toLowerCase();
        let expireTimestamp: number;
        if (expireIn < 0 || expireIn === Number.MAX_SAFE_INTEGER) {
            expireTimestamp = Number.MAX_SAFE_INTEGER;
        } else if (expireIn === 0) {
            expireTimestamp = 0;
        } else {
            expireTimestamp = this.ms2s(performance.now()) + expireIn;
        }
        const linkCache: LinkStatus = {
            display_name: displayName,
            expire_timestamp: expireTimestamp,
            platform: platform,
            platform_user_id: platformUserId
        };
        this.cache.set(`${authProvider.getUserId()}-${platform}`, linkCache);
        return linkCache;
    }

    protected static cacheGet(authProvider: AuthProvider, platform: string): LinkStatus | null {
        const item: LinkStatus = this.cache.get(`${authProvider.getUserId()}-${platform}`);
        if (!item) return null;
        return item;
    }

    protected static invalidateCache(authProvider: AuthProvider, platform: string) {
        const cache: LinkStatus = AccountProvider.cacheGet(authProvider, platform);
        if (cache) {
            AccountProvider.cacheSet(authProvider, cache.display_name, 0, cache.platform);
        }
    }

    protected static ms2s(ms: number): number {
        const s2ms: number = 1000;
        return Math.floor(ms / s2ms);
    }

    protected static async httpRequestHelper(
        authInfo: Promise<string>,
        url: string,
        method: HttpMethods,
        service: string,
        headers: { [key: string]: string } = {},
        body: string = "",
        propagateErrors: number[] = []
    ) {
        if (authInfo) headers["authorization"] = await authInfo;
        const options: RequestHttpOptions = {
            method: method,
            headers: headers,
            body: body
        };
        const response = await performHttpRequest(url, options);
        if (response.status != 200 && !propagateErrors.find(err => err === response.status)) {
            throw new Error(
                `${service} could not be performed with error code ${
                    response.status
                } options ${JSON.stringify(options)}`
            );
        }
        return response;
    }

    public abstract initLink(authProvider: AuthProvider): Promise<string>;
    public abstract finishLink(
        authProvider: AuthProvider,
        redirectData: RedirectDataBlock
    ): Promise<LinkStatus>;
    public abstract initLinkOffDevice(authProvider: AuthProvider): Promise<[string, string]>;
    public abstract pollLinkOffDevice(deviceCode: string): CancelablePromise<boolean>;
    public abstract unlink(authProvider: AuthProvider): Promise<void>;
    public abstract checkLink(authProvider: AuthProvider): Promise<boolean>;
}

class AccountLinkingProvider extends AccountProvider {
    private static readonly alsEndpoint: string = "https://als.geforcenow.com/v1"; // used by general account linking
    private static readonly alsEndpointStg: string = "https://als-stg.nvidiagrid.net/v1";

    public async initLink(authProvider: AuthProvider): Promise<string> {
        const url: URL = new URL(AccountLinkingProvider.getUrl("login_url"));
        url.searchParams.set("platform", this.provider.toUpperCase());
        url.searchParams.set("redirect_uri", CommonConfigs.redirectUrl);
        url.searchParams.set("client_id", CommonConfigs.clientId);
        const response = await AccountProvider.httpRequestHelper(
            AccountLinkingProvider.getAuthToken(authProvider),
            url.toString(),
            HttpMethods.GET,
            "AccountLinkProvider initLink"
        );
        return JSON.parse(response.data).login_url ?? "";
    }

    public async finishLink(
        authProvider: AuthProvider,
        redirectData: RedirectDataBlock
    ): Promise<LinkStatus> {
        return AccountProvider.cacheSet(
            authProvider,
            redirectData.display_name,
            Number(redirectData.expires_in ?? 0),
            redirectData.platform
        );
    }

    public async initLinkOffDevice(authProvider: AuthProvider): Promise<[string, string]> {
        const url = AccountLinkingProvider.getUrl("login_url");
        const response = await AccountLinkingProvider.httpRequestHelper(
            AccountLinkingProvider.getAuthToken(authProvider),
            url,
            HttpMethods.POST,
            "AccountLinkProvider initLinkOffDevice",
            { "Content-Type": "application/json" },
            JSON.stringify({
                platform: (this.provider as string).toUpperCase(),
                client_id: CommonConfigs.clientId
            })
        );
        const responseJson: any = JSON.parse(response.data);
        return [responseJson.verification_uri_complete ?? "", responseJson.device_code ?? ""];
    }

    public pollLinkOffDevice(deviceCode: string): CancelablePromise<boolean> {
        const url: string = AccountLinkingProvider.getUrl("token");
        let polling: boolean = true;
        const poll = async (): Promise<boolean> => {
            if (!polling) {
                return false;
            }
            const response = await AccountLinkingProvider.httpRequestHelper(
                undefined,
                url,
                HttpMethods.POST,
                "AccountLinkProvider pollLinkOffDevice",
                { "Content-Type": "application/json" },
                JSON.stringify({
                    client_id: CommonConfigs.clientId,
                    device_code: deviceCode
                }),
                [400]
            );
            if (response.status == 400) {
                await new Promise<void>(resolve => {
                    setTimeout(() => resolve(), 500);
                });
                return poll();
            } else {
                return true;
            }
        };
        const cancelablePoll = poll() as CancelablePromise<boolean>;
        cancelablePoll.cancel = () => (polling = false);
        return cancelablePoll;
    }

    public async unlink(authProvider: AuthProvider) {
        const platform: string = (this.provider as string).toUpperCase();
        const path: string = AccountLinkingProvider.getUrl("linking");
        const url: string = `${path}/${platform}`;
        await AccountProvider.httpRequestHelper(
            AccountLinkingProvider.getAuthToken(authProvider),
            url,
            HttpMethods.DELETE,
            "AccountLinkProvider unlink"
        );
        AccountProvider.invalidateCache(authProvider, this.provider);
    }

    public async checkLink(authProvider: AuthProvider): Promise<boolean> {
        const cache: LinkStatus = AccountProvider.cacheGet(authProvider, this.provider);
        if (cache) {
            return !AccountProvider.expired(cache.expire_timestamp);
        }
        const platform: string = (this.provider as string).toUpperCase();
        const path: string = AccountLinkingProvider.getUrl("linking");
        const url: string = `${path}/${platform}`;
        const response = await AccountProvider.httpRequestHelper(
            AccountLinkingProvider.getAuthToken(authProvider),
            url,
            HttpMethods.GET,
            "AccountLinkProvider checkLink",
            undefined,
            undefined,
            [404]
        );
        const responseJson: any = response.data?.length ? JSON.parse(response.data) : undefined;
        if (response.status === 200) {
            const syncedResponse: LinkStatus = AccountProvider.cacheSet(
                authProvider,
                responseJson.display_name,
                Number(responseJson.expires_in ?? 0),
                responseJson.platform
            );
            return !AccountProvider.expired(syncedResponse.expire_timestamp);
        } else {
            return false;
        }
    }

    public static async syncPlatforms(
        authProvider: AuthProvider,
        authTokenCallback?: Promise<string>
    ): Promise<Array<LinkStatus>> {
        const url: string = AccountLinkingProvider.getUrl("linking");
        const response = await this.httpRequestHelper(
            authTokenCallback ?? AccountLinkingProvider.getAuthToken(authProvider),
            url,
            HttpMethods.GET,
            "AccountLinkProvider syncAll"
        );
        const responseJson: Array<any> = JSON.parse(response.data);
        const allStatus: Array<LinkStatus> = [];
        responseJson.forEach((providerStatus: any) => {
            allStatus.push(
                this.cacheSet(
                    authProvider,
                    providerStatus.display_name,
                    Number(providerStatus.expires_in ?? 0),
                    providerStatus.platform
                )
            );
        });
        return allStatus;
    }

    private static async getAuthToken(authProvider: AuthProvider): Promise<string> {
        try {
            const authInfo = await authProvider.getAuthInfo();
            return `${CommonConfigs.alsAuthHedaer} ${authInfo.token}`;
        } catch (err) {
            throw new Error("AccountLinkingProvider get auth token error, please login again");
        }
    }

    private static getUrl(service: string): string {
        return `${
            this.isStaging
                ? AccountLinkingProvider.alsEndpointStg
                : AccountLinkingProvider.alsEndpoint
        }/${service}`;
    }
}

abstract class OwnershipSyncProvider extends AccountProvider {
    private static readonly gpssEndpoint: string = "https://api.gpss.nvidiagrid.net/v1"; // used by steam linking
    private static readonly gpssEndpointStg: string = "https://api.stg.gpss.nvidiagrid.net/v1";
    private static readonly userstore: string = "https://userstore.nvidia.com/v1";
    private static readonly userstoreStg: string = "https://stg.userstore.nvidia.com/v1";

    public async finishLink(
        authProvider: AuthProvider,
        redirectData: RedirectDataBlock
    ): Promise<LinkStatus> {
        const url: string = OwnershipSyncProvider.getUrl("connect");
        authProvider.getUserId();
        const response = await AccountProvider.httpRequestHelper(
            OwnershipSyncProvider.getAuthToken(authProvider),
            url,
            HttpMethods.POST,
            "OwnershipSyncProvider finishLink",
            undefined,
            JSON.stringify({
                platform: this.provider.toUpperCase(),
                platformUserId: redirectData.platform_user_id
            })
        );
        const responseJson: any = JSON.parse(response.data);
        return AccountProvider.cacheSet(
            authProvider,
            responseJson.personaName,
            responseJson.platformSyncStatus === "SUCCESS" ? Number.MAX_SAFE_INTEGER : 0,
            responseJson.platform,
            responseJson.platformUserId
        );
    }

    public async initLinkOffDevice(_authProvider: AuthProvider): Promise<[string, string]> {
        throw new Error(`${this.getName()} does not support off-device flow`);
    }

    public pollLinkOffDevice(_deviceCode: string): CancelablePromise<boolean> {
        throw new Error(`${this.getName()} does not support off-device flow`);
    }

    public async unlink(authProvider: AuthProvider) {
        const url: URL = new URL(OwnershipSyncProvider.getUrl("connect"));
        url.searchParams.set("platform", this.provider.toUpperCase());
        await AccountProvider.httpRequestHelper(
            OwnershipSyncProvider.getAuthToken(authProvider),
            url.toString(),
            HttpMethods.DELETE,
            "OwnershipSyncProvider unlink"
        );
        AccountProvider.invalidateCache(authProvider, this.provider);
    }

    public async checkLink(authProvider: AuthProvider): Promise<boolean> {
        const cache: LinkStatus = AccountProvider.cacheGet(authProvider, this.provider);
        if (cache) {
            return !AccountProvider.expired(cache.expire_timestamp);
        }
        const allStatus: Array<LinkStatus> = await OwnershipSyncProvider.syncPlatforms(
            authProvider
        );
        return !AccountProvider.expired(
            allStatus.find(status => status.platform === this.provider)?.expire_timestamp
        );
    }

    public static async syncPlatforms(
        authProvider: AuthProvider,
        authTokenCallback?: Promise<string>
    ): Promise<Array<LinkStatus>> {
        const url: URL = new URL(
            `${
                this.isStaging
                    ? OwnershipSyncProvider.userstoreStg
                    : OwnershipSyncProvider.userstore
            }/clientData`
        );
        url.searchParams.set("key", "syncedPlatforms");
        const response = await this.httpRequestHelper(
            authTokenCallback ?? OwnershipSyncProvider.getAuthToken(authProvider),
            url.toString(),
            HttpMethods.GET,
            "OnwershipSyncProvider syncAll"
        );
        const responseJson = JSON.parse(response.data);
        const data = responseJson.data.find((d: any) => d.key === "syncedPlatforms");
        if (!data) {
            return [];
        }
        const allStatus: Array<LinkStatus> = [];
        data.value.forEach((d: any) => {
            const dJson = JSON.parse(d);
            allStatus.push(
                this.cacheSet(
                    authProvider,
                    dJson.personaName,
                    Number.MAX_SAFE_INTEGER,
                    dJson.platform
                )
            );
        });
        return allStatus;
    }

    private static async getAuthToken(authProvider: AuthProvider): Promise<string> {
        try {
            const authInfo = await authProvider.getAuthInfo();
            return `${CommonConfigs.gpssAuthHeader} ${authInfo.token}`;
        } catch (err) {
            throw new Error("OwnershipProvider get auth token error, please login again");
        }
    }

    private static getUrl(service: string): string {
        return `${
            this.isStaging
                ? OwnershipSyncProvider.gpssEndpointStg
                : OwnershipSyncProvider.gpssEndpoint
        }/${service}`;
    }
}

class SteamProvider extends OwnershipSyncProvider {
    constructor(supportedGames: number[]) {
        super(AccountProviderTypes.Steam, supportedGames);
    }

    public async initLink(_authProvider: AuthProvider): Promise<string> {
        return (
            "https://steamcommunity.com/openid/login?" +
            "openid.mode=" +
            encodeURIComponent("checkid_setup") +
            "&openid.ns=" +
            encodeURIComponent("http://specs.openid.net/auth/2.0") +
            "&openid.ns.sreg=" +
            encodeURIComponent("http://openid.net/extensions/sreg/1.1") +
            "&openid.sreg.optional=" +
            encodeURIComponent(
                "nickname,email,fullname,dob,gender,postcode,country,language,timezone"
            ) +
            "&openid.ns.ax=" +
            encodeURIComponent("http://openid.net/srv/ax/1.0") +
            "&openid.ax.mode=" +
            encodeURIComponent("fetch_request") +
            "&openid.ax.type.fullname=" +
            encodeURIComponent("http://axschema.org/namePerson") +
            "&openid.ax.type.firstname=" +
            encodeURIComponent("http://axschema.org/namePerson/first") +
            "&openid.ax.type.lastname=" +
            encodeURIComponent("http://axschema.org/namePerson/last") +
            "&openid.ax.type.email=" +
            encodeURIComponent("http://axschema.org/contact/email") +
            "&openid.ax.required=" +
            encodeURIComponent("fullname,firstname,lastname,email") +
            "&openid.identity=" +
            encodeURIComponent("http://specs.openid.net/auth/2.0/identifier_select") +
            "&openid.claimed_id=" +
            encodeURIComponent("http://specs.openid.net/auth/2.0/identifier_select") +
            "&openid.return_to=" +
            encodeURIComponent(CommonConfigs.redirectUrl) +
            "&openid.realm=" +
            encodeURIComponent(CommonConfigs.redirectUrl)
        );
    }
}

const enum Games {
    Fortnite = 100013311,
    FortniteTouch = 100542811,
    Fortnite3 = 101642411,
    CounterStrike = 7315111,
    Steam = 100021711,
    TomClancys = 100065511,
    LOL = 3883111,
    Destiny2 = 100362311,
    Rust = 18105111,
    Warframe = 11285111,
    RocketLeague = 17034111,
    ARK = 18541011,
    DeadByDaylight = 18545711,
    PUBG = 100059711,
    DisneyUnityTestApp = 101606211,
    UE4Sample = 101652711
}

export const accountProviders: AccountProvider[] = [
    new AccountLinkingProvider(AccountProviderTypes.Epic, [Games.Fortnite]),
    new SteamProvider([
        Games.CounterStrike,
        Games.Destiny2,
        Games.Rust,
        Games.Warframe,
        Games.RocketLeague,
        Games.ARK,
        Games.DeadByDaylight
    ]),
    new AccountLinkingProvider(AccountProviderTypes.Uplay, [Games.TomClancys])
];
