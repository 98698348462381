// Based on https://msdn.microsoft.com/en-us/library/windows/desktop/dd375731(v=vs.85).aspx
export const enum VIRTUAL_KEYS {
    CODE_NONE = 0x00,

    // Virtual Keys, Standard Set
    CODE_LBUTTON = 0x01,
    CODE_RBUTTON = 0x02,
    CODE_CANCEL = 0x03,
    CODE_MBUTTON = 0x04, // NOT contiguous with LBUTTON and RBUTTON
    CODE_BACK = 0x08,
    CODE_TAB = 0x09,
    CODE_CLEAR = 0x0c,
    CODE_RETURN = 0x0d,
    CODE_SHIFT = 0x10,
    CODE_CONTROL = 0x11,
    CODE_MENU = 0x12,
    CODE_PAUSE = 0x13,
    CODE_CAPITAL = 0x14,
    CODE_HANGUL = 0x15,
    CODE_HANJA = 0x19,

    CODE_ESCAPE = 0x1b,
    CODE_SPACE = 0x20,
    CODE_PRIOR = 0x21,
    CODE_NEXT = 0x22,
    CODE_END = 0x23,
    CODE_HOME = 0x24,
    CODE_LEFT = 0x25,
    CODE_UP = 0x26,
    CODE_RIGHT = 0x27,
    CODE_DOWN = 0x28,
    CODE_SELECT = 0x29,
    CODE_PRINT = 0x2a,
    CODE_EXECUTE = 0x2b,
    CODE_SNAPSHOT = 0x2c,
    CODE_INSERT = 0x2d,
    CODE_DELETE = 0x2e,
    CODE_HELP = 0x2f,

    CODE_0 = 0x30,
    CODE_1 = 0x31,
    CODE_2 = 0x32,
    CODE_3 = 0x33,
    CODE_4 = 0x34,
    CODE_5 = 0x35,
    CODE_6 = 0x36,
    CODE_7 = 0x37,
    CODE_8 = 0x38,
    CODE_9 = 0x39,

    CODE_A = 0x41,
    CODE_B = 0x42,
    CODE_C = 0x43,
    CODE_D = 0x44,
    CODE_E = 0x45,
    CODE_F = 0x46,
    CODE_G = 0x47,
    CODE_H = 0x48,
    CODE_I = 0x49,
    CODE_J = 0x4a,
    CODE_K = 0x4b,
    CODE_L = 0x4c,
    CODE_M = 0x4d,
    CODE_N = 0x4e,
    CODE_O = 0x4f,
    CODE_P = 0x50,
    CODE_Q = 0x51,
    CODE_R = 0x52,
    CODE_S = 0x53,
    CODE_T = 0x54,
    CODE_U = 0x55,
    CODE_V = 0x56,
    CODE_W = 0x57,
    CODE_X = 0x58,
    CODE_Y = 0x59,
    CODE_Z = 0x5a,
    CODE_LWIN = 0x5b,
    CODE_RWIN = 0x5c,
    CODE_APPS = 0x5d,
    CODE_NUMPAD0 = 0x60,
    CODE_NUMPAD1 = 0x61,
    CODE_NUMPAD2 = 0x62,
    CODE_NUMPAD3 = 0x63,
    CODE_NUMPAD4 = 0x64,
    CODE_NUMPAD5 = 0x65,
    CODE_NUMPAD6 = 0x66,
    CODE_NUMPAD7 = 0x67,
    CODE_NUMPAD8 = 0x68,
    CODE_NUMPAD9 = 0x69,
    CODE_MULTIPLY = 0x6a,
    CODE_ADD = 0x6b,
    CODE_SEPARATOR = 0x6c,
    CODE_SUBTRACT = 0x6d,
    CODE_DECIMAL = 0x6e,
    CODE_DIVIDE = 0x6f,
    CODE_F1 = 0x70,
    CODE_F2 = 0x71,
    CODE_F3 = 0x72,
    CODE_F4 = 0x73,
    CODE_F5 = 0x74,
    CODE_F6 = 0x75,
    CODE_F7 = 0x76,
    CODE_F8 = 0x77,
    CODE_F9 = 0x78,
    CODE_F10 = 0x79,
    CODE_F11 = 0x7a,
    CODE_F12 = 0x7b,
    CODE_F13 = 0x7c,
    CODE_F14 = 0x7d,
    CODE_F15 = 0x7e,
    CODE_F16 = 0x7f,
    CODE_F17 = 0x80,
    CODE_F18 = 0x81,
    CODE_F19 = 0x82,
    CODE_F20 = 0x83,
    CODE_F21 = 0x84,
    CODE_F22 = 0x85,
    CODE_F23 = 0x86,
    CODE_F24 = 0x87,
    CODE_NUMLOCK = 0x90,
    CODE_SCROLL = 0x91,

    // CODE_L* and CODE_R* - left and right Alt, Ctrl and Shift virtual keys.
    // Used only as parameters to GetAsyncKeyState() and GetKeyState().
    // No other API or message will distinguish left and right keys in this way.
    CODE_LSHIFT = 0xa0,
    CODE_RSHIFT = 0xa1,
    CODE_LCONTROL = 0xa2,
    CODE_RCONTROL = 0xa3,
    CODE_LMENU = 0xa4,
    CODE_RMENU = 0xa5,

    CODE_PROCESSKEY = 0xe5,

    CODE_ATTN = 0xf6,
    CODE_CRSEL = 0xf7,
    CODE_EXSEL = 0xf8,
    CODE_EREOF = 0xf9,
    CODE_PLAY = 0xfa,
    CODE_ZOOM = 0xfb,
    CODE_NONAME = 0xfc,
    CODE_PA1 = 0xfd,
    CODE_OEM_CLEAR = 0xfe,

    END_OF_ASCII_RANGE = 0xff,

    CODE_OEM_1 = 0xba, // ';:' for US
    CODE_OEM_PLUS = 0xbb, // '+' any country
    CODE_OEM_COMMA = 0xbc, // ',' any country
    CODE_OEM_MINUS = 0xbd, // '-' any country
    CODE_OEM_PERIOD = 0xbe, // '.' any country
    CODE_OEM_2 = 0xbf, // '/?' for US
    CODE_OEM_3 = 0xc0, // '`~' for US
    CODE_OEM_4 = 0xdb, // '[{' for US
    CODE_OEM_5 = 0xdc, // '\|' for US
    CODE_OEM_6 = 0xdd, // ']}' for US
    CODE_OEM_7 = 0xde, // ''"' for US
    CODE_OEM_BACKSLASH = 0xe2, // '/<>|' for non-US

    // On Windows, for Japanese keyboard, this key is mapped to one of the VK_OEM_* virtual keys.
    // Looks like keyboard logic in GS assumes that physicalKey<->vk mapping is constant across languages/layouts.
    // This holds true for all 101/102-key layouts except for 106/109-key layout (Japanese keyboard).
    // So can't use VK_OEM_* which is tied to another physical key, instead defining one from unassigned range 0xC1-0xD7
    CODE_YEN = 0xc1,
    CODE_RO = 0xc2,

    // Japanese 106 keyboard toggle keys
    CODE_HIRAGANA_KATAKANA = 0xe9, // 106/109 Japanese toggle key - 2nd on right of space [ScanCode:70]
    CODE_HENKAN_ZENKOUHO = 0xea, // 106/109 Japanese toggle key - right of space[ScanCode:79]
    CODE_MUHENKAN = 0xeb // 106/109 Japanese toggle key - left of space [ScanCode:7B]
}

export const CODE_TO_VK_MAP = new Map([
    ["Escape", VIRTUAL_KEYS.CODE_ESCAPE],
    ["Digit0", VIRTUAL_KEYS.CODE_0],
    ["Digit1", VIRTUAL_KEYS.CODE_1],
    ["Digit2", VIRTUAL_KEYS.CODE_2],
    ["Digit3", VIRTUAL_KEYS.CODE_3],
    ["Digit4", VIRTUAL_KEYS.CODE_4],
    ["Digit5", VIRTUAL_KEYS.CODE_5],
    ["Digit6", VIRTUAL_KEYS.CODE_6],
    ["Digit7", VIRTUAL_KEYS.CODE_7],
    ["Digit8", VIRTUAL_KEYS.CODE_8],
    ["Digit9", VIRTUAL_KEYS.CODE_9],
    ["Minus", VIRTUAL_KEYS.CODE_OEM_MINUS],
    ["Equal", VIRTUAL_KEYS.CODE_OEM_PLUS],
    ["Backspace", VIRTUAL_KEYS.CODE_BACK],
    ["Tab", VIRTUAL_KEYS.CODE_TAB],
    ["KeyQ", VIRTUAL_KEYS.CODE_Q],
    ["KeyW", VIRTUAL_KEYS.CODE_W],
    ["KeyE", VIRTUAL_KEYS.CODE_E],
    ["KeyR", VIRTUAL_KEYS.CODE_R],
    ["KeyT", VIRTUAL_KEYS.CODE_T],
    ["KeyY", VIRTUAL_KEYS.CODE_Y],
    ["KeyU", VIRTUAL_KEYS.CODE_U],
    ["KeyI", VIRTUAL_KEYS.CODE_I],
    ["KeyO", VIRTUAL_KEYS.CODE_O],
    ["KeyP", VIRTUAL_KEYS.CODE_P],
    ["BracketLeft", VIRTUAL_KEYS.CODE_OEM_4],
    ["BracketRight", VIRTUAL_KEYS.CODE_OEM_6],
    ["Enter", VIRTUAL_KEYS.CODE_RETURN],
    ["ControlLeft", VIRTUAL_KEYS.CODE_LCONTROL],
    ["KeyA", VIRTUAL_KEYS.CODE_A],
    ["KeyS", VIRTUAL_KEYS.CODE_S],
    ["KeyD", VIRTUAL_KEYS.CODE_D],
    ["KeyF", VIRTUAL_KEYS.CODE_F],
    ["KeyG", VIRTUAL_KEYS.CODE_G],
    ["KeyH", VIRTUAL_KEYS.CODE_H],
    ["KeyJ", VIRTUAL_KEYS.CODE_J],
    ["KeyK", VIRTUAL_KEYS.CODE_K],
    ["KeyL", VIRTUAL_KEYS.CODE_L],
    ["Semicolon", VIRTUAL_KEYS.CODE_OEM_1],
    ["Quote", VIRTUAL_KEYS.CODE_OEM_7],
    ["Backquote", VIRTUAL_KEYS.CODE_OEM_3],
    ["ShiftLeft", VIRTUAL_KEYS.CODE_LSHIFT],
    ["Backslash", VIRTUAL_KEYS.CODE_OEM_5],
    ["KeyZ", VIRTUAL_KEYS.CODE_Z],
    ["KeyX", VIRTUAL_KEYS.CODE_X],
    ["KeyC", VIRTUAL_KEYS.CODE_C],
    ["KeyV", VIRTUAL_KEYS.CODE_V],
    ["KeyB", VIRTUAL_KEYS.CODE_B],
    ["KeyN", VIRTUAL_KEYS.CODE_N],
    ["KeyM", VIRTUAL_KEYS.CODE_M],
    ["Comma", VIRTUAL_KEYS.CODE_OEM_COMMA],
    ["Period", VIRTUAL_KEYS.CODE_OEM_PERIOD],
    ["Slash", VIRTUAL_KEYS.CODE_OEM_2],
    ["ShiftRight", VIRTUAL_KEYS.CODE_RSHIFT],
    ["NumpadMultiply", VIRTUAL_KEYS.CODE_MULTIPLY],
    ["AltLeft", VIRTUAL_KEYS.CODE_LMENU],
    ["Space", VIRTUAL_KEYS.CODE_SPACE],
    ["CapsLock", VIRTUAL_KEYS.CODE_CAPITAL],
    ["F1", VIRTUAL_KEYS.CODE_F1],
    ["F2", VIRTUAL_KEYS.CODE_F2],
    ["F3", VIRTUAL_KEYS.CODE_F3],
    ["F4", VIRTUAL_KEYS.CODE_F4],
    ["F5", VIRTUAL_KEYS.CODE_F5],
    ["F6", VIRTUAL_KEYS.CODE_F6],
    ["F7", VIRTUAL_KEYS.CODE_F7],
    ["F8", VIRTUAL_KEYS.CODE_F8],
    ["F9", VIRTUAL_KEYS.CODE_F9],
    ["F10", VIRTUAL_KEYS.CODE_F10],
    ["Pause", VIRTUAL_KEYS.CODE_PAUSE],
    ["ScrollLock", VIRTUAL_KEYS.CODE_SCROLL],
    ["Numpad7", VIRTUAL_KEYS.CODE_NUMPAD7],
    ["Numpad8", VIRTUAL_KEYS.CODE_NUMPAD8],
    ["Numpad9", VIRTUAL_KEYS.CODE_NUMPAD9],
    ["NumpadSubtract", VIRTUAL_KEYS.CODE_SUBTRACT],
    ["Numpad4", VIRTUAL_KEYS.CODE_NUMPAD4],
    ["Numpad5", VIRTUAL_KEYS.CODE_NUMPAD5],
    ["Numpad6", VIRTUAL_KEYS.CODE_NUMPAD6],
    ["NumpadAdd", VIRTUAL_KEYS.CODE_ADD],
    ["Numpad1", VIRTUAL_KEYS.CODE_NUMPAD1],
    ["Numpad2", VIRTUAL_KEYS.CODE_NUMPAD2],
    ["Numpad3", VIRTUAL_KEYS.CODE_NUMPAD3],
    ["Numpad0", VIRTUAL_KEYS.CODE_NUMPAD0],
    ["NumpadDecimal", VIRTUAL_KEYS.CODE_DECIMAL],
    ["PrintScreen", VIRTUAL_KEYS.CODE_PRINT],
    ["IntlBackslash", VIRTUAL_KEYS.CODE_OEM_BACKSLASH],
    ["F11", VIRTUAL_KEYS.CODE_F11],
    ["F12", VIRTUAL_KEYS.CODE_F12],
    ["NumpadEqual", VIRTUAL_KEYS.CODE_OEM_PLUS], // bestguess
    ["F13", VIRTUAL_KEYS.CODE_F13],
    ["F14", VIRTUAL_KEYS.CODE_F14],
    ["F15", VIRTUAL_KEYS.CODE_F15],
    ["F16", VIRTUAL_KEYS.CODE_F16],
    ["F17", VIRTUAL_KEYS.CODE_F17],
    ["F18", VIRTUAL_KEYS.CODE_F18],
    ["F19", VIRTUAL_KEYS.CODE_F19],
    ["F20", VIRTUAL_KEYS.CODE_F20],
    ["F21", VIRTUAL_KEYS.CODE_F21],
    ["F22", VIRTUAL_KEYS.CODE_F22],
    ["F23", VIRTUAL_KEYS.CODE_F23],
    ["F24", VIRTUAL_KEYS.CODE_F24],
    ["KanaMode", VIRTUAL_KEYS.CODE_HIRAGANA_KATAKANA],
    ["Lang2", VIRTUAL_KEYS.CODE_HANJA],
    ["Lang1", VIRTUAL_KEYS.CODE_HANGUL],
    ["IntlRo", VIRTUAL_KEYS.CODE_RO],
    ["Convert", VIRTUAL_KEYS.CODE_HENKAN_ZENKOUHO],
    ["NonConvert", VIRTUAL_KEYS.CODE_MUHENKAN],
    ["IntlYen", VIRTUAL_KEYS.CODE_YEN],
    ["NumpadComma", VIRTUAL_KEYS.CODE_OEM_COMMA], // bestguess
    //    ["Undo", VIRTUAL_KEYS.CODE_],
    //    ["Paste", VIRTUAL_KEYS.CODE_],
    //    ["MediaTrackPrevious", VIRTUAL_KEYS.CODE_],
    //    ["Cut", VIRTUAL_KEYS.CODE_],
    //    ["Copy", VIRTUAL_KEYS.CODE_],
    //    ["MediaTrackNext", VIRTUAL_KEYS.CODE_],
    ["NumpadEnter", VIRTUAL_KEYS.CODE_RETURN], // mapped
    ["ControlRight", VIRTUAL_KEYS.CODE_RCONTROL],
    //    ["LaunchMail", VIRTUAL_KEYS.CODE_],
    //    ["AudioVolumeMute", VIRTUAL_KEYS.CODE_],
    //    ["LaunchApp2", VIRTUAL_KEYS.CODE_],
    //    ["MediaPlayPause", VIRTUAL_KEYS.CODE_],
    //    ["MediaStop", VIRTUAL_KEYS.CODE_],
    //    ["Eject", VIRTUAL_KEYS.CODE_],
    //    ["AudioVolumeDown", VIRTUAL_KEYS.CODE_],
    //    ["AudioVolumeUp", VIRTUAL_KEYS.CODE_],
    //    ["BrowserHome", VIRTUAL_KEYS.CODE_],
    ["NumpadDivide", VIRTUAL_KEYS.CODE_DIVIDE],
    ["PrintScreen", VIRTUAL_KEYS.CODE_PRINT],
    ["AltRight", VIRTUAL_KEYS.CODE_RMENU],
    //    ["Help", VIRTUAL_KEYS.CODE_],
    ["NumLock", VIRTUAL_KEYS.CODE_NUMLOCK],
    ["Pause", VIRTUAL_KEYS.CODE_PAUSE],
    ["Home", VIRTUAL_KEYS.CODE_HOME],
    ["ArrowUp", VIRTUAL_KEYS.CODE_UP],
    ["PageUp", VIRTUAL_KEYS.CODE_PRIOR],
    ["ArrowLeft", VIRTUAL_KEYS.CODE_LEFT],
    ["ArrowRight", VIRTUAL_KEYS.CODE_RIGHT],
    ["End", VIRTUAL_KEYS.CODE_END],
    ["ArrowDown", VIRTUAL_KEYS.CODE_DOWN],
    ["PageDown", VIRTUAL_KEYS.CODE_NEXT],
    ["Insert", VIRTUAL_KEYS.CODE_INSERT],
    ["Delete", VIRTUAL_KEYS.CODE_DELETE],
    ["MetaLeft", VIRTUAL_KEYS.CODE_LWIN],
    ["OSLeft", VIRTUAL_KEYS.CODE_LWIN],
    ["MetaRight", VIRTUAL_KEYS.CODE_RWIN],
    ["OSRight", VIRTUAL_KEYS.CODE_RWIN],
    //    ["ContextMenu", VIRTUAL_KEYS.CODE_],
    //    ["Power", VIRTUAL_KEYS.CODE_],
    //    ["BrowserSearch", VIRTUAL_KEYS.CODE_],
    //    ["BrowserFavorites", VIRTUAL_KEYS.CODE_],
    //    ["BrowserRefresh", VIRTUAL_KEYS.CODE_],
    //    ["BrowserStop", VIRTUAL_KEYS.CODE_],
    //    ["BrowserForward", VIRTUAL_KEYS.CODE_],
    //    ["BrowserBack", VIRTUAL_KEYS.CODE_],
    //    ["LaunchApp1", VIRTUAL_KEYS.CODE_],
    //    ["LaunchMail", VIRTUAL_KEYS.CODE_],
    //    ["LaunchMediaPlayer", VIRTUAL_KEYS.CODE_],
    ["Lang2", VIRTUAL_KEYS.CODE_HANJA],
    ["Lang1", VIRTUAL_KEYS.CODE_HANGUL],

    ["NumpadClear", VIRTUAL_KEYS.CODE_CLEAR], // mapped
    ["NumpadClearEntry", VIRTUAL_KEYS.CODE_CLEAR] // bestguess
]);

// fallback map in case evt.code is not set.
export const KEY_TO_VK_MAP = new Map([
    ["HanjaMode", VIRTUAL_KEYS.CODE_HANJA],
    ["HangulMode", VIRTUAL_KEYS.CODE_HANGUL]
]);

export declare interface VirtualKeyCodeMapping {
    vkCode: number;
    shift: boolean;
}

// Mapping char to {VK_Code, Shift_ModifierFlag}
// On android platforms text input is handled via textcomposition/input events.
// And we don't received keycodes in keydown/keyup events.
export const CHAR_TO_VK_MAP = new Map<String, VirtualKeyCodeMapping>([
    ["1", { vkCode: VIRTUAL_KEYS.CODE_1, shift: false }],
    ["2", { vkCode: VIRTUAL_KEYS.CODE_2, shift: false }],
    ["3", { vkCode: VIRTUAL_KEYS.CODE_3, shift: false }],
    ["4", { vkCode: VIRTUAL_KEYS.CODE_4, shift: false }],
    ["5", { vkCode: VIRTUAL_KEYS.CODE_5, shift: false }],
    ["6", { vkCode: VIRTUAL_KEYS.CODE_6, shift: false }],
    ["7", { vkCode: VIRTUAL_KEYS.CODE_7, shift: false }],
    ["8", { vkCode: VIRTUAL_KEYS.CODE_8, shift: false }],
    ["9", { vkCode: VIRTUAL_KEYS.CODE_9, shift: false }],
    ["0", { vkCode: VIRTUAL_KEYS.CODE_0, shift: false }],

    ["!", { vkCode: VIRTUAL_KEYS.CODE_1, shift: true }],
    ["@", { vkCode: VIRTUAL_KEYS.CODE_2, shift: true }],
    ["#", { vkCode: VIRTUAL_KEYS.CODE_3, shift: true }],
    ["$", { vkCode: VIRTUAL_KEYS.CODE_4, shift: true }],
    ["%", { vkCode: VIRTUAL_KEYS.CODE_5, shift: true }],
    ["^", { vkCode: VIRTUAL_KEYS.CODE_6, shift: true }],
    ["&", { vkCode: VIRTUAL_KEYS.CODE_7, shift: true }],
    ["*", { vkCode: VIRTUAL_KEYS.CODE_8, shift: true }],
    ["(", { vkCode: VIRTUAL_KEYS.CODE_9, shift: true }],
    [")", { vkCode: VIRTUAL_KEYS.CODE_0, shift: true }],

    ["-", { vkCode: VIRTUAL_KEYS.CODE_OEM_MINUS, shift: false }],
    ["_", { vkCode: VIRTUAL_KEYS.CODE_OEM_MINUS, shift: true }],

    ["=", { vkCode: VIRTUAL_KEYS.CODE_OEM_PLUS, shift: false }],
    ["+", { vkCode: VIRTUAL_KEYS.CODE_OEM_PLUS, shift: true }],

    [",", { vkCode: VIRTUAL_KEYS.CODE_OEM_COMMA, shift: false }],
    ["<", { vkCode: VIRTUAL_KEYS.CODE_OEM_COMMA, shift: true }],

    [".", { vkCode: VIRTUAL_KEYS.CODE_OEM_PERIOD, shift: false }],
    [">", { vkCode: VIRTUAL_KEYS.CODE_OEM_PERIOD, shift: true }],

    [";", { vkCode: VIRTUAL_KEYS.CODE_OEM_1, shift: false }],
    [":", { vkCode: VIRTUAL_KEYS.CODE_OEM_1, shift: true }],

    ["/", { vkCode: VIRTUAL_KEYS.CODE_OEM_2, shift: false }],
    ["?", { vkCode: VIRTUAL_KEYS.CODE_OEM_2, shift: true }],

    ["`", { vkCode: VIRTUAL_KEYS.CODE_OEM_3, shift: false }],
    ["~", { vkCode: VIRTUAL_KEYS.CODE_OEM_3, shift: true }],

    ["[", { vkCode: VIRTUAL_KEYS.CODE_OEM_4, shift: false }],
    ["{", { vkCode: VIRTUAL_KEYS.CODE_OEM_4, shift: true }],

    ["\\", { vkCode: VIRTUAL_KEYS.CODE_OEM_5, shift: false }],
    ["|", { vkCode: VIRTUAL_KEYS.CODE_OEM_5, shift: true }],

    ["]", { vkCode: VIRTUAL_KEYS.CODE_OEM_6, shift: false }],
    ["}", { vkCode: VIRTUAL_KEYS.CODE_OEM_6, shift: true }],

    ["'", { vkCode: VIRTUAL_KEYS.CODE_OEM_7, shift: false }],
    ['"', { vkCode: VIRTUAL_KEYS.CODE_OEM_7, shift: true }],

    [" ", { vkCode: VIRTUAL_KEYS.CODE_SPACE, shift: false }],

    ["A", { vkCode: VIRTUAL_KEYS.CODE_A, shift: true }],
    ["B", { vkCode: VIRTUAL_KEYS.CODE_B, shift: true }],
    ["C", { vkCode: VIRTUAL_KEYS.CODE_C, shift: true }],
    ["D", { vkCode: VIRTUAL_KEYS.CODE_D, shift: true }],
    ["E", { vkCode: VIRTUAL_KEYS.CODE_E, shift: true }],
    ["F", { vkCode: VIRTUAL_KEYS.CODE_F, shift: true }],
    ["G", { vkCode: VIRTUAL_KEYS.CODE_G, shift: true }],
    ["H", { vkCode: VIRTUAL_KEYS.CODE_H, shift: true }],
    ["I", { vkCode: VIRTUAL_KEYS.CODE_I, shift: true }],
    ["J", { vkCode: VIRTUAL_KEYS.CODE_J, shift: true }],
    ["K", { vkCode: VIRTUAL_KEYS.CODE_K, shift: true }],
    ["L", { vkCode: VIRTUAL_KEYS.CODE_L, shift: true }],
    ["M", { vkCode: VIRTUAL_KEYS.CODE_M, shift: true }],
    ["N", { vkCode: VIRTUAL_KEYS.CODE_N, shift: true }],
    ["O", { vkCode: VIRTUAL_KEYS.CODE_O, shift: true }],
    ["P", { vkCode: VIRTUAL_KEYS.CODE_P, shift: true }],
    ["Q", { vkCode: VIRTUAL_KEYS.CODE_Q, shift: true }],
    ["R", { vkCode: VIRTUAL_KEYS.CODE_R, shift: true }],
    ["S", { vkCode: VIRTUAL_KEYS.CODE_S, shift: true }],
    ["T", { vkCode: VIRTUAL_KEYS.CODE_T, shift: true }],
    ["U", { vkCode: VIRTUAL_KEYS.CODE_U, shift: true }],
    ["V", { vkCode: VIRTUAL_KEYS.CODE_V, shift: true }],
    ["W", { vkCode: VIRTUAL_KEYS.CODE_W, shift: true }],
    ["X", { vkCode: VIRTUAL_KEYS.CODE_X, shift: true }],
    ["Y", { vkCode: VIRTUAL_KEYS.CODE_Y, shift: true }],
    ["Z", { vkCode: VIRTUAL_KEYS.CODE_Z, shift: true }],

    ["a", { vkCode: VIRTUAL_KEYS.CODE_A, shift: false }],
    ["b", { vkCode: VIRTUAL_KEYS.CODE_B, shift: false }],
    ["c", { vkCode: VIRTUAL_KEYS.CODE_C, shift: false }],
    ["d", { vkCode: VIRTUAL_KEYS.CODE_D, shift: false }],
    ["e", { vkCode: VIRTUAL_KEYS.CODE_E, shift: false }],
    ["f", { vkCode: VIRTUAL_KEYS.CODE_F, shift: false }],
    ["g", { vkCode: VIRTUAL_KEYS.CODE_G, shift: false }],
    ["h", { vkCode: VIRTUAL_KEYS.CODE_H, shift: false }],
    ["i", { vkCode: VIRTUAL_KEYS.CODE_I, shift: false }],
    ["j", { vkCode: VIRTUAL_KEYS.CODE_J, shift: false }],
    ["k", { vkCode: VIRTUAL_KEYS.CODE_K, shift: false }],
    ["l", { vkCode: VIRTUAL_KEYS.CODE_L, shift: false }],
    ["m", { vkCode: VIRTUAL_KEYS.CODE_M, shift: false }],
    ["n", { vkCode: VIRTUAL_KEYS.CODE_N, shift: false }],
    ["o", { vkCode: VIRTUAL_KEYS.CODE_O, shift: false }],
    ["p", { vkCode: VIRTUAL_KEYS.CODE_P, shift: false }],
    ["q", { vkCode: VIRTUAL_KEYS.CODE_Q, shift: false }],
    ["r", { vkCode: VIRTUAL_KEYS.CODE_R, shift: false }],
    ["s", { vkCode: VIRTUAL_KEYS.CODE_S, shift: false }],
    ["t", { vkCode: VIRTUAL_KEYS.CODE_T, shift: false }],
    ["u", { vkCode: VIRTUAL_KEYS.CODE_U, shift: false }],
    ["v", { vkCode: VIRTUAL_KEYS.CODE_V, shift: false }],
    ["w", { vkCode: VIRTUAL_KEYS.CODE_W, shift: false }],
    ["x", { vkCode: VIRTUAL_KEYS.CODE_X, shift: false }],
    ["y", { vkCode: VIRTUAL_KEYS.CODE_Y, shift: false }],
    ["z", { vkCode: VIRTUAL_KEYS.CODE_Z, shift: false }]
]);
