import { IsiDevice, PlatformDetails, AppLaunchMode } from "../dependencies";
import { StreamingQuality, MicState, StreamingResolution, StreamUpdateEvent } from "../interfaces";
import { StreamingStats } from "../internalinterfaces";
import { ExtendedDebugStats, StaticStreamStats } from "../rinterfaces";
import { GetResolutionString } from "../util/utils";

const LOGTAG = "streamstats";

interface PerfOscStats {
    value: number;
    count: number;
    max: number;
}

class RollingAverage {
    prevSum: number = 0;
    currentSum: number = 0;
    prevCount: number = 0;
    currentCount: number = 0;

    public update(sum: number, count: number) {
        if (this.prevSum == 0 && this.prevCount == 0) {
            this.prevSum = sum;
            this.prevCount = count;
            return;
        }

        this.currentCount = count;
        this.currentSum = sum;
    }

    public getRollingAvg() {
        const count = this.currentCount - this.prevCount;
        if (count != 0) {
            return (this.currentSum - this.prevSum) / count;
        }
        return 0;
    }

    public reset() {
        this.prevSum = this.currentSum;
        this.prevCount = this.currentCount;
    }
}

const STATS_OVERLAY_ID = "overlay-543d9968";

export class StreamStats {
    private overlayElement: HTMLElement;
    private streamingQuality: StreamingQuality;
    private fps: number;
    private avgStreamingRate: number;
    private bwu: number;
    private bweMbps: number;
    private frameDecoded: number;
    private frameReceived: number;
    private framesDropped: number;
    private avgVideoJitterBufferDelay: number;
    private avgGameFps?: number;
    private rtd: number;
    private packetLoss: number;
    private streamingResolution: StreamingResolution;
    private interFrameDelay: number;
    private videoElement: HTMLVideoElement;
    private mainThreadblockedDuration: number;
    private dcSendDuration: PerfOscStats;
    private getstatsDuration: PerfOscStats;
    private decodeTimeAvg: RollingAverage;
    private cumulativeAvgDecodeTime: number;
    private frameLost: number;
    private prevFrameLost: number;
    private extendedDebugStats: ExtendedDebugStats;
    private staticStreamStats: StaticStreamStats;
    private platformDetails: PlatformDetails;
    private codec?: number;
    private softwareDecodeFallback: boolean;
    private decoder: string;
    constructor(
        videoElement: HTMLVideoElement,
        platformDetails: PlatformDetails,
        staticStreamStats: StaticStreamStats
    ) {
        this.platformDetails = platformDetails;
        this.streamingQuality = {
            qualityScore: 0,
            bandwidthScore: 0,
            networkLossScore: 0,
            latencyScore: 0
        };
        this.fps = 0;
        this.avgStreamingRate = 0;
        this.bwu = 4;
        this.bweMbps = 0;
        this.frameDecoded = 0;
        this.frameReceived = 0;
        this.framesDropped = 0;
        this.avgVideoJitterBufferDelay = 0;
        this.rtd = 0;
        this.packetLoss = 0;
        this.streamingResolution = { width: 0, height: 0 };
        this.interFrameDelay = 0;
        this.videoElement = videoElement;
        this.mainThreadblockedDuration = 0;
        this.dcSendDuration = {
            value: 0,
            count: 0,
            max: 0
        };
        this.getstatsDuration = {
            value: 0,
            count: 0,
            max: 0
        };
        this.decodeTimeAvg = new RollingAverage();
        let overlay = document.getElementById(STATS_OVERLAY_ID);
        if (!overlay) {
            overlay = this.createOverlayNode();
            this.videoElement.insertAdjacentElement("afterend", overlay);
        }
        this.overlayElement = overlay;
        this.cumulativeAvgDecodeTime = 0;
        this.frameLost = 0;
        this.prevFrameLost = 0;
        this.extendedDebugStats = {
            isVideoElementPaused: false,
            isAudioElementPaused: false,
            isUserInputEnabled: false,
            isVirtualKeyboardVisible: false,
            micState: MicState.UNINITIALIZED,
            isRsdmmActive: false,
            keyboardLayout: "",
            appLaunchMode: AppLaunchMode.Default
        };
        this.staticStreamStats = staticStreamStats;
        this.softwareDecodeFallback = false;
        this.decoder = "";
    }

    public updateFps(
        currentReport: RTCInboundRtpStreamStats,
        prevReport: RTCInboundRtpStreamStats
    ) {
        let currentFramesDecoded = (<any>currentReport).framesDecoded;
        let prevFramesDecoded = (<any>prevReport).framesDecoded;

        let t1 = prevReport.timestamp ?? 0;
        let t2 = currentReport.timestamp ?? 0;
        if (t1 == t2) return;
        let fps = ((currentFramesDecoded - prevFramesDecoded) / (t2 - t1)) * 1000;
        this.fps = Math.floor(fps);
    }

    public updateAvgDecodeTime(currentReport: any) {
        if (!currentReport) return;
        this.decodeTimeAvg.update(currentReport.totalDecodeTime, currentReport.framesDecoded);
    }

    public updateCumulativeAvgDecodeTime(cumulativeTime: number) {
        this.cumulativeAvgDecodeTime = cumulativeTime;
    }

    public updateAvgStreamingRate(
        currentReport: RTCIceCandidatePairStats,
        prevReport: RTCIceCandidatePairStats
    ) {
        let currentBytesReceived = currentReport.bytesReceived ?? 0;
        let prevBytesReceived = prevReport.bytesReceived ?? 0;

        let t1 = currentReport.timestamp ?? 0;
        let t2 = prevReport.timestamp ?? 0;
        if (t1 == t2) return;

        this.avgStreamingRate = (currentBytesReceived - prevBytesReceived) / ((t1 - t2) * 125);
    }

    public updateBwu(currentBweMbps: number) {
        // Make sure to update the bwu ONLY when avgStreamingRate and currentBweMbps are in sync
        //  avgStreamingRate might not be up to date when currentBweMbps is updated since
        //  currentBweMbps is updated by the server whereas avgStreamingRate is updated by the
        //  client. When the bandwidth drops, this leads to a wrong, not consistent values
        if (currentBweMbps && this.avgStreamingRate <= currentBweMbps) {
            this.bwu = (this.avgStreamingRate / currentBweMbps) * 100;
        }
        this.bweMbps = currentBweMbps;
        return this.bwu;
    }

    public updatepacketLoss(packetLost: number) {
        if (!packetLost) return;
        this.packetLoss = packetLost;
    }

    public updateFrameDecoded(frameNumber: number) {
        if (!frameNumber) return;
        this.frameDecoded = frameNumber;
    }

    public updateFrameReceived(frameReceived: number) {
        if (!frameReceived) return;
        this.frameReceived = frameReceived;
    }

    public updateFramesDropped(framesDropped: number) {
        if (!framesDropped) return;
        this.framesDropped = framesDropped;
    }

    public updateStreamingResolution(width: number, height: number) {
        if (!width || !height) return;
        this.streamingResolution = { width: width, height: height };
    }

    public updateQScore(sq: StreamingQuality) {
        if (!sq) return;
        this.streamingQuality = sq;
    }

    public updateRtd(rtd: number) {
        this.rtd = rtd;
    }

    public updateJitter(jitter: number) {
        this.avgVideoJitterBufferDelay = jitter;
    }

    public updateAvgGameFps(fps: number) {
        this.avgGameFps = fps;
    }
    public updateInterFrameDelay(totalInterFrameDelay: number, framesReceived: number) {
        let totalInterFrameDelayMs = totalInterFrameDelay * 1000;
        this.interFrameDelay = totalInterFrameDelayMs / (framesReceived - 1);
    }

    public getStreamingStats() {
        let streamingStats: StreamingStats = {
            fps: this.fps,
            cumulativeAvgDecodeTime: this.cumulativeAvgDecodeTime,
            avgDecodeTime: this.decodeTimeAvg.getRollingAvg() * 1000,
            bwe: this.bweMbps,
            bwu: this.bwu,
            width: this.streamingResolution.width,
            height: this.streamingResolution.height
        };
        return streamingStats;
    }

    public makeStreamUpdateEvent(): StreamUpdateEvent {
        const event: StreamUpdateEvent = {
            /** Average game FPS */
            avgGameFps: this.avgGameFps ?? 0,
            /** Streaming FPS */
            fps: this.fps,
            /** Round trip delay (ms) */
            rtd: this.rtd,
            /** Average decode cost (ms) */
            avgDecodeTime: this.decodeTimeAvg.getRollingAvg() * 1000,
            /** Total frame loss since last update */
            frameLoss: this.frameLost - this.prevFrameLost,
            /** Total packet loss */
            packetLoss: this.packetLoss,
            /** Available bandwidth (Mbps) */
            totalBandwidth: this.bweMbps,
            /** Utilized bandwidth (%) */
            utilizedBandwidth: this.bwu,
            /** Streaming resolution */
            streamingResolution: this.streamingResolution
        };
        this.prevFrameLost = this.frameLost;
        return event;
    }

    public drawStatsOnScreen(enableDevStats: boolean) {
        this.overlayElement.innerText = this.getText(enableDevStats);
        this.dcSendDuration = { value: 0, count: 0, max: 0 };
        this.getstatsDuration = { value: 0, count: 0, max: 0 };
        this.decodeTimeAvg.reset();
    }

    public setShown(show: boolean) {
        this.overlayElement.style.display = show ? "block" : "none";
    }

    private createOverlayNode() {
        var overlay = document.createElement("div");
        overlay.id = STATS_OVERLAY_ID;
        overlay.style.display = "none";
        overlay.style.position = "fixed";
        //@todo think of a way to remove dependency on platformDetails and remove it as class member
        if (IsiDevice(this.platformDetails)) {
            overlay.style.top = "env(safe-area-inset-top, 0)";
            overlay.style.left = "max(24px, env(safe-area-inset-left, 0))";
        } else {
            overlay.style.top = "0";
            overlay.style.left = "0";
        }
        overlay.style.padding = "0.5em";
        overlay.style.backgroundColor = "rgba(0,0,0,0.5)";
        overlay.style.zIndex = "300";
        overlay.style.fontSize = "12px";
        overlay.style.fontFamily = "monospace";
        overlay.style.color = "white";
        overlay.style.whiteSpace = "pre";
        overlay.style.lineHeight = "100%";
        overlay.style.pointerEvents = "none";
        return overlay;
    }

    public updateDcSendDuration(time: number) {
        this.dcSendDuration.value += time;
        this.dcSendDuration.count += 1;
        this.dcSendDuration.max = Math.max(time, this.dcSendDuration.max);
    }

    public updateStatsDuration(time: number) {
        this.getstatsDuration.value += time;
        this.getstatsDuration.count += 1;
        this.getstatsDuration.max = Math.max(time, this.getstatsDuration.max);
    }

    public updateMainThreadBlockDuration(duration: number) {
        this.mainThreadblockedDuration = Math.max(duration, this.mainThreadblockedDuration);
    }

    private getAvg(stats: PerfOscStats) {
        let avg = 0;
        if (stats.count > 0) {
            avg = stats.value / stats.count;
        }
        return avg.toFixed(2);
    }

    private getMax(stats: PerfOscStats) {
        return stats.max.toFixed(2);
    }

    public updateFrameLoss(pliCount: number) {
        if (pliCount === undefined || isNaN(pliCount)) {
            return;
        }
        this.frameLost = pliCount;
    }

    public updateExtendedDebugStats(stats: ExtendedDebugStats) {
        this.extendedDebugStats = stats;
    }

    public updateVideoCodec(codec: string) {
        // 5 represents H265; 6 represents AV1; 4 represents H264 or unknown
        codec = codec.toUpperCase();
        if (codec.includes("H265")) {
            this.codec = 5;
        } else if (codec.includes("AV1")) {
            this.codec = 6;
        } else {
            this.codec = 4;
        }
    }

    public setSoftwareDecodeFallback(fallback: boolean) {
        this.softwareDecodeFallback = fallback;
    }

    public setDecoderImplementation(decoder: string) {
        this.decoder = decoder;
    }

    /**
     * Convert boolean to y/n string representation
     */
    private boolToString(x: boolean) {
        return x ? "y" : "n";
    }

    public getText(enableDevStats: boolean): string {
        const line1 = `Seat: ${this.staticStreamStats.zoneName} (${this.staticStreamStats.requestedRegion}) / ${this.staticStreamStats.gpuType}\n`;

        let line2 = `Game: CMS ${this.staticStreamStats.appId}`;
        if (this.avgGameFps !== undefined) {
            line2 += ` / fps ${this.avgGameFps.toFixed()}`;
        }
        line2 += `\n`;

        const line3 = `Stream: Current ${GetResolutionString(this.streamingResolution)}@${
            this.fps
        } / Default: ${this.staticStreamStats.streamInfo.width}x${
            this.staticStreamStats.streamInfo.height
        }@${this.staticStreamStats.streamInfo.fps} / Codec ${this.codec ?? ""}\n`;

        const line4 = `Network: RTD ${this.rtd}ms / FL ${this.frameLost} / PL ${
            this.packetLoss
        } / J ${this.avgVideoJitterBufferDelay.toFixed(
            2
        )}ms / Bitrate ${this.avgStreamingRate.toFixed(2)}Mbps / BWU ${this.bwu.toFixed(2)}%\n`;

        const line5 = `QOS: frame ${this.frameDecoded} / FT ${this.interFrameDelay.toFixed(
            2
        )} / D ${(this.decodeTimeAvg.getRollingAvg() * 1000).toFixed(2)} / Q ${Math.floor(
            this.streamingQuality.qualityScore
        )}\n`;

        const line6 = `Client: ${this.platformDetails.os} ${
            this.staticStreamStats.clientAppVersion
        } ${this.staticStreamStats.clientLocale} Resolution ${GetResolutionString({
            width: window.innerWidth * window.devicePixelRatio,
            height: window.innerHeight * window.devicePixelRatio
        })}\n`;

        let text = line1 + line2 + line3 + line4 + line5 + line6;

        const internalStatsLine1 = `DC ${this.getAvg(this.dcSendDuration)}ms (${this.getMax(
            this.dcSendDuration
        )}ms) / Blocked ${this.mainThreadblockedDuration}\n`;

        const internalStatsLine2 = `Stats ${this.getAvg(this.getstatsDuration)}ms (${this.getMax(
            this.getstatsDuration
        )}ms) / FR ${this.frameReceived} / FDR ${this.framesDropped}\n`;

        const internalStatsLine3 = `Latency ${this.streamingQuality.latencyScore.toFixed(
            1
        )} / Network ${this.streamingQuality.networkLossScore.toFixed(
            1
        )} / Bandwidth ${this.streamingQuality.bandwidthScore.toFixed(1)}\n`;

        const internalStatsLine4 = `VP ${this.boolToString(
            this.extendedDebugStats.isVideoElementPaused
        )} / AP ${this.boolToString(
            this.extendedDebugStats.isAudioElementPaused
        )} / UI ${this.boolToString(
            this.extendedDebugStats.isUserInputEnabled
        )} / VKB ${this.boolToString(this.extendedDebugStats.isVirtualKeyboardVisible)} / MS ${
            this.extendedDebugStats.micState
        } / RSDMM ${+this.extendedDebugStats.isRsdmmActive}\n`;

        const internalStatsLine5 = `KBL ${this.extendedDebugStats.keyboardLayout} / ALM ${+this
            .extendedDebugStats.appLaunchMode} / SWD ${this.boolToString(
            this.softwareDecodeFallback
        )} / ${this.decoder}`;

        // Enable dev stats for only internal clients
        if (enableDevStats) {
            text +=
                internalStatsLine1 +
                internalStatsLine2 +
                internalStatsLine3 +
                internalStatsLine4 +
                internalStatsLine5;
        }

        return text;
    }
}
