export const enum WireProtocolPacketId {
    MOUSE_GROUP = 0x20,
    RI_PACKET = 0x21,
    RI_NO_SIZE = 0x22,
    SENT_TIMESTAMP = 0x23,
    CHROME_CALLBACK_TIMESTAMP = 0x24,
    INPUT_GROUP = 0xff
}

export const CHROME_CALLBACK_LEN = 9;
export const SENT_TIMESTAMP_LEN = 9;

export const MOUSE_GROUP_HEADER_LEN = 19;

export const enum PacketId {
    PACKET_HEARTBEAT = 0x00000002,
    PACKET_KEYDOWN = 0x00000003,
    PACKET_KEYUP = 0x00000004,
    PACKET_MOUSEMOVE_ABS = 0x00000005,
    PACKET_MOUSEMOVE_ABS_VIRT = 0x00000006,
    PACKET_MOUSEMOVE_REL = 0x00000007,
    PACKET_MOUSEDOWN = 0x00000008,
    PACKET_MOUSEUP = 0x00000009,
    PACKET_MOUSEWHEEL = 0x0000000a,
    PACKET_GAMEPAD = 0x0000000b,
    PACKET_GAMEPAD_MULTIUSER = 0x0000000c,
    PACKET_HAPTICS_CONTROL = 0x0000000d,
    PACKET_LOCK_KEYS = 0x00000013,
    PACKET_UNICODE = 0x00000017,
    PACKET_TOUCH_LOW_LEVEL = 0x00000018,
    // Legacy server-to-client commands only after here.
    PACKET_HAPTICS_EVENT = 0x0000010b
}

// Two specific 'legacy' commands for the input channel are sent as-is from server to client (for the RTC transport).
export const enum ServerCommands {
    STREAMER_SERVER_HAPTICS_EVENT = 0x010b,
    STREAMER_INPUT_CHANNEL_VERSINO_INFO = 0x020e
}

// See "struct HapticsCommand" in Mjolnir/RemoteInput/common/haptics.h
export const enum HapticsCommands {
    HAPTICS_NOOP = 0,
    HAPTICS_SIMPLE = 1,
    HAPTICS_DURATION = 2
}

export const enum InputModifierFlags {
    NVST_MF_NONE = 0x0000,
    NVST_MF_SHIFT = 0x0001,
    NVST_MF_CONTROL = 0x0002,
    NVST_MF_ALT = 0x0004,
    NVST_MF_META = 0x0008,
    NVST_MF_SHIFT_RIGHT = 0x0010,
    NVST_MF_CONTROL_RIGHT = 0x0020,
    NVST_MF_ALT_RIGHT = 0x0040,
    NVST_MF_META_RIGHT = 0x0080,
    NVST_MF_ABSCOORDS = 0x0800,
    NVST_MF_VIRTUAL = 0x1000
}

export const enum LockKeyBitMask {
    NVST_LKB_NONE = 0x00,
    NVST_LKB_CAPS = 0x01,
    NVST_LKB_NUM = 0x02,
    NVST_LKB_SCROLL = 0x04,
    // These bits determine if the corresponding bits above are valid.
    // If unset, the state of the corresponding key will not change.
    NVST_LKB_CAPS_VALID = 0x10,
    NVST_LKB_NUM_VALID = 0x20,
    NVST_LKB_SCROLL_VALID = 0x40
}
