import {
    GridServer,
    GridServerCallbacks,
    PassThruServer,
    SESSIONMODIFY_ACTION,
    SessionParams,
    StreamParam,
    AppLaunchMode,
    SessionState,
    SessionProgressUpdateEvent,
    SessionProgressState,
    GsInitParams,
    GS_EVENTS,
    ActiveSessionResultEvent,
    TelemetryHttpEvent,
    ClientType,
    GridSession,
    ClientStreamer,
    AuthInfo,
    IsValidIPv4,
    GetHexString,
    CLIENT_IDENTIFICATION,
    CLIENT_VERSION,
    customFetch,
    TelemetryDebugEvent,
    PlatformDetails,
    AddPlatformTelemetry,
    ErrorDetails,
    IsMacOS,
    IsTouchDevice,
    IsTV,
    IsXbox,
    UTIL_EVENTS,
    getNewGuid,
    LogQueueingEventEmitter,
    TracingCarrier,
    TracingInfo,
    enableTracing,
    IsWebOS,
    IsiDevice,
    MonitorSettings,
    TelemetryEventPayload,
    IsiOSVersion,
    IsiOSVersionAtLeast,
    NetworkTypeEnum,
    PackageVersion,
    Log,
    IEventEmitter
} from "./dependencies";
import { EventDataElements, StreamExitEventData } from "./telemetry/telemetryinterfaces";
import {
    InitParams,
    StartSessionResultEvent,
    StopSessionResultEvent,
    GetSessionResult,
    InputConfigFlags,
    defaultInputConfigFlags,
    EVENTS,
    Zoneless,
    CustomMessage,
    RNotificationCode,
    InputType,
    StreamingTerminatedEvent
} from "./interfaces";
import { StreamClient } from "./streamclient";
import { Connectivity } from "./telemetry/analytics";
import { TelemetryHandler } from "./telemetry/telemetryhandler";
import { MicCapturer } from "./miccapturer";
import {
    ConvertErrorOnSleep,
    ConvertErrorOnConnectivityTest,
    ShouldRunConnectivityTest,
    CanResume
} from "./util/utils";
import { RagnarokSettings } from "./util/settings";
import { SleepDetector } from "./util/sleepdetector";
import { VirtualGamepadHandler } from "./input/virtualgamepad";
import { GamepadTester } from "./debug/gamepadtester";
import { GamepadHandler } from "./input/gamepadhandler";
import { AudioRecorder } from "./debug/audiorecorder";
import { RErrorCode } from "./rerrorcode";
import { TelemetryEventProcessor } from "./telemetry/telemetryeventprocessor";
import { IStreamCallbacks, LowAudioVolumeType } from "./rinterfaces";
import {
    DeviceCapabilities,
    MediaCapabilitiesDecodingInfo,
    ConvertCapabilityToNumber,
    IsMediaCapabilitiesSupported
} from "./util/devicecapabilities";
import { NetworkDetector } from "./util/networkdetector";
import { MockPMGridServer } from "./util/mockpmgridserver";

const LOGTAG = "gridapp";

const IOS_CONTEXT_PROP = "ios15AudioContext";
const IOS_STREAM_PROP = "ios15AudioStream";

performance.mark("GfnRBegin");

interface ValidatedStreamParam {
    videoElement: HTMLVideoElement;
    audioElement: HTMLAudioElement;
}

interface ValidatedSessionParams {
    sessionParams: SessionParams;
    validatedStreamParams: ValidatedStreamParam[];
}

declare interface LGEventDetails {
    idle: boolean;
}

declare interface LGSystemIdleEvent extends Event {
    detail: LGEventDetails;
}

interface ConnectivityStatus {
    errorCode: number;
    connectivity: string;
}

/**
 * The interface which defines the protocol to connect to server.
 *
 * GridApp is the class which implements the protocol and provides the actual ability to connect to server.
 * And it could be instantiated as: const gridApp: GridApp = new GridApp(platformDetails);
 *
 * Clients can register for callbacks only for EVENTS (declared in interfaces.ts). AddListener method of GridApp provided by EventEmitter
 * is used to register for these events and client can do UI updates accordingly.
 * Client needs InitParams and sessionStartParams object with correct data before a session is initiated.
 * call setAuthInfo to set auth method used to communicate with server (default is Jarvis).
 * call startSession only after successfull initialization of grid app using initialize method
 * call stopSession to stop streaming.
 * call getActiveSessions if SESSION_START_RESULT fails with RErrorCode.SessionLimitExceeded to get list of sessions
 * and then client can stop those sessions using stopSession method to start a new session later on.
 *
 * Sample Usage:
 * const gridApp = new GridApp(platformDetails);
 * gridApp.initialize({
 *     serverAddress: "server-address.nvidiagrid.net",
 *     authTokenCallback: <function callback>,
 *     deviceHashId: "1234567890",
 * },
 * {
 *     cursorType: CursorType.SOFTWARE,
 *     allowUnconfined: false
 * });
 * gridApp.setAuthInfo({ AuthType.JARVIS, "some token" });
 *
 * gridApp.addListener(EVENTS.SESSION_START_RESULT, (eventData: StartSessionResultEvent) => {});
 * gridApp.addListener(EVENTS.SESSION_STOP_RESULT, (eventData: StopSessionResultEvent) => {});
 * gridApp.addListener(EVENTS.ACTIVE_SESSIONS_RESULT, (eventData: ActiveSessionResultEvent) => {});
 * gridApp.addListener(EVENTS.PROGRESS_UPDATE, (eventData: SessionProgressUpdateEvent) => {});
 * gridApp.addListener(EVENTS.STREAM_STOPPED, (eventData: StreamingTerminatedEvent) => {});
 * gridApp.addListener(EVENTS.STREAMING_EVENT, (eventData: StreamingEvent) => {});
 * gridApp.addListener(EVENTS.GET_SESSION_RESULT, (eventData: GetSessionResult) => {});
 *
 * gridApp.startSession({
 *     appId: 100000000,
 *     keyboardLayout: "en-US",
 *     shortName: "Fortnite (Epic)",
 *     streamParams: [...]
 * }); // Start a session
 * gridApp.getSession(some_session_id); // Retrieve the session by using the given session id, session detail will be emitted through the GET_SESSION_RESULT event
 * gridApp.stopSession(some_session_id); // Stop a session
 */
declare interface IGridApp extends IEventEmitter {
    /**
     * Initializes the GridApp.
     * Returns true if successfully initialized.
     * @param initializeParams - interface: InitParams
     * @param inputConfigFlags - interface: InputConfigFlags [default: { allowUnconfined: false, preventNavigation: false }]
     **/
    initialize(initializeParams: InitParams, inputConfigFlags: InputConfigFlags): boolean;

    /**
     * This API is used by client to keep ragnarok informed about all the latest params need to be passed in telemetry event.
     * To get user and session id updated client should at least call it just before every call to startSession and resume.
     * @param {EventDataElements} eventDataElements
     * @memberof GridApp
     */
    updateEventDataElements(eventDataElements: EventDataElements): void;

    /**
     * Gets the current active sessions for the user.
     * This is an asynchronous API, upon completion ACTIVE_SESSIONS_RESULT event is emitted.
     * @param tracingCarrier - carrier representing parent tracing context of getActiveSessions operation. Adheres to OpenTracing standard.
     **/
    getActiveSessions(tracingCarrier?: TracingCarrier): void;

    /**
     * Starts a session.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * If there result doesn't contain error then client should display the video tag as a successful result indicates
     * streaming has begun.
     * @param sessionStartParams - check SessionParams interface for details
     * @param tracingCarrier - carrier representing parent tracing context of startSession operation. Adheres to OpenTracing standard.
     **/
    startSession(sessionStartParams: SessionParams, tracingCarrier?: TracingCarrier): void;

    /**
     * getSession request.
     * This is an asynchronous API and the result is delivered in GET_SESSION_RESULT event.
     * This API is meant for resume scenario only. Since getActiveSessions is expensive as it includes a jarvis call.
     * So client needs to make getActiveSessions call once and subsequent calls can be getSession to get the state.
     * @param sessionId - sessionId to be resumed.
     * @param tracingCarrier - carrier representing parent tracing context of getSession operation. Adheres to OpenTracing standard.
     **/
    getSession(sessionId: string, tracingCarrier?: TracingCarrier): void;

    /**
     * resume request.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * This call is recommended instead of resume() and covers all resume cases.
     * * @param sessionResumeParams - check SessionParams for details
     * * @param sessionId of resumable session
     * * @param tracingCarrier - carrier representing parent tracing context of resumeSession operation. Adheres to OpenTracing standard.
     * Here sre steps client go through:
     * 1. Client make a startSessionRequest
     * 2. Session is streaming.
     * 3. Client gets STREAM_STOPPED event with error group (((eventData.error.code ^ RErrorCode.StreamerErrorCategory)>>8) == 0)
     *    or tab is closed or browser crashed or session launched from different device with same account and same appid.
     * 4. Client calls getactivesession() and if the appid is same and error is (RErrorCode.SessionLimitExceeded || RErrorCode.SessionLimitPerDeviceReached)
     * 5. Client Calls multiple getSession(sessionid) untill status becomes SessionState.READY_FOR_CONNECTION
     * 6. Client calls resumeSession()
     **/
    resumeSession(
        sessionResumeParams: SessionParams,
        sessionId: string,
        tracingCarrier?: TracingCarrier
    ): void;

    /**
     * Stops a session.
     * This is an asynchronous API and the result is delivered in SESSION_STOP_RESULT event.
     * If the session to be stopped is streaming in current client then streaming is aborted and session is terminated on the server.
     * If not streaming then session is terminated on the server.
     * @param sessionId - session to be stopped.
     * @param exitCode - reason for stopping the session
     * @param tracingCarrier - carrier representing parent tracing context of stopSession operation. Adheres to OpenTracing standard.
     **/
    stopSession(sessionId?: string, exitCode?: number, tracingCarrier?: TracingCarrier): void;

    /**
     * Pauses a session.
     * This is an asynchronous API and the result is delivered in SESSION_STOP_RESULT event.
     * If the session to be stopped is streaming in current client then streaming is aborted and session is not terminated on the server.
     * If not streaming then session is terminated on the server.
     * @param sessionId - session to be paused.
     * @param tracingCarrier - carrier representing parent tracing context of pauseSession operation. Adheres to OpenTracing standard.
     **/
    pauseSession(sessionId?: string, tracingCarrier?: TracingCarrier): void;

    /**
     * DEPRECATED - should use resumeSession instead.
     * resume request.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * This call has a limitation that it is meant for auto resume only as client wants to stick with this due to same it's making in geronimo.
     * We can't resume once the tab is closed or from another device.
     * @param tracingCarrier - carrier representing parent tracing context of resume operation. Adheres to OpenTracing standard.
     * Here sre steps client go through:
     * 1. Client make a startSessionRequest
     * 2. Session is streaming.
     * 3. Client gets STREAM_STOPPED event with error group (((eventData.error.code ^ RErrorCode.StreamerErrorCategory)>>8) == 0)
     * 4. Client Calls multiple getSession(sessionid) untill status becomes SessionState.READY_FOR_CONNECTION
     * 5. Client calls resume()
     **/
    resume(tracingCarrier?: TracingCarrier): void;

    /**
     * Find whether mic is supported or not
     * This is definitive (either site is http (https/Chrome flags bypass is required),
     * or browser does not support capture)
     **/
    isMicSupported(): boolean;

    /**
     * Enable/disable mic recording
     * This will enable/disable recording of microphone (mic stream is already established
     * if supported when we initialize), can cause perf issues if enabled
     * This is an asynchronous API, upon completion MIC_STATE event is emitted.
     * This method should be called after a successful session start
     **/
    setMicRecordingEnabled(enabled: boolean): void;

    /**
     * Get current status of mic recording
     * This method will emit a MicStateEvent with result of operation
     **/
    getMicState(): void;

    /**
     * Find whether mic should be enabled by default
     * This recommendation can be made based on various criteria
     * e.g. platform permissions, performance, limited support etc.
     * This is not the same as isMicSupported and cannot be used as a replacement
     **/
    shouldDefaultEnableMic(): boolean;

    /**
     * This API will enable/disable input handlings for given input types in fullscreen and windowed mode.
     * When inputs are enabled/disabled, event listeners are registerd/de-registered with certain DOM elements.
     * This could be useful when showing overlay buttton under fullsceen mode.
     * @param enable - enable/disable
     * @param inputs - input types that need to be enabled/disabled [default: InputType.All]
     **/
    toggleUserInput(enable: boolean, inputs?: InputType): void;

    /**
     * Send custom messages to the streaming server
     * NOTE: do nothing if stream is not started.
     * @param message - messages need to be sent to the server
     **/
    sendCustomMessage(message: CustomMessage): void;

    /**
     * Get the reference of VirtualGamepadHandler. Reference can be undefined if inputchannel is failed to go in open state.
     * Clients should refer virtualgamepad.ts API details.
     **/
    getVirtualGamepadHandler(): VirtualGamepadHandler | undefined;

    /**
     * Set the client's authorization information.
     * initialize must be called before using this API.
     * Should be called before the first use of APIs that require authentication with the server:
     *   - startSession
     *   - getActiveSessions
     *   - getSession
     *   - resumeSession
     *   - stopSession
     *   - pauseSession
     *   - resume
     * If not called, Jarvis authentication will be assumed.
     * In the event of authentication related errors, can be called again to update the existing authentication information.
     * @param authInfo - object
     *               { type: enum<auth method for server communication>
     *                 token: string<token to authenticate server communication>}
     **/
    setAuthInfo(authInfo: AuthInfo): void;

    /**
     * Send plain text to server.
     * NOTE: do nothing if stream is not started.
     * @param text - ArrayBuffer containing text in utf-8 format
     */
    sendTextInput(text: ArrayBuffer): void;

    /**
     * Send virtual keyboard state to server.
     * NOTE: do nothing if stream is not started.
     * @param visible - Boolean describing the visual state of the virtual keyboard
     */
    setVirtualKeyboardState(visible: boolean): void;

    /**
     * Transform video element locally.
     * @param offsetX - X coordinate offset
     * @param offsetY - Y coordinate offset
     * @param zoomFactor - zoom factor to determine translation limits
     */
    setVideoTransforms(offsetX: number, offsetY: number, zoomFactor: number): void;

    /**
     * Toggle the visibility of on screen stats.
     * Other shortcuts to bring up the stats (key combination and gestures) can still be used to toggle the visibility of the stats.
     * NOTE: do nothing if stream is not started.
     **/
    toggleOnScreenStats(): void;

    /**
     * Sets the keyboard layout on the server during a session
     * NOTE: do nothing if stream is not started.
     * @param layout - keyboard layout string (e.g. "en-US")
     */
    setKeyboardLayout(layout: string): void;

    /**
     * Download the audio recording.
     * NOTE: the audio recording must exist. If not exist, no file will be downloaded.
     *       To generate the audio, ctrl + alt + d is needed to trigger PCM dump under dev mode.
     */
    downloadAudio(): void;

    /**
     * Toggle Right-Stick Dynamic Mouse Mode on/off.
     * Default state is off, and must be explicitly enabled.
     * Note: for xbox left stick will be used for rsdmm feature with same API
     * @param enable - true to enable, false to disable.
     */
    toggleRsdmm(enable: boolean): void;

    /**
     * Sends keyboard event to streamer.
     * NOTE: do nothing if stream is not started.
     * @param event - keyboard event
     */
    sendKeyEvent(event: KeyboardEvent): void;

    /**
     * Sets the max bitrate while streaming.
     * NOTE: do nothing if stream is not started.
     * @param kbps - bitrate in kbps
     * @param streamIdx - stream index
     */
    setStreamingMaxBitrate(kbps: number, streamIdx?: number): void;

    /**
     * Adjust for poor network condition while streaming.
     * NOTE: do nothing if stream is not started.
     * @param enabled - true for enable; false for disable
     * @param streamIdx - stream index
     */
    setDrcDfcState(enabled: boolean, streamIdx?: number): void;
}

export class GridApp extends LogQueueingEventEmitter implements IGridApp, IStreamCallbacks {
    private currentSession?: GridSession;
    private streamClient?: StreamClient;
    private gridServer?: GridServer;
    private initializeParams: InitParams;
    private sessionStartParams?: ValidatedSessionParams;
    private startTime: number;
    private unloadFunc: any;
    private onlineFunc: any;
    private visibilitychangeFunc: any;
    private unhandledExceptionFunc: any;
    private framesDecoded: number;
    private telemetry: TelemetryHandler;
    private isResume: boolean;
    private inputConfigFlags: InputConfigFlags;
    private micCapturer: MicCapturer;
    private audioRecorder: AudioRecorder;
    private inputEnabled: boolean = false;
    private inputs?: InputType;
    private micEnabled: boolean = false;
    private sleepDetector: SleepDetector;
    private gamepadTester: GamepadTester = new GamepadTester();
    private gamepadHandler: GamepadHandler;
    private sessionSetUpInProgress: boolean = false;
    private deviceChangeFunc: any = undefined;
    private resetAudioFunc: any = undefined;
    private pageShutDownCleanupDuringSessionDone: boolean = false;
    private exitErrorCodeForUnload?: number;
    private exitEventCacheTimeoutId: number = 0;
    private telemetryEventProcessor: TelemetryEventProcessor;
    private systemIdleFunc: any;
    private systemIdleId: number = 0;
    private visibilityHiddenTime: number = 0;
    private deviceChangeCount: number = 0;
    private isSessionOngoing: boolean = false;
    constructor(private platformDetails: PlatformDetails) {
        super(EVENTS.LOG_EVENT);
        Log.addListener(UTIL_EVENTS.LOG_EVENT, this.onLogEvent.bind(this));
        this.initializeParams = {
            clientIdentification: CLIENT_IDENTIFICATION,
            clientVersion: CLIENT_VERSION,
            deviceHashId: "",
            serverAddress: ""
        };
        this.startTime = performance.now();
        this.unloadFunc = this.unload.bind(this);
        this.onlineFunc = this.online.bind(this);
        this.visibilitychangeFunc = this.visibilitychange.bind(this);
        this.unhandledExceptionFunc = this.unhandledException.bind(this);
        this.framesDecoded = 0;
        this.telemetryEventProcessor = new TelemetryEventProcessor();
        this.telemetry = new TelemetryHandler(this, this.telemetryEventProcessor);
        this.sleepDetector = new SleepDetector(this.telemetry);
        this.isResume = false;
        this.inputConfigFlags = defaultInputConfigFlags;
        this.audioRecorder = new AudioRecorder();

        window.addEventListener("error", this.unhandledExceptionFunc);

        const zoneless = (window as any).zoneless as Zoneless;
        this.micCapturer = new MicCapturer(this.platformDetails, this.telemetry);
        this.resetAudioFunc = this.micCapturer.resetAudio.bind(this.micCapturer);
        this.deviceChangeFunc = this.deviceChange.bind(this);
        this.gamepadHandler = new GamepadHandler(this.platformDetails, zoneless);
        if (RagnarokSettings.gamepadTesterEnabled) {
            this.gamepadHandler.addGamepadDataHandler(this.gamepadTester);
            this.gamepadHandler.enableUserInput();
        }

        this.systemIdleFunc = this.handleSystemIdle.bind(this);

        // Play out any cached Platform telemetry
        AddPlatformTelemetry(this.telemetry);
        // Send telemetry that won't change between sessions
        this.collectPerformanceTimingTelemetry();
        this.collectMediaCapabilitiesTelemetry();
        if (RagnarokSettings.ragnarokConfig.sendNonEssentialMetricEvents) {
            this.telemetry.emitMetricEvent(
                "PlatformDetailsExecutionTime",
                "",
                this.platformDetails.totalTime,
                0,
                0,
                0
            );
        }
        // Register with network callbacks
        NetworkDetector.registerNetworkCallback((network: NetworkTypeEnum) => {
            this.gridServer?.setNetworkType(network);
        });
    }

    private resetSessionIds() {
        if (this.gridServer) {
            const sessionId = this.gridServer.getSessionId();
            const subSessionId = this.gridServer.getSubSessionId();
            Log.d("{9d1820c}", "{2fd42df}", sessionId, subSessionId);
            this.telemetry.setSubSessionId(subSessionId);
            this.sleepDetector.setSubSessionId(subSessionId);
            this.telemetry.setSessionId(sessionId);
            this.sleepDetector.setSessionId(sessionId);
            this.telemetryEventProcessor.resetDataOnNewSubSession(sessionId, subSessionId);
        }
    }

    private unhandledException(e: ErrorEvent): boolean {
        Log.e("{9d1820c}", "{8516485}", e.filename, e.lineno, e.colno);
        this.telemetry.emitExceptionEvent(e.error,
            e.error && e.error.message ? e.error.message : e.message,
            e.filename,
            e.lineno,
            e.colno,
            false);

        if (RagnarokSettings.ragnarokConfig.terminateUnhandledException ?? true) {
            this.terminateOrAbortStreaming(RErrorCode.UnhandledException);
        }

        return false; //false allows error to propogate
    }

    private sendExitAnalyticsEvent(exitAnalyticsEventData: StreamExitEventData) {
        this.clearExitEventCacheTimeout();
        // Clear the exitEvent cache once the telemetry is sent. When the GridServer session setup
        // fails, we end up not have a streaming session, but we do send the telemetry. We were not
        // clearing the index db in this case and the same set of events were being sent again in
        // the next launch.
        this.telemetry.sendExitAnalyticsEvent(exitAnalyticsEventData, true);
    }

    private getRagnarokStreamExitEventData(
        data: StreamingTerminatedEvent | StartSessionResultEvent,
        streamDuration: number,
        sessionSetupFailed: boolean,
        connectivity?: string,
        sleep?: boolean
    ): StreamExitEventData {
        return {
            exitErrorCode: data.error
                ? GetHexString(data.error.code)
                : GetHexString(RErrorCode.Success),
            sessionId: data.sessionId,
            subSessionId: data.subSessionId,
            zoneAddress: data.zoneAddress,
            streamDuration: streamDuration,
            frameCount: this.framesDecoded,
            codec: this.streamClient?.getVideoCodec() ?? "UNKNOWN",
            isResume: this.isResume,
            connectivity: connectivity ?? Connectivity.UNDEFINED,
            sleep: sleep ?? false,
            networkTestSessionId: this.sessionStartParams?.sessionParams.networkSessionId ?? "",
            sessionSetupFailed: sessionSetupFailed
        };
    }

    private pageShutDownCleanupDuringSession() {
        if (this.pageShutDownCleanupDuringSessionDone) {
            return;
        }
        this.pageShutDownCleanupDuringSessionDone = true;

        this.terminateOrAbortStreaming(
            this.exitErrorCodeForUnload ?? this.streamClient?.isStartNotified()
                ? RErrorCode.WebPageClosed
                : RErrorCode.SessionSetupCancelled
        );
    }

    private unload(evt: any) {
        this.pageShutDownCleanupDuringSession();
    }

    private online(evt: any) {
        Log.i("{9d1820c}", "{a9a135f}");
        this.telemetryEventProcessor.sendAllCachedTelemetryEvents();
    }

    private visibilitychange(evt: any) {
        Log.i("{9d1820c}", "{478a408}", document.visibilityState, !!this.streamClient);
        if (!this.streamClient) {
            return;
        }
        const videoElement = this.sessionStartParams!.validatedStreamParams[0].videoElement;
        const audioElement = this.sessionStartParams!.validatedStreamParams[0].audioElement;
        // WAR for audio playing when device locked
        const shouldApplyMuteWAR =
            IsiOSVersionAtLeast(this.platformDetails, 15, 4) && RagnarokSettings.allowMutingVideo;
        // WAR for mic disabled when PWA app minimized.  Do not apply when user terminated mic recorder.
        const shouldEnableMicWAR =
            IsiOSVersionAtLeast(this.platformDetails, 15, 4) &&
            this.micEnabled &&
            RagnarokSettings.allowEnableMic;
        if (document.visibilityState === "hidden") {
            this.visibilityHiddenTime = performance.now();
            if (shouldApplyMuteWAR) {
                Log.i("{9d1820c}", "{cbd3b6e}");
                videoElement.muted = true;
                audioElement.muted = true;
            }
            this.cacheExitEventInDbPeriodically();
        } else if (document.visibilityState === "visible") {
            if (shouldApplyMuteWAR) {
                Log.i("{9d1820c}", "{05deefa}");
                videoElement.muted = false;
                audioElement.muted = false;
            }
            if (shouldEnableMicWAR) {
                // User terminated mic recorder if time between onended and visibility events above given threshold in milliseconds
                const USER_TERMINATED_MIC_RECORDER_THRESHOLD =
                    RagnarokSettings.ragnarokConfig.userTerminatedMicRecorderThreshold ?? 2000;
                if (
                    this.visibilityHiddenTime - this.micCapturer.getTrackEndedTime() <
                    USER_TERMINATED_MIC_RECORDER_THRESHOLD
                ) {
                    Log.i("{9d1820c}", "{862bd20}");
                    this.micCapturer.setWillAudioVolumeBeLow(true);
                    this.setMicRecordingEnabled(true);
                } else {
                    this.micEnabled = false;
                }
            } else if (
                IsiOSVersionAtLeast(this.platformDetails, 15, 4) &&
                !this.micCapturer.isAudioVolumeLow() &&
                this.micCapturer.isUsingBuiltInMic() &&
                this.micCapturer.didUserDisableMic()
            ) {
                this.micCapturer.recordLowAudioDebugEvent(LowAudioVolumeType.VISIBILITY_CHANGE);
            }
        }
    }

    private onGsTelemetryHttpEvent(eventData: TelemetryHttpEvent) {
        this.telemetry.emitHttpEvent(eventData, true);
    }

    private onGsTelemetryDebugEvent(data: TelemetryDebugEvent) {
        this.telemetry.emitDebugEvent(
            data.key1,
            data.key2,
            data.key3,
            data.key4,
            data.key5,
            data.sessionId,
            data.subSessionId,
            true
        );
    }

    private onGsTelemetryEvent(eventPayload: TelemetryEventPayload) {
        Log.d("{9d1820c}", "{972bb72}", JSON.stringify(eventPayload));
        // TODO: We will have to add index-db caching support for GridServer_GameLaunch_Event
        // since we are separating the PM yield and Streaming yield.
        this.telemetry.dispatchEvent(eventPayload);
    }

    private onGsActiveSessionsResult(eventData: ActiveSessionResultEvent) {
        this.emit(EVENTS.ACTIVE_SESSIONS_RESULT, eventData);
    }

    private onGsProgressUpdate(eventData: SessionProgressUpdateEvent) {
        this.emit(EVENTS.PROGRESS_UPDATE, eventData);
    }

    private initializeGridServer(gridServer: GridServer, tracingInfo?: TracingInfo) {
        Log.d("{9d1820c}", "{3c652b7}");
        // Reset the previous run's sessionId as these stale entries are being used in telemetry events sent before the new sessionId gets filled.
        this.resetSessionIds();

        // Register for events based on the GFNUI schema
        if (RagnarokSettings.useUITelemetry) {
            gridServer.addListener(
                GS_EVENTS.TELEMETRY_HTTP_EVENT,
                this.onGsTelemetryHttpEvent.bind(this)
            );
            gridServer.addListener(
                GS_EVENTS.TELEMETRY_DEBUG_EVENT,
                this.onGsTelemetryDebugEvent.bind(this)
            );
        }
        // Register for events based on the new split schema
        if (RagnarokSettings.useSplitSchema) {
            gridServer.addListener(GS_EVENTS.TELEMETRY_EVENT, this.onGsTelemetryEvent.bind(this));
        }

        gridServer.addListener(GS_EVENTS.LOG_EVENT, this.onLogEvent.bind(this)); //this must be set before intialize
        gridServer.addListener(
            GS_EVENTS.ACTIVE_SESSIONS_RESULT,
            this.onGsActiveSessionsResult.bind(this)
        );
        gridServer.addListener(GS_EVENTS.PROGRESS_UPDATE, this.onGsProgressUpdate.bind(this));
        this.initializeParams.clientHeaders =
            this.initializeParams.clientHeaders ?? new Map<string, string>();

        const clientType =
            RagnarokSettings.clientType || this.initializeParams.clientType || ClientType.BROWSER;
        const shouldSetBrowser = clientType !== ClientType.NATIVE;

        if (shouldSetBrowser) {
            this.initializeParams.clientHeaders.set(
                "nv-browser-version",
                this.platformDetails.browserFullVer
            );
        }

        const gsInitParams: GsInitParams = {
            deviceOs: RagnarokSettings.deviceOs ?? this.platformDetails.deviceOS,
            deviceOsVer: this.platformDetails.osVer,
            deviceType: RagnarokSettings.deviceType ?? this.platformDetails.deviceType,
            deviceModel: RagnarokSettings.deviceModel ?? this.platformDetails.deviceModel,
            clientIdentification:
                this.initializeParams.clientIdentification ?? CLIENT_IDENTIFICATION,
            clientVersion: this.initializeParams.clientVersion ?? CLIENT_VERSION,
            clientAppVersion: this.initializeParams.clientAppVersion,
            clientStreamer: RagnarokSettings.clientStreamer ?? ClientStreamer.WEBRTC,
            clientId: RagnarokSettings.clientId || this.initializeParams.clientId,
            browserType: shouldSetBrowser ? this.platformDetails.browser.toUpperCase() : undefined,
            clientPlatformName: RagnarokSettings.clientPlatformName || "browser",
            clientType: clientType,
            deviceHashId: this.initializeParams.deviceHashId,
            serverAddress: this.initializeParams.serverAddress,
            authTokenCallback: this.initializeParams.authTokenCallback,
            tracingInfo: tracingInfo,
            clientHeaders: this.initializeParams.clientHeaders
        };
        gridServer.initialize(gsInitParams);
        // Update telemetry network status
        gridServer.setNetworkType(NetworkDetector.getNetworkType());
    }

    /**
     * Initializes the GridApp.
     * Returns true if successfully initialized.
     * @param initializeParams - interface: InitParams
     * @param inputConfigFlags - interface: InputConfigFlags [default: { allowUnconfined: false, preventNavigation: false }]
     **/
    public initialize(
        initializeParams: InitParams,
        inputConfigFlags: InputConfigFlags = defaultInputConfigFlags
    ): boolean {
        Log.i("{9d1820c}", "{2569364}", PackageVersion);
        Log.d("{9d1820c}", "{e85ad66}", navigator.userAgent);
        if (!initializeParams) {
            Log.e("{9d1820c}", "{a98b177}");
            return false;
        }

        Object.assign(this.initializeParams, initializeParams);

        this.gridServer?.uninitialize();
        if (
            !this.initializeParams.serverAddress ||
            IsValidIPv4(this.initializeParams.serverAddress)
        ) {
            Log.d("{9d1820c}", "{e2d9a04}");
            this.gridServer = new PassThruServer();
        } else if (this.initializeParams.serverAddress.startsWith("mockpm://")) {
            Log.d("{9d1820c}", "{4a79d8e}");
            this.gridServer = new MockPMGridServer();
            this.initializeParams.serverAddress = this.initializeParams.serverAddress.replace(
                "mockpm://",
                ""
            );
        } else {
            if (!this.initializeParams.authTokenCallback) {
                Log.e("{9d1820c}", "{28d1400}");
                return false;
            }
            let options: GridServerCallbacks = {
                remoteBitmapCallback: this.gamepadHandler.getBitmap.bind(this.gamepadHandler)
            };

            // Configure GridServer to emit events synchronously, since gridApp is configured to
            // relay these events asynchronously to upper layers.
            this.gridServer = new GridServer(this.platformDetails, true, options);
        }
        const tracingInfo = enableTracing(this.initializeParams.createTracer);
        this.initializeGridServer(this.gridServer, tracingInfo);

        this.inputConfigFlags = inputConfigFlags;
        this.telemetry.setClientShutDownCallback(this.initializeParams.clientShutDownCallback);

        return true;
    }

    public downloadAudio() {
        this.audioRecorder.downloadAudio();
    }

    /**
     * This API is used by client to keep ragnarok informed about all the latest params need to be passed in telemetry event.
     * To get user and session id updated client should at least call it just before every call to startSession and resume.
     * @param {EventDataElements} eventDataElements
     * @memberof GridApp
     */
    public updateEventDataElements(eventDataElements: EventDataElements) {
        Log.i("{9d1820c}", "{4008dc4}", JSON.stringify(eventDataElements)); //@todo remove string print later
        this.telemetryEventProcessor.updateEventDataElements(eventDataElements);
        if (eventDataElements.telemetryEventIds) {
            this.gridServer?.updateTelemetryEventIds(eventDataElements.telemetryEventIds);
        }
        if (this.streamClient && !this.sessionSetUpInProgress) {
            // we only want to write when session is streaming to prevent db from getting overritten of previous session
            this.cacheExitEventInDbPeriodically();
        }
    }

    /**
     * Gets the current active sessions for the user.
     * This is an asynchronous API, upon completion ACTIVE_SESSIONS_RESULT event is emitted.
     * @param tracingCarrier - carrier representing parent tracing context of getActiveSessions operation. Adheres to OpenTracing standard.
     **/
    public getActiveSessions(tracingCarrier?: TracingCarrier) {
        if (!this.gridServer) {
            const result: ActiveSessionResultEvent = {
                sessionList: [],
                error: this.getGridAppUninitializedError("ActiveSessions")
            };
            this.emit(EVENTS.ACTIVE_SESSIONS_RESULT, result);
            return;
        }
        this.gridServer.getAllActiveSessions(tracingCarrier);
    }

    private onSessionStartException(exp: any) {
        let result: StartSessionResultEvent = {
            sessionId: this.gridServer?.getSessionId() ?? "",
            subSessionId: this.gridServer?.getSubSessionId() ?? "",
            error: {
                code: RErrorCode.ExceptionHappened,
                description: "Quitting due to exception"
            },
            streamInfo: this.currentSession?.streamInfo,
            zoneName: this.gridServer?.getZoneName() ?? "",
            zoneAddress: this.gridServer?.getZoneAddress() ?? "",
            gpuType: this.gridServer?.getGpuType() ?? "",
            isResume: this.isResume
        };
        this.onSessionStart(result, true);

        let msg = "Exception happened in session call";
        Log.e("{9d1820c}", "{90f98fb}", exp);
        this.telemetry.emitExceptionEvent(exp, msg, "{9d1820c}.ts", 0, 0, true);
    }

    private onSessionStartError(error: ErrorDetails, sessionId: string, subSessionId: string) {
        let result: StartSessionResultEvent = {
            sessionId: sessionId,
            subSessionId: subSessionId,
            error: error,
            zoneName: "",
            zoneAddress: "",
            gpuType: "",
            isResume: this.isResume
        };
        this.onSessionStart(result, true);
    }

    private runSession(
        action: number,
        sessionStartParams: ValidatedSessionParams,
        tracingCarrier?: TracingCarrier,
        sessionId?: string
    ) {
        this.currentSession = undefined;
        if (!this.gridServer) {
            const result: StartSessionResultEvent = {
                sessionId: "",
                subSessionId: "",
                zoneName: "",
                zoneAddress: "",
                gpuType: "",
                isResume: this.isResume,
                error: this.getGridAppUninitializedError(this.isResume ? "Resume" : "Start")
            };
            this.emit(EVENTS.SESSION_START_RESULT, result);
            return;
        }

        try {
            this.telemetry.setGameDetails(
                sessionStartParams.sessionParams.appId,
                sessionStartParams.sessionParams.shortName ?? ""
            );
            this.startTime = performance.now();
            this.sleepDetector.startSleepDetectionTimer();
            Log.d("{9d1820c}", "{892168d}", Log.sanitize(JSON.stringify(sessionStartParams.sessionParams)));
            const gridServer = this.gridServer;
            gridServer
                .putOrPostSession(
                    sessionStartParams.sessionParams,
                    action,
                    sessionId,
                    tracingCarrier
                )
                .then((session?: GridSession) => {
                    // session will be empty if
                    //  no GsInitParams were set (early exit path)
                    // session will be undefined if:
                    //  the session request had no response data
                    //  the session request's response data had no "session" property
                    // TODO: Handle these error cases
                    //       (Causing "TypeError: Cannot read property 'sessionId' of undefined" exceptions)
                    this.resetSessionIds();
                    Log.d("{9d1820c}", "{92a2573}", session?.sessionId, session?.subSessionId);
                    if (session?.state === SessionState.READY_FOR_CONNECTION) {
                        Log.d("{9d1820c}", "{2042e0a}");
                        this.currentSession = session;
                        const pr: Promise<GridSession | undefined> = new Promise(
                            (resolve, reject) => {
                                resolve(session);
                            }
                        ); // just fake a poll success.
                        return pr;
                    } else {
                        return gridServer.getSession(session!.sessionId, true, tracingCarrier);
                    }
                })
                .then((session?: GridSession) => {
                    this.currentSession = session; //we have updated ports now.
                    Log.d("{9d1820c}", "{315afdc}", session?.sessionId);
                    this.startStreaming(sessionStartParams);
                })
                .catch(error => {
                    this.resetSessionIds();
                    if (error.code) {
                        if (
                            error.code == RErrorCode.SessionSetupCancelled ||
                            error.code == RErrorCode.SessionSetupCancelledDuringQueuing
                        ) {
                            if (gridServer.getSessionId()) {
                                this.sendDeleteRequest(
                                    gridServer.getSessionId(),
                                    gridServer,
                                    tracingCarrier
                                );
                            }
                        }

                        Log.e("{9d1820c}", "{09efe49}", GetHexString(error.code));
                        let result: StartSessionResultEvent = {
                            sessionId: gridServer.getSessionId() ?? "",
                            subSessionId: gridServer.getSubSessionId() ?? "",
                            error: error,
                            sessionList: error.sessionList,
                            streamInfo: this.currentSession?.streamInfo,
                            zoneName: gridServer.getZoneName() ?? "",
                            zoneAddress: gridServer.getZoneAddress() ?? "",
                            gpuType: gridServer.getGpuType() ?? "",
                            isResume: this.isResume
                        };
                        this.onSessionStart(result, true);
                    } else {
                        //it's an exception
                        this.onSessionStartException(error);
                    }
                });
        } catch (exp) {
            this.onSessionStartException(exp);
        }
    }

    private sendSessionSetupInProgressTelemetry(isResume: boolean) {
        // collecting this to see how rare/huge the problem is
        this.telemetry.emitDebugEvent("SessionSetupInProgressError", isResume ? "resume" : "start");
    }

    private updateExitEventCacheWithNewParams(exitErrorCode: string) {
        this.telemetryEventProcessor.updateCachedExitEvent(
            exitErrorCode,
            this.gridServer?.getSessionId() ?? "",
            this.gridServer?.getSubSessionId() ?? "",
            this.gridServer?.getZoneAddress() || this.initializeParams.serverAddress,
            performance.now() - this.startTime,
            this.streamClient?.getFramesDecoded() ?? 0,
            this.streamClient?.getVideoCodec() ?? "UNKNOWN",
            this.isResume
        );
    }

    private clearExitEventCacheTimeout() {
        if (this.exitEventCacheTimeoutId !== 0) {
            window.clearTimeout(this.exitEventCacheTimeoutId);
            this.exitEventCacheTimeoutId = 0;
        }
    }

    private cacheExitEventInDbPeriodically() {
        this.updateExitEventCacheWithNewParams(
            GetHexString(this.exitErrorCodeForUnload ?? RErrorCode.DelayedSessionError)
        );
        const currentTimeoutId = this.exitEventCacheTimeoutId;
        this.telemetryEventProcessor
            .cacheExitEventInDb()
            .then(() => {
                if (currentTimeoutId === this.exitEventCacheTimeoutId) {
                    this.setPeriodicExitEventCacheTimeout();
                }
            })
            .catch(() => {
                Log.e("{9d1820c}", "{204a7ac}");
            });
    }

    private setPeriodicExitEventCacheTimeout() {
        this.clearExitEventCacheTimeout();
        this.exitEventCacheTimeoutId = window.setTimeout(
            () => this.cacheExitEventInDbPeriodically(),
            2 * 60 * 1000 // 2 mins
        );
    }

    private registerWindowAndDocumentEvents() {
        // Using the `unload` event to determine terminate in page lifecycle it's unreliable, especially for iOS Safari.
        // Using `pagehide` event rather than `unload`, it will only be fired while closing tabs and refreshing pages.
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
        window.addEventListener("pagehide", this.unloadFunc);

        window.addEventListener("online", this.onlineFunc);
        document.addEventListener("visibilitychange", this.visibilitychangeFunc);
        if (navigator.mediaDevices) {
            navigator.mediaDevices.addEventListener("devicechange", this.deviceChangeFunc);
        }
    }

    private collectPerformanceTimingTelemetry() {
        if (RagnarokSettings.ragnarokConfig.sendNonEssentialMetricEvents) {
            const metricName = "PerformanceTiming";

            const jsInitEntry = performance.getEntriesByName("GfnJsInitStart")[0];
            const platformBeginEntry = performance.getEntriesByName("platformBegin")[0];
            const ragnarokBeginEntry = performance.getEntriesByName("GfnRBegin")[0];
            const navigationEntries = performance.getEntriesByType("navigation");

            const jsInitTime = Math.round(jsInitEntry?.startTime ?? -1);
            const platformBeginTime = Math.round(platformBeginEntry?.startTime ?? -1);

            this.telemetry.emitMetricEvent(
                metricName,
                "application timings",
                jsInitTime,
                platformBeginTime,
                Math.round(ragnarokBeginEntry?.startTime ?? -1),
                platformBeginTime - jsInitTime
            );

            if (!navigationEntries.length) {
                this.telemetry.emitMetricEvent(
                    metricName,
                    "navigation timings unsupported",
                    0,
                    0,
                    0,
                    0
                );
                return;
            }

            const navigationEntry = navigationEntries[
                navigationEntries.length - 1
            ] as PerformanceNavigationTiming;

            this.telemetry.emitMetricEvent(
                metricName,
                "load timings",
                Math.round(navigationEntry.loadEventStart),
                Math.round(navigationEntry.loadEventEnd),
                Math.round(navigationEntry.domContentLoadedEventStart),
                Math.round(navigationEntry.domContentLoadedEventEnd)
            );
        }
    }

    private unregisterWindowAndDocumentEvents() {
        window.removeEventListener("pagehide", this.unloadFunc);
        window.removeEventListener("online", this.onlineFunc);
        document.removeEventListener("visibilitychange", this.visibilitychangeFunc);
        if (navigator.mediaDevices) {
            navigator.mediaDevices.removeEventListener("devicechange", this.deviceChangeFunc);
        }
        if (IsWebOS(this.platformDetails)) {
            document.removeEventListener("SystemIdle", this.systemIdleFunc);
            this.clearSystemIdleTimeout();
        }
    }

    private prepareSession(isResume: boolean) {
        this.exitErrorCodeForUnload = undefined;
        this.telemetryEventProcessor.sendAllCachedTelemetryEvents();
        this.isSessionOngoing = true;
        this.registerWindowAndDocumentEvents();
        this.isResume = isResume;
        if (this.sessionSetUpInProgress) {
            this.sendSessionSetupInProgressTelemetry(this.isResume);
        }
        this.sessionSetUpInProgress = true;
    }

    /**
     * Starts a session.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * If there result doesn't contain error then client should display the video tag as a successful result indicates
     * streaming has begun.
     * @param sessionStartParams - check SessionParams interface for details
     * @param tracingCarrier - carrier representing parent tracing context of startSession operation. Adheres to OpenTracing standard.
     **/
    public startSession(sessionStartParams: SessionParams, tracingCarrier?: TracingCarrier) {
        this.prepareSession(false);
        const validatedParams = this.updateSessionParams(sessionStartParams);
        if (!validatedParams) {
            return;
        }
        this.runSession(SESSIONMODIFY_ACTION.UNKNOWN, validatedParams, tracingCarrier);
    }

    /**
     * getSession request.
     * This is an asynchronous API and the result is delivered in GET_SESSION_RESULT event.
     * This API is meant for resume scenario only. Since getActiveSessions is expensive as it includes a jarvis call.
     * So client needs to make getActiveSessions call once and subsequent calls can be getSession to get the state.
     * @param sessionId - sessionId to be resumed.
     * @param tracingCarrier - carrier representing parent tracing context of getSession operation. Adheres to OpenTracing standard.
     **/
    public getSession(sessionId: string, tracingCarrier?: TracingCarrier) {
        if (!this.gridServer) {
            const result: GetSessionResult = {
                sessionId: "",
                subSessionId: "",
                error: this.getGridAppUninitializedError("GetSession")
            };
            this.emit(EVENTS.GET_SESSION_RESULT, result);
            return;
        }

        this.gridServer
            .getSession(sessionId, false, tracingCarrier)
            .then((session?: GridSession) => {
                /**
                 * @TODO fix the mismatch between the session? expressions here - requiring session to be non-empty - and the session? in the parameter arguments,
                 * which permits session to be null. Either session can be null, in which case all of this code is potentially a source of errors, or it's not allowed
                 * to be null, in which case there are unnecessary ? here.
                 */
                let result: GetSessionResult = {
                    sessionId: session?.sessionId ?? "",
                    appId: session?.appId,
                    subSessionId: session?.subSessionId ?? "",
                    state: session?.state,
                    status: session?.state
                };
                this.emit(EVENTS.GET_SESSION_RESULT, result);
            })
            .catch(error => {
                let result: GetSessionResult = {
                    sessionId: sessionId,
                    subSessionId: this.gridServer?.getSubSessionId() ?? "",
                    error: error
                };
                this.emit(EVENTS.GET_SESSION_RESULT, result);
            });
    }

    /**
     * NOTE: should use resumeSession instead.
     * resume request.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * This call has a limitation that it is meant for auto resume only as client wants to stick with this due to same it's making in geronimo.
     * We can't resume once the tab is closed or from another device.
     * @param tracingCarrier - carrier representing parent tracing context of resume operation. Adheres to OpenTracing standard.
     * Here sre steps client go through:
     * 1. Client make a startSessionRequest
     * 2. Session is streaming.
     * 3. Client gets STREAM_STOPPED event with error group (((eventData.error.code ^ RErrorCode.StreamerErrorCategory)>>8) == 0)
     * 4. Client Calls multiple getSession(sessionid) untill status becomes SessionState.READY_FOR_CONNECTION
     * 5. Client calls resume()
     **/
    public resume(tracingCarrier?: TracingCarrier) {
        // Delegate to the full-featured resumeSession call to make sure video/audio elements get re-initialized.
        //TODO: Handle case where GridServer is uninitialized
        this.resumeSession(
            this.sessionStartParams!.sessionParams,
            this.gridServer!.getSessionId(),
            tracingCarrier
        );
    }

    /**
     * resume request.
     * This is an asynchronous API and the result is delivered in SESSION_START_RESULT event.
     * This call is recommended instead of resume() and covers all resume cases.
     * * @param sessionResumeParams - check SessionParams for details
     * * @param sessionId of resumable session
     * * @param tracingCarrier - carrier representing parent tracing context of resumeSession operation. Adheres to OpenTracing standard.
     * Here sre steps client go through:
     * 1. Client make a startSessionRequest
     * 2. Session is streaming.
     * 3. Client gets STREAM_STOPPED event with error group (((eventData.error.code ^ RErrorCode.StreamerErrorCategory)>>8) == 0)
     *    or tab is closed or browser crashed or session launched from different device with same account and same appid.
     * 4. Client calls getactivesession() and if the appid is same and error is (RErrorCode.SessionLimitExceeded || RErrorCode.SessionLimitPerDeviceReached)
     * 5. Client Calls multiple getSession(sessionid) untill status becomes SessionState.READY_FOR_CONNECTION
     * 6. Client calls resumeSession()
     **/
    public resumeSession(
        sessionResumeParams: SessionParams,
        sessionId: string,
        tracingCarrier?: TracingCarrier
    ) {
        this.prepareSession(true);
        const updatedSessionParams = this.updateSessionParams(sessionResumeParams);
        if (!updatedSessionParams) {
            return;
        }
        this.runSession(
            SESSIONMODIFY_ACTION.RESUME,
            updatedSessionParams,
            tracingCarrier,
            sessionId
        );
    }

    /**
     * Stops a session.
     * This is an asynchronous API and the result is delivered in SESSION_STOP_RESULT event.
     * If the session to be stopped is streaming in current client then streaming is aborted and session is terminated on the server.
     * If not streaming then session is terminated on the server.
     * @param sessionId - session to be stopped.
     * @param exitCode - reason for stopping the session
     * @param tracingCarrier - carrier representing parent tracing context of stopSession operation. Adheres to OpenTracing standard.
     **/
    public stopSession(sessionId?: string, exitCode?: number, tracingCarrier?: TracingCarrier) {
        if (!this.gridServer) {
            const result: StopSessionResultEvent = {
                sessionId: "",
                subSessionId: "",
                error: this.getGridAppUninitializedError("StopSession")
            };
            this.emit(EVENTS.SESSION_STOP_RESULT, result);
            return;
        }

        if (exitCode !== undefined) {
            Log.i("{9d1820c}", "{ae7f61b}", GetHexString(exitCode));
        }
        // Will update this check to be more correct once we remove dependency on GridServer session caching
        if (sessionId === this.gridServer.getSessionId() || sessionId === undefined) {
            // do not disable input on PauseSession call
            // \todo: do not change inputEnabled at all. Depends on confirmation from the UI client that that is okay.
            if (exitCode !== RErrorCode.PauseSession) {
                this.inputEnabled = false;
            }
            this.micCapturer.shutdown();
            this.framesDecoded = this.streamClient?.getFramesDecoded() ?? 0;
            this.sleepDetector.stopSleepDetectionTimer();
            const exitAnalyticsEvent = this.getRagnarokStreamExitEventData(
                {
                    sessionId: this.gridServer.getSessionId() ?? "",
                    subSessionId: this.gridServer.getSubSessionId() ?? "",
                    error: exitCode ? { code: exitCode } : undefined,
                    zoneName: this.gridServer.getZoneName() ?? "",
                    zoneAddress:
                        this.gridServer.getZoneAddress() || this.initializeParams.serverAddress,
                    gpuType: this.gridServer.getGpuType() ?? "",
                    isResume: this.isResume
                },
                performance.now() - this.startTime,
                false
            );
            if (this.streamClient) {
                this.isSessionOngoing = false;
                this.unregisterWindowAndDocumentEvents(); // cancel case during polling is handled by notifyClientError
                sessionId = this.gridServer.getSessionId() ?? "";

                if (this.streamClient.isStartNotified()) {
                    this.sendExitAnalyticsEvent(exitAnalyticsEvent);
                }
                this.stopStreamClient(
                    exitCode ??
                        (this.streamClient.isStartNotified()
                            ? RErrorCode.Success
                            : RErrorCode.SessionSetupCancelled)
                );
                if (exitCode === RErrorCode.PauseSession) {
                    Log.d("{9d1820c}", "{12bc45e}", sessionId);
                    this.notifyClientOfStop(sessionId);
                } else {
                    this.sendDeleteRequest(sessionId, this.gridServer, tracingCarrier);
                }
            } else {
                this.cancelSessionSetup();
            }
        } else if (sessionId) {
            this.sendDeleteRequest(sessionId, this.gridServer, tracingCarrier);
        }
    }

    /**
     * Pauses a session.
     * This is an asynchronous API and the result is delivered in SESSION_STOP_RESULT event.
     * If the session to be stopped is streaming in current client then streaming is aborted and session is not terminated on the server.
     * If not streaming then session is terminated on the server.
     * @param sessionId - session to be paused.
     * @param tracingCarrier - carrier representing parent tracing context of pauseSession operation. Adheres to OpenTracing standard.
     **/
    public pauseSession(sessionId?: string, tracingCarrier?: TracingCarrier) {
        this.stopSession(sessionId, RErrorCode.PauseSession, tracingCarrier);
    }

    private startStreaming(sessionStartParams: ValidatedSessionParams) {
        let progressData: SessionProgressUpdateEvent = {
            sessionId: this.gridServer?.getSessionId() ?? "",
            subSessionId: this.gridServer?.getSubSessionId() ?? "",
            queuePosition: 0,
            eta: 0,
            state: SessionProgressState.STARTING_STREAMER
        };
        this.emit(EVENTS.PROGRESS_UPDATE, progressData);

        const region = IsValidIPv4(this.initializeParams.serverAddress)
            ? ""
            : this.initializeParams.serverAddress.split(".")[0];

        this.streamClient = new StreamClient(
            this,
            this.inputConfigFlags,
            sessionStartParams.sessionParams.appLaunchMode ?? AppLaunchMode.Default,
            this.gamepadTester,
            this.gamepadHandler,
            this.telemetry,
            this.platformDetails,
            this.currentSession!,
            this.audioRecorder,
            this.initializeParams.textInputElement,
            sessionStartParams.sessionParams.streamParams?.[0].maxBitrateKbps,
            sessionStartParams.sessionParams.streamParams?.[0].drc,
            this.initializeParams.clientAppVersion,
            this.isResume,
            region,
            sessionStartParams.sessionParams.streamParams?.[0].sendVideoTrack
        );
        // The reason sendCachedExitEvent needed here is to prevent sending duplicate exit event if the session is running in another tab.
        // And we also need valid session id when writing in db. Also as per telemetry data almost all missed events happen after succesfull launch.
        // The existence of streamclient object is treated as evidence of polling complete which we use to clear db.
        // @todo there is a very rare possibility that cached event from previous session might get deleted here.
        this.telemetry
            .sendCachedExitEvent(true)
            .then(() => {
                this.cacheExitEventInDbPeriodically();
            })
            .catch(() => {
                Log.e("{9d1820c}", "{c761bfd}");
            });
        // TODO: Should remove need for video element, for headless mode
        const videoElement = sessionStartParams.validatedStreamParams[0].videoElement;
        if (IsiOSVersionAtLeast(this.platformDetails, 15)) {
            const OLD_PLAY_PROP = "ragnarokOldPlay";
            const video = <any>videoElement;
            if (!video[OLD_PLAY_PROP]) {
                video[OLD_PLAY_PROP] = videoElement.play;
            }
            const shouldResetSrc =
                RagnarokSettings.allowSourceReset && IsiOSVersion(this.platformDetails, 15, 1);
            videoElement.play = () => {
                if (RagnarokSettings.allowAudioReset) {
                    Log.i("{9d1820c}", "{4a5b360}");
                    this.removeContextFix();
                    this.applyContextFix();
                    this.micCapturer.resetAudio();
                }
                if (shouldResetSrc) {
                    // WAR for blank video on app switch on iOS 15.1
                    Log.i("{9d1820c}", "{8f5cd77}");
                    const src = videoElement.srcObject;
                    videoElement.srcObject = null;
                    videoElement.srcObject = src;
                }
                return video[OLD_PLAY_PROP].apply(videoElement);
            };
        }
        this.streamClient.start(
            videoElement,
            sessionStartParams.validatedStreamParams[0].audioElement,
            this.micCapturer
        );
        if (sessionStartParams.sessionParams.keyboardLayout) {
            this.streamClient.setKeyboardLayout(sessionStartParams.sessionParams.keyboardLayout);
        }
    }

    private notifyClientStartResult(data: StartSessionResultEvent) {
        this.sessionSetUpInProgress = false;
        if (data.error) {
            this.isSessionOngoing = false;
            this.unregisterWindowAndDocumentEvents();
        }
        this.emit(EVENTS.SESSION_START_RESULT, data);
    }

    private notifyClientWithError(
        exitAnalyticsEvent: StreamExitEventData,
        errorData: StartSessionResultEvent | StreamingTerminatedEvent,
        isLaunchResult: boolean
    ) {
        this.removeContextFix();
        const possiblyNoConnectivity =
            exitAnalyticsEvent.connectivity?.startsWith(Connectivity.OFFLINE) ||
            exitAnalyticsEvent.connectivity?.startsWith(Connectivity.TIMEOUT);
        // If no connectivity, we should cache the exit event.
        if (possiblyNoConnectivity) {
            // just cache it, send exit event in next subsession
            this.clearExitEventCacheTimeout();
            this.updateExitEventCacheWithNewParams(
                GetHexString(errorData.error?.code ?? RErrorCode.DelayedSessionError)
            );
            this.telemetryEventProcessor.cacheExitEventInDb().catch(() => {
                Log.e("{9d1820c}", "{0c2de99}");
            });
        } else {
            this.sendExitAnalyticsEvent(exitAnalyticsEvent);
        }
        this.stopStreamClient(errorData.error ? errorData.error.code : RErrorCode.Success);
        if (isLaunchResult) {
            this.notifyClientStartResult(errorData as StartSessionResultEvent);
        } else {
            if (errorData.error) {
                const isResumable = CanResume(errorData.error.code, this.platformDetails);
                (errorData as StreamingTerminatedEvent).isResumable = isResumable;
                Log.i("{9d1820c}", "{723f7be}", isResumable);
            }
            this.isSessionOngoing = false;
            this.sessionSetUpInProgress = false;
            this.unregisterWindowAndDocumentEvents(); // streaming terminated with error case
            this.emit(EVENTS.STREAM_STOPPED, errorData);
        }
    }

    private getConnectivityStatus(errorCode?: number): Promise<ConnectivityStatus> {
        let connectivityStatus: ConnectivityStatus = {
            errorCode: errorCode ?? RErrorCode.Success,
            connectivity: Connectivity.ONLINE
        };

        if (errorCode && ShouldRunConnectivityTest(errorCode)) {
            let timeout = 1500; // 1.5 secs
            if (
                RagnarokSettings.ragnarokConfig.connectivityCheckTimeout &&
                RagnarokSettings.ragnarokConfig.connectivityCheckTimeout !== 0
            ) {
                timeout = RagnarokSettings.ragnarokConfig.connectivityCheckTimeout;
                Log.i("{9d1820c}", "{f9909d8}", timeout);
            }
            const connectivityUrl =
                (window.location.protocol === "http:" ? "http://" : "https://") +
                this.initializeParams.serverAddress;
            Log.i("{9d1820c}", "{df38106}", connectivityUrl);
            const requestStartTime = performance.now();
            return customFetch(connectivityUrl, timeout, { method: "OPTIONS" })
                .then(response => {
                    if (
                        !(
                            (response.status >= 200 && response.status < 300) ||
                            response.status == 403
                        )
                    ) {
                        connectivityStatus.connectivity =
                            Connectivity.OFFLINE_WRONG_STATUS + "(" + String(response.status) + ")";
                    } else {
                        const requestCompleteTime =
                            Math.ceil((performance.now() - requestStartTime) / 25) * 25;
                        connectivityStatus.connectivity =
                            Connectivity.ONLINE + "(" + String(requestCompleteTime) + ")";
                    }
                    return connectivityStatus;
                })
                .catch(err => {
                    if (err.name === "AbortError") {
                        connectivityStatus.connectivity = Connectivity.TIMEOUT;
                    } else {
                        connectivityStatus.connectivity =
                            Connectivity.OFFLINE + "(" + err.name + ":" + err.message + ")";
                        // change error code
                        connectivityStatus.errorCode = ConvertErrorOnConnectivityTest(errorCode);
                    }
                    return connectivityStatus;
                });
        } else {
            return Promise.resolve(connectivityStatus);
        }
    }

    private stopStreamClient(code: number) {
        if (this.streamClient) {
            this.streamClient.stop(code);
            this.streamClient = undefined;
        }
    }

    private notifyClientOfStop(sessionId: string, error?: ErrorDetails) {
        const result: StopSessionResultEvent = {
            sessionId: sessionId,
            subSessionId: this.gridServer?.getSubSessionId() ?? "",
            framesDecoded: this.framesDecoded,
            error: error
        };
        this.removeContextFix();
        if (IsiDevice(this.platformDetails) && this.micEnabled) {
            Log.d("{9d1820c}", "{b9b1288}");
            this.setMicRecordingEnabled(false);
        }
        this.emit(EVENTS.SESSION_STOP_RESULT, result);
    }

    public onSessionStart(data: StartSessionResultEvent, sessionSetupFailed?: boolean) {
        if (data.error) {
            // Check sleep error codes upfront to use it for launch telemetry as well.
            const wasSleepExit = this.sleepDetector.wasSleepExit(data.error.code);
            if (wasSleepExit) {
                data.error.code = ConvertErrorOnSleep(data.error.code, this.platformDetails);
            }

            // Get the modified error code based on connectivity status
            this.getConnectivityStatus(data.error?.code).then(
                (connectivityStatus: ConnectivityStatus) => {
                    // Send only legacy Ragnarok_Launch_Event event when session setup fails.
                    // TODO: We can stop sending the below launch telemetry once we stop sending the legacy events
                    // emitLaunchEvent(sessionSetupFailed) sends only the legacy Ragnarok_Launch_Event
                    this.telemetry.emitLaunchEvent(
                        data.sessionId,
                        data.subSessionId,
                        this.isResume,
                        data.zoneAddress || this.initializeParams.serverAddress,
                        Math.round(performance.now() - this.startTime),
                        GetHexString(connectivityStatus.errorCode),
                        this.streamClient?.getVideoCodec() ?? "UNKNOWN",
                        this.sessionStartParams
                            ? String(this.sessionStartParams.sessionParams.appId)
                            : "",
                        this.sessionStartParams?.sessionParams.networkSessionId ?? "",
                        sessionSetupFailed // Don't emit split schema Streamer_Start for session setup failures
                    );

                    // if stream start fails, terminate connection if exists.
                    this.framesDecoded = this.streamClient?.getFramesDecoded() ?? 0;
                    if (!data.zoneAddress) {
                        data.zoneAddress = this.initializeParams.serverAddress;
                    }
                    if (data.error) {
                        data.error.code = connectivityStatus.errorCode;
                    }
                    const exitAnalyticsEvent = this.getRagnarokStreamExitEventData(
                        data,
                        0,
                        sessionSetupFailed ?? false,
                        connectivityStatus.connectivity,
                        wasSleepExit
                    );
                    this.exitErrorCodeForUnload = data.error?.code;
                    this.notifyClientWithError(exitAnalyticsEvent, data, true);
                }
            );
        } else {
            this.telemetry.emitLaunchEvent(
                data.sessionId,
                data.subSessionId,
                this.isResume,
                data.zoneAddress || this.initializeParams.serverAddress,
                Math.round(performance.now() - this.startTime),
                GetHexString(RErrorCode.Success),
                this.streamClient?.getVideoCodec() ?? "UNKNOWN",
                this.sessionStartParams ? String(this.sessionStartParams?.sessionParams.appId) : "",
                this.sessionStartParams?.sessionParams.networkSessionId ?? "",
                false
            );

            this.startTime = performance.now();
            if (this.isResume && this.micEnabled) {
                this.setMicRecordingEnabled(true);
            }

            this.notifyClientStartResult(data);
            /* Input handling is not enabled by default.
               If Client had enabled input handling then continue to handle in next streaming session.
               This is required for auto resume use case.*/
            if (this.inputEnabled) {
                this.streamClient?.toggleUserInput(true, this.inputs);
            }

            if (IsWebOS(this.platformDetails)) {
                // Listen to LG TV's idle warning to prevent burn in
                // Idle time cannot be set per platform in server, eventually move to GES
                document.addEventListener("SystemIdle", this.systemIdleFunc);
            }
        }
    }

    private collectMediaCapabilitiesTelemetry() {
        const metricName = "MediaCapabilities";

        if (!IsMediaCapabilitiesSupported()) {
            this.telemetry.emitMetricEvent(metricName, "API unsupported", -1, -1, -1, -1);
            return;
        }

        const startTime = performance.now();

        DeviceCapabilities.getDecodeCapability("h264").then(
            async (baseCapability?: MediaCapabilitiesDecodingInfo) => {
                if (!baseCapability) {
                    this.telemetry.emitMetricEvent(
                        metricName,
                        "webrtc unsupported",
                        performance.now() - startTime,
                        -1,
                        -1,
                        -1
                    );
                } else {
                    const highProfilePromise = DeviceCapabilities.getDecodeCapability(
                        "h264; profile-level-id=640033", // 5.1 profile
                        2560,
                        1440,
                        60
                    );
                    const constrainedProfilePromise = DeviceCapabilities.getDecodeCapability(
                        "h264; profile-level-id=64e033", // 5.1 constrained profile
                        2560,
                        1440,
                        60
                    );
                    const highProfileResult = ConvertCapabilityToNumber(await highProfilePromise);
                    const constrainedProfileResult = ConvertCapabilityToNumber(
                        await constrainedProfilePromise
                    );

                    const highProfileCheckTime = performance.now() - startTime;

                    const av1Promise = DeviceCapabilities.getAv1Capabilities();
                    const highFpsPromise = DeviceCapabilities.getDecodeCapability(
                        "h264",
                        1920,
                        1080,
                        120
                    );
                    const av1Result = ConvertCapabilityToNumber(await av1Promise);
                    const highFpsResult = ConvertCapabilityToNumber(await highFpsPromise);
                    const refreshRate = await DeviceCapabilities.getRefreshRate();
                    const is120FpsSupported = await DeviceCapabilities.is120FpsSupported();
                    Log.d("{9d1820c}", "{1efbc27}", refreshRate, is120FpsSupported);
                    this.telemetry.emitMetricEvent(
                        "DisplayCaps",
                        "Is120FpsSupported: " + is120FpsSupported,
                        refreshRate,
                        0,
                        0,
                        0
                    );
                    this.telemetry.emitMetricEvent(
                        metricName,
                        "codec 5.1: " +
                            highProfileResult +
                            " 5.1 constrained: " +
                            constrainedProfileResult +
                            " AV1: " +
                            av1Result,
                        highProfileCheckTime,
                        highFpsResult,
                        highProfileResult | constrainedProfileResult,
                        Math.round(window.screen.height)
                    );
                }
            }
        );
    }

    public onStreamStop(data: StreamingTerminatedEvent) {
        this.micCapturer.shutdown();
        Log.d("{9d1820c}", "{a94b484}");
        let sleep = this.sleepDetector.wasSleepExit(data.error.code);
        if (sleep) {
            data.error.code = ConvertErrorOnSleep(data.error.code, this.platformDetails);
        }
        this.framesDecoded = this.streamClient?.getFramesDecoded() ?? 0;
        if (!data.zoneAddress) {
            data.zoneAddress = this.initializeParams.serverAddress;
        }
        this.getConnectivityStatus(data.error?.code).then(
            (connectivityStatus: ConnectivityStatus) => {
                data.error.code = connectivityStatus.errorCode;
                const exitAnalyticsEvent = this.getRagnarokStreamExitEventData(
                    data,
                    performance.now() - this.startTime,
                    false,
                    connectivityStatus.connectivity,
                    sleep
                );
                this.exitErrorCodeForUnload = data.error?.code;
                this.telemetry.emitMetricEvent("HotPlug", "", 0, this.deviceChangeCount, 0, 0);
                this.deviceChangeCount = 0;
                this.notifyClientWithError(exitAnalyticsEvent, data, false);
            }
        );
    }

    public onUserIdleClear(): void {
        this.clearSystemIdleTimeout();
    }

    // This function validates, updates, and caches the session parameters and emits failure notifications for invalid parameters.
    private updateSessionParams(params: SessionParams): ValidatedSessionParams | undefined {
        let validated: ValidatedSessionParams = {
            sessionParams: Object.assign({}, params),
            validatedStreamParams: []
        };
        // check for valid video and audio element from provided tags
        if (validated.sessionParams.streamParams) {
            for (const stream of validated.sessionParams.streamParams) {
                const videoElement = document.getElementById(stream.videoTagId);
                if (videoElement == null || !(videoElement instanceof HTMLVideoElement)) {
                    let err: ErrorDetails = {
                        code: RErrorCode.InvalidVideoElement,
                        description:
                            "Didn't find video element for videoTagId: " + stream.videoTagId
                    };
                    this.onSessionStartError(err, "", getNewGuid());
                    return undefined;
                }
                const audioElement = document.getElementById(stream.audioTagId);
                if (audioElement == null || !(audioElement instanceof HTMLAudioElement)) {
                    let err: ErrorDetails = {
                        code: RErrorCode.InvalidAudioElement,
                        description:
                            "Didn't find audio element for audioTagId: " + stream.audioTagId
                    };
                    this.onSessionStartError(err, "", getNewGuid());
                    return undefined;
                }
                validated.validatedStreamParams.push({
                    videoElement: videoElement,
                    audioElement: audioElement
                });
            }
        }
        // apply platform specific defaults
        validated.sessionParams.keyboardLayout =
            validated.sessionParams.keyboardLayout ??
            (IsMacOS(this.platformDetails) ? "m-us" : "en_US");
        if (
            validated.sessionParams.appLaunchMode === undefined ||
            validated.sessionParams.appLaunchMode === AppLaunchMode.Default
        ) {
            validated.sessionParams.appLaunchMode =
                IsTouchDevice() || IsTV(this.platformDetails) || IsXbox(this.platformDetails)
                    ? AppLaunchMode.GamepadFriendly
                    : AppLaunchMode.Default;
        }
        // apply overrides
        if (RagnarokSettings.sessionMetaData) {
            if (!validated.sessionParams.metaData) {
                validated.sessionParams.metaData = RagnarokSettings.sessionMetaData;
            } else {
                for (const key in RagnarokSettings.sessionMetaData) {
                    validated.sessionParams.metaData[key] = RagnarokSettings.sessionMetaData[key];
                }
            }
        }

        if (RagnarokSettings.appLaunchMode !== undefined) {
            validated.sessionParams.appLaunchMode = RagnarokSettings.appLaunchMode;
            Log.d("{9d1820c}", "{c4969b1}", RagnarokSettings.appLaunchMode);
        }

        const shouldOverrideResolution =
            !!RagnarokSettings.resWidth && !!RagnarokSettings.resHeight;
        const shouldOverrideFps = !!RagnarokSettings.fps;
        if (shouldOverrideResolution || shouldOverrideFps) {
            validated.sessionParams.streamParams?.forEach((item: StreamParam) => {
                if (shouldOverrideResolution) {
                    item.width = RagnarokSettings.resWidth;
                    item.height = RagnarokSettings.resHeight;
                }
                if (shouldOverrideFps) {
                    item.fps = RagnarokSettings.fps;
                }
            });
            validated.sessionParams.monitorSettings?.forEach((item: MonitorSettings) => {
                if (shouldOverrideResolution) {
                    item.widthInPixels = RagnarokSettings.resWidth;
                    item.heightInPixels = RagnarokSettings.resHeight;
                }
                if (shouldOverrideFps) {
                    item.framesPerSecond = RagnarokSettings.fps;
                }
            });

            if (shouldOverrideResolution) {
                Log.i("{9d1820c}", "{92a6b38}", RagnarokSettings.resWidth, RagnarokSettings.resHeight);
            }
            if (shouldOverrideFps) {
                Log.d("{9d1820c}", "{6618bcd}", RagnarokSettings.fps);
            }
        }
        // cache parameters
        this.sessionStartParams = validated;
        return this.sessionStartParams;
    }

    private sendDeleteRequest(
        sessionId: string,
        gridServer: GridServer,
        tracingCarrier?: TracingCarrier
    ) {
        gridServer.sendDeleteRequest(sessionId, tracingCarrier).then(
            () => {
                Log.d("{9d1820c}", "{fee1eea}", sessionId);
                this.notifyClientOfStop(sessionId);
            },
            _error => {
                Log.e("{9d1820c}", "{50e2a6e}", sessionId);
                this.notifyClientOfStop(sessionId, _error);
            }
        );
    }

    /**
     * Find whether mic is supported or not
     * This is definitive (either site is http (https/Chrome flags bypass is required),
     * or browser does not support capture)
     **/
    public isMicSupported(): boolean {
        return MicCapturer.isMicSupported();
    }

    /**
     * Enable/disable mic recording
     * This will enable/disable recording of microphone (mic stream is already established
     * if supported when we initialize), can cause perf issues if
     * enabled
     * This is an asynchronous API, upon completion MIC_STATE event is emitted.
     * This method should be called after a successful session start
     **/
    public setMicRecordingEnabled(enabled: boolean) {
        Log.i("{9d1820c}", "{f5b2f30}", enabled);
        if (enabled) {
            this.micEnabled = true;
            this.micCapturer.startMicCaptureOnDefaultDeviceWithFallback();
        } else {
            this.micEnabled = false;
            this.micCapturer.stopMicRecording();
        }
    }

    /**
     * Get current status of mic recording
     * This method will emit a MicStateEvent with result of operation
     **/
    public getMicState() {
        this.micCapturer.emitMicState();
    }

    /**
     * Find whether mic should be enabled by default
     * This recommendation can be made based on various criteria
     * e.g. platform permissions, performance, limited support etc.
     * This is not the same as isMicSupported and cannot be used as a replacement
     **/
    public shouldDefaultEnableMic(): boolean {
        return this.micCapturer.shouldDefaultEnableMic();
    }

    /**
     * Send custom messages to the streaming server
     * NOTE: do nothing if stream is not started.
     * @param message - messages need to be sent to the server
     **/
    public sendCustomMessage(message: CustomMessage) {
        this.streamClient?.sendCustomMessage(message);
    }

    /**
     * This API will enable/disable input handlings for given input types in fullscreen and windowed mode.
     * When inputs are enabled/disabled, event listeners are registerd/de-registered with certain DOM elements.
     * This could be useful when showing overlay buttton under fullsceen mode.
     * @param enable - enable/disable
     * @param inputs - input types that need to be enabled/disabled [default: InputType.All]
     **/
    public toggleUserInput(enable: boolean, inputs?: InputType) {
        this.streamClient?.toggleUserInput(enable, inputs);
        this.inputEnabled = enable;
        this.inputs = inputs;
    }

    /**
     * Toggle Right-Stick Dynamic Mouse Mode on/off.
     * Default state is off, and must be explicitly enabled.
     * Note: for xbox left stick will be used for rsdmm feature with same API
     */
    public toggleRsdmm(enable: boolean) {
        // Callback after platform details have been initialised; ensures that the GamepadHandler
        // has been created before it's used.
        this.gamepadHandler.toggleRsdmmMode(enable);
    }

    /**
     * Get the reference of VirtualGamepadHandler. Reference can be undefined if inputchannel is failed to go in open state.
     * Clients should refer virtualgamepad.ts API details.
     **/
    public getVirtualGamepadHandler(): VirtualGamepadHandler | undefined {
        return this.streamClient?.getVirtualGamepadHandler();
    }

    /**
     * Set the client's authorization information.
     * initialize must be called before using this API.
     * Should be called before the first use of APIs that require authentication with the server (startSession, getActiveSessions).
     * If not called, Jarvis authentication will be assumed.
     * In the event of authentication related errors, can be called again to update the existing authentication information.
     * @param authInfo - object
                     { type: enum<auth method for server communication>
                       token: string<token to authenticate server communication>}
     **/
    public setAuthInfo(authInfo: AuthInfo) {
        this.gridServer?.setAuthInfo(authInfo);
    }

    /**
     * Send plain text to server.
     * NOTE: do nothing if stream is not started.
     * @param text - ArrayBuffer containing text in utf-8 format
     */
    public sendTextInput(text: ArrayBuffer) {
        this.streamClient?.sendTextInput(text);
    }

    /**
     * Send virtual keyboard state to server.
     * NOTE: do nothing if stream is not started.
     * @param visible - Boolean describing the visual state of the virtual keyboard
     */
    public setVirtualKeyboardState(visible: boolean) {
        this.streamClient?.setVirtualKeyboardState(visible);
    }

    /**
     * Transform video element locally.
     * @param offsetX - X coordinate offset
     * @param offsetY - Y coordinate offset
     * @param zoomFactor - zoom factor to determine translation limits
     */
    public setVideoTransforms(offsetX: number, offsetY: number, zoomFactor: number) {
        this.streamClient?.setVideoTransforms(offsetX, offsetY, zoomFactor);
    }

    /**
     * Toggle the visibility of on screen stats.
     * Other shortcuts to bring up the stats (key combination and gestures) can still be used to toggle the visibility of the stats.
     * NOTE: do nothing if stream is not started.
     **/
    public toggleOnScreenStats() {
        this.streamClient?.toggleOnScreenStats();
    }

    /**
     * Sets the keyboard layout on the server during a session
     * NOTE: do nothing if stream is not started.
     * @param layout - keyboard layout string (e.g. "en-US")
     */
    public setKeyboardLayout(layout: string) {
        Log.i("{9d1820c}", "{10717a6}", layout);
        this.streamClient?.setKeyboardLayout(layout);
    }

    /**
     * Sends keyboard event to streamer.
     * NOTE: do nothing if stream is not started.
     * @param event - keyboard event
     */
    public sendKeyEvent(event: KeyboardEvent) {
        this.streamClient?.sendKeyEvent(event);
    }

    /**
     * Sets the max bitrate while streaming.
     * NOTE: do nothing if stream is not started.
     * @param kbps - bitrate in kbps
     * @param streamIdx - stream index
     */
    public setStreamingMaxBitrate(kbps: number, streamIdx: number = 0) {
        this.streamClient?.setStreamingMaxBitrate(streamIdx, kbps);
    }

    /**
     * Adjust for poor network condition while streaming.
     * NOTE: do nothing if stream is not started.
     * @param enabled - true for enable; false for disable
     * @param streamIdx - stream index
     */
    public setDrcDfcState(enabled: boolean, streamIdx: number = 0) {
        this.streamClient?.setDrcDfcState(streamIdx, enabled);
    }

    private applyContextFix() {
        if (!RagnarokSettings.allowAudioReset) {
            return;
        }
        if ((<any>window)[IOS_CONTEXT_PROP]) {
            Log.i("{9d1820c}", "{e2aba64}");
            return;
        }

        const AudioContext = (<any>window).AudioContext || (<any>window).webkitAudioContext;
        const context: AudioContext = new AudioContext(<AudioContextOptions>{
            latencyHint: "interactive",
            sampleRate: 48000
        });
        context.onstatechange = () => {
            if (context.state === "suspended") {
                context.resume();
                this.micCapturer.resetAudio();
                Log.i("{9d1820c}", "{faee684}");
            }
        };
        (<any>window)[IOS_CONTEXT_PROP] = context;
        (<any>window)[IOS_STREAM_PROP] = context.createMediaStreamDestination();

        if (navigator.mediaDevices && this.resetAudioFunc) {
            navigator.mediaDevices.addEventListener("devicechange", this.resetAudioFunc);
        }
        Log.i("{9d1820c}", "{b5f7b9b}");
    }

    private removeContextFix() {
        if (!RagnarokSettings.allowAudioReset) {
            return;
        }
        if ((<any>window)[IOS_CONTEXT_PROP]) {
            (<any>window)[IOS_CONTEXT_PROP].close();
            (<any>window)[IOS_CONTEXT_PROP] = undefined;
            Log.i("{9d1820c}", "{0ac2a42}");
        }
        (<any>window)[IOS_STREAM_PROP] = undefined;
        if (navigator.mediaDevices && this.resetAudioFunc) {
            navigator.mediaDevices.removeEventListener("devicechange", this.resetAudioFunc);
        }
    }

    private handleSystemIdle(event: LGSystemIdleEvent) {
        const DEFAULT_IDLE_SECONDS_LEFT = 60; // eventually get from GES, likely come from client
        if (event.detail.idle) {
            this.streamClient?.notifyIdleUpdate(
                RNotificationCode.ApproachingIdleTimeout,
                DEFAULT_IDLE_SECONDS_LEFT
            );
            this.systemIdleId = window.setTimeout(() => {
                this.streamClient?.stopStreamWithError(RErrorCode.ClientDisconnectedUserIdle);
            }, DEFAULT_IDLE_SECONDS_LEFT * 1000);
        }
    }

    private clearSystemIdleTimeout() {
        if (this.systemIdleId) {
            window.clearTimeout(this.systemIdleId);
            this.systemIdleId = 0;
        }
    }

    private deviceChange() {
        this.micCapturer.onDeviceChange(this.deviceChangeCount);
        this.deviceChangeCount++;
    }

    private terminateOrAbortStreaming(errorCode: number) {
        if (!this.isSessionOngoing) {
            return;
        }
        // When there is an exception in the UI layer, typically a bunch of them occur one after the other in bursts.
        this.isSessionOngoing = false;

        this.framesDecoded = this.streamClient?.getFramesDecoded() ?? 0;
        const streamingTerminatedEvent = {
            sessionId: this.gridServer?.getSessionId() ?? "",
            subSessionId: this.gridServer?.getSubSessionId() ?? "",
            zoneName: this.gridServer?.getZoneName() ?? "",
            zoneAddress: this.gridServer?.getZoneAddress() || this.initializeParams.serverAddress,
            error: {
                code: errorCode
            }
        };
        const exitAnalyticsEvent = this.getRagnarokStreamExitEventData(
            streamingTerminatedEvent,
            performance.now() - this.startTime,
            false
        );

        this.micCapturer.shutdown();

        if (!this.streamClient || this.streamClient?.isStartNotified()) {
            this.sendExitAnalyticsEvent(exitAnalyticsEvent);
        }
        this.stopStreamClient(errorCode);
        this.cancelSessionSetup();
    }

    private cancelSessionSetup() {
        if (!this.streamClient && this.gridServer) {
            this.gridServer.cancelSessionSetup(); // will take care of delete request and events
        }
    }

    private getGridAppUninitializedError(apiName: string): ErrorDetails {
        return {
            code: RErrorCode.GridAppNotInitialized,
            description: apiName + " called before initialize"
        };
    }
}
