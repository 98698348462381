(window as any)["Ragnarok"] = (window as any)["Ragnarok"] || {};

/* Typings to be exposed to library user */
export { GridApp } from "./output/gridapp";
export * from "./output/interfaces";
export { TelemetryEvent } from "./output/telemetry/analytics";
export * from "./output/rerrorcode";
export {
    EdgeInsets,
    ChooseStreamingSettings,
    ChooseStreamingResolution,
    CalculateMaxBitrateKbps,
    CalculateDataUsage,
    InitializeUtils,
    IsFeatureSupported,
    GetSupportedAudioChannelCount
} from "./output/util/utils";
export { VirtualButton, VirtualGamepad, VirtualGamepadHandler } from "./output/input/virtualgamepad";
export * from "./output/util/settings";
export * from "../gridserver-core/index-output";
export {
    CHANGELIST,
    CLIENT_VERSION,
    CLIENT_IDENTIFICATION,
    BrowserName,
    PlatformName,
    DeviceType,
    DeviceOS,
    DeviceModel,
    LogLevel,
    LogEvent,
    authTokenCallbackType,
    AuthType,
    AuthInfo,
    // Dev client is still relying on these interfaces, so export them intentionally
    performHttpRequest,
    RequestHttpOptions,
    Response,
    getPlatformDetails,
    PlatformDetails,
    AddPlatformTelemetry,
    LogImpl
} from "../rtcutils-core/index-output";
