import { AuthType, authTokenCallbackType, AuthInfo } from "@gamestream/ragnarok";
import { AuthProvider, OffDeviceInfo, LoginResponse } from "./authprovider";

/*
 * This class provides a dummy auth provider that can be used with MockPM and passthru streaming,
 * where login with an auth provider is not required.
 */

export class DummyAuth implements AuthProvider {
    setDataForLoginType(isPartner: boolean) {
        console.log("initializing dummy auth");
    }

    getLoginUrl(): string {
        return "error"; // should never attempt login
    }

    getUserName(): string {
        return "Local";
    }

    getUserId(): string {
        return "Local";
    }

    getExternalUserId(): string {
        return "Local";
    }

    getDeviceId(): string {
        return "Local";
    }

    getIdpId(): string {
        return "Local";
    }

    checkTokenValidity(): Promise<void> {
        return Promise.resolve();
    }

    respondToRedirect(redirectMessage: LoginResponse): Promise<void> {
        return Promise.resolve(); // should never get here
    }

    getTokenCallback(): authTokenCallbackType {
        console.log("getting dummy auth");
        return () => {
            return Promise.resolve("dummy");
        };
    }

    getAuthInfo(): Promise<AuthInfo> {
        console.log("getting dummy auth");
        return Promise.resolve({ type: AuthType.JWT_GFN, token: "dummy" });
    }

    logout() {
        console.log("logging out of dummy auth");
    }

    startOffDeviceLogin(): Promise<OffDeviceInfo> {
        return Promise.resolve({
            verification_uri: "",
            user_code: "",
            device_code: "",
            expires_in: 0,
            interval: 0
        });
    }

    pollOffDeviceLogin(info: OffDeviceInfo): Promise<void> {
        return Promise.resolve();
    }
}
