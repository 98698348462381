import { IsiDevice, PlatformDetails } from "../dependencies";
import { InputMediaElement } from "../input/inputinterfaces";

const LATENCY_INDICATOR_ID = "ragnarok-latency-indicator";

export class LatencyIndicator {
    private indicatorElement?: HTMLCanvasElement;
    private indicatorContext: WebGLRenderingContext | null = null;
    private currentColor: string = "";
    private platformDetails: PlatformDetails | undefined;

    private static singleton: LatencyIndicator;

    private constructor() {}

    public static getInstance(): LatencyIndicator {
        if (!LatencyIndicator.singleton) {
            LatencyIndicator.singleton = new LatencyIndicator();
        }

        return LatencyIndicator.singleton;
    }

    public initialize(videoElement: InputMediaElement, platformDetails: PlatformDetails) {
        this.platformDetails = platformDetails;
        this.indicatorElement = this.createLatencyIndicator(videoElement);
        this.indicatorElement.style.display = "block";
        this.indicatorContext = this.indicatorElement.getContext("webgl");
        this.toggleIndicator();
    }

    public toggleIndicator() {
        if (this.currentColor === "white") {
            this.renderRed();
        } else {
            this.renderWhite();
        }
    }

    private createLatencyIndicator(videoElement: InputMediaElement): HTMLCanvasElement {
        let indicatorId = LATENCY_INDICATOR_ID;
        let indicator = document.getElementById(indicatorId);
        if (
            indicator &&
            indicator instanceof HTMLCanvasElement &&
            indicator.parentElement === videoElement.parentElement
        ) {
            return indicator;
        } else {
            if (indicator) {
                // Choose new indicator ID
                do {
                    indicatorId = LATENCY_INDICATOR_ID + Math.round(Math.random() * 10000);
                    indicator = document.getElementById(indicatorId);
                } while (indicator);
            }

            let newIndicator: HTMLCanvasElement = document.createElement("canvas");
            newIndicator.id = indicatorId;
            newIndicator.style.position = "fixed";
            if (IsiDevice(this.platformDetails!)) {
                newIndicator.style.bottom = "env(safe-area-inset-bottom, 0)";
                newIndicator.style.right = "max(24px, env(safe-area-inset-right, 0))";
            } else {
                newIndicator.style.bottom = "0";
                newIndicator.style.right = "0";
            }
            newIndicator.style.width = "40px";
            newIndicator.style.height = "40px";
            newIndicator.style.zIndex = "300";
            newIndicator.style.pointerEvents = "none";

            videoElement.insertAdjacentElement("afterend", newIndicator);

            return newIndicator;
        }
    }

    private renderWhite() {
        if (this.indicatorContext) {
            this.indicatorContext.clearColor(1, 1, 1, 1); // white
            this.indicatorContext.clear(this.indicatorContext.COLOR_BUFFER_BIT);
            this.currentColor = "white";
        }
    }

    private renderRed() {
        if (this.indicatorContext) {
            this.indicatorContext.clearColor(1, 0, 0, 1); // red
            this.indicatorContext.clear(this.indicatorContext.COLOR_BUFFER_BIT);
            this.currentColor = "red";
        }
    }
}
