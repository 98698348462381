export declare interface IEventEmitter {
    addListener(eventname: string, handler: Function): void;
    removeListener(eventname: string, handler: Function): void;
    removeAllListenersOfEvent(eventname: string): void;
    removeAllListeners(): void;
    hasListener(eventname: string): boolean;
    emit(eventname: string, ...args: any[]): void;
}

/**
 * Implements a custom event emitter pattern.
 *  a. Provides option for adding and removing listeners for an event type.
 *  b. Exposes function to emit events with variable argument.
 **/
export class EventEmitter implements IEventEmitter {
    private handlers: Map<string, Set<Function>>;
    private emitSynchronously?: boolean;
    constructor(emitSynchronously?: boolean) {
        this.handlers = new Map();
        this.emitSynchronously = emitSynchronously;
    }

    /**
     * Adds a function for to be invoked for a particular event.
     * Any number of handlers can be registered for an event.
     * @param eventname - the event type for which the corresponding handler to be invoked.
     * @param handler - listener function to be invoked when the event is emitted.
     **/
    public addListener(eventname: string, handler: Function) {
        const handles = this.handlers.get(eventname);
        if (handles !== undefined) {
            handles.add(handler);
        } else {
            const newHandles = new Set<Function>();
            newHandles.add(handler);
            this.handlers.set(eventname, newHandles);
        }
    }

    /**
     *  Removes an handler for a particular event.
     * @param eventname - the event type for which the corresponding handler  has to be removed.
     * @param handler - listener function to be removed.
     * Note: Removes only on instance of the function in each call, if a function is added as listener for X times
     * then this function needs to be invoked X times to remove all instances.
     **/
    public removeListener(eventname: string, handler: Function) {
        let handles = this.handlers.get(eventname);
        handles?.delete(handler);
    }

    public removeAllListenersOfEvent(eventname: string) {
        this.handlers.delete(eventname);
    }

    public removeAllListeners() {
        this.handlers.clear();
    }

    public hasListener(eventname: string): boolean {
        const handles = this.handlers.get(eventname);
        return handles !== undefined && handles.size > 0;
    }

    /**
     *  Emits an event. The corresponding listeners will be executed.
     * @param eventname - the event type.
     * @param args - variable number of args for this event. These parameters are passed to registered listeners.
     * Note: If there are no listener for input event type then this function is no op.
     **/
    emit(eventname: string, ...args: any[]) {
        try {
            let handles = this.handlers.get(eventname);
            if (handles) {
                for (const handler of handles) {
                    if (this.emitSynchronously) {
                        handler(...args);
                    } else {
                        window.setTimeout(handler, 0, ...args);
                    }
                }
            }
        } catch (err) {
            //Can't send this log to UI due to circular calls emit->Log.i->emit
            console.log("Exception in emit: " + err);
        }
    }
}
